/* eslint-disable sonarjs/prefer-single-boolean-return */
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

export enum Boolean {
  TRUE = 'true',
  FALSE = 'false',
}

export function isActualNumberOrNumericString(value: string | number): boolean {
  // Check if value is already a number (excluding NaN)
  if (isNumber(value) && !isNaN(value)) {
    return true;
  }

  // Check if value is a numeric string
  if (isString(value) && !isNaN(parseFloat(value))) {
    return true;
  }

  return false;
}
