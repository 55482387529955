import { Col, Form, FormInstance, Popconfirm, Row, Tooltip } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { useTheme } from 'styled-components';

import { useSendAlternateEmailVerificationMutation } from 'src/client/hooks/mutations';
import { AlternateEmail, Donor } from 'src/commons/types';

import { Box, Flex, Input, Text } from '..';

import * as S from './styles';

type Props = {
  alternateEmailsFromDb: AlternateEmail[] | undefined;
  donor: Donor;
  formInstance: FormInstance<any>;
};

const gutter = 10;

function DonorAlternateEmailEditField(props: Props) {
  const { alternateEmailsFromDb, donor, formInstance } = props;

  const { colors } = useTheme();
  const { mutateAsync: sendAlternateEmailVerification } =
    useSendAlternateEmailVerificationMutation();

  function handleAlternateEmailsChange(
    alternateEmailsFromField: AlternateEmail[],
    alternateEmailsFromDb: AlternateEmail[] | undefined
  ) {
    const alternateEmailsToCreate = getAlternateEmailsToCreate(
      alternateEmailsFromField,
      alternateEmailsFromDb
    );

    const deletedAlternateEmailIds = getDeletedAlternateEmailIds(
      alternateEmailsFromField,
      alternateEmailsFromDb
    );

    formInstance.setFieldsValue({
      deletedAlternateEmailIds,
      alternateEmailsToCreate,
    });
  }

  function renderAlternateEmails(
    fields: FormListFieldData[],
    operation: FormListOperation
  ) {
    const { remove } = operation;

    return fields.map((field) => {
      const alternateEmail = formInstance.getFieldsValue().alternateEmails?.[
        field.name
      ] as Partial<AlternateEmail>;

      function removeAlternateEmail() {
        remove(field.name);

        handleAlternateEmailsChange(
          formInstance.getFieldsValue().alternateEmails,
          alternateEmailsFromDb
        );
      }

      async function resendVerification() {
        sendAlternateEmailVerification({
          alternateEmail: alternateEmail.email as string,
          alternateEmailId: alternateEmail.id as string,
        });
      }

      const unverifiedLabel = !alternateEmail?.isVerified && (
        <Text color={colors.warning} type="body1reg2">
          Unverified
        </Text>
      );

      const verifiedLabel = alternateEmail?.isVerified && (
        <Text color={colors.success} type="body1reg2">
          Verified
        </Text>
      );

      const resendButton = !alternateEmail?.isVerified &&
        alternateEmail?.id && (
          <S.ResendButton type="link" onClick={resendVerification}>
            Resend verification
          </S.ResendButton>
        );

      if (alternateEmail?.email === donor.email) {
        return null;
      }

      return (
        <S.AlternateEmailContainer key={field.key}>
          <Flex>
            <Text color={colors.darkEmphasis1} type="body1reg2">
              {alternateEmail?.email}
            </Text>
            <Box margin="0 0 0 10px">
              {unverifiedLabel}
              {verifiedLabel}
            </Box>
          </Flex>
          <Flex alignItems="center">
            {resendButton}
            <Popconfirm
              placement="top"
              title="Are you sure you want to delete this alternate email?"
              onConfirm={removeAlternateEmail}
            >
              <S.DeleteIcon />
            </Popconfirm>
          </Flex>
        </S.AlternateEmailContainer>
      );
    });
  }

  function renderAddEmailButton(formInstance: FormInstance<any>) {
    const {
      alternateEmails: alternateEmailsFieldValue = [],
      alternateEmailInput,
    } = formInstance.getFieldsValue();

    function handleAddEmail() {
      if (!alternateEmailInput?.trim()) {
        return null;
      }

      const newAlternateEmails = [
        ...alternateEmailsFieldValue,
        {
          email: alternateEmailInput,
          isVerified: false,
        },
      ];

      formInstance.setFieldsValue({
        alternateEmails: newAlternateEmails,
      });

      handleAlternateEmailsChange(
        newAlternateEmails as AlternateEmail[],
        alternateEmailsFromDb
      );

      formInstance.resetFields(['alternateEmailInput']);
    }

    return (
      <S.AddEmailButton type="secondary" onClick={handleAddEmail}>
        Add Email
      </S.AddEmailButton>
    );
  }

  return (
    <>
      <Flex>
        <Text color={colors.darkEmphasis1} type="body1med2">
          Alternate Email Addresses
        </Text>
        <Tooltip
          placement="topLeft"
          title="Add all the email addresses user gives with to Giving Side."
        >
          <Box margin="0 0 0 8px">
            <img src="/infoIcon.png" />
          </Box>
        </Tooltip>
      </Flex>
      <Box margin="12px 0 0 0" />
      <Text color={colors.darkEmphasis2} type="body2reg2">
        Only a verified email address can be used as a primary email address.
      </Text>
      <Box margin="16px 0 0 0" />
      <Form.List initialValue={alternateEmailsFromDb} name="alternateEmails">
        {renderAlternateEmails}
      </Form.List>
      <Box margin="12px 0 0 0" />
      <Row gutter={[gutter, gutter]}>
        <Col flex="auto">
          <Form.Item
            name="alternateEmailInput"
            shouldUpdate={(previousValues, currentValues) =>
              previousValues.alternateEmails !== currentValues.alternateEmails
            }
          >
            <Input placeholder="Add alternative email" size="large" />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            shouldUpdate={(previousValues, currentValues) =>
              previousValues.alternateEmailInput !==
              currentValues.alternateEmailInput
            }
          >
            {renderAddEmailButton}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item noStyle name="alternateEmailsToCreate" />
      <Form.Item noStyle name="deletedAlternateEmailIds" />
    </>
  );
}

function getAlternateEmailsToCreate(
  alternateEmails: AlternateEmail[],
  alternateEmailsFromDb: AlternateEmail[] | undefined
) {
  return alternateEmails
    .filter(
      (alternateEmail) =>
        !isAlternateEmailAlreadyCreated(
          alternateEmail.email,
          alternateEmailsFromDb
        )
    )
    .map((alternateEmail) => alternateEmail.email);
}

function isAlternateEmailAlreadyCreated(
  alternateEmail: string,
  alternateEmailsFromDb: AlternateEmail[] | undefined
) {
  return alternateEmailsFromDb?.some(
    (alternateEmailFromDb) => alternateEmailFromDb.email === alternateEmail
  );
}

function getDeletedAlternateEmailIds(
  alternateEmailsFromFieldObject: AlternateEmail[],
  alternateEmailsFromDbObject: AlternateEmail[] | undefined
) {
  const alternateEmailsFromField = alternateEmailsFromFieldObject.map(
    (alternateEmailFromFieldObject) => alternateEmailFromFieldObject.email
  );

  return (
    alternateEmailsFromDbObject
      ?.filter(
        (alternateEmailFromDbObject) =>
          !alternateEmailsFromField.includes(alternateEmailFromDbObject.email)
      )
      .map((alternateEmailFromDbObject) => alternateEmailFromDbObject.id) ?? []
  );
}

export default DonorAlternateEmailEditField;
