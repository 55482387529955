import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  DescriptionDropdown,
  Flex,
  GivingGoalCard,
  Text,
  Title,
} from 'src/client/components';
import RecentGivesCard from 'src/client/components/RecentGivesCard';
import { AccountContext } from 'src/client/contexts/AccountContext';
import { useGetGives } from 'src/client/hooks/queries';
import routes from 'src/commons/constants/routes';
import {
  AccountContext as AccountContextType,
  Donor,
  GivesFilter,
  GIVE_STATUS,
} from 'src/commons/types';
import { getCurrentYear } from 'src/commons/utils/DateUtils';

import Gallery from './components/Gallery';
import Movement from './components/Movement';

import * as S from './styles';

type Props = {
  donor: Donor;
};

const lastSectionGutter = 30;
const firstSectionGutterHorizontal = 32;
const firstSectionGutterVertical = 24;

function DonorHomePage(props: Props) {
  const { donor } = props;

  const account = useContext(AccountContext) as AccountContextType;
  const giveQuery: GivesFilter = {
    giveYear: String(getCurrentYear()),
    donorId: donor.id,
    status: GIVE_STATUS.PROCESSED,
  };

  const { data: givesResponse, isLoading: isGivesLoading } =
    useGetGives(giveQuery);

  function handleFeedbackOnClick() {
    window.location.href = 'mailto:hello@givingside.com';
  }

  function getDashboardLink() {
    if (account.isRoleAdmin) {
      return routes.ADMIN_DONOR_DASHBOARD.replace(/:donorId/, donor.id);
    }

    if (account.isRoleDonor) {
      return routes.DASHBOARD;
    }

    return '';
  }

  return (
    <>
      <Title title="Home - Giving Side" />
      <S.Container>
        <DescriptionDropdown donor={donor} />
        <S.Content justify="center">
          <Col md={20} sm={20} xs={22}>
            <Row>
              <Col span={24}>
                <S.Top>
                  <Flex flexDirection="column">
                    <Text type="h2bold2">
                      See your giving in a whole new way.
                    </Text>
                    <S.Caption type="body1reg2">
                      View its rhythm, trends, and momentum over time.
                    </S.Caption>
                  </Flex>
                  <Link to={getDashboardLink()}>
                    <Button type="primary">View Full Dashboard</Button>
                  </Link>
                </S.Top>
              </Col>
            </Row>
            <Row
              gutter={[
                firstSectionGutterHorizontal,
                firstSectionGutterVertical,
              ]}
              justify="center"
            >
              <Col lg={11} sm={24} xs={24}>
                <GivingGoalCard donor={donor} yearView={getCurrentYear()} />
              </Col>
              <Col lg={13} sm={24} xs={24}>
                <RecentGivesCard
                  gives={givesResponse?.data}
                  isGivesLoading={isGivesLoading}
                  yearView={getCurrentYear()}
                />
              </Col>
            </Row>
            <Gallery donor={donor} />
            <Row gutter={[lastSectionGutter, lastSectionGutter]}>
              <Col lg={12} sm={24}>
                <Movement />
              </Col>
              <Col lg={12} sm={24}>
                <S.StyledFeedback onClick={handleFeedbackOnClick} />
              </Col>
            </Row>
          </Col>
        </S.Content>
      </S.Container>
    </>
  );
}

export default DonorHomePage;
