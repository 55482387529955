const precision = 12;

export function trimImpreciseFloatingPoint(number: number) {
  return parseFloat(number.toPrecision(precision));
}

export function secureRandomNumber(maxValue: number) {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);

  return array[0] % maxValue;
}
