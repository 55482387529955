import { Grid } from 'antd';
import React from 'react';

import * as S from './styles';

type Props = {
  isDescriptionEmpty?: boolean;
  isInsideGalleryDescription: boolean;
  isQuoteTextAreaRendering: boolean;
  isViewingPublicly: boolean;
};

const { useBreakpoint } = Grid;

function ArrowGuide1(props: Props) {
  const {
    isDescriptionEmpty,
    isInsideGalleryDescription,
    isQuoteTextAreaRendering,
    isViewingPublicly,
  } = props;

  const screens = useBreakpoint();
  const isMobileScreen = !screens.md;
  const shouldShowTheArrowInsideGalleryTitle =
    isInsideGalleryDescription && !isViewingPublicly;
  const shouldShowTheArrowOutsideGalleryTitle =
    !isInsideGalleryDescription &&
    isViewingPublicly &&
    !isQuoteTextAreaRendering;
  const shouldHideArrow =
    isMobileScreen ||
    shouldShowTheArrowInsideGalleryTitle ||
    shouldShowTheArrowOutsideGalleryTitle;

  if (shouldHideArrow) {
    return null;
  }

  return (
    <S.ArrowGuide1
      data-cy="arrow-guide-1"
      isDescriptionEmpty={isDescriptionEmpty}
      isQuoteTextAreaRendering={isQuoteTextAreaRendering}
      src="/galleryArrow1.svg"
    />
  );
}

export default ArrowGuide1;
