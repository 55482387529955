import { Modal } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

import { useUploadRecipientImageMutation } from 'src/client/hooks/mutations';
import {
  useGetRecipientLogo,
  updateRecipientLogoQueryData,
} from 'src/client/hooks/queries/imageQueries';
import { Recipient } from 'src/commons/types';

import * as S from './styles';

type Props = React.ComponentProps<typeof Modal> & {
  recipient: Recipient;
};

function UploadLogoModal(props: Props) {
  const { recipient } = props;

  const queryClient = useQueryClient();
  const [imageUploaded, setImageUploaded] = useState<File | undefined>(
    undefined
  );

  const { data: recipientLogo } = useGetRecipientLogo(recipient?.id);
  const {
    mutateAsync: uploadRecipientImage,
    isLoading: isUploadRecipientImageLoading,
  } = useUploadRecipientImageMutation({
    onSuccess: () => {
      if (imageUploaded) {
        const imagePath = URL.createObjectURL(imageUploaded);
        updateRecipientLogoQueryData(queryClient, recipient.id, imagePath);
      }
    },
  });

  const currentRecipientLogoFile = [
    {
      uid: '1',
      name: 'recipientLogo',
      url: recipientLogo?.recipientLogoImagePath,
    },
  ];

  const uploadPlaceholder = recipientLogo?.recipientLogoImagePath
    ? currentRecipientLogoFile
    : [];

  function handleLogoChange(info: UploadChangeParam<UploadFile<unknown>>) {
    setImageUploaded((info.file as unknown) as File);
  }

  async function handleLogoUpload(
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) {
    if (imageUploaded) {
      await uploadRecipientImage({
        file: imageUploaded,
        isPrimaryLogo: true,
        recipientId: recipient.id,
      });
    }

    if (props.onOk) {
      props.onOk(e);
    }
  }

  return (
    <S.StyledModal
      {...props}
      cancelText="Discard Changes"
      confirmLoading={isUploadRecipientImageLoading}
      destroyOnClose={true}
      okText="Save Changes"
      onOk={handleLogoUpload}
    >
      <S.StyledText type="subtitle1">Upload Logo</S.StyledText>
      <S.StyledUpload
        beforeUpload={() => false}
        defaultFileList={uploadPlaceholder}
        maxCount={1}
        onChange={handleLogoChange}
      />
    </S.StyledModal>
  );
}

export default UploadLogoModal;
