import styled, { css } from 'styled-components';

type BoxProps = {
  margin?: string;
  padding?: string;
};

type FlexProps = {
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
  flexDirection?: 'row' | 'column';
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  center?: boolean;
  gap?: string;
  flexGrow?: number;
};

export const Box = styled.div<BoxProps>`
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
`;

export const Flex = styled(Box)<FlexProps>`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex-direction: ${(props) => props.flexDirection};
  flex-wrap: ${(props) => props.flexWrap};
  gap: ${(props) => props.gap};
  flex-grow: ${(props) => props.flexGrow};

  ${(props) =>
    props.center &&
    css`
      justify-content: center;
      align-items: center;
    `}
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const minimalisticScrollBar = css`
  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.lightSurface20};
    border-radius: 6px;
  }

  *::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
`;
