import { Button as AntdButton } from 'antd';
import React, { forwardRef } from 'react';

import {
  Primary,
  Secondary,
  Link,
  Variant2,
  Elevated1,
  Elevated3,
  Elevated2,
  Variant1,
  NoStyle,
  LinkUnderline,
  Destructive
} from './styles';

type Props = Omit<React.ComponentProps<typeof AntdButton>, 'type'> & {
  as?: any;
  to?: any;
  type?:
  | 'primary'
  | 'secondary'
  | 'link'
  | 'noStyle'
  | 'variant1'
  | 'variant2'
  | 'elevated1'
  | 'elevated2'
  | 'elevated3'
  | 'destructive';
};

const Button = forwardRef<HTMLElement, Props>((props, ref) => {
  const { children, size = 'large', ...remainingProps } = props;
  const buttonProps = { children, ref, size, ...remainingProps };

  //TODO: use Enum

  if (remainingProps.type === 'primary') {
    return <Primary {...buttonProps}>{children}</Primary>;
  }

  if (remainingProps.type === 'secondary') {
    return <Secondary {...buttonProps}>{children}</Secondary>;
  }

  if (remainingProps.type === 'destructive') {
    return <Destructive {...buttonProps}>{children}</Destructive>;
  }

  if (remainingProps.type === 'link') {
    return (
      <Link {...buttonProps}>
        <LinkUnderline />
        {children}
      </Link>
    );
  }

  if (remainingProps.type === 'variant1') {
    return <Variant1 {...buttonProps}>{children}</Variant1>;
  }

  if (remainingProps.type === 'variant2') {
    return <Variant2 {...buttonProps}>{children}</Variant2>;
  }

  if (remainingProps.type === 'noStyle') {
    return <NoStyle {...buttonProps}>{children}</NoStyle>;
  }

  if (remainingProps.type === 'elevated1') {
    return <Elevated1 {...buttonProps}>{children}</Elevated1>;
  }

  if (remainingProps.type === 'elevated2') {
    return <Elevated2 {...buttonProps}>{children}</Elevated2>;
  }

  if (remainingProps.type === 'elevated3') {
    return <Elevated3 {...buttonProps}>{children}</Elevated3>;
  }

  return <NoStyle {...buttonProps}>{children}</NoStyle>;
});

export default Button;
