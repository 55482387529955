import styled from 'styled-components';

import { Input, Flex } from 'src/client/components';
import { BODY_1_REG_2 } from 'src/client/constants/text';

export const SearchInput = styled(Input)`
  width: 756px;
  padding: 8px 12px;
  height: 40px;
  ${BODY_1_REG_2};
`;

export const ActionContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;

  svg {
    color: ${(props) => props.theme.colors.teal2};
  }
`;
