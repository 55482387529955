/* eslint-disable no-magic-numbers */
import { Grid } from 'antd';
import React from 'react';
import {
  Bar,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTheme } from 'styled-components';

import LoadingCard from 'src/client/components/LoadingCard';
import { Box } from 'src/client/components/StyledCommon';
import Text from 'src/client/components/Text';
import { useGetRecipientsByIds } from 'src/client/hooks/queries';
import { getGiveTypesStats } from 'src/client/utils/GiveUtils';
import { getRecipientIdsFromGives } from 'src/client/utils/Recipientutils';
import { Give, RECIPIENT_TYPE } from 'src/commons/types';

import * as S from './styles';

type Props = {
  gives: Give[] | undefined;
  isGivesLoading: boolean;
};

const { useBreakpoint } = Grid;

// eslint-disable-next-line sonarjs/cognitive-complexity
function GivingTypeSection(props: Props) {
  const { gives, isGivesLoading } = props;

  const theme = useTheme();
  const recipientIds = gives && getRecipientIdsFromGives(gives);
  const screen = useBreakpoint();

  const { data: recipients, isLoading: isRecipientsLoading } =
    useGetRecipientsByIds(recipientIds || []);

  const chartData =
    (!isGivesLoading &&
      !isRecipientsLoading &&
      getGiveTypesStats(recipients || [], gives || [])) ||
    undefined;

  const isLoading = isGivesLoading || isRecipientsLoading;
  const isMobile = !screen.lg;

  return (
    <S.Container>
      <LoadingCard isLoading={isLoading}>
        <Box margin="0 0 45px 0">
          <Text type="h3bold2">Types of Giving</Text>
        </Box>
        <S.GraphContainer>
          <ResponsiveContainer>
            <S.StyledBarChart
              barSize={isMobile ? 18 : 28}
              data={chartData}
              layout={isMobile ? 'vertical' : 'horizontal'}
              margin={{
                left: isMobile ? 50 : 0,
                bottom: isMobile ? 0 : 20,
              }}
            >
              {isMobile ? null : (
                <CartesianGrid
                  strokeDasharray="3"
                  strokeOpacity={0.25}
                  vertical={false}
                />
              )}
              <XAxis
                dataKey={isMobile ? 'count' : 'giveTypeDisplay'}
                hide={isMobile ? true : false}
                interval={0}
                label={{
                  fontSize: 12,
                  fontWeight: 400,
                }}
                stroke="#BFBFBF"
                strokeDasharray="0"
                strokeOpacity={1}
                style={{
                  fill: 'green',
                }}
                tick={isMobile ? undefined : <CustomTick />}
                tickLine={false}
                type={isMobile ? 'number' : 'category'}
              />
              <YAxis
                axisLine={false}
                dataKey={isMobile ? 'giveTypeDisplay' : 'count'}
                domain={[0, (dataMax: number) => (dataMax > 3 ? dataMax : 4)]}
                tickLine={false}
                type={isMobile ? 'category' : 'number'}
              />
              <Tooltip cursor={{ fill: theme.colors.lightSurface20 }} />
              <Bar dataKey="count" maxBarSize={70}>
                {renderCell(chartData)}
              </Bar>
            </S.StyledBarChart>
          </ResponsiveContainer>
        </S.GraphContainer>
      </LoadingCard>
    </S.Container>
  );
}

function CustomTick({ x, y, payload }: any) {
  const tickText = payload.value;
  const words = tickText.split(' ');

  return (
    <g transform={`translate(${x},${y + 10})`}>
      {words.map((word: any, index: any) => (
        <text
          fill="#666"
          fontSize={12}
          fontWeight={400}
          key={`tick-${index}`}
          textAnchor="middle"
          x={0}
          y={index * 16}
        >
          {word}
        </text>
      ))}
    </g>
  );
}

function renderCell(
  chartData: ReturnType<typeof getGiveTypesStats> | undefined
) {
  return chartData?.map((entry, index) => {
    let fill;

    switch (entry.giveType) {
      case RECIPIENT_TYPE.CROWDFUNDING:
        fill = '#4FE074';
        break;
      case RECIPIENT_TYPE.FOR_PROFIT:
        fill = '#6E3EAE';
        break;
      case RECIPIENT_TYPE.FOR_PROFIT_B_CORP:
        fill = '#DEBE2A';
        break;
      case RECIPIENT_TYPE.INDIVIDUAL:
        fill = '#2E83E8';
        break;
      case RECIPIENT_TYPE.LABOR_UNION:
        fill = '#2C8C6D';
        break;
      case RECIPIENT_TYPE.NONPROFIT:
        fill = '#26C0C6';
        break;
      case RECIPIENT_TYPE.NONPROFIT_FISCALLY_SPONSORED:
        fill = '#524AD0';
        break;
      case RECIPIENT_TYPE.OTHER:
        fill = '#2E83E8';
        break;
      case RECIPIENT_TYPE.POLITICAL_C4:
        fill = '#D83A8F';
        break;
      case RECIPIENT_TYPE.POLITICAL_CANDIDATE:
        fill = '#E6862D';
        break;
      case RECIPIENT_TYPE.POLITICAL_PAC:
        fill = '#908CF7';
        break;
      default:
        fill = '#26C0C6';
    }

    return <Cell fill={fill} key={`cell-${index}`} />;
  });
}

export default GivingTypeSection;
