import { Alert } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

import { Box, Text } from 'src/client/components';
import { ProcessGiveResponse } from 'src/client/types/ApiResponse';
import routes from 'src/commons/constants/routes';
import { replaceRouteParams } from 'src/commons/utils/RouteUtils';

type DuplicateGiveInfoProps = {
  processGiveResponse: ProcessGiveResponse | undefined;
};

function DuplicateGiveInfo(props: DuplicateGiveInfoProps) {
  const { processGiveResponse } = props;

  if (
    isEmpty(processGiveResponse) ||
    isEmpty(processGiveResponse.data.duplicateGiveIds)
  ) {
    return null;
  }

  const duplicateGiveMessage = processGiveResponse.data?.duplicateGiveIds?.map(
    (giveId) => {
      const duplicateGiveLink = replaceRouteParams(routes.ADMIN_VIEW_GIVE, {
        giveId,
      });

      return (
        <Text key={giveId} type="body2reg2">
          <a
            data-cy="duplicate-give-link"
            href={duplicateGiveLink}
            rel="noreferrer"
            target="_blank"
          >
            {duplicateGiveLink}
          </a>
        </Text>
      );
    }
  );

  const duplicateCount = processGiveResponse.data?.duplicateGiveIds
    ?.length as number;

  const titleText =
    duplicateCount > 1
      ? 'Below are possible duplicate gives:'
      : 'Below is a possible duplicate give:';

  return (
    <Alert
      message={
        <>
          <Text type="h4med2">{titleText}</Text>
          {duplicateGiveMessage}
          <Box margin="8px 0 0 0" />
          <Text type="body2reg2">
            To process anyway, click process button below.
          </Text>
        </>
      }
      type="warning"
    />
  );
}

export default DuplicateGiveInfo;
