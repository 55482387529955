import { Checkbox, Form, Row, Col } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { AxiosError } from 'axios';
import HttpStatus from 'http-status-codes';

import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Link, useRouteMatch } from 'react-router-dom';

import { login } from 'src/client/api/AuthApi';
import { Box, Button, FlexCenter, Text } from 'src/client/components';
import LandingPageFooter from 'src/client/components/LandingPageFooter';
import config from 'src/client/configs';
import { UserRole } from 'src/commons/constants/roles';
import routes from 'src/commons/constants/routes';
import { replaceRouteParams } from 'src/commons/utils/RouteUtils';

import * as S from './styles';

type LoginForm = {
  email: string;
  password: string;
};

const formInitialValues = { remember: true };
const emailRules = [{ required: true, message: 'Please input your email.' }];
const passwordRules = [
  { required: true, message: 'Please input your password.' },
];

export default function LoginPage() {
  const [form] = Form.useForm();
  const adminRouteMatch = useRouteMatch(routes.ADMIN_SIGN_IN);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const screen = useBreakpoint();

  const { isLoading: isLoginUserLoading, mutate: loginUser } = useMutation(
    login,
    {
      onSuccess: () => handleRedirect(),
      onError: (error: AxiosError<any>) => {
        const incorrectCredentialsStatusCodes = [
          HttpStatus.NOT_FOUND,
          HttpStatus.UNAUTHORIZED,
        ];

        if (
          incorrectCredentialsStatusCodes.includes(
            error.response?.data.error.statusCode
          )
        ) {
          setLoginErrorMessage('Invalid email or password');
        } else {
          setLoginErrorMessage('Something went wrong');
        }
      },
    }
  );
  const userRole = adminRouteMatch?.isExact ? UserRole.ADMIN : UserRole.DONOR;

  function handleFinish(values: LoginForm) {
    loginUser({
      ...values,
      userRole,
    });
  }

  function handleRedirect() {
    if (adminRouteMatch?.isExact) {
      window.location.href = routes.ADMIN_DASHBOARD;
    } else {
      window.location.href = routes.ROOT;
    }
  }

  function handleGoogleLogin() {
    if (userRole === UserRole.ADMIN) {
      window.location.href = `${config.API_URL}/api/1.0/auth/admin/google`;
    } else {
      window.location.href = `${config.API_URL}/api/1.0/auth/donor/google`;
    }
  }

  const errorText = loginErrorMessage ? (
    <Box margin="0 0 8px 0">
      <S.ErrorText type="body1med2">{loginErrorMessage}</S.ErrorText>
    </Box>
  ) : null;

  return (
    <S.Container>
      <S.NavigationBar>
        <div>
          <Link to="/">
            <S.Logo src="/logo.png" />
          </Link>
        </div>
        {screen.md && (
          <Link to={routes.DONOR_REGISTER}>
            <Button type="primary">Request early access</Button>
          </Link>
        )}
      </S.NavigationBar>
      <Row justify="center">
        <Col md={24} xs={20}>
          <S.BodyContainer>
            <S.Header type="displayMBold2">
              Welcome back to Your Giving Side
            </S.Header>
            {errorText}
            <Form
              form={form}
              initialValues={formInitialValues}
              name="basic"
              wrapperCol={{
                span: 24,
              }}
              onFinish={handleFinish}
            >
              <Form.Item name="email" rules={emailRules}>
                <S.StyledInput
                  data-cy="email-input"
                  placeholder="Email"
                  size="large"
                />
              </Form.Item>
              <Box margin="24px 0">
                <Form.Item name="password" rules={passwordRules}>
                  <S.StyledInputPassword
                    data-cy="password-input"
                    placeholder="Password"
                    size="large"
                  />
                </Form.Item>
              </Box>
              <S.CheckboxContainer>
                <Form.Item noStyle name="remember" valuePropName="checked">
                  <Checkbox data-cy="remember-me-checkbox">
                    <Text type="body1reg2">Remember me</Text>
                  </Checkbox>
                </Form.Item>
                <Link
                  data-cy="forgot-password-link"
                  to={replaceRouteParams(routes.SEND_RESET_PASSWORD_EMAIL, {
                    userRole: userRole.toLowerCase(),
                  })}
                >
                  <Text type="body1med2">Forgot Password</Text>
                </Link>
              </S.CheckboxContainer>
              <S.LoginButton
                htmlType="submit"
                loading={isLoginUserLoading}
                type="primary"
              >
                Log in
              </S.LoginButton>
            </Form>
            <Box margin="21px 0">
              <FlexCenter>
                <Text type="body1reg2">or</Text>
              </FlexCenter>
            </Box>
            <S.GoogleButton type="variant2" onClick={handleGoogleLogin}>
              <img src="/googleIcon.png" />
              Continue with Google
            </S.GoogleButton>
            <S.RequestEarlyAccessContainer>
              <Text type="body1reg2">Don&apos;t have an account?&nbsp;</Text>
              <Link to={routes.DONOR_REGISTER}>
                <Text type="body1med2">Request early access</Text>
              </Link>
            </S.RequestEarlyAccessContainer>
          </S.BodyContainer>
        </Col>
      </Row>
      <Box margin="208px 0 0 0" />
      <LandingPageFooter />
    </S.Container>
  );
}
