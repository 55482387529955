import { useMutation } from 'react-query';

import {
  createDonorEthnicities,
  createDonorGoal,
  deleteDonorById,
  deleteDonorEthnicities,
  resetDonorPassword,
  updateDonor,
  updateDonorEthnicities,
  updateDonorGender,
  updateDonorGoal,
  updateDonorPassword,
  sendSetInitialPasswordEmail,
  sendResetPasswordEmail,
  sendConfirmMergeAccountsEmail,
  mergeAccounts,
} from 'src/client/api/DonorApi';
import { Donor, DonorEthnicity, Goal } from 'src/commons/types';
import { ApiResponse } from 'src/commons/types/Response.type';

type Options<Data = Goal> = {
  onSuccess?: (data: Data) => void;
  onMutate?: (variables: any) => unknown;
  onError?: (error: any) => void;
};

export function useUpdateDonorMutation(opts?: Options<Donor>) {
  return useMutation(updateDonor, opts);
}

export function useUpdateDonorGenderMutation(
  opts?: Options<{ message: string }>
) {
  return useMutation(updateDonorGender, opts);
}

export function useUpdateDonorEthnicitiesMutation(
  opts?: Options<ApiResponse<DonorEthnicity[]>>
) {
  return useMutation(updateDonorEthnicities, opts);
}

export function useDeleteDonorEthnicitiesMutation(
  opts?: Options<{ message: string }>
) {
  return useMutation(deleteDonorEthnicities, opts);
}

export function useCreateDonorEthnicitiesMutation(
  opts?: Options<ApiResponse<DonorEthnicity[]>>
) {
  return useMutation(createDonorEthnicities, opts);
}

export function useCreateDonorGoalMutation(opts?: Options) {
  return useMutation(createDonorGoal, opts);
}

export function useUpdateDonorGoalMutation(opts?: Options) {
  return useMutation(updateDonorGoal, opts);
}

export function useDeleteDonorByIdMutation(
  opts?: Options<ApiResponse<string>>
) {
  return useMutation(deleteDonorById, opts);
}

export function useUpdateDonorPasswordMutation() {
  return useMutation(updateDonorPassword);
}

export function useResetPasswordMutation() {
  return useMutation(resetDonorPassword);
}

export function useSendSetInitialPasswordEmailMutation() {
  return useMutation(sendSetInitialPasswordEmail);
}

export function useSendResetPasswordEmailMutation() {
  return useMutation(sendResetPasswordEmail);
}

export function useSendConfirmMergeAccountsEmailMutation(opts?: Options) {
  return useMutation(sendConfirmMergeAccountsEmail, { ...opts, retry: 0 });
}

export function useMergeAccountsMutation() {
  return useMutation(mergeAccounts);
}
