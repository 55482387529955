import { Select } from 'antd';
import styled, { css } from 'styled-components';

import { DatePicker, Button, Input, Table, Text } from 'src/client/components';
import { BODY_1_REG_2 } from 'src/client/constants/text';

const { RangePicker } = DatePicker;

const filterBaseStyle = css`
  padding: 8px 12px;
  height: 40px;
  ${BODY_1_REG_2};
`;

export const NameInput = styled(Input)`
  width: 400px;
  ${filterBaseStyle};
`;

export const GiveDateRange = styled(RangePicker)`
  width: 400px;
  ${filterBaseStyle};
`;

const FilterSelect = styled(Select)`
  &&& .ant-select-selector {
    height: 40px;
    text-transform: capitalize;
    border-radius: 8px;
    ${filterBaseStyle};
  }
`;

export const PlatformSelect = styled(FilterSelect)`
  && {
    width: 163px;
  }
`;

export const TaxSelect = styled(FilterSelect)`
  && {
    width: 300px;
  }
`;

export const StatusSelect = styled(FilterSelect)`
  && {
    width: 300px;
  }
`;

export const StyledOption = styled(Select.Option)`
  &&& {
    text-transform: capitalize;
  }
`;

export const StyledTable = styled(Table)`
  margin-top: 24px;

  tr {
    cursor: pointer;
  }

  tbody tr:nth-child(odd) {
    background-color: #fafafa;
    td:last-child {
      background-color: #fafafa;
    }
  }
  tbody tr:nth-child(even) {
    background-color: ${(props) => props.theme.colors.white};
  }
`;

export const HighlightedCellText = styled(Text)`
  color: ${(props) => props.theme.colors.teal2};
`;

export const IconButton = styled(Button)`
  &&& {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
    color: ${(props) => props.theme.colors.teal2};
  }
`;
