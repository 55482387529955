import { Popover } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

import { FlexCenter } from 'src/client/components/StyledCommon';
import Text from 'src/client/components/Text';

type GoalAmountContainerProps = {
  isGoalExceeded: boolean;
};

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background-image: url('/croppedBird.png');
  background-repeat: no-repeat;
  background-position: top 17px left 0px;
  background-size: 69%;
  padding: 0 17px 28px 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .ant-progress-inner {
    border-radius: 100px !important;
  }

  .ant-progress-bg {
    height: 24px !important;
  }

  @media ${(props) => props.theme.device.laptop} {
    padding: 0 48px 40px 48px;
  }
`;

export const Title = styled(Text)`
  margin: 0 0 16px 0;
  color: white;

  @media ${(props) => props.theme.device.laptop} {
    margin: 0 0 24px 0;
  }
`;

export const GoalExceededText = styled(Text)`
  text-align: center;
`;

export const GoalAmountContainer = styled(FlexCenter)<GoalAmountContainerProps>`
  margin: ${(props) => (props.isGoalExceeded ? '8px 0 8px 0' : '8px 0 20px 0')};
  flex-wrap: wrap;
  width: 100%;
  text-align: center;

  @media ${(props) => props.theme.device.laptop} {
    margin: ${(props) =>
      props.isGoalExceeded ? '8px 0 8px 0' : '8px 0 32px 0'};
  }
`;

export const StyledPopover = styled(Popover)`
  background: ${(props) => props.theme.colors.darkSurface3};
`;

export const PopoverContainer = styled.div`
  color: white;
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.colors.darkSurface3};
`;

export const Legend = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  margin-right: 8px;
  flex-shrink: 0;
`;

export const GiveLegend = styled(Legend)`
  background-color: ${(props) => props.theme.colors.accent1};
`;

export const ExceedingLegend = styled(Legend)`
  background-color: ${(props) => props.theme.colors.darkSecondary};
`;

export const PopoverLabelContainer = styled.div`
  width: 115px;
`;

export const GoalDisplayStateGlobalStyle = createGlobalStyle`
  .ant-popover-arrow-content::before {
    background: ${(props) => props.theme.colors.darkSurface3} !important;
  }
`;
