import React, { useMemo } from 'react';

import { LoadingCard } from 'src/client/components';
import { PageRecipient } from 'src/commons/types';

import FeaturedCardV2 from '../../../DonorGalleryV2/components/CardsSection/components/FeaturedCard';
import FeaturedCardBase from '../FeaturedCard';

import * as stylesheet from './styles';

type Props = {
  featuredPageRecipients: PageRecipient[] | undefined;
  isLoading: boolean;
  styles?: any;
};

function FeaturedCardsViewMode(props: Props) {
  const { featuredPageRecipients, isLoading, styles } = props;

  const S = styles ?? stylesheet;
  const FeaturedCard = styles ? FeaturedCardV2 : FeaturedCardBase;

  const featuredCards = useMemo(
    () =>
      featuredPageRecipients?.map((pageRecipient) => (
        <FeaturedCard
          data-page-recipient-id={pageRecipient.id}
          data-page-recipient-visibility="featured-card"
          key={pageRecipient.id}
          pageRecipient={pageRecipient}
          onClick={
            pageRecipient.recipientWebsite
              ? () =>
                  window.open(
                    'https://' + pageRecipient.recipientWebsite!,
                    '_blank'
                  )
              : undefined
          }
        />
      )),
    [FeaturedCard, featuredPageRecipients]
  );

  return (
    <S.Container>
      <LoadingCard
        isLoading={isLoading}
        spinProps={{
          'data-cy': 'featured-cards-spinner',
        }}
      >
        {featuredCards}
      </LoadingCard>
    </S.Container>
  );
}

export default FeaturedCardsViewMode;
