import { useMutation } from 'react-query';

import { updatePageRecipients } from 'src/client/api/PageRecipientApi';
import { PageRecipient } from 'src/commons/types';

type CommonOptions = {
  onSuccess?: (option: PageRecipient[]) => void;
};

export function useUpdatePageRecipientsMutation(options?: CommonOptions) {
  return useMutation(updatePageRecipients, options);
}
