import styled from 'styled-components';

import { Flex } from 'src/client/components';

export const Container = styled(Flex)`
  padding: 16px;
  color: black;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.border1};
  background: white;

  .anticon-close {
    margin-left: 14px;
    color: ${(props) => props.theme.colors.darkMedEmphasis};
    font-size: 16px;
  }
`;
