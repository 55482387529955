import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Row, Col, FormInstance, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';

import { Box, InputNumber, Radio, Select } from 'src/client/components';
import useThemeContext from 'src/client/hooks/useTheme';
import { donationMatchingTooltipText } from 'src/commons/constants/adminForm';
import { DONATION_TYPE, TAX_DEDUCTIBLE } from 'src/commons/types';
import { capitalizeFirstLetter } from 'src/commons/utils/StringUtils';

import { GroupInputTitleText, StyledCheckbox } from './../styles';
import DonationMatchingDetails from './DonationMatchingDetails';
import SplitDetails from './SplitDetails';

const gutterBig = 24;

const { Option } = Select;

function DonationDetailsInput() {
  const { colors } = useThemeContext();

  function handleResetSplitDetailsInput(
    checked: boolean,
    formInstance: FormInstance<any>
  ) {
    if (!checked) {
      formInstance.setFieldsValue({
        splitAmount: null,
        splitTaxDeductible: null,
        splitType: null,
      });
    }
  }

  function handleResetDonationMatchingDetailsInput(
    checked: boolean,
    formInstance: FormInstance<any>
  ) {
    if (!checked) {
      formInstance.setFieldsValue({
        matchedDonationAmount: null,
        matchedDonationTaxDeductible: TAX_DEDUCTIBLE.NO,
        matchedDonationCompany: null,
      });
    } else {
      formInstance.setFieldValue(
        'matchedDonationTaxDeductible',
        TAX_DEDUCTIBLE.NO
      );
    }
  }

  function renderDonationTypeInput(formInstance: FormInstance<any>) {
    const { getFieldValue } = formInstance;

    const isDonationTypeCash =
      getFieldValue('donationType') === DONATION_TYPE.CASH;

    if (isDonationTypeCash) {
      return (
        <Form.Item
          label="Amount"
          name="amount"
          rules={[{ required: true, message: 'Amount is required' }]}
        >
          <InputNumber
            data-cy="give-amount-input"
            placeholder="Amount in USD"
            prefix="$"
            size="large"
          />
        </Form.Item>
      );
    }

    return (
      <Form.Item
        label="Hours"
        name="hours"
        rules={[{ required: true, message: 'Hours is required' }]}
      >
        <InputNumber
          data-cy="hours-input"
          placeholder="No. of hours"
          size="large"
        />
      </Form.Item>
    );
  }

  function renderSplitDonationDetails(formInstance: FormInstance<any>) {
    const { getFieldValue } = formInstance;

    const isDonationTypeCash =
      getFieldValue('donationType') === DONATION_TYPE.CASH;

    const shouldShowSplitDeatils =
      getFieldValue('isDonationSplit') && isDonationTypeCash;

    if (shouldShowSplitDeatils) {
      return <SplitDetails />;
    }
  }

  function renderSplitDonationCheckbox(formInstance: FormInstance<any>) {
    const { getFieldValue } = formInstance;

    const isDonationTypeCash =
      getFieldValue('donationType') === DONATION_TYPE.CASH;

    if (isDonationTypeCash) {
      return (
        <Form.Item
          name="isDonationSplit"
          style={{ marginBottom: 8 }}
          valuePropName="checked"
        >
          <StyledCheckbox
            data-cy="split-donation-checkbox"
            onChange={(e: CheckboxChangeEvent) =>
              handleResetSplitDetailsInput(e.target.checked, formInstance)
            }
          >
            Split donation amount
          </StyledCheckbox>
        </Form.Item>
      );
    }
  }

  function renderDonationMatchingCheckbox(formInstance: FormInstance<any>) {
    const { getFieldValue } = formInstance;

    const isDonationTypeCash =
      getFieldValue('donationType') === DONATION_TYPE.CASH;

    if (isDonationTypeCash) {
      return (
        <Form.Item
          name="isDonationMatching"
          style={{ marginBottom: 8 }}
          valuePropName="checked"
        >
          <StyledCheckbox
            onChange={(e: CheckboxChangeEvent) =>
              handleResetDonationMatchingDetailsInput(
                e.target.checked,
                formInstance
              )
            }
          >
            With donation matching
            <Box style={{ display: 'inline', paddingLeft: '4px' }}>
              <Tooltip placement="bottom" title={donationMatchingTooltipText}>
                <InfoCircleOutlined style={{ color: colors.darkEmphasis2 }} />
              </Tooltip>
            </Box>
          </StyledCheckbox>
        </Form.Item>
      );
    }
  }

  function renderDonationMatchingDetails(formInstance: FormInstance<any>) {
    const { getFieldValue } = formInstance;

    const isDonationTypeCash =
      getFieldValue('donationType') === DONATION_TYPE.CASH;

    const shouldShowDonationMatchingDetails =
      getFieldValue('isDonationMatching') && isDonationTypeCash;

    if (shouldShowDonationMatchingDetails) {
      return <DonationMatchingDetails />;
    }
  }

  const taxDeductibleOptions = Object.values(TAX_DEDUCTIBLE)
    .filter((taxDeductible) => taxDeductible !== TAX_DEDUCTIBLE.SPLIT)
    .map((taxDeductible) => (
      <Option key={taxDeductible} value={taxDeductible}>
        {capitalizeFirstLetter(taxDeductible)}
      </Option>
    ));

  return (
    <>
      <GroupInputTitleText>Donation</GroupInputTitleText>
      <Form.Item label="Type" name="donationType">
        <Radio.Group>
          <Radio data-cy="cash-radio-btn" value={DONATION_TYPE.CASH}>
            Cash
          </Radio>
          <Radio data-cy="hours-radio-btn" value={DONATION_TYPE.HOURS}>
            Hours
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Row gutter={[gutterBig, 0]}>
        <Col flex="auto">
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.donationType !== currentValues.donationType
            }
          >
            {renderDonationTypeInput}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Tax Deductible?"
            name="taxDeductible"
            rules={[{ required: true, message: 'Tax Deductible is required' }]}
          >
            <Select
              showSearch
              data-cy="tax-deductible-select"
              placeholder="Tax deductible?"
              size="large"
              virtual={false}
            >
              {taxDeductibleOptions}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.donationType !== currentValues.donationType
        }
      >
        {renderDonationMatchingCheckbox}
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.isDonationMatching !== currentValues.isDonationMatching ||
          prevValues.donationType !== currentValues.donationType
        }
      >
        {renderDonationMatchingDetails}
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.donationType !== currentValues.donationType
        }
      >
        {renderSplitDonationCheckbox}
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.isDonationSplit !== currentValues.isDonationSplit ||
          prevValues.donationType !== currentValues.donationType
        }
      >
        {renderSplitDonationDetails}
      </Form.Item>
    </>
  );
}

export default DonationDetailsInput;
