import { PlatformStat } from 'src/commons/types';
import { formatToFinancial } from 'src/commons/utils/MoneyUtilts';

import ApiClient from './ApiClient';

const apiVersion = '1.0';
const endpoint = 'platform-stats';
const url = `${apiVersion}/${endpoint}`;

export async function getPlatformStat(
  platformId: string
): Promise<PlatformStat> {
  const { data } = await ApiClient.get<PlatformStat>(
    `${url}/platform/${platformId}`
  );

  return (
    data && {
      ...data,
      givesTotalAmount: formatToFinancial(data.givesTotalAmount),
      aveGiveAmount: formatToFinancial(data.aveGiveAmount),
      medGiveAmount: formatToFinancial(data.medGiveAmount),
      aveGoalAmount: formatToFinancial(data.aveGoalAmount),
      medGoalAmount: formatToFinancial(data.medGoalAmount),
      donorTotalGiveAmount: formatToFinancial(data.donorTotalGiveAmount),
      donorMedGiveAmount: formatToFinancial(data.donorMedGiveAmount),
    }
  );
}
