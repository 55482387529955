import { DragEndEvent, SensorDescriptor, SensorOptions } from '@dnd-kit/core';
import React, { forwardRef } from 'react';

import HiddenCardsEditModeBase from 'src/client/pages/DonorGallery/components/HiddenCardsEditMode';
import { PageRecipient } from 'src/commons/types';

import * as stylesheet from './styles';

type Props = {
  isLoading: boolean;
  hiddenPageRecipients: PageRecipient[] | undefined;
  onFeaturePageRecipient: (pageRecipientId: string) => void;
  handleDragEnd: (event: DragEndEvent) => void;
  handleDragStart: (event: DragEndEvent) => void;
  sensors: SensorDescriptor<SensorOptions>[];
};

const HiddenCardsEditMode = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <HiddenCardsEditModeBase
    {...props}
    showSpotlightText
    ref={ref}
    styles={stylesheet}
  />
));

export default HiddenCardsEditMode;
