import { Table as AntdTable } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import take from 'lodash/take';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Give } from 'src/commons/types';

import { calculateTotalGiveAmount } from 'src/commons/utils/GiveUtilts';
import { formatToCurrency } from 'src/commons/utils/MoneyUtilts';

import { Container } from '../pages/DonorDashboard/components/GivesSection/styles';

import Button from './Button';
import LoadingCard from './LoadingCard';
import Table from './Table';
import Text from './Text';
import ExportButton from './v2/ExportButton';

const initialRows = 10;

type ContainerProps = {
  hasLeftBorder?: boolean;
};

type Props = {
  id?: string;
  columns: ColumnsType<Give>;
  dataSource: Give[];
  isLoading?: boolean;
  onChange?: (
    pagination: any,
    filters: any,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => void;
  onExportExcelClick?: () => void;
  title: string;
  subtitle?: string;
  hasLeftBorder?: boolean;
} & ContainerProps;

function TaxTable(props: Props) {
  const {
    id,
    columns,
    dataSource = [],
    isLoading = true,
    onChange,
    onExportExcelClick,
    title,
    subtitle,
    hasLeftBorder,
  } = props;

  const [isTablePartiallyRendered, setIsTablePartiallyRendered] =
    useState<boolean>(true);

  const shouldShowViewAllGivesButton =
    dataSource.length > initialRows && isTablePartiallyRendered;

  const gives = isTablePartiallyRendered
    ? take(dataSource, initialRows)
    : dataSource;

  function handleViewAll() {
    setIsTablePartiallyRendered(false);
  }

  const renderExportButton = !isLoading && (
    <ExportButton onClick={onExportExcelClick} />
  );

  const renderViewAllGives = shouldShowViewAllGivesButton && (
    <ViewAllGivesButton
      data-cy="view-all-gives-btn"
      type="link"
      onClick={handleViewAll}
    >
      View all gives
    </ViewAllGivesButton>
  );

  const renderTableSummary = (data: readonly Give[]) => {
    const total = calculateTotalGiveAmount(data as Give[]);

    return (
      <AntdTable.Summary.Row>
        <AntdTable.Summary.Cell index={1}>
          <Text type="body1reg2">Total</Text>
        </AntdTable.Summary.Cell>
        <AntdTable.Summary.Cell index={2} />
        <AntdTable.Summary.Cell align="right" index={3}>
          <Text data-cy="total-amount" type="h4med2">
            {formatToCurrency(total)}
          </Text>
        </AntdTable.Summary.Cell>
      </AntdTable.Summary.Row>
    );
  };

  return (
    <TableContainer data-cy={id} hasLeftBorder={hasLeftBorder} id={id}>
      <TableHeader>
        <div>
          <Text type="h3med2">{title}</Text>
          {subtitle && <SubtitleText type="body1reg2">{subtitle}</SubtitleText>}
        </div>
        {renderExportButton}
      </TableHeader>
      <StyledLoader isLoading={isLoading}>
        <StyledTable
          isV2
          columns={columns}
          dataSource={gives}
          pagination={false}
          summary={renderTableSummary}
          onChange={onChange}
        />
      </StyledLoader>
      <TableFooter>
        {renderViewAllGives}
        {renderExportButton}
      </TableFooter>
    </TableContainer>
  );
}

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.size.laptop}) {
    gap: 14px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SubtitleText = styled(Text)`
  color: ${(props) => props.theme.colors.lightEmphasis2};
`;

const StyledTable = styled(Table)`
  margin-top: 24px;
  width: 100%;
  overflow: auto;
`;

const TableFooter = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;

  @media (max-width: ${({ theme }) => theme.size.laptop}) {
    justify-content: flex-start;
  }
`;

const TableContainer = styled(Container)<ContainerProps>`
  padding: 24px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.darkSurface2};
  border-left: ${(props) =>
    props.hasLeftBorder ? `2px solid ${props.theme.colors.warning}` : ''};
`;

const StyledLoader = styled(LoadingCard)`
  height: 500px;
`;

const ViewAllGivesButton = styled(Button)`
  margin-right: auto;
`;

export default TaxTable;
