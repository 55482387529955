import styled from 'styled-components';

import { FlexCenter, Flex, RadioButtonGroup } from 'src/client/components';

export const ContentContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;

  @media (${(props) => props.theme.screen.md}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (${(props) => props.theme.screen.xl}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const Container = styled.div`
  display: flex;
  margin-top: 66px;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  row-gap: 60px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 8px;

  & > :first-child {
    padding-right: 0;
    margin-bottom: 24px;
  }

  @media ${(props) => props.theme.screen.lg} {
    flex-direction: row;
    justify-content: space-between;

    & > :first-child {
      width: 60%;
      margin-bottom: 0;
    }

    & > :last-child {
      width: max-content;
      align-self: center;
    }
  }

  @media ${(props) => props.theme.device.laptopL} {
    & > :first-child {
      width: auto;
    }
  }
`;

export const SpinContainer = styled(FlexCenter)`
  height: 700px;
`;

export const StyledButtonGroup = styled(RadioButtonGroup)`
  margin-right: 86px;

  @media ${(props) => props.theme.device.desktopL} {
    margin-right: 0;
  }
`;

export const StyledFlex = styled(Flex)`
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;
`;
