import React from 'react';

import GridOptionBase from 'src/client/pages/DonorGallery/components/IntroSection/components/GridOption';
import { GridOptionType, GalleryColor } from 'src/commons/constants/gallery';

import { Page } from 'src/commons/types';

import * as stylesheet from './styles';

type Props = {
  children?: React.ReactNode;
  color?: GalleryColor;
  onClick?: (value: string) => void;
  isSelected: boolean;
  type: GridOptionType;
  shape?: Page['galleryShape'];
};

function GridOption(props: Props) {
  return <GridOptionBase {...props} styles={stylesheet} />;
}

export default GridOption;
