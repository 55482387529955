import { useQuery } from 'react-query';

import { getAlternateEmails } from 'src/client/api/AlternateEmailApi';

const GET_ALTERNATE_EMAILS_KEY = 'GET_ALTERNATE_EMAILS_KEY';

export function useGetAlternateEmails(donorId: string) {
  return useQuery([GET_ALTERNATE_EMAILS_KEY, donorId], () =>
    getAlternateEmails(donorId)
  );
}
