import { Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Flex, Text } from 'src/client/components';
import routes from 'src/commons/constants/routes';

import * as S from './styles';

function Cta() {
  return (
    <S.Container>
      <Row justify="center">
        <Col md={24} xs={20}>
          <Flex
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <Text type="displaySBold2">Take your first step today</Text>
            <Box margin="8px 0 0 0" />
            <Text type="body1med2">
              Bring more remembrance, support and love to the good that you do.
            </Text>
            <Box margin="23px 0 0 0" />
            <S.EmailInputContainer>
              <S.EmailInput placeholder="Email" size="large" />
              <Link to={routes.DONOR_REGISTER}>
                <Button type="primary">Request early access</Button>
              </Link>
            </S.EmailInputContainer>
          </Flex>
        </Col>
      </Row>
    </S.Container>
  );
}

export default Cta;
