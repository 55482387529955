import { rem } from 'polished';
import styled from 'styled-components';

import { EditLabel } from '../../styles';

export const Container = styled.div`
  position: relative;
`;

export const StyledEditLabel = styled(EditLabel)`
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.darkEmphasis1} !important;
`;

export const DescriptionTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamilies.sourceSerif};
  color: ${(props) => props.theme.colors.black};
  font-size: ${rem('24px')};
  line-height: ${rem('34px')};
  font-weight: ${(props) => props.theme.fontWeights.semiBold};

  @media ${(props) => props.theme.screen.md} {
    font-size: ${rem('40px')};
    line-height: ${rem('56px')};
  }
`;
