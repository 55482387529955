import styled, { css } from 'styled-components';

type ContainerProps = {
  isBigScreen: boolean;
};

export const Container = styled.div<ContainerProps>`
  .slick-slide.slick-active.slick-center {
    ${(props) =>
      props.isBigScreen &&
      css`
        position: relative;
        transform: scale(1.35);
        z-index: 2;
      `}
  }

  .slick-slide.slick-active:not(.slick-current) {
    opacity: 0.6;
  }

  .slick-slide.slick-active.slick-center > div > div > div {
    box-shadow: ${(props) => props.theme.shadows.shadow3};
  }
  .slick-slide.slick-active.slick-center p {
    display: block;
  }
`;

export const ArrowButton = styled.button`
  height: 44px;
  width: 44px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.darkLowEmphasis};
  opacity: 0.4;
  border-radius: 100px;

  z-index: 1;

  &:hover,
  &:focus,
  &:focus:active {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.darkLowEmphasis};
    opacity: 0.4;
  }

  &.slick-prev {
    top: 45%;
    left: -24px;

    @media ${(props) => props.theme.device.tablet} {
      left: -70px;
    }
  }

  &.slick-next {
    top: 45%;
    right: -24px;

    @media ${(props) => props.theme.device.tablet} {
      right: -70px;
    }
  }

  svg {
    font-size: 30px;
  }

  &:before {
    content: none;
  }

  @media (${(props) => props.theme.screen.md}) {
    height: 50px;
    width: 50px;
    background-color: ${(props) => props.theme.colors.lightLowEmphasis};
    color: ${(props) => props.theme.colors.white};
    opacity: 0.8;

    &:hover,
    &:focus,
    &:focus:active {
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.lightLowEmphasis};
      opacity: 0.8;
    }
  }
`;
