import { MenuOutlined } from '@ant-design/icons';
import styled from 'styled-components';

type ContainerProps = {
  isPositionFixed: boolean;
};

const containerZIndex = 99;

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  position: ${(props) => (props.isPositionFixed ? 'fixed' : 'relative')};
  visibility: ${(props) => (props.isPositionFixed ? 'visible' : 'hidden')};
  padding: 13px 16px;
  left: 0;
  right: 0;
  top: 0;
  z-index: ${(props) => (props.isPositionFixed ? containerZIndex : undefined)};
  background-color: ${(props) => props.theme.colors.midnight7};

  .ant-divider-horizontal {
    margin: 0;
  }
`;

export const Logo = styled.img`
  height: 22px;
  cursor: pointer;
`;

export const StyledMenuOutlined = styled(MenuOutlined)`
  margin-right: 20px;
  width: 19px;
  color: white;
`;

export const YearFilterContainer = styled.div`
  flex: 1;
  margin: 16px 0 0;

  .ant-select {
    width: 100%;
  }
`;
