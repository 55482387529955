import { Button } from 'antd';
import styled from 'styled-components';

import { BUTTON_1_2 } from 'src/client/constants/text';

export const BaseButton = styled(Button)`
  padding: 8px 12px;
  height: fit-content;
  border: none;
  border-radius: 8px;
  box-shadow: none;
  transition: background-color 0s;
  transition: transform 0.2s;

  & > .anticon + span,
  & > span + .anticon {
    margin-left: 11px;
  }

  [disabled] {
    background: ${(props) => props.theme.colors.surfaceDisabled};
    color: ${(props) => props.theme.colors.darkEmphasis3};
  }

  span {
    ${BUTTON_1_2};
  }

  &:focus {
    outline: 1px solid ${(props) => props.theme.colors.cyan400};
    outline-offset: 3px;
    box-shadow: none;
  }

  :hover:not([disabled]) {
    transform: translate(0px, -4px);
  }

  &.ant-btn-sm {
    padding: 4.5px 4px;
  }

  &.ant-btn-lg {
    padding: 11px 12px;
  }
`;

export const Primary = styled(BaseButton)`
  background: ${(props) => props.theme.colors.darkPrimary100};
  color: ${(props) => props.theme.colors.black};

  :hover,
  &:focus,
  &:active {
    background: ${(props) => props.theme.colors.darkPrimary100};
    color: ${(props) => props.theme.colors.black};
  }

  :hover {
    background: #9ffdff;
  }
`;

export const Secondary = styled(BaseButton)`
  background: ${(props) => props.theme.colors.gray4};
  color: ${(props) => props.theme.colors.black};
  box-shadow: none;

  :hover,
  &:focus,
  &:active {
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.darkEmphasis1};
  }

  :hover {
    background: ${(props) => props.theme.colors.gray3};
  }
`;

export const Destructive = styled(BaseButton)`
  background: ${(props) => props.theme.colors.red1};
  color: ${(props) => props.theme.colors.red6};

  :hover,
  &:focus,
  &:active {
    background: ${(props) => props.theme.colors.red1};
    color: ${(props) => props.theme.colors.red6};
  }
`

export const LinkUnderline = styled.div`
  transition: width 0.2s;
  position: absolute;
  width: 0%;
  top: 0;
  left: 0;
  bottom: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.cyan400};
`;

export const Link = styled(BaseButton)`
  position: relative;
  padding: 8px 0;
  background: transparent;
  color: ${(props) => props.theme.colors.darkPrimary100};

  &[disabled],
  &[disabled]:hover {
    background: transparent;
    color: ${(props) => props.theme.colors.lightEmphasis3};
  }

  :hover:not([disabled]) {
    transform: initial;
  }

  :hover:not([disabled]) ${LinkUnderline} {
    width: 100%;
  }

  :hover,
  &:focus,
  &:active {
    background: transparent;
    color: ${(props) => props.theme.colors.darkPrimary100};
  }

  &.ant-btn-sm {
    padding: 4.5px 0;
  }

  &.ant-btn-lg {
    padding: 11px 0;
  }
`;

export const Variant1 = styled(BaseButton)`
  &&:not([disabled]) {
    color: ${(props) => props.theme.colors.black};
  }

  :hover {
    background: ${(props) => props.theme.colors.gray3};
  }
`;

export const NoStyle = styled(BaseButton)`
  background: transparent;
  color: initial;
  padding: none;

  :hover,
  &:focus,
  &:active {
    background: transparent;
    outline: none;
  }

  :hover:not([disabled]) {
    transform: initial;
  }

  &.ant-btn-sm {
    padding: initial;
  }

  &.ant-btn-lg {
    padding: initial;
  }
`;

export const Variant2 = styled(BaseButton)`
  background: ${(props) => props.theme.colors.darkSurface3};
  color: ${(props) => props.theme.colors.white};

  :hover,
  &:focus,
  &:active {
    background: ${(props) => props.theme.colors.darkSurface3};
    color: ${(props) => props.theme.colors.white};
  }

  :hover {
    background: ${(props) => props.theme.colors.midnight4};
  }
`;

export const ElevatedBaseButton = styled(BaseButton)`
  transition: none;
  color: ${(props) => props.theme.colors.white};

  :hover,
  :active,
  :focus {
    color: ${(props) => props.theme.colors.white};
  }

  :hover:not([disabled]) {
    transform: translate(0px, 1px);
  }

  :active {
    box-shadow: none;
  }

  :focus {
    outline: 1px solid ${(props) => props.theme.colors.gray5};
    outline-offset: 4px;
    box-shadow: none;
  }

  & > .anticon + span,
  & > span + .anticon {
    margin-left: 11px;
  }
`;

export const Elevated1 = styled(ElevatedBaseButton)`
  background: ${(props) => props.theme.colors.magenta500};
  box-shadow: 0px 4px 0px #a4138e;

  :hover,
  :active,
  :focus {
    background: ${(props) => props.theme.colors.magenta300};
  }

  :hover {
    box-shadow: 0px 3px 0px #a4138e;
  }
`;

export const Elevated2 = styled(ElevatedBaseButton)`
  background: ${(props) => props.theme.colors.green500};
  box-shadow: 0px 4px 0px #59931a;

  :hover,
  :active,
  :focus {
    background: ${(props) => props.theme.colors.green300};
  }

  :hover {
    box-shadow: 0px 3px 0px #59931a;
  }
`;

export const Elevated3 = styled(ElevatedBaseButton)`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.lightHighEmphasis};
  box-shadow: 0px 4px 0px #bfbebe;
  border: 1px solid ${(props) => props.theme.colors.gray4} !important;

  :hover,
  :active,
  :focus {
    background: ${(props) => props.theme.colors.gray3};
    color: ${(props) => props.theme.colors.darkEmphasis1};
  }

  :hover {
    box-shadow: 0px 3px 0px #bfbebe;
  }
`;
