import styled from 'styled-components';

import { Button, Text } from 'src/client/components';

export const Container = styled.div`
  padding: 6rem;
`;

export const NavWrapper = styled.div`
  background: #11182c;
  padding: 11px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.img`
  height: 34px;
`;

export const StyledBackButton = styled(Button)`
  color: #ffffff;
`;

export const ArticleTitle = styled(Text)`
  margin-bottom: 3rem;
  font-size: 4rem;
  line-height: 7.7rem;
  font-family: 'Times-New-Roman';
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0.02em;
  color: #11182c;
  text-align: left;
`;

export const ArticleModificationDate = styled(Text)`
  margin-bottom: 3rem;
  font-size: 1.6rem;
  line-height: 170%;
  font-family: 'Arial';
  letter-spacing: 0.01em;
  text-align: left;
  font-style: italic;
  font-weight: normal;
`;

export const ArticleContentH1 = styled(Text)`
  margin-bottom: 3rem;
  font-size: 2rem;
  line-height: 170%;
  font-family: 'Times-New-Roman';
  letter-spacing: 0.01em;
  text-align: left;
  font-style: normal;
  font-weight: bold;
`;

export const ArticleContentP = styled.p`
  margin-bottom: 3rem;
  font-size: 1.6rem;
  line-height: 170%;
  font-family: 'Arial';
  letter-spacing: 0.01em;
  text-align: left;
  font-style: normal;
  font-weight: normal;
`;

export const ArticleContentAddressP = styled.p`
  margin-bottom: 1rem;
  font-size: 1.6rem;
  line-height: 170%;
  font-family: 'Arial';
  letter-spacing: 0.01em;
  text-align: left;
  font-style: normal;
  font-weight: normal;
`;

export const ArticleFooter = styled.p`
  font-size: 1.5rem;
  line-height: 170%;
  font-family: 'Arial';
  letter-spacing: 0.01em;
  text-align: left;
  font-style: italic;
  font-weight: normal;
`;

export const FooterLink = styled.a`
  font-size: 1.5rem;
  line-height: 100%;
  font-family: 'Arial';
  letter-spacing: 0.01em;
  text-align: left;
  font-style: italic;
  font-weight: normal;
`;
