import { useQuery } from 'react-query';

import { getPlatformStat } from 'src/client/api/PlatformStatApi';

const GET_PLATFORM_STAT_KEY = 'GET_PLATFORM_STAT_KEY';

export function useGetPlatformStat(platformId: string) {
  return useQuery([GET_PLATFORM_STAT_KEY, { platformId }], () =>
    getPlatformStat(platformId)
  );
}
