import React from 'react';

import GridOptionPickerBase from 'src/client/pages/DonorGallery/components/IntroSection/components/GridOptionPicker';
import {
  GalleryColor,
  GalleryShape,
  GridOptionType,
} from 'src/commons/constants/gallery';

import * as stylesheet from './styles';

type Props = {
  colors?: GalleryColor[];
  type: GridOptionType;
  selectedColor: GalleryColor;
  selectedShape?: GalleryShape;
  onChange?: (value: string) => void;
};

function GridOptionPicker(props: Props) {
  return <GridOptionPickerBase {...props} styles={stylesheet} />;
}

export default GridOptionPicker;
