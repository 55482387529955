import { Col, Row } from 'antd';
import React from 'react';

import { Text } from 'src/client/components';

import * as S from './styles';

function Features() {
  return (
    <S.FeaturesBody>
      <S.SlantBg src="/slantBg.png" />
      <S.FeaturesBg>
        <Row justify="center">
          <Col md={24} xs={20}>
            <S.FeaturesContainer>
              <S.FeatureContainer>
                <S.FeatureImg src="/trackAndRememberYourGiving.png" />
                <S.FeatureDescriptionContainer>
                  <Text type="h1med2">Track and remember</Text>
                  <Text type="body1med2">
                    You give in so many different ways -charitable, political,
                    crowdfunding, volunteering, etc. You give through so many
                    different platforms - actblue, gofundme, venmo, etc.
                    <br />
                    <br />
                    For the first time, Giving Side invites you to bring
                    together all the ways you do good.
                  </Text>
                </S.FeatureDescriptionContainer>
              </S.FeatureContainer>
              <S.FeatureContainer>
                <S.FeatureImg src="/honorAndCelebrateYourGiving.png" />
                <S.FeatureDescriptionContainer>
                  <Text type="h1med2">Honor and celebrate</Text>
                  <Text type="body1med2">
                    Your giving is a proxy for the things you care about - your
                    friends and family, causes you’re passionate about, and
                    organizations and communities you support.
                    <br />
                    <br />
                    We designed everything to help you honor and celebrate the
                    relationships that inspire your giving side.
                  </Text>
                </S.FeatureDescriptionContainer>
              </S.FeatureContainer>
              <S.FeatureContainer>
                <S.FeatureImg src="/discoverAndLearnAboutYourGiving.png" />
                <S.FeatureDescriptionContainer>
                  <Text type="h1med2">Discover and learn</Text>
                  <Text type="body1med2">
                    Your Giving Side is so much more than a random set of
                    individual transactions - there’s patterns, trends,
                    momentum, relationships, and a practice to be found in the
                    good you do.
                    <br />
                    <br />
                    Giving Side provides a brand new and free way to discover,
                    see, and practice it all.
                  </Text>
                </S.FeatureDescriptionContainer>
              </S.FeatureContainer>
            </S.FeaturesContainer>
          </Col>
        </Row>
      </S.FeaturesBg>
    </S.FeaturesBody>
  );
}

export default Features;
