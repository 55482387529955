import React from 'react';

import { Text } from 'src/client/components';
import { EMAIL_QUOTES } from 'src/commons/constants/quotes';

import * as S from './styles';

export type QuoteObject = { quote: string; author: string };

type Props = {
  open: boolean;
  onClose: () => void;
  onSelectQuote: (obj: QuoteObject) => void;
  selectedQuote: QuoteObject;
};

function QuotesDrawer(props: Props) {
  const { open, onClose, onSelectQuote, selectedQuote } = props;
  const { quote, author: quoteAuthor } = selectedQuote;

  return (
    <S.StyledDrawer open={open} title={<DrawerHeader />} onClose={onClose}>
      <S.DrawerContentContainer data-cy="curated-quotes-drawer">
        {EMAIL_QUOTES.map((item) => (
          <S.QuoteItemContainer
            isQuoteMatch={item.message === quote && item.author === quoteAuthor}
            key={`${item.author}-${item.message}`}
            onClick={() =>
              onSelectQuote({
                author: item.author,
                quote: item.message,
              })
            }
            {...item}
          >
            <Text type="body1med2">{item.message}</Text>
            <Text type="body2reg2">{`- ${item.author}`}</Text>
            {item.message === quote && item.author === quoteAuthor && (
              <S.QuoteItemSelectedIcon />
            )}
          </S.QuoteItemContainer>
        ))}
      </S.DrawerContentContainer>
    </S.StyledDrawer>
  );
}

function DrawerHeader() {
  return (
    <>
      <Text type="h4bold2">Curated Quotes</Text>
      <Text type="body2med2">
        Choose a quote that resonates with your giving side
      </Text>
    </>
  );
}

export default QuotesDrawer;
