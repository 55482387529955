import styled from 'styled-components';

import { Button } from 'src/client/components';
import { H3_MED_2 } from 'src/client/constants/text';

type NavigationButtonProps = {
  isVisible?: boolean;
};

const NavigationButton = styled(Button)<NavigationButtonProps>`
  visibility: ${(props) => (props.isVisible === false ? 'hidden' : 'visible')};
  display: flex;
  align-items: center;

  :hover {
    background: white;
  }

  && span {
    ${H3_MED_2};
    color: ${(props) => props.theme.colors.darkEmphasis1};
  }
`;

export const PrevGiveButton = styled(NavigationButton)`
  && {
    padding-left: 0;
  }

  && img {
    margin-right: 12px;
  }
`;

export const NextGiveButton = styled(NavigationButton)`
  && {
    padding-left: 0;
  }

  && img {
    margin-left: 12px;
    transform: rotate(180deg);
  }
`;
