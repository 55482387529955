import { rem } from 'polished';
import styled from 'styled-components';

import { Flex, Text } from 'src/client/components';

type ContainerProps = {
  isPreview?: boolean;
};

type Props = {
  breakpoint?: string;
};

export const Container = styled.footer<ContainerProps>`
  position: relative;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
`;

export const ContentContainer = styled.div`
  padding: 20px;
  background-color: ${(props) => props.theme.colors.midnight7};

  @media (${(props) => props.theme.device.tablet}) {
    padding: 80px 48px 20px;
  }

  @media (${(props) => props.theme.device.laptop}) {
    padding: 140px 96px 20px;
  }
`;

export const TriangleImage = styled.img`
  width: 100%;
  user-select: none;
`;

export const StyledText = styled(Text)<Props>`
  max-width: ${(props) => props.breakpoint};
  text-align: center;
`;

export const SendEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 388px;
  row-gap: 48px;

  button {
    padding: 20px 16px !important;
    color: ${(props) => props.theme.colors.midnight7};
    border-radius: 8px;
  }
`;

export const FooterNavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
  width: 100%;

  @media (${(props) => props.theme.screen.sm}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Logo = styled.img`
  height: 22px;
  cursor: pointer;
`;

export const FlexChild = styled(Flex)`
  column-gap: 8px;
  align-items: center;

  & > p {
    font-size: ${rem('12px')};
    line-height: ${rem('16px')};
  }

  @media (${(props) => props.theme.screen.md}) {
    column-gap: 41px;

    & > p {
      font-size: ${rem('16px')};
      line-height: ${rem('24px')};
    }
  }
`;
