import { Tabs } from 'antd';
import { rem } from 'polished';
import styled from 'styled-components';

import { Flex } from 'src/client/components';
import { BUTTON_1_2 } from 'src/client/constants/text';

type WidthLimitProps = {
  isRelative?: boolean;
};

export const WidthLimit = styled.div<WidthLimitProps>`
  position: ${(props) => (props.isRelative ? 'relative' : 'initial')};
  margin: auto;
  max-width: 1440px;
`;

export const FirstSection = styled.div`
  position: relative;
  height: 705px;
  width: 100%;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.darkHighEmphasis};
`;

export const RadialGradient1 = styled.img`
  position: absolute;
  top: -50px;
  left: 50px;
`;

export const RadialGradient2 = styled.img`
  position: absolute;
  top: 20px;
  right: 205px;
`;

export const Logo = styled.img`
  width: 181px;
`;

export const HeaderContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
`;

export const StyledTabs = styled(Tabs)`
  margin-top: -64px;

  .ant-tabs-tab {
    padding-bottom: 0;
  }

  .ant-tabs-tab-btn {
    padding-bottom: 8px;
    color: ${(props) => props.theme.colors.white};

    p {
      ${BUTTON_1_2}
      font-size: ${rem('12px')};
      line-height: ${rem('16px')};
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.teal1};
    border-bottom: 2px solid;
    border-color: ${(props) => props.theme.colors.teal1};
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-nav::before {
    border-bottom: 1px solid ${(props) => props.theme.colors.gray2};
  }

  .ant-tabs-nav {
    margin: 0;
  }

  @media ${(props) => props.theme.screen.md} {
    .ant-tabs-tab-btn {
      padding-bottom: 12px;

      p {
        ${BUTTON_1_2}
      }
    }
  }
`;

export const RecipientLogo = styled.img`
  margin-right: 24px;
  height: 40px;
  width: 80px;
  border-radius: 8px;
`;
