import styled from 'styled-components';

import { Flex } from 'src/client/components/StyledCommon';

export const Container = styled(Flex)`
  padding: 45px 0 0 0;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .ant-input-affix-wrapper {
    width: 172px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: left;
`;

export const Line = styled.hr`
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.colors.lightLowEmphasis};
`;

export const GivingGoalInputContainer = styled.div`
  display: flex;
  margin-bottom: 19px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;
