import { CityState } from 'src/commons/types/Location';

import ApiClient from './ApiClient';

const apiVersion = '1.0';
const endpoint = 'location';
const url = `${apiVersion}/${endpoint}`;

export async function getCityStateByZipCode(
  zipCode: number
): Promise<CityState> {
  const { data } = await ApiClient.get<CityState>(
    `${url}/city-state/${zipCode}`
  );

  return data;
}
