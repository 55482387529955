import styled, { createGlobalStyle } from 'styled-components';

import { Button, Flex } from 'src/client/components';
import { BODY_1_REG_2 } from 'src/client/constants/text';

type BottomContainerProps = {
  shouldShowSuccessContent: boolean;
};

export const GlobalStyle = createGlobalStyle`
  .ant-popover-arrow-content::before {
    background: ${(props) => props.theme.colors.darkSurface1};
  }
`;

export const PrivacyPopoverContent = styled.div`
  color: ${(props) => props.theme.colors.white};
`;

export const PrivacyConfirmButton = styled(Button)`
  padding: 12px;
  width: 100%;
`;

export const Divider = styled.hr`
  margin: 12px 0;
  border: none;
  border-top: 1px solid ${(props) => props.theme.colors.lightSurface20};
`;

export const TextButton = styled(Button)`
  &&,
  &&:hover {
    padding: 11px 8px;
    background: transparent;
    color: ${(props) => props.theme.colors.white};
    border: none;
  }

  span {
    margin-left: 7px !important;
  }
`;

export const SuccessActionButtonsContainer = styled(Flex)`
  justify-content: space-between;
  gap: 8px;

  button {
    padding: 8px;
  }
`;

export const PublicLinkDisplay = styled.div`
  padding: 8px;
  flex-grow: 1;
  text-align: left;
  background: ${(props) => props.theme.colors.darkSurface3};
  border-radius: 8px;
  overflow: auto;
  ${BODY_1_REG_2};

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.lightSurface20};
    border-radius: 6px;
  }

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
`;

export const BottomContainer = styled(Flex)<BottomContainerProps>`
  justify-content: ${(props) =>
    props.shouldShowSuccessContent ? 'space-between' : 'center'};
`;
