import {
  LEGACY_RECIPIENT_TYPES,
  LegacyRecipientType,
  PageRecipient,
  PageRecipientVisibility,
  RECIPIENT_TYPE,
  ValidRecipientType,
} from '../types';

export function filterByVisibility(visibility: PageRecipientVisibility) {
  return (pageRecipient: PageRecipient) =>
    pageRecipient.visibility === visibility;
}

export function isValidRecipientType(
  value: string
): value is ValidRecipientType {
  return (
    Object.values(RECIPIENT_TYPE).includes(value as RECIPIENT_TYPE) ||
    LEGACY_RECIPIENT_TYPES.includes(value as LegacyRecipientType)
  );
}
