import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Flex, Text, Title } from 'src/client/components';
import AdminLayout from 'src/client/layouts/AdminLayout';
import routes from 'src/commons/constants/routes';

import RecipientsTable from './components/RecipientsTable';

function AdminRecipients() {
  return (
    <>
      <Title title="Recipients - Admin" />
      <AdminLayout>
        <Box padding="71px 105px">
          <Box margin="0 0 28px 0">
            <Flex alignItems="center" gap="24px" margin="0 0 32px 0">
              <Text type="displayMMed2">Recipients</Text>
              <Link to={routes.ADMIN_ADD_RECIPIENTS}>
                <Button type="primary">
                  <PlusOutlined />
                  Recipient
                </Button>
              </Link>
            </Flex>
          </Box>
          <RecipientsTable />
        </Box>
      </AdminLayout>
    </>
  );
}

export default AdminRecipients;
