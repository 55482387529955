import { Modal } from 'antd';
import styled from 'styled-components';

import { Button } from 'src/client/components';
import { BUTTON_1_2 } from 'src/client/constants/text';

export const MergeAccountsButton = styled(Button)`
  && {
    background: ${(props) => props.theme.colors.darkSurface3_08};
    color: ${(props) => props.theme.colors.darkEmphasis1};
  }
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    overflow: hidden;
  }

  .cancel-button {
    padding: 16px 20px;
    background: transparent;
    border: none;
    color: ${(props) => props.theme.colors.darkEmphasis1};
    height: fit-content;

    span {
      ${BUTTON_1_2};
    }
  }

  .ok-button {
    padding: 16px 20px;
    background: ${(props) => props.theme.colors.darkPrimary100};
    border: none;
    color: ${(props) => props.theme.colors.darkEmphasis1};
    border-radius: 8px;
    height: fit-content;

    span {
      ${BUTTON_1_2};
    }
  }
`;
