import { Layout } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useMatchMenuItem } from 'src/client/hooks/useMatchMenuItem';

import { MenuSiderConfig } from '../AdminSider';

import { StyledText } from '../Text/styles';

import * as S from './styles';

type Props = React.ComponentProps<typeof Layout.Sider> & {
  menuItemsConfig?: MenuSiderConfig[];
};

function DonorSider(props: Props) {
  const { menuItemsConfig } = props;

  const history = useHistory();
  const { getMatchedMenuItem } = useMatchMenuItem();
  const screens = useBreakpoint();

  function handleMenuItemClick(evt: any) {
    history.push(evt.key);
  }

  const menuItems = menuItemsConfig
    ? menuItemsConfig.map((menuItemConfig) => (
        <S.StyledMenuItem
          data-testid={menuItemConfig.name}
          icon={<S.IconWrapper>{menuItemConfig.icon}</S.IconWrapper>}
          key={menuItemConfig.route}
        >
          <StyledText isInline type="body2Regular">
            {menuItemConfig.name}
          </StyledText>
        </S.StyledMenuItem>
      ))
    : [];

  const matchedMenuItem = getMatchedMenuItem(menuItemsConfig);
  const selectedKeys = matchedMenuItem ? [matchedMenuItem] : undefined;

  return (
    <S.StyledSider
      collapsible
      collapsedWidth={screens.lg ? '50' : '0'}
      theme={'light' as any}
      trigger={null}
      {...props}
    >
      <S.MenuStyle
        selectedKeys={selectedKeys}
        onClick={handleMenuItemClick as any}
      >
        {menuItems}
      </S.MenuStyle>
    </S.StyledSider>
  );
}

export default DonorSider;
