import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import styled from 'styled-components';

import Text from 'src/client/components/Text';

export const StyledExclamationCircleFilled = styled(ExclamationCircleFilled)`
  margin-right: 8px;
  svg { 
    fill: ${(props) => props.theme.colors.ExclamationCircleFilledColor};
    }
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 572px;
    border-radius: 8px;
    overflow: hidden;
    transform: translateY(40%);
  }
  .ant-modal-body {
    height: 100%;
  }
  .ant-btn {
    border: none;
  }
  .ant-btn.ant-btn-primary {
    background-color: ${(props) => props.theme.colors.teal2};
    border-color: ${(props) => props.theme.colors.teal2};
    height: 50px;
    width: 147px;
    border-radius: 8px;
    padding: 15px 16px 13px 16px;
  }
`;

export const WarningText = styled(Text)`
  background: ${(props) => props.theme.colors.cornSilk};
  border: 1px solid ${(props) => props.theme.colors.yellow};
  border-radius: 2px;
  padding: 8px 16px 8px 16px;
`;
