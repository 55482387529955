import { QueryClient, useQuery } from 'react-query';

import {
  getRecipientLogo,
  getPlatformImage,
  getRecipientsLogo,
  getPlatformsLogo,
  getRecipientImages,
} from 'src/client/api/ImageApi';

type CommonOptions = {
  enabled: boolean;
};

const GET_RECIPIENT_LOGO_KEY = 'GET_RECIPIENT_LOGO_KEY';
const GET_RECIPIENT_IMAGES_KEY = 'GET_RECIPIENT_IMAGES_KEY';
const GET_RECIPIENTS_LOGO_KEY = 'GET_RECIPIENTS_LOGO_KEY';
const GET_PLATFORM_IMAGE_KEY = 'GET_PLATFORM_IMAGE_KEY';
const GET_PLATFORMS_IMAGE_KEY = 'GET_PLATFORMS_IMAGE_KEY';

export function useGetRecipientLogo(
  recipientId: string | undefined | null,
  options?: CommonOptions
) {
  return useQuery(
    [GET_RECIPIENT_LOGO_KEY, { recipientId }],
    () => getRecipientLogo(recipientId as string),
    {
      enabled: !!recipientId,
      ...options,
    }
  );
}

export function resetGetRecipientLogo(
  recipientId: string,
  queryClient: QueryClient
) {
  return queryClient.removeQueries([GET_RECIPIENT_LOGO_KEY, { recipientId }]);
}

export function useGetRecipientImages(
  recipientId: string | undefined | null,
  options?: CommonOptions
) {
  return useQuery(
    [GET_RECIPIENT_IMAGES_KEY, { recipientId }],
    () => getRecipientImages(recipientId as string),
    {
      enabled: !!recipientId,
      ...options,
    }
  );
}

export function resetGetRecipientImages(
  recipientId: string,
  queryClient: QueryClient
) {
  return queryClient.removeQueries([GET_RECIPIENT_IMAGES_KEY, { recipientId }]);
}

export function updateRecipientLogoQueryData(
  queryClient: QueryClient,
  recipientId: string,
  imagePath: string
) {
  queryClient.setQueryData([GET_RECIPIENT_LOGO_KEY, { recipientId }], (old) => {
    if (!old) {
      return undefined;
    }

    return {
      recipientLogoImagePath: imagePath,
    };
  });
}

export function useGetRecipientsLogo(
  recipientIds: string[] | undefined,
  options?: CommonOptions
) {
  return useQuery(
    [GET_RECIPIENTS_LOGO_KEY, { recipientIds }],
    () => getRecipientsLogo(recipientIds as string[]),
    {
      enabled: !!recipientIds?.length,
      ...options,
    }
  );
}

export function useGetPlatformsLogo(
  platformIds: string[] | undefined,
  options?: CommonOptions
) {
  return useQuery(
    [GET_PLATFORMS_IMAGE_KEY, { recipientIds: platformIds }],
    () => getPlatformsLogo(platformIds as string[]),
    {
      enabled: !!platformIds,
      ...options,
    }
  );
}

export function useGetPlatformImage(
  platformId: string | undefined,
  options?: CommonOptions
) {
  return useQuery(
    [GET_PLATFORM_IMAGE_KEY, { platformId }],
    () => getPlatformImage(platformId as string),
    {
      enabled: !!platformId,
      ...options,
    }
  );
}
