import { FileTextOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';

import { Flex, Text } from 'src/client/components';
import { RESOURCES } from 'src/client/constants/tax';
import { BUTTON_1_2 } from 'src/client/constants/text';

import { CardsContainer } from '../styles';

function Resources() {
  const renderItems = RESOURCES.map(({ link, text }, i) => (
    <CardContainer key={i}>
      <FileTextOutlined />
      <Flex flexDirection="column">
        <Text type="h5med2">{text}</Text>
        <Link href={link} rel="noopener noreferrer" target="_blank">
          Read more
        </Link>
      </Flex>
    </CardContainer>
  ));

  return (
    <div>
      <Text type="h3med2">Resources</Text>
      <CardsContainer>{renderItems}</CardsContainer>
    </div>
  );
}

const CardContainer = styled.div`
  padding: 24px;
  width: 100%;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.darkSurface2};
  display: flex;
  align-items: flex-start;
  height: auto;

  svg {
    height: 35px;
    width: 27px;
    margin-right: 21px;
  }

  @media ${(props) => props.theme.device.laptop} {
    width: 389px;
  }
`;

const Link = styled.a`
  margin-top: 9px;
  color: ${(props) => props.theme.colors.darkPrimary100};

  ${BUTTON_1_2};
`;

export default Resources;
