import { useState } from 'react';

import { Goal } from 'src/commons/types';

import {
  calculatePercentage,
  calculateRate,
} from 'src/commons/utils/MathUtils';

type GoalState = {
  incomeRate: number | null;
  income: number | null;
  goal: number | null;
};

const defaultIncomeRate = 0.02;
const defaultIncome = 40_000;
const defaultGoal = 800;

function useGivingGoalState(donorGoal: Goal | null | undefined) {
  const [goalState, setGoalState] = useState<GoalState>({
    incomeRate: null,
    income: donorGoal?.income ?? null,
    goal: donorGoal?.amount ?? null,
  });

  function setGoalStateWithFormatter(newState: GoalState) {
    setGoalState({
      ...newState,
      goal:
        typeof newState.goal === 'number'
          ? formatAmount(newState.goal)
          : newState.goal,
      income:
        typeof newState.income === 'number'
          ? formatAmount(newState.income)
          : newState.income,
      incomeRate:
        typeof newState.incomeRate === 'number'
          ? formatAmount(newState.incomeRate, true)
          : newState.incomeRate,
    });
  }

  function setNewGoal(newGoal: number | null) {
    setGoalStateWithFormatter({
      ...goalState,
      goal: newGoal,
    });
  }

  function calculateSuperGoal(incomeRate: number, income: number) {
    return calculatePercentage(incomeRate, income);
  }

  function adjustIncomeRate(newIncomeRate: number) {
    if (goalState.income === null) {
      setGoalStateWithFormatter({
        ...goalState,
        incomeRate: newIncomeRate,
        income: defaultIncome,
        goal: calculateSuperGoal(newIncomeRate, defaultIncome),
      });
    } else {
      setGoalStateWithFormatter({
        ...goalState,
        incomeRate: newIncomeRate,
        goal: calculateSuperGoal(newIncomeRate, goalState.income),
      });
    }
  }

  function adjustIncome(newIncome: number) {
    if (goalState.incomeRate === null) {
      setGoalStateWithFormatter({
        ...goalState,
        income: newIncome,
        incomeRate: defaultIncomeRate,
        goal: calculateSuperGoal(defaultIncomeRate, newIncome),
      });
    } else {
      setGoalStateWithFormatter({
        ...goalState,
        income: newIncome,
        goal: calculateSuperGoal(goalState.incomeRate, newIncome),
      });
    }
  }

  function adjustGivingGoal(newGivingGoal: number) {
    if (goalState.income === null) {
      setGoalStateWithFormatter({
        ...goalState,
        goal: newGivingGoal,
        incomeRate: calculateRate(defaultIncome, newGivingGoal),
        income: defaultIncome,
      });
    } else {
      setGoalStateWithFormatter({
        ...goalState,
        goal: newGivingGoal,
        incomeRate: calculateRate(goalState.income, newGivingGoal),
      });
    }
  }

  function setAllSuperGoalDataToDefault() {
    setGoalStateWithFormatter({
      ...goalState,
      goal: defaultGoal,
      income: defaultIncome,
      incomeRate: defaultIncomeRate,
    });
  }

  function startEditingSuperGoal() {
    const isGoalEmpty = goalState.goal === null;
    const isIncomeRateAndIncomeEmpty =
      goalState.incomeRate === null && goalState.income === null;

    if (isGoalEmpty) {
      setAllSuperGoalDataToDefault();
    } else if (isIncomeRateAndIncomeEmpty) {
      prefillIncomeAndIncomeRateBasedOnSavedGoal();
    } else {
      adjustIncomeRateBasedOnSavedGoalAndIncome();
    }
  }

  function prefillIncomeAndIncomeRateBasedOnSavedGoal() {
    if (!goalState?.goal) {
      return null;
    }

    setGoalStateWithFormatter({
      ...goalState,
      goal: goalState?.goal,
      income: defaultIncome,
      incomeRate: calculateRate(defaultIncome, goalState.goal),
    });
  }

  function adjustIncomeRateBasedOnSavedGoalAndIncome() {
    setGoalStateWithFormatter({
      ...goalState,
      income: goalState?.income as number,
      goal: goalState?.goal as number,
      incomeRate: calculateRate(
        goalState?.income as number,
        goalState?.goal as number
      ),
    });
  }

  return {
    startEditingSuperGoal,
    adjustIncome,
    adjustIncomeRate,
    adjustGivingGoal,
    setNewGoal,
    setGoalState: setGoalStateWithFormatter,
    goalState,
  };
}

function formatAmount(amount: number, isIncomeRate = false) {
  if (amount === Infinity) {
    return 0;
  }

  let fractionDigits = 2;

  if (isIncomeRate) {
    const incomeRateFractionDigits = 4;
    fractionDigits = incomeRateFractionDigits;
  }

  return parseFloat(amount.toFixed(fractionDigits));
}

export default useGivingGoalState;
