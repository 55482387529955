import { Modal, Tabs, Upload } from 'antd';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { BODY_2_MED_2 } from 'src/client/constants/text';

import Shape from '../Shape';

type GalleryModalProps = {
  activeTab: string;
};

export const StyledModal = styled(Modal)<GalleryModalProps>`
  .ant-modal-body {
    padding: 24px 24px 32px;
  }

  .ant-modal-content {
    border-radius: 8px;

    ${(props) =>
      props.activeTab === '2' &&
      css`
        max-width: 440px;
      `}

    .ant-modal-close-x {
      padding-top: 12px;
      padding-right: 8px;
    }
  }
`;

export const ButtonGroup = styled.div`
  height: 125px;
  display: flex;
  gap: 24px;
`;

export const StyledTabs = styled(Tabs)`
  display: flex;

  .ant-tabs-nav-list {
    white-space: nowrap;
  }

  .ant-tabs-tab-btn {
    ${BODY_2_MED_2};
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.black} !important;
  }

  .ant-tabs-ink-bar {
    background: ${(props) => props.theme.colors.black};
  }
`;

export const StyledUpload = styled(Upload)`
  width: 100%;
  cursor: pointer;

  .ant-upload {
    height: 100%;
    width: 100%;

    & > div {
      gap: 10px;
      height: 100%;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      border-radius: 6px;
      background: ${(props) => props.theme.colors.gray3};

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  .ant-upload-list-picture-card-container {
    cursor: pointer;
  }

  .ant-upload-list-picture-card
    .ant-upload-list-item:hover
    .ant-upload-list-item-info:before {
    opacity: 0;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  max-height: 100%;

  &:hover .overlay {
    opacity: 1;
  }
`;

export const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

export const ChooseShapeButton = styled.div`
  cursor: pointer;
  width: 100%;
  gap: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border-radius: 6px;
  background: ${(props) => props.theme.colors.gray3};
`;

export const BackHeading = styled.div`
  gap: 12px;

  display: flex;
  align-items: center;

  & > span {
    cursor: pointer;

    .anticon {
      margin-top: 2px;
      font-size: ${rem('20px')};
    }
  }
`;

export const SelectedShapeDisplay = styled(Shape)`
  display: flex;
  justify-content: center;

  svg {
    width: 103px;
    height: 103px;
  }
`;
