import { FormInstance, UploadFile } from 'antd';

import isNil from 'lodash/isNil';
import { nanoid } from 'nanoid';

import {
  DonorsFilterInput,
  GiveFilterInput,
  PlatformFilterInput,
  RecipientFilterInput,
} from 'src/commons/constants/filterInputs';
import { ImageWithUrl, Indexable } from 'src/commons/types';

import {
  FilterInputEnums,
  MultiFilterFormValue,
} from '../components/MultiFilter';

type CreateUploadFormItemValue = {
  url: string;
  fileName: string;
};

export function getTouchedFieldsValue<T>(form: FormInstance<T>): Partial<T> {
  return Object.fromEntries(
    Object.entries(form.getFieldsValue() as Indexable).filter(
      ([field, value]) => form.isFieldTouched(field)
    )
  ) as Partial<T>;
}

export function getFieldsWithValue<T>(form: FormInstance<T>): Partial<T> {
  return Object.fromEntries(
    Object.entries(form.getFieldsValue() as Indexable).filter(
      ([field, value]) => !isNil(value)
    )
  ) as Partial<T>;
}

export function getFormErrorList(form: FormInstance<any>) {
  return form
    .getFieldsError()
    .map((error) => error.errors)
    .flat();
}

export function handleGetValueFromUploadEvent(e: any) {
  if (Array.isArray(e)) {
    return e;
  }

  return e?.fileList;
}

export function setAllFieldsTouchedToFalse(form: FormInstance<any>) {
  Object.entries(form.getFieldsValue() as Indexable).forEach(
    ([field, value]) => {
      form.setFields([{ name: field, touched: false }]);
    }
  );
}

export function createUploadFormItemValue(params: CreateUploadFormItemValue) {
  const { url, fileName } = params;

  return {
    uid: nanoid(),
    status: 'done',
    url,
    name: fileName,
  };
}

export function imageToUploadFile(image: ImageWithUrl): UploadFile {
  return {
    status: 'done',
    uid: image.id,
    url: image.url,
    name: image.file || '',
  };
}

export function createFilterDefaultValues(
  inputs:
    | typeof GiveFilterInput
    | typeof RecipientFilterInput
    | typeof PlatformFilterInput
    | typeof DonorsFilterInput,
  defaultFilterType?: FilterInputEnums
): MultiFilterFormValue {
  const result = Object.fromEntries(
    Object.values(inputs).map((input) => [input, undefined])
  );

  return {
    ...result,
    filterType: defaultFilterType,
  } as MultiFilterFormValue;
}
