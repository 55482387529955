import { DefaultProperties } from './Common.type';

import { TopPlatformStat, TopRecipientStat } from './TopEntitiesStat.type';

import { RECIPIENT_TYPE } from '.';

export type RecipientStat = {
  donorCount: number;
  donorsGivesCount: number;
  donorsGivesTotalAmount: number;
  donorsGivesTotalAmountAvg: number;
  donorsGivesTotalAmountMed: number;
  donorsTopRecipientsByAmount: TopRecipientStat[];
  donorsTopRecipientsByCount: TopRecipientStat[];
  recipientTypeStats: RecipientTypeStat[];
  donorGivesTopPlatforms: TopPlatformStat[];
  goalsAmountAvg: number;
  goalsAmountMed: number;
  goalsCount: number;
  recipientId: string;
  recurringGivesAmountAvg: number;
  recurringGivesAmountMed: number;
  recurringGivesCount: number;
  year: number | RecipientStatYear.ALL_TIME;
  donorsStayAveMonths: number;
  graph: {
    donorCount: Graph;
    giveCount: Graph;
    totalGiveAmount: Graph;
    goalCount: Graph;
    totalGoalAmount: Graph;
  };
} & DefaultProperties;

export type Graph = {
  xField: string | number;
  yField: number;
}[];

export type RecipientTypeStat = {
  recipientType: RECIPIENT_TYPE;
  giveCount: number;
};

export enum RecipientStatYear {
  ALL_TIME = 'ALL_TIME',
}
