import styled from 'styled-components';

type ShapeProps = {
  color: string;
};

export const Container = styled.div<ShapeProps>`
  svg {
    fill: ${(props) => props.color};
  }
`;
