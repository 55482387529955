import { ReloadOutlined } from '@ant-design/icons';
import styled, { keyframes } from 'styled-components';

import { BODY_2_MED_2 } from 'src/client/constants/text';

type CoverContainerProps = {
  coverImage: string;
};

type StyledReloadOutlinedProps = {
  isLoading: boolean;
};

export const CoverContainer = styled.div<CoverContainerProps>`
  display: flex;
  padding: 0 105px;
  height: 310px;
  width: 100%;
  background-image: url(${(props) => props.coverImage});
  background-size: cover;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  gap: 32px;

  button.ant-btn {
    padding: 20px 45px;
  }

  a {
    ${BODY_2_MED_2};
  }
`;

export const AnalyticsContainer = styled.div`
  display: flex;
  padding: 50px 105px;
  width: 100%;
  background: white;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 33px;
`;

const loadingAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const ReloadIcon = styled(ReloadOutlined)<StyledReloadOutlinedProps>`
  cursor: pointer;
  animation: ${loadingAnimation} 1s linear infinite
    ${(props) => (props.isLoading ? 'running' : 'paused')};
`;
