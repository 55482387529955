import { Card } from 'antd';

import styled from 'styled-components';

import { Flex } from 'src/client/components';
import { H1_BOLD_2 } from 'src/client/constants/text';

type LogoProps = {
  logoSrc: string | undefined;
  recipientName: string | undefined;
};

export const CoverContainer = styled.div<LogoProps>`
  position: relative;
  padding: 19px 30px;
  background: ${(props) =>
    props.logoSrc
      ? `${props.theme.colors.white} center center / cover no-repeat url(${props.logoSrc})`
      : props.theme.colors.darkSurface3};
  color: ${(props) => (props.logoSrc ? 'inherit' : props.theme.colors.darkPrimary100)};
  display: flex;
  justify-content: center;
  align-items: center;
  ${H1_BOLD_2}

  &:after {
    content: ${(props) => props.logoSrc ? '""' : `'${props.recipientName}'`};
    display: ${(props) => props.logoSrc ? 'none' : 'block'};
    position: absolute;
    bottom: 5%;
    left: 5%;
    max-width: 80%;
    text-transform: uppercase;
  }
`

export const StyledCard = styled(Card)`
  border-radius: 16px;
  overflow: hidden;
  border: none;
  background: ${(props) => props.theme.colors.darkSurface2};
  color: ${(props) => props.theme.colors.white};
  flex-shrink: 1;
  flex-grow: 1;
  max-width: 378px;
  width: 400px;

  .ant-card-body {
    display: flex;
    padding: 16px;
    gap: 8px;
    flex-grow: 1;

    ::before,
    ::after {
      content: none;
    }
  }

  .ant-card-cover {
    display: flex;
    height: 258px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .ant-card-cover > ${CoverContainer} {
    height: 100%;
  }
`;

export const DescriptionContainer = styled(Flex)`
  width: 100%;
  gap: 8px;
  flex-direction: column;

  & > * {
    width: 100%;
  }
`;

export const SubtitleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
