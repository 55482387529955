import { QueryClient, useQuery } from 'react-query';

import {
  getAllRecipients,
  getRecipients,
  getRecipientById,
  getRecipientsByIds,
  verifyRecipientResetPasswordToken,
} from 'src/client/api/RecipientApi';
import { Recipient, RecipientFilter } from 'src/commons/types';

const GET_RECIPIENTS_BY_IDS_KEY = 'GET_RECIPIENTS_BY_IDS_KEY';
const GET_RECIPIENT_BY_ID_KEY = 'GET_RECIPIENT_BY_ID_KEY';
const GET_ALL_RECIPIENTS_KEY = 'GET_ALL_RECIPIENTS_KEY';
const GET_RECIPIENTS_KEY = 'GET_RECIPIENTS_KEY';
const VERIFY_RECIPIENT_RESET_PASSWORD_TOKEN =
  'VERIFY_RECIPIENT_RESET_PASSWORD_TOKEN ';

type CommonOptions = {
  enabled: boolean;
};

type UpdateGetRecipientByIdParams = {
  queryClient: QueryClient;
  data: Recipient;
  recipientId: string;
};

export function useGetRecipientsByIds(
  recipientIds: string[] | undefined,
  options?: CommonOptions
) {
  const isEnabled = !!recipientIds?.length;

  return useQuery(
    [GET_RECIPIENTS_BY_IDS_KEY, { recipientIds }],
    () => getRecipientsByIds(recipientIds as string[]),
    {
      enabled: isEnabled,
      ...options,
    }
  );
}

export function useGetRecipientById(
  recipientId: string | undefined,
  options?: CommonOptions
) {
  return useQuery(
    [GET_RECIPIENT_BY_ID_KEY, { recipientId }],
    () => getRecipientById(recipientId as string),
    { ...options, enabled: !!recipientId }
  );
}

export function useGetAllRecipients() {
  return useQuery([GET_ALL_RECIPIENTS_KEY], getAllRecipients);
}

export function useGetRecipients(params?: RecipientFilter) {
  return useQuery([GET_RECIPIENTS_KEY, params], () => getRecipients(params));
}

export function updateGetRecipientByIdQueryData(
  params: UpdateGetRecipientByIdParams
) {
  const { data, recipientId, queryClient } = params;

  return queryClient.setQueryData<Recipient | undefined>(
    [GET_RECIPIENT_BY_ID_KEY, { recipientId }],
    (old) =>
      old && {
        ...old,
        ...data,
      }
  );
}

export function resetGetRecipientByIdQueryData(
  recipientId: string,
  queryClient: QueryClient
) {
  return queryClient.removeQueries([GET_RECIPIENT_BY_ID_KEY, { recipientId }]);
}

export function useVerifyRecipientResetPasswordToken(token: string) {
  return useQuery([VERIFY_RECIPIENT_RESET_PASSWORD_TOKEN, token], () =>
    verifyRecipientResetPasswordToken(token)
  );
}
