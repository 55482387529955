import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import React, { useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { Button, Text } from 'src/client/components';

import { AccountContext } from 'src/client/contexts/AccountContext';
import routes from 'src/commons/constants/routes';

import { Page } from 'src/commons/types';
import { replaceRouteParams } from 'src/commons/utils/RouteUtils';

import * as stylesheet from './styles';

type Props = {
  buttonType?: 'primary' | 'secondary';
  isViewingPublicly: boolean;
  page: Page;
  styles?: any;
};

function PrivacyStatusInfo(props: Props) {
  const { buttonType = 'primary', isViewingPublicly, page, styles } = props;

  const S = styles ?? stylesheet;

  const account = useContext(AccountContext);
  const history = useHistory();

  const isPrivate = page.isVisible === false;

  if (!isViewingPublicly) {
    return null;
  }

  function handleEditClick() {
    if (account?.isRoleAdmin) {
      history.push(
        replaceRouteParams(routes.ADMIN_DONOR_GALLERY, {
          pageId: page.id,
          donorId: page.ownerId as string,
        })
      );
    }

    if (account?.isRoleDonor) {
      history.push(
        replaceRouteParams(routes.PERSONAL_GALLERY, {
          pageId: page.id,
        })
      );
    }
  }

  const title = isPrivate ? 'Private' : 'Public';
  const description = isPrivate
    ? 'Only you can view your gallery'
    : 'Anyone with the link can view your gallery';
  const lockIcon = isPrivate ? <LockOutlined /> : <UnlockOutlined />;

  if (!account) {
    return null;
  }

  return (
    <S.Container data-cy="privacy-status-info">
      <S.Body>
        {lockIcon}
        <div>
          <Text type="body1med2">{title}</Text>
          <S.DescriptionText type="label1med2">{description}</S.DescriptionText>
        </div>

        <Button
          data-cy="edit-gallery-btn"
          type={buttonType}
          onClick={handleEditClick}
        >
          Edit
        </Button>
      </S.Body>
    </S.Container>
  );
}

export default PrivacyStatusInfo;
