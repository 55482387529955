import { useMutation } from 'react-query';

import {
  createAdmin,
  deleteAdmin,
  recalculateAdminDashboardStats,
  resetAdminPassword,
  sendAdminResetPasswordEmail,
  updateAdmin,
} from 'src/client/api/AdminApi';
import { Admin } from 'src/commons/types/Admin.type';
import { ApiResponse } from 'src/commons/types/Response.type';

type CommonUserOptions = {
  onSuccess: (option: ApiResponse<Admin>) => void;
};

export function useAddAdminMutation(opts: CommonUserOptions) {
  return useMutation(createAdmin, opts);
}

export function useUpdateAdminMutation(opts: CommonUserOptions) {
  return useMutation(updateAdmin, opts);
}

export function useDeleteAdminMutation(opts: CommonUserOptions) {
  return useMutation(deleteAdmin, opts);
}

export function useRecalculateAdminDashboardStats() {
  return useMutation(recalculateAdminDashboardStats);
}

export function useSendAdminResetPasswordEmailMutation() {
  return useMutation(sendAdminResetPasswordEmail);
}

export function useResetAdminPasswordMutation() {
  return useMutation(resetAdminPassword);
}
