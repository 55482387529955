import { Col, Grid, Row, Space } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { useTheme } from 'styled-components';

import { Button, Flex, Text } from 'src/client/components';

import routes from 'src/commons/constants/routes';

import LandingPageFooter from '../../components/LandingPageFooter';

import Cta from './components/Cta';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import * as S from './styles';

const { useBreakpoint } = Grid;

function LandingPage() {
  const { colors } = useTheme();

  const screen = useBreakpoint();
  const isMobile = !screen.md;

  const navButtons = isMobile ? (
    <Link to={routes.DONOR_SIGN_IN}>
      <Button type="secondary">Sign-in</Button>
    </Link>
  ) : (
    <Space wrap size={11}>
      <Link to={routes.DONOR_SIGN_IN}>
        <Button type="secondary">Beta user sign-in</Button>
      </Link>
      <Link to={routes.DONOR_REGISTER}>
        <Button type="primary">Request early access</Button>
      </Link>
    </Space>
  );

  return (
    <S.Container>
      <S.NavigationBar>
        <div>
          <Link to="/">
            <S.Logo src="/logo.png" />
          </Link>
        </div>
        {navButtons}
      </S.NavigationBar>
      <Row justify="center">
        <Col md={24} xs={20}>
          <S.IntroductionContainer>
            <Flex
              alignItems="center"
              flexDirection="column"
              gap="24px"
              justifyContent="center"
            >
              <S.Header type="displayLBold2">
                A brand new way to experience all the giving you do
              </S.Header>
              <Text color={colors.black} type="body1med2">
                Gift your generosity the love, support, remembrance you’ve
                always deserved
              </Text>
            </Flex>
            <S.EarlyAccessInputContainer>
              <S.EmailInput placeholder="Email" size="large" />
              <Link to={routes.DONOR_REGISTER}>
                <Button type="primary">Request early access</Button>
              </Link>
            </S.EarlyAccessInputContainer>
          </S.IntroductionContainer>
        </Col>
      </Row>
      <Features />
      <S.SponsorsContainer>
        <S.SponsorsTitleContainer>
          <S.SponsorsTitle type="body1med2">Special thanks to</S.SponsorsTitle>
        </S.SponsorsTitleContainer>
        <S.DschoolLogo src="/dschoolLogo.png" />
        <S.CardinalVenturesLogo src="/cardinalVenturesLogo.png" />
        <S.GuideStarLogo src="/guideStarLogo.png" />
        <S.EastBayCommunityLogo src="/eastBayCommunityLogo.png" />
        <S.JusticeFundersLogo src="/justiceFundersLogo.png" />
      </S.SponsorsContainer>
      <Testimonials />
      <Cta />
      <LandingPageFooter />
    </S.Container>
  );
}

export default LandingPage;
