import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { SEGMENT_EVENTS } from 'src/commons/constants/segment';

import { analytics } from '../libs/segment';

import { useUpdatePageMutation } from './mutations';
import { GET_PAGE_KEY } from './queries';

export function useUpdatePageVisibility(pageId: string) {
  const queryClient = useQueryClient();
  const { mutateAsync: updatePage } = useUpdatePageMutation();

  const updatePageVisibility = useCallback(
    async (isVisible: boolean) => {
      await updatePage({
        id: pageId,
        isVisible,
      });

      queryClient.invalidateQueries({ queryKey: [GET_PAGE_KEY, pageId] });

      analytics.track(SEGMENT_EVENTS.USER_PUBLISHED_THEIR_GALLERY, {
        pageId,
      });
    },
    [pageId, updatePage, queryClient]
  );

  return {
    updatePageVisibility,
  };
}
