import { DefaultProperties } from './Common.type';

export type Give = {
  amount?: number | null;
  attachments?: string[] | null;
  detailEntry?: string | null;
  donorId: string;
  donorLegacyId?: string | null;
  donorName?: string | null;
  endDate?: string | null;
  frequency?: GIVE_FREQUENCY | null;
  fromEmail: string;
  fromName: string;
  giveCount?: number | null;
  giveCountTotal?: number | null;
  giveDate: string | null;
  htmlBody?: string | null;
  isFeatured?: boolean | null;
  isRecurring?: boolean | null;
  loweredCaseRecipientName?: string | null;
  matchedDonationAmount?: number | null;
  matchedDonationTaxDeductible?: TAX_DEDUCTIBLE | null;
  matchedDonationCompany?: string | null;
  platformCover?: string | null;
  platformId: string | null;
  platformLegacyId?: string | null;
  platformName?: string | null;
  processDate?: Date | null;
  processUserId?: string | null;
  processUserLegacyId?: string | null;
  rawData?: string | null;
  recipientCover?: string | null;
  recipientId: string | null;
  recipientLegacyId?: string | null;
  recipientName?: string | null;
  searchTags: string[];
  splitAmount?: number | null;
  splitTaxDeductible?: TAX_DEDUCTIBLE | null;
  splitType?: SPLIT_TYPE | null;
  splitUnit?: string | null;
  startDate?: string | null;
  status: GIVE_STATUS;
  subject?: string | null;
  taxDeductible?: TAX_DEDUCTIBLE | null;
  textBody?: string | null;
  timezone?: string | null;
  unit: number;
  aiParsedGiveEmail?: AiParsedGiveEmail | null;
} & DefaultProperties;

export enum GIVE_UNIT {
  DOLLARS = 0,
  HOURS = 1,
}

export enum TAX_DEDUCTIBLE {
  NO = 'no',
  PARTIAL = 'partial',
  SPLIT = 'split',
  UNKNOWN = 'unknown',
  YES = 'yes',
}

export enum TAX_DEDUCTIBLE_STATUS {
  TAX_DEDUCTIBLE = 'Tax-deductible',
  UNKNOWN = 'Unknown',
}

export enum GIVE_FREQUENCY {
  ANNUALLY = 'annually',
  BI_MONTHLY = 'bi-monthly',
  BI_WEEKLY = 'bi-weekly',
  MONTHLY = 'monthly',
  ONE_TIME = 'one-time',
  QUARTERLY = 'quarterly',
  WEEKLY = 'weekly',
}

export enum GIVE_STATUS {
  PROCESSED = 'processed',
  UNPROCESSED = 'unprocessed',
  REJECTED = 'rejected',
}

export enum DONATION_TYPE {
  CASH = 'cash',
  HOURS = 'hours',
}

export enum SPLIT_TYPE {
  PROCESSING_FEE = 'processing fee',
  OTHER = 'other',
  TIP = 'tip',
}

export type GroupedGiveByMonth = {
  month: string;
  totalAmount: number;
};

export type GroupedGiveByYear = {
  year: number;
  totalAmount: number;
};

export type GroupedMonthlyRecurringGives = {
  recipientId: string;
  monthlyGiveAmount: number;
  recipientName: string;
  frequency: GIVE_FREQUENCY;
};

export type AiParsedGiveEmail = {
  recipientName?: string | null;
  giveDate?: string | null;
  platformName?: string | null;
  detailEntry?: string | null;
  amount?: number | null;
  isSplit?: boolean | null;
  splitAmount?: number | null;
  splitType?: SPLIT_TYPE | null;
  splitTaxDeductible: TAX_DEDUCTIBLE | null;
  taxDeductible: TAX_DEDUCTIBLE | null;
  isRecurring?: boolean | null;
  frequency: GIVE_FREQUENCY | null;
  startDate?: string | null;
  endDate?: string | null;
  giveCount?: number | null;
  giveCountTotal?: number | null;
  matchedDonationAmount?: number | null;
  matchedDonationTaxDeductible?: TAX_DEDUCTIBLE | null;
  matchedDonationCompany?: string | null;
};
