import React from 'react';

import { StatisticCard } from 'src/client/components';

import { useGetAdminDashboardStats } from 'src/client/hooks/queries';
import PageNotFound from 'src/client/pages/PageNotFound';
import { AdminDashboardStatsCount } from 'src/commons/types';

import * as S from './styles';

type Props = {};

export function GiveStatistics(props: Props) {
  const {
    data: adminDashboardStatsResponse,
    isLoading: isAdminDashboardLoading,
  } = useGetAdminDashboardStats();

  const formattedDashboardStats = Object.fromEntries(
    Object.entries(adminDashboardStatsResponse?.adminDashboardStats ?? {}).map(
      ([key, value]) => [key, value?.toLocaleString()]
    )
  ) as unknown as AdminDashboardStatsCount;

  if (!formattedDashboardStats) {
    return <PageNotFound />;
  }

  return (
    <S.StatisticCardContainer justifyContent="space-between">
      <StatisticCard
        isLoading={isAdminDashboardLoading}
        statisticValue={`${formattedDashboardStats.givesProcessed}`}
        title="Gives Parsed"
      />
      <StatisticCard
        isLoading={isAdminDashboardLoading}
        statisticValue={`$${formattedDashboardStats.totalGivesProcessedAmount}`}
        title="Total Contributed"
      />
      <StatisticCard
        isLoading={isAdminDashboardLoading}
        statisticValue={`${formattedDashboardStats.taxDeductiblePercentage}%`}
        title="Tax Deductible"
      />
      <StatisticCard
        isLoading={isAdminDashboardLoading}
        statisticValue={`$${formattedDashboardStats.totalMatchedDonationAmount}`}
        title="Matched"
      />
      <StatisticCard
        isLoading={isAdminDashboardLoading}
        statisticValue={`${formattedDashboardStats.totalHourGive}`}
        title="Hrs Volunteered"
      />
    </S.StatisticCardContainer>
  );
}
