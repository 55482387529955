import React from 'react';

import { useTheme } from 'styled-components';

import { Box, Text } from 'src/client/components';

import { formatToCurrency } from 'src/commons/utils/MoneyUtilts';

import * as S from './styles';

type Props = {
  giveCount: number;
  recipientName: string;
  totalAmount: number;
  counterBadgeCount: number;
};

function BottomCard(props: Props) {
  const { giveCount, recipientName, totalAmount, counterBadgeCount } = props;

  const { colors } = useTheme();

  return (
    <S.Container>
      <S.StyledCounterBadge count={counterBadgeCount} />
      <Text type="body1reg2">{recipientName}</Text>
      <Box margin="8px 0 0 0" />
      <Text color={colors.lightMedEmphasis} type="body2reg2">
        {giveCount} Gives
      </Text>
      <Box margin="4px 0 0 0" />
      <Text color={colors.lightMedEmphasis} type="body2reg2">
        {formatToCurrency(totalAmount)} Total
      </Text>
    </S.Container>
  );
}

export default BottomCard;
