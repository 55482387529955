import { QueryClient, useQuery } from 'react-query';

import {
  getAdmin,
  getAdminDashboardStats,
  getAdmins,
  verifyAdminResetPasswordToken,
} from 'src/client/api/AdminApi';
import { Admin } from 'src/commons/types/Admin.type';
import { GetAdminsQuery } from 'src/commons/types/Params.type';

const GET_USER_KEY = 'GET_USER_KEY';
const GET_USERS_KEY = 'GET_USERS_KEY';
const GET_ADMIN_DASHBOARD_STATS = 'GET_ADMIN_DASHBOARD_STATS';
const VERIFY_ADMIN_RESET_PASSWORD_TOKEN = 'VERIFY_ADMIN_RESET_PASSWORD_TOKEN';

export function useGetAdmins(params: GetAdminsQuery) {
  return useQuery([GET_USERS_KEY], () => getAdmins(params));
}

export function getAdminsCache(queryClient: QueryClient) {
  return queryClient.getQueryData([GET_USERS_KEY]);
}

export function updateAdminsQueryData(queryClient: QueryClient, data: Admin) {
  queryClient.setQueryData([GET_USERS_KEY], (users: Admin[] | undefined) =>
    (users || []).map((user) => {
      if (user.id === data.id) {
        return data;
      }

      return user;
    })
  );
}

export function addAdminsQueryData(queryClient: QueryClient, data: Admin) {
  queryClient.setQueryData([GET_USERS_KEY], (users: Admin[] | undefined) => [
    ...(users || []),
    data,
  ]);
}

export function removeAdminsQueryData(queryClient: QueryClient, data: Admin) {
  queryClient.setQueryData([GET_USERS_KEY], (old: Admin[] | undefined) =>
    [...(old || [])].filter((o) => o.id !== data.id)
  );
}

export function useGetAdmin(id?: string | null) {
  return useQuery([GET_USER_KEY, id], () => getAdmin(id as string), {
    enabled: Boolean(id),
  });
}

export function updateAdminQueryData(queryClient: QueryClient, data: Admin) {
  queryClient.setQueryData(
    [GET_USER_KEY, data.id],
    (old: Admin | undefined) => ({
      ...(old || {}),
      ...data,
    })
  );
}

export function useGetAdminDashboardStats() {
  return useQuery([GET_ADMIN_DASHBOARD_STATS], getAdminDashboardStats);
}

export function useVerifyAdminResetPasswordToken(token: string) {
  return useQuery([VERIFY_ADMIN_RESET_PASSWORD_TOKEN, token], () =>
    verifyAdminResetPasswordToken(token)
  );
}
