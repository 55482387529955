import { Tabs } from 'antd';
import styled from 'styled-components';

import EmailViewer from 'src/client/components/EmailViewer';

export const ReceiptBody = styled(EmailViewer)`
  padding: 10px 20px;
  height: 720px;
  width: 100%;
  overflow: auto;
  background: ${(props) => props.theme.colors.gray4};
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0;
    color: black;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.teal2};
  }

  .ant-tabs-ink-bar {
    background: ${(props) => props.theme.colors.teal2};
  }
`;
