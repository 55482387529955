import { rem } from 'polished';
import styled, { createGlobalStyle } from 'styled-components';

import { Button, Flex, FlexCenter, Text } from 'src/client/components';
import { BODY_2_REG_2 } from 'src/client/constants/text';

export const DonorGalleryGlobalStyle = createGlobalStyle`
  .update-page-success-message {
    margin-top: 45vh;
  }

  .update-page-success-message .ant-message-notice-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 122px;
    width: 270px;
    border-radius: 6px;
  }
`;

export const Container = styled.div`
  color: black;

  .ant-form-item-explain {
    margin-top: 4px;
  }

  .ant-form-item-explain-error {
    ${BODY_2_REG_2}
    color: ${(props) => props.theme.colors.danger500};
  }
`;

export const FeaturedCardsContainer = styled(Flex)`
  justify-content: space-between;
`;

export const GivingAdjectiveSection = styled.div`
  background: black;
`;

export const GivingAdjectiveContentContainer = styled.div`
  margin: 0 auto;
  padding: 46px 46px 4px 46px;
  max-width: 1440px;
`;

export const AdjectiveText = styled(Text)`
  margin: 135px 0 0 0;
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${rem('198px')};
  line-height: 239px;
  font-weight: 860;
  color: white;
`;

export const AdjectiveHeader = styled(Text)`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${rem('40px')};
  line-height: 48px;
  font-weight: 700;
  color: white;
`;

export const EditLabel = styled(Text).attrs({
  type: 'label2med2',
})`
  && {
    margin-left: 10px;
    padding: 4px 10px;
    background: ${(props) => props.theme.colors.darkPrimary100};
    border-radius: 6px 6px 0 0;
    width: fit-content;

    @media ${(props) => props.theme.screen.md} {
      padding: 6px 10px;
    }
  }
`;

export const UnderlinedButton = styled(Button).attrs({
  type: 'noStyle',
})`
  && {
    color: ${(props) => props.theme.colors.darkPrimary100};
  }

  &[disabled],
  &[disabled]:hover {
    color: ${(props) => props.theme.colors.darkPrimary100};
    opacity: 40%;
  }

  & > span {
    text-decoration: underline !important;
  }
`;

export const FooterBar = styled(FlexCenter)`
  padding: 10px;
  border-top: 1px solid ${(props) => props.theme.colors.black};
`;

export const PrivacyStatusContainer = styled.div`
  display: flex;
  margin-top: 0;
  justify-content: center;

  @media ${(props) => props.theme.screen.lg} {
    margin-right: 24px;
    justify-content: flex-end;
  }
`;
