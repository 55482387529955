/* eslint-disable no-magic-numbers */
import { useEffect, useState } from 'react';

type UseScrollRotateProps = {
  container: HTMLElement | null;
  increment?: number;
};

export const useScrollRotation = (props: UseScrollRotateProps) => {
  const { container, increment = 3 } = props;
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    if (!container) {
      return;
    }

    let lastScrollY = container.scrollTop;

    const handleScroll = (event: Event) => {
      const target = event.target as HTMLElement;
      const currentScrollY = target.scrollTop;
      const isScrollingDown = currentScrollY > lastScrollY;

      setRotation((prev) => {
        if (isScrollingDown) {
          return (prev + increment) % 360;
        }

        return prev === 0 ? 360 - increment : prev - increment;
      });

      lastScrollY = currentScrollY;
    };

    container.addEventListener('scroll', handleScroll);

    return () => container.removeEventListener('scroll', handleScroll);
  }, [container, increment]);

  return rotation;
};
