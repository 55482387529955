import styled from 'styled-components';

import { FlexCenter } from 'src/client/components/StyledCommon';

export const Container = styled.div`
  display: flex;
  padding: 50px 50px;
  height: 100%;
  min-height: 447px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
`;

export const ButtonsContainer = styled(FlexCenter)`
  margin: 0 16px 0 0;
  flex-wrap: wrap;
  gap: 16px;
`;
