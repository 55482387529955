import { Col } from 'antd';
import React from 'react';

import { RowDivider } from 'src/client/components';

type Props = {
  title: React.ReactElement;
  value: React.ReactElement;
};

const horizontalGutter = 24;

function GiveInfoRowDivider(props: Props) {
  const { title, value } = props;

  return (
    <RowDivider gutter={[horizontalGutter, 0]}>
      <Col span={4}>{title}</Col>
      <Col flex="auto">{value}</Col>
    </RowDivider>
  );
}

export default GiveInfoRowDivider;
