import React, { useEffect, useState } from 'react';

import { YEAR_VIEW } from 'src/client/constants/DonorPages';
import { useGetDonorGoal, useGetGives } from 'src/client/hooks/queries';
import { GetDonorGivingGoalParams } from 'src/client/types/ApiParams';
import { Donor, GivesFilter, GIVE_STATUS } from 'src/commons/types';
import { getCurrentYear } from 'src/commons/utils/DateUtils';

import AllTimeState from './components/AllTimeState';
import EditState from './components/EditState';
import GoalDisplayState from './components/GoalDisplayState';
import NoGoalState from './components/NoGoalState';
import * as S from './styles';

type Props = {
  donor: Donor;
  yearView: number | YEAR_VIEW.ALL_TIME;
};

export enum CardState {
  EDITING_FIXED_AMOUNT = 'EDITING_FIXED_AMOUNT',
  EDITING_SUPER_GOAL = 'EDITING_SUPER_GOAL',
  DISPLAY_GOAL = 'DISPLAY_GOAL',
  NO_GOAL_CURRENT_YEAR = 'NO_GOAL_CURRENT_YEAR',
  ALL_TIME = 'ALL_TIME',
}

export default function GivingGoalCard(props: Props) {
  const { donor, yearView } = props;

  const [cardState, setCardState] = useState(CardState.DISPLAY_GOAL);
  const [shouldShowConfetti, setShouldShowConfetti] = useState(false);

  const donorGoalQuery: GetDonorGivingGoalParams = {
    donorId: donor?.id,
    year: yearView === YEAR_VIEW.ALL_TIME ? undefined : yearView,
  };

  const { data: donorGoal, isLoading: isDonorGoalLoading } =
    useGetDonorGoal(donorGoalQuery);

  const giveQuery: GivesFilter = {
    giveYear: yearView === YEAR_VIEW.ALL_TIME ? undefined : String(yearView),
    donorId: donor.id,
    status: GIVE_STATUS.PROCESSED,
  };

  const { data: givesResponse, isLoading: isGivesLoading } =
    useGetGives(giveQuery);

  const isGivingGoalCardLoading = isDonorGoalLoading || isGivesLoading;

  useEffect(() => {
    if (!isGivingGoalCardLoading) {
      handleDashboardViewChange();
    }
  }, [yearView, isGivingGoalCardLoading]);

  function handleDashboardViewChange() {
    const isGoalNotYetSet = !isGivingGoalCardLoading && !donorGoal;
    const isGoalNotYetSetAndIsInCurrentYear =
      isGoalNotYetSet && yearView === getCurrentYear();
    const isInPreviousYear =
      yearView !== YEAR_VIEW.ALL_TIME && yearView !== getCurrentYear();
    const isGoalNotYetSetAndIsInPreviousYear =
      isGoalNotYetSet && isInPreviousYear;

    if (isGoalNotYetSetAndIsInCurrentYear) {
      setCardState(CardState.NO_GOAL_CURRENT_YEAR);
    } else if (isGoalNotYetSetAndIsInPreviousYear) {
      setCardState(CardState.ALL_TIME);
    } else if (yearView === YEAR_VIEW.ALL_TIME) {
      setCardState(CardState.ALL_TIME);
    } else {
      setCardState(CardState.DISPLAY_GOAL);
    }
  }

  function renderContent() {
    switch (cardState) {
      case CardState.DISPLAY_GOAL:
        return (
          <GoalDisplayState
            donorGoal={donorGoal}
            gives={givesResponse?.data}
            setCardState={setCardState}
            shouldShowConfetti={shouldShowConfetti}
            yearView={yearView}
          />
        );
      case CardState.NO_GOAL_CURRENT_YEAR:
        return <NoGoalState setCardState={setCardState} />;
      case CardState.EDITING_FIXED_AMOUNT:
      case CardState.EDITING_SUPER_GOAL:
        return (
          <EditState
            cardState={cardState}
            donor={donor}
            donorGoal={donorGoal}
            donorGoalQuery={donorGoalQuery}
            setCardState={setCardState}
            setShouldShowConfetti={setShouldShowConfetti}
            yearView={yearView}
          />
        );
      case CardState.ALL_TIME:
        return <AllTimeState donorId={donor.id} setCardState={setCardState} />;
      default:
        return null;
    }
  }

  return (
    <S.Container>
      <S.StyledLoadingCard
        isLoading={isGivingGoalCardLoading}
        spinProps={{
          'data-cy': 'goal-card-spinner',
        }}
      >
        {renderContent()}
      </S.StyledLoadingCard>
    </S.Container>
  );
}
