import { EditOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Flex, Button, Text, Box } from 'src/client/components';
import Marquee from 'src/client/components/Marquee';
import { useGetDonorById } from 'src/client/hooks/queries';
import { analytics } from 'src/client/libs/segment';
import { STATUS_OPTIONS, SectionStatus } from 'src/client/types/Gallery';
import {
  GALLERY_COLORS,
  GalleryColor,
  GalleryShape,
  GridOptionType,
} from 'src/commons/constants/gallery';
import { GivingAdjective } from 'src/commons/constants/givingAdjectives';

import routes from 'src/commons/constants/routes';
import { SEGMENT_EVENTS } from 'src/commons/constants/segment';

import { isSectionEditing } from '../../utils';

import GridOptionPicker from '../IntroSection/components/GridOptionPicker';

import * as S from './styles';

const GALLERY_SHAPES = Object.values(GalleryShape);
const SEPARATOR_SHAPES_COUNT = 3;
const SEPARATOR_SHAPES = GALLERY_SHAPES.slice(
  1,
  GALLERY_SHAPES.length - SEPARATOR_SHAPES_COUNT
);

const ADDITIONAL_COLORS = [GalleryColor.COLOR_5] as const;
const EXTENDED_GALLERY_COLORS = [...GALLERY_COLORS, ...ADDITIONAL_COLORS];

const { VIEW_MODE, EDITING_WITH_NO_CHANGES, EDITING_WITH_CHANGES } =
  STATUS_OPTIONS;

type Props = {
  donorId: string;
  isUpdating: boolean;
  isViewingPublicly: boolean;
  onSave: (payload: {
    adjective?: GivingAdjective;
    previousAdjective?: GivingAdjective;
    separatorColor?: GalleryColor;
  }) => Promise<void>;
  sectionStatus: SectionStatus;
  setSectionStatus: (newStatus: STATUS_OPTIONS) => void;
};

const { useBreakpoint } = Grid;

function AdjectiveSection(props: Props) {
  const {
    donorId,
    isUpdating,
    isViewingPublicly,
    onSave,
    sectionStatus,
    setSectionStatus,
  } = props;

  const { colors } = useTheme();
  const history = useHistory();

  const { data: donor, isLoading: isDonorLoading } = useGetDonorById(donorId);

  const [selectedItem, setSelectedItem] = useState<GivingAdjective>();
  const [selectedColor, setSelectedColor] = useState<GalleryColor>();

  const currentAdjective = getAdjective(donor?.descriptionCurrent as string);
  const currentSeparatorColor = donor?.separatorColor;

  const screens = useBreakpoint();
  const isBigScreen = screens.md;
  const currentRoute = history.location.pathname;
  const isPreview = currentRoute.includes(
    routes.PUBLIC_GALLERY.replace(':pageId', '')
  );

  useEffect(() => {
    if (donor) {
      setSelectedItem(currentAdjective as GivingAdjective);
      setSelectedColor(
        (currentSeparatorColor as GalleryColor) || GalleryColor.COLOR_1
      );
    }
  }, [donor, currentAdjective, currentSeparatorColor]);

  const isSaveDisabled =
    (selectedItem === currentAdjective &&
      selectedColor === currentSeparatorColor) ||
    isUpdating ||
    isDonorLoading;

  const isEditing = isSectionEditing(sectionStatus);

  function getAdjective(value: string) {
    const isAlphabetic = /^[a-zA-Z]+$/;

    return isAlphabetic.test(value) ? value : '';
  }

  function startEditing() {
    setSectionStatus(EDITING_WITH_NO_CHANGES);
    analytics.track(
      SEGMENT_EVENTS.USER_CLICKED_AN_EDIT_GALLERY_SECTION_BUTTON,
      {
        section: 'adjective',
        ownerName: donor?.name,
        donorEmail: donor?.email,
      }
    );
  }

  function cancelEditing() {
    setSectionStatus(VIEW_MODE);
    setSelectedItem(currentAdjective as GivingAdjective);
    setSelectedColor(currentSeparatorColor as GalleryColor);
  }

  async function handleSave() {
    const newAdjectiveSelected = selectedItem !== currentAdjective;
    const newColorSelected = selectedColor !== currentSeparatorColor;

    const payload = {
      ...(newAdjectiveSelected && {
        adjective: selectedItem,
        previousAdjective: currentAdjective as GivingAdjective,
      }),
      ...(newColorSelected && { color: selectedColor }),
    };

    await onSave(payload);
    setSectionStatus(VIEW_MODE);
    analytics.track(SEGMENT_EVENTS.USER_SAVED_A_GALLERY_SECTION, {
      section: 'adjection',
      ...(newColorSelected && { newColor: selectedColor }),
      ...(newAdjectiveSelected && {
        newAdjective: selectedItem,
        previousAdjective: currentAdjective,
      }),
    });
  }

  function handleSelect(newAdjective: GivingAdjective) {
    setSelectedItem(newAdjective);

    if (newAdjective === currentAdjective) {
      setSectionStatus(EDITING_WITH_NO_CHANGES);
    } else {
      setSectionStatus(EDITING_WITH_CHANGES);
    }
  }

  function handleColorChange(newColor: string) {
    setSelectedColor(newColor as GalleryColor);

    if (newColor === currentSeparatorColor) {
      setSectionStatus(EDITING_WITH_NO_CHANGES);
    } else {
      setSectionStatus(EDITING_WITH_CHANGES);
    }
  }

  const menuItemDisplay = Object.values(GivingAdjective).map((item) => (
    <S.StyledMenuItem
      data-cy={`adjective-item ${item.toLowerCase()}`}
      data-cy-selected={selectedItem === item ? 'true' : 'false'}
      isSelected={selectedItem === item}
      key={item}
      onClick={(_event) => handleSelect(item)}
    >
      {item}
    </S.StyledMenuItem>
  ));

  const adjectiveHeaderText = isBigScreen
    ? 'My Giving Side is'
    : `${donor?.name}'s Giving Side`;
  const editButtonText = isBigScreen ? 'Edit Status' : 'Edit';
  const editButton = isViewingPublicly ? null : (
    <S.EditButton
      data-cy="edit-status-btn"
      type="elevated1"
      onClick={startEditing}
    >
      <EditOutlined />
      {editButtonText}
    </S.EditButton>
  );

  if (isViewingPublicly && !currentAdjective) {
    return null;
  }

  return (
    <>
      <S.GivingAdjectiveSection>
        <S.GivingAdjectiveContentContainer isPreview={isPreview}>
          <Flex justifyContent="space-between">
            <S.AdjectiveHeader>{adjectiveHeaderText}</S.AdjectiveHeader>
            {editButton}
          </Flex>
          <Marquee
            galleryShapeColor={currentSeparatorColor as GalleryColor}
            scrollSpeed={0}
            separatorShapes={SEPARATOR_SHAPES}
          >
            <S.AdjectiveText>{currentAdjective}</S.AdjectiveText>
          </Marquee>
        </S.GivingAdjectiveContentContainer>
      </S.GivingAdjectiveSection>
      <S.StyledModal
        centered
        data-cy="adjective-modal"
        footer={false}
        open={isEditing}
        onCancel={cancelEditing}
      >
        <Text type="h4med2">Select a word that describes your Giving Side</Text>
        <Text color={colors.darkEmphasis2} type="body2reg2">
          Personalize your gallery by showcasing a word that describes your
          giving.
        </Text>
        <S.Container>
          <Text color={colors.darkHighEmphasis} type="label1med2">
            My Giving Side is...
          </Text>
          <S.StyledMenu>{menuItemDisplay}</S.StyledMenu>
          <Box margin="24px 0 0 0" />
          <S.SeparatorPickerContainer>
            <S.SeparatorColorSection>
              <Text type="label1med2">Separator Color</Text>
              <Box margin="4px 0 0 0" />
              <GridOptionPicker
                colors={EXTENDED_GALLERY_COLORS}
                selectedColor={selectedColor as GalleryColor}
                type={GridOptionType.COLOR}
                onChange={handleColorChange}
              />
            </S.SeparatorColorSection>
            <S.SeparatorPreviewContainer>
              <Text type="label1med2">Separator Preview</Text>
              {SEPARATOR_SHAPES.map((galleryShape) => (
                <S.StyledShape
                  key={galleryShape}
                  shape={{
                    name: galleryShape,
                    color: selectedColor as GalleryColor,
                  }}
                />
              ))}
            </S.SeparatorPreviewContainer>
          </S.SeparatorPickerContainer>
        </S.Container>
        <Flex gap="8px" justifyContent="flex-end">
          <Button
            data-cy="cancel-save-adjective-btn"
            type="secondary"
            onClick={cancelEditing}
          >
            Cancel
          </Button>
          <Button
            data-cy="save-adjective-btn"
            disabled={isSaveDisabled}
            loading={isUpdating}
            type="primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </Flex>
      </S.StyledModal>
    </>
  );
}

export default AdjectiveSection;
