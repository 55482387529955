import { matchPath } from 'react-router-dom';

import { MenuSiderConfig } from '../components/AdminSider';

export function useMatchMenuItem() {
  function getMatchedMenuItem(
    itemConfig: MenuSiderConfig[] | undefined
  ): string | null {
    const matchedMenuItem = itemConfig?.find((item) => {
      const matchedKey = item?.keys.some((key) =>
        matchPath(window.location.pathname, { path: key, exact: true })
      );

      if (matchedKey) {
        return true;
      }

      return null;
    });

    if (matchedMenuItem) {
      return matchedMenuItem.route;
    }

    return null;
  }

  return {
    getMatchedMenuItem,
  };
}
