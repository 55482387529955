import styled from 'styled-components';

import {
  Button,
  Checkbox,
  Flex,
  InputNumber,
  Text,
} from 'src/client/components';
import { BODY_1_MED_2, BUTTON_1_2 } from 'src/client/constants/text';

export const Container = styled.div`
  margin: 69px auto;
  max-width: 1440px;

  p {
    color: ${(props) => props.theme.colors.darkEmphasis1};
  }
`;

export const AddRecipientButton = styled(Button)`
  margin-top: 20px;
  color: ${(props) => props.theme.colors.lightHighEmphasis};

  span {
    ${BUTTON_1_2};
  }
`;

export const GiveNumberInput = styled(InputNumber)`
  && {
    width: 80px;
  }
`;

export const ActionButtonsContainer = styled(Flex)`
  margin-top: 48px;
  gap: 22px;
`;

export const GroupInputTitleText = styled(Text).attrs(() => ({
  type: 'h3med2',
}))`
  margin: 24px 0 24px 0;
  color: ${(props) => props.theme.colors.black};
`;

export const BackButton = styled(Button)`
  && {
    padding-left: 0;
  }

  :hover {
    background: white;
  }

  span {
    ${BUTTON_1_2};
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  span {
    ${BODY_1_MED_2};
    color: ${(props) => props.theme.colors.darkEmphasis1};
  }
`;
