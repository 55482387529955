import styled from 'styled-components';

import Text from 'src/client/components/Text';
import { H1_MED_2 } from 'src/client/constants/text';

export const StyledText = styled(Text)`
  position: relative;
  display: flex;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  height: 103px;
  padding-right: 50px;
  align-items: center;

  span {
    z-index: 2;
    margin-left: 38px;
    ${H1_MED_2}
  }
`;

export const TitleImage = styled.img`
  position: absolute;
  height: 103px;
  left: 0;
`;
