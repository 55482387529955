import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { Button, Text } from 'src/client/components';
import EmailViewer from 'src/client/components/EmailViewer';

export const Container = styled.div`
  padding: 65px 106px;
`;

export const BackButton = styled(Button)`
  color: ${(props) => props.theme.colors.darkEmphasis2};
`;

export const Receipt = styled(EmailViewer)`
  padding: 10px 20px;
  height: 703px;
  width: 720px;
  background: ${(props) => props.theme.colors.gray4};
  overflow: auto;
`;

export const FieldTitle = styled(Text).attrs(() => ({
  type: 'body1med2',
}))`
  color: ${(props) => props.theme.colors.gray2};
`;

export const FieldValue = styled(Text).attrs(() => ({
  type: 'body1med2',
}))`
  color: ${(props) => props.theme.colors.darkHighEmphasis};
`;

export const FieldSubValue = styled(Text).attrs(() => ({
  type: 'body2Regular',
}))`
  color: ${(props) => props.theme.colors.gray2};
`;

export const RecipientName = styled(Text)`
  color: ${(props) => props.theme.colors.darkHighEmphasis};
`;

export const StyledDeleteOutlined = styled(DeleteOutlined)`
  cursor: pointer;
  color: ${(props) => props.theme.colors.darkLowEmphasis};
`;
