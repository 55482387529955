import styled from 'styled-components';

import { Flex } from 'src/client/components';

export const Container = styled(Flex)`
  justify-content: center;
  flex-wrap: wrap;
  gap: 28px 36px;
  margin-bottom: 80px;

  @media ${(props) => props.theme.screen.md} {
    gap: 28px 28px;
    margin-bottom: 128px;
  }
`;
