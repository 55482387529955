import { Form, FormInstance, notification } from 'antd';
import React, { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';

import { useTheme } from 'styled-components';

import { AccountContext } from 'src/client/contexts/AccountContext';
import { useSwitchPrimaryEmailMutation } from 'src/client/hooks/mutations';
import {
  updateGetCurrentAccountQuery,
  updateGetDonorByIdQuery,
} from 'src/client/hooks/queries';

import { AlternateEmail, Donor } from 'src/commons/types';

import { Text } from '..';
import Select from '../Select';
import { Box, Flex } from '../StyledCommon';

import * as S from './styles';

type Props = {
  alternateEmailsFromDb: AlternateEmail[] | undefined;
  description: string;
  donor: Donor;
  formInstance: FormInstance<any>;
  modalDescription: string;
};

const { Option } = Select;

function DonorPrimaryEmailEditField(props: Props) {
  const {
    alternateEmailsFromDb,
    description,
    donor,
    modalDescription,
    formInstance,
  } = props;

  const account = useContext(AccountContext);

  const { colors } = useTheme();
  const queryClient = useQueryClient();

  const currentPrimaryAlternateEmail = alternateEmailsFromDb?.find(
    (alternateEmail) => alternateEmail.email === donor.email
  );

  const [selectedAlternateEmail, setAlternateEmail] = useState(
    currentPrimaryAlternateEmail?.email
  );
  const [isChangeEmailModalVisible, setIsChangeEmailModalVisible] =
    useState(false);

  const [isMaskClosable] = useState(false);

  const { mutateAsync: switchPrimaryEmail } = useSwitchPrimaryEmailMutation({
    onSuccess: (response) => {
      const { data } = response;
      updateAccountInfo(data);
    },
  });

  function showChangeEmailModal() {
    setIsChangeEmailModalVisible(true);
  }

  function hideChangeEmailModal() {
    setIsChangeEmailModalVisible(false);
  }

  function handleSelectChange(value: unknown) {
    setAlternateEmail(value as string);
  }

  function updateAccountInfo(data: Donor) {
    if (account && !account.isRoleAdmin) {
      updateGetCurrentAccountQuery({
        data: {
          email: data?.email,
        },
        queryClient,
      });
    }

    updateGetDonorByIdQuery({
      data: data,
      donorId: data.id,
      queryClient,
    });
  }

  async function handleModalConfirm() {
    const fieldsValue = formInstance.getFieldsValue();

    if (!fieldsValue.passwordVerification) {
      formInstance.validateFields(['passwordVerification']);

      return;
    }

    const newPrimaryEmail = alternateEmailsFromDb?.find(
      (alternateEmail) => alternateEmail.email === selectedAlternateEmail
    );

    await switchPrimaryEmail({
      donorId: donor.id,
      targetAlternateEmailId: newPrimaryEmail?.id ?? '',
      passwordConfirmation: fieldsValue.passwordVerification,
    });

    formInstance.setFieldsValue({
      primaryEmail: newPrimaryEmail?.email,
    });

    notification.success({
      message: `Primary email changed to ${newPrimaryEmail?.email}`,
    });

    formInstance.resetFields();

    setIsChangeEmailModalVisible(false);
  }

  const alternateEmailOptions = alternateEmailsFromDb
    ?.filter(
      (alternateEmailDb) =>
        alternateEmailDb.email !== donor?.email && alternateEmailDb.isVerified
    )
    .map((alternateEmailDb) => (
      <Option key={alternateEmailDb.id} value={alternateEmailDb.email}>
        {alternateEmailDb.email}
      </Option>
    ));

  return (
    <S.Container>
      <Flex alignItems="center">
        <Text color={colors.darkEmphasis1} type="body1med2">
          Primary Email Address
        </Text>
        <S.ChangeButton type="noStyle" onClick={showChangeEmailModal}>
          Change
        </S.ChangeButton>
      </Flex>
      <Box margin="0 0 8px 0" />
      <Form.Item name="primaryEmail">
        <S.PrimaryEmailInput disabled size="large" />
      </Form.Item>
      <Box margin="8px 0 0 0" />
      <Text color={colors.darkEmphasis2} type="body1reg2">
        {description}
      </Text>
      <S.StyledModal
        centered
        cancelButtonProps={{
          className: 'cancel-button',
        }}
        cancelText="Cancel"
        maskClosable={isMaskClosable}
        okButtonProps={{
          className: 'ok-button',
        }}
        okText="Change Primary Email"
        title={
          <Text color={colors.title85} type="h5bold2">
            Change Primary Email
          </Text>
        }
        visible={isChangeEmailModalVisible}
        onCancel={hideChangeEmailModal}
        onOk={handleModalConfirm}
      >
        <Text color={colors.darkMedEmphasis} type="body1reg2">
          {modalDescription}
        </Text>
        <div>
          <Text isInline color={colors.darkMedEmphasis} type="body1reg2">
            Current:&nbsp;
          </Text>
          <Text isInline color={colors.title85} type="body1reg2">
            {donor.email}
          </Text>
        </div>
        <Box margin="24px 0 0 0" />
        <Text color={colors.title85} type="body1med2">
          Select new primary email
        </Text>
        <Box margin="8px 0 0 0" />
        <S.StyledSelect
          placeholder="Please select"
          size="large"
          value={selectedAlternateEmail}
          onChange={handleSelectChange}
        >
          {alternateEmailOptions}
        </S.StyledSelect>
        <Box margin="6px 0 0 0" />
        <Text isInline color={colors.darkMedEmphasis} type="body2reg2">
          Only verified alternate emails can be used.&nbsp;
        </Text>
        <S.CheckAlternateEmailText
          isInline
          color={colors.teal2}
          type="body2reg2"
          onClick={hideChangeEmailModal}
        >
          Check alternate emails
        </S.CheckAlternateEmailText>
        <Box margin="24px 0 0 0" />
        <Text color={colors.darkHighEmphasis} type="body1reg2">
          Input password to confirm
        </Text>
        <Box margin="8px 0 0 0" />
        <Form.Item
          name="passwordVerification"
          rules={[
            {
              required: true,
              message: 'Please input your current password!',
            },
          ]}
        >
          <S.ConfirmPasswordInput placeholder="Password" size="large" />
        </Form.Item>
      </S.StyledModal>
    </S.Container>
  );
}

export default DonorPrimaryEmailEditField;
