import styled from 'styled-components';

import { Button } from 'src/client/components';
import { BUTTON_1_2, LABEL_1_MED_2 } from 'src/client/constants/text';

export const Container = styled.div`
  .ant-form-item-label {
    ${LABEL_1_MED_2};
  }
`;

export const Card = styled.div`
  padding: 40px;
  border-radius: 24px;
  background: ${(props) => props.theme.colors.white};
`;

export const SetPasswordButton = styled(Button)`
  width: 100%;
  ${BUTTON_1_2};

  && {
    color: ${(props) => props.theme.colors.darkEmphasis1};
  }
`;

export const ResendEmailButton = styled(Button)`
  &&& {
    padding: 0;
    border: 0;
    color: ${(props) => props.theme.colors.teal2};
  }
`;
