/* eslint-disable sonarjs/cognitive-complexity */
import { LeftOutlined } from '@ant-design/icons';
import { Form, UploadFile } from 'antd';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  Box,
  Button,
  Text,
  RecipientDetailsForm,
  Title,
} from 'src/client/components';

import useAdminEditRecipients from 'src/client/hooks/useAdminEditRecipients';
import useGetQueryString from 'src/client/hooks/useGetQueryString';
import useThemeContext from 'src/client/hooks/useTheme';
import AdminLayout from 'src/client/layouts/AdminLayout';
import { imageToUploadFile } from 'src/client/utils/FormUtils';
import routes from 'src/commons/constants/routes';
import { Recipient } from 'src/commons/types';

import { replaceRouteParams } from 'src/commons/utils/RouteUtils';

import { LoadingPage, PageNotFound } from '..';

import * as S from './styles';

type UrlParams = {
  recipientId: string;
};

const { useForm } = Form;

function AdminEditRecipient(props: RouteComponentProps<UrlParams>) {
  const { history, match } = props;

  const recipientId = match.params.recipientId;

  const [recipientDetailsForm] = useForm();
  const { colors } = useThemeContext();

  const {
    recipient,
    isLoading,
    isSubmitting,
    uploadedImagesFromDb,
    scrapedImagesFromDb,
    updateRecipient,
    getImageTasks,
    clearRecipientQueryCache,
    shouldUpdateLogoId,
  } = useAdminEditRecipients(recipientId);
  const queryString = useGetQueryString();

  function goBack() {
    history.goBack();
  }

  function handleRecipientReset() {
    recipientDetailsForm.resetFields();
  }

  async function handleEditRecipient() {
    const fieldValues = recipientDetailsForm.getFieldsValue();
    const updatedRecipientData: Partial<Recipient> = {};

    if (recipientDetailsForm.isFieldTouched('name')) {
      updatedRecipientData.name = fieldValues.name;
    }

    if (recipientDetailsForm.isFieldTouched('website')) {
      updatedRecipientData.website = fieldValues.website;
    }

    if (recipientDetailsForm.isFieldTouched('recipientTypes')) {
      updatedRecipientData.recipientTypes = [fieldValues.recipientTypes];
    }

    if (recipientDetailsForm.isFieldTouched('taxid')) {
      updatedRecipientData.taxid = fieldValues.taxid;
      updatedRecipientData.parentRecipientId = null;
    }

    if (recipientDetailsForm.isFieldTouched('sponsor')) {
      updatedRecipientData.parentRecipientId = fieldValues.sponsor;
      updatedRecipientData.taxid = null;
    }

    if (recipientDetailsForm.isFieldTouched('email')) {
      updatedRecipientData.email = fieldValues.email;
    }

    if (recipientDetailsForm.isFieldTouched('password')) {
      updatedRecipientData.encryptedPassword = fieldValues.password;
    }

    if (
      recipientDetailsForm.isFieldTouched('logoImageId') &&
      shouldUpdateLogoId(fieldValues.logoImageId)
    ) {
      updatedRecipientData.logoImageId = fieldValues.logoImageId;
    }

    if (recipientDetailsForm.isFieldTouched('ogTitle')) {
      updatedRecipientData.ogTitle = fieldValues.ogTitle?.trim() || null;
    }

    if (recipientDetailsForm.isFieldTouched('ogDescription')) {
      updatedRecipientData.ogDescription =
        fieldValues.ogDescription?.trim() || null;
    }

    const imageTasks = getImageTasks({
      imagesToDelete: fieldValues?.imagesToDelete,
      uploadedImages: fieldValues.uploadedImages,
      scrapedImages: fieldValues.scrapedImages,
      selectedLogoImageId: fieldValues.logoImageId,
    });

    await Promise.all(imageTasks);

    await updateRecipient({
      id: recipientId,
      ...updatedRecipientData,
    });

    clearRecipientQueryCache();

    if (queryString.giveId) {
      window.location.href = replaceRouteParams(routes.ADMIN_EDIT_GIVE, {
        giveId: queryString.giveId as string,
      });
    } else {
      window.location.href = routes.ADMIN_RECIPIENTS;
    }
  }

  const recipientDetailsFormValues = {
    name: recipient?.name,
    website: recipient?.website,
    recipientTypes: recipient?.recipientTypes?.[0],
    taxid: recipient?.taxid,
    sponsor: recipient?.parentRecipientId,
    email: recipient?.email,
    ogTitle: recipient?.ogTitle,
    ogDescription: recipient?.ogDescription,
    logoImageId: recipient?.logoImageId,
    imagesToDelete: [] as UploadFile[],
    uploadedImages: uploadedImagesFromDb?.map(imageToUploadFile),
    scrapedImages: scrapedImagesFromDb?.map(imageToUploadFile),
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!recipient) {
    return <PageNotFound />;
  }

  return (
    <>
      <Title title="Edit Recipient - Admin" />
      <AdminLayout>
        <S.Container>
          <Button type="variant1" onClick={goBack}>
            <LeftOutlined />
            Back
          </Button>
          <Box margin="22px 0 46px 0">
            <Text color={colors.darkEmphasis1} type="h1med2">
              Edit Recipient
            </Text>
          </Box>
          <Box margin="0 0 26px 0">
            <Text color={colors.darkEmphasis1} type="h3med2">
              Recipient Details
            </Text>
          </Box>
          <RecipientDetailsForm
            isEditingRecipient
            form={recipientDetailsForm}
            initialValues={recipientDetailsFormValues}
            isSubmitting={isSubmitting}
            onCancel={handleRecipientReset}
            onFinish={handleEditRecipient}
          />
        </S.Container>
      </AdminLayout>
    </>
  );
}

export default AdminEditRecipient;
