import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { BODY_2_REG_2 } from 'src/client/constants/text';

import { OptionsGroup } from '../../styles';

export const Container = styled.div`
  position: relative;
  margin-top: 60px;
  margin-bottom: 8px;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-control {
    div:not(:only-child):last-child {
      display: none !important;
    }
  }
`;

const DescriptionTitleText = css`
  width: 100%;
  text-align: center;

  font-family: ${(props) => props.theme.fontFamilies.vollkorn};
  font-size: 64px;
  font-weight: 400;
  line-height: 110%; /* 70.4px */
  letter-spacing: -1.28px;

  @media ${(props) => props.theme.screen.lg} {
    font-size: ${rem('165px')};
    line-height: ${rem('163.5px')};
    letter-spacing: -3.3px;
  }
`;

export const DescriptionTitle = styled.div`
  border-radius: 8px;
  padding: 8px 16px;
  border: 2px solid ${(props) => props.theme.colors.darkEmphasis1};

  ${DescriptionTitleText}
`;

export const GalleryTitleError = styled.span`
  margin-top: 4px;
  ${BODY_2_REG_2}
  color: ${(props) => props.theme.colors.danger500};
`;

export const TitleDisplayContainer = styled.span<{ isGalleryVisible: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: ${(props) => (props.isGalleryVisible ? '80px' : '92px')};

  ${DescriptionTitleText}
`;

export const TitleText = styled.span`
  display: block;
  ${DescriptionTitleText}
`;

export { OptionsGroup };
