import React from 'react';

import ShapeBase from 'src/client/pages/DonorGallery/components/IntroSection/components/Shape';
import {
  GALLERY_THEME_COLOR_VALUES,
  GalleryColor,
  GalleryShape,
} from 'src/commons/constants/gallery';

import * as stylesheet from './styles';

type Props = {
  shape: {
    name: GalleryShape;
    color: GalleryColor;
  };
  className?: string;
};

function Shape(props: Props) {
  return (
    <ShapeBase
      {...props}
      colorValues={GALLERY_THEME_COLOR_VALUES}
      styles={stylesheet}
    />
  );
}

export default Shape;
