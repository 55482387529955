import { Radio } from 'antd';
import { rem } from 'polished';
import styled from 'styled-components';

import { Flex, FlexCenter } from 'src/client/components/StyledCommon';

import { StyledText } from 'src/client/components/Text/styles';

import { radioButton } from '.';

export const TopCardsContainer = styled(Flex)`
  gap: 16px 32px;
  flex-wrap: wrap;

  @media (max-width: 945px) {
    justify-content: center;
  }
`;

export const BottomCardContainer = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(155px, 1fr));
  grid-gap: 16px;
  margin-top: 24px;

  @media (max-width: 945px) {
    grid-template-columns: 1fr;
  }
`;

export const RadioGroup = styled(Radio.Group)`
  --bgColor: ${(props) => props.value == radioButton.amount ? props.theme.colors.teal1 : props.theme.colors.magenta500};
  background-color: ${(props) => props.theme.colors.darkSurface3};
  display: grid;
  grid-auto-flow: column;
  border-radius: 6px;
  padding: 4px;
  gap: 6px;

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: ${(props) => props.value == radioButton.amount ? props.theme.colors.black : props.theme.colors.white};
    background: var(--bgColor);
    border-color: var(--bgColor);

    &:hover{
      color: ${(props) => props.value == radioButton.amount ? props.theme.colors.black : props.theme.colors.white};
      background: var(--bgColor);
      border-color: var(--bgColor);
    }

    &:before{
      background-color: transparent;
    }
  }

  .ant-radio-button-wrapper {
    display: flex;
    border: none;
    background: transparent;
    color: ${(props) => props.theme.colors.white};
    border-radius: 6px;
    padding: 4px 8px 4px 8px;
    align-items: center;
    justify-content: center;

    &:focus-within{
      box-shadow: none !important;
    }

    &:not(:first-child){
      &:before{
        background-color: transparent;
      }
    }
  }

  span:has(.anticon svg){
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .anticon{
    margin-right: 6px;
    & svg{
      font-size: ${rem('19px')};
      stroke-width: 5px;
    }
  }
`

export const Text = styled(StyledText)`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${(props) => props.theme.fontSizes.body2};
  line-height: ${rem('16.8px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`

export const EmptyGivesContainer = styled(FlexCenter)`
  height: 300px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.darkSurface3};
`;
