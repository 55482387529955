import { Dropdown, Grid } from 'antd';
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { Box, FlexCenter } from 'src/client/components';
import Text from 'src/client/components/Text';

import { getFirstWord } from 'src/commons/utils/StringUtils';

import { BUTTON_MEDIUM } from '../constants/text';

type ProfilePhotoProps = {
  profilePhotoUrl?: string;
};

type Props = {
  toolbarMenu: React.ReactElement<any>;
  profilePhoto?: string;
  userName: string | undefined;
};

const { useBreakpoint } = Grid;

function UserToolbar(props: Props) {
  const { profilePhoto, toolbarMenu, userName } = props;

  const screens = useBreakpoint();

  const greetings = screens.sm && userName && (
    <Greet type="buttonMedium">Hi {getFirstWord(userName)}</Greet>
  );

  return (
    <>
      <StyledDropdown />
      <Dropdown
        overlay={toolbarMenu}
        overlayClassName="user-toolbar-dropdown"
        placement="bottomRight"
      >
        <FlexCenter>
          <ProfilePhoto profilePhotoUrl={profilePhoto}></ProfilePhoto>
          {greetings}
          <Box margin="0 0 0 12px">
            <img src="/submenuIconDown.svg" />
          </Box>
        </FlexCenter>
      </Dropdown>
    </>
  );
}

const ProfilePhoto = styled.div<ProfilePhotoProps>`
  margin: 0 8px 0 0;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: white;
  background-image: url(${(props) => props.profilePhotoUrl});
  background-size: cover;
  background-position: center;
`;

const Greet = styled(Text)`
  color: white;
`;

export const StyledDropdown = createGlobalStyle`
  .user-toolbar-dropdown .ant-dropdown-menu {
    background: ${(props) => props.theme.colors.darkSurface1};
    border-radius: 8px;
  }

  .user-toolbar-dropdown .ant-dropdown-menu-item.ant-dropdown-menu-item-active, .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active, .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active, .ant-dropdown-menu-submenu-title:hover {
    background: ${(props) => props.theme.colors.darkSurface1};
  }

  .user-toolbar-dropdown .ant-dropdown-menu-item {
    color: ${(props) => props.theme.colors.white};
    ${BUTTON_MEDIUM}
  }
`;

export default UserToolbar;
