import { CloseOutlined } from '@ant-design/icons';
import { Affix, Grid } from 'antd';
import React from 'react';

import { Text } from 'src/client/components';

import { useLocalStorage } from 'src/client/hooks/useLocalStorage';

import * as S from './styles';

const { useBreakpoint } = Grid;

function MobileDismissableAlert() {
  const screens = useBreakpoint();
  const isMobile = !screens.md;

  const [isGalleryDismissableAlertClosed, setIsGalleryDismissableAlertClosed] =
    useLocalStorage('isGalleryDismissableAlertClosed', false);

  function handleClose() {
    setIsGalleryDismissableAlertClosed(true);
  }

  if (!isMobile) {
    return null;
  }

  if (isGalleryDismissableAlertClosed) {
    return null;
  }

  return (
    <Affix offsetTop={64}>
      <S.Container>
        <Text type="body2reg2">
          For a better editing experience, we recommend using a desktop browser
          for your final design
        </Text>
        <CloseOutlined onClick={handleClose} />
      </S.Container>
    </Affix>
  );
}

export default MobileDismissableAlert;
