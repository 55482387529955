import { getCurrentYear } from 'src/commons/utils/DateUtils';

const APRIL = 3;
const DAY_OF_DEADLINE = 15;

export const US_TAX_DEADLINE = new Date(
  getCurrentYear(),
  APRIL,
  DAY_OF_DEADLINE
);

export const RESOURCES = [
  {
    link: 'https://www.irs.gov/charities-non-profits/charitable-organizations/charitable-contribution-deductions',
    text: 'Charitable Contribution Deductions',
  },
  {
    link: 'https://www.thebalancesmb.com/tax-deductions-for-charitable-donations-2501942',
    text: 'The Basics of Tax Deductions for Charitable Donations',
  },
  {
    link: 'https://histphil.org/2019/04/23/highlighting-the-elitist-history-of-the-charitable-contribution-income-tax-deduction/',
    text: 'A History of Charitable Deduction',
  },
];

export const STATUS_KEYS = [
  { title: '“Yes”', description: 'Your give is tax-deductible.' },
  {
    title: '“Unknown”',
    description:
      'Your give may or may not be tax-deductible. Unfortunately, we were unable to determine the tax exempt status of your donation based on your receipt. You may have received other documentation about your donation’s tax status.',
  },
  {
    title: '“Partial”',
    description:
      'Your give is mostly taxedeductible, however there is an amount that is not tax-deductible. Most of the time this is because of goods or services received, such as with fundraiser event tickets or membership gifts.',
  },
];
