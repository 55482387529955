import React from 'react';
import styled from 'styled-components';

import { Text } from 'src/client/components';
import { STATUS_KEYS } from 'src/client/constants/tax';

import { CardsContainer } from '../styles';

function StatusKeys() {
  const renderItems = STATUS_KEYS.map(({ title, description }) => (
    <ItemContainer key={title}>
      <Text type="h5med2">{title}</Text>
      <Description type="body1reg2">{description}</Description>
    </ItemContainer>
  ));

  return (
    <div>
      <Text type="h3med2">Tax-Deductible Status Key</Text>
      <ItemsContainer>{renderItems}</ItemsContainer>
    </div>
  );
}

const ItemContainer = styled.div`
  padding: 24px;
  width: 100%;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.darkSurface2};
  color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.device.laptop} {
    width: 389px;
  }
`;

const ItemsContainer = styled(CardsContainer)`
  align-items: flex-start;
`;

const Description = styled(Text)`
  color: ${(props) => props.theme.colors.lightEmphasis2};
  margin-top: 9px;
`;

export default StatusKeys;
