import { Modal } from 'antd';
import DOMPurifiy from 'dompurify';
import React from 'react';
import styled from 'styled-components';

import LoadingCard from 'src/client/components/LoadingCard';
import { useGetGiveHtmlBody } from 'src/client/hooks/queries';

type Props = {
  giveId: string;
  isVisible: boolean;
  onCancel: () => void;
};

function ReceiptModal(props: Props) {
  const { giveId, isVisible, onCancel } = props;

  const { data: htmlBody, isLoading: isHtmlBodyLoading } =
    useGetGiveHtmlBody(giveId);

  const sanitizedHtmlBody = DOMPurifiy.sanitize(htmlBody || '');

  const loadingCard = isHtmlBodyLoading && (
    <ContentContainer>
      <LoadingCard isLoading={isHtmlBodyLoading} />
    </ContentContainer>
  );

  const receipt = htmlBody && (
    <ContentContainer
      dangerouslySetInnerHTML={{ __html: sanitizedHtmlBody }}
    ></ContentContainer>
  );

  return (
    <StyledModal
      data-cy="receipt-modal"
      footer={false}
      open={isVisible}
      width={866}
      onCancel={onCancel}
    >
      {receipt}
      {loadingCard}
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    height: 80vh;
    border-radius: 20px;
    overflow: hidden;

    svg {
      color: ${(props) => props.theme.colors.white};
    }
  }
  .ant-modal-body {
    padding: 40px 30px;
    height: 100%;
    background-color: ${(props) => props.theme.colors.darkSurface2};
  }
`;

const ContentContainer = styled.div`
  height: 100%;
  overflow: auto;
  background: white;
  /* color: white;

  a {
    color: ${(props) => props.theme.colors.darkPrimary100};
  } */
`;

export default ReceiptModal;
