import { Col, Row } from 'antd';
import React, { useEffect } from 'react';

import { useRouteMatch } from 'react-router-dom';

import { Box } from 'src/client/components';
import LoadingAnimation from 'src/client/components/LoadingAnimation';
import { useMergeAccountsMutation } from 'src/client/hooks/mutations';
import BasicLayout from 'src/client/layouts/BasicLayout';

import ActionSuccessPage from '../ActionSuccessPage';
import PageNotFound from '../PageNotFound';

import * as S from './styles';

type Params = {
  destinationDonorId: string;
  sourceDonorMergeToken: string;
};

function MergeAccountsSuccess() {
  const match = useRouteMatch<Params>();
  const {
    mutate: mergeAccounts,
    isLoading: isMergingAccounts,
    data: mergeAccountsResponse,
    error: mergeAccountsError,
  } = useMergeAccountsMutation();

  useEffect(() => {
    mergeAccounts({
      destinationDonorId: match.params.destinationDonorId,
      sourceDonorMergeToken: match.params.sourceDonorMergeToken,
    });
  }, []);

  if (mergeAccountsError) {
    return <PageNotFound />;
  }

  const content = isMergingAccounts ? (
    <S.SpinContainer>
      <S.LoadingText type="body">Transferring...</S.LoadingText>
      <Box margin="24px 0 0 0" />
      <LoadingAnimation />
    </S.SpinContainer>
  ) : (
    <Row justify="center">
      <Col lg={15} sm={15} xl={8} xs={22}>
        <ActionSuccessPage headerText={`Merge Accounts Success`}>
          Gives and alternate emails of{' '}
          <S.HighlightText>
            {mergeAccountsResponse?.data.sourceDonor.email}
          </S.HighlightText>{' '}
          have now been transferred to your primary account{' '}
          <S.HighlightText>
            {mergeAccountsResponse?.data.destinationDonor.email}
          </S.HighlightText>
        </ActionSuccessPage>
      </Col>
    </Row>
  );

  return <BasicLayout isOrnamentVisible>{content}</BasicLayout>;
}

export default MergeAccountsSuccess;
