export enum GiveFilterInput {
  SEARCH_QUERY = 'searchQuery',
  DATE_CREATED_RANGE = 'dateCreatedRange',
  TAX_DEDUCTIBLE = 'taxDeductible',
  STATUS = 'status',
}

export enum RecipientFilterInput {
  SEARCH_QUERY = 'searchQuery',
  RECIPIENT_TYPE = 'recipientType',
  GIVES = 'givesCountRange',
  DATE_CREATED_RANGE = 'dateCreatedRange',
}

export enum DonorsFilterInput {
  SEARCH_QUERY = 'searchQuery',
  GIVES = 'givesCountRange',
  LAST_GIVE_RANGE = 'lastGiveRange',
  DATE_CREATED_RANGE = 'dateCreatedRange',
  CURRENT_YEAR_GOAL_AMOUNT_RANGE = 'currentYearGoalAmountRange',
}

export enum PlatformFilterInput {
  SEARCH_QUERY = 'searchQuery',
  GIVES = 'givesCountRange',
}

export enum FilterType {
  DATE_RANGE = 'dateRange',
  NUMBER_RANGE = 'numberRange',
  SELECT = 'select',
  TEXT = 'text',
}
