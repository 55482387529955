import { Col, Form, Row } from 'antd';
import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { useTheme } from 'styled-components';

import { Box, FlexCenter, Input, Text } from 'src/client/components';
import { useResetPasswordMutation } from 'src/client/hooks/mutations';
import { useLoginMutation } from 'src/client/hooks/mutations/authMutations';
import { useVerifyResetPasswordToken } from 'src/client/hooks/queries';
import BasicLayout from 'src/client/layouts/BasicLayout';
import { UserRole } from 'src/commons/constants/roles';
import routes from 'src/commons/constants/routes';

import { LoadingPage, PageNotFound } from '..';

import * as S from './styles';

type FormValues = {
  password: string;
};

function InitialDonorPassword(props: RouteComponentProps<{ token: string }>) {
  const { match, history } = props;

  const { colors } = useTheme();
  const { data: verifyPasswordTokenResponse, isLoading } =
    useVerifyResetPasswordToken(match.params.token);
  const { mutateAsync: resetPassword, isLoading: isResetPasswordLoading } =
    useResetPasswordMutation();
  const { mutateAsync: login } = useLoginMutation();

  async function handleFinish(values: FormValues) {
    await resetPassword({
      password: values.password,
      token: match.params.token,
    });
    await login({
      userRole: UserRole.DONOR,
      password: values.password,
      email: verifyPasswordTokenResponse?.donorEmail as string,
    });

    window.location.href = routes.ROOT;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  if (verifyPasswordTokenResponse?.isTokenExpired) {
    history.push(routes.RESEND_INITIAL_PASSWORD_LINK);
  }

  if (!verifyPasswordTokenResponse?.isValid) {
    return <PageNotFound />;
  }

  return (
    <BasicLayout isOrnamentVisible>
      <Row justify="center">
        <Col lg={15} sm={15} xl={8} xs={22}>
          <div>
            <Box margin="88px 0 0 0" />
            <Text color={colors.teal1} type="h5bold2">
              ACCOUNT SET UP
            </Text>
            <Box margin="24px 0 40px 0">
              <Text color={colors.white} type="displaySBold2">
                Welcome to your Giving Side
              </Text>
            </Box>
          </div>
          <S.Card>
            <Text type="h3bold2">
              We’re so glad you’re here! Just one more step...
            </Text>
            <Box margin="16px 0 40px 0">
              <Text type="body1reg2">
                Create a password (at least 8 characters)
              </Text>
            </Box>
            <Form layout="vertical" onFinish={handleFinish}>
              <Form.Item
                label={<Text type="buttonRegular">Password</Text>}
                name="password"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <S.SetPasswordButton
                htmlType="submit"
                loading={isResetPasswordLoading}
                type="primary"
              >
                Set Password
              </S.SetPasswordButton>
            </Form>
            <Box margin="40px 0 0 0" />
            <FlexCenter>
              <Text isInline type="body1reg2">
                Already a member?&nbsp;
              </Text>
              <Link to={routes.DONOR_SIGN_IN}>
                <Text isInline type="body1reg2">
                  Log in here
                </Text>
              </Link>
            </FlexCenter>
          </S.Card>
        </Col>
      </Row>
    </BasicLayout>
  );
}

export default InitialDonorPassword;
