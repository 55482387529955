import { Select as AntdSelect } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select';
import React from 'react';

import { useModifyHandleDropdownRender } from 'src/client/hooks/useModifyHandleDropdownRender';

import * as S from './styles';

type Props = React.ComponentProps<typeof AntdSelect> & {
  theme?: 'dark' | 'light';
};

function Select(props: Props) {
  const { children, onChange, theme = 'light', ...selectProps } = props;

  const handleDropdownRender = useModifyHandleDropdownRender({
    ...props,
    theme,
  });

  function handleChange(
    value: any,
    options:
      | DefaultOptionType
      | BaseOptionType
      | (DefaultOptionType | BaseOptionType)[]
  ) {
    if (onChange) {
      onChange(value === undefined ? null : value, options);
    }
  }

  return (
    <S.StyledSelect
      dropdownRender={handleDropdownRender}
      onChange={handleChange}
      {...selectProps}
    >
      {children}
    </S.StyledSelect>
  );
}

Select.Option = AntdSelect.Option;

export default Select;
