import { rem } from 'polished';

import styled from 'styled-components';

import { Text } from 'src/client/components';
import { BaseButton } from 'src/client/components/Button/styles';
import { BODY_2_MED_2 } from 'src/client/constants/text';

type QuoteButtonProps = {
  isRandomize?: boolean;
};

export const Container = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const StyledButton = styled(BaseButton)<QuoteButtonProps>`
  background: ${(props) =>
    props.isRandomize
      ? props.theme.colors.randomizeYellow
      : props.theme.colors.seeAllPink};
  color: ${(props) => props.theme.colors.black};
  border: 2px solid black;
  border-radius: 8px;
  box-shadow: 0px 4px 0px 0px black;

  width: 50%;
  padding: 12px; /* Adjust padding for smaller screens */

  transition: background-color 0s;
  transition: transform 0.2s;

  :hover,
  &:focus,
  &:active {
    background: ${(props) =>
      props.isRandomize
        ? props.theme.colors.randomizeYellow
        : props.theme.colors.seeAllPink};
    border: 2px solid black;
    color: ${(props) => props.theme.colors.black};
  }

  @media (min-width: ${(props) => props.theme.size.laptop}) {
    display: flex;
    align-items: center;
    position: relative;
    padding: 24px 14px;
    width: 100%;
    max-height: 91px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.size.laptop}) {
    text-align: left;
  }
`;

export const StyledText = styled(Text)`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  font-size: ${rem('14px')};
  line-height: ${rem('17px')};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  color: ${(props) => props.theme.colors.lightHighEmphasis};

  height: 40px;
  margin-bottom: 8px;
  white-space: normal; // Allow white space to wrap

  & > br {
    @media (min-width: ${(props) => props.theme.size.laptop}) {
      display: none;
    }
  }

  @media ${(props) => props.theme.screen.md} {
    height: auto;
  }

  @media (min-width: ${(props) => props.theme.size.laptop}) {
    white-space: nowrap;
    margin: 0;
  }

  @media (min-width: ${(props) => props.theme.size.laptop}) {
    font-size: ${rem('20px')};
    line-height: ${rem('24px')};
  }
`;

export const StyledSubtitle = styled(Text)`
  ${BODY_2_MED_2}
  display: none;
  color: ${(props) => props.theme.colors.darkEmphasis2};

  @media (min-width: ${(props) => props.theme.size.laptop}) {
    display: block;
  }
`;

export const StyledIcon = styled.img`
  max-height: 70px;
  object-fit: contain;
  z-index: 10;

  @media (min-width: ${(props) => props.theme.size.laptop}) {
    max-height: 80px;
    position: absolute;
    top: -14px;
    right: 0px;
  }

  @media ${(props) => props.theme.screen.xl} {
    max-height: 94px;
  }
`;
