import React from 'react';

import { Button } from 'src/client/components';
import GiveCardBase from 'src/client/pages/DonorGallery/components/GiveCard';
import { PageRecipient } from 'src/commons/types';

import * as stylesheet from './styles';

type Props = {
  editModeConfig?: {
    primaryButtonText?: string;
    secondaryButtonText?: string;
    isPrimaryButtonHidden?: boolean;
    isSecondaryButtonHidden?: boolean;
    primaryButtonProps?: React.ComponentProps<typeof Button>;
    secondaryButtonProps?: React.ComponentProps<typeof Button>;
  };
  cyId?: string[];
  isDragOverlay?: boolean;
  isDraggable?: boolean;
  isEditMode?: boolean;
  pageRecipient: PageRecipient;
} & React.HTMLAttributes<HTMLDivElement>;

function GiveCard(props: Props) {
  return <GiveCardBase {...props} styles={stylesheet} />;
}

export default GiveCard;
