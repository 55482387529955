export const BASICS = 'basics';
export const PERSONALIZE_GIVING_SIDE = 'personalize-giving-side';
export const WHO_GIVES = 'more-about-who-gives';

export const DEFAULT_EDIT_PROFILE_TAB = BASICS;
export const EDIT_PROFILE_TABS = [BASICS, PERSONALIZE_GIVING_SIDE, WHO_GIVES];

export enum RECIPIENT_HOMEPAGE_TABS {
  OUR_PEOPLE = 'OUR_PEOPLE',
  GIVING_AND_GOAL = 'GIVING_AND_GOAL',
  HOW_OUR_PEOPLE_GIVE = 'HOW_OUR_PEOPLE_GIVE',
}

export enum RECIPIENT_SIGN_IN_TABS {
  LOGIN = 'LOGIN',
  REQUEST_ACCESS = 'REQUEST_ACCESS',
}
