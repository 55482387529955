import { useSortable } from '@dnd-kit/sortable';
import React, { useState } from 'react';

import { Button } from 'src/client/components';
import { LOW_OPACITY_VALUE } from 'src/commons/constants/gallery';
import { PageRecipient } from 'src/commons/types';

import RecipientLogoV2 from '../../../DonorGalleryV2/components/CardsSection/components/RecipientLogo';
import RecipientLogoBase from '../RecipientLogo';

import * as stylesheet from './styles';

type Props = {
  editModeConfig?: {
    primaryButtonText?: string;
    secondaryButtonText?: string;
    isPrimaryButtonHidden?: boolean;
    isSecondaryButtonHidden?: boolean;
    primaryButtonProps?: React.ComponentProps<typeof Button>;
    secondaryButtonProps?: React.ComponentProps<typeof Button>;
  };
  cyId?: string[];
  isDragOverlay?: boolean;
  isDraggable?: boolean;
  isEditMode?: boolean;
  pageRecipient: PageRecipient;
  styles?: any;
} & React.HTMLAttributes<HTMLDivElement>;

function GiveCard(props: Props) {
  const {
    pageRecipient,
    editModeConfig,
    isDragOverlay = false,
    isEditMode = false,
    isDraggable = isEditMode,
    cyId,
    styles,
    ...containerProps
  } = props;

  const S = styles ?? stylesheet;
  const RecipientLogo = styles ? RecipientLogoV2 : RecipientLogoBase;

  const [isButtonsVisible, setIsButtonsVisible] = useState(false);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: pageRecipient.id,
    disabled: !isDraggable,
  });

  const style = {
    transition,
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    opacity: isDragging ? LOW_OPACITY_VALUE : 1,
  };

  function handleMouseEnter() {
    if (!isTouchDevice()) {
      setIsButtonsVisible(true);
    }
  }

  function handleMouseLeave() {
    if (!isTouchDevice()) {
      setIsButtonsVisible(false);
    }
  }

  function toggleButtonVisibility() {
    setIsButtonsVisible(!isButtonsVisible);
  }

  const primaryButtonText = editModeConfig?.primaryButtonText ?? 'Feature';
  const secondaryButtonText = editModeConfig?.secondaryButtonText ?? 'Hide';

  const primaryButton = editModeConfig?.isPrimaryButtonHidden ? null : (
    <S.ActionButton
      data-cy="page-recipient-primary-btn"
      type="secondary"
      {...editModeConfig?.primaryButtonProps}
    >
      {primaryButtonText}
    </S.ActionButton>
  );
  const secondaryButton = editModeConfig?.isSecondaryButtonHidden ? null : (
    <S.ActionButton
      data-cy="page-recipient-secondary-btn"
      type="secondary"
      {...editModeConfig?.secondaryButtonProps}
    >
      {secondaryButtonText}
    </S.ActionButton>
  );

  const hoverOverlay = isEditMode &&
    isButtonsVisible &&
    !isDragging &&
    !isDragOverlay && (
      <S.HoverOverlay>
        {primaryButton}
        {secondaryButton}
      </S.HoverOverlay>
    );

  const moreCyIdAttributes = cyId ? cyId.join(' ') : '';
  const meatballsMenuIcon = isEditMode && (
    <S.MeatballsMenuIcon
      src="/meatballsMenuIcon.png"
      onClick={toggleButtonVisibility}
    />
  );

  return (
    <S.Container
      {...listeners}
      {...attributes}
      {...containerProps}
      data-cy={`page-recipient-card ${pageRecipient.id} ${moreCyIdAttributes}`}
      data-page-recipient-id={pageRecipient.id}
      isEditMode={isEditMode}
      ref={setNodeRef}
      style={style}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <S.RecipientLogoContainer>
        <RecipientLogo
          pageRecipient={pageRecipient}
          pageRecipientLogoReplacementTextType="h5bold2"
        />
      </S.RecipientLogoContainer>
      <S.Title>{pageRecipient.recipientName}</S.Title>
      {hoverOverlay}
      {meatballsMenuIcon}
    </S.Container>
  );
}

function isTouchDevice() {
  return !!('ontouchstart' in window || navigator.maxTouchPoints);
}

export default GiveCard;
