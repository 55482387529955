import { QueryClient, useQuery } from 'react-query';

import {
  getDonorById,
  getDonorByIds,
  getDonorEthnicities,
  getDonorFirstGive,
  getDonorGender,
  getDonorGoal,
  getDonors,
  verifyResetPasswordToken,
} from 'src/client/api/DonorApi';
import { GetDonorGivingGoalParams } from 'src/client/types/ApiParams';
import { Donor, DonorsFilter, Goal } from 'src/commons/types';

type UpdateGetDonorByIdParams = {
  queryClient: QueryClient;
  data: Partial<Donor>;
  donorId: string;
};

const GET_DONOR_BY_ID_KEY = 'GET_DONOR_BY_ID_KEY';
const GET_DONOR_BY_IDS_KEY = 'GET_DONOR_BY_IDS_KEY';
const GET_DONOR_GENDER_KEY = 'GET_DONOR_GENDER_KEY';
const GET_DONOR_ETHNICITY_KEY = 'GET_DONOR_ETHNICITY_KEY';
const GET_DONOR_FIRST_GIVE_KEY = 'GET_DONOR_FIRST_GIVE_KEY';
const GET_DONOR_GIVING_GOAL_KEY = 'GET_DONOR_GIVING_GOAL_KEY';
const GET_DONOR_GIVING_GOAL = 'GET_DONOR_GIVING_GOAL';
const VERIFY_DONOR_RESET_PASSWORD_TOKEN = 'VERIFY_DONOR_RESET_PASSWORD_TOKEN';

export function useGetDonorByIds(ids?: string[]) {
  return useQuery(
    [GET_DONOR_BY_IDS_KEY, ids],
    () => getDonorByIds(ids as string[]),
    {
      enabled: !!ids?.length,
    }
  );
}

export function useGetDonorGender(donorId: string) {
  return useQuery([GET_DONOR_GENDER_KEY, donorId], () =>
    getDonorGender(donorId)
  );
}

export function useGetDonorEthnicities(donorId: string) {
  return useQuery([GET_DONOR_ETHNICITY_KEY, donorId], () =>
    getDonorEthnicities(donorId)
  );
}

export function useGetDonorFirstGive(donorId: string) {
  return useQuery([GET_DONOR_FIRST_GIVE_KEY, donorId], () =>
    getDonorFirstGive(donorId)
  );
}

export function useGetDonorGoal(params: GetDonorGivingGoalParams) {
  return useQuery([GET_DONOR_GIVING_GOAL_KEY, params], () =>
    getDonorGoal(params)
  );
}

export function useGetDonors(filter: DonorsFilter) {
  return useQuery([GET_DONOR_GIVING_GOAL, { filter }], () => getDonors(filter));
}

export function useVerifyResetPasswordToken(token: string) {
  return useQuery([VERIFY_DONOR_RESET_PASSWORD_TOKEN, token], () =>
    verifyResetPasswordToken(token)
  );
}

export function useGetDonorById(donorId: string | undefined | null) {
  return useQuery(
    [GET_DONOR_BY_ID_KEY, donorId],
    () => getDonorById(donorId as string),
    {
      enabled: !!donorId,
    }
  );
}

export function updateDonorGoalQueryData(
  queryClient: QueryClient,
  data: Goal,
  queryParams: GetDonorGivingGoalParams
) {
  return queryClient.setQueryData(
    [GET_DONOR_GIVING_GOAL_KEY, queryParams],
    (old: Goal | undefined) => ({
      ...(old || {}),
      ...data,
    })
  );
}

export function updateGetDonorByIdQuery(params: UpdateGetDonorByIdParams) {
  const { data, donorId, queryClient } = params;

  return queryClient.setQueryData<Donor | undefined>(
    [GET_DONOR_BY_ID_KEY, donorId],
    (old) =>
      old && {
        ...old,
        ...data,
      }
  );
}
