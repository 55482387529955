import { ImageWithUrl } from 'src/commons/types';

import ApiClient from './ApiClient';

const apiVersion = '1.0';
const endpoint = 'images';
export const url = `${apiVersion}/${endpoint}`;

type GetRecipientLogoResponse = {
  recipientLogoImagePath: string;
};

type GetRecipientImagesResponse = {
  recipientImages: ImageWithUrl[];
};

type GetRecipientsLogoResponse = {
  recipientId: string;
  imagePath: string;
}[];

type GetPlatformsLogoResponse = {
  platformId: string;
  imagePath: string;
}[];

type GetPlatformImageResponse = {
  platformImagePath: string;
};

export async function getRecipientLogo(
  recipientId: string
): Promise<GetRecipientLogoResponse> {
  const { data } = await ApiClient.get<GetRecipientLogoResponse>(
    `${url}/recipient/logo/${recipientId}`
  );

  return data;
}

export async function getRecipientImages(
  recipientId: string
): Promise<GetRecipientImagesResponse> {
  const { data } = await ApiClient.get<GetRecipientImagesResponse>(
    `${url}/recipient/images/${recipientId}`
  );

  return data;
}

export async function deleteImages(ids: string[]): Promise<any> {
  const { data } = await ApiClient.delete<void>(url, {
    data: {
      ids,
    },
  });

  return data;
}

export async function getRecipientsLogo(
  recipientIds: string[]
): Promise<GetRecipientsLogoResponse> {
  const { data } = await ApiClient.get<GetRecipientsLogoResponse>(
    `${url}/recipient/logo/`,
    {
      params: {
        recipientIds,
      },
    }
  );

  return data;
}

export async function getPlatformsLogo(
  platformIds: string[]
): Promise<GetPlatformsLogoResponse> {
  const { data } = await ApiClient.get<GetPlatformsLogoResponse>(
    `${url}/platform/logo/`,
    {
      params: {
        platformIds,
      },
    }
  );

  return data;
}

export async function getPlatformImage(
  platformId: string
): Promise<GetPlatformImageResponse> {
  const { data } = await ApiClient.get<GetPlatformImageResponse>(
    `${url}/platform/${platformId}`
  );

  return data;
}
