import { Layout, Menu } from 'antd';
import styled from 'styled-components';

import theme from 'src/client/themes/default';

export const StyledSider = styled(Layout.Sider)`
  position: fixed;
  height: 100vh;
  background-color: ${theme.colors.darkSurface1};
  z-index: 99;
  border-right: 2px solid ${theme.colors.border2};

  svg {
    font-size: 20px;
  }
`;

export const MenuStyle = styled(Menu)`
  .ant-menu-item.ant-menu-item-selected {
    background-color: ${(props) => props.theme.colors.darkSurface1};
    border-color: ${(props) => props.theme.colors.teal1};
    color: ${(props) => props.theme.colors.teal1};
  }

  svg path {
    fill: ${(props) => props.theme.colors.white};
  }

  .ant-menu-item.ant-menu-item-selected svg path {
    fill: ${(props) => props.theme.colors.teal1};
  }

  .ant-menu-item {
    padding: 0 10px !important;
    border-right: 3px solid ${(props) => props.theme.colors.darkSurface1};
    display: flex;
    color: ${(props) => props.theme.colors.white};
  }

  margin-top: 29px;
  width: 100%;
  background: ${(props) => props.theme.colors.darkSurface1};
  border-right: 0;
`;

export const StyledMenuItem = styled(Menu.Item)`
  .ant-tooltip-placement-right {
    padding-left: 18px;
  }

  &&:hover {
    background-color: ${(props) => props.theme.colors.darkSurface3};
    border-color: ${(props) => props.theme.colors.gray10};
    color: ${(props) => props.theme.colors.white};
  }

  &&:hover.ant-menu-item-selected {
    color: ${(props) => props.theme.colors.teal1};
  }
`;

export const IconWrapper = styled.div`
  padding-top: 1px;
  margin-left: 3px;
`;
