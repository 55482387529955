import { Layout } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React from 'react';

import { useHistory } from 'react-router-dom';

import { Navigation } from 'src/client/components';
import MinimalisticFooter from 'src/client/components/MinimalisticFooter';
import { useGetRecipientLogo } from 'src/client/hooks/queries';

import routes, { publicRoutes } from 'src/commons/constants/routes';

import * as S from './styles';

const { Content } = Layout;

type Props = {
  children: React.ReactNode | React.ReactNodeArray;
  recipientId?: string;
  isOrnamentVisible?: boolean;
};

function BasicLayout(props: Props) {
  const { children, isOrnamentVisible, recipientId } = props;
  const history = useHistory();

  const { data: recipientLogo } = useGetRecipientLogo(recipientId);
  const screen = useBreakpoint();

  const currentRoute = history.location.pathname;
  const isInRegisterRoute = currentRoute === routes.DONOR_REGISTER;
  const isInPublicRoute = Object.values(publicRoutes).includes(currentRoute);

  const ornament = !isInRegisterRoute && !isInPublicRoute && isOrnamentVisible && screen.md && (
    <S.Ornament src="/ornament14.png" />
  );

  return (
    <S.StyledLayout>
      <Navigation
        isPositionFixed={false}
        profileImage={recipientLogo?.recipientLogoImagePath}
        shouldShowProfileMenuIten={false}
      />
      <Navigation
        isPositionFixed
        profileImage={recipientLogo?.recipientLogoImagePath}
        shouldShowProfileMenuIten={false}
        shouldShowSiderButton={!isInPublicRoute}
        shouldShowYearFilter={!isInPublicRoute}
      />
      <Content>
        {ornament}
        {children}
      </Content>
      <MinimalisticFooter />
    </S.StyledLayout>
  );
}

export default BasicLayout;
