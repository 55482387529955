import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { Box, Text } from 'src/client/components';
import Histogram from 'src/client/components/Histogram';
import { useGetPlatformsLogo } from 'src/client/hooks/queries';
import defaultTheme from 'src/client/themes/default';
import { ChartFields } from 'src/commons/constants/fields';
import { RecipientStat, RECIPIENT_TYPE } from 'src/commons/types';
import { capitalizeFirstLetter } from 'src/commons/utils/StringUtils';

import { WidthLimit } from '../../styles';
import PlatformCard from '../PlatformCard';

import * as S from './styles';

type Props = {
  currentRecipientStat?: RecipientStat;
};

type HandleHistogramColorsParams = {
  giveType: RECIPIENT_TYPE;
};

function HowOurPeopleGiveTab(props: Props) {
  const { currentRecipientStat } = props;

  const platformIds = currentRecipientStat?.donorGivesTopPlatforms.map(
    (topPlatform) => topPlatform.platformId
  );

  const { colors } = useContext(ThemeContext);
  const { data: platformsLogo } = useGetPlatformsLogo(platformIds);

  const typesOfGivingData = Object.values(RECIPIENT_TYPE).map(
    (recipientType) => ({
      count: getRecipientTypeGiveCount(recipientType),
      giveType: recipientType
        .split('_')
        .map((word) => capitalizeFirstLetter(word))
        .join(' '),
    })
  );

  function getRecipientTypeGiveCount(recipientType: RECIPIENT_TYPE) {
    return (
      currentRecipientStat?.recipientTypeStats.find(
        (recipientTypeStat) => recipientTypeStat.recipientType === recipientType
      )?.giveCount ?? 0
    );
  }

  const platformCards = currentRecipientStat?.donorGivesTopPlatforms.map(
    (topPlatform, i) => (
      <PlatformCard
        givesCount={topPlatform.platformCount}
        key={topPlatform.platformId}
        order={i + 1}
        platformLogo={
          platformsLogo?.find(
            (platformLogo) => platformLogo.platformId === topPlatform.platformId
          )?.imagePath
        }
        platformName={topPlatform.platformName}
      />
    )
  );

  return (
    <>
      <S.FirstSection>
        <WidthLimit>
          <S.FirstSectionContainer>
            <S.FirstTitleContainer>
              <Box margin="0 0 16px 0">
                <Text color={colors.lightMedEmphasis} type="h3med2">
                  Top Platforms
                </Text>
              </Box>
              <S.FirstTitleText color={colors.white} type="displaySMed2">
                Our people give through these platforms
              </S.FirstTitleText>
            </S.FirstTitleContainer>
            <div>{platformCards}</div>
          </S.FirstSectionContainer>
        </WidthLimit>
      </S.FirstSection>
      <S.SecondSection>
        <S.SecondTitleContainer>
          <S.SecondTitle type="displaySMed2">
            Our People Gave in Many Ways
          </S.SecondTitle>
          <S.Ornament src="/ornament.svg" />
        </S.SecondTitleContainer>
        <Histogram
          color={handleHistogramColors}
          data={typesOfGivingData}
          xField={ChartFields.GIVING_TYPE}
          yField={ChartFields.COUNT}
        />
      </S.SecondSection>
    </>
  );
}

function handleHistogramColors(params: unknown): string {
  const { giveType } = params as HandleHistogramColorsParams;

  const formattedGiveType = giveType.toLowerCase().replace(/ /g, '_');

  switch (formattedGiveType) {
    case RECIPIENT_TYPE.CROWDFUNDING:
      return '#26C0C6';
    case RECIPIENT_TYPE.FOR_PROFIT:
      return '#524AD0';
    case RECIPIENT_TYPE.FOR_PROFIT_B_CORP:
      return '#E6862D';
    case RECIPIENT_TYPE.INDIVIDUAL:
      return '#D83A8F';
    case RECIPIENT_TYPE.LABOR_UNION:
      return '#908CF7';
    case RECIPIENT_TYPE.NONPROFIT:
      return '#4FE074';
    case RECIPIENT_TYPE.NONPROFIT_FISCALLY_SPONSORED:
      return '#2E83E8';
    case RECIPIENT_TYPE.OTHER:
      return '#6E3EAE';
    case RECIPIENT_TYPE.POLITICAL_C4:
      return '#DEBE2A';
    case RECIPIENT_TYPE.POLITICAL_CANDIDATE:
      return '#2C8C6D';
    case RECIPIENT_TYPE.POLITICAL_PAC:
      return '#E6862D';
    default:
      return defaultTheme.colors.teal2;
  }
}

export default HowOurPeopleGiveTab;
