import { DollarCircleOutlined, HistoryOutlined } from '@ant-design/icons';
import { Radio, RadioChangeEvent } from 'antd';
import React, { useEffect, useState } from 'react';

import LoadingCard from 'src/client/components/LoadingCard';
import Text from 'src/client/components/Text';
import { DASHBOARD_VIEW } from 'src/client/constants/DonorDashboard';
import {
  groupGivesByYear,
  groupGivesByMonth,
} from 'src/client/utils/GiveUtils';
import { ChartFields } from 'src/commons/constants/fields';
import { Give, GIVE_UNIT, GroupedGiveByMonth, GroupedGiveByYear } from 'src/commons/types';
import { getCurrentYear } from 'src/commons/utils/DateUtils';

import * as S from './styles';

type Props = {
  dashboardView: string;
  gives: Give[] | undefined;
  isGivesLoading: boolean;
};

export enum GraphView {
  AMOUNT = 'Amount',
  TIME = 'Time',
}

function getLineGraphData(givesDataToDisplay: Give[], dashboardView: string) {
  if (dashboardView === DASHBOARD_VIEW.ALL_TIME) {
    return groupGivesByYear(givesDataToDisplay);
  } else if (dashboardView === DASHBOARD_VIEW.THIS_YEAR) {
    return groupGivesByMonth(givesDataToDisplay, getCurrentYear());
  } else {
    return groupGivesByMonth(
      givesDataToDisplay,
      Number(dashboardView)
    );
  }
}

function GivingOverTimeCard(props: Props) {
  const { dashboardView, gives, isGivesLoading } = props;

  const [graphView, setGraphView] = useState(GraphView.AMOUNT);
  const [lineGraphData, setLineGraphData] = useState<GroupedGiveByYear[] | GroupedGiveByMonth[]>([]);

  const [givesDataToDisplay, setGivesDataToDisplay] = useState<Give[]>([]);

  useEffect(() => {
    if (gives) {
      const data = gives.filter((give) =>
        graphView === GraphView.AMOUNT
          ? give.unit === GIVE_UNIT.DOLLARS
          : give.unit === GIVE_UNIT.HOURS
      );
      setGivesDataToDisplay(data);
    }
  }, [gives, graphView]);

  useEffect(() => {
    if (givesDataToDisplay) {
      const data = getLineGraphData(givesDataToDisplay, dashboardView);
      setLineGraphData(data);
    }
  }, [givesDataToDisplay, dashboardView]);

  function getXField() {
    if (dashboardView === DASHBOARD_VIEW.ALL_TIME) {
      return ChartFields.YEAR;
    } else {
      return ChartFields.MONTH;
    }
  }

  function handleGraphViewChange(e: RadioChangeEvent) {
    const value = e.target.value;
    setGraphView(value as GraphView);
  }

  const lineGraph = givesDataToDisplay && (
    <S.LineGraphContainer>
      <S.StyledLineGraph
        data={lineGraphData}
        giveUnit={
          graphView === GraphView.AMOUNT ? GIVE_UNIT.DOLLARS : GIVE_UNIT.HOURS
        }
        xField={getXField()}
        yField={ChartFields.TOTAL_AMOUNT}
      />
    </S.LineGraphContainer>
  );

  return (
    <S.Container>
      <S.HeadingContainer>
        <Text type="h3bold2">Total Giving Over Time</Text>
        <S.RadioGroup
          buttonStyle="solid"
          value={graphView}
          onChange={handleGraphViewChange}
        >
          <Radio.Button value={GraphView.AMOUNT}><DollarCircleOutlined /><S.Text>{GraphView.AMOUNT}</S.Text></Radio.Button>
          <Radio.Button value={GraphView.TIME}><HistoryOutlined /><S.Text>{GraphView.TIME}</S.Text></Radio.Button>
        </S.RadioGroup>
      </S.HeadingContainer>
      <LoadingCard isLoading={isGivesLoading}>{lineGraph}</LoadingCard>
    </S.Container>
  );
}

export default GivingOverTimeCard;
