import styled from 'styled-components';

import { Text } from 'src/client/components';

export const Ornament = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const TextHeading = styled(Text)`
  text-transform: uppercase;
`;
