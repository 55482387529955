import { Entity } from 'src/commons/constants/entities';

export const FILE_PATH_UPLOAD = {
  [Entity.DONOR]: {
    MODEL: Entity.DONOR.toLowerCase(),
    MOUNTED_AS: 'avatar',
  },
  [Entity.GROUP]: {
    MODEL: Entity.GROUP.toLowerCase(),
    MOUNTED_AS: 'logo',
  },
  [Entity.IMAGE]: {
    MODEL: Entity.IMAGE.toLowerCase(),
    MOUNTED_AS: 'file',
  },
  [Entity.PAGE]: {
    MODEL: Entity.PAGE.toLowerCase(),
    MOUNTED_AS: 'logo',
  },
};
