import React from 'react';

import { useTheme } from 'styled-components';

import LoadingCard from 'src/client/components/LoadingCard';
import { Flex } from 'src/client/components/StyledCommon';
import Text from 'src/client/components/Text';
import { GIVING_SIDE_EMAIL } from 'src/client/constants/email';
import { groupGivesByRecurring } from 'src/client/utils/GiveUtils';
import { Give, GIVE_FREQUENCY } from 'src/commons/types';

import { sortObjectsByDescending } from 'src/commons/utils/ArrayUtils';
import { formatToCurrency } from 'src/commons/utils/MoneyUtilts';

import * as S from './styles';

type Props = {
  gives: Give[] | undefined;
  isGivesLoading: boolean;
};

function RecurringGivesCard(props: Props) {
  const { gives, isGivesLoading } = props;

  const { colors } = useTheme();

  const groupedGiveByRecurring = gives && groupGivesByRecurring(gives);
  const sortedGroupedGiveByRecurring =
    groupedGiveByRecurring &&
    sortObjectsByDescending(groupedGiveByRecurring, 'monthlyGiveAmount');

  function getFrequencyText(frequency: GIVE_FREQUENCY) {
    if (frequency === GIVE_FREQUENCY.ANNUALLY) {
      return 'per year';
    }

    return `per ${frequency.replace('ly', '')}`;
  }

  const recurringGivesRow = sortedGroupedGiveByRecurring?.map((giveGroup) => (
    <S.RecurringGiveRowContainer
      key={`${giveGroup.recipientId}-${giveGroup.frequency}`}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Text color={colors.teal1} type="h6med2">
          {giveGroup.recipientName}
        </Text>
        <div>
          <Text type="body1reg2">
            {formatToCurrency(giveGroup.monthlyGiveAmount)}
          </Text>
          <Text color={colors.lightMedEmphasis} type="body1reg2">
            {getFrequencyText(giveGroup.frequency)}
          </Text>
        </div>
      </Flex>
    </S.RecurringGiveRowContainer>
  ));

  const isRecurringGivesEmpty = !sortedGroupedGiveByRecurring?.length;

  const recurringGivesContent = isRecurringGivesEmpty ? (
    <S.EmptyContentContainer>
      <div>
        <Text isInline color={colors.lightMedEmphasis} type="body1reg2">
          Forward any recurring giving receipt you already have to&nbsp;
        </Text>
        <a href={`mailto:${GIVING_SIDE_EMAIL}`}>
          <Text isInline color={colors.teal1} type="body1med2">
            {GIVING_SIDE_EMAIL}
          </Text>
        </a>
      </div>
    </S.EmptyContentContainer>
  ) : (
    <S.RecurringGivesRowContainer>
      {recurringGivesRow}
    </S.RecurringGivesRowContainer>
  );

  return (
    <S.Container>
      <Text type="h3bold2">Recurring Gives</Text>
      <LoadingCard isLoading={isGivesLoading}>
        {recurringGivesContent}
      </LoadingCard>
    </S.Container>
  );
}

export default RecurringGivesCard;
