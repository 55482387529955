import { DeleteOutlined } from '@ant-design/icons';
import { rem } from 'polished';

import styled from 'styled-components';

import { Button, GalleryTextArea } from 'src/client/components';
import { EditLabel } from 'src/client/pages/DonorGallery/styles';

export const GalleryDescriptionInputContainer = styled.div`
  position: relative;
`;

export const StyledEditLabel = styled(EditLabel)`
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.darkEmphasis1} !important;
`;

export const GalleryDescriptionTextArea = styled(GalleryTextArea)`
  width: 100%;
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${rem('18px')};
  line-height: ${rem('27px')};
  font-weight: ${(props) => props.theme.fontWeights.regular};

  border: ${(props) =>
    props.isEditing
      ? `2px solid ${props.theme.colors.darkEmphasis1}`
      : 'inherit'};

  &:hover,
  &:focus {
    border: ${(props) =>
      props.isEditing
        ? `2px solid ${props.theme.colors.darkEmphasis1}`
        : 'inherit'};
    box-shadow: none;
  }

  @media ${(props) => props.theme.screen.md} {
    font-size: ${rem('22px')};
    line-height: ${rem('33px')};
  }
`;

export const CollapseDescriptionButton = styled(DeleteOutlined)`
  top: -12px;
  right: -12px;
  position: absolute;

  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${rem('20px')};

  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);

  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.darkLowEmphasis};
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background: ${(props) => props.theme.colors.gray3};
    color: ${(props) => props.theme.colors.darkEmphasis1};
  }
`;

export const AddDescriptionButton = styled(Button)`
  width: 100%;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .anticon {
    margin-right: 8px;
  }

  && {
    padding: 8px;
  }
`;
