import React from 'react';

import styled from 'styled-components';

import { Indexable } from 'src/commons/types';

const spinSizes = {
  small: '14px',
  default: '20px',
  large: '32px',
}

type Props = {
  size?: 'small' | 'default' | 'large',
} & Indexable

function LoadingAnimation(props: Props) {
  const size = props.size || 'default';

  return <StyledDiv loadingSize={size} {...props} />;
}

const StyledDiv = styled.div<{ loadingSize: 'small' | 'default' | 'large' }>`
  width: ${(props) => spinSizes[props.loadingSize]};
  aspect-ratio: 1;
  --color: ${(props) => props.theme.colors.teal1};
  --_g: no-repeat radial-gradient(farthest-side, var(--color) 90%, #0000);
  background: var(--_g), var(--_g), var(--_g), var(--_g);
  background-size: 40% 40%;
  animation: d6 1s infinite;

  @keyframes d6 {
    0% {
      background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    }
    40%, 50% {
      background-position: 100% 100%, 100% 0, 0 0, 0 100%;
    }
    90%, 100% {
      background-position: 100% 100%, 0 100%, 0 0, 100% 0;
    }
  }
`;

export default LoadingAnimation;
