import { Input } from 'antd';

import styled, { css } from 'styled-components';

import { BODY_2_REG_2 } from 'src/client/constants/text';

const lightModeStyle = css`
  color: ${(props) => props.theme.colors.darkEmphasis1};
  border: 1px solid ${(props) => props.theme.colors.darkEmphasis3};
  padding: 0 8px;
  height: ${(props) => props.theme.input.middle.height};
  border-radius: 4px;
  line-height: 100%;
  ${BODY_2_REG_2};

  &&:hover,
  &&:focus,
  &&:active {
    border-color: black;
    box-shadow: none;
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.darkEmphasis3};
  }
`;

const darkModeStyle = css`
  border-radius: 4px;

  &,
  .ant-input-suffix,
  .ant-input-prefix {
    ${BODY_2_REG_2};
  }

  .ant-input-prefix {
    margin-right: 8px;
  }
`;

export const InputBase = styled(Input)`
  &.ant-input-lg {
    height: ${(props) => props.theme.input.large.height};
    padding: 0 12px;
    border-radius: 8px;
  }
`;

export const InputLightMode = styled(InputBase)`
  ${lightModeStyle}
`;

export const InputPasswordLightMode = styled(Input.Password)`
  ${lightModeStyle}

  &.ant-input-affix-wrapper-focused {
    border: 1px solid black;
    box-shadow: none;
  }

  &.ant-input-password-large {
    height: ${(props) => props.theme.input.large.height};
    padding: 0 12px;
    border-radius: 8px;
  }
`;

export const TextAreaLightMode = styled(Input.TextArea)`
  padding: 4px 8px;
  height: 48px;
  ${lightModeStyle};

  &.ant-input-lg {
    padding: 8px 12px;
    height: 64px;
    border-radius: 8px;
  }
`;

export const InputDarkMode = styled(InputBase)`
  ${darkModeStyle}
`;
