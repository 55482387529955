import { rem } from 'polished';
import styled from 'styled-components';

import { Flex, Text } from 'src/client/components';

export const FirstSection = styled.section`
  background-color: ${(props) => props.theme.colors.white};
  padding: 100px 40px;

  @media (${(props) => props.theme.screen.md}) {
    padding: 100px 106px;
  }
`;

export const FirstTitle = styled(Text)`
  font-size: ${rem('32px')};
  line-height: ${rem('42px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};

  @media ${(props) => props.theme.screen.lg} {
    width: 913px;
    font-size: ${rem('40px')};
    line-height: ${rem('52px')};
  }
`;

export const StatisticCardsContainer = styled(Flex)`
  margin-top: 52px;
  flex-wrap: wrap;
  gap: 32px 30px;
  justify-content: center;
`;

export const SecondSection = styled(FirstSection)`
  position: relative;

  padding: 117px 16px;

  @media (${(props) => props.theme.screen.md}) {
    padding: 274px 105px 105px 105px;
  }
`;

export const Ornament = styled.img`
  position: absolute;
  height: 440px;
  width: 440px;
  left: -255px;
  top: -175px;

  @media ${(props) => props.theme.screen.md} {
    height: 504px;
    width: 504px;
    left: -255px;
    top: -175px;
  }
`;

export const SectionContainer = styled(Flex)`
  flex-direction: column;

  @media ${(props) => props.theme.screen.lg} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const SectionTitle = styled(Text)`
  font-size: ${rem('32px')};
  line-height: ${rem('42px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  color: ${(props) => props.theme.colors.darkEmphasis1};

  @media ${(props) => props.theme.screen.md} {
    width: 388px;
  }

  @media ${(props) => props.theme.screen.lg} {
    font-size: ${rem('40px')};
    line-height: ${rem('52px')};
  }
`;

export const OrnamentTitleContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 208px;

  @media ${(props) => props.theme.screen.lg} {
    margin-top: 70px;
    margin-bottom: 0;
  }
`;

export const LineGraphsContainer = styled(Flex)`
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;

  @media ${(props) => props.theme.screen.lg} {
    justify-content: flex-end;
  }
`;
