import { Col, Form, Row } from 'antd';
import React from 'react';

import { Box, Flex, PictureCardUpload, Text } from 'src/client/components';
import Select from 'src/client/components/Select';
import { handleGetValueFromUploadEvent } from 'src/client/utils/FormUtils';
import {
  PLATFORM_COMPANY_TYPE,
  PLATFORM_STATUS_TYPE,
  PLATFORM_TYPE,
} from 'src/commons/types';
import { toTitleCase } from 'src/commons/utils/StringUtils';

import * as S from './styles';

type Props = React.ComponentProps<typeof Form> & {
  confirmButtonText?: string;
  cancelButtonText?: string;
  onCancel?: () => void;
};

const inputGutter = 30;
const { Option } = Select;

function PlatformDetailsForm(props: Props) {
  const {
    confirmButtonText = 'Add Platform',
    cancelButtonText = 'Cancel',
    onCancel,
    ...formProps
  } = props;

  const platformTypeOptions = Object.values(PLATFORM_TYPE).map(
    (platformType) => (
      <Option key={platformType} value={platformType}>
        {toTitleCase(platformType)}
      </Option>
    )
  );

  const platformCompanyTypeOptions = Object.values(PLATFORM_COMPANY_TYPE).map(
    (platformCompanyType) => (
      <Option key={platformCompanyType} value={platformCompanyType}>
        {toTitleCase(platformCompanyType.replace(/_/g, ' '))}
      </Option>
    )
  );

  const platformStatusTypeOptions = Object.values(PLATFORM_STATUS_TYPE).map(
    (platformStatusType) => (
      <Option key={platformStatusType} value={platformStatusType}>
        {toTitleCase(platformStatusType)}
      </Option>
    )
  );

  return (
    <>
      <S.StyledForm {...formProps} layout="vertical">
        <Row gutter={[inputGutter, 0]}>
          <Col span={12}>
            <Form.Item
              label="Platform Name"
              name="name"
              rules={[{ required: true, message: 'Platform name is required' }]}
            >
              <S.StyledFormInput placeholder="Platform Name" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Website" name="website">
              <S.StyledFormInput placeholder="Paste website URL" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[inputGutter, 0]}>
          <Col span={12}>
            <Form.Item
              label="Platform Type"
              name="platformType"
              rules={[{ required: true, message: 'Platform type is required' }]}
            >
              <S.StyledFormInputSelect
                showSearch
                placeholder="Select platform type"
                size="large"
              >
                {platformTypeOptions}
              </S.StyledFormInputSelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Company Type"
              name="platformCompanyType"
              rules={[{ required: true, message: 'Company type is required' }]}
            >
              <S.StyledFormInputSelect
                showSearch
                placeholder="Select company type"
                size="large"
              >
                {platformCompanyTypeOptions}
              </S.StyledFormInputSelect>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Status"
          name="platformStatusType"
          rules={[{ required: true, message: 'Status is required' }]}
        >
          <S.StatusSelect showSearch placeholder="Select Status" size="large">
            {platformStatusTypeOptions}
          </S.StatusSelect>
        </Form.Item>
        <Box margin="52px 0">
          <Form.Item
            getValueFromEvent={handleGetValueFromUploadEvent}
            label={
              <Box margin="0 0 19px 0">
                <Text type="body1med2">Images</Text>
              </Box>
            }
            name="imageUpload"
            valuePropName="fileList"
          >
            <PictureCardUpload beforeUpload={() => false} maxCount={1} />
          </Form.Item>
        </Box>
        <Flex>
          <S.AddPlatformButton htmlType="submit" type="primary">
            {confirmButtonText}
          </S.AddPlatformButton>
          <Box margin="0 0 0 20px">
            <S.CancelButton type="variant1" onClick={onCancel}>
              {cancelButtonText}
            </S.CancelButton>
          </Box>
        </Flex>
      </S.StyledForm>
    </>
  );
}

export default PlatformDetailsForm;
