import { LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router-dom';

import { Box, Button, Flex, Input, Title } from 'src/client/components';
import {
  useAddAdminMutation,
  useUpdateAdminMutation,
} from 'src/client/hooks/mutations';
import {
  addAdminsQueryData,
  getAdminsCache,
  updateAdminQueryData,
  updateAdminsQueryData,
  useGetAdmin,
} from 'src/client/hooks/queries';
import AdminLayout from 'src/client/layouts/AdminLayout';
import LoadingPage from 'src/client/pages/LoadingPage';
import routes from 'src/commons/constants/routes';

import * as S from '../styles';

type MatchParams = { adminId: string };

export type Values = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
};

export default function ActionPage() {
  const queryClient = useQueryClient();
  const editUserRouteMatch = useRouteMatch<MatchParams>(routes.EDIT_ADMIN);

  const [form] = Form.useForm();

  const userId = editUserRouteMatch?.params?.adminId;

  const { data: user, isLoading } = useGetAdmin(userId);

  const isAdding = !userId;
  const isEditting = !isAdding;

  const { isLoading: isAddUserLoading, mutate: addUser } = useAddAdminMutation({
    onSuccess: (user) => {
      const hasCache = getAdminsCache(queryClient);

      if (hasCache) {
        addAdminsQueryData(queryClient, user.data);
      }

      redirectToAdminTable();
    },
  });

  const { isLoading: isUpdateUserLoading, mutate: updateUser } =
    useUpdateAdminMutation({
      onSuccess: (user) => {
        const hasCache = getAdminsCache(queryClient);

        if (hasCache) {
          updateAdminQueryData(queryClient, user.data);
          updateAdminsQueryData(queryClient, user.data);
        }

        redirectToAdminTable();
      },
    });

  const isSubmitting = isAddUserLoading || isUpdateUserLoading;

  function handleFinish(formValues: Values) {
    const adminData: Partial<Values> = {
      firstName: formValues?.firstName,
      lastName: formValues?.lastName,
      email: formValues?.email,
      password: formValues?.password,
    };

    if (formValues.id) {
      updateUser({ ...adminData, id: formValues.id });
    } else {
      addUser(adminData);
    }
  }

  function redirectToAdminTable() {
    window.location.href = routes.ADMINS;
  }

  function createPageTitle() {
    return isEditting ? 'Edit Admin - Admin' : 'Add Admin - Admin';
  }

  if (isEditting && isLoading) {
    return (
      <AdminLayout>
        <LoadingPage />
      </AdminLayout>
    );
  }

  const title = isEditting ? 'Edit Admin' : 'Add Admin';

  const passwordLabel = isEditting ? 'New Password' : 'Password';

  const confirmPasswordLabel = isEditting
    ? 'Confirm New Password'
    : 'Confirm Password';

  const ctaBtnText = isEditting ? (
    'Save Changes'
  ) : (
    <>
      <PlusOutlined /> Create Admin
    </>
  );

  return (
    <>
      <Title title={createPageTitle()} />
      <AdminLayout>
        <S.Container>
          <Button type="variant1" onClick={redirectToAdminTable}>
            <LeftOutlined /> Back
          </Button>
          <S.HeaderTitle type="h4">{title}</S.HeaderTitle>
          <Row gutter={20}>
            <Col lg={12} md={24} sm={24} span={24} xl={12} xs={24}>
              <Form
                form={form}
                initialValues={user}
                layout="vertical"
                name="form_in_modal"
                onFinish={handleFinish}
              >
                <Form.Item hidden={true} label="ID" name="id">
                  <Input />
                </Form.Item>

                <Row gutter={20}>
                  <Col md={12} span={12} xs={24}>
                    <Form.Item
                      label="First Name"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: 'Please input the firstname!',
                        },
                      ]}
                    >
                      <Input placeholder="First Name" size="large" />
                    </Form.Item>
                  </Col>
                  <Col md={12} span={12} xs={24}>
                    <Form.Item
                      label="Last Name"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: 'Please input the lastname!',
                        },
                      ]}
                    >
                      <Input placeholder="Last Name" size="large" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the email!',
                    },
                    { type: 'email' },
                  ]}
                >
                  <Input placeholder="example@email.com" size="large" />
                </Form.Item>

                <Row gutter={20}>
                  <Col md={12} span={12} xs={24}>
                    <Form.Item
                      hasFeedback
                      label={passwordLabel}
                      name="password"
                      rules={[
                        {
                          required: isAdding,
                          message: 'Please input the password!',
                        },
                      ]}
                    >
                      <Input.Password
                        autoComplete="new-password"
                        placeholder={passwordLabel}
                        size="large"
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} span={12} xs={24}>
                    <Form.Item
                      hasFeedback
                      dependencies={['password']}
                      label={confirmPasswordLabel}
                      name="confirmPassword"
                      rules={[
                        {
                          required: isAdding,
                          message: 'Please input the password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              new Error(
                                'The two passwords that you entered do not match!'
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        autoComplete="new-password"
                        placeholder={confirmPasswordLabel}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Flex>
                  <Button
                    disabled={isSubmitting}
                    htmlType="submit"
                    type="primary"
                  >
                    {ctaBtnText}
                  </Button>
                  <Box margin="0 0 0 12px">
                    <Button
                      disabled={isSubmitting}
                      type="variant1"
                      onClick={redirectToAdminTable}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Flex>
              </Form>
            </Col>
          </Row>
        </S.Container>
      </AdminLayout>
    </>
  );
}
