import React from 'react';
import { Route, RouteChildrenProps, Switch } from 'react-router-dom';

import { useGetRecipientById } from 'src/client/hooks/queries';
import AdminLayout from 'src/client/layouts/AdminLayout';
import {
  LoadingPage,
  PageNotFound,
  PrivacyPolicyPage,
  RecipientHomepage,
} from 'src/client/pages';
import routes from 'src/commons/constants/routes';

type UrlParams = {
  recipientId: string;
};

function AdminRecipientRoutes(props: RouteChildrenProps<UrlParams>) {
  const { match } = props;

  const {
    data: recipient,
    isLoading: isRecipientLoading,
  } = useGetRecipientById(match?.params.recipientId);

  if (isRecipientLoading) {
    return <LoadingPage />;
  }

  if (!recipient) {
    return <PageNotFound />;
  }

  return (
    <AdminLayout>
      <Switch>
        <Route exact path={routes.PRIVACY_POLICY}>
          <PrivacyPolicyPage />
        </Route>
        <Route exact path={routes.ADMIN_RECIPIENT_ROOT}>
          <RecipientHomepage recipient={recipient} />
        </Route>
      </Switch>
    </AdminLayout>
  );
}

export default AdminRecipientRoutes;
