import styled from 'styled-components';

import { Flex, FlexCenter, Input, Text } from 'src/client/components';
import { DISPLAY_L_BOLD_2 } from 'src/client/constants/text';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled(Text)`
  max-width: 749px;
  text-align: center;
  color: black;
`;

export const IntroductionContainer = styled(Flex)`
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: 70px;
  text-align: center;

  i {
    ${DISPLAY_L_BOLD_2};
  }
`;

export const EarlyAccessInputContainer = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

export const NavigationBar = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 16px 40px;
  width: 100%;
  margin-bottom: 100px;
  flex-wrap: wrap;
`;

export const Logo = styled.img`
  width: 123px;
`;

export const EmailInput = styled(Input)`
  && {
    height: 50px;
    max-width: 300px;
  }
`;

export const SponsorsContainer = styled(Flex)`
  padding: 40px 60px;
  gap: 80px;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const SponsorsTitle = styled(Text)`
  padding: 0 10px;
  height: fit-content;
  border-radius: 100px;
  background: ${(props) => props.theme.colors.warning};
`;

export const SponsorsTitleContainer = styled(FlexCenter)`
  width: 100%;

  @media ${(props) => props.theme.device.tablet} {
    width: fit-content;
  }
`;

export const DschoolLogo = styled.img`
  width: 106px;

  @media ${(props) => props.theme.device.tablet} {
    width: 132px;
  }
`;

export const CardinalVenturesLogo = styled.img`
  width: 154px;

  @media ${(props) => props.theme.device.tablet} {
    width: 192px;
  }
`;

export const GuideStarLogo = styled.img`
  width: 146px;

  @media ${(props) => props.theme.device.tablet} {
    width: 182px;
  }
`;

export const EastBayCommunityLogo = styled.img`
  width: 112px;

  @media ${(props) => props.theme.device.tablet} {
    width: 139px;
  }
`;

export const JusticeFundersLogo = styled.img`
  width: 130px;

  @media ${(props) => props.theme.device.tablet} {
    width: 163px;
  }
`;
