import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { UserRole } from 'src/commons/constants/roles';

import {
  useSendAdminResetPasswordEmailMutation,
  useSendRecipientResetPasswordEmailMutation,
  useSendResetPasswordEmailMutation,
} from '../hooks/mutations';

import ResendPasswordLink from './ResendPasswordLink';

type UrlParams = { userRole: string };

function ResendResetPasswordLink(props: RouteComponentProps<UrlParams>) {
  const { match } = props;

  const {
    mutateAsync: sendDonorResetPasswordEmail,
    isLoading: isSendDonorResetPasswordEmailLoading,
  } = useSendResetPasswordEmailMutation();

  const {
    mutateAsync: sendRecipientResetPasswordEmail,
    isLoading: isSendRecipientPasswordEmailLoading,
  } = useSendRecipientResetPasswordEmailMutation();

  const {
    mutateAsync: sendAdminResetPasswordEmail,
    isLoading: isSendAdminResetPasswordEmailLoading,
  } = useSendAdminResetPasswordEmailMutation();

  const isPasswordEmailLoading =
    isSendDonorResetPasswordEmailLoading ||
    isSendRecipientPasswordEmailLoading ||
    isSendAdminResetPasswordEmailLoading;

  async function handleEmailSend(email: string) {
    switch (match.params.userRole) {
      case UserRole.ADMIN.toLowerCase():
        await sendAdminResetPasswordEmail(email);
        break;
      case UserRole.RECIPIENT.toLowerCase():
        await sendRecipientResetPasswordEmail(email);
        break;
      case UserRole.DONOR.toLowerCase():
        await sendDonorResetPasswordEmail(email);
        break;
    }
  }

  return (
    <ResendPasswordLink
      content={{
        emailInput: {
          header: 'Reset password link has expired',
          subheading:
            'Reset your password using the link we will send to your email',
        },
        success: {
          header: 'Reset password email sent!',
          subheading:
            'We’ve sent you an email the contains the link to reset your password.',
        },
      }}
      isSendEmailButtonLoading={isPasswordEmailLoading}
      onEmailSend={handleEmailSend}
    />
  );
}

export default ResendResetPasswordLink;
