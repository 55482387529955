import React from 'react';
import { Route, Switch } from 'react-router-dom';

import routes from 'src/commons/constants/routes';

import ActionPage from './components/ActionPage';
import ViewPage from './components/ViewPage';

export default function AdminUsersPage() {
  return (
    <Switch>
      <Route exact component={ViewPage} path={routes.ADMINS} />
      <Route exact component={ActionPage} path={routes.ADD_ADMIN} />
      <Route exact component={ActionPage} path={routes.EDIT_ADMIN} />
    </Switch>
  );
}
