import styled from 'styled-components';

import { Card, FlexCenter } from 'src/client/components';

type CoverImageProps = {
  imageSrc?: string;
};

export const GalleryCard = styled(Card)`
  margin: 0;
  overflow: hidden;
  border-color: ${(props) => props.theme.colors.darkSurface1};

  height: 171px;
  width: 100%;

  & > .ant-card-cover {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  & > .ant-card-cover > img {
    height: 100%;
    width: 100%;
  }

  @media (${(props) => props.theme.screen.md}) {
    height: 309px;
  }

  @media (${(props) => props.theme.screen.xl}) {
    height: 249px;
  }
`;

export const CoverImage = styled(FlexCenter)<CoverImageProps>`
  padding: 20px;
  height: auto;
  width: 100%;
  aspect-ratio: 1;
  background-image: url(${(props) => props.imageSrc || '/recipientBg.png'});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;
