import { EyeOutlined } from '@ant-design/icons';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  useSensor,
  useSensors,
  MouseSensor,
} from '@dnd-kit/core';
import { Switch } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import { Button, Flex, Text } from 'src/client/components';
import {
  RECIPIENT_TYPE_DISPLAY,
  RECIPIENT_TYPE_ORDER,
} from 'src/client/constants/pageRecipients';
import { useSectionStatus } from 'src/client/hooks/useSectionStatus';
import { analytics } from 'src/client/libs/segment';
import { useMovePageRecipientsEditData } from 'src/client/pages/DonorGallery/components/CardsSection/hooks/useMovePageRecipient';
import { STATUS_OPTIONS, SectionStatus } from 'src/client/types/Gallery';
import { sortAndGroupPageRecipients } from 'src/client/utils/PageRecipientUtils';
import { SEGMENT_EVENTS } from 'src/commons/constants/segment';
import {
  Page,
  PageRecipient,
  PageRecipientVisibility,
  RECIPIENT_TYPE,
  ValidRecipientType,
} from 'src/commons/types';

import FeaturedCardsEditMode from './components/FeaturedCardsEditMode';
import FeaturedCardsViewMode from './components/FeaturedCardsViewMode';
import GiveCard from './components/GiveCard';
import HiddenCardsEditMode from './components/HiddenCardsEditMode';
import UnfeaturedCardsEditMode from './components/UnfeaturedCardsEditMode';
import UnfeaturedCardsViewMode from './components/UnfeaturedCardsViewMode';

import * as S from './styles';

type Props = {
  isLoading: boolean;
  isSaveButtonLoading: boolean;
  isViewingPublicly: boolean;
  onSave: () => void;
  page: Page;
  pageRecipientsEditData: PageRecipient[];
  savedPageRecipients: PageRecipient[] | undefined;
  sectionStatus: SectionStatus;
  setPageRecipientsEditData: (newPageRecipients: PageRecipient[]) => void;
  setSectionStatus: (newStatus: STATUS_OPTIONS) => void;
  setVisibleTypes: React.Dispatch<React.SetStateAction<Set<RECIPIENT_TYPE>>>;
  visibleTypes: Set<RECIPIENT_TYPE>;
};

function CardsSection(props: Props) {
  const {
    isLoading,
    isViewingPublicly,
    page,
    pageRecipientsEditData,
    savedPageRecipients,
    sectionStatus,
    visibleTypes,
    setPageRecipientsEditData,
    setSectionStatus,
    setVisibleTypes,
  } = props;

  const { isSectionHasChanges: isCardsSectionHasChanges } =
    useSectionStatus(sectionStatus);
  const [activePageRecipient, setActivePageRecipient] =
    useState<PageRecipient | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const pageRecipients = pageRecipientsEditData;
  const unfilteredUnfeaturedPageRecipients = pageRecipients?.filter(
    (pageRecipient) =>
      pageRecipient.visibility === PageRecipientVisibility.UNFEATURED
  );

  const {
    sortedFeaturedPageRecipients: featuredPageRecipients,
    sortedUnfeaturedPageRecipients: unfeaturedPageRecipients,
    sortedHiddenPageRecipients: hiddenPageRecipients,
  } = sortAndGroupPageRecipients(pageRecipients, visibleTypes);

  const {
    featurePageRecipient,
    featuredCardsEditModeRef,
    hiddenCardsEditModeRef,
    hidePageRecipient,
    moveAndSetPageRecipientOrder,
    moveFeaturedPageRecipientToUnfeatured,
    moveHiddenPageRecipientToUnfeatured,
    unfeaturedCardsEditModeRef,
  } = useMovePageRecipientsEditData({
    pageRecipientsEditData,
    setPageRecipientsEditData,
    isCardsSectionHasChanges,
    setSectionStatus,
  });

  useEffect(() => {
    if (savedPageRecipients) {
      setPageRecipientsEditData(savedPageRecipients);
      setVisibleTypes(new Set(page.visibleRecipientTypes || []));
      setTempVisibleTypes(new Set(page.visibleRecipientTypes || []));
      setSectionStatus(STATUS_OPTIONS.EDITING_WITH_NO_CHANGES);

      analytics.track(
        SEGMENT_EVENTS.USER_CLICKED_AN_EDIT_GALLERY_SECTION_BUTTON,
        {
          page,
          ownerName: page.ownerName,
          section: 'cards',
        }
      );
    }
  }, [page, savedPageRecipients]);

  useEffect(() => {
    setTempVisibleTypes(visibleTypes);
  }, [visibleTypes]);

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;

    setActivePageRecipient(
      pageRecipientsEditData.find(
        (pageRecipient) => pageRecipient.id === active.id
      ) as PageRecipient
    );
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (!over) {
      return null;
    }

    const overPageRecipient = pageRecipients?.find(
      (pageRecipient) => pageRecipient.id === over?.id
    ) as PageRecipient;

    const isGoingToDropToDifferentVisibility =
      overPageRecipient.visibility !== activePageRecipient?.visibility;

    if (isGoingToDropToDifferentVisibility) {
      return null;
    }

    if (active.id !== over?.id) {
      const pageRecipientOver = pageRecipients?.find(
        (pageRecipient) => pageRecipient.id === over.id
      ) as PageRecipient;
      moveAndSetPageRecipientOrder({
        pageRecipients: pageRecipientsEditData,
        pageRecipienToMoveId: active.id as string,
        newOrderIndex: pageRecipientOver.order as number,
        newVisibility: pageRecipientOver.visibility,
      });
    }
  }

  const isFeaturedPageRecipientsEmpty = featuredPageRecipients?.length === 0;
  const isUnfeaturedPageRecipientsEmpty =
    unfeaturedPageRecipients?.length === 0;
  const isHiddenPageRecipientsEmpty = hiddenPageRecipients?.length === 0;

  const isEditing = !isViewingPublicly;

  const featuredCards = isEditing ? (
    <FeaturedCardsEditMode
      featuredPageRecipients={featuredPageRecipients}
      handleDragEnd={handleDragEnd}
      handleDragStart={handleDragStart}
      isLoading={isLoading}
      ref={featuredCardsEditModeRef}
      sensors={sensors}
      onHidePageRecipient={hidePageRecipient}
      onUnfeaturePageRecipient={moveFeaturedPageRecipientToUnfeatured}
    />
  ) : (
    <FeaturedCardsViewMode
      featuredPageRecipients={featuredPageRecipients}
      isLoading={isLoading}
    />
  );
  const unfeaturedCards = isEditing ? (
    <UnfeaturedCardsEditMode
      featuredPageRecipients={featuredPageRecipients}
      handleDragEnd={handleDragEnd}
      handleDragStart={handleDragStart}
      isLoading={isLoading}
      ref={unfeaturedCardsEditModeRef}
      sensors={sensors}
      unfeaturedPageRecipients={unfeaturedPageRecipients}
      onFeaturePageRecipient={featurePageRecipient}
      onHidePageRecipient={hidePageRecipient}
    />
  ) : (
    <UnfeaturedCardsViewMode
      isLoading={isLoading}
      unfeaturedPageRecipients={unfeaturedPageRecipients}
    />
  );
  const hiddenCardsContent = isEditing ? (
    <HiddenCardsEditMode
      handleDragEnd={handleDragEnd}
      handleDragStart={handleDragStart}
      hiddenPageRecipients={hiddenPageRecipients}
      isLoading={isLoading}
      ref={hiddenCardsEditModeRef}
      sensors={sensors}
      onFeaturePageRecipient={moveHiddenPageRecipientToUnfeatured}
    />
  ) : null;

  const giveCardOverlay = activePageRecipient ? (
    <GiveCard
      isDragOverlay
      isEditMode
      id={activePageRecipient.id}
      pageRecipient={activePageRecipient}
    />
  ) : null;

  const [isModalOpen, setModalOpen] = useState(false);
  const [tempVisibleTypes, setTempVisibleTypes] = useState(visibleTypes);

  function handleToggle(type: RECIPIENT_TYPE, isVisible: boolean) {
    setTempVisibleTypes((prev) => {
      const newSet = new Set(prev);

      if (isVisible) {
        newSet.add(type);
      } else {
        newSet.delete(type);
      }

      return newSet;
    });
  }

  function handleCancelEditingVisibleTypes() {
    setTempVisibleTypes(visibleTypes);
    setModalOpen(false);
  }

  function handleSaveVisibleTypes() {
    setVisibleTypes(tempVisibleTypes);
    setSectionStatus(STATUS_OPTIONS.EDITING_WITH_CHANGES);
    setModalOpen(false);
  }

  const isSaveDisabled =
    tempVisibleTypes.size === visibleTypes.size &&
    [...tempVisibleTypes].every((type) => visibleTypes.has(type));

  return (
    <DndContext
      autoScroll={{
        acceleration: 500,
      }}
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <S.GivesSection>
        <S.GivesSectionContentContainer isEditing={isEditing}>
          {(!isFeaturedPageRecipientsEmpty || isEditing) && (
            <>
              <S.CardsHeader isEditing={isEditing}>Featured</S.CardsHeader>
              {featuredCards}
            </>
          )}
          {(!isUnfeaturedPageRecipientsEmpty || isEditing) && (
            <>
              <S.RecipientFilterHeader isEditing={isEditing}>
                <S.CardsHeader isEditing={isEditing}>
                  Recipient Gallery
                </S.CardsHeader>
                {!isViewingPublicly && (
                  <Button
                    size="large"
                    type="secondary"
                    onClick={() => setModalOpen(true)}
                  >
                    <EyeOutlined />
                    Show/hide recipient type
                  </Button>
                )}
              </S.RecipientFilterHeader>
              {unfeaturedCards}
            </>
          )}
          {!isHiddenPageRecipientsEmpty && isEditing && (
            <>
              <S.CardsHeader isEditing={isEditing}>Hidden</S.CardsHeader>
              {hiddenCardsContent}
            </>
          )}
          <DragOverlay>{giveCardOverlay}</DragOverlay>
        </S.GivesSectionContentContainer>
      </S.GivesSection>

      <S.StyledModal
        footer={false}
        open={isModalOpen}
        onCancel={() => setModalOpen(false)}
      >
        <S.ModalHeading>
          <Text type="h4med2">Show/Hide Recipient Types</Text>
          <Text type="body2reg2">Filter recipients displayed by type</Text>
        </S.ModalHeading>

        <S.ModalTip>
          <Text>📌</Text>
          <Text>
            To hide specific recipients, hover/click on the recipient and select
            “Hide”
          </Text>
        </S.ModalTip>

        <S.RecipientFilterSection>
          <RecipientTypeToggle
            unfeaturedPageRecipients={unfilteredUnfeaturedPageRecipients}
            visibleTypes={tempVisibleTypes}
            onToggle={handleToggle}
          />
        </S.RecipientFilterSection>

        <Flex gap="8px" justifyContent="flex-end">
          <Button
            data-cy="cancel-save-adjective-btn"
            type="secondary"
            onClick={handleCancelEditingVisibleTypes}
          >
            Cancel
          </Button>
          <Button
            disabled={isSaveDisabled}
            type="primary"
            onClick={handleSaveVisibleTypes}
          >
            Save
          </Button>
        </Flex>
      </S.StyledModal>
    </DndContext>
  );
}

type RecipientTypeToggleProps = {
  onToggle: (type: RECIPIENT_TYPE, isVisible: boolean) => void;
  visibleTypes: Set<RECIPIENT_TYPE>;
  unfeaturedPageRecipients: PageRecipient[];
};

function shouldIncludeRecipientInType(
  recipientTypes: ValidRecipientType[] | undefined,
  typeToCheck: RECIPIENT_TYPE
): boolean {
  if (!recipientTypes) {
    return false;
  }

  if (typeToCheck === RECIPIENT_TYPE.OTHER) {
    return recipientTypes.some(
      (type) => type === RECIPIENT_TYPE.OTHER || type === 'political_campaign'
    );
  }

  return recipientTypes.includes(typeToCheck);
}

function RecipientTypeToggle(props: RecipientTypeToggleProps) {
  const { onToggle, visibleTypes, unfeaturedPageRecipients } = props;

  const getCountByType = (type: RECIPIENT_TYPE) =>
    unfeaturedPageRecipients.filter((recipient) =>
      shouldIncludeRecipientInType(recipient.recipientTypes, type)
    ).length;

  const sortedRecipientTypes = useMemo(
    () =>
      Object.values(RECIPIENT_TYPE).sort(
        (a, b) =>
          RECIPIENT_TYPE_ORDER.indexOf(a) - RECIPIENT_TYPE_ORDER.indexOf(b)
      ),
    []
  );

  return (
    <div>
      {sortedRecipientTypes.map((type) => {
        const count = getCountByType(type);
        const isVisible = visibleTypes.has(type);

        return (
          <S.RecipientTypeRow key={type}>
            <span>
              {RECIPIENT_TYPE_DISPLAY[type]} ({count})
            </span>
            <S.RecipientToggle>
              <span>{isVisible ? 'Show' : 'Hide'}</span>
              <Switch
                checked={isVisible}
                onChange={(checked) => onToggle(type, checked)}
              />
            </S.RecipientToggle>
          </S.RecipientTypeRow>
        );
      })}
    </div>
  );
}

export default CardsSection;
