import { Select as AntdSelect } from 'antd';
import React, { forwardRef } from 'react';
import { useTheme } from 'styled-components';

import { useModifyHandleDropdownRender } from 'src/client/hooks/useModifyHandleDropdownRender';

import * as S from './styles';

type Props = React.ComponentProps<typeof AntdSelect> & {
  theme?: 'dark' | 'light';
};

const SelectComponent = forwardRef<any, React.PropsWithChildren<Props>>(
  (props: Props, ref) => {
    const { children, theme = 'dark', ...selectProps } = props;

    const { colors } = useTheme();
    const handleDropdownRender = useModifyHandleDropdownRender({
      ...props,
      theme,
    });

    return (
      <>
        <S.SelectV2GlobalStyle />
        <S.StyledSelect
          dropdownRender={handleDropdownRender}
          dropdownStyle={{
            backgroundColor: colors.darkSurface3,
            borderRadius: '8px',
          }}
          listHeight={200}
          listItemHeight={30}
          ref={ref}
          {...selectProps}
        >
          {children}
        </S.StyledSelect>
      </>
    );
  }
);

type SelectType = typeof SelectComponent & {
  Option: typeof AntdSelect.Option;
};

const Select: SelectType = SelectComponent as SelectType;

Select.Option = AntdSelect.Option;

export default Select;
