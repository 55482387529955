import { Input as AntdInput } from 'antd';
import React from 'react';

import * as S from './styles';

type InputProps = React.ComponentProps<typeof AntdInput> & {
  theme?: 'dark' | 'light';
};
type PasswordProps = React.ComponentProps<typeof AntdInput.Password>;
type TextAreaProps = React.ComponentProps<typeof AntdInput.TextArea>;

function Input(props: InputProps) {
  const { theme = 'light', ...restProps } = props;

  if (theme === 'dark') {
    return <S.InputDarkMode {...restProps} />;
  }

  return <S.InputLightMode {...restProps} />;
}

function Password(props: PasswordProps) {
  return <S.InputPasswordLightMode {...props} />;
}

function TextArea(props: TextAreaProps) {
  return <S.TextAreaLightMode {...props} />;
}

Input.Password = Password;
Input.TextArea = TextArea;
Input.Group = AntdInput.Group;

export default Input;
