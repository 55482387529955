import React, { forwardRef } from 'react';

import * as S from './styles';

type Props = {
  children: React.ReactNode;
  className?: string;
  emptyStateInstructionText: React.ReactNode;
  instructionTabText: string;
  isEmpty: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const DragAndDropArea = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    className = '',
    emptyStateInstructionText,
    instructionTabText,
    isEmpty,
    ...dragAndDropAreaProps
  } = props;

  const content = isEmpty ? (
    <S.EmptyStateInstructionText type="body1reg2">
      {emptyStateInstructionText}
    </S.EmptyStateInstructionText>
  ) : (
    children
  );

  return (
    <div className={className} ref={ref}>
      <S.InstructionTabText className="instruction-tab" type="label1med2">
        {instructionTabText}
      </S.InstructionTabText>
      <S.DragAndDropArea
        className="drag-and-drop-area"
        {...dragAndDropAreaProps}
      >
        {content}
      </S.DragAndDropArea>
    </div>
  );
});

export default DragAndDropArea;
