export enum SEGMENT_EVENTS {
  FIRST_GIVE_SUBMITTED = 'User Submitted First Give',
  USER_SUBMITTED_GIVE = 'User Submitted Give',
  GIVE_PROCESSED = 'Give Processed',
  USER_SET_GOAL = 'User Set Goal',
  USER_CREATED_ACCOUNT = 'User Created Account',
  USER_ADDED_ALTERNATE_EMAILS = 'User Added Alternate Emails',
  USER_EXPORTED_GIVES = 'User Exported Gives',
  USER_UPDATED_TAX_DEDUCTIBLE_STATUS = 'User Updated Tax Deductible Status',
  USER_CLICKED_RECEIPT_ICON = 'User Clicked Receipt Icon',
  USER_OPENED_EDIT_GIVE_MODAL = 'User Opened Edit Give Modal',
  USER_CLOSED_EDIT_GIVE_MODAL = 'User Closed Edit Give Modal',
  USER_VISITED_RECIPIENT_WEBSITE = 'User Visited Recipient Website',
  USER_CLICKED_LINK_ICON = 'User Clicked Link Icon',
  USER_SAVED_EDIT_GIVE_MODAL = 'User Saved Edit Give Modal',
  USER_CLICKED_AN_EDIT_GALLERY_SECTION_BUTTON = 'User Clicked An Edit Gallery Section Button',
  USER_SAVED_A_GALLERY_SECTION = 'User Saved A Gallery Section',
  USER_PUBLISHED_THEIR_GALLERY = 'User Published Their Gallery',
}
