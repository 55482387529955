import { Col, Form, Row } from 'antd';
import React from 'react';

import styled from 'styled-components';

import { Input, InputNumber, Select, Text } from 'src/client/components';
import { TAX_DEDUCTIBLE } from 'src/commons/types';
import { capitalizeFirstLetter } from 'src/commons/utils/StringUtils';

const { Option } = Select;

const horizontalGutter = 12;

function DonationMatchingDetails() {
  const matchedDonationTaxDeductibleOptions = Object.values(TAX_DEDUCTIBLE).map(
    (taxDeductible) => (
      <Option key={taxDeductible} value={taxDeductible}>
        {capitalizeFirstLetter(taxDeductible)}
      </Option>
    )
  );

  return (
    <Container>
      <Row gutter={[horizontalGutter, 0]}>
        <Col flex={9}>
          <Form.Item
            label={<Label>Matched Amount</Label>}
            name="matchedDonationAmount"
            rules={[{ required: true, message: 'Amount is required' }]}
          >
            <InputNumber placeholder="Amount in USD" prefix="$" size="large" />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            initialValue="no"
            label={<Label>Tax Deductible?</Label>}
            name="matchedDonationTaxDeductible"
          >
            <Select showSearch size="large">
              {matchedDonationTaxDeductibleOptions}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label={<Label>Company</Label>} name="matchedDonationCompany">
        <Input placeholder="Add a company" size="large" />
      </Form.Item>
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 24px;
  padding: 16px;
  background-color: ${(props) => props.theme.colors.border1};
  border-radius: 8px;
`;

const Label = styled(Text).attrs(() => ({
  type: 'label1med2',
}))`
  color: ${(props) => props.theme.colors.darkEmphasis1};
`;

export default DonationMatchingDetails;
