import { Modal } from 'antd';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { Text } from 'src/client/components';
import {
  BODY_2_REG_2,
  BUTTON_2_2,
  LABEL_2_REG_2,
} from 'src/client/constants/text';

type EditingElementsProps = {
  isEditing: boolean;
};

export const GivesSection = styled.section`
  background: white;
`;

export const GivesSectionContentContainer = styled.div<EditingElementsProps>`
  margin: 0 auto;
  padding: 0 20px 80px;
  max-width: 1600px;

  @media ${(props) => props.theme.screen.md} {
    padding: 0 32px 80px;
  }

  @media ${(props) => props.theme.screen.xl} {
    padding: 0 76px 208px;
  }
`;

export const CardsHeader = styled(Text)<EditingElementsProps>`
  position: relative;
  padding: 80px 0 32px 0;

  color: ${(props) => props.theme.colors.gray11};

  font-family: ${(props) => props.theme.fontFamilies.vollkorn};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-size: 48px;
  line-height: 63px;
  letter-spacing: -0.96px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${(props) => props.theme.screen.md} {
    padding: ${(props) => (props.isEditing ? '128px 0 0 0' : '128px 0 32px 0')};

    font-size: ${rem('96px')};
    line-height: ${rem('125px')};
    letter-spacing: -1.92px;
  }
`;

export const RecipientFilterHeader = styled.div<EditingElementsProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${(props) =>
    props.isEditing &&
    css`
      ${CardsHeader} {
        padding: 0;
      }
    `}

  button {
    width: max-content;
    margin-bottom: 32px;
    padding: 8px 16px;
    display: flex;
    align-items: center;

    span {
      ${BUTTON_2_2}
    }

    span > svg {
      height: 16px;
      width: 16px;
    }
  }

  @media ${(props) => props.theme.screen.md} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    button {
      margin: 0;
    }
  }
`;

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 24px 24px 32px;

    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .ant-modal-content {
    border-radius: 8px;

    .ant-modal-close-x {
      padding-top: 12px;
      padding-right: 8px;
    }
  }

  @media ${(props) => props.theme.screen.sm} {
    max-width: 400px;
  }
`;

export const ModalHeading = styled.div`
  display: flex;
  flex-direction: column;

  & > p:last-child {
    max-width: 275px;
    color: ${(props) => props.theme.colors.darkEmphasis2};
  }
`;

export const ModalTip = styled.div`
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;

  border-radius: 4px;
  background: ${(props) => props.theme.colors.neutral6};

  & > p {
    ${BODY_2_REG_2}
  }

  & > p:first-child {
    font-size: ${rem('18px')};
  }
`;

export const RecipientFilterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const RecipientTypeRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #e0e0e0;

  span {
    ${BODY_2_REG_2}
  }
`;

export const RecipientToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    &.ant-switch-checked {
      background-color: ${(props) => props.theme.colors.green500};
    }
  }

  span {
    ${LABEL_2_REG_2}
    color: ${(props) => props.theme.colors.darkEmphasis2};
  }
`;
