import { Checkbox as AntdCheckbox } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

import { BODY_1_REG_2 } from '../constants/text';

type CheckboxProps = React.ComponentProps<typeof AntdCheckbox>;
type CheckboxGroupProps = React.ComponentProps<(typeof AntdCheckbox)['Group']>;

function Checkbox(props: CheckboxProps) {
  const { children, ...extras } = props;

  return <StyledCheckbox {...extras}>{children}</StyledCheckbox>;
}

function CheckboxGroup(props: CheckboxGroupProps) {
  return <StyledCheckboxGroup {...props} />;
}

Checkbox.Group = CheckboxGroup;

const checkboxStyle = css`
  .ant-checkbox-inner {
    border-color: ${(props) => props.theme.colors.neutral5};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.colors.darkEmphasis1} !important;
  }

  span {
    ${BODY_1_REG_2};
    color: ${(props) => props.theme.colors.title85};
  }
`;

const StyledCheckbox = styled(AntdCheckbox)`
  ${checkboxStyle}
`;

const StyledCheckboxGroup = styled(AntdCheckbox.Group)`
  ${checkboxStyle}
`;

export default Checkbox;
