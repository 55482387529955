import styled from 'styled-components';

import LoadingCard from '../LoadingCard';

export const Container = styled.div`
  position: relative;
  min-height: 447px;
  width: 100%;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.darkSurface2};
  border: none;
  color: ${(props) => props.theme.colors.white};

  @media ${(props) => props.theme.device.laptop} {
    height: 100%;
    min-height: 613px;
  }
`;

export const StyledLoadingCard = styled(LoadingCard)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
