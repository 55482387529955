import { QueryClient, useQuery } from 'react-query';

import {
  getPage,
  getPages,
  getDonorGalleryPage,
  getPageCustomImageUrls,
} from 'src/client/api/PageApi';
import { Page } from 'src/commons/types';
import { RequireField } from 'src/commons/utils/TypescriptUtils';

type CommonPageParams = {
  data: RequireField<Partial<Page>, 'id'>;
  queryClient: QueryClient;
};

type CommonOptions = {
  onSuccess: (option: Page) => void;
};

export const GET_PAGES_KEY = 'GET_PAGES_KEY';
export const GET_PAGE_KEY = 'GET_PAGE_KEY';
export const GET_GALLERY_PAGES_KEY = 'GET_GALLERY_PAGES_KEY';
export const GET_GALLERY_CUSTOM_IMAGES_KEY = 'GET_GALLERY_CUSTOM_IMAGES_KEY';

export function useGetPages(cursor?: string) {
  return useQuery([GET_PAGES_KEY, cursor], () => getPages({ cursor }));
}

export function useGetPage(id: string) {
  return useQuery([GET_PAGE_KEY, id], () => getPage(id));
}

export function useGetDonorGalleryPage(donorId: string, opts?: CommonOptions) {
  return useQuery(
    [GET_GALLERY_PAGES_KEY, donorId],
    () => getDonorGalleryPage(donorId),
    {
      ...opts,
      enabled: !!donorId,
    }
  );
}

export function updateGetPageQueryData(params: CommonPageParams) {
  const { data, queryClient } = params;

  queryClient.setQueryData<Page | undefined>(
    [GET_PAGE_KEY, data.id],
    (oldData: Page | undefined) =>
      oldData && {
        ...oldData,
        ...data,
      }
  );
}

export function useGetGalleryCustomImageUrls(pageId: string) {
  return useQuery([GET_GALLERY_CUSTOM_IMAGES_KEY, pageId], () =>
    getPageCustomImageUrls(pageId)
  );
}
