import { Form, Col } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { FlexCenter, Input, Text, Box } from 'src/client/components';
import useThemeContext from 'src/client/hooks/useTheme';
import { NO_SPECIAL_CHARACTERS_EXCEPT_SPACE } from 'src/commons/constants/regex';
import routes from 'src/commons/constants/routes';

import * as S from './styles';

type Props = {
  onRequestAccessSend: (formValues: any) => void;
};

const { TextArea } = Input;

function FormCard(props: Props) {
  const { onRequestAccessSend } = props;

  const { colors } = useThemeContext();

  const regexRule = {
    pattern: new RegExp(NO_SPECIAL_CHARACTERS_EXCEPT_SPACE),
    message: 'Special characters not accepted.',
  };

  const label = (
    <Col>
      <Text type="label1med2">Tell us a little about yourself</Text>
      <Box margin="5px 0 0 0" padding="0 3px 0 0">
        <Text color={colors.gray2} type="caption1reg2">
          We love our users and would love to know more about you.
        </Text>
      </Box>
    </Col>
  );

  const requiredRule = { required: true, message: 'Required Field.' };

  return (
    <S.Container>
      <Form layout="vertical" onFinish={onRequestAccessSend}>
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[requiredRule, regexRule]}
        >
          <Input maxLength={255} placeholder="First Name" size="large" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[requiredRule, regexRule]}
        >
          <Input maxLength={255} placeholder="Last Name" size="large" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            requiredRule,
            { message: 'Invalid email address.', type: 'email' },
          ]}
        >
          <Input maxLength={255} placeholder="Email" size="large" />
        </Form.Item>
        <Form.Item
          label="How did you hear about us?"
          name="hearAboutUs"
          rules={[requiredRule]}
        >
          <TextArea
            maxLength={255}
            placeholder="How did you hear about us?"
            rows={4}
            size="large"
          />
        </Form.Item>

        <Form.Item label={label} name="aboutYourself">
          <TextArea
            placeholder="Tell us a little about yourself…"
            rows={4}
            size="large"
          />
        </Form.Item>
        <S.SubmitButton htmlType="submit" type="primary">
          Submit Request
        </S.SubmitButton>
        <FlexCenter>
          <Text color={colors.black} type="body1reg2">
            Already have an account?&nbsp;
          </Text>
          <Link to={routes.DONOR_SIGN_IN}>
            <Text color={colors.teal2} type="body1reg2">
              Login
            </Text>
          </Link>
        </FlexCenter>
      </Form>
    </S.Container>
  );
}

export default FormCard;
