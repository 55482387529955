import { Checkbox } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 0;
  padding: 8px;
  width: 100%;
  border-radius: 4px;
  background: ${(props) => props.theme.colors.gray4};
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background: ${(props) => props.theme.colors.teal1};
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${(props) => props.theme.colors.darkEmphasis1};
  }
`;

export const CheckBoxTitleContainer = styled.div`
  margin-top: -3px;
`;
