import { Modal } from 'antd';

import styled from 'styled-components';

import { Flex } from 'src/client/components';
import { BODY_1_REG_2 } from 'src/client/constants/text';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;

    .ant-modal-body {
      white-space: nowrap;
      word-wrap: normal;
    }
  }
`;

export const SuccessActionButtonGroupContainer = styled(Flex)`
  justify-content: space-between;
  gap: 8px;

  button {
    padding: 8px;
  }
`;

export const PublicLinkDisplay = styled.div`
  padding: 8px;
  flex-grow: 1;
  background: ${(props) => props.theme.colors.gray4};
  border-radius: 8px;
  overflow: auto;

  ${BODY_1_REG_2};

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.darkMedEmphasis};
    border-radius: 6px;
  }

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
`;
