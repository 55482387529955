import { Card } from 'antd';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  display: flex;
  margin: 5px;
  padding: 0px;
  font-size: ${(props) => props.theme.fontSizes.buttonSize};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  border-radius: 20px;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;

  .ant-card-body {
    padding: 0px;
    width: 100%;
  }

  .ant-card-cover {
    height: 100%;
    width: 100%;
  }

  .ant-card-cover img {
    height: 100%;
    width: 100%;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
  }
`;
