import styled from 'styled-components';

import { FlexCenter, Text } from 'src/client/components';

export const DragAndDropArea = styled(FlexCenter)`
  margin-bottom: 72px;
  padding: 21px;
  flex-wrap: wrap;
  gap: 16px;

  border-radius: 4px;
  border: 1px dashed ${(props) => props.theme.colors.gray5};
  background: ${(props) => props.theme.colors.gray3};
`;

export const InstructionTabText = styled(Text)`
  margin-left: 10px;
  padding: 6px 10px;
  border-radius: 2px 2px 0 0;
  width: 265px;
  background: ${(props) => props.theme.colors.darkSecondary};
  color: ${(props) => props.theme.colors.white};
`;

export const EmptyStateInstructionText = styled(Text)`
  text-align: center;

  & * {
    font-family: inherit;
  }

  span {
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }
`;
