import React from 'react';

import { DASHBOARD_VIEW } from 'src/client/constants/DonorDashboard';
import { useGetYearsSinceFirstGive } from 'src/client/hooks/useGetYearsSinceFirstGive';

import * as S from './styles';

type Props = {
  className: string;
  donorId: string;
  value: string;
  onChange?: (value: string) => void;
};

function YearFilter(props: Props) {
  const { className, donorId, value, onChange } = props;

  const { giveYears } = useGetYearsSinceFirstGive(donorId, true);

  function handleChange(value: unknown) {
    if (onChange) {
      onChange(value as string);
    }
  }

  function makeFilterOptions() {
    const thisYearAndAllTimeOptions = [
      {
        value: DASHBOARD_VIEW.THIS_YEAR,
        label: 'This Year',
      },
      {
        value: DASHBOARD_VIEW.ALL_TIME,
        label: 'All Time',
      },
    ];

    const yearsOptions = giveYears.map((year) => ({
      value: `${year}`,
      label: `${year}`,
    }));

    return [...thisYearAndAllTimeOptions, ...yearsOptions];
  }

  return (
    <>
      <S.YearFilterGlobalStyle />
      <S.StyledSelect
        className={className}
        options={makeFilterOptions()}
        value={value}
        virtual={false}
        onChange={handleChange}
      />
    </>
  );
}

export default YearFilter;
