import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import { ActiveCampaignTrackingCode } from 'src/client/components';

import { AccountContext } from 'src/client/contexts/AccountContext';
import DonorLayout from 'src/client/layouts/DonorLayout';
import {
  DonorDashboard,
  DonorEditProfile,
  DonorHomePage,
  PageNotFound,
  PrivacyPolicyPage,
  PublicGallery,
} from 'src/client/pages';
import AlternateEmailVerification from 'src/client/pages/AlternateEmailVerification';
import DonorGalleryV2 from 'src/client/pages/DonorGalleryV2';
import InitialDonorPassword from 'src/client/pages/InitialDonorPassword';
import MergeAccountsSuccess from 'src/client/pages/MergeAccountsSuccess';
import ResendInitialPasswordLink from 'src/client/pages/ResendInitialPasswordLink';
import DonorTax from 'src/client/pages/TaxDeductions';
import routes from 'src/commons/constants/routes';
import { Donor } from 'src/commons/types';

function DonorPersonalRoutes() {
  const donor = useContext(AccountContext) as Donor;

  return (
    <>
      <Switch>
        <Route exact path={routes.DASHBOARD}>
          <DonorLayout>
            <DonorDashboard donor={donor} />
          </DonorLayout>
        </Route>
        <Route exact path={routes.DONOR_PERSONAL_DASHBOARD_VIEW}>
          <DonorLayout>
            <DonorDashboard donor={donor} />
          </DonorLayout>
        </Route>
        <Route path={routes.HOME}>
          <DonorLayout>
            <DonorHomePage donor={donor} />
          </DonorLayout>
        </Route>
        <Route exact path={routes.PERSONAL_GALLERY}>
          <DonorGalleryV2 isViewingPublicly={false} />
        </Route>
        <Route
          exact
          component={PrivacyPolicyPage}
          path={routes.PRIVACY_POLICY}
        />
        <Route exact component={PublicGallery} path={routes.PUBLIC_GALLERY} />
        <Route path={routes.DONOR_TAX}>
          <DonorLayout>
            <DonorTax donor={donor} />
          </DonorLayout>
        </Route>
        <Route component={DonorEditProfile} path={routes.EDIT_PROFILE} />;
        <Route
          exact
          component={ResendInitialPasswordLink}
          path={routes.RESEND_INITIAL_PASSWORD_LINK}
        />
        <Route
          exact
          component={InitialDonorPassword}
          path={routes.INITIAL_DONOR_PASSWORD}
        />
        <Route
          exact
          component={AlternateEmailVerification}
          path={routes.VERIFY_ALTERNATE_EMAIL}
        />
        <Route
          exact
          component={MergeAccountsSuccess}
          path={routes.MERGE_ACCOUNTS}
        />
        <Route component={PageNotFound} />;
      </Switch>
      <ActiveCampaignTrackingCode />
    </>
  );
}

export default DonorPersonalRoutes;
