import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { Line, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { useTheme } from 'styled-components';

import LoadingCard from 'src/client/components/LoadingCard';
import { Box, Flex } from 'src/client/components/StyledCommon';
import Text from 'src/client/components/Text';
import { useGetGoalAndGiveAmountHistory } from 'src/client/hooks/queries/goalQueries';

import { CardState } from '../..';

import * as S from './styles';

type Props = {
  donorId: string;
  setCardState: React.Dispatch<React.SetStateAction<CardState>>;
};

function AllTimeState(props: Props) {
  const { donorId, setCardState } = props;

  const theme = useTheme();
  const {
    data: goalAndGiveAmountHistory,
    isLoading: isGoalAndGiveAmountHistoryLoading,
  } = useGetGoalAndGiveAmountHistory(donorId);

  useEffect(() => {
    const isGoalAndGiveHistoryEmpty =
      Array.isArray(goalAndGiveAmountHistory) &&
      isEmpty(goalAndGiveAmountHistory);

    if (isGoalAndGiveHistoryEmpty) {
      setCardState(CardState.NO_GOAL_CURRENT_YEAR);
    }
  }, [goalAndGiveAmountHistory?.length]);

  function tooltipFormatter(value: ValueType, name: NameType) {
    const formattedName =
      name === 'goalAmount' ? 'Goal Amount' : 'Amount Given';

    return [`$${value.toLocaleString()}`, formattedName];
  }

  return (
    <S.Container>
      <LoadingCard isLoading={isGoalAndGiveAmountHistoryLoading}>
        <Text type="h3med2">Giving Over Time</Text>
        <S.LineContainer>
          <ResponsiveContainer>
            <S.StyledLineChart data={goalAndGiveAmountHistory}>
              <Line
                connectNulls
                dataKey="goalAmount"
                dot={{
                  fill: theme.colors.darkPrimary100,
                }}
                stroke={theme.colors.darkPrimary100}
                strokeWidth={2}
                type="linear"
              />
              <Line
                connectNulls
                dataKey="totalDonatedAmount"
                dot={{
                  fill: theme.colors.darkSecondary,
                }}
                stroke={theme.colors.darkSecondary}
                strokeWidth={2}
                type="linear"
              />
              <XAxis dataKey="year" />
              <YAxis hide tick={false} />
              <Tooltip formatter={tooltipFormatter as any} />
            </S.StyledLineChart>
          </ResponsiveContainer>
        </S.LineContainer>
        <Flex>
          <S.ColorLegend type="goal" />
          <Text type="label1med2">Goal</Text>
        </Flex>
        <Box margin="18px 0 0 0" />
        <Flex>
          <S.ColorLegend type="donation" />
          <Text type="label1med2">Amount Given</Text>
        </Flex>
      </LoadingCard>
    </S.Container>
  );
}

export default AllTimeState;
