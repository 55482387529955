import styled, { css } from 'styled-components';

import { DatePicker, Flex, Input, Select, Text } from 'src/client/components';
import { BODY_1_REG_2 } from 'src/client/constants/text';

const { RangePicker } = DatePicker;

const filterBaseStyle = css`
  padding: 8px 12px;
  height: 40px;
  ${BODY_1_REG_2};
`;

export const Container = styled.div`
  .ant-form-item {
    height: fit-content;
  }

  tbody tr {
    cursor: pointer;
  }
`;

export const HighlightedCell = styled(Text).attrs(() => ({
  type: 'body1reg2',
}))`
  color: ${(props) => props.theme.colors.teal2};
`;

export const SearchBar = styled(Input)`
  &&& {
    width: 318px;
    ${filterBaseStyle};
  }
`;

export const RecipientTypeSelect = styled(Select)`
  &&&& {
    width: 262px;

    .ant-select-selector {
      ${filterBaseStyle};
    }
  }
`;

export const LogoSelect = styled(Select)`
  &&&& {
    width: 98px;
  }
`;

export const DateCreatedPicker = styled(RangePicker)`
  width: 300px;
  ${filterBaseStyle};
`;

export const ActionButtonsContainer = styled(Flex)`
  justify-content: space-around;
  align-items: center;

  svg {
    color: ${(props) => props.theme.colors.teal2};
  }
`;
