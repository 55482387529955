export enum Environments {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export const deployedEnvironments = [
  Environments.STAGING,
  Environments.PRODUCTION,
];
