import styled from 'styled-components';

import { Flex } from 'src/client/components';

export const Bg = styled.div`
  width: 100%;
  background: ${(props) => props.theme.colors.gray4};
`;

export const Container = styled(Flex)`
  width: 100%;
  padding: 70px 0;
  flex-direction: column;
  align-items: center;
  gap: 19px;
`;

export const TestimonialCard = styled(Flex)`
  max-width: 666px;
  background: white;
  border-radius: 16px;
  padding: 24px;
  column-gap: 42px;
  row-gap: 18px;
  flex-direction: column;

  @media ${(props) => props.theme.screen.md} {
    flex-direction: row;
  }
`;

export const TestimonialPerson = styled.img`
  height: 136px;
  width: 136px;
  border-radius: 16px;
`;
