export const NON_DIGIT_EXCEPT_PERIOD = /[^.\d]/g;
export const ALL_DIGIT_EXCEPT_HYPHEN = /(?!-)\D/g;
export const NO_SPECIAL_CHARACTERS_EXCEPT_SPACE = /^[a-zA-Z ]*$/g;
export const REGEX_REMOVE_SPECIFIC_SYMBOLS = /[>]/g;
export const REGEX_SPLIT_SPECIFIC_SYMBOLS = /(?=\s|[!])/g;
export const REGEX_NEWLINE = /(?:\\[rn])+/g;
export const REGEX_MULTIPLE_SPACES = /\s\s+/g;
export const REGEX_WHITE_SPACE = /\s/g;
export const REGEX_NEWLINE_INSENSITIVE = /\n/gi;
export const ISO_8601_ZULU_DATE_PATTERN =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})$/;
export const REGEX_HTTPS = /^https?:\/\//;
export const ISO_8601_NON_ZULU_DATE_PATTERN =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?$/;
