import styled from 'styled-components';

import { Flex, FlexCenter } from 'src/client/components';

export const FeaturesBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  width: 100%;
`;

export const SlantBg = styled.img`
  width: 100%;
`;

export const FeaturesBg = styled(Flex)`
  justify-content: center;
  padding-bottom: 70px;
  width: 100%;
  background: ${(props) => props.theme.colors.givingSideMidnight};
  color: ${(props) => props.theme.colors.white};
`;

export const FeatureContainer = styled(FlexCenter)`
  flex-wrap: wrap;
  gap: 23px;
  width: 100%;
`;

export const FeatureDescriptionContainer = styled(Flex)`
  width: 507px;
  flex-direction: column;
  gap: 22px;
`;

export const FeatureImg = styled.img`
  width: 100%;
  max-width: 500px;
`;

export const FeaturesContainer = styled(Flex)`
  width: 100%;
  gap: 60px;
  align-items: center;
  flex-direction: column;

  @media ${(props) => props.theme.screen.md} {
    margin: -200px 0 0 0;
  }
`;
