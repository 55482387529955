import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { Button, Text } from 'src/client/components';

import { UnderlinedButton } from '../../styles';

type ContainerProps = {
  isSpacer: boolean;
};

export const Container = styled(motion.div)<ContainerProps>`
  display: flex;
  flex-wrap: wrap;
  padding: 12px 24px;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.darkEmphasis3};
  visibility: ${(props) => (props.isSpacer ? 'hidden' : 'visible')};

  ${(props) =>
    props.isSpacer
      ? css`
          position: relative;
          visibility: hidden;
        `
      : css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: 100;
        `}
`;

export const CloseEditButton = styled(Button)`
  padding: 12px 16px;
`;

export const UnsavedSectionText = styled(Text)`
  color: ${(props) => props.theme.colors.darkEmphasis1};
`;

export const DiscardButton = styled(UnderlinedButton)`
  &,
  &:hover {
    box-shadow: none;
    color: ${(props) => props.theme.colors.darkMedEmphasis};
  }
`;
