import { AxiosResponse } from 'axios';

export function downloadFile(response: AxiosResponse<any>, filename: string) {
  const type = response.headers['content-type'];
  const fileBlob = new Blob([response.data], { type });

  // IE variant
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveOrOpenBlob(fileBlob, filename);

    return;
  }

  const url = window.URL.createObjectURL(fileBlob);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);

  document.body.appendChild(link);

  link.click();
  link.remove();
}
