import { Button, Input } from 'antd';
import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import routes from 'src/commons/constants/routes';

type Props = {
  className?: string;
};

function LegacyEmailCta(props: Props) {
  const { className = '' } = props;

  return (
    <Container className={className}>
      <EmailInput placeholder="Email Address" />
      <StyledSubmit>
        <Link to={routes.DONOR_REGISTER}>Request early access</Link>
      </StyledSubmit>
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  position: relative;
  margin-top: 1.9rem;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 0.4rem 0.5rem rgb(0 0 0 / 20%);
  border-radius: 0.4rem;
  overflow: hidden;
`;

export const EmailInput = styled(Input)`
  padding: 1rem;
  width: 100%;
  border: 1px solid #cccccc;
  font-size: 1.2rem;
  line-height: 1.4rem;
`;

export const StyledSubmit = styled(Button)`
  position: absolute;
  padding: 1.1rem;
  height: 100%;
  font-size: 1rem;
  line-height: 1.2rem;
  border-radius: 0.3rem;
  border: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0.1rem solid #e0e0e0;
  right: 0;
  top: 0;
  bottom: 0;

  &&& {
    background-color: #2fe9f8;
    color: black;
  }
`;

export default LegacyEmailCta;
