import { Col, Form, Row } from 'antd';
import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Box, Button, Input, Text } from 'src/client/components';
import LandingPageFooter from 'src/client/components/LandingPageFooter';
import {
  useSendAdminResetPasswordEmailMutation,
  useSendRecipientResetPasswordEmailMutation,
  useSendResetPasswordEmailMutation,
} from 'src/client/hooks/mutations';
import { UserRole } from 'src/commons/constants/roles';
import routes from 'src/commons/constants/routes';
import { capitalizeFirstLetter } from 'src/commons/utils/StringUtils';

import * as S from './styles';

type FormValues = {
  email: string;
};

type UrlParams = {
  userRole: string;
};

function SendResetPasswordEmail(props: RouteComponentProps<UrlParams>) {
  const { history, match } = props;

  const {
    mutateAsync: sendDonorResetPasswordEmail,
    isLoading: isSendResetPasswordEmailLoading,
  } = useSendResetPasswordEmailMutation();
  const {
    mutateAsync: sendRecipientResetPasswordEmail,
    isLoading: isSendRecipientPasswordEmailLoading,
  } = useSendRecipientResetPasswordEmailMutation();
  const {
    mutateAsync: sendAdminResetPasswordEmail,
    isLoading: isSendAdminPasswordEmailLoading,
  } = useSendAdminResetPasswordEmailMutation();

  const userRoleMatch = capitalizeFirstLetter(match.params.userRole);

  const isLoadingResetPassword =
    isSendResetPasswordEmailLoading ||
    isSendRecipientPasswordEmailLoading ||
    isSendAdminPasswordEmailLoading;

  async function handleSend(values: FormValues) {
    switch (userRoleMatch) {
      case UserRole.ADMIN:
        await sendAdminResetPasswordEmail(values.email);
        history.push(routes.ADMIN_SIGN_IN);
        break;
      case UserRole.RECIPIENT:
        await sendRecipientResetPasswordEmail(values.email);
        history.push(routes.RECIPIENT_SIGN_IN);
        break;
      case UserRole.DONOR:
        await sendDonorResetPasswordEmail(values.email);
        history.push(routes.DONOR_SIGN_IN);
        break;
    }
  }

  return (
    <>
      <S.Container>
        <S.NavigationBar>
          <div>
            <Link to="/">
              <S.Logo src="/logo.png" />
            </Link>
          </div>
        </S.NavigationBar>
        <Row justify="center">
          <Col md={24} xs={20}>
            <S.BodyContainer>
              <S.Header type="displayMBold2">Forgot your password?</S.Header>
              <Form
                wrapperCol={{
                  span: 24,
                }}
                onFinish={handleSend}
              >
                <Text type="body1med2">
                  Enter your primary Giving Side email and we&apos;ll send you
                  instructions on how to reset your password
                </Text>
                <Box margin="8px 0 0 0" />
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email',
                    },
                    {
                      type: 'email',
                      message: `Invalid email address`,
                    },
                  ]}
                >
                  <Input placeholder="Email" size="large" />
                </Form.Item>
                <Box margin="24px 0 0 0" />
                <Form.Item noStyle>
                  <Button
                    htmlType="submit"
                    loading={isLoadingResetPassword}
                    type="primary"
                  >
                    Send reset instructions
                  </Button>
                </Form.Item>
              </Form>
              <S.SignInContainer>
                <Text type="body1reg2">Already have an account?&nbsp;</Text>
                <Link to={routes.DONOR_SIGN_IN}>
                  <Text type="body1med2">Sign In</Text>
                </Link>
              </S.SignInContainer>
            </S.BodyContainer>
          </Col>
        </Row>
      </S.Container>
      <LandingPageFooter />
    </>
  );
}

export default SendResetPasswordEmail;
