import { DefaultProperties } from './Common.type';
import { ValidRecipientType } from './Recipient.type';

export type PageRecipient = {
  comment?: string | null;
  imageLegacyId?: string | null;
  imagePath: string | null;
  order?: number | null;
  pageLegacyId?: string | null;
  recipientId: string;
  recipientLegacyId?: string | null;
  recipientName?: string;
  recipientWebsite?: string | null;
  recipientTypes?: ValidRecipientType[];
  pageId: string;
  visibility: PageRecipientVisibility;
} & DefaultProperties;

export enum PageRecipientVisibility {
  FEATURED = 'featured',
  UNFEATURED = 'unfeatured',
  HIDDEN = 'hidden',
}
