import { Tabs } from 'antd';
import React from 'react';

import * as S from './styles';

type Props = {
  textBody: string | null | undefined;
  htmlBody: string | null | undefined;
};

const { TabPane } = Tabs;

function Receipt(props: Props) {
  const { htmlBody, textBody } = props;

  return (
    <S.StyledTabs defaultActiveKey="htmlBody">
      <TabPane key="textBody" tab="Text">
        <S.ReceiptBody body={textBody} className="receipt"></S.ReceiptBody>
      </TabPane>
      <TabPane key="htmlBody" tab="HTML">
        <S.ReceiptBody body={htmlBody} className="receipt"></S.ReceiptBody>
      </TabPane>
    </S.StyledTabs>
  );
}

export default Receipt;
