import { Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { Text } from 'src/client/components';

import { STATUS_KEYS } from 'src/client/constants/tax';

type Props = {
  isVisible: boolean;
  onClose: () => void;
};

function StatusKeysModal(props: Props) {
  const { isVisible, onClose } = props;

  const renderStatusKeyItems = STATUS_KEYS.map(({ title, description }) => (
    <Card key={title}>
      <Text type="h5med2">{title}</Text>
      <Description type="body1reg2">{description}</Description>
    </Card>
  ));

  return (
    <StyledModal
      centered
      data-cy="tax-deductible-status-key-modal"
      footer={false}
      open={isVisible}
      onCancel={onClose}
    >
      <Text type="h4med2">Tax-Deductible Status Key</Text>
      <ItemsContainer>{renderStatusKeyItems}</ItemsContainer>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  color: ${(props) => props.theme.colors.white};

  .ant-modal-content {
    background-color: transparent;
    margin: 20px;

    svg {
      color: ${(props) => props.theme.colors.white};
    }
  }
  .ant-modal-body {
    border-radius: 20px;
    padding: 24px;
    height: 100%;
    background-color: ${(props) => props.theme.colors.darkSurface2};
  }
`;

const Card = styled.div`
  padding: 24px;
  width: 100%;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.darkSurface3};
  color: ${(props) => props.theme.colors.white};
`;

const ItemsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 60vh;
  overflow-y: auto;
`;

const Description = styled(Text)`
  color: ${(props) => props.theme.colors.lightEmphasis2};
  margin-top: 9px;
`;

export default StatusKeysModal;
