import { Row } from 'antd';
import styled from 'styled-components';

import { Feedback, Flex, Text } from 'src/client/components';

export const Container = styled.div`
  padding-bottom: 124px;
  background-color: ${(props) => props.theme.colors.darkSurface1};
  color: white;
`;

export const Content = styled(Row)`
  margin: 0 auto 0 auto;
  padding: 22px 0 0 0;
  width: 100%;
  max-width: 1440px;

  @media ${(props) => props.theme.device.laptop} {
    padding: 66px 0 0 0;
  }
`;

export const StyledFeedback = styled(Feedback)`
  height: 100%;
  width: 100%;
`;

export const Caption = styled(Text)`
  margin-top: 8px;
  color: ${(props) => props.theme.colors.lightEmphasis2};
`;

export const Top = styled(Flex)`
  margin: 0 0 35px 0;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
`;
