import styled from 'styled-components';

import { Button, Flex } from 'src/client/components';
import { LABEL_2_REG_2 } from 'src/client/constants/text';

import Shape from './components/Shape';

type ShapeInputProps = {
  galleryCustomImage?: Record<string, string>[];
};

export const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;

  margin-bottom: 40px;

  @media ${(props) => props.theme.screen.md} {
    margin-bottom: 80px;
  }
`;

export const TealCircle = styled.div`
  margin-right: 16px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #2fe9fe;
  flex-shrink: 0;

  @media ${(props) => props.theme.screen.md} {
    margin-right: 29px;
    height: 96px;
    width: 96px;
  }
`;

export const DisplayContainer = styled.div`
  position: relative;
`;

export const CustomizeButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;

    position: absolute;
    bottom: -18px;
    left: 8px;

    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.gray9};

    & > .anticon + span {
      margin-left: 0px;
    }

    span {
      ${LABEL_2_REG_2}
    }

    span > svg {
      height: 16px;
      width: 16px;
    }
  }
`;

export const CustomImage = styled.img<ShapeInputProps>`
  display: ${(props) => (props.galleryCustomImage ? 'block' : 'none')};

  width: 300px;
  height: auto;
`;

export const StyledShape = styled(Shape)<ShapeInputProps>`
  display: ${(props) => (props.galleryCustomImage ? 'none' : 'block')};

  svg {
    width: 72px;
    height: auto;

    @media ${(props) => props.theme.screen.md} {
      width: 103px;
    }
  }
`;
