import { LeftOutlined } from '@ant-design/icons';
import { Col, Form, Row, Space } from 'antd';
import React from 'react';
import { useQueryClient } from 'react-query';
import { RouteChildrenProps } from 'react-router-dom';

import {
  Box,
  Button,
  Input,
  Text,
  DonorPrimaryEmailEditField,
  DonorAlternateEmailEditField,
  Title,
} from 'src/client/components';
import {
  useCreateAlternateEmailsMutation,
  useDeleteAlternateEmailsMutation,
  useUpdateDonorMutation,
} from 'src/client/hooks/mutations';
import {
  updateGetDonorByIdQuery,
  useGetAlternateEmails,
  useGetDonorById,
} from 'src/client/hooks/queries';
import AdminLayout from 'src/client/layouts/AdminLayout';
import { getTouchedFieldsValue } from 'src/client/utils/FormUtils';
import { AlternateEmail } from 'src/commons/types';

import { LoadingPage, PageNotFound } from '..';

import * as S from './styles';

type UrlParams = {
  donorId: string;
};

type FieldValues = {
  alternateEmailInput: string;
  alternateEmails: AlternateEmail[] | undefined;
  alternateEmailsToCreate: string[];
  deletedAlternateEmailIds: string[];
  name: string;
  primaryEmail: string;
};

const { useForm } = Form;

function AdminEditDonor(props: RouteChildrenProps<UrlParams>) {
  const { history, match } = props;

  const donorId = match?.params.donorId as string;
  const [form] = useForm<FieldValues>();

  const queryClient = useQueryClient();

  const { data: donor, isLoading: isDonorLoading } = useGetDonorById(donorId);

  const {
    data: alternateEmailsFromDb,
    isLoading: isAlternateEmailsFromDbLoading,
  } = useGetAlternateEmails(donorId);
  const { mutateAsync: createAlternateEmails } =
    useCreateAlternateEmailsMutation();
  const { mutateAsync: deleteAlternateEmails } =
    useDeleteAlternateEmailsMutation();
  const { mutateAsync: updateDonor } = useUpdateDonorMutation({
    onSuccess: (data) => {
      updateGetDonorByIdQuery({
        data: data,
        donorId: data.id,
        queryClient,
      });
    },
  });

  async function handleSave() {
    const {
      alternateEmailsToCreate = [],
      deletedAlternateEmailIds = [],
      name,
    } = getTouchedFieldsValue(form);

    if (alternateEmailsToCreate?.length > 0) {
      await createAlternateEmails({
        donorId,
        newAlternateEmails: alternateEmailsToCreate,
      });
    }

    if (deletedAlternateEmailIds?.length > 0) {
      await deleteAlternateEmails({
        donorId,
        deletedAlternateEmailIds,
      });
    }

    if (name) {
      await updateDonor({
        name,
        id: donorId,
      });
    }

    goBack();
  }

  function goBack() {
    history.goBack();
  }

  if (isDonorLoading || isAlternateEmailsFromDbLoading) {
    return <LoadingPage />;
  }

  if (!donor) {
    return <PageNotFound />;
  }

  return (
    <>
      <Title title="Edit Donor - Admin" />
      <AdminLayout>
        <S.Container>
          <Row>
            <Col span={12}>
              <Button type="variant1" onClick={goBack}>
                <LeftOutlined />
                Back
              </Button>
              <Text type="h4">Edit User</Text>
              <Box margin="0 0 14px 0" />
              <Form
                form={form}
                initialValues={{
                  name: donor.name,
                  primaryEmail: donor.email,
                }}
                layout="vertical"
                onFinish={handleSave}
              >
                <Form.Item label="Name" name="name">
                  <Input size="large" />
                </Form.Item>
                <Box margin="53px 0 0 0" />
                <DonorPrimaryEmailEditField
                  alternateEmailsFromDb={alternateEmailsFromDb}
                  description="Used to log in to their Giving Side account."
                  donor={donor}
                  formInstance={form}
                  modalDescription="Used to log in to their Giving Side account."
                />
                <Box margin="53px 0 0 0" />
                <DonorAlternateEmailEditField
                  alternateEmailsFromDb={alternateEmailsFromDb}
                  donor={donor}
                  formInstance={form}
                />
                <Box margin="54px 0 0 0" />
                <Space size={12}>
                  <Button htmlType="submit" type="primary">
                    Save Changes
                  </Button>
                  <Button type="variant1" onClick={goBack}>
                    Cancel
                  </Button>
                </Space>
              </Form>
            </Col>
          </Row>
        </S.Container>
      </AdminLayout>
    </>
  );
}

export default AdminEditDonor;
