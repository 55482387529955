import ApiClient from './ApiClient';

const apiVersion = '1.0';
const endpoint = 'outbound-email';
const url = `${apiVersion}/${endpoint}`;

type SendRecipientRequestAccessEmailParams = {
  email: string;
  firstName: string;
  hearAboutUs: string;
  lastName: string;
  organization: string;
};

export async function sendRecipientRequestAccessEmail(
  params: SendRecipientRequestAccessEmailParams
): Promise<{ message: string }> {
  const { data } = await ApiClient.post<{ message: string }>(
    `${url}/recipient-request-access`,
    params
  );

  return data;
}

type SendDonorRequestAccessEmailParams = {
  aboutYourself: string;
  email: string;
  firstName: string;
  hearAboutUs: string;
  lastName: string;
};

export async function sendDonorRequestAccessEmail(
  params: SendDonorRequestAccessEmailParams
): Promise<{ message: string }> {
  const { data } = await ApiClient.post<{ message: string }>(
    `${url}/donor-request-access`,
    params
  );

  return data;
}
