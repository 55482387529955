import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Form, FormInstance } from 'antd';

import React, { useCallback, useEffect, useState } from 'react';

import { Button } from 'src/client/components';
import { SectionStatus, STATUS_OPTIONS } from 'src/client/types/Gallery';
import { DEFAULT_GALLERY_TITLE } from 'src/commons/constants/gallery';

import { getLastLetter } from 'src/commons/utils/StringUtils';

import { EditLabel } from '../../styles';

import EditableGalleryTitleInput from './components/EditableGalleryTitleInput';
import * as S from './styles';

type Props = {
  galleryVisibility: boolean;
  introSectionForm: FormInstance<any>;
  isViewingPublicly: boolean;
  sectionStatus: SectionStatus;
  handleFormChange: () => void;
};

function GalleryTitleInput(props: Props) {
  const {
    galleryVisibility,
    introSectionForm,
    isViewingPublicly,
    sectionStatus,
    handleFormChange,
  } = props;

  const isIntroductionTitleEditable = introSectionForm.getFieldValue(
    'isIntroductionTitleEditable'
  );

  const [ownerNameError, setOwnerNameError] = useState<string | null>(null);
  const [isHidden, setIsHidden] = useState<boolean>(
    !isIntroductionTitleEditable
  );

  useEffect(() => {
    if (sectionStatus.status === STATUS_OPTIONS.VIEW_MODE) {
      setOwnerNameError(null); // reset error state when form is reset
      setIsHidden(!isIntroductionTitleEditable);
    }
  }, [isIntroductionTitleEditable, sectionStatus]);

  function handleSetGalleryTitleVisibility(value: boolean) {
    introSectionForm.setFieldsValue({ isIntroductionTitleEditable: !value });
    setIsHidden(value);

    handleFormChange();
  }

  function makeGalleryTitle(ownerName: string) {
    const ownerNameLastLetter = getLastLetter(ownerName);
    const possessive = ownerNameLastLetter === 's' ? "'" : "'s";

    return (
      <>
        <S.TitleText>
          {ownerName}
          {possessive}
        </S.TitleText>
        <S.TitleText>Giving Side</S.TitleText>
      </>
    );
  }

  function renderGalleryTitle() {
    if (isViewingPublicly) {
      const ownerName = introSectionForm.getFieldValue('ownerName');
      const title = isHidden
        ? DEFAULT_GALLERY_TITLE
        : makeGalleryTitle(ownerName);

      return (
        <S.TitleDisplayContainer isGalleryVisible={galleryVisibility}>
          {title}
        </S.TitleDisplayContainer>
      );
    }

    return (
      <>
        <EditLabel>Gallery title</EditLabel>
        <Form.Item
          data-cy="owner-name-form-item"
          help={null}
          name="ownerName"
          rules={[
            {
              required: true,
              message: 'Add a title for your gallery',
            },
          ]}
          validateStatus={ownerNameError ? 'error' : ''}
          validateTrigger={[]}
        >
          {isHidden ? (
            <S.DescriptionTitle data-cy="intro-title-text">
              {DEFAULT_GALLERY_TITLE}
            </S.DescriptionTitle>
          ) : (
            <EditableGalleryTitleInput onChange={handleOwnerNameChange} />
          )}
        </Form.Item>

        <Form.Item noStyle name="isIntroductionTitleEditable">
          <S.OptionsGroup isHidden={isHidden}>
            <Button
              type="noStyle"
              onClick={() => handleSetGalleryTitleVisibility(false)}
            >
              <EditOutlined height={20} />
              <span>Customize</span>
            </Button>
            <Button
              type="noStyle"
              onClick={() => handleSetGalleryTitleVisibility(true)}
            >
              <CloseOutlined />
              <span>Hide name</span>
            </Button>
          </S.OptionsGroup>
        </Form.Item>

        {ownerNameError && (
          <S.GalleryTitleError>{ownerNameError}</S.GalleryTitleError>
        )}
      </>
    );
  }

  const handleOwnerNameChange = useCallback(
    async (value: string) => {
      try {
        await introSectionForm.validateFields(['ownerName']);
        setOwnerNameError(null);
        introSectionForm.setFieldsValue({ ownerName: value });
        handleFormChange();
      } catch (errorInfo: any) {
        const error = errorInfo.errorFields.find(
          (field: { name: string | string[] }) =>
            field.name.includes('ownerName')
        );
        setOwnerNameError(error ? error.errors[0] : null);
      }
    },
    [handleFormChange, introSectionForm]
  );

  return (
    <S.Container>
      <Form.Item noStyle dependencies={['isIntroductionTitleEditable']}>
        {renderGalleryTitle}
      </Form.Item>
    </S.Container>
  );
}

export default GalleryTitleInput;
