import styled from 'styled-components';

import { Radio } from 'src/client/components';

export const Container = styled.div`
  padding: 69px 110px;

  .ant-select-selection-item > div {
    align-items: center;
  }
`;

export const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  gap: 16px;
`;
