import { Radio } from 'antd';
import { rem } from 'polished';
import styled from 'styled-components';

import { Flex, LoadingCard } from 'src/client/components';
import Text from 'src/client/components/Text';

import { StyledText } from 'src/client/components/Text/styles';

import { CardViewType } from '.';

export const Container = styled.div`
  margin-top: 48px;

  @media ${(props) => props.theme.device.laptop} {
    margin-top: 50px;
  }
`;

export const CentralTendencyTitle = styled(Text).attrs({
  type: 'h6med2',
})`
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.lightMedEmphasis};
`;

export const CardsContainer = styled.div`
  display: flex;
  margin-top: 33px;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 16px;

  @media ${(props) => props.theme.screen.md} {
    gap: 32px 23px;
  }
`;

export const StyledLoadingCard = styled(LoadingCard)`
  height: 300px;
  background-color: ${(props) => props.theme.colors.darkSurface2};
  border-radius: 8px;
`;

export const CentralTendencyContainer = styled(Flex)`
  margin: 22px 0 0 0;
  row-gap: 32px;
  flex-wrap: wrap;
  row-gap: 32px;
`;

export const RadioGroup = styled(Radio.Group)`
  --bgColor: ${(props) => props.value == CardViewType.AMOUNT ? props.theme.colors.teal1 : props.theme.colors.magenta500};
  background-color: ${(props) => props.theme.colors.darkSurface3};
  display: grid;
  grid-auto-flow: column;
  border-radius: 6px;
  padding: 4px;
  gap: 6px;

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: ${(props) => props.value == CardViewType.AMOUNT ? props.theme.colors.black : props.theme.colors.white};
    background: var(--bgColor);
    border-color: var(--bgColor);

    &:hover{
      color: ${(props) => props.value == CardViewType.AMOUNT ? props.theme.colors.black : props.theme.colors.white};
      background: var(--bgColor);
      border-color: var(--bgColor);
    }

    &:before{
      background-color: transparent;
    }
  }

  .ant-radio-button-wrapper {
    display: flex;
    border: none;
    background: transparent;
    color: ${(props) => props.theme.colors.white};
    border-radius: 6px;
    padding: 4px 8px 4px 8px;
    align-items: center;
    justify-content: center;

    &:focus-within{
      box-shadow: none !important;
    }

    &:not(:first-child){
      &:before{
        background-color: transparent;
      }
    }
  }

  span:has(.anticon svg){
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .anticon{
    margin-right: 6px;
    & svg{
      font-size: ${rem('19px')};
    }
  }
`

export const RadioText = styled(StyledText)`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${(props) => props.theme.fontSizes.body2};
  line-height: ${rem('16.8px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`

