import { useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { Entity } from 'src/commons/constants/entities';
import { Awaited } from 'src/commons/utils/TypescriptUtils';

import { makePaginateQueryKey } from '../utils/QueryKeyUtils';

type ApiConstraint = (param: any) => any;
type Params<Api extends ApiConstraint> = {
  api: Api;
  entity: Entity;
  query: Parameters<Api>[0];
  refetchOnMount?: boolean;
};

export function usePaginate<Api extends ApiConstraint>(params: Params<Api>) {
  type AwaitedApiReturnType = Awaited<ReturnType<typeof api>>;

  const { api, entity, query, refetchOnMount = false } = params;

  const [currentPageCount, setCurrentPageCount] = useState(0);

  const { data, fetchNextPage, isFetchingNextPage, isLoading, refetch } =
    useInfiniteQuery<AwaitedApiReturnType>(
      [makePaginateQueryKey(entity), query],
      (context) =>
        api({
          ...query,
          cursor: context.pageParam?.nextCursor,
        }),
      {
        getNextPageParam: (lastPage) => {
          if (lastPage.cursor) {
            return {
              nextCursor: lastPage.cursor,
            };
          } else {
            return undefined;
          }
        },
        refetchOnMount: refetchOnMount,
      }
    );

  function getCurrentPage(): AwaitedApiReturnType | undefined {
    if (isFetchingNextPage) {
      return data?.pages[currentPageCount - 1];
    }

    return data?.pages[currentPageCount];
  }

  function goToNextPage() {
    if (isFetchingNextPage) {
      return null;
    }

    setCurrentPageCount(currentPageCount + 1);
    fetchNextPage();
  }

  function goToPrevPage() {
    if (currentPageCount === 0) {
      return null;
    }

    setCurrentPageCount(currentPageCount - 1);
  }

  function goToFirstPage() {
    setCurrentPageCount(0);
  }

  return {
    goToFirstPage,
    currentPageCount,
    fetchNextPage,
    goToNextPage,
    goToPrevPage,
    isFetchingNextPage,
    isLoading,
    refetch,
    currentPage: getCurrentPage(),
    isThereMoreToPaginate: !!getCurrentPage()?.cursor,
  };
}
