import { Select } from 'antd';

import styled, { createGlobalStyle } from 'styled-components';

import { BUTTON_1_2 } from 'src/client/constants/text';

export const StyledSelect = styled(Select)`
  width: 170px;

  &&&& .ant-select-selector {
    padding: 8px 0 7px 10px;
    height: unset;
    color: ${(props) => props.theme.colors.teal1};
    background-color: ${(props) => props.theme.colors.darkSurface3};
    border: none;
    border-radius: 6px;
    box-shadow: none;
  }

  && .ant-select-selection-item {
    height: unset;
    ${BUTTON_1_2};
  }

  && .ant-select-selector::after,
  && .ant-select-selection-item::after {
    content: none;
  }

  .ant-select-selection-search-input {
    height: unset;
  }

  .anticon-down {
    color: ${(props) => props.theme.colors.teal1};
    font-size: 15px;
  }
`;

export const SelectV2GlobalStyle = createGlobalStyle`
	.ant-select-dropdown-dark {
    *::-webkit-scrollbar-track {
      background: transparent;
    }

    *::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.lightSurface20};
      border-radius: 6px;
    }

    *::-webkit-scrollbar {
      width: 5px;
    }

    .ant-select-item {
      color: white;
    }

    .ant-select-item-option-selected.ant-select-item-option-selected {
      color: ${(props) => props.theme.colors.teal1};
      background: unset;
    }

    .ant-select-item-option-active.ant-select-item-option-active {
      background: ${(props) => props.theme.colors.darkSurface2};
    }

    .ant-select-item-option-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      ${BUTTON_1_2};
    }
  }
`;
