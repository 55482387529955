import { Col, Form, Row } from 'antd';
import React from 'react';

import styled from 'styled-components';

import { BODY_1_REG_2 } from '../constants/text';

import { Input, InputNumber } from '.';

type Props = {
  label?: string;
  name: string;
};

const giveCountGutter = 12;

function NumberRangeInput(props: Props) {
  const { label, name } = props;

  return (
    <Container>
      <Form.Item label={label} name={name}>
        <Input.Group>
          <Row gutter={[giveCountGutter, 0]}>
            <Col span={12}>
              <Form.Item noStyle name={[name, 'minimum']}>
                <InputNumber placeholder="Minimum" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={[name, 'maximum']}>
                <InputNumber placeholder="Maximum" />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>
      </Form.Item>
    </Container>
  );
}

const Container = styled.div`
  .ant-form-item {
    margin: 0;
  }

  input {
    padding: 6px 12px;
    height: 36px;
    color: ${(props) => props.theme.colors.darkEmphasis1};
    ${BODY_1_REG_2};
  }
`;

export default NumberRangeInput;
