import { Row } from 'antd';
import React from 'react';
import styled from 'styled-components';

type Props = React.ComponentProps<typeof Row>;

function RowDivider(props: Props) {
  const { children, ...rowProps } = props;

  return <StyledRow {...rowProps}>{children}</StyledRow>;
}

const StyledRow = styled(Row)`
  padding: 16px 0px 12px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.border1};
`;

export default RowDivider;
