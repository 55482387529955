import React from 'react';

import {
  GridOptionType,
  GalleryShape,
  GalleryColor,
  GALLERY_COLOR_VALUES,
} from 'src/commons/constants/gallery';

import { Page } from 'src/commons/types';

import * as stylesheet from './styles';

type Props = {
  children?: React.ReactNode;
  color?: GalleryColor;
  onClick?: (value: string) => void;
  isSelected: boolean;
  type: GridOptionType;
  shape?: Page['galleryShape'];
  styles?: any;
};

function GridOption(props: Props) {
  const { children, color, type, isSelected, shape, styles, onClick } = props;

  const S = styles ?? stylesheet;

  function handleClick() {
    if (onClick) {
      if (type === GridOptionType.COLOR) {
        onClick(color as string);
      }

      if (type === GridOptionType.SHAPE) {
        onClick(shape?.name as GalleryShape);
      }
    }
  }

  if (type === GridOptionType.COLOR) {
    return (
      <S.Container
        data-cy="grid-option"
        data-is-selected={isSelected}
        data-option-type={type}
        data-value={color}
        isSelected={isSelected}
        onClick={handleClick}
      >
        <S.Color color={GALLERY_COLOR_VALUES[color as GalleryColor]} />
      </S.Container>
    );
  }

  if (type === GridOptionType.SHAPE) {
    return (
      <S.Container
        data-cy="grid-option"
        data-is-selected={isSelected}
        data-option-type={type}
        data-shape-color={shape?.color}
        data-value={shape?.name}
        isSelected={isSelected}
        onClick={handleClick}
      >
        <S.StyledShape
          shape={{
            name: shape?.name as GalleryShape,
            color: shape?.color as GalleryColor,
          }}
        />
      </S.Container>
    );
  }

  return (
    <S.Container data-cy="grid-option" isSelected={isSelected}>
      {children}
    </S.Container>
  );
}

export default GridOption;
