import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import routes from 'src/commons/constants/routes';

import { Box, Flex, Text } from '.';

type Props = {
  className?: string;
  onClose?: () => void;
};

function PrivacyInfoCard(props: Props) {
  const { className = '', onClose } = props;

  const { colors } = useTheme();

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <Container className={className}>
      <Flex>
        <ShieldSecurityIcon src="/shield-security-outline.svg" />
        <Flex flexDirection="column">
          <Box margin="0 0 7px 0">
            <Text type="body1reg2">We Value Your Privacy</Text>
          </Box>
          <Text color={colors.gray2} type="body2reg2">
            Giving Side does not sell, rent or lease its customer lists to third
            parties. See our{' '}
            <Link to={routes.PRIVACY_POLICY}>
              <Text isInline type="body2reg2">
                Privacy Policy
              </Text>
            </Link>{' '}
            informed by Digital Impact’s toolkit. Learn more about Digital
            Impact’s toolkit{' '}
            <a href="https://digitalimpact.io" rel="noreferrer" target="_blank">
              <Text isInline type="body2reg2">
                here
              </Text>
            </a>
            .
          </Text>
        </Flex>
      </Flex>
      <StyledCloseOutlined onClick={handleClose} />
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 20px;
  padding: 20px 23.09px;
  max-width: 488px;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.gray4};
  box-shadow: ${(props) => props.theme.shadows.shadow2};
  background: white;
  position: fixed;
  bottom: 0;
  left: 150px;

  @media (max-width: ${(props) => props.theme.size.tablet}) {
    width: 90% !important;
    left: 5px;
  }
`;

const ShieldSecurityIcon = styled.img`
  margin-right: 24px;
  height: 40px;
`;

const StyledCloseOutlined = styled(CloseOutlined)`
  position: absolute;
  margin-right: 24px;
  margin-top: 24px;
  cursor: pointer;
  right: 0;
  top: 0;
`;

export default PrivacyInfoCard;
