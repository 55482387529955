import {
  RecipientStat,
  RecipientStatFilter,
  TopRecipientStat,
} from 'src/commons/types';
import { ApiResponse } from 'src/commons/types/Response.type';
import { formatToFinancial } from 'src/commons/utils/MoneyUtilts';

import ApiClient from './ApiClient';

const apiVersion = '1.0';
const endpoint = 'recipient-stats';
const url = `${apiVersion}/${endpoint}`;

export async function getRecipientStats(
  params?: RecipientStatFilter
): Promise<ApiResponse<RecipientStat[]>> {
  const { data } = await ApiClient.get<ApiResponse<RecipientStat[]>>(url, {
    params: {
      payload: JSON.stringify(params),
    },
  });

  const formattedData = data.data.map((recipientStat) => ({
    ...recipientStat,
    donorsGivesTotalAmount: formatToFinancial(
      recipientStat.donorsGivesTotalAmount ?? 0
    ),
    donorsGivesTotalAmountMed: formatToFinancial(
      recipientStat.donorsGivesTotalAmountMed ?? 0
    ),
    donorsTopRecipientsByAmount: recipientStat.donorsTopRecipientsByAmount?.map(
      formatTopRecipient
    ),
    donorsTopRecipientsByCount: recipientStat.donorsTopRecipientsByCount?.map(
      formatTopRecipient
    ),
    donorsGivesTotalAmountAvg: formatToFinancial(
      recipientStat.donorsGivesTotalAmountAvg ?? 0
    ),
    goalsAmountAvg: formatToFinancial(recipientStat.goalsAmountAvg ?? 0),
    goalsAmountMed: formatToFinancial(recipientStat.goalsAmountMed ?? 0),
    recurringGivesAmountAvg: formatToFinancial(
      recipientStat.recurringGivesAmountAvg ?? 0
    ),
    recurringGivesAmountMed: formatToFinancial(
      recipientStat.recurringGivesAmountMed ?? 0
    ),
    graph: {
      ...recipientStat.graph,
      totalGiveAmount: recipientStat.graph.totalGiveAmount?.map((graph) => ({
        ...graph,
        yField: formatToFinancial(graph.yField),
      })),
      totalGoalAmount: recipientStat.graph.totalGoalAmount?.map((graph) => ({
        ...graph,
        yField: formatToFinancial(graph.yField),
      })),
    },
  }));

  return {
    ...data,
    data: formattedData,
  };
}

function formatTopRecipient(topRecipient: TopRecipientStat) {
  return {
    ...topRecipient,
    totalAmount: formatToFinancial(topRecipient.totalAmount ?? 0),
  };
}
