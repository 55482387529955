import React from 'react';

import * as S from './styles';

type Props = {
  title: string;
};

function ContentTitle(props: Props) {
  const { title } = props;

  return (
    <S.StyledText type="body">
      <S.TitleImage src="/ornament.svg" />
      <span>{title}</span>
    </S.StyledText>
  );
}

export default ContentTitle;
