import React, { useState } from 'react';

import { defaultGalleryImage } from 'src/client/constants/image';
import {
  useGetPlatformsLogo,
  useGetRecipientsLogo,
} from 'src/client/hooks/queries';
import { Entity } from 'src/commons/constants/entities';
import {
  PlatformStat,
  TopPlatformStat,
  TopRecipientStat,
} from 'src/commons/types';

import GalleryCard from './GalleryCard';
import GalleryContainer, { GALLERY_VIEW } from './GalleryContainer';

type Props = {
  platformStat: PlatformStat | undefined;
  isLoading: boolean;
  type: Entity.RECIPIENT | Entity.PLATFORM;
};

function GallerySection(props: Props) {
  const { platformStat, isLoading, type } = props;

  const [galleryView, setGalleryView] = useState(GALLERY_VIEW.GIVE_COUNT);

  const { imageIds, topCardData } = getTopGalleryCardsData(
    galleryView,
    platformStat,
    type
  );

  const { data: recipientsLogo } = useGetRecipientsLogo(imageIds, {
    enabled: type === Entity.RECIPIENT && !!imageIds?.length,
  });

  const { data: platformsLogo } = useGetPlatformsLogo(imageIds, {
    enabled: type === Entity.PLATFORM && !!imageIds?.length,
  });

  function getLogo(cardData: TopRecipientStat | TopPlatformStat) {
    if (type === Entity.RECIPIENT) {
      return (
        recipientsLogo?.find(
          (recipientLogo) =>
            recipientLogo.recipientId ===
            (cardData as TopRecipientStat).recipientId
        )?.imagePath || defaultGalleryImage
      );
    } else {
      return (
        platformsLogo?.find(
          (platformLogo) =>
            platformLogo.platformId === (cardData as TopPlatformStat).platformId
        )?.imagePath || defaultGalleryImage
      );
    }
  }

  const gallerydata = topCardData?.map((cardData) => ({
    logo: getLogo(cardData),
    parentId:
      'platformId' in cardData ? cardData.platformId : cardData.recipientId,
  }));

  function handleButtonGroupChange(value: GALLERY_VIEW) {
    setGalleryView(value);
  }

  const galleryCards = gallerydata?.map((data) => (
    <GalleryCard key={data.parentId} recipientLogo={data.logo} />
  ));

  const title =
    type === Entity.RECIPIENT
      ? 'Other Causes Your Users Supported'
      : 'Your Top Giving Platforms';

  return (
    <GalleryContainer
      buttonGroupValue={galleryView}
      isLoading={isLoading}
      title={title}
      onButtonGroupChange={handleButtonGroupChange}
    >
      {galleryCards}
    </GalleryContainer>
  );
}

function getTopGalleryCardsData(
  galleryView: GALLERY_VIEW,
  platformStat: PlatformStat | undefined,
  type: Entity
) {
  const topRecipients =
    galleryView === GALLERY_VIEW.GIVE_COUNT
      ? platformStat?.donorsTopRecipientsByCount
      : platformStat?.donorsTopRecipientsByAmount;

  const topPlatforms =
    galleryView === GALLERY_VIEW.GIVE_COUNT
      ? platformStat?.donorsTopPlatformsByCount
      : platformStat?.donorsTopPlatformsByAmount;

  if (type === Entity.RECIPIENT) {
    return {
      topCardData: topRecipients,
      imageIds: topRecipients?.map((topRecipient) => topRecipient.recipientId),
    };
  } else {
    return {
      topCardData: topPlatforms,
      imageIds: topPlatforms?.map((topPlatform) => topPlatform.platformId),
    };
  }
}

export default GallerySection;
