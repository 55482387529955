import { useCallback, useState } from 'react';

export function useLocalStorage(key: string, initialValue: any) {
  const [storedValue, setStoredValue] = useState(() => {
    const item = window.localStorage.getItem(key);

    if (item) {
      return JSON.parse(item);
    } else {
      const valueToStore =
        initialValue instanceof Function
          ? initialValue(storedValue)
          : initialValue;

      window.localStorage.setItem(key, JSON.stringify(valueToStore));

      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: (arg0: any) => any) => {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    },
    [key, storedValue]
  );

  return [storedValue, setValue];
}
