import { CaretDownFilled } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React, { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { useTheme } from 'styled-components';

import { AccountContext } from 'src/client/contexts/AccountContext';
import { useUpdateDonorMutation } from 'src/client/hooks/mutations';
import {
  updateGetCurrentAccountQuery,
  updateGetDonorByIdQuery,
} from 'src/client/hooks/queries';
import { GivingAdjective } from 'src/commons/constants/givingAdjectives';
import { Donor } from 'src/commons/types';

import { UpdateDonorParams } from 'src/commons/types/Params.type';

import * as S from './styles';

type Props = {
  donor: Donor;
};

function DescriptionDropdown(props: Props) {
  const { donor } = props;

  const account = useContext(AccountContext);
  const queryClient = useQueryClient();
  const { colors } = useTheme();

  const { mutate: updateCurrentAccount, isLoading: isUpdatingCurrentAcount } =
    useUpdateDonorMutation({
      onSuccess: (data) => {
        if (account?.isRoleDonor) {
          updateGetCurrentAccountQuery({
            data: {
              descriptionCurrent: data.descriptionCurrent,
              descriptionPrevious: data.descriptionPrevious,
            },
            queryClient,
          });
        } else {
          updateGetDonorByIdQuery({
            data,
            donorId: data.id,
            queryClient,
          });
        }
      },
      onMutate: (params: UpdateDonorParams) => {
        if (account?.isRoleDonor) {
          updateGetCurrentAccountQuery({
            data: {
              descriptionCurrent: params.descriptionCurrent,
              descriptionPrevious: params.descriptionPrevious,
            },
            queryClient,
          });
        } else {
          updateGetDonorByIdQuery({
            data: {
              descriptionCurrent: params.descriptionCurrent as string,
              descriptionPrevious: params.descriptionPrevious,
            },
            donorId: params.id,
            queryClient,
          });
        }
      },
    });

  const menuItemDisplay = Object.values(GivingAdjective).map((type) => {
    function handleClick() {
      updateCurrentAccount({
        id: donor.id,
        descriptionCurrent: type,
        descriptionPrevious: donor.descriptionCurrent,
      });
    }

    return (
      <Menu.Item key={type}>
        <a onClick={handleClick}>{type}</a>
      </Menu.Item>
    );
  });

  const menu = <Menu>{menuItemDisplay}</Menu>;
  const accountDescription = donor.descriptionCurrent?.includes('...')
    ? '...'
    : donor.descriptionCurrent ?? '...';

  return (
    <S.Container>
      <S.DescriptionDropdownGlobalStyle />
      <S.Title color={colors.white}>My Giving Side is</S.Title>
      <Dropdown
        overlay={menu}
        overlayClassName="give-adjective-dropdown"
        placement="bottom"
      >
        <S.AccountDescriptionContainer>
          <S.AccountDescriptionText
            isMutating={isUpdatingCurrentAcount}
            type="displayXlBold2"
          >
            {accountDescription}
          </S.AccountDescriptionText>
          <CaretDownFilled />
        </S.AccountDescriptionContainer>
      </Dropdown>
    </S.Container>
  );
}

export default DescriptionDropdown;
