import TextArea from 'antd/lib/input/TextArea';
import React from 'react';

import * as S from './styles';

type Props = React.ComponentProps<typeof TextArea> & S.GalleryTextAreaProps;

function GalleryTextArea(props: Props) {
  const { isEditing, ...textAreaProps } = props;

  return (
    <S.GalleryTextArea
      isEditing={isEditing}
      key={String(isEditing)}
      readOnly={!isEditing}
      {...textAreaProps}
    />
  );
}

export default GalleryTextArea;
