import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Text } from 'src/client/components';
import useThemeContext from 'src/client/hooks/useTheme';
import routes from 'src/commons/constants/routes';

type Props = {
  headerText: string;
  children?: React.ReactNode;
};

function ActionSuccessPage(props: Props) {
  const { headerText, children } = props;

  const { colors } = useThemeContext();

  return (
    <>
      <Box margin="88px 0 0 0">
        <img src="/birdIcon.png" />
      </Box>
      <Box margin="24px 0 16px 0">
        <Text color={colors.white} type="displaySBold2">
          {headerText}
        </Text>
      </Box>
      <Text color={colors.white} type="body1reg2">
        {children}
      </Text>
      <Box margin="40px 0 0 0">
        <Link to={routes.ROOT}>
          <Button type="primary">Take me to Giving Side</Button>
        </Link>
      </Box>
    </>
  );
}

export default ActionSuccessPage;
