import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  title: string;
};

const defaultTitle =
  "Giving Side - Give your generosity the love, remembrance, and support it's always deserved.";

function Title(props: Props) {
  const { title } = props;

  useEffect(
    () => () => {
      document.title = defaultTitle;
    },
    []
  );

  return (
    <Helmet>
      <title>{title ?? defaultTitle}</title>
    </Helmet>
  );
}

export default Title;
