import { Affix } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import {
  Box,
  Button,
  FlexCenter,
  StatisticCard,
  Text,
} from 'src/client/components';
import Select from 'src/client/components/v2/Select';
import { useGetRecipientsLogo } from 'src/client/hooks/queries/imageQueries';
import { RecipientStat } from 'src/commons/types';

import BottomRecipientCard from '../BottomRecipientCard';
import TopRecipientCard from '../TopRecipientCard';

import * as S from './styles';

type Props = {
  currentRecipientStat?: RecipientStat;
};

enum View {
  NUMBER_OF_GIVES = 'NUMBER_OF_GIVES',
  TOTAL_AMOUNT = 'TOTAL_AMOUNT',
}

const topCardLimit = 3;
const targetTop = 70;

function OurPeopleTab(props: Props) {
  const { currentRecipientStat } = props;

  const { colors } = useTheme();
  const screen = useBreakpoint();
  const [selectedView, setSelectedView] = useState(View.NUMBER_OF_GIVES);
  const [isBottomCardsVisibleInMobile, setIsBottomCardsVisibleInMobile] =
    useState(false);

  const topRecipients =
    selectedView === View.NUMBER_OF_GIVES
      ? currentRecipientStat?.donorsTopRecipientsByCount
      : currentRecipientStat?.donorsTopRecipientsByAmount;

  const recipientIds = topRecipients?.map(
    (topRecipient) => topRecipient.recipientId
  );

  const { data: recipientsLogo, isLoading: isRecipientsLogoLoading } =
    useGetRecipientsLogo(recipientIds);

  function getSortGivesOptions() {
    return [
      { value: View.NUMBER_OF_GIVES, label: 'Number of Gives' },
      { value: View.TOTAL_AMOUNT, label: 'Total Amount' },
    ];
  }

  function handleViewChange(value: unknown) {
    setSelectedView(value as View);
  }

  const renderTopRecipientsFull = topRecipients
    ?.slice(0, FullCardlastIndex)
    .map((topRecipient, i) => (
      <TopRecipientCard
        counterBadgeNumber={i + 1}
        coverImageSrc={
          recipientsLogo?.find(
            (recipientLogo) =>
              recipientLogo.recipientId === topRecipient.recipientId
          )?.imagePath
        }
        giveAmount={topRecipient.totalAmount}
        giveCount={topRecipient.giveCount}
        isCoverImageLoading={isRecipientsLogoLoading}
        key={topRecipient.recipientId}
        recipientName={topRecipient.recipientName}
      />
    ));

  const renderBottomRecipientCards = topRecipients
    ?.slice(FullCardlastIndex, compactCardlastIndex)
    .map((topRecipient, i) => (
      <BottomRecipientCard
        counterBadgeNumber={i + FullCardlastIndex + 1}
        giveAmount={topRecipient.totalAmount}
        giveCount={topRecipient.giveCount}
        key={topRecipient.recipientId}
        recipientName={topRecipient.recipientName}
      />
    ));

  function toggleBottomCardsVisibility() {
    setIsBottomCardsVisibleInMobile(!isBottomCardsVisibleInMobile);
  }

  function renderBottomCards() {
    const isInDesktop = screen.lg;
    const isBottomCardsVisibleAndIsInMobile =
      isBottomCardsVisibleInMobile && !isInDesktop;

    if (isBottomCardsVisibleAndIsInMobile) {
      return (
        <>
          <S.BottomRecipientCardsContainer>
            {renderBottomRecipientCards}
          </S.BottomRecipientCardsContainer>
          {visibilityLimitButton}
        </>
      );
    }

    if (isInDesktop) {
      return (
        <S.BottomRecipientCardsContainer>
          {renderBottomRecipientCards}
        </S.BottomRecipientCardsContainer>
      );
    }

    return visibilityLimitButton;
  }

  const shouldShowVisibilityLimitButton =
    (topRecipients?.length || 0) > topCardLimit;

  const visibilityLimitButton = shouldShowVisibilityLimitButton && (
    <>
      <Box margin="24px 0 0 0" />
      <FlexCenter>
        <Button type="variant2" onClick={toggleBottomCardsVisibility}>
          {isBottomCardsVisibleInMobile ? 'Hide recipients' : 'Show more'}
        </Button>
      </FlexCenter>
    </>
  );

  const statisticCardsData = [
    {
      statisticValue: `${currentRecipientStat?.donorCount ?? 0}`,
      title: 'Individuals',
    },
    {
      statisticValue: `${currentRecipientStat?.donorsGivesCount ?? 0}`,
      title: 'Gives',
    },
    {
      statisticValue: `$${Math.round(
        currentRecipientStat?.donorsGivesTotalAmount ?? 0
      ).toLocaleString()}`,
      title: 'Total Given',
    },
    {
      statisticValue: `$${Math.round(
        currentRecipientStat?.donorsGivesTotalAmountAvg ?? 0
      ).toLocaleString()}`,
      title: 'Average Give Amount',
    },
    {
      statisticValue: `$${Math.round(
        currentRecipientStat?.donorsGivesTotalAmountMed ?? 0
      ).toLocaleString()}`,
      title: 'Median Give Amount',
    },
    {
      statisticValue: getAverageMonthsOfGivingValue(),
      title: 'Average Years of Giving',
    },
  ];

  function getAverageMonthsOfGivingValue() {
    const { donorsStayAveMonths = 0 } = currentRecipientStat || {};

    const monthsInAYear = 12;
    const years = Math.round(donorsStayAveMonths / monthsInAYear);
    const months = donorsStayAveMonths % monthsInAYear;

    let yearText = '';
    let monthText = '';

    if (donorsStayAveMonths === 0) {
      return '0';
    }

    if (years === 1) {
      yearText = '1 year';
    } else if (years > 1) {
      yearText = `${years} years`;
    }

    if (months === 1) {
      monthText = '1 month';
    } else if (months > 1) {
      monthText = `${months} months`;
    }

    return `${yearText} ${monthText}`;
  }

  const statisticCards = statisticCardsData.map((data) => (
    <StatisticCard
      key={data.title}
      statisticValue={data.statisticValue}
      title={data.title}
    />
  ));

  function renderStickyTitleComponent() {
    const title = 'Our People and their Giving';

    if (screen.md) {
      return (
        <Affix offsetTop={targetTop}>
          <S.SecondTitle>{title}</S.SecondTitle>
        </Affix>
      );
    }

    return <S.SecondTitle>{title}</S.SecondTitle>;
  }

  return (
    <>
      <S.FirstSection>
        <S.FirstSectionContainer>
          <S.FirstSectionHeader>
            <S.FirstTitle color={colors.white} type="displaySMed2">
              Our People Support these Organizations and Causes
            </S.FirstTitle>
            <FlexCenter>
              <Box margin="2px 12px 0 0">
                <Text color={colors.white} type="body2Regular">
                  Sort
                </Text>
              </Box>
              <SortGivesSelect
                options={getSortGivesOptions()}
                value={selectedView}
                virtual={false}
                onChange={handleViewChange}
              />
            </FlexCenter>
          </S.FirstSectionHeader>
          <Box margin="36px 0 0 0">
            <S.TopRecipientCardsContainer>
              {renderTopRecipientsFull}
            </S.TopRecipientCardsContainer>
            {renderBottomCards()}
          </Box>
        </S.FirstSectionContainer>
      </S.FirstSection>
      <S.SecondSection>
        <S.SecondSectionContainer>
          {renderStickyTitleComponent()}
          <S.StatisticCardContainer>{statisticCards}</S.StatisticCardContainer>
        </S.SecondSectionContainer>
      </S.SecondSection>
    </>
  );
}

const FullCardlastIndex = 3;
const compactCardlastIndex = 10;

const SortGivesSelect = styled(Select)`
  position: sticky;
  width: 210px;

  .ant-select-arrow {
    top: 18px;
  }

  && .ant-select-selection-item::before {
    color: ${(props) => props.theme.colors.teal1};
    padding-right: 5px;
  }
`;

export default OurPeopleTab;
