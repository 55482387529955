import { Column } from '@ant-design/charts';
import React from 'react';

import defaultTheme from 'src/client/themes/default';

import { HistogramStyled } from './styles';

type Props = React.ComponentProps<typeof Column>;

const DEFAULT_DATA = [
  {
    type: '家家电',
    sales: 38,
  },
  {
    type: '粮油副食',
    sales: 52,
  },
  {
    type: '生鲜水果',
    sales: 61,
  },
  {
    type: '美容洗护',
    sales: 145,
  },
  {
    type: '母婴用品',
    sales: 48,
  },
  {
    type: '进口食品',
    sales: 38,
  },
  {
    type: '食品饮料',
    sales: 38,
  },
  {
    type: '家庭清洁',
    sales: 38,
  },
];

export default function Histogram(props: Props) {
  const { data = DEFAULT_DATA, ...histogramProps } = props;
  const config = {
    data,
    columnWidthRatio: 0.8,
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
      },
    },
    color: defaultTheme.colors.teal2,
    ...(histogramProps as any),
  };

  return <HistogramStyled {...config} />;
}
