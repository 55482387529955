import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

export function useScrollToUrlHash() {
  const match = useRouteMatch();

  useEffect(() => {
    if (window.location.hash) {
      const targetId = window.location.hash.substring(1);
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [match.url]);
}
