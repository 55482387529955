import { Upload } from 'antd';
import styled from 'styled-components';

import { Radio } from 'src/client/components';

export const Container = styled.div`
  padding: 69px 110px;
`;

export const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  gap: 16px;
`;

export const uploadButton = styled(Upload)`
  .ant-upload {
    width: 150px;
    height: 150px;
  }
`;
