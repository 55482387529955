import { InputNumber as AntdInputNumber } from 'antd';
import React from 'react';

import * as S from './styles';

type Props = React.ComponentProps<typeof AntdInputNumber>;

function InputNumber(props: Props) {
  return <S.StyledInputNumber controls={false} type="number" {...props} />;
}

export default InputNumber;
