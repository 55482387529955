import { Progress } from 'antd';
import party from 'party-js';
import React, { useEffect, useRef } from 'react';

import { useTheme } from 'styled-components';

import Button from 'src/client/components/Button';

import { Box, Flex } from 'src/client/components/StyledCommon';
import Text from 'src/client/components/Text';
import { YEAR_VIEW } from 'src/client/constants/DonorPages';
import { Give, Goal } from 'src/commons/types';
import { getCurrentYear } from 'src/commons/utils/DateUtils';
import { calculateTotalGiveAmount } from 'src/commons/utils/GiveUtilts';
import { calculateRate, roundFloat } from 'src/commons/utils/MathUtils';
import { formatToCurrency } from 'src/commons/utils/MoneyUtilts';

import { CardState } from '../..';

import * as S from './styles';

type Props = {
  donorGoal: Goal | undefined;
  gives: Give[] | undefined;
  setCardState: React.Dispatch<React.SetStateAction<CardState>>;
  shouldShowConfetti: boolean;
  yearView: number | YEAR_VIEW.ALL_TIME;
};

const confettiCount = 50;
const confettiSpread = 20;
const confettiSize = 1;

function GoalDisplayState(props: Props) {
  const { donorGoal, gives, setCardState, shouldShowConfetti, yearView } =
    props;

  const { colors } = useTheme();
  const goalAmountContainerRef = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    if (shouldShowConfetti) {
      party.confetti(goalAmountContainerRef.current, {
        count: party.variation.range(confettiCount, confettiCount),
        spread: party.variation.range(confettiSpread, confettiSpread),
        size: party.variation.range(confettiSize, confettiSize),
      });
    }
  }, []);

  function calculateProgressPercentage() {
    if (donorGoal) {
      return (
        calculateRate(getGivingGoalAmount(), getTotalDonatedAmount()) * 100
      );
    }

    return 0;
  }

  function handleEditGoalClick() {
    setCardState(CardState.EDITING_FIXED_AMOUNT);
  }

  function getGivingGoalAmount() {
    return donorGoal?.amount || 0;
  }

  function getTotalDonatedAmount() {
    return calculateTotalGiveAmount(gives);
  }

  const isGoalExceeded = getGivingGoalAmount() < getTotalDonatedAmount();

  const givesExceededGoalPercent = calculatePercentOfGiveExceededGoal(
    getTotalDonatedAmount(),
    getGivingGoalAmount()
  );

  const amountExceeded = getTotalDonatedAmount() - getGivingGoalAmount();

  const progressBar = isGoalExceeded ? (
    <S.StyledPopover
      content={
        <S.PopoverContainer>
          <Text type="label1med2">
            Total Given: {formatToCurrency(getTotalDonatedAmount())}
          </Text>
          <Flex justifyContent="space-between" margin="16px 0px">
            <Flex>
              <S.GiveLegend />
              <Text type="label1med2">Giving Goal</Text>
            </Flex>
            <Text type="label1med2">
              {formatToCurrency(getGivingGoalAmount())}
            </Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Flex>
              <S.ExceedingLegend />
              <S.PopoverLabelContainer>
                <Text type="label1med2">Amount exceeding Giving Goal</Text>
              </S.PopoverLabelContainer>
            </Flex>
            <Text type="label1med2">{formatToCurrency(amountExceeded)}</Text>
          </Flex>
        </S.PopoverContainer>
      }
      overlayInnerStyle={{
        padding: '4px 0',
        background: colors.darkSurface3,
        borderRadius: '8px',
        width: '221px',
      }}
    >
      <Progress
        percent={calculatePercentDonatedComparedToGoal(
          getTotalDonatedAmount(),
          getGivingGoalAmount()
        )}
        showInfo={false}
        strokeColor={colors.accent1}
        strokeLinecap="square"
        trailColor={colors.darkSecondary}
      />
    </S.StyledPopover>
  ) : (
    <Progress
      percent={calculateProgressPercentage()}
      showInfo={false}
      strokeColor={colors.darkSecondary}
      strokeLinecap="square"
      trailColor="rgba(255, 255, 255, 0.2)"
    />
  );

  const messageForExceedingGoal = isGoalExceeded && (
    <Box margin="0 0 16px 0">
      <S.GoalExceededText type="body2reg2">
        You blew past your goal for this year by{' '}
        {givesExceededGoalPercent.toLocaleString()}% (+
        {formatToCurrency(getTotalDonatedAmount() - getGivingGoalAmount())}).
      </S.GoalExceededText>
    </Box>
  );

  const editButton =
    yearView === getCurrentYear() ? (
      <Button type="link" onClick={handleEditGoalClick}>
        Edit Goal
      </Button>
    ) : null;

  return (
    <>
      <S.GoalDisplayStateGlobalStyle />
      <S.Container>
        <S.Title type="h3med2">My Giving Goal</S.Title>
        <Text type="h6med2">You&apos;ve given</Text>
        <S.GoalAmountContainer
          isGoalExceeded={isGoalExceeded}
          ref={goalAmountContainerRef}
        >
          <Text type="h1med2">
            {formatToCurrency(getTotalDonatedAmount())}&nbsp;
          </Text>
          <Text type="h4med2">
            of ${getGivingGoalAmount().toLocaleString()} goal
          </Text>
        </S.GoalAmountContainer>
        {messageForExceedingGoal}
        {progressBar}
        <Box margin="33px 0 0 0" />
        {editButton}
      </S.Container>
    </>
  );
}

const percentageConversionFactor = 100;

function calculatePercentOfGiveExceededGoal(
  totalDonatedAmount: number,
  givingGoalAmount: number
) {
  const amountExceeded = totalDonatedAmount - givingGoalAmount;

  const percentValue =
    calculateRate(givingGoalAmount, amountExceeded) *
    percentageConversionFactor;

  return roundFloat(percentValue);
}

function calculatePercentDonatedComparedToGoal(
  totalDonatedAmount: number,
  givingGoalAmount: number
) {
  const total = givingGoalAmount + totalDonatedAmount;

  const goalPercent =
    calculateRate(total, totalDonatedAmount) * percentageConversionFactor;

  return percentageConversionFactor - goalPercent;
}

export default GoalDisplayState;
