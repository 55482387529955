import React from 'react';

import { useSendSetInitialPasswordEmailMutation } from 'src/client/hooks/mutations';

import ResendPasswordLink from './ResendPasswordLink';

function ResendInitialPasswordLink() {
  const {
    mutateAsync: sendSetInitialPasswordEmail,
    isLoading: isSettingInitialPassword,
  } = useSendSetInitialPasswordEmailMutation();

  async function handleEmailSend(email: string) {
    await sendSetInitialPasswordEmail(email);
  }

  return (
    <ResendPasswordLink
      content={{
        emailInput: {
          header: 'Password setup link has expired',
          subheading:
            'To login to your account, setup your password using the link we will send to your email.',
        },
        success: {
          header: 'Password Setup email sent!',
          subheading:
            'We’ve sent you an email the contains the link to setup your password.',
        },
      }}
      isSendEmailButtonLoading={isSettingInitialPassword}
      onEmailSend={handleEmailSend}
    />
  );
}

export default ResendInitialPasswordLink;
