import styled from 'styled-components';

import CounterBadge from '../CounterBadge';

export const Container = styled.div`
  padding: 16px;
  width: 155px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.darkSurface2};

  @media (max-width: 945px) {
    width: 100%;
  }
`;

export const StyledCounterBadge = styled(CounterBadge)`
  margin-bottom: 16px;
  height: 32px;
  width: 32px;
`;
