import React, { forwardRef } from 'react';
import styled from 'styled-components';

type Props = React.HTMLProps<HTMLDivElement> & {
  src: string;
};
type DivImgProps = {
  src: string;
};

const DivImg = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { onError, children, src, ...divImgProps } = props;

  return (
    <>
      <BrokenImageDetector src={src} onError={onError} />
      <StyledDivImg {...divImgProps} as="div" ref={ref} src={src}>
        {children}
      </StyledDivImg>
    </>
  );
});

const BrokenImageDetector = styled.img`
  display: none;
`;

const StyledDivImg = styled.div<DivImgProps>`
  background-image: url(${(props) => props.src});
`;

export default DivImg;
