import {
  DashboardOutlined,
  FileTextOutlined,
  HomeOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import { Layout } from 'antd';
import React, { useContext, useState } from 'react';

import { Navigation } from 'src/client/components';
import { MenuSiderConfig } from 'src/client/components/AdminSider';
import DonorSider from 'src/client/components/DonorSider';
import { AccountContext } from 'src/client/contexts/AccountContext';
import { useGetDonorGalleryPage } from 'src/client/hooks/queries';
import { LoadingPage } from 'src/client/pages';
import routes from 'src/commons/constants/routes';
import { Donor } from 'src/commons/types';

import { replaceRouteParams } from 'src/commons/utils/RouteUtils';

import { LayoutStyle } from './styles';

type Props = {
  children: React.ReactNode | React.ReactNodeArray;
};

const { Content } = Layout;

const layoutStyle = { minHeight: '100vh', backgroundColor: '#193B4E' };

export default function DonorLayout(props: Props) {
  const { children } = props;

  const donor = useContext(AccountContext) as Donor;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const { data: page, isFetching: isFetchingGalleryPage } =
    useGetDonorGalleryPage(donor?.id as string);

  function handleCollapse() {
    setIsCollapsed(!isCollapsed);
  }

  if (isFetchingGalleryPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <LayoutStyle />
      {/* Duplicate navigation to move contents below it */}
      <Navigation
        donorId={donor.id}
        isPositionFixed={false}
        profileImage={donor.profilePhoto}
        onMenuSiderClick={handleCollapse}
      />
      <Navigation
        isPositionFixed
        donorId={donor.id}
        profileImage={donor.profilePhoto}
        onMenuSiderClick={handleCollapse}
      />
      <Layout style={layoutStyle}>
        <DonorSider
          collapsed={isCollapsed}
          menuItemsConfig={getSiderConfig(page?.id as string)}
          onCollapse={handleCollapse}
        />
        <Layout className="site-layout">
          <Content className="site-layout-background">{children}</Content>
        </Layout>
      </Layout>
    </>
  );
}

function getSiderConfig(pageId: string): MenuSiderConfig[] {
  return [
    {
      route: routes.HOME,
      name: 'Home',
      icon: <HomeOutlined />,
      keys: [routes.HOME],
    },
    {
      route: routes.DASHBOARD,
      name: 'Dashboard',
      icon: <DashboardOutlined />,
      keys: [routes.DASHBOARD, routes.DONOR_PERSONAL_DASHBOARD_VIEW],
    },
    {
      route: replaceRouteParams(routes.PERSONAL_GALLERY, {
        pageId,
      }),
      name: 'Gallery',
      icon: <PictureOutlined />,
      keys: [routes.PERSONAL_GALLERY],
    },
    {
      route: routes.DONOR_TAX,
      name: 'Tax Info',
      icon: <FileTextOutlined />,
      keys: [routes.DONOR_TAX],
    },
  ];
}
