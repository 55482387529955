import { Col, Row } from 'antd';
import React, { useState } from 'react';

import { Box, Text } from 'src/client/components';
import { useSendDonorRequestAccessEmailMutation } from 'src/client/hooks/mutations';

import useThemeContext from 'src/client/hooks/useTheme';
import BasicLayout from 'src/client/layouts/BasicLayout';

import ActionSuccessPage from '../ActionSuccessPage';

import FormCard from './components/Form';
import * as S from './styles';

function DonorRegister() {
  const { colors } = useThemeContext();

  const [isRequestSent, setIsRequestSent] = useState(false);
  const {
    mutateAsync: sendRequestAccessEmail,
    isLoading: isSendRequestAccessEmailLoading,
  } = useSendDonorRequestAccessEmailMutation();

  async function handleRequest(formValues: any) {
    if (!isSendRequestAccessEmailLoading) {
      await sendRequestAccessEmail({
        email: formValues.email,
        firstName: formValues.firstName,
        hearAboutUs: formValues.hearAboutUs,
        lastName: formValues.lastName,
        aboutYourself: formValues.aboutYourself,
      });

      setIsRequestSent(true);
    }
  }

  const content = isRequestSent ? (
    <ActionSuccessPage headerText="Your request has been received.">
      Thank you! Someone from Giving Side will be in touch with you soon.
    </ActionSuccessPage>
  ) : (
    <Box margin="0 0 25%">
      <Box margin="88px 0 24px 0">
        <S.TextHeading color={colors.teal1} type="h5bold2">
          Create your account
        </S.TextHeading>
      </Box>
      <Text color={colors.white} type="displaySBold2">
        Giving Side Beta Waitlist
      </Text>
      <FormCard onRequestAccessSend={handleRequest} />
    </Box>
  );

  return (
    <BasicLayout isOrnamentVisible>
      <Row justify="center">
        <Col lg={15} sm={15} xl={8} xs={22}>
          {content}
        </Col>
      </Row>
    </BasicLayout>
  );
}

export default DonorRegister;
