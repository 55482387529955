import { rem } from 'polished';

import styled, { css } from 'styled-components';

import { FlexCenter, GalleryTextArea } from 'src/client/components';
import { EditLabel } from 'src/client/pages/DonorGallery/styles';

export type InputContainerProps = {
  isEditing: boolean;
};

export const StyledEditLabel = styled(EditLabel)`
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.darkEmphasis1} !important;
`;

export const InputContainer = styled.div<InputContainerProps>`
  width: 100%;
  max-width: 895px;
  padding: 10px;
  background-color: transparent !important;
  outline: none;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.black};

  border: ${(props) =>
    props.isEditing
      ? `2px solid ${props.theme.colors.darkEmphasis1}`
      : 'inherit'};

  &:hover,
  &:focus {
    border: ${(props) =>
      props.isEditing
        ? `2px solid ${props.theme.colors.darkEmphasis1}`
        : 'inherit'};
    box-shadow: none;
  }

  @media (min-width: ${(props) => props.theme.size.laptop}) {
    width: 85%;
  }

  ${(props) =>
    !props.isEditing &&
    css`
      border: none;
      padding: 0;

      &:hover,
      &:focus {
        border: none;
        box-shadow: none;
      }
    `}
`;

export const StyledSpinnerContainer = styled(FlexCenter)`
  height: 240px;
`;

const noBorderStyles = `
  overflow: hidden;
  border: none;

  &:hover,
  &:focus {
    border: none;
    box-shadow: none;
  }
`;

export const QuoteTextArea = styled(GalleryTextArea)`
  font-family: ${(props) => props.theme.fontFamilies.sourceSerif};
  font-size: ${rem('36px')};
  line-height: ${rem('50px')} !important;
  font-weight: ${(props) => props.theme.fontWeights.semiBold};

  @media ${(props) => props.theme.screen.md} {
    font-size: ${rem('54px')};
    line-height: ${rem('70.2px')} !important;
  }

  ${noBorderStyles}
`;

const authorTextBaseStyles = css`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${rem('18px')};
  line-height: ${rem('27px')} !important;
  font-weight: ${(props) => props.theme.fontWeights.regular};

  @media ${(props) => props.theme.screen.md} {
    font-size: ${rem('22px')};
    line-height: ${rem('33px')} !important;
  }
`;

export const AuthorTextArea = styled(GalleryTextArea)`
  ${authorTextBaseStyles}
  ${noBorderStyles}
  
  margin-left: ${(props) => (props.isEditing ? '16px' : '28px')};
  width: 100%;
`;

type AuthorHyphenProps = {
  isEditing: boolean;
  isEmpty: boolean;
};

export const AuthorHyphen = styled.span<AuthorHyphenProps>`
  ${authorTextBaseStyles}
  position: absolute;
  left: 0;
  top: ${(props) => (props.isEditing ? '10px' : 0)};

  ${(props) =>
    props.isEditing
      ? css`
          color: ${(props) => props.theme.colors.darkEmphasis3};
        `
      : css`
          color: ${props.isEmpty
            ? props.theme.colors.darkEmphasis3
            : 'initial'};
          margin-right: 10px;
        `}
`;

export const AuthorContainer = styled.div`
  position: relative;
  margin-top: 8px;
`;
