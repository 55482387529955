import { SorterResult } from 'antd/lib/table/interface';
import isEmpty from 'lodash/isEmpty';

import { DatastoreOrder, Order } from 'src/commons/constants/order';

import { TableOrder } from '../types/Table';

export function formatTableOrder<Entity>(
  sorter: SorterResult<any> | SorterResult<any>[]
): TableOrder<Entity> | undefined {
  if (Array.isArray(sorter) || isEmpty(sorter)) {
    return undefined;
  }

  if (sorter.order === undefined) {
    return undefined;
  } else {
    return {
      columnKey: sorter.columnKey as keyof Entity,
      order: sorter.order as Order,
    };
  }
}

export function convertTableOrderToDatastoreOrder<Entity>(
  order: TableOrder<Entity>
): DatastoreOrder<keyof Entity> | undefined {
  if (!order.order) {
    return undefined;
  }

  return {
    property: order.columnKey,
    isDescending: order.order === Order.DESCEND ? true : false,
  };
}
