import { rem } from 'polished';
import styled from 'styled-components';

import { Flex, FlexCenter, Text } from 'src/client/components';

export const Container = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  max-width: 409px;
  min-width: 344px;

  &:hover {
    text-decoration: ${(props) => (props.onClick ? 'underline' : 'none')};
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  }
`;

export const Card = styled(FlexCenter)`
  margin-bottom: 21px;
  width: 100%;
  padding: 18px;
  height: 498px;
  border: 0.856px solid ${(props) => props.theme.colors.gray6};
  border-radius: 3.71px;
  background-color: ${(props) => props.theme.colors.white};
`;

export const StyledText = styled(Text)`
  text-align: center;
  font-family: ${(props) => props.theme.fontFamilies.vollkorn};
  font-size: ${rem('16px')};
  line-height: 120.5%; /* 19.28px */

  @media ${(props) => props.theme.screen.md} {
    font-size: ${rem('20px')};
  }
`;
