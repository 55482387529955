import React from 'react';

export function useModifyHandleDropdownRender(props: any) {
  const { theme, ...selectProps } = props;

  function handleDropdownRender(
    menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  ) {
    const cyId = (selectProps as any)['data-cy'];
    let newMenuElement = (
      <div className={`ant-select-dropdown-${theme}`}>{menu}</div>
    );

    if (cyId) {
      newMenuElement = <div data-cy={`${cyId}-dropdown`}>{menu}</div>;
    }

    if (selectProps.dropdownRender) {
      return selectProps.dropdownRender(newMenuElement);
    }

    return newMenuElement;
  }

  return handleDropdownRender;
}
