import { DollarCircleOutlined, HistoryOutlined } from '@ant-design/icons';
import { Grid, Radio, RadioChangeEvent } from 'antd';
import React, { useState } from 'react';

import { useTheme } from 'styled-components';

import { Button } from 'src/client/components';
import LoadingAnimation from 'src/client/components/LoadingAnimation';
import { Box, Flex, FlexCenter } from 'src/client/components/StyledCommon';
import Text from 'src/client/components/Text';
import { GIVING_SIDE_EMAIL } from 'src/client/constants/email';
import { groupGivesByRecipient } from 'src/client/utils/GiveUtils';
import { Give, GroupedGiveByRecipient } from 'src/commons/types';

import BottomCard from './components/BottomCard';
import TopCard from './components/TopCard';
import * as S from './styles';

type Props = {
  gives: Give[] | undefined;
  isGivesLoading: boolean;
};

const topCardLimit = 3;
const bottomCardLimit = 7;

export const radioButton = {
  amount: 'Amount',
  frequency: 'Frequency',
};

const { useBreakpoint } = Grid;

function TopGivesSection(props: Props) {
  const { gives, isGivesLoading } = props;

  const { colors } = useTheme();
  const screen = useBreakpoint();
  const [topGiveSortedBy, setTopGiveSortedBy] = useState(radioButton.amount);
  const [isBottomCardsVisibleInMobile, setIsBottomCardsVisibleInMobile] =
    useState(false);
  const groupedGives = gives && sortGroupGives(groupGivesByRecipient(gives));

  function handleSelectChange(e: RadioChangeEvent) {
    const value = e.target.value;
    setTopGiveSortedBy(value as string);
  }

  function sortGroupGives(groupedGives: GroupedGiveByRecipient[]) {
    return [...groupedGives].sort((groupedGiveA, groupedGiveB) => {
      if (topGiveSortedBy === radioButton.amount) {
        return (
          groupedGiveB.totalAmountOfGives - groupedGiveA.totalAmountOfGives
        );
      } else {
        return groupedGiveB.giveCount - groupedGiveA.giveCount;
      }
    });
  }

  function getContentForEmptyGivesPlaceholder() {
    if (isGivesLoading) {
      return <LoadingAnimation />;
    }

    if (isGivesEmpty) {
      return (
        <>
          <Text isInline color={colors.lightMedEmphasis} type="caption1">
            Forward your next give to&nbsp;
          </Text>
          <Text isInline color={colors.teal1} type="caption1">
            {GIVING_SIDE_EMAIL}
          </Text>
        </>
      );
    }
  }

  const topCardItems = groupedGives
    ?.slice(0, topCardLimit)
    .map((give, i) => (
      <TopCard
        counterBadgeNumber={i + 1}
        giveCount={give.giveCount}
        key={give.recipientId}
        recipientId={give.recipientId}
        recipientName={give.recipientName}
        totalGiveAmount={give.totalAmountOfGives}
      />
    ));

  const bottomCardItems = groupedGives
    ?.slice(topCardLimit, topCardLimit + bottomCardLimit)
    .map((groupedGive, i) => (
      <BottomCard
        counterBadgeCount={topCardLimit + 1 + i}
        giveCount={groupedGive.giveCount}
        key={groupedGive.recipientId}
        recipientName={groupedGive.recipientName}
        totalAmount={groupedGive.totalAmountOfGives}
      />
    ));

  const isGivesEmpty = gives?.length === 0;
  const isGivesEmptyOrLoading = isGivesLoading || isGivesEmpty;

  const emptyGivesPlaceholder = (
    <S.EmptyGivesContainer>
      {getContentForEmptyGivesPlaceholder()}
    </S.EmptyGivesContainer>
  );

  function toggleBottomCardsVisibility() {
    setIsBottomCardsVisibleInMobile(!isBottomCardsVisibleInMobile);
  }

  function renderBottomCards() {
    const isInDesktop = screen.lg;
    const isBottomCardsVisibleAndIsInMobile =
      isBottomCardsVisibleInMobile && !isInDesktop;

    if (isBottomCardsVisibleAndIsInMobile) {
      return (
        <>
          <S.BottomCardContainer>{bottomCardItems}</S.BottomCardContainer>
          {visibilityLimitButton}
        </>
      );
    }

    if (isInDesktop) {
      return <S.BottomCardContainer>{bottomCardItems}</S.BottomCardContainer>;
    }

    return visibilityLimitButton;
  }

  const shouldShowVisibilityLimitButton =
    (groupedGives?.length || 0) > topCardLimit;

  const visibilityLimitButton = shouldShowVisibilityLimitButton && (
    <>
      <Box margin="24px 0 0 0" />
      <FlexCenter>
        <Button type="variant2" onClick={toggleBottomCardsVisibility}>
          {isBottomCardsVisibleInMobile ? 'Hide gives' : 'Show more'}
        </Button>
      </FlexCenter>
    </>
  );

  const topGivesContent = isGivesEmptyOrLoading ? (
    emptyGivesPlaceholder
  ) : (
    <>
      <S.TopCardsContainer>{topCardItems}</S.TopCardsContainer>
      {renderBottomCards()}
    </>
  );

  return (
    <>
      <Box margin="40px 0 0 0" />
      <Flex justifyContent="space-between">
        <Text type="h3bold2">Top Gives</Text>
        <S.RadioGroup
          buttonStyle="solid"
          value={topGiveSortedBy}
          onChange={handleSelectChange}
        >
          <Radio.Button value={radioButton.amount}>
            <DollarCircleOutlined />
            <S.Text>{radioButton.amount}</S.Text>
          </Radio.Button>
          <Radio.Button value={radioButton.frequency}>
            <HistoryOutlined />
            <S.Text>{radioButton.frequency}</S.Text>
          </Radio.Button>
        </S.RadioGroup>
      </Flex>
      <Box margin="26px 0 0 0" />
      {topGivesContent}
    </>
  );
}

export default TopGivesSection;
