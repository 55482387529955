import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Form, Popconfirm, Tooltip } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import React from 'react';
import { Link } from 'react-router-dom';

import { getDonors } from 'src/client/api/DonorApi';
import { Box, Progress, Text } from 'src/client/components';
import LoadingAnimation from 'src/client/components/LoadingAnimation';
import MultiFilter, {
  FilterInputs,
  MultiFilterFormValue,
} from 'src/client/components/MultiFilter';
import { useDeleteDonorByIdMutation } from 'src/client/hooks/mutations';
import { useGetDonorsGoalProgress } from 'src/client/hooks/queries/goalQueries';
import { useManageTableQueryString } from 'src/client/hooks/useManageTableQueryString';
import { usePaginate } from 'src/client/hooks/usePaginate';
import { TableOrder } from 'src/client/types/Table';
import { createFilterDefaultValues } from 'src/client/utils/FormUtils';
import { Entity } from 'src/commons/constants/entities';
import {
  DonorsFilterInput,
  FilterType,
} from 'src/commons/constants/filterInputs';
import routes from 'src/commons/constants/routes';
import { Donor, DonorsFilter, DonorsGoalProgress } from 'src/commons/types';
import { formatDate, getCurrentYear } from 'src/commons/utils/DateUtils';
import { formatToCurrency } from 'src/commons/utils/MoneyUtilts';

import * as S from './styles';

const donorTableLimit = 50;
const { useForm } = Form;

const filterDefaultValues: MultiFilterFormValue = createFilterDefaultValues(
  DonorsFilterInput,
  DonorsFilterInput.SEARCH_QUERY
);

function DonorsTable() {
  const [filterForm] = useForm();

  const { mutateAsync: deleteDonorById } = useDeleteDonorByIdMutation();
  const {
    formattedQueryStringForApiFilter,
    formattedQueryStringForFormValues,
    queryString,
    setTableQueryString,
  } = useManageTableQueryString<DonorsFilter>();

  const {
    currentPageCount,
    isThereMoreToPaginate: isThereMoreDonorsToPaginate,
    goToNextPage: goToNextDonorPage,
    goToPrevPage: goToPrevDonorPage,
    goToFirstPage: goToFirstDonorPage,
    currentPage: donorsResponse,
    isLoading: isDonorsLoading,
    isFetchingNextPage: isFetchingNextDonorPage,
  } = usePaginate({
    api: getDonors,
    entity: Entity.DONOR,
    query: {
      ...formattedQueryStringForApiFilter,
      limit: donorTableLimit,
    },
    refetchOnMount: true,
  });

  const donors = donorsResponse?.data;

  const {
    data: donorsGoalProgress,
    isIdle: isDonorsGoalProgressIdle,
    isLoading: isDonorsGoalProgressLoading,
  } = useGetDonorsGoalProgress({
    donorIds: donors?.map((donor) => donor.id),
    year: getCurrentYear(),
  });

  async function handleDonorDelete(donorId: string) {
    await deleteDonorById(donorId);

    window.location.href = routes.ADMIN_DONORS;
  }

  function handleTableChange(
    pagination: any,
    filters: any,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) {
    setTableQueryString({
      ...queryString,
      sorter,
    });
  }

  function handleTableFilterChange(changedValues: any, allValues: any) {
    setTableQueryString(allValues);
  }

  function resetFilterAndSort() {
    setTableQueryString({});
    filterForm.setFieldsValue(filterDefaultValues);
    goToFirstDonorPage();
  }

  const columns = makeColumns({
    donorsGoalProgress,
    isDonorsGoalProgressLoading:
      isDonorsGoalProgressIdle || isDonorsGoalProgressLoading,
    onDelete: handleDonorDelete,
    orderInfo: queryString.tableOrder,
  });

  return (
    <S.Container>
      <MultiFilter
        filterInputs={filterInputs}
        form={filterForm}
        initialFilterType={DonorsFilterInput.SEARCH_QUERY}
        initialValues={formattedQueryStringForFormValues}
        onClearFilterAndSort={resetFilterAndSort}
        onValuesChange={handleTableFilterChange}
      />
      <Box margin="24px 0 0 0" />
      <S.StyledTable
        columns={columns}
        dataSource={donors}
        loading={{
          spinning: isDonorsLoading || isFetchingNextDonorPage,
          indicator: <LoadingAnimation />,
        }}
        paginate={{
          currentPageCount: currentPageCount,
          isThereMoreItemsToPaginate: isThereMoreDonorsToPaginate,
          onJumpToFirstPage: goToFirstDonorPage,
          onNextPage: goToNextDonorPage,
          onPrevPage: goToPrevDonorPage,
          pageSize: donorTableLimit,
        }}
        scroll={{ x: 1300 }}
        onChange={handleTableChange}
      />
    </S.Container>
  );
}

type MakeColumnsParams = {
  donorsGoalProgress: DonorsGoalProgress | undefined;
  isDonorsGoalProgressLoading: boolean;
  onDelete: (donorId: string) => void;
  orderInfo: TableOrder<any> | undefined;
};

const filterInputs: FilterInputs = Object.values(DonorsFilterInput).map(
  (donorFilterInput) => {
    switch (donorFilterInput) {
      case DonorsFilterInput.SEARCH_QUERY:
        return {
          filterType: FilterType.TEXT,
          label: 'Search',
          placeholder: 'Search user',
          key: donorFilterInput,
        };
      case DonorsFilterInput.GIVES:
        return {
          filterType: FilterType.NUMBER_RANGE,
          label: 'Gives',
          key: donorFilterInput,
        };
      case DonorsFilterInput.LAST_GIVE_RANGE:
        return {
          filterType: FilterType.DATE_RANGE,
          label: 'Last Give',
          key: donorFilterInput,
        };
      case DonorsFilterInput.DATE_CREATED_RANGE:
        return {
          filterType: FilterType.DATE_RANGE,
          label: 'Joined',
          key: donorFilterInput,
        };
      case DonorsFilterInput.CURRENT_YEAR_GOAL_AMOUNT_RANGE:
        return {
          filterType: FilterType.NUMBER_RANGE,
          label: 'Goal',
          key: donorFilterInput,
        };
    }
  }
);

function makeColumns(params: MakeColumnsParams) {
  const {
    donorsGoalProgress,
    isDonorsGoalProgressLoading,
    onDelete,
    orderInfo,
  } = params;

  return [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Name',
      sorter: (a: any, b: any) => 0,
      sortOrder: orderInfo?.columnKey === 'name' ? orderInfo.order : undefined,
      render: (name: string | null, record: Donor) => (
        <Link to={routes.ADMIN_DONOR_ROOT.replace(/:donorId/g, record.id)}>
          <Text isInline type="link">
            {name}
          </Text>
        </Link>
      ),
    },
    {
      dataIndex: 'giveCount',
      key: 'giveCount',
      title: 'Gives',
      width: '120px',
      sorter: (a: any, b: any) => 0,
      sortOrder:
        orderInfo?.columnKey === 'giveCount' ? orderInfo.order : undefined,
      render: (giveCount: number | null) => giveCount ?? 0,
    },
    {
      dataIndex: 'lastGiveDate',
      key: 'lastGiveDate',
      title: 'Last Give',
      sorter: (a: any, b: any) => 0,
      sortOrder:
        orderInfo?.columnKey === 'lastGiveDate' ? orderInfo.order : undefined,
      render: (lastGiveDate: string | null) =>
        lastGiveDate ? formatDate(lastGiveDate) : undefined,
    },
    {
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      title: 'Joined',
      sorter: (a: any, b: any) => 0,
      sortOrder:
        orderInfo?.columnKey === 'dateCreated' ? orderInfo.order : undefined,
      render: (dataCreated: string) => formatDate(dataCreated),
    },
    {
      dataIndex: 'currentYearGoalAmount',
      key: 'currentYearGoalAmount',
      title: 'Goal',
      width: '120px',
      sorter: (a: any, b: any) => 0,
      sortOrder:
        orderInfo?.columnKey === 'currentYearGoalAmount'
          ? orderInfo.order
          : undefined,
      render: (currentYearGoalAmount: number | null) =>
        currentYearGoalAmount ? formatToCurrency(currentYearGoalAmount) : '-',
    },
    {
      dataIndex: '',
      key: '',
      title: 'Progress',
      render: (email: string | null, donor: Donor) => {
        const donorGoalProgress = donorsGoalProgress?.[donor.id];

        if (isDonorsGoalProgressLoading) {
          return <LoadingAnimation />;
        }

        const hasGoalProgress =
          donorGoalProgress &&
          donorGoalProgress.goalProgressPercentage !== null &&
          donorGoalProgress.goalProgressPercentage !== undefined;

        if (hasGoalProgress) {
          return (
            <Progress percent={donorGoalProgress.goalProgressPercentage} />
          );
        }

        return '-';
      },
    },
    {
      dataIndex: 'email',
      key: 'email',
      title: 'Email',
      sorter: (a: any, b: any) => 0,
      sortOrder: orderInfo?.columnKey === 'email' ? orderInfo.order : undefined,
      render: (email: string | null) => email,
    },
    {
      key: 'action',
      title: 'Action',
      fixed: 'right' as const,
      width: '100px',
      render: (text: string, record: Donor) => {
        function handleConfirm() {
          onDelete(record.id);
        }

        return (
          <S.ActionButtonsContainer>
            <Link to={routes.ADMIN_EDIT_DONOR.replace(/:donorId/, record.id)}>
              <Tooltip placement="bottom" title="Edit">
                <EditOutlined />
              </Tooltip>
            </Link>
            <Popconfirm
              cancelText="No"
              okButtonProps={{
                danger: true,
                type: 'primary',
              }}
              okText="Yes"
              placement="topRight"
              title="Delete this user?"
              onConfirm={handleConfirm}
            >
              <Tooltip placement="bottom" title="Delete">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </S.ActionButtonsContainer>
        );
      },
    },
  ];
}

export default DonorsTable;
