import { useQuery } from 'react-query';

import { getRecipientStats } from 'src/client/api/RecipientStatApi';
import { RecipientStatFilter } from 'src/commons/types';

const GET_RECIPIENT_STATS_KEY = 'GET_RECIPIENT_STATS_KEY';

export function useGetRecipientStats(params: RecipientStatFilter) {
  return useQuery([GET_RECIPIENT_STATS_KEY, params], () =>
    getRecipientStats(params)
  );
}
