import moment from 'moment';

import { NUMBER_FORMAT } from 'src/commons/constants/dateFormat';
import { GivesFilter } from 'src/commons/types/Filters.type';
import { TAX_DEDUCTIBLE_STATUS, Give } from 'src/commons/types/Give.type';
import { ApiResponse } from 'src/commons/types/Response.type';
import { toIntMoney } from 'src/commons/utils/MoneyUtilts';

import { RequireField } from 'src/commons/utils/TypescriptUtils';

import { ProcessGiveResponse } from '../types/ApiResponse';
import { downloadFile } from '../utils/DownloadUtils';
import { formatMoneyInGiveToFinancial } from '../utils/GiveUtils';

import ApiClient from './ApiClient';

const apiVersion = '1.0';
const endpoint = 'gives';
export const url = `${apiVersion}/${endpoint}`;

type GetAttachmentsUrlResponse = {
  attachments: { attachmentName: string; url: string }[];
};

type GetGiveByIdParams = {
  giveId: string;
  shouldPrepopulateWhenUnprocessed?: boolean;
};

type DuplicateGiveResponse = {
  duplicatedGive: Give;
};

export type ProcessGiveParams = {
  give: RequireField<Partial<Give>, 'id'>;
  shouldCheckForDuplicates: boolean;
};

export async function getGiveByIds(giveIds: string[]): Promise<Give[]> {
  const { data: gives } = await ApiClient.get<Give[]>(`${url}/ids`, {
    params: {
      giveIds,
    },
  });

  return gives.map(formatMoneyInGiveToFinancial);
}

export async function getGiveById(params: GetGiveByIdParams): Promise<Give> {
  const { data: give } = await ApiClient.get<Give>(
    `${url}/id/${params.giveId}`,
    {
      params: {
        shouldPrepopulateWhenUnprocessed:
          params.shouldPrepopulateWhenUnprocessed,
      },
    }
  );

  return formatMoneyInGiveToFinancial(give);
}

export async function getPrepopulatedUnprocessedGiveById(
  giveId: string
): Promise<Give> {
  const { data: give } = await ApiClient.get<Give>(
    `${url}/prepopulate/${giveId}`
  );

  return formatMoneyInGiveToFinancial(give);
}

export async function getGives(
  params?: GivesFilter
): Promise<ApiResponse<Give[]>> {
  const { data: responseData } = await ApiClient.get<ApiResponse<Give[]>>(url, {
    params: {
      payload: params,
    },
  });

  const formattedGives = responseData.data.map(formatMoneyInGiveToFinancial);

  return {
    ...responseData,
    data: formattedGives,
  };
}

export async function getGiveHtmlBody(
  giveId: string
): Promise<string | undefined> {
  const { data: htmlBody } = await ApiClient.get<string>(
    `${url}/${giveId}/htmlBody`
  );

  return htmlBody;
}

export async function updateGive(
  params: Partial<Give>
): Promise<ApiResponse<Give>> {
  const { id, ...toUpdate } = params;

  const { data: responseData } = await ApiClient.put<ApiResponse<Give>>(
    `${url}/${id}`,
    {
      ...toUpdate,
      amount: toUpdate.amount && toIntMoney(toUpdate.amount),
      splitAmount: toUpdate.splitAmount && toIntMoney(toUpdate.splitAmount),
      matchedDonationAmount:
        toUpdate.matchedDonationAmount &&
        toIntMoney(toUpdate.matchedDonationAmount),
    }
  );

  return {
    ...responseData,
    data: responseData.data && formatMoneyInGiveToFinancial(responseData.data),
  };
}

export async function processGive(
  params: ProcessGiveParams
): Promise<ProcessGiveResponse> {
  const { give, shouldCheckForDuplicates } = params;

  const { data: responseData } = await ApiClient.patch<ProcessGiveResponse>(
    `${url}/${params.give.id}/process`,
    {
      shouldCheckForDuplicates,
      give: {
        ...give,
        amount: give.amount && toIntMoney(give.amount),
        splitAmount: give.splitAmount && toIntMoney(give.splitAmount),
        matchedDonationAmount:
          give.matchedDonationAmount && toIntMoney(give.matchedDonationAmount),
      },
    }
  );

  return {
    ...responseData,
    data: {
      ...responseData.data,
      updatedGive:
        responseData.data.updatedGive &&
        formatMoneyInGiveToFinancial(responseData.data.updatedGive),
    },
  };
}

export async function rejectGive(giveId: string): Promise<ApiResponse<Give>> {
  const { data: responseData } = await ApiClient.patch<ApiResponse<Give>>(
    `${url}/${giveId}/reject`
  );

  return {
    ...responseData,
    data: responseData.data && formatMoneyInGiveToFinancial(responseData.data),
  };
}

export async function deleteGive(giveId: string): Promise<string> {
  const { data: deletedGiveId } = await ApiClient.delete<string>(
    `${url}/${giveId}`
  );

  return deletedGiveId;
}

export async function downloadGivesExcel(params?: GivesFilter) {
  const response = await ApiClient.get<ArrayBuffer>(`${url}/excel`, {
    params,
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const dateToday = moment(new Date()).format(NUMBER_FORMAT);
  let taxDeductibleStatus = '';

  if (params?.taxDeductibleStatus === TAX_DEDUCTIBLE_STATUS.UNKNOWN) {
    taxDeductibleStatus = 'Unknown ';
  }

  const giveYear = params?.giveYear || 'All Time';
  const fileName = params?.taxDeductibleStatus
    ? `Exported ${taxDeductibleStatus}Tax-Deductible Gives ${dateToday}.xlsx`
    : `Exported Gives ${giveYear} - All.xlsx`;

  downloadFile(response, fileName);
}

export async function createGive(giveData: Partial<Give>): Promise<Give> {
  const { data: newGive } = await ApiClient.post<Give>(`${url}`, giveData);

  return newGive;
}

export async function getAttachmentsUrl(
  attachmentFileNames: string[]
): Promise<GetAttachmentsUrlResponse> {
  const { data } = await ApiClient.get<GetAttachmentsUrlResponse>(
    `${url}/attachments`,
    {
      params: {
        attachmentFileNames,
      },
    }
  );

  return data;
}

export async function duplicateGive(
  giveId: string
): Promise<DuplicateGiveResponse> {
  const { data } = await ApiClient.post<DuplicateGiveResponse>(
    `${url}/duplicate`,
    {
      giveId,
    }
  );

  return data;
}
