import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { Button } from '..';

export const AddEmailButton = styled(Button)`
  && {
    height: 40px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const AlternateEmailContainer = styled.div`
  display: flex;
  padding: 14px 0;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.colors.border1};
  flex-wrap: flex;
  justify-content: space-between;
`;

export const DeleteIcon = styled(DeleteOutlined)`
  color: ${(props) => props.theme.colors.darkLowEmphasis};
  cursor: pointer;
`;

export const ResendButton = styled(Button)`
  &&& {
    margin: 0 26px 0 0;
    padding: 0;
    color: ${(props) => props.theme.colors.teal2};

    :hover {
      background: white;
    }
  }
`;
