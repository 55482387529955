import { rem } from 'polished';
import styled from 'styled-components';

import { Flex } from 'src/client/components';
import { BODY_1_MED_2, H2_MED_2 } from 'src/client/constants/text';

export const StatisticCardContainer = styled(Flex)`
  margin-bottom: 32px;
  gap: 16px;
  flex-wrap: wrap;

  .statistic-value {
    ${H2_MED_2};
  }

  .statistic-title {
    ${BODY_1_MED_2}
    font-size: ${rem('12px')};
    line-height: ${rem('16px')};
    color: ${(props) => props.theme.colors.darkEmphasis3};

    @media ${(props) => props.theme.screen.md} {
      font-size: ${rem('16px')};
      line-height: ${rem('24px')};
    }
  }

  & > div {
    padding: 25px;
    overflow: hidden;
    height: 135px;
    width: 550px;
  }

  @media ${(props) => props.theme.screen.md} {
    flex-wrap: nowrap;
  }
`;
