import isEmpty from 'lodash/isEmpty';
import { useQuery } from 'react-query';

import {
  getDonorsGoalProgress,
  getGoalAndGiveAmountHistory,
} from 'src/client/api/GoalApi';
import { DonorsGoalProgressFilter } from 'src/commons/types';

export const GET_DONORS_GOAL_PROGRESS_KEY = 'GET_DONORS_GOAL_PROGRESS_KEY';
export const GET_GOAL_AND_GIVE_AMOUNT_HISTORY_KEY =
  'GET_GOAL_AND_GIVE_AMOUNT_HISTORY_KEY';

export function useGetDonorsGoalProgress(
  params: Partial<DonorsGoalProgressFilter>
) {
  return useQuery(
    [GET_DONORS_GOAL_PROGRESS_KEY, params],
    () => getDonorsGoalProgress(params as DonorsGoalProgressFilter),
    {
      enabled: !isEmpty(params.donorIds),
      refetchOnMount: true,
    }
  );
}

export function useGetGoalAndGiveAmountHistory(donorId: string) {
  return useQuery([GET_GOAL_AND_GIVE_AMOUNT_HISTORY_KEY, donorId], () =>
    getGoalAndGiveAmountHistory(donorId)
  );
}
