/* eslint-disable max-params */
import { useEffect } from 'react';

declare let vgo: {
  (command: string, ...args: any[]): void;
};

function ActiveCampaignTrackingCode() {
  useEffect(() => {
    (function (e: any, t: any, o: any, n: any, p, r, i) {
      e.visitorGlobalObjectAlias = n;

      e[e.visitorGlobalObjectAlias] =
        e[e.visitorGlobalObjectAlias] ||
        function () {
          (e[e.visitorGlobalObjectAlias].q =
            e[e.visitorGlobalObjectAlias].q || []).push(arguments);
        };

      e[e.visitorGlobalObjectAlias].l = new Date().getTime();
      r = t.createElement('script');
      (r as any).src = o;
      (r as any).async = true;
      i = t.getElementsByTagName('script')[0];
      (i as any).parentNode.insertBefore(r, i);
    })(
      window,
      document,
      'https://diffuser-cdn.app-us1.com/diffuser/diffuser.js',
      'vgo'
    );

    vgo('setAccount', '254938839');
    vgo('setTrackByDefault', true);
    vgo('process');
  }, []);

  return null;
}

export default ActiveCampaignTrackingCode;
