import { Col, Form, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Box, Flex, FlexCenter, Input, Text } from 'src/client/components';
import BasicLayout from 'src/client/layouts/BasicLayout';
import routes from 'src/commons/constants/routes';

import * as S from './styles';

type Props = {
  content: {
    emailInput: {
      header: string;
      subheading: string;
    };
    success: {
      header: string;
      subheading: string;
    };
  };
  isSendEmailButtonLoading?: boolean;
  onEmailSend: (email: string) => Promise<void>;
};

function ResendPasswordLink(props: Props) {
  const { content, isSendEmailButtonLoading = false, onEmailSend } = props;
  const { success, emailInput } = content;

  const { colors } = useTheme();
  const [form] = useForm();
  const [isEmailSent, setIsEmailSent] = useState(false);

  async function handleSendEmail() {
    const donorEmail = form.getFieldValue('email');
    await onEmailSend(donorEmail);
    setIsEmailSent(true);
  }

  const cardContent = isEmailSent ? (
    <Flex alignItems="center" flexDirection="column" justifyContent="center">
      <img src="/approveOutline.png" />
      <Box margin="16px 0 8px 0">
        <Text type="h3bold2">{success.header}</Text>
      </Box>
      <Text type="body1reg2">{success.subheading}</Text>
      <Box margin="40px 0 0 0">
        <Text type="body1reg2">
          Didn’t receive the email?
          <S.ResendEmailButton
            loading={isSendEmailButtonLoading}
            onClick={handleSendEmail}
          >
            &nbsp;Resend email
          </S.ResendEmailButton>
        </Text>
      </Box>
    </Flex>
  ) : (
    <>
      <Text type="h3bold2">{emailInput.header}</Text>
      <Box margin="8px 0 40px 0">
        <Text type="body1reg2">{emailInput.subheading}</Text>
      </Box>
      <Form
        form={form}
        layout="vertical"
        validateMessages={{
          required: '${label} is required!',
          types: {
            email: '${label} is not a valid email!',
          },
        }}
        onFinish={handleSendEmail}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
            },
          ]}
        >
          <Input placeholder="Email" size="large" />
        </Form.Item>
        <S.SetPasswordButton
          htmlType="submit"
          loading={isSendEmailButtonLoading}
          type="primary"
        >
          Send me a new link
        </S.SetPasswordButton>
      </Form>
      <Box margin="40px 0 0 0" />
      <FlexCenter>
        <Text isInline type="body1reg2">
          Already a member?&nbsp;
        </Text>
        <Link to={routes.DONOR_SIGN_IN}>
          <Text isInline type="body1reg2">
            Log in here
          </Text>
        </Link>
      </FlexCenter>
    </>
  );

  return (
    <BasicLayout isOrnamentVisible>
      <S.Container>
        <Row justify="center">
          <Col lg={15} sm={15} xl={8} xs={22}>
            <div>
              <Box margin="88px 0 0 0" />
              <Text color={colors.teal1} type="h5bold2">
                ACCOUNT SET UP
              </Text>
              <Box margin="24px 0 40px 0">
                <Text color={colors.white} type="displaySBold2">
                  Welcome to your Giving Side
                </Text>
              </Box>
            </div>
            <S.Card>{cardContent}</S.Card>
          </Col>
        </Row>
      </S.Container>
    </BasicLayout>
  );
}

export default ResendPasswordLink;
