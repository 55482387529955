import styled from 'styled-components';

import { Flex } from 'src/client/components';

import { WidthLimit } from '../../styles';
import RecipientLogo from '../RecipientLogo';

export const Container = styled.div`
  height: 303px;
  background-image: url('/recipientFooterBg.png');
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;

  padding: 0 8px 24px;

  @media (${(props) => props.theme.screen.md}) {
    padding: 0 24px 74px;
  }

  @media (${(props) => props.theme.screen.lg}) {
    padding: 0 104px 74px;
  }
`;

export const StyledWidthLimit = styled(WidthLimit)`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
`;

export const StyledRecipientLogo = styled(RecipientLogo)`
  height: 73px;
  width: 145px;
  margin-bottom: 48px;

  @media (${(props) => props.theme.screen.md}) {
    margin-bottom: 0;
  }
`;

export const NavigationContainer = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .anticon {
    font-size: 30px;
    color: ${(props) => props.theme.colors.white};
  }
`;
