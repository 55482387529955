/* eslint-disable no-alert */
import { EyeOutlined, ExportOutlined, CopyOutlined } from '@ant-design/icons';
import { Form, Tooltip } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Link, useHistory } from 'react-router-dom';

import { getGives } from 'src/client/api/GiveApi';

import { MultiFilter, Flex, Text } from 'src/client/components';

import LoadingAnimation from 'src/client/components/LoadingAnimation';
import {
  FilterInputs,
  MultiFilterFormValue,
} from 'src/client/components/MultiFilter';
import { useDuplicateGiveMutation } from 'src/client/hooks/mutations';
import { addGiveInInfiniteGivesQueryData } from 'src/client/hooks/queries';
import { useManageTableQueryString } from 'src/client/hooks/useManageTableQueryString';
import { usePaginate } from 'src/client/hooks/usePaginate';
import { TableOrder } from 'src/client/types/Table';
import { createFilterDefaultValues } from 'src/client/utils/FormUtils';
import { FULL_MONTH_DATE } from 'src/commons/constants/dateFormat';

import { Entity } from 'src/commons/constants/entities';

import {
  FilterType,
  GiveFilterInput,
} from 'src/commons/constants/filterInputs';
import routes from 'src/commons/constants/routes';

import {
  Give,
  GivesFilter,
  GIVE_STATUS,
  GIVE_UNIT,
  Indexable,
  TAX_DEDUCTIBLE,
} from 'src/commons/types';
import { formatDate } from 'src/commons/utils/DateUtils';
import { formatToCurrency } from 'src/commons/utils/MoneyUtilts';
import { replaceRouteParams } from 'src/commons/utils/RouteUtils';
import { capitalizeFirstLetter } from 'src/commons/utils/StringUtils';

import * as S from './styles';

type MakeColumnsParams = {
  orderInfo: TableOrder<any>;
  duplicateGive: (giveId: string) => void;
};

const filterDefaultValues: MultiFilterFormValue = createFilterDefaultValues(
  GiveFilterInput,
  GiveFilterInput.SEARCH_QUERY
);

const giveTableLimit = 50;

const { useForm } = Form;

function GivesTable() {
  const [filterForm] = useForm();
  const history = useHistory();
  const queryClient = useQueryClient();

  const {
    formattedQueryStringForApiFilter,
    formattedQueryStringForFormValues,
    queryString,
    setTableQueryString,
  } = useManageTableQueryString<GivesFilter>();

  const paginateQuery = {
    ...formattedQueryStringForApiFilter,
    limit: giveTableLimit,
  };

  const {
    currentPageCount,
    currentPage: currentPageData,
    goToNextPage: goToNextGivePage,
    goToPrevPage: goToPrevGivePage,
    goToFirstPage: goToFirstGivePage,
    isFetchingNextPage: isFetchingNextGivesPage,
    isLoading: isGivesLoading,
    isThereMoreToPaginate: isThereMoreGivesToPaginate,
    refetch: refetchGives,
  } = usePaginate({
    api: getGives,
    entity: Entity.GIVE,
    query: paginateQuery,
  });

  const { mutateAsync: duplicateGive, isLoading: isDuplicatingGive } =
    useDuplicateGiveMutation({
      onSuccess: (response) => {
        addGiveInInfiniteGivesQueryData(
          queryClient,
          response.duplicatedGive,
          paginateQuery
        );
        refetchGives();
      },
    });

  const gives = currentPageData?.data;

  useEffect(() => {
    refetchGives();
  }, []);

  function handleTableChange(
    pagination: any,
    filters: any,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) {
    setTableQueryString({
      ...queryString,
      sorter,
    });
  }

  function handleTableFilterChange(changedValues: any, allValues: any) {
    setTableQueryString(allValues);
  }

  function handleClearFilterAndSort() {
    setTableQueryString({});
    filterForm.setFieldsValue(filterDefaultValues);
    goToFirstGivePage();
  }

  function handleRowClick(give: Give, e: React.MouseEvent) {
    if (give.status === GIVE_STATUS.UNPROCESSED) {
      history.push(
        replaceRouteParams(routes.ADMIN_EDIT_GIVE, { giveId: give.id })
      );
    } else {
      history.push(
        replaceRouteParams(routes.ADMIN_VIEW_GIVE, { giveId: give.id })
      );
    }
  }

  const columns = makeColumns({
    duplicateGive,
    orderInfo: queryString.tableOrder,
  });

  return (
    <>
      <MultiFilter
        filterInputs={filterInputs}
        form={filterForm}
        initialFilterType={GiveFilterInput.SEARCH_QUERY}
        initialValues={formattedQueryStringForFormValues}
        onClearFilterAndSort={handleClearFilterAndSort}
        onValuesChange={handleTableFilterChange}
      />
      <S.StyledTable
        columns={columns}
        dataSource={gives}
        loading={{
          spinning:
            isGivesLoading || isFetchingNextGivesPage || isDuplicatingGive,
          indicator: <LoadingAnimation data-cy="table-loading-icon" />,
        }}
        paginate={{
          currentPageCount,
          isThereMoreItemsToPaginate: isThereMoreGivesToPaginate,
          onJumpToFirstPage: goToFirstGivePage,
          onNextPage: goToNextGivePage,
          onPrevPage: goToPrevGivePage,
          pageSize: giveTableLimit,
        }}
        scroll={{ x: 1300 }}
        onChange={handleTableChange}
        onRow={(record) => ({
          onClick: (e) => handleRowClick(record, e),
        })}
      />
    </>
  );
}

const filterInputs: FilterInputs = Object.values(GiveFilterInput).map(
  (giveFilterInput) => {
    let displayValue = capitalizeFirstLetter(giveFilterInput);
    let filterType;
    let selectOptions;
    let placeholder;

    switch (giveFilterInput) {
      case GiveFilterInput.SEARCH_QUERY:
        filterType = FilterType.TEXT;
        displayValue = 'Search';
        placeholder = 'Search give';
        break;
      case GiveFilterInput.DATE_CREATED_RANGE:
        filterType = FilterType.DATE_RANGE;
        displayValue = 'Date Created';
        break;
      case GiveFilterInput.TAX_DEDUCTIBLE:
        filterType = FilterType.SELECT;
        displayValue = 'Tax Deductible';
        placeholder = 'All';
        selectOptions = Object.values(TAX_DEDUCTIBLE).map((taxDeductible) => ({
          label: capitalizeFirstLetter(taxDeductible),
          value: taxDeductible,
        }));
        break;
      case GiveFilterInput.STATUS:
        filterType = FilterType.SELECT;
        placeholder = 'All';
        selectOptions = Object.values(GIVE_STATUS).map((giveStatus) => ({
          label: capitalizeFirstLetter(giveStatus),
          value: giveStatus,
        }));
    }

    return {
      filterType,
      placeholder,
      selectOptions,
      label: displayValue,
      key: giveFilterInput,
    };
  }
);

// eslint-disable-next-line sonarjs/cognitive-complexity
function makeColumns(params: MakeColumnsParams): ColumnsType<any> {
  const { orderInfo, duplicateGive } = params;

  return [
    {
      dataIndex: 'donorName',
      key: 'donorName',
      title: 'User (Giver)',
      sorter: (a: any, b: any) => 0,
      onCell: () => ({
        onClick: (e: React.MouseEvent) => e.stopPropagation(),
      }),
      sortOrder:
        orderInfo?.columnKey === 'donorName' ? orderInfo.order : undefined,
      render: (donorName: string, record: Give) => (
        <Link
          to={replaceRouteParams(routes.ADMIN_DONOR_ROOT, {
            donorId: record.donorId ?? '',
          })}
        >
          <Text isInline type="link">
            {donorName}
          </Text>
        </Link>
      ),
    },
    {
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      title: 'Received',
      sorter: (a: any, b: any) => 0,
      sortOrder:
        orderInfo?.columnKey === 'dateCreated' ? orderInfo.order : undefined,
      render: (dateCreated: string) => formatDate(dateCreated, FULL_MONTH_DATE),
    },
    {
      dataIndex: 'giveDate',
      key: 'giveDate',
      title: 'Give Date',
      sorter: (a: any, b: any) => 0,
      sortOrder:
        orderInfo?.columnKey === 'giveDate' ? orderInfo.order : undefined,
      render: (giveDate: string) => formatDate(giveDate, FULL_MONTH_DATE),
    },
    {
      dataIndex: 'recipientName',
      key: 'loweredCaseRecipientName',
      title: 'Recipient',
      sorter: (a: any, b: any) => 0,
      sortOrder:
        orderInfo?.columnKey === 'loweredCaseRecipientName'
          ? orderInfo.order
          : undefined,
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      title: 'Contribution',
      sorter: (a: any, b: any) => 0,
      sortOrder:
        orderInfo?.columnKey === 'amount' ? orderInfo.order : undefined,
      render: (amount: number, give: Give) => {
        if (amount === undefined || amount === null) {
          return '';
        }

        return give.unit === GIVE_UNIT.DOLLARS
          ? formatToCurrency(amount)
          : `${amount} hrs`;
      },
    },
    {
      dataIndex: 'matchedDonationAmount',
      key: 'matchedDonationAmount',
      title: 'Matched',
      sorter: (a: any, b: any) => 0,
      sortOrder:
        orderInfo?.columnKey === 'matchedDonationAmount'
          ? orderInfo.order
          : undefined,
      render: (amount: number) => {
        if (amount === undefined || amount === null) {
          return '';
        }

        return formatToCurrency(amount);
      },
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
      sorter: (a: any, b: any) => 0,
      sortOrder:
        orderInfo?.columnKey === 'status' ? orderInfo.order : undefined,
      render: (status: string) => capitalizeFirstLetter(status),
    },
    {
      dataIndex: 'taxDeductible',
      key: 'taxDeductible',
      title: 'Tax Deductible',
      sorter: (a: any, b: any) => 0,
      sortOrder:
        orderInfo?.columnKey === 'taxDeductible' ? orderInfo.order : undefined,
      render: (taxDeductible: string | null) =>
        taxDeductible && capitalizeFirstLetter(taxDeductible),
    },
    {
      dataIndex: 'platformName',
      key: 'platformName',
      title: 'Platform',
      sorter: (a: any, b: any) => 0,
      sortOrder:
        orderInfo?.columnKey === 'platformName' ? orderInfo.order : undefined,
    },
    {
      key: 'action',
      title: 'Action',
      fixed: 'right' as const,
      width: '100px',
      onCell: () => ({
        onClick: (e: React.MouseEvent) => e.stopPropagation(),
      }),
      render: (text: string, record: Indexable) => {
        const firstIcon =
          record.status === GIVE_STATUS.UNPROCESSED ? (
            <Tooltip placement="bottom" title="Process">
              <Link to={routes.ADMIN_EDIT_GIVE.replace(/:giveId/, record.id)}>
                <ExportOutlined />
              </Link>
            </Tooltip>
          ) : (
            <Tooltip placement="bottom" title="View">
              <Link to={routes.ADMIN_VIEW_GIVE.replace(/:giveId/, record.id)}>
                <EyeOutlined />
              </Link>
            </Tooltip>
          );

        async function handleDuplicateClick() {
          const isConfirm = window.confirm(
            'Are you sure you want to duplicate this give?'
          );

          if (isConfirm) {
            await duplicateGive(record.id);
          }
        }

        return (
          <Flex justifyContent="space-around">
            <S.IconButton>{firstIcon}</S.IconButton>
            <Tooltip placement="bottom" title="Duplicate">
              <S.IconButton
                data-cy="duplicate-icon"
                onClick={handleDuplicateClick}
              >
                <CopyOutlined />
              </S.IconButton>
            </Tooltip>
          </Flex>
        );
      },
    },
  ];
}

export default GivesTable;
