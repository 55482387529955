import { Tabs } from 'antd';
import styled from 'styled-components';

import { Button } from 'src/client/components';

export const Container = styled.div`
  margin-top: 40px;
  padding: 40px;
  border-radius: 24px;
  background: ${(props) => props.theme.colors.white};

  .ant-tabs-ink-bar {
    background: ${(props) => props.theme.colors.teal2};
  }

  .ant-btn-text {
    padding: 0;
  }
`;

export const StyledTab = styled(Tabs)`
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.teal2} !important;
  }
`;

export const SubmitButton = styled(Button)`
  margin-bottom: 40px;
  width: 100%;
`;

export const ForgotPasswordContainer = styled.div`
  display: flex;
  margin: -20px 0 24px 0;
  justify-content: flex-end;
`;
