import { FormInstance } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { Moment } from 'moment';
import { useState } from 'react';
import { UseMutateAsyncFunction, useQueryClient } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  getTouchedFieldsValue,
  getFieldsWithValue,
} from 'src/client/utils/FormUtils';
import routes from 'src/commons/constants/routes';
import {
  GIVE_STATUS,
  GIVE_UNIT,
  Give,
  Platform,
  RecipientBasic,
} from 'src/commons/types';

import { removeTimezone } from 'src/commons/utils/DateUtils';
import { RequireField } from 'src/commons/utils/TypescriptUtils';

import { ProcessGiveParams } from '../api/GiveApi';
import { ProcessGiveResponse } from '../types/ApiResponse';

import { useUpdateGiveMutation } from './mutations';
import { GET_GIVE_BY_ID_KEY } from './queries';
import { useNavigateNewerAndOlderUnprocessedGives } from './useNavigateNewerAndOlderUnprocessedGives';

type GetUpdatePayloadParams = {
  form: FormInstance<any>;
  give: Give | undefined;
  platforms: Platform[] | undefined;
  recipients: RecipientBasic[] | undefined;
};

type UrlParams = {
  giveId: string;
};

type UseHandleUpdateGiveParams = {
  give: Give | undefined;
  processGive: UseMutateAsyncFunction<
    ProcessGiveResponse,
    unknown,
    ProcessGiveParams,
    unknown
  >;
  platforms: Platform[] | undefined;
  recipients: RecipientBasic[] | undefined;
  form: FormInstance<any>;
};

export function useHandleUpdateGive(params: UseHandleUpdateGiveParams) {
  const { give, processGive, platforms, recipients, form } = params;

  const match = useRouteMatch<UrlParams>();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [shouldCheckForDuplicates, setShouldCheckForDuplicates] =
    useState(true);

  const { mutateAsync: updateGive, isLoading: isUpdatingGive } =
    useUpdateGiveMutation();
  const { goToNewerUnprocessedGive, newerUnprocessedGive } =
    useNavigateNewerAndOlderUnprocessedGives(give);

  async function handleUpdate() {
    const fieldsToUpdate: Partial<Give> = getUpdatePayload({
      form,
      give,
      platforms,
      recipients,
    });

    if (isEmpty(fieldsToUpdate)) {
      return null;
    }

    const giveUpdatePayload = {
      id: match.params.giveId,
      ...fieldsToUpdate,
    };

    const shouldUpdateGive = give?.status === GIVE_STATUS.PROCESSED;

    if (shouldUpdateGive) {
      await updateGive(giveUpdatePayload);
      queryClient.invalidateQueries([GET_GIVE_BY_ID_KEY, { giveId: give.id }]);

      redirectToGivesTable();
    } else {
      await handleProcessGive(giveUpdatePayload);
      queryClient.invalidateQueries([
        GET_GIVE_BY_ID_KEY,
        { giveId: match.params.giveId },
      ]);
    }
  }

  async function handleProcessGive(
    giveUpdatePayload: RequireField<Partial<Give>, 'id'>
  ) {
    const newProcessGiveResponse = await processGive({
      give: giveUpdatePayload,
      shouldCheckForDuplicates,
    });

    const isThereDuplicateGives =
      newProcessGiveResponse.data.duplicateGiveIds &&
      newProcessGiveResponse?.data?.duplicateGiveIds?.length > 0;

    if (isThereDuplicateGives) {
      setShouldCheckForDuplicates(false);
    } else {
      redirectAfterProcessingGive();
    }
  }

  function redirectAfterProcessingGive() {
    if (newerUnprocessedGive) {
      goToNewerUnprocessedGive();
    } else {
      redirectToGivesTable();
    }
  }

  function redirectToGivesTable() {
    history.push(routes.ADMIN_GIVES);
  }

  return {
    handleUpdate,
    isUpdatingGive,
  };
}

const matchedDonationFields = [
  'matchedDonationAmount',
  'matchedDonationTaxDeductible',
  'matchedDonationCompany',
];

function getUpdatePayload(params: GetUpdatePayloadParams) {
  const { form, give, platforms, recipients } = params;

  const isGiveAboutToBeProcessed = give?.status === GIVE_STATUS.UNPROCESSED;
  const isGiveAboutToBeUpdated = give?.status === GIVE_STATUS.PROCESSED;
  const formValues = getFieldsWithValue(form);
  let fieldsToUpdate: Record<string, any>;

  if (isGiveAboutToBeProcessed) {
    fieldsToUpdate = { ...formValues };
  } else if (isGiveAboutToBeUpdated) {
    fieldsToUpdate = { ...getTouchedFieldsValue(form) };

    if (fieldsToUpdate.isDonationMatching) {
      matchedDonationFields.forEach((field) => {
        fieldsToUpdate[field] = formValues[field];
      });
    }
  } else {
    throw new Error('Give should be unprocessed or processed');
  }

  if (fieldsToUpdate.recipientId) {
    fieldsToUpdate.recipientName = recipients?.find(
      (recipient) => recipient.id === fieldsToUpdate.recipientId
    )?.name;
  }

  if (fieldsToUpdate.platformId) {
    fieldsToUpdate.platformName = platforms?.find(
      (platform) => platform.id === fieldsToUpdate.platformId
    )?.name;
  }

  if (fieldsToUpdate.giveDate && fieldsToUpdate.giveTime) {
    const giveDate = (fieldsToUpdate.giveDate as Moment).toDate();
    const giveTime = (fieldsToUpdate.giveTime as Moment).toDate();
    const hours = giveTime.getHours();
    const minutes = giveTime.getMinutes();
    const seconds = giveTime.getSeconds();
    const finalDate = giveDate.setHours(hours, minutes, seconds, 0);

    fieldsToUpdate.giveDate = removeTimezone(new Date(finalDate));
  }

  if (fieldsToUpdate.giveDate && !fieldsToUpdate.giveTime) {
    const giveDateMilliseconds = (fieldsToUpdate.giveDate as Moment)
      .toDate()
      .setHours(0, 0, 0, 0);
    fieldsToUpdate.giveDate = new Date(giveDateMilliseconds);
  }

  const shouldRemoveSplitDonation = Boolean(
    fieldsToUpdate.isDonationSplit === false && give?.splitAmount
  );

  const shouldRemoveDonationMatching = Boolean(
    fieldsToUpdate.isDonationMatching === false && give?.matchedDonationAmount
  );

  if (shouldRemoveSplitDonation) {
    fieldsToUpdate.splitAmount = null;
    fieldsToUpdate.splitTaxDeductible = null;
    fieldsToUpdate.splitType = null;
  }

  if (shouldRemoveDonationMatching) {
    fieldsToUpdate.matchedDonationAmount = null;
    fieldsToUpdate.matchedDonationTaxDeductible = null;
    fieldsToUpdate.matchedDonationCompany = null;
  }

  if (fieldsToUpdate.amount !== undefined) {
    fieldsToUpdate.amount = Number(fieldsToUpdate.amount);
    fieldsToUpdate.unit = GIVE_UNIT.DOLLARS;
  }

  if (fieldsToUpdate.hours !== undefined) {
    fieldsToUpdate.amount = Number(fieldsToUpdate.hours);
    fieldsToUpdate.unit = GIVE_UNIT.HOURS;
    delete fieldsToUpdate.hours;
  }

  if (fieldsToUpdate.startDate) {
    fieldsToUpdate.startDate = removeTimezone(
      (fieldsToUpdate.startDate as Moment).startOf('day').toDate()
    );
  }

  if (fieldsToUpdate.endDate) {
    fieldsToUpdate.endDate = removeTimezone(
      (fieldsToUpdate.endDate as Moment).startOf('day').toDate()
    );
  }

  delete fieldsToUpdate.donationType;
  delete fieldsToUpdate.giveTime;
  delete fieldsToUpdate.isDonationSplit;
  delete fieldsToUpdate.isDonationMatching;

  return fieldsToUpdate;
}
