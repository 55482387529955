import { Switch as AntdSwitch } from 'antd';
import React from 'react';
import styled from 'styled-components';

type Props = React.ComponentProps<typeof AntdSwitch>;

function Switch(props: Props) {
  return <StyledSwitch {...props} />;
}

const StyledSwitch = styled(AntdSwitch)`
  &.ant-switch-checked {
    background-color: ${(props) => props.theme.colors.teal2};
  }
`;

export default Switch;
