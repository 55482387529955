import { QueryClient, useQuery } from 'react-query';

import { getCurrentAccount } from 'src/client/api/AccountApi';
import { Account } from 'src/commons/types';

type CommonAccountParams = {
  data: Partial<Account>;
  queryClient: QueryClient;
};

const GET_CURRENT_ACCOUNT_KEY = 'GET_CURRENT_ACCOUNT_KEY';

export function useGetCurrentAccount() {
  return useQuery(GET_CURRENT_ACCOUNT_KEY, () => getCurrentAccount());
}

export function updateGetCurrentAccountQuery(params: CommonAccountParams) {
  const { data, queryClient } = params;

  queryClient.setQueryData<Account | undefined>(
    GET_CURRENT_ACCOUNT_KEY,
    (oldData: Account | undefined) =>
      oldData &&
      ({
        ...oldData,
        ...data,
      } as Account)
  );
}
