import styled from 'styled-components';

import { Flex, Text } from 'src/client/components';

export const Container = styled.div`
  padding: 8px 10px;
  width: fit-content;
  border: 1px solid ${(props) => props.theme.colors.darkSurface3};
  border-radius: 8px;
  z-index: 99;
  background: white;

  button {
    padding: 8px 16px;
  }
`;

export const Body = styled(Flex)`
  align-items: center;

  & > div {
    margin: 0 43px 0 14px;
  }
`;

export const DescriptionText = styled(Text)`
  color: ${(props) => props.theme.colors.darkEmphasis2};
`;
