import { EditOutlined } from '@ant-design/icons';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React from 'react';

import { Button } from 'src/client/components';

import SaveCancelButtons from '../SaveCancelButtons';

import * as S from './styles';

type Props = {
  cancelButtonCyId: string;
  className?: string;
  editButtonCyId: string;
  editButtonText: {
    bigScreenText: string;
    smallScreenText: string;
  };
  isEditing: boolean;
  isSaving: boolean;
  isViewingPublicly: boolean;
  onCancel: () => void;
  onEdit: () => void;
  onSave: () => void;
  saveButtonCyId: string;
  title: string;
  isSaveButtonDisabled: boolean;
};

function EditControlButtons(props: Props) {
  const {
    cancelButtonCyId,
    className,
    editButtonCyId,
    editButtonText,
    isEditing,
    isSaveButtonDisabled,
    isSaving,
    isViewingPublicly,
    onCancel,
    onEdit,
    onSave,
    saveButtonCyId,
    title,
  } = props;

  const screens = useBreakpoint();
  const isBigScreen = screens.md;

  if (isViewingPublicly) {
    return null;
  }

  const content = isEditing ? (
    <SaveCancelButtons
      cancelButtonProps={{
        ['data-cy']: cancelButtonCyId,
      }}
      className="save-cancel-btn"
      saveButtonProps={{
        ['data-cy']: saveButtonCyId,
        disabled: isSaveButtonDisabled,
        loading: isSaving,
      }}
      title={title}
      onCancel={onCancel}
      onSave={onSave}
    />
  ) : (
    <Button
      className="edit-btn"
      data-cy={editButtonCyId}
      type="elevated1"
      onClick={onEdit}
    >
      <EditOutlined />
      {isBigScreen
        ? editButtonText.bigScreenText
        : editButtonText.smallScreenText}
    </Button>
  );

  return <S.Container className={className}>{content}</S.Container>;
}

export default EditControlButtons;
