import {
  DashboardOutlined,
  FileTextOutlined,
  HomeOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { MenuSiderConfig } from 'src/client/components/AdminSider';

import { CurrentDonorInViewContext } from 'src/client/contexts/CurrentDonorInViewContext';
import {
  useGetDonorById,
  useGetDonorGalleryPage,
} from 'src/client/hooks/queries';
import AdminLayout from 'src/client/layouts/AdminLayout';
import DonorGalleryV2 from 'src/client/pages/DonorGalleryV2';
import DonorTax from 'src/client/pages/TaxDeductions';
import routes from 'src/commons/constants/routes';
import { replaceRouteParams } from 'src/commons/utils/RouteUtils';

import {
  DonorDashboard,
  DonorHomePage,
  LoadingPage,
  PageNotFound,
} from '../../pages';

type UrlParams = {
  donorId: string;
};

function AdminDonorRoutes() {
  const match = useRouteMatch<UrlParams>();

  const { data: donor, isLoading: isDonorLoading } = useGetDonorById(
    match.params.donorId
  );
  const { data: page, isFetching: isFetchingGalleryPage } =
    useGetDonorGalleryPage(donor?.id as string);

  if (isDonorLoading || isFetchingGalleryPage) {
    return <LoadingPage />;
  }

  if (!donor) {
    return <PageNotFound />;
  }

  return (
    <CurrentDonorInViewContext.Provider value={donor}>
      <Switch>
        <Route exact path={routes.ADMIN_DONOR_GALLERY}>
          <DonorGalleryV2 isViewingPublicly={false} />
        </Route>
        <AdminLayout
          donorId={donor.id}
          mobileSiderTitle={`${donor.name}'s Pages`}
          siderConfig={getSiderConfig({
            donorId: donor.id,
            pageId: page?.id as string,
          })}
        >
          <Switch>
            <Route exact path={routes.ADMIN_DONOR_ROOT}>
              <DonorDashboard donor={donor} />
            </Route>
            <Route exact path={routes.ADMIN_DONOR_DASHBOARD_VIEW}>
              <DonorDashboard donor={donor} />
            </Route>
            <Route path={routes.ADMIN_DONOR_HOME}>
              <DonorHomePage donor={donor} />
            </Route>
            <Route exact path={routes.PUBLIC_GALLERY}>
              <DonorGalleryV2 isViewingPublicly />
            </Route>
            <Route exact path={routes.ADMIN_DONOR_TAX}>
              <DonorTax donor={donor} />
            </Route>
          </Switch>
        </AdminLayout>
      </Switch>
    </CurrentDonorInViewContext.Provider>
  );
}

type GetSiderConfigParams = {
  donorId: string;
  pageId: string;
};

function getSiderConfig(params: GetSiderConfigParams): MenuSiderConfig[] {
  const { donorId, pageId } = params;

  return [
    {
      route: replaceRouteParams(routes.ADMIN_DONOR_HOME, {
        donorId: donorId,
      }),
      name: 'Home',
      icon: <HomeOutlined />,
      keys: [routes.ADMIN_DONOR_HOME],
    },
    {
      route: replaceRouteParams(routes.ADMIN_DONOR_DASHBOARD, {
        donorId: donorId,
      }),
      name: 'Dashboard',
      icon: <DashboardOutlined />,
      keys: [routes.ADMIN_DONOR_DASHBOARD, routes.ADMIN_DONOR_DASHBOARD_VIEW],
    },
    {
      route: replaceRouteParams(routes.ADMIN_DONOR_GALLERY, {
        donorId,
        pageId,
      }),
      name: 'Gallery',
      icon: <PictureOutlined />,
      keys: [routes.ADMIN_DONOR_GALLERY],
    },
    {
      route: replaceRouteParams(routes.ADMIN_DONOR_TAX, {
        donorId: donorId,
      }),
      name: 'Tax Info',
      icon: <FileTextOutlined />,
      keys: [routes.ADMIN_DONOR_TAX],
    },
  ];
}

export default AdminDonorRoutes;
