import styled from 'styled-components';

import { Flex, Table } from 'src/client/components';
import { BODY_1_REG_2 } from 'src/client/constants/text';

export const Container = styled.div`
  input,
  .ant-picker {
    padding: 8px 12px;
    height: 40px;
    ${BODY_1_REG_2};
  }
`;

export const StyledTable = styled(Table)`
  td:nth-child(2),
  td:nth-child(5) {
    text-align: right;
  }

  tbody tr:nth-child(odd) {
    background-color: #fafafa;

    td:last-child {
      background-color: #fafafa;
    }
  }

  tbody tr:nth-child(even) {
    background-color: ${(props) => props.theme.colors.white};
  }
`;

export const ActionButtonsContainer = styled(Flex)`
  justify-content: space-around;
  align-items: center;

  svg {
    color: ${(props) => props.theme.colors.teal2};
  }
`;
