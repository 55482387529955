import { RadioChangeEvent } from 'antd';
import React from 'react';

import {
  RadioButtonLeft,
  RadioButtonRight,
  RadioButtonText,
  RadioGroup,
} from './styles';

type Props = {
  isDisabled?: boolean;
  onChange: (e: RadioChangeEvent) => void;
  value?: string;
  value1: string;
  value2: string;
  text1?: string;
  noStyle?: string;
};

export default function RadioButtonGroup(props: Props) {
  const {
    isDisabled = false,
    onChange,
    value,
    value1,
    value2,
    text1,
    noStyle,
  } = props;

  const radioButtonLeftText = text1 ?? value1;
  const radioButtonRightText = noStyle ?? value2;

  return (
    <RadioGroup
      buttonStyle="solid"
      defaultValue={value1}
      disabled={isDisabled}
      value={value}
      onChange={onChange}
    >
      <RadioButtonLeft value={value1}>
        <RadioButtonText color="black" type="button-1-2">
          {radioButtonLeftText}
        </RadioButtonText>
      </RadioButtonLeft>
      <RadioButtonRight value={value2}>
        <RadioButtonText color="black" type="button-1-2">
          {radioButtonRightText}
        </RadioButtonText>
      </RadioButtonRight>
    </RadioGroup>
  );
}
