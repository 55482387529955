import React from 'react';
import styled from 'styled-components';

import { Text, Flex } from 'src/client/components';
import StatCard from 'src/client/components/v2/StatCard';
import { getTaxDeductibleStats } from 'src/client/utils/GiveUtils';
import { Give } from 'src/commons/types/Give.type';
import { formatToCurrency } from 'src/commons/utils/MoneyUtilts';

type Props = {
  gives: Give[];
  isLoading?: boolean;
};

function Statistics(props: Props) {
  const { gives, isLoading } = props;

  const { taxDeductibleAmount, taxDeductibleCount, givesCount } =
    getTaxDeductibleStats(gives);

  return (
    <Container>
      <StatCard isLoading={isLoading} title="Total tax-deductible amount">
        <Text type="displayMMed2">{formatToCurrency(taxDeductibleAmount)}</Text>
      </StatCard>
      <StatCard isLoading={isLoading} title="Number of tax-deductible gives">
        <Flex alignItems="baseline" gap="6px">
          <Text type="displayMMed2">{taxDeductibleCount}</Text>
          <Text type="h6med2">out of {givesCount}</Text>
        </Flex>
      </StatCard>
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  margin: 45px 0;
  flex-wrap: wrap;
  gap: 33px;
`;

export default Statistics;
