import { AlternateEmail } from 'src/commons/types';
import { ApiResponse } from 'src/commons/types/Response.type';

import ApiClient from './ApiClient';

const apiVersion = '1.0';
const endpoint = 'alternate-emails';
const url = `${apiVersion}/${endpoint}`;

type CreateAlternateEmailsParams = {
  donorId: string;
  newAlternateEmails: string[];
};

type DeleteAlternateEmailsParams = {
  deletedAlternateEmailIds: string[];
  donorId: string;
};

type SwitchPrimaryEmailParams = {
  targetAlternateEmailId: string;
  donorId: string;
  passwordConfirmation: string;
};

type SendAlternateEmailVerificationParams = {
  alternateEmailId: string;
  alternateEmail: string;
};

export async function getAlternateEmails(
  donorId: string
): Promise<AlternateEmail[]> {
  const { data } = await ApiClient.get<AlternateEmail[]>(
    `${url}/donors/${donorId}`
  );

  return data;
}

export async function createAlternateEmails(
  params: CreateAlternateEmailsParams
): Promise<AlternateEmail[]> {
  const { data } = await ApiClient.post<AlternateEmail[]>(
    `${url}/donors/${params.donorId}`,
    {
      newAlternateEmails: params.newAlternateEmails,
    }
  );

  return data;
}

export async function deleteAlternateEmails(
  params: DeleteAlternateEmailsParams
): Promise<
  ApiResponse<{
    deleteAlternateEmails: string[];
  }>
> {
  const { data } = await ApiClient.delete<
    ApiResponse<{ deleteAlternateEmails: string[] }>
  >(`${url}/donors/${params.donorId}`, {
    params: {
      deletedAlternateEmailIds: params.deletedAlternateEmailIds,
    },
  });

  return data;
}

export async function switchPrimaryEmail(params: SwitchPrimaryEmailParams) {
  const { data } = await ApiClient.patch(
    `${url}/donors/${params.donorId}/primary-email`,
    {
      targetAlternateEmailId: params.targetAlternateEmailId,
      passwordConfirmation: params.passwordConfirmation,
    }
  );

  return data;
}

export async function sendAlternateEmailVerification(
  params: SendAlternateEmailVerificationParams
) {
  const { data } = await ApiClient.post(`${url}/verification-email`, params);

  return data;
}

export async function verifyAlternateEmail(token: string) {
  const { data } = await ApiClient.patch(`${url}/verify/${token}`);

  return data;
}
