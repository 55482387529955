import { Layout } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { Text } from 'src/client/components';

import { getCurrentYear } from 'src/commons/utils/DateUtils';

type Props = {
  className?: string;
};

const { Footer } = Layout;

function MinimalisticFooter(props: Props) {
  const { className = '' } = props;

  return (
    <Container className={className}>
      <StyledText>©{getCurrentYear()} Giving Side</StyledText>
      <StyledText>The Town | Oakland, CA</StyledText>
      <a href="/privacy-policy">
        <StyledText>Privacy Policy</StyledText>
      </a>
    </Container>
  );
}

const Container = styled(Footer)`
  display: flex;
  padding: 16px 0px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.midnight7};
  gap: 24px;

  @media (max-width: ${(props) => props.theme.size.mobileL}) {
    flex-direction: column;
    align-items: flex-start;
    text-indent: 15px;
    gap: 5px;
  }
`;

const StyledText = styled(Text).attrs({
  type: 'caption2',
})`
  color: ${(props) => props.theme.colors.lightMedEmphasis};
`;

export default MinimalisticFooter;
