import { Admin } from 'src/commons/types/Admin.type';

export function transformToTable(user: Admin) {
  return {
    ...user,
    fullName:
      user.lastName && user.firstName
        ? `${user.lastName}, ${user.firstName}`
        : user.username,
  };
}
