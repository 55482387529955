import React from 'react';

import FeaturedCardsViewModeBase from 'src/client/pages/DonorGallery/components/FeaturedCardsViewMode';
import { PageRecipient } from 'src/commons/types';

import * as stylesheet from './styles';

type Props = {
  featuredPageRecipients: PageRecipient[] | undefined;
  isLoading: boolean;
};

function FeaturedCardsViewMode(props: Props) {
  return <FeaturedCardsViewModeBase {...props} styles={stylesheet} />;
}

export default FeaturedCardsViewMode;
