import React from 'react';

import { useTheme } from 'styled-components';

import { Box } from 'src/client/components/StyledCommon';
import Text from 'src/client/components/Text';

import ActionButtons from '../ActionButtons';

import * as S from './styles';

type Props = {
  goal: number | null;
  isSavingGoal: boolean;
  onCancel: () => void;
  onSave: () => void;
  setNewGoal: (newGoal: number | null) => void;
};

function FixedAmountEditState(props: Props) {
  const { goal, isSavingGoal, onCancel, onSave, setNewGoal } = props;

  const { colors } = useTheme();

  function handleGoalChange(value: number) {
    if (Number.isNaN(value)) {
      setNewGoal(null);
    } else {
      setNewGoal(value);
    }
  }

  const isSaveButtonDisabled = typeof goal !== 'number';

  return (
    <S.Container>
      <Box margin="98px 0 13px 0">
        <Text color={colors.lightMedEmphasis} type="h6med2">
          Fixed Amount Goal
        </Text>
      </Box>
      <Text type="h3med2">
        Set a goal for yourself this year to track progress over time
      </Text>
      <S.AmountInput
        data-cy="fixed-amount-input"
        placeholder="$0.00"
        prefixValue="$"
        value={goal || 0}
        onChange={handleGoalChange}
      />
      <Text color={colors.lightMedEmphasis} type="body2reg2">
        Don&apos;t worry, you can edit your goal at any time.
      </Text>
      <Box margin="0 0 100px 0" />
      <ActionButtons
        confirmButtonProps={{
          disabled: isSaveButtonDisabled,
          loading: isSavingGoal,
        }}
        onCancel={onCancel}
        onConfirm={onSave}
      />
    </S.Container>
  );
}

export default FixedAmountEditState;
