/* eslint-disable sonarjs/cognitive-complexity */
import { Col, Form, Modal, Row } from 'antd';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';

import DatePicker from 'src/client/components/DatePicker';
import Input from 'src/client/components/Input';
import InputNumber from 'src/client/components/InputNumber';
import LoadingCard from 'src/client/components/LoadingCard';
import { Box, Flex } from 'src/client/components/StyledCommon';
import Text from 'src/client/components/Text';
import Select from 'src/client/components/v2/Select';
import { useUpdateGiveMutation } from 'src/client/hooks/mutations';
import { useGetRecipientLogo } from 'src/client/hooks/queries/imageQueries';
import { analytics } from 'src/client/libs/segment';
import { getFormErrorList } from 'src/client/utils/FormUtils';
import { FULL_MONTH_DATE } from 'src/commons/constants/dateFormat';
import { SEGMENT_EVENTS } from 'src/commons/constants/segment';
import { Give, GIVE_FREQUENCY, TAX_DEDUCTIBLE } from 'src/commons/types';
import { removeTimezone } from 'src/commons/utils/DateUtils';
import {
  capitalizeFirstLetter,
  toTrimmedString,
} from 'src/commons/utils/StringUtils';

import * as S from './styles';

type ModalProps = React.ComponentProps<typeof Modal>;

type Props = ModalProps & {
  give: Give;
  onClose: () => void;
  onSave: (give: Give) => void;
};

const { TextArea } = Input;

const formGutter = 10;

const { useForm } = Form;

function EditGiveModal(props: Props) {
  const { give, onClose, onSave, ...modalProps } = props;

  const [editGiveForm] = useForm();
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  const { mutateAsync: updateGive, isLoading: isUpdateGiveLoading } =
    useUpdateGiveMutation({
      onSuccess: (newGive) => {
        onSave(newGive.data);
      },
    });

  const { data: recipientLogo, isLoading: isRecipientLogoLoading } =
    useGetRecipientLogo(give?.recipientId);

  async function handleUpdateGive() {
    const formValues = editGiveForm.getFieldsValue();
    const dataToUpdate: Partial<Give> = {};

    if (editGiveForm.isFieldTouched('taxDeductible')) {
      dataToUpdate.taxDeductible = formValues.taxDeductible.toLowerCase();
    }

    if (editGiveForm.isFieldTouched('frequency')) {
      dataToUpdate.frequency = formValues.frequency?.toLowerCase() ?? null;
    }

    if (editGiveForm.isFieldTouched('giveDate')) {
      dataToUpdate.giveDate = removeTimezone(formValues.giveDate.toDate());
    }

    if (editGiveForm.isFieldTouched('amount')) {
      dataToUpdate.amount = formValues.amount || 0;
    }

    if (editGiveForm.isFieldTouched('splitAmount')) {
      dataToUpdate.splitAmount = formValues.splitAmount;
    }

    if (editGiveForm.isFieldTouched('splitTaxDeductible')) {
      dataToUpdate.splitTaxDeductible =
        formValues.splitTaxDeductible?.toLowerCase() ?? null;
    }

    if (editGiveForm.isFieldTouched('detailEntry')) {
      dataToUpdate.detailEntry = toTrimmedString(formValues.detailEntry);
    }

    if (editGiveForm.isFieldsTouched()) {
      await updateGive({
        id: give?.id,
        ...dataToUpdate,
      });

      analytics.track(SEGMENT_EVENTS.USER_SAVED_EDIT_GIVE_MODAL, {
        fieldChanges: dataToUpdate,
        giveId: give?.id,
      });
    }

    onClose();
  }

  function handleEditGiveOkClick() {
    const errors = getFormErrorList(editGiveForm);

    if (errors.length) {
      return null;
    }

    handleUpdateGive();
  }

  function handleFormChange() {
    const isSaveButtonDisabled =
      !editGiveForm.isFieldsTouched() ||
      !!getFormErrorList(editGiveForm).length;
    setIsSaveButtonDisabled(isSaveButtonDisabled);
  }

  const taxDeductibleOptions = Object.values(TAX_DEDUCTIBLE).map(
    (taxDeductible) => {
      const formattedTaxDeductible = capitalizeFirstLetter(taxDeductible);

      return (
        <Select.Option key={taxDeductible} value={taxDeductible}>
          {formattedTaxDeductible}
        </Select.Option>
      );
    }
  );

  const frequencyOptions = Object.values(GIVE_FREQUENCY).map((frequency) => {
    const formattedFrequency = capitalizeFirstLetter(frequency);

    return (
      <Select.Option key={frequency} value={frequency}>
        {formattedFrequency}
      </Select.Option>
    );
  });

  const formInitialValues: Omit<Give, 'giveDate'> & {
    giveDate: Moment | null;
  } = {
    ...give,
    amount:
      typeof give.amount === 'number'
        ? (give.amount.toFixed(2) as unknown as number)
        : null,
    splitAmount:
      typeof give.splitAmount === 'number'
        ? (give.splitAmount.toFixed(2) as unknown as number)
        : null,
    giveDate: give.giveDate ? moment(give.giveDate) : null,
    detailEntry: give.detailEntry?.trim(),
    frequency: getFrequencyDisplay(give),
  };

  return (
    <S.Container
      {...modalProps}
      confirmLoading={isUpdateGiveLoading}
      data-cy="edit-give-modal"
      okButtonProps={{
        disabled: isSaveButtonDisabled,
        loading: isUpdateGiveLoading,
      }}
      okText="Save Changes"
      onOk={handleEditGiveOkClick}
    >
      <Flex alignItems="center" margin="0 0 34px 0">
        <S.RecipientLogo logo={recipientLogo?.recipientLogoImagePath}>
          <LoadingCard isLoading={isRecipientLogoLoading} />
        </S.RecipientLogo>
        <Text type="h3med2">{give?.recipientName}</Text>
      </Flex>
      <Form
        form={editGiveForm}
        initialValues={formInitialValues}
        labelAlign="left"
        labelCol={{ span: 24 }}
        layout="vertical"
        onFieldsChange={handleFormChange}
      >
        <Row gutter={formGutter}>
          <Col md={12} xs={20}>
            <Form.Item
              data-testid="give-date-form-item"
              label="Date of donation"
              name="giveDate"
              rules={[
                { required: true, message: 'Date of Donation is required' },
              ]}
            >
              <DatePicker format={FULL_MONTH_DATE} size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Box margin="36px 0 0 0" />
        <Row gutter={formGutter}>
          <Col span={24}>
            <Form.Item label="Note (Optional)" name="detailEntry">
              <TextArea placeholder="Add a note" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Box margin="50px 0 0 0" />
        <Flex alignItems="center">
          <S.HeartIcon src="/heartIcon.png" />
          <Text type="h5med2">Donation</Text>
        </Flex>
        <Box margin="21px 0 16px 0">
          <S.Divider />
        </Box>
        <Row justify="space-between">
          <Col md={11} xs={21}>
            <Form.Item
              label="Amount"
              name="amount"
              rules={[{ required: true, message: 'Amount is required' }]}
            >
              <InputNumber placeholder="0" prefix="$" step="0.01" />
            </Form.Item>
          </Col>
          <Col md={11} xs={20}>
            <Form.Item
              extra="Is the donation amount tax deductible?"
              label="Tax Deductible"
              name="taxDeductible"
              rules={[
                { required: true, message: 'Tax Deductible is required' },
              ]}
            >
              <S.StyledSelect
                data-testid="tax-deductible-select"
                placeholder="Tax Deductible"
              >
                {taxDeductibleOptions}
              </S.StyledSelect>
            </Form.Item>
          </Col>
        </Row>
        <Box margin="28px 0 16px 0">
          <S.Divider />
        </Box>
        <Row justify="space-between">
          <Col md={11} xs={21}>
            <Form.Item label="Tips/Fee" name="splitAmount">
              <InputNumber
                data-cy="tips-fee-input"
                placeholder="0"
                prefix="$"
                step="0.01"
              />
            </Form.Item>
          </Col>
          <Col md={11} xs={21}>
            <Form.Item
              extra="Is the Tip/Fee amount tax deductible?"
              label="Tips/Fee Tax Deductible"
              name="splitTaxDeductible"
            >
              <S.StyledSelect
                data-testid="split-tax-deductible-select"
                placeholder="Tip/Fee Tax Deductible"
              >
                {taxDeductibleOptions}
              </S.StyledSelect>
            </Form.Item>
          </Col>
        </Row>
        <Box margin="28px 0 25px 0">
          <S.Divider />
        </Box>
        <Flex alignItems="center" margin="0 0 20px 0">
          <S.HourglassIcon src="/hourglassIcon.png" />
          <Text type="h5med2">Duration</Text>
        </Flex>
        <Row>
          <Col span={11}>
            <Form.Item label="Frequency" name="frequency">
              <S.StyledSelect
                data-testid="frequency-select"
                placeholder="Frequency"
              >
                {frequencyOptions}
              </S.StyledSelect>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </S.Container>
  );
}

function getFrequencyDisplay(record: Give) {
  if (!record.isRecurring && record.frequency === null) {
    return GIVE_FREQUENCY.ONE_TIME;
  } else if (record.frequency) {
    return record.frequency;
  } else {
    return undefined;
  }
}

export default EditGiveModal;
