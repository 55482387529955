import { Grid } from 'antd';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Box, Button, Flex, Text } from 'src/client/components';
import { YEAR_VIEW } from 'src/client/constants/DonorPages';
import { GIVING_SIDE_EMAIL } from 'src/client/constants/email';
import {
  useGetRecipientsByIds,
  useGetRecipientsLogo,
} from 'src/client/hooks/queries';
import { useScrollToUrlHash } from 'src/client/hooks/useScrollToUrlHash';
import {
  getRecipientById,
  getRecipientIdsFromGives,
} from 'src/client/utils/Recipientutils';
import routes from 'src/commons/constants/routes';
import { GIVE_UNIT, Give } from 'src/commons/types';
import { formatDate, getCurrentYear } from 'src/commons/utils/DateUtils';

import { formatToCurrency } from 'src/commons/utils/MoneyUtilts';

import { cleanRecipientUrl } from 'src/commons/utils/StringUtils';

import LoadingAnimation from '../LoadingAnimation';

import * as S from './styles';

type Props = {
  yearView: YEAR_VIEW.ALL_TIME | number;
  gives: Give[] | undefined;
  isGivesLoading: boolean;
};

const givesLimit = 5;
const { useBreakpoint } = Grid;

function RecentGivesCard(props: Props) {
  const { yearView, gives, isGivesLoading } = props;

  useScrollToUrlHash();

  const match = useRouteMatch();
  const dashboardRouteMatch = useRouteMatch(
    routes.DONOR_PERSONAL_DASHBOARD_VIEW
  );
  const { colors } = useTheme();
  const screens = useBreakpoint();
  const recentGives = getRecentGives();
  const recipientIds = recentGives && getRecipientIdsFromGives(recentGives);
  const { data: recipients } = useGetRecipientsByIds(recipientIds);
  const { data: recipientsLogo, isLoading: isRecipientsLogoLoading } =
    useGetRecipientsLogo(recipientIds);

  function getRecentGives() {
    if (gives) {
      const sortedGivesByDescending = [...gives].sort(
        (giveA, giveB) =>
          new Date(giveB.giveDate as unknown as string).getTime() -
          new Date(giveA.giveDate as unknown as string).getTime()
      );

      return sortedGivesByDescending?.slice(0, givesLimit);
    }
  }

  function getGivesSectionUrl() {
    const urlHash = '#gives-section';

    if (dashboardRouteMatch?.isExact) {
      return `${match.url}${urlHash}`;
    } else {
      return `${routes.DASHBOARD}${urlHash}`;
    }
  }

  const recentGivesRow = recentGives?.map((give) => {
    const recipient = getRecipientById(recipients, give.recipientId);
    const recipientLogo = recipientsLogo?.find(
      (recipientLogo) => recipientLogo.recipientId === give.recipientId
    )?.imagePath;
    const recipientDefaultLogo = !recipientLogo && (
      <Text type="h2med2">{give.recipientName?.[0]}</Text>
    );

    const renderRecipientLogo = isRecipientsLogoLoading ? (
      <S.RecipientImageSpinContainer>
        <LoadingAnimation />
      </S.RecipientImageSpinContainer>
    ) : (
      <S.RecipientImage logo={recipientLogo}>
        {recipientDefaultLogo}
      </S.RecipientImage>
    );

    const website = screens.lg && (
      <Text color={colors.lightMedEmphasis} type="body2reg2">
        {cleanRecipientUrl(recipient?.website as string)}
      </Text>
    );

    return (
      <S.GiveRowContainer key={give.id}>
        <Flex alignItems="center">
          {renderRecipientLogo}
          <S.InfoContainer>
            <S.RecipientNameContainer>
              <S.RecipientNameText type="h6med2">
                {give.recipientName}
              </S.RecipientNameText>
              {website}
            </S.RecipientNameContainer>
            <S.GiveAmountContainer>
              <Text type="h6med2">
                {(give.unit === GIVE_UNIT.DOLLARS ? formatToCurrency(give.amount as number) : `${give.amount} hours`)}
              </Text>
              <Text color={colors.lightMedEmphasis} type="body2reg2">
                {formatDate(give.giveDate as string)}
              </Text>
            </S.GiveAmountContainer>
          </S.InfoContainer>
        </Flex>
      </S.GiveRowContainer>
    );
  });

  const emptyGiveContent = (
    <S.EmptyScreenContainer>
      <Text isInline color={colors.lightMedEmphasis} type="caption1">
        Forward your next give to&nbsp;
      </Text>
      <Text isInline color={colors.teal1} type="caption1">
        {GIVING_SIDE_EMAIL}
      </Text>
    </S.EmptyScreenContainer>
  );

  const isDashboardViewCurrentYearOrAllTime =
    yearView === getCurrentYear() || yearView === YEAR_VIEW.ALL_TIME;
  const titleText = isDashboardViewCurrentYearOrAllTime
    ? 'Recent Gives'
    : `${yearView} Gives`;

  const isGivesEmpty = recentGives?.length === 0;
  const renderGivesContent = isGivesEmpty ? emptyGiveContent : recentGivesRow;

  return (
    <S.Container>
      <Flex alignItems="center" flexWrap="wrap" justifyContent="space-between">
        <Text type="h3bold2">{titleText}</Text>
        <a href={getGivesSectionUrl()}>
          <Button type="link">View All</Button>
        </a>
      </Flex>
      <Box margin="27px 0 0 0" />
      <S.StyledLoadingCard isLoading={isGivesLoading}>
        {renderGivesContent}
      </S.StyledLoadingCard>
    </S.Container>
  );
}

export default RecentGivesCard;
