import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  border-radius: 8px;
  padding: 8px 16px;
  border: 2px solid ${(props) => props.theme.colors.darkEmphasis1};
  background-color: #00000008;

  width: 100%;
  text-align: center;

  * {
    display: inline-block;
    height: 100%;
    width: fit-content;

    font-family: ${(props) => props.theme.fontFamilies.vollkorn};
    font-size: ${rem('64px')};
    font-weight: 400;
    line-height: 110%; /* 70.4px */
    letter-spacing: -1.28px;

    @media ${(props) => props.theme.screen.md} {
      font-size: ${rem('165px')};
      letter-spacing: -3.3px;
    }
  }

  p {
    margin: 0 !important;
  }

  && input,
  input:focus-visible {
    display: inline;
    z-index: 10;
    position: relative;
    padding: 0;
    border: none;
    outline: none;
    color: black;
    max-width: 100%;
    background-color: transparent;

    margin-bottom: -18px;
  }

  input::placeholder {
    color: ${(props) => props.theme.colors.darkEmphasis3};
  }
`;

export const InputWidthReference = styled.span`
  position: absolute;
  visibility: hidden;
  top: 0;
  left: 0;
`;

export const SuffixText = styled.span`
  display: inline;

  font-size: ${rem('64px')};
  line-height: 110%; /* 70.4px */
  letter-spacing: -1.28px;
  color: ${(props) => props.theme.colors.darkEmphasis1};

  @media ${(props) => props.theme.screen.md} {
    font-size: ${rem('165px')};
    letter-spacing: -3.3px;
  }
`;

export const EndText = styled.span`
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: ${(props) => props.theme.colors.darkEmphasis3};
  font-size: ${rem('64px')};
  line-height: 110%; /* 70.4px */
  letter-spacing: -1.28px;

  @media ${(props) => props.theme.screen.md} {
    font-size: ${rem('165px')};
    letter-spacing: -3.3px;
  }
`;
