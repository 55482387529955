import { CheckOutlined } from '@ant-design/icons';
import React from 'react';

import styled from 'styled-components';

import { Box, Button, Flex, Text } from 'src/client/components';
import { Indexable } from 'src/commons/types';

type Props = {
  cancelButtonProps?: React.ComponentProps<typeof Button> & Indexable;
  onCancel: () => void;
  onSave?: () => void;
  saveButtonProps?: React.ComponentProps<typeof Button> & Indexable;
  title: string;
  className?: string;
};

function SaveCancelButtons(props: Props) {
  const {
    cancelButtonProps,
    className,
    onCancel,
    onSave,
    saveButtonProps,
    title,
  } = props;

  return (
    <ActionButtonsContainer className={className}>
      <Text type="h5med2">{title}</Text>
      <Box margin="8px 0 0 0" />
      <Flex>
        <Box margin="0 16px 0 0">
          <Button {...saveButtonProps} type="elevated1" onClick={onSave}>
            <CheckOutlined />
            Save
          </Button>
        </Box>
        <Button {...cancelButtonProps} type="elevated3" onClick={onCancel}>
          Cancel
        </Button>
      </Flex>
    </ActionButtonsContainer>
  );
}

export const ActionButtonsContainer = styled.div`
  padding: 8px;
  border: 1px solid ${(props) => props.theme.colors.darkEmphasis4};
  border-radius: 8px;
  background: white;
  z-index: 99;
`;

export default SaveCancelButtons;
