import styled from 'styled-components';

import { FlexCenter } from 'src/client/components/StyledCommon';

export const Container = styled.div`
  display: flex;
  padding: 30px;
  height: 467px;
  flex-direction: column;
  background: ${(props) => props.theme.colors.darkSurface2};
  border-radius: 8px;
`;

export const RecurringGiveRowContainer = styled.div`
  padding: 13px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
`;

export const RecurringGivesRowContainer = styled.div`
  margin-top: 24px;
  padding-right: 8px;
  overflow: auto;
`;

export const EmptyContentContainer = styled(FlexCenter)`
  margin-top: 24px;
  padding: 24px;
  height: 100%;
  background: ${(props) => props.theme.colors.darkSurface3};
  border-radius: 8px;

  & > div {
    text-align: center;
  }
`;
