import { Col, Form, Row, Upload, message } from 'antd';
import React, { useContext, useRef } from 'react';
import { useIsMutating } from 'react-query';

import {
  Box,
  DonorAlternateEmailEditField,
  DonorPrimaryEmailEditField,
  EditProfileBottom,
  Input,
  Text,
} from 'src/client/components';
import { AccountContext } from 'src/client/contexts/AccountContext';
import {
  useCreateAlternateEmailsMutation,
  useDeleteAlternateEmailsMutation,
  useUpdateDonorMutation,
  useUploadFileMutation,
} from 'src/client/hooks/mutations';
import { useGetAlternateEmails } from 'src/client/hooks/queries';
import { getTouchedFieldsValue } from 'src/client/utils/FormUtils';
import { FILE_PATH_UPLOAD } from 'src/commons/constants/filePathUpload';
import { Donor } from 'src/commons/types';

import { LoadingPage } from '../..';
import MergeAccounts from '../components/MergeAccounts';
import PasswordEditField from '../components/PasswordEditField';

import * as S from './styles';

const { useForm } = Form;

function Basics() {
  const donor = useContext(AccountContext) as Donor;
  const [form] = useForm();
  const profilePhotoRef = useRef<HTMLDivElement>(null!);
  let profilePhotoTemp = '';

  const { data: alternateEmailsDb, isLoading: isAlternateEmailsDBLoading } =
    useGetAlternateEmails(donor.id);
  const { mutateAsync: createAlternateEmails } =
    useCreateAlternateEmailsMutation();
  const { mutateAsync: deleteAlternateEmails } =
    useDeleteAlternateEmailsMutation();
  const mutationCount = useIsMutating();

  const { mutateAsync: updateDonor } = useUpdateDonorMutation();
  const { mutateAsync: uploadFile } = useUploadFileMutation();

  async function handleSave() {
    const touchedFieldsValue = getTouchedFieldsValue(form);
    const updatedDonorData: Partial<Donor> = {};

    const updatePromises: Promise<any>[] = [];

    if (touchedFieldsValue.name) {
      updatedDonorData.name = touchedFieldsValue.name;
    }

    if (touchedFieldsValue.alternateEmailsToCreate?.length > 0) {
      await createAlternateEmails({
        donorId: donor.id,
        newAlternateEmails: touchedFieldsValue.alternateEmailsToCreate,
      });
    }

    if (touchedFieldsValue.deletedAlternateEmailIds?.length > 0) {
      await deleteAlternateEmails({
        donorId: donor.id,
        deletedAlternateEmailIds: touchedFieldsValue.deletedAlternateEmailIds,
      });
    }

    if (touchedFieldsValue.profilePhoto) {
      const profilePhotoFile = touchedFieldsValue.profilePhoto.file;
      const uploadFilePromise = uploadFile({
        file: profilePhotoFile,
        model: FILE_PATH_UPLOAD.Donor.MODEL,
        modelId: donor.id,
        mountedAs: FILE_PATH_UPLOAD.Donor.MOUNTED_AS,
      });

      updatedDonorData.avatar = profilePhotoFile.name;
      updatedDonorData.isAvatarLegacy = false;
      updatePromises.push(uploadFilePromise);
    }

    const updateDonorPromise = updateDonor({
      id: donor.id,
      ...updatedDonorData,
    });

    updatePromises.push(updateDonorPromise);

    await Promise.all(updatePromises);

    window.location.reload();
  }

  function handleBeforeUpload(file: File) {
    const fileURL = URL.createObjectURL(file);
    profilePhotoTemp = profilePhotoRef.current.style.backgroundImage;
    profilePhotoRef.current.style.backgroundImage = `url(${fileURL})`;

    return false;
  }

  function resetForm() {
    form.resetFields();
    profilePhotoRef.current.style.backgroundImage = profilePhotoTemp;
    message.info('Changes Discarded!');
  }

  if (isAlternateEmailsDBLoading) {
    return <LoadingPage />;
  }

  return (
    <S.Container>
      <Row>
        <Col xl={12}>
          <S.StyledForm
            form={form}
            initialValues={{
              primaryEmail: donor.email,
              email: donor.email,
              name: donor.name,
            }}
            layout="vertical"
            validateMessages={{
              types: {
                email: 'Not a valid email!',
              },
            }}
          >
            <Row>
              <Col sm={{ span: 4, order: 1 }} xs={{ span: 24 }}>
                <S.ProfilePhoto
                  profilePhotoSrc={donor.profilePhoto}
                  ref={profilePhotoRef}
                />
              </Col>
              <Col sm={{ span: 8, order: 2 }} xs={{ span: 24 }}>
                <S.FlexForProfilePhoto alignItems="center">
                  <Form.Item name="profilePhoto">
                    <Upload
                      accept="image/*"
                      beforeUpload={handleBeforeUpload}
                      showUploadList={false}
                    >
                      <S.StyledButton type="secondary">
                        Upload a photo
                      </S.StyledButton>
                    </Upload>
                  </Form.Item>
                </S.FlexForProfilePhoto>
              </Col>
            </Row>
            <Box margin="44px 0 0 0" />
            <Form.Item label="Name" name="name">
              <Input placeholder="Your name" size="large" />
            </Form.Item>
            <Box margin="72px 0 32px 0">
              <Text type="h4bold2">Email Addresses</Text>
            </Box>
            <DonorPrimaryEmailEditField
              alternateEmailsFromDb={alternateEmailsDb}
              description="You will use this to log in to your Giving Side account."
              donor={donor}
              formInstance={form}
              modalDescription="You will use this email to log in to your Giving Side account."
            />
            <Box margin="52px 0 0 0" />
            <DonorAlternateEmailEditField
              alternateEmailsFromDb={alternateEmailsDb}
              donor={donor}
              formInstance={form}
            />
            <MergeAccounts />
          </S.StyledForm>
          <PasswordEditField />
          <Form form={form}>
            <Form.Item noStyle shouldUpdate>
              {() => (
                <EditProfileBottom
                  saveBtnProps={{
                    disabled: !form.isFieldsTouched(),
                    loading: !!mutationCount,
                  }}
                  onDiscard={resetForm}
                  onSave={handleSave}
                />
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </S.Container>
  );
}

export default Basics;
