import { Give } from '../types';

export function calculateTotalGiveAmount(gives: Give[] | undefined) {
  return (
    gives?.reduce((total, give) => {
      if (typeof give.amount === 'number') {
        return give.amount + total;
      }

      return total;
    }, 0) || 0
  );
}

export function calculateTotalMatchedDonationAmount(gives: Give[] | undefined) {
  return (
    gives?.reduce((total, give) => {
      if (give.matchedDonationAmount != null) {
        return give.matchedDonationAmount + total;
      }

      return total;
    }, 0) || 0
  );
}
