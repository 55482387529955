import { Row, Tabs } from 'antd';
import styled from 'styled-components';

import Feedback from 'src/client/components/FeedbackCard';
import {
  Flex,
  minimalisticScrollBar,
} from 'src/client/components/StyledCommon';
import { BODY_1_REG_2 } from 'src/client/constants/text';

export const Container = styled.div`
  background: ${(props) => props.theme.colors.darkSurface1};
  color: ${(props) => props.theme.colors.white};

  ${minimalisticScrollBar};

  .tabs-popup {
    height: 192px;
    border-radius: 8px;
    background: ${(props) => props.theme.colors.darkSurface3};
  }
`;

export const HeaderContainer = styled(Flex)`
  margin: 0 0 20px 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .year-filter {
    @media (max-width: ${(props) => props.theme.size.sm}) {
      display: none;
    }
  }

  @media ${(props) => props.theme.screen.md} {
    margin: 0 0 66px 0;
  }
`;

export const Content = styled(Row)`
  margin: auto;
  padding: 20px 0 0 0;
  width: 100%;
  max-width: 1440px;

  @media ${(props) => props.theme.screen.md} {
    padding: 67px 0 0 0;
  }
`;

export const StyledFeedback = styled(Feedback)`
  margin-bottom: 36px;

  .ant-card-body {
    width: 100%;
  }

  @media ${(props) => props.theme.device.tablet} {
    padding: 49px;
  }
`;

export const RadioButtonContainer = styled.div`
  position: sticky;
  margin-top: 20px;
  top: 200px;
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.darkSurface1};
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav{
    margin: 0 0 0 0;
    &:before{
      border-bottom: 1px solid rgba(255,255,255,0.12);
    }
  }
  .ant-tabs-nav-operations {
    display: none !important; 
  }
  .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.lightEmphasis2};
    ${BODY_1_REG_2}
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.cyan400};
}
  .ant-tabs-ink-bar {
    background: ${(props) => props.theme.colors.cyan400};
  }
`;

