import { LeftOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Box, PlatformDetailsForm, Text, Title } from 'src/client/components';
import {
  useCreatePlatformMutation,
  useUploadPlatformImageMutation,
} from 'src/client/hooks/mutations';
import AdminLayout from 'src/client/layouts/AdminLayout';
import routes from 'src/commons/constants/routes';
import { Platform } from 'src/commons/types';
import { removeUndefinedEntries } from 'src/commons/utils/ObjectUtil';

import * as S from './styles';

const { useForm } = Form;

function AdminAddPlatform() {
  const history = useHistory();
  const [platformDetailsForm] = useForm();

  const { mutateAsync: createPlatform } = useCreatePlatformMutation();
  const { mutateAsync: uploadPlatformImage } = useUploadPlatformImageMutation();

  async function handleAddPlatform() {
    const formValues = platformDetailsForm.getFieldsValue();

    const platformToCreate = {
      name: formValues.name,
      website: formValues.website,
      platformTypes: formValues.platformType
        ? [formValues.platformType]
        : undefined,
      platformCompanyTypes: formValues.platformCompanyType
        ? [formValues.platformCompanyType]
        : undefined,
      platformStatusTypes: formValues.platformStatusType
        ? [formValues.platformStatusType]
        : undefined,
    };

    const platform = await createPlatform(
      removeUndefinedEntries(platformToCreate) as Platform
    );

    const uploadedPlatformImage = formValues.imageUpload?.[0]?.originFileObj;

    if (uploadedPlatformImage) {
      await uploadPlatformImage({
        platformId: platform.data.id,
        file: uploadedPlatformImage,
      });
    }

    window.location.href = routes.ADMIN_PLATFORMS;
  }

  function handleCancel() {
    history.push(routes.ADMIN_PLATFORMS);
  }

  return (
    <>
      <Title title="Add Platform - Admin" />
      <AdminLayout>
        <Box margin="134px 211px">
          <Link to={routes.ADMIN_PLATFORMS}>
            <S.StyledButton type="variant1">
              <LeftOutlined />
              Back
            </S.StyledButton>
          </Link>
          <Box margin="22px 0 35px 0">
            <Text type="displayMMed2">Add Platform</Text>
          </Box>
          <Box margin="0 0 26px 0">
            <Text type="h2med2">Platform details</Text>
          </Box>
          <PlatformDetailsForm
            form={platformDetailsForm}
            onCancel={handleCancel}
            onFinish={handleAddPlatform}
          />
        </Box>
      </AdminLayout>
    </>
  );
}

export default AdminAddPlatform;
