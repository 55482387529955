import { createGlobalStyle } from 'styled-components';

const LegacyGlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;
  }
  
  a {
    color: #0ABCC7;
  }
`;

export default LegacyGlobalStyle;
