import { Grid } from 'antd';
import React from 'react';

import { Box } from 'src/client/components';
import ScaledCenterCarousel from 'src/client/components/ScaledCenterCarousel';
import { HIGHTLIGHTED_PAGE_RECIPIENT_LIMIT } from 'src/client/constants/pageRecipients';
import { useGetDonorGalleryPage } from 'src/client/hooks/queries';
import { useGetPageRecipients } from 'src/client/hooks/queries/PageRecipientQueries';
import { ScaledSlide } from 'src/client/types/Slide';
import { Donor, PageRecipientVisibility } from 'src/commons/types';

import { filterByVisibility } from 'src/commons/utils/PageRecipientsUtils';

const { useBreakpoint } = Grid;

const featuredGivePlaceholder = '/recipientBg.png';

type Props = {
  donor: Donor;
};

function FeaturedGivesCarousel(props: Props) {
  const { donor } = props;

  const { data: page } = useGetDonorGalleryPage(donor?.id || '');

  const { data: pageRecipients, isLoading: isGetPageRecipientsLoading } =
    useGetPageRecipients(
      {
        pageId: page?.id as string,
      },
      {
        enabled: !!page?.id,
      }
    );

  const screen = useBreakpoint();

  const highlightedRecipients = pageRecipients
    ?.filter(filterByVisibility(PageRecipientVisibility.FEATURED))
    .sort(
      (pageRecipientA, pageRecipientB) =>
        (pageRecipientA.order as number) - (pageRecipientB.order as number)
    );

  function getSlides() {
    if (!highlightedRecipients) {
      return [];
    }

    const slides: ScaledSlide[] = highlightedRecipients.map(
      (highlightedRecipient) => ({
        backgroundUrl:
          highlightedRecipient.imagePath || featuredGivePlaceholder,
        title: highlightedRecipient.recipientName || '',
        slideText: highlightedRecipient.imagePath
          ? undefined
          : highlightedRecipient.recipientName,
      })
    );

    if (slides.length < HIGHTLIGHTED_PAGE_RECIPIENT_LIMIT) {
      const missingPageRecipientCount =
        HIGHTLIGHTED_PAGE_RECIPIENT_LIMIT - slides.length;

      [...Array(missingPageRecipientCount).keys()].forEach(() => {
        slides.push({
          backgroundUrl: featuredGivePlaceholder,
          title: '',
        });
      });
    }

    return [...slides, ...slides];
  }

  if (screen.lg) {
    return (
      <Box margin="0 50px">
        <ScaledCenterCarousel
          isLoading={isGetPageRecipientsLoading}
          slides={getSlides()}
        />
      </Box>
    );
  }

  return (
    <ScaledCenterCarousel
      isLoading={isGetPageRecipientsLoading}
      slides={getSlides()}
    />
  );
}

export default FeaturedGivesCarousel;
