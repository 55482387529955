import {
  isSectionEditing,
  isSectionHasChanges,
  isSectionSaveButtonDisabled,
} from '../pages/DonorGallery/utils';
import { SectionStatus } from '../types/Gallery';

export function useSectionStatus(sectionStatus: SectionStatus) {
  const isEditing = isSectionEditing(sectionStatus);
  const isSaveButtonDisabled = isSectionSaveButtonDisabled(sectionStatus);

  return {
    isEditing,
    isSaveButtonDisabled,
    isSectionHasChanges: isSectionHasChanges(sectionStatus),
  };
}
