import { LeftOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import qs from 'qs';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  Box,
  Button,
  Text,
  RecipientDetailsForm,
  Title,
} from 'src/client/components';
import {
  useCreateRecipientMutation,
  useSaveScrapedRecipientWebsiteImageMutation,
  useScrapeRecipientWebsite,
  useUpdateRecipientMutation,
  useUploadRecipientImageMutation,
} from 'src/client/hooks/mutations';
import useGetQueryString from 'src/client/hooks/useGetQueryString';
import AdminLayout from 'src/client/layouts/AdminLayout';
import routes from 'src/commons/constants/routes';
import { asyncForEach } from 'src/commons/utils/ArrayUtils';
import { replaceRouteParams } from 'src/commons/utils/RouteUtils';

import * as S from './styles';

const { useForm } = Form;

function AdminAddRecipient(props: RouteComponentProps) {
  const { history } = props;

  const queryString = useGetQueryString();
  const [recipientDetailsForm] = useForm();

  const { mutateAsync: createRecipient, isLoading: isCreatingRecipient } =
    useCreateRecipientMutation({
      onSuccess: () => false,
    });
  const { mutateAsync: updateRecipient, isLoading: isUpdatingRecipient } =
    useUpdateRecipientMutation({
      onSuccess: () => false,
    });
  const {
    mutateAsync: uploadRecipientImage,
    isLoading: isUploadingRecipientLogo,
  } = useUploadRecipientImageMutation({
    onSuccess: () => false,
  });
  const {
    mutateAsync: saveScrapedRecipientWebsiteImage,
    isLoading: isSavingScrapedRecipientWebsiteImage,
  } = useSaveScrapedRecipientWebsiteImageMutation({
    onSuccess: () => false,
  });
  const { mutateAsync: scrapeRecipientWebsite, isLoading: isScraping } =
    useScrapeRecipientWebsite();

  const isSubmitting =
    isCreatingRecipient ||
    isUploadingRecipientLogo ||
    isSavingScrapedRecipientWebsiteImage ||
    isUpdatingRecipient ||
    isScraping;

  function handleBack() {
    history.push(routes.ADMIN_RECIPIENTS);
  }

  function handleCancel() {
    history.push(routes.ADMIN_RECIPIENTS);
  }

  async function handleAddRecipient() {
    const formValues = recipientDetailsForm.getFieldsValue();

    const recipient = await createRecipient({
      name: formValues.name,
      recipientTypes: [formValues.recipientTypes],
      website: formValues.website,
      taxid: formValues.taxid,
      parentRecipientId: formValues.sponsor,
    });

    const uploadedRecipientImage = formValues.logoImage?.[0]?.originFileObj;

    if (uploadedRecipientImage) {
      await uploadRecipientImage({
        recipientId: recipient.data.id,
        file: uploadedRecipientImage,
        isPrimaryLogo: true,
      });
    }

    if (recipient.data.website) {
      try {
        await scrapeRecipient(recipient.data.id, recipient.data.website);
      } catch (error) {
        console.error(error);
      }
    }

    history.push({
      pathname: replaceRouteParams(routes.ADMIN_EDIT_RECIPIENT, {
        recipientId: recipient.data.id,
      }),
      search: queryString.giveId
        ? qs.stringify({ giveId: queryString.giveId })
        : undefined,
    });
  }

  async function scrapeRecipient(recipientId: string, website: string) {
    const {
      title,
      description,
      images: newScrapedImageUrls = [],
    } = await scrapeRecipientWebsite(website);

    await asyncForEach(newScrapedImageUrls, async (scrapedRecipientUrl) => {
      await saveScrapedRecipientWebsiteImage({
        recipientId,
        imageUrl: scrapedRecipientUrl,
        isPrimaryLogo: false,
      });
    });

    await updateRecipient({
      id: recipientId,
      ogDescription: description?.trim() || null,
      ogTitle: title?.trim() || null,
    });
  }

  return (
    <>
      <Title title="Add Recipient - Admin" />
      <AdminLayout>
        <S.Container>
          <Button type="variant1" onClick={handleBack}>
            <LeftOutlined />
            Back
          </Button>
          <Box margin="22px 0 19px 0">
            <Text type="h4">Add Recipient</Text>
          </Box>
          <Box margin="22px 0 19px 0">
            <Text type="body">Recipient Details</Text>
          </Box>
          <RecipientDetailsForm
            form={recipientDetailsForm}
            isEditingRecipient={false}
            isSubmitting={isSubmitting}
            onCancel={handleCancel}
            onFinish={handleAddRecipient}
          />
        </S.Container>
      </AdminLayout>
    </>
  );
}

export default AdminAddRecipient;
