import { Col, Form, FormInstance, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

import { DatePicker, Select, Text } from 'src/client/components';
import { MONTH_DAY_YEAR } from 'src/commons/constants/dateFormat';
import { GIVE_FREQUENCY } from 'src/commons/types';
import { capitalizeFirstLetter } from 'src/commons/utils/StringUtils';

import { GiveNumberInput, GroupInputTitleText } from './../styles';

const { Option } = Select;

const gutterBig = 24;
const gutterSmall = 8;
const giveFrequencies = [
  GIVE_FREQUENCY.ONE_TIME,
  GIVE_FREQUENCY.MONTHLY,
  GIVE_FREQUENCY.WEEKLY,
  GIVE_FREQUENCY.ANNUALLY,
  GIVE_FREQUENCY.BI_MONTHLY,
  GIVE_FREQUENCY.BI_WEEKLY,
  GIVE_FREQUENCY.QUARTERLY,
];

function DurationDetailsInput() {
  function renderPledgeAndGiveNumberInput(formInstance: FormInstance<any>) {
    const { getFieldValue } = formInstance;
    const frequency = getFieldValue('frequency');

    if (isEmpty(frequency) || frequency === GIVE_FREQUENCY.ONE_TIME) {
      return null;
    }

    return (
      <>
        <Row gutter={[gutterBig, 0]}>
          <Col span={12}>
            <Form.Item label="Pledge Start" name="startDate">
              <DatePicker
                data-cy="pledge-start-date-picker"
                format={MONTH_DAY_YEAR}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Pledge End" name="endDate">
              <DatePicker
                data-cy="pledge-end-date-picker"
                format={MONTH_DAY_YEAR}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Give Number">
          <Row align="middle" gutter={[gutterSmall, 0]}>
            <Col>
              <Form.Item noStyle name="giveCount">
                <GiveNumberInput
                  data-cy="give-count-input"
                  placeholder="0"
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col>
              <Text type="label1reg2">of</Text>
            </Col>
            <Col>
              <Form.Item noStyle name="giveCountTotal">
                <GiveNumberInput
                  data-cy="give-count-total-input"
                  placeholder="0"
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </>
    );
  }

  const giveFrequencyOptions = giveFrequencies.map((giveFrequency) => (
    <Option key={giveFrequency} value={giveFrequency}>
      {capitalizeFirstLetter(giveFrequency)}
    </Option>
  ));

  return (
    <>
      <GroupInputTitleText>Duration</GroupInputTitleText>
      <Form.Item label="Frequency" name="frequency">
        <Select
          allowClear
          showSearch
          data-cy="frequency-select"
          placeholder="Frequency"
          size="large"
        >
          {giveFrequencyOptions}
        </Select>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.frequency !== currentValues.frequency
        }
      >
        {renderPledgeAndGiveNumberInput}
      </Form.Item>
    </>
  );
}

export default DurationDetailsInput;
