import React from 'react';

import { LoadingCard } from 'src/client/components';
import ContentTitle from 'src/client/pages/AdminPlatformDashboard/components/ContentTitle';

import * as S from './styles';

type Props = {
  children?: React.ReactNode;
  isLoading: boolean;
  title: string;
};

function CardsSection(props: Props) {
  const { title, isLoading, children } = props;

  return (
    <S.ContentSection>
      <ContentTitle title={title} />
      <S.AnalyticsContainer>
        <LoadingCard isLoading={isLoading}>{children}</LoadingCard>
      </S.AnalyticsContainer>
    </S.ContentSection>
  );
}

export default CardsSection;
