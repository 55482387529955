import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Flex, Text } from 'src/client/components';
import FeaturedGivesCarousel from 'src/client/components/FeaturedGivesCarousel';
import { AccountContext } from 'src/client/contexts/AccountContext';
import { useGetDonorGalleryPage } from 'src/client/hooks/queries/pageQueries';
import { UserRole } from 'src/commons/constants/roles';
import routes from 'src/commons/constants/routes';
import { AccountContext as AccountContextType, Donor } from 'src/commons/types';
import { replaceRouteParams } from 'src/commons/utils/RouteUtils';

type Props = {
  donor: Donor;
};

function Gallery(props: Props) {
  const { donor } = props;

  const account = useContext(AccountContext) as AccountContextType;

  const { data: page } = useGetDonorGalleryPage(donor.id || '');

  function getPersonalGalleryLink() {
    if (!page) {
      return undefined;
    }

    switch (account.role) {
      case UserRole.ADMIN:
        return replaceRouteParams(routes.ADMIN_DONOR_GALLERY, {
          donorId: donor.id,
          pageId: page.id,
        });
      case UserRole.DONOR:
        return replaceRouteParams(routes.PERSONAL_GALLERY, {
          pageId: page.id,
        });
      default:
        return undefined;
    }
  }

  return (
    <Container>
      <Top>
        <Flex flexDirection="column">
          <Text type="h2bold2">Gallery</Text>
          <Caption type="body1reg2">
            Customize your own Giving Side site, either just for yourself or to
            share with family and friends looking for suggestions and guidance
          </Caption>
        </Flex>
        <Flex flexWrap="wrap">
          <Link to={getPersonalGalleryLink() || ''}>
            <Button type="primary">Preview your gallery</Button>
          </Link>
        </Flex>
      </Top>
      <CarouselContainer>
        <Row>
          <Col span={24}>
            <FeaturedGivesCarousel donor={donor} />
          </Col>
        </Row>
      </CarouselContainer>
    </Container>
  );
}

const Container = styled.div`
  margin: 48px 0;

  @media ${(props) => props.theme.device.laptop} {
    margin: 70px 0 50px;
  }
`;

const CarouselContainer = styled.div`
  margin: -20px 30px 0 30px;

  @media ${(props) => props.theme.device.tablet} {
    margin: 49px 30px 0 30px;
  }
`;

const Caption = styled(Text)`
  margin-top: 8px;
  width: 100%;
  max-width: 666px;
  text-align: justify;
  color: ${(props) => props.theme.colors.lightEmphasis2};
`;

const Top = styled(Flex)`
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;

  @media (max-width: ${(props) => props.theme.size.mobileL}) {
    justify-content: flex-start;
  }
`;

export default Gallery;
