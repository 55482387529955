import { DragEndEvent, SensorDescriptor, SensorOptions } from '@dnd-kit/core';
import React, { forwardRef } from 'react';

import UnfeaturedCardsEditModeBase from 'src/client/pages/DonorGallery/components/UnfeaturedCardsEditMode';
import { PageRecipient } from 'src/commons/types';

import * as stylesheet from './styles';

type Props = {
  unfeaturedPageRecipients: PageRecipient[] | undefined;
  featuredPageRecipients: PageRecipient[] | undefined;
  isLoading: boolean;
  onFeaturePageRecipient: (pageRecipientId: string) => void;
  onHidePageRecipient: (pageRecipientId: string) => void;
  handleDragEnd: (event: DragEndEvent) => void;
  handleDragStart: (event: DragEndEvent) => void;
  sensors: SensorDescriptor<SensorOptions>[];
};

const UnfeaturedCardsEditMode = forwardRef<HTMLDivElement, Props>(
  (props, ref) => (
    <UnfeaturedCardsEditModeBase
      {...props}
      showSpotlightText
      ref={ref}
      styles={stylesheet}
    />
  )
);

export default UnfeaturedCardsEditMode;
