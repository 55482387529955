import { SorterResult } from 'antd/lib/table/interface';
import moment, { Moment } from 'moment';
import qs from 'qs';
import { useHistory } from 'react-router-dom';

import { TableOrder } from 'src/client/types/Table';
import {
  formatTableOrder,
  convertTableOrderToDatastoreOrder,
} from 'src/client/utils/OrderUtils';
import { Indexable } from 'src/commons/types';
import {
  convertMomentDateRangeToDate,
  convertStringDateRangeToMoment,
} from 'src/commons/utils/DateUtils';
import { removeUndefinedEntries } from 'src/commons/utils/ObjectUtil';

import { MultiFilterFormValue } from '../components/MultiFilter';

import useGetQueryString from './useGetQueryString';

type SetQueryStringParams = Partial<MultiFilterFormValue> & {
  sorter?: SorterResult<any> | SorterResult<any>[];
};

export function useManageTableQueryString<ApiFilter>() {
  const history = useHistory();
  const queryString = useGetQueryString() as unknown as QueryString;

  type QueryString = MultiFilterFormValue & {
    tableOrder: TableOrder<ApiFilter>;
  };

  function setTableQueryString(data: SetQueryStringParams) {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...formatDataToBeQueryStringFriendly(data),
        tableOrder: data.sorter ? formatTableOrder(data.sorter) : undefined,
      }),
    });
  }

  function getFilter(multiFilterFormValue: MultiFilterFormValue) {
    const filterName = multiFilterFormValue.filterType;
    const filterValue = multiFilterFormValue[filterName];

    if (filterValue) {
      return { [filterName]: filterValue };
    }

    return {};
  }

  function formatDataToBeQueryStringFriendly(data: SetQueryStringParams) {
    return Object.fromEntries(
      Object.entries(data)
        .map(([key, value]) => {
          const isMomentRange =
            Array.isArray(value) &&
            value.length === 2 &&
            moment.isMoment(value[0]) &&
            moment.isMoment(value[1]);

          if (isMomentRange) {
            return [
              key,
              convertMomentDateRangeToDate(value as [Moment, Moment]),
            ];
          }

          return [key, value];
        })
        .filter(([key, value]) => !!value)
    );
  }

  function getFormattedQueryStringForApiFilter(): ApiFilter {
    let formattedQueryStringForApiFilter: Indexable = getFilter(queryString);

    if (queryString.tableOrder) {
      formattedQueryStringForApiFilter = {
        ...formattedQueryStringForApiFilter,
        order: convertTableOrderToDatastoreOrder(queryString.tableOrder),
      };
    }

    return removeUndefinedEntries(
      formattedQueryStringForApiFilter
    ) as ApiFilter;
  }

  function getFormattedQueryStringForFormValues(): MultiFilterFormValue {
    return Object.fromEntries(
      Object.entries(queryString as Indexable)
        .map(([key, value]) => {
          const isTheValueAStringDateRange =
            Array.isArray(value) &&
            value.length === 2 &&
            typeof value[0] === 'string' &&
            typeof value[1] === 'string';

          if (isTheValueAStringDateRange) {
            return [
              key,
              convertStringDateRangeToMoment(value as [string, string]),
            ];
          }

          return [key, value];
        })
        .filter(([key, value]) => !!value)
    );
  }

  return {
    queryString,
    setTableQueryString,
    formattedQueryStringForApiFilter: getFormattedQueryStringForApiFilter(),
    formattedQueryStringForFormValues: getFormattedQueryStringForFormValues(),
  };
}
