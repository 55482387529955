import qs from 'qs';
import { useLocation } from 'react-router-dom';

function useGetQueryString() {
  const location = useLocation();

  return qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
}

export default useGetQueryString;
