import { Grid } from 'antd';
import React from 'react';

import styled from 'styled-components';

import { FlexCenter } from 'src/client/components';

type RightButtonsContainerProps = {
  children?: React.ReactNode;
};

const { useBreakpoint } = Grid;

function RightButtonsContainer(props: RightButtonsContainerProps) {
  const { children } = props;

  const screens = useBreakpoint();
  const isBigScreen = screens.md;

  if (isBigScreen) {
    return <Container>{children}</Container>;
  } else {
    return <>{children}</>;
  }
}

const Container = styled(FlexCenter)`
  gap: 16px;
`;

export default RightButtonsContainer;
