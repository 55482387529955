import { PaperClipOutlined } from '@ant-design/icons';
import React from 'react';

import { Box, LoadingCard, Text } from 'src/client/components';
import { useGetAttachmentsUrl } from 'src/client/hooks/queries';
import { Give } from 'src/commons/types';

import * as S from './styles';

type Props = {
  give: Give;
};

function Attachments(props: Props) {
  const { give } = props;
  const { data: attachmentsResponse, isLoading: isAttachmentsLoading } =
    useGetAttachmentsUrl(give.attachments ?? []);
  const attachmentsWithUrl = attachmentsResponse?.attachments;

  function getAttachmentUrl(fileName: string) {
    return attachmentsWithUrl?.find(
      (attachmentWithUrl) => attachmentWithUrl.attachmentName === fileName
    )?.url;
  }

  const attachments = give?.attachments?.map((attachment, i) => (
    <S.Attachment href={getAttachmentUrl(attachment)} key={i} target="_blank">
      <PaperClipOutlined />
      {attachment}
    </S.Attachment>
  ));

  return (
    <S.Container>
      <Text type="body1med2">Attachments</Text>
      <Box margin="0 0 17px 0" />
      <LoadingCard isLoading={isAttachmentsLoading}>{attachments}</LoadingCard>
    </S.Container>
  );
}

export default Attachments;
