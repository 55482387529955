import { HeartOutlined } from '@ant-design/icons';
import CalendarSyncOutlineIcon from 'mdi-react/CalendarSyncOutlineIcon';
import HandCoinOutlineIcon from 'mdi-react/HandCoinOutlineIcon';
import React from 'react';

import styled from 'styled-components';

import Text from 'src/client/components/Text';

type Props = {
  children: React.ReactNode;
  icon: 'frequency' | 'hand' | 'heart';
};

export function Tag(props: Props) {
  const { children, icon } = props;

  let renderIcon;

  switch (icon) {
    case 'heart':
      renderIcon = <HeartIcon data-testid="icon-heart" size={14} />;
      break;
    case 'frequency':
      renderIcon = <FrequencyIcon data-testid="icon-frequency" size={14} />;
      break;
    default:
      renderIcon = <FeeIcon data-testid="icon-hand" size={14} />;
  }

  return (
    <TagContainer>
      {renderIcon}
      <Text type="label2med2">{children}</Text>
    </TagContainer>
  );
}

const TagContainer = styled.span`
  display: flex;
  margin-top: 10px;
  padding: 5px 9px;
  align-items: center;
  border-radius: 100px;
  background-color: ${(props) => props.theme.colors.lightSurface20};

  P {
    margin-left: 4px;
    text-transform: capitalize;
    white-space: nowrap;
  }
`;

const FeeIcon = styled(HandCoinOutlineIcon)`
  color: #ffbb0b;
`;

const FrequencyIcon = styled(CalendarSyncOutlineIcon)`
  color: ${(props) => props.theme.colors.success};
`;

const HeartIcon = styled(HeartOutlined)`
  color: ${(props) => props.theme.colors.magenta300};
`;

export default Tag;
