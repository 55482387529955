import { rem } from 'polished';
import styled from 'styled-components';

import { Flex, Text } from 'src/client/components';

export const FirstSection = styled.section`
  background-color: ${(props) => props.theme.colors.darkHighEmphasis};
  padding: 100px 40px;

  @media (${(props) => props.theme.screen.md}) {
    padding: 100px 80px;
  }

  @media (${(props) => props.theme.screen.lg}) {
    padding: 100px 106px;
  }
`;

export const FirstSectionContainer = styled(Flex)`
  flex-direction: column;

  @media ${(props) => props.theme.screen.md} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const FirstTitleContainer = styled.div`
  margin-bottom: 48px;

  @media ${(props) => props.theme.screen.md} {
    margin-bottom: 0;
  }
  @media ${(props) => props.theme.screen.lg} {
    width: 493px;
  }
`;

export const FirstTitleText = styled(Text)`
  font-size: ${rem('32px')};
  line-height: ${rem('42px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};

  @media ${(props) => props.theme.screen.md} {
    font-size: ${rem('40px')};
    line-height: ${rem('52px')};
  }
`;

export const SecondSection = styled.section`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.white};

  padding: 100px 40px;

  @media (${(props) => props.theme.screen.md}) {
    padding: 100px 80px;
  }

  @media (${(props) => props.theme.screen.lg}) {
    padding: 100px 106px;
  }
`;

export const SecondTitleContainer = styled.div`
  position: relative;
  margin-bottom: 80px;
`;

export const Ornament = styled.img`
  height: 103px;
  width: 103px;
`;

export const SecondTitle = styled(Text)`
  position: absolute;
  top: 36px;
  left: 48px;

  font-size: ${rem('32px')};
  line-height: ${rem('42px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};

  @media ${(props) => props.theme.screen.md} {
    width: 566px;
    font-size: ${rem('40px')};
    line-height: ${rem('52px')};
  }
`;
