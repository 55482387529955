import styled from 'styled-components';

import DragAndDropArea from '../DragAndDropArea';

export const StyledDragAndDropArea = styled(DragAndDropArea)`
  .drag-and-drop-area {
    border-color: ${(props) => props.theme.colors.darkEmphasis2};
    background: transparent;
  }

  .instruction-tab {
    background: ${(props) => props.theme.colors.darkEmphasis2};
  }
`;
