import React from 'react';

import { Box, Text, Title } from 'src/client/components';
import AdminLayout from 'src/client/layouts/AdminLayout';

import DonorsTable from './components/DonorsTable';

import * as S from './styles';

function AdminDonors() {
  return (
    <>
      <Title title="Donors - Admin" />
      <AdminLayout>
        <S.Container>
          <Box margin="0 0 24px 0">
            <Text type="displayMMed2">Users</Text>
          </Box>
          <DonorsTable />
        </S.Container>
      </AdminLayout>
    </>
  );
}

export default AdminDonors;
