import styled from 'styled-components';

import { Flex, Input } from 'src/client/components';

export const Container = styled.div`
  padding: 60px 0;
  width: 100%;
  color: white;
  background: ${(props) => props.theme.colors.darkSurface1};
  text-align: center;
`;

export const EmailInput = styled(Input)`
  && {
    height: 50px;
    max-width: 300px;
  }
`;

export const EmailInputContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;
