import { useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';

import { analytics } from '../libs/segment';

export function usePageTrack() {
  const location = useLocation();
  const previousLocationPathname = useRef<string | null>(null);

  useEffect(() => {
    const hasUserNavigated =
      previousLocationPathname.current &&
      previousLocationPathname.current !== location.pathname;

    if (hasUserNavigated) {
      analytics.page({
        path: location.pathname,
      });
    }

    previousLocationPathname.current = location.pathname;
  }, [location.pathname]);
}
