import React from 'react';

import { RecipientStat } from 'src/commons/types';

import { WidthLimit } from '../../styles';

import LineGraph from '../LineGraph';
import StatisticCardWithIcon from '../StatisticCardWithIcon';

import * as S from './styles';

type Props = {
  currentRecipientStat?: RecipientStat;
};

function GivingAndGoalsTab(props: Props) {
  const { currentRecipientStat } = props;

  const lineGraphData = [
    {
      graphData: currentRecipientStat?.graph.donorCount,
      title: 'Individials',
      yFieldName: 'Donor count',
    },
    {
      graphData: currentRecipientStat?.graph.giveCount,
      title: 'No. of gives',
      yFieldName: 'Give Count',
    },
    {
      graphData: currentRecipientStat?.graph.totalGiveAmount,
      title: 'Amount given',
      yFieldName: 'Total Give Amount',
      isAmount: true,
    },
    {
      graphData: currentRecipientStat?.graph.goalCount,
      title: 'No. of giving goals',
      yFieldName: 'Goal Count',
    },
    {
      graphData: currentRecipientStat?.graph.totalGoalAmount,
      title: 'Giving Goal Amount',
      yFieldName: 'Total Goal Amount',
      isAmount: true,
    },
    {
      title: 'Total gives by supporters',
      yFieldName: 'Give Count',
    },
  ];

  const lineGraphs = lineGraphData.map((data) => (
    <LineGraph
      graphData={data.graphData}
      isAmount={data.isAmount}
      key={data.title}
      title={data.title}
      yFieldName={data.yFieldName}
    />
  ));

  return (
    <>
      <S.FirstSection>
        <S.FirstTitle type="displaySMed2">
          Our People are Givers with goals and budgets. They are crafting a
          practice of engagement.
        </S.FirstTitle>
        <S.StatisticCardsContainer>
          <StatisticCardWithIcon
            icon="/maleAndTwoFemale.png"
            statisticValue={`${currentRecipientStat?.goalsCount.toLocaleString()}`}
            title="Supporters with Goal"
          />
          <StatisticCardWithIcon
            icon="/targetGoal.png"
            statisticValue={`$${Math.ceil(
              currentRecipientStat?.goalsAmountAvg ?? 0
            ).toLocaleString()}`}
            title="Average Goal"
          />
          <StatisticCardWithIcon
            icon="/heartBeholdIcon.png"
            statisticValue={`$${Math.ceil(
              currentRecipientStat?.goalsAmountMed ?? 0
            ).toLocaleString()}`}
            title="Median Goal"
          />
          <StatisticCardWithIcon
            icon="/heartPinkOutlineIcon.png"
            statisticValue={`${currentRecipientStat?.recurringGivesCount.toLocaleString()}`}
            title="Total Recurring Gives"
          />
          <StatisticCardWithIcon
            icon="/heartVioletOutlineIcon.png"
            statisticValue={`$${Math.ceil(
              currentRecipientStat?.recurringGivesAmountAvg ?? 0
            ).toLocaleString()}`}
            title="Avg Recurring Give Amt"
          />
          <StatisticCardWithIcon
            icon="/heartTealOutlineIcon.png"
            statisticValue={`$${Math.ceil(
              currentRecipientStat?.recurringGivesAmountMed ?? 0
            ).toLocaleString()}`}
            title="Median Recurring Give Amt"
          />
        </S.StatisticCardsContainer>
      </S.FirstSection>
      <S.SecondSection>
        <WidthLimit isRelative>
          <S.Ornament src="/ornament.svg" />
          <S.SectionContainer>
            <S.OrnamentTitleContainer>
              <S.SectionTitle type="displaySMed2">
                Our People on Giving Side are Growing
              </S.SectionTitle>
            </S.OrnamentTitleContainer>
            <S.LineGraphsContainer>{lineGraphs}</S.LineGraphsContainer>
          </S.SectionContainer>
        </WidthLimit>
      </S.SecondSection>
    </>
  );
}

export default GivingAndGoalsTab;
