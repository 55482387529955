import styled from 'styled-components';

import { DivImg, FlexCenter, Text } from 'src/client/components';

export const RecipientLogoReplacementContainer = styled(FlexCenter)`
  padding: 12px;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.darkSurface1};
`;

export const RecipientLogoReplacement = styled(Text)`
  color: white;
  text-align: center;
`;

export const RecipientLogo = styled(DivImg)`
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${(props) => props.theme.colors.darkSurface1};
`;
