import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { Form, FormInstance, Input, UploadFile } from 'antd';
import React from 'react';

import { Box, Button, Flex, PictureCardUpload, Text } from 'src/client/components';

import useThemeContext from 'src/client/hooks/useTheme';

import { handleGetValueFromUploadEvent } from 'src/client/utils/FormUtils';

import LoadingAnimation from '../../LoadingAnimation';
import { StyledUpload } from '../../PictureCardUpload';
import * as S from '../styles';

type Props = {
  form: FormInstance<any>;
};

function LogoSelection(props: Props) {
  const { form } = props;

  const scrapedImages = form?.getFieldValue('scrapedImages');
  const logoImageId = form?.getFieldValue('logoImageId');
  const website = form?.getFieldValue('website');
  const hasWebsiteImages = scrapedImages?.length > 0;

  const uploadInputRef = React.useRef<HTMLElement>(null);

  const { colors } = useThemeContext();

  async function handleSelectLogo(file: UploadFile<any>) {
    form?.setFieldsValue({ logoImageId: file.uid });
  }

  async function handleRemove(file: UploadFile) {
    const isFileInDb = file.status === 'done';

    if (isFileInDb) {
      const imagesToDelete = form?.getFieldValue('imagesToDelete') || [];

      form?.setFieldsValue({ imagesToDelete: [...imagesToDelete, file] });
    }

    if (file.uid === logoImageId) {
      form?.setFieldsValue({ logoImageId: null });
    }
  }

  // eslint-disable-next-line max-params
  function handleItemRender(originNode: React.ReactElement, file: UploadFile, fileList: any, actions: any) {
    return (
      <Flex flexDirection='column' style={{ position: 'relative' }} onClick={() => handleSelectLogo(file)}>
        <S.ImageContainer
          data-cy="image-item"
          id={file.uid}
          isSelected={file.uid === logoImageId}
          key={file.uid}
        >
          {file.status === 'uploading' ? <LoadingAnimation /> : originNode}

        </S.ImageContainer>
        <S.StyledFlex isSelected={file.uid === logoImageId}>{file.uid === logoImageId ? <CheckOutlined /> : <S.UncheckedCircle />}{file.uid === logoImageId ? 'Selected' : 'Select'}</S.StyledFlex>
        <S.DeleteButton
          data-cy="delete-btn"
          // eslint-disable-next-line react/jsx-handler-names
          onClick={actions.remove}
        >
          <DeleteOutlined />
        </S.DeleteButton>
      </Flex >
    );
  }

  const triggerFileUpload = () => {
    if (!uploadInputRef.current) {
      return
    }

    uploadInputRef.current.click();
  }

  const renderImageScrapedFromWebsite = hasWebsiteImages && (
    <Box margin="0 0 27px 0">
      <Text color={colors.darkEmphasis2} type="body2reg2">
        Images scraped from {website}
      </Text>
    </Box>
  );

  const renderWebsiteImages = hasWebsiteImages ? (
    <StyledUpload
      beforeUpload={() => false}
      itemRender={handleItemRender}
      listType="picture-card"
      showUploadList={{
        showPreviewIcon: false,
        showRemoveIcon: false,
      }}
      onRemove={handleRemove}
    ></StyledUpload>
  ) : (
    <S.WebsiteImagesPlaceholderContainer>
      <Text color={colors.darkEmphasis2} type="body1reg2">
        Scrape URL to retrieve images
      </Text>
    </S.WebsiteImagesPlaceholderContainer>
  );

  return (
    <S.ImagesSectionContainer>
      <Flex flexDirection='row' gap='24px' margin="0px 0px 38px 0px">
        <Text color={colors.darkHighEmphasis} type="h3med2">
          Select logo
        </Text>
        <Button
          data-cy="scrape-btn"
          type="variant1"
          onClick={triggerFileUpload}
        >
          + Upload photo
        </Button>
      </Flex>
      <Text color={colors.darkEmphasis1} type="h4med2">
        Website images
      </Text>
      {renderImageScrapedFromWebsite}
      <Form.Item
        data-cy="scraped-images-list"
        getValueFromEvent={handleGetValueFromUploadEvent}
        name="scrapedImages"
        valuePropName="fileList"
      >
        {renderWebsiteImages}
      </Form.Item>

      <Box margin="0 0 24px 0">
        <Text color={colors.darkEmphasis1} type="h4med2">
          Uploaded Photos
        </Text>
        <Text color={colors.darkEmphasis2} type="body2reg2">
          Add logo or images
        </Text>
      </Box>

      <Form.Item
        data-cy="uploaded-images-list"
        getValueFromEvent={handleGetValueFromUploadEvent}
        name="uploadedImages"
        valuePropName="fileList"
      >
        <PictureCardUpload
          multiple
          beforeUpload={() => false}
          itemRender={handleItemRender}
          showUploadList={{
            showPreviewIcon: false,
            showRemoveIcon: false,
          }}
          uploadRef={uploadInputRef}
          onRemove={handleRemove}
        />
      </Form.Item>
      <Form.Item hidden name="logoImageId">
        <Input hidden />
      </Form.Item>
      <Form.Item hidden name="imagesToDelete">
        <Input hidden />
      </Form.Item>
    </S.ImagesSectionContainer>
  );
}

export default LogoSelection;
