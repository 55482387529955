import { useMutation } from 'react-query';

import {
  createAlternateEmails,
  deleteAlternateEmails,
  sendAlternateEmailVerification,
  switchPrimaryEmail,
  verifyAlternateEmail,
} from 'src/client/api/AlternateEmailApi';
import { ApiResponse, Donor } from 'src/commons/types';

type Options<Data> = {
  onSuccess: (data: Data) => void;
};

export function useCreateAlternateEmailsMutation() {
  return useMutation(createAlternateEmails);
}

export function useDeleteAlternateEmailsMutation() {
  return useMutation(deleteAlternateEmails);
}

export function useSwitchPrimaryEmailMutation(
  opts?: Options<ApiResponse<Donor>>
) {
  return useMutation(switchPrimaryEmail, opts);
}

export function useSendAlternateEmailVerificationMutation() {
  return useMutation(sendAlternateEmailVerification);
}

export function useVerifyAlternateEmail() {
  return useMutation(verifyAlternateEmail);
}
