import { Form, Space } from 'antd';
import React, { useContext, useState } from 'react';
import { useTheme } from 'styled-components';

import { Box, Button, Input, Text } from 'src/client/components';
import { AccountContext } from 'src/client/contexts/AccountContext';
import { useUpdateDonorPasswordMutation } from 'src/client/hooks/mutations';
import { Donor } from 'src/commons/types';

import * as S from './styles';

type ConfirmPasswordFormValues = {
  currentPassword: string;
  newPassword: string;
};

const { useForm } = Form;

function PasswordEditField() {
  const { colors } = useTheme();
  const [form] = useForm();
  const donor = useContext(AccountContext) as Donor;
  const [isPasswordInputsVisible, setIsPasswordInputsVisible] = useState(false);
  const {
    mutateAsync: updateDonorPassword,
    isLoading: isUpdateDonorPasswordLoading,
  } = useUpdateDonorPasswordMutation();

  function showPasswordInputs() {
    setIsPasswordInputsVisible(true);
  }

  function hidePasswordInputs() {
    form.resetFields();
    setIsPasswordInputsVisible(false);
  }

  async function confirmPasswordChange(values: ConfirmPasswordFormValues) {
    await updateDonorPassword({
      currentPasswordVerification: values.currentPassword,
      donorId: donor.id,
      newPassword: values.newPassword,
    });

    hidePasswordInputs();
  }

  const changePasswordButton = !isPasswordInputsVisible && (
    <S.ChangePasswordButton type="noStyle" onClick={showPasswordInputs}>
      {/* <Button type="variant1">Change Password</Button> */}
      Change Password
    </S.ChangePasswordButton>
  );

  const passwordFields = isPasswordInputsVisible && (
    <>
      <Box margin="24px 0 0 0" />
      <Form.Item
        label={
          <Text color={colors.darkHighEmphasis} type="body1med2">
            Current Password
          </Text>
        }
        name="currentPassword"
        rules={[
          {
            required: true,
            message: 'Please input your current password!',
          },
        ]}
      >
        <Input.Password placeholder="Current Password" size="large" />
      </Form.Item>
      <Box margin="0 0 32px 0" />
      <Form.Item
        hasFeedback
        label={
          <Text color={colors.darkHighEmphasis} type="body1med2">
            New Password
          </Text>
        }
        name="newPassword"
        rules={[
          {
            required: true,
            message: 'Please input your new password!',
          },
        ]}
      >
        <Input.Password placeholder="New Password" size="large" />
      </Form.Item>
      <Box margin="0 0 32px 0" />
      <Form.Item
        hasFeedback
        dependencies={['newPassword']}
        label={
          <Text color={colors.darkHighEmphasis} type="body1med2">
            Confirm New Password
          </Text>
        }
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator: (_, value) => {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }

              return Promise.reject(
                new Error('The two passwords that you entered do not match!')
              );
            },
          }),
        ]}
      >
        <Input.Password placeholder="Confirm New Password" size="large" />
      </Form.Item>
      <Box margin="24px 0 0 0" />
      <Space size={12}>
        <Button type="variant1" onClick={hidePasswordInputs}>
          Cancel
        </Button>
        <Button
          htmlType="submit"
          loading={isUpdateDonorPasswordLoading}
          type="primary"
        >
          Confirm
        </Button>
      </Space>
    </>
  );

  return (
    <Form form={form} layout="vertical" onFinish={confirmPasswordChange}>
      <Box margin="72px 0 8px 0">
        <Text color={colors.darkEmphasis1} type="h4bold2">
          Password
        </Text>
      </Box>
      {changePasswordButton}
      {passwordFields}
    </Form>
  );
}

export default PasswordEditField;
