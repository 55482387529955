import { PageRecipient } from 'src/commons/types';

import { GetPageRecipientParams } from '../types/ApiParams';

import ApiClient from './ApiClient';

const apiVersion = '1.0';
const endpoint = 'page-recipients';
const url = `${apiVersion}/${endpoint}`;

export async function getPageRecipients(
  params: GetPageRecipientParams
): Promise<PageRecipient[]> {
  const { data } = await ApiClient.get<PageRecipient[]>(url, {
    params,
  });

  return data;
}

export async function updatePageRecipients(
  params: PageRecipient[]
): Promise<PageRecipient[]> {
  const { data } = await ApiClient.put<PageRecipient[]>(url, params);

  return data;
}
