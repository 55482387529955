/* eslint-disable no-magic-numbers */
import { Col, Form, Row, message, FormInstance } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React, { useContext } from 'react';
import { useIsMutating } from 'react-query';

import { GetDonorEthnicityResponse } from 'src/client/api/DonorApi';

import {
  Box,
  Checkbox,
  EditProfileBottom,
  Input,
  Text,
} from 'src/client/components';
import { AccountContext } from 'src/client/contexts/AccountContext';
import {
  useUpdateDonorMutation,
  useUpdateDonorGenderMutation,
  useUpdateDonorEthnicitiesMutation,
  useDeleteDonorEthnicitiesMutation,
  useCreateDonorEthnicitiesMutation,
} from 'src/client/hooks/mutations';
import {
  useGetDonorEthnicities,
  useGetDonorGender,
} from 'src/client/hooks/queries';
import { ETHNICITIES } from 'src/commons/constants/ethnicities';
import { GENDERS } from 'src/commons/constants/genders';

import { Donor, Indexable } from 'src/commons/types';
import { getEthnicityLegacyId } from 'src/commons/utils/EthnicityUtils';

import LoadingPage from '../../LoadingPage';

import * as S from './styles';

type EthnicityFormValue = {
  isChecked: boolean;
  specifics: undefined | string | null;
};

type DonorGender = {
  gender: string | null | undefined;
  isOther: boolean;
};

type EthnicityObj = { name: string; legacyId: string };

const { useForm } = Form;

const emptyOnSuccessOption = { onSuccess: () => {} };

function WhoGives() {
  const donor = useContext(AccountContext) as Donor;
  const [form] = useForm();
  const mutationCount = useIsMutating();

  const { data: donorGender, isLoading: isDonorGenderLoading } =
    useGetDonorGender(donor.id);
  const { data: donorEthnicities, isLoading: isDonorEthnicityLoading } =
    useGetDonorEthnicities(donor.id);

  const { mutateAsync: updateDonor } =
    useUpdateDonorMutation(emptyOnSuccessOption);
  const { mutateAsync: updateDonorGender } =
    useUpdateDonorGenderMutation(emptyOnSuccessOption);
  const { mutateAsync: updateDonorEthnicities } =
    useUpdateDonorEthnicitiesMutation(emptyOnSuccessOption);
  const { mutateAsync: deleteDonorEthnicities } =
    useDeleteDonorEthnicitiesMutation(emptyOnSuccessOption);
  const { mutateAsync: createDonorEthnicities } =
    useCreateDonorEthnicitiesMutation(emptyOnSuccessOption);

  async function handleSave() {
    const formValues = form.getFieldsValue();
    const updatedDonorData: Partial<Donor> = {};
    const requestPromises: Promise<any>[] = [];

    if (form.isFieldTouched('occupation')) {
      updatedDonorData.occupation = formValues.occupation;
    }

    if (form.isFieldTouched('gender')) {
      const isOther = formValues.gender === GENDERS.OTHER;
      const gender = isOther
        ? formValues.otherGender.trim()
        : formValues.gender;

      const updateDonorGenderPromise = updateDonorGender({
        isOther,
        gender,
        donorId: donor.id,
      });

      requestPromises.push(updateDonorGenderPromise);
    }

    if (form.isFieldTouched(['ethnicity'])) {
      const { donorEthnicityIdsToDelete, donorEthnictiesToUpdate } =
        getDonorEthnicitiesToDeleteAndUpdate(form, donorEthnicities);
      const donorEthnictiesToCreate = getDonorEthnicitiesToCreate(
        form,
        donorEthnicities
      );

      const updateDonorEthnicitiesPromise = updateDonorEthnicities({
        donorEthnicities: donorEthnictiesToUpdate,
        donorId: donor.id,
      });
      const deleteDonorEthnicitiesPromise = deleteDonorEthnicities({
        deletedDonorEthnicityIds: donorEthnicityIdsToDelete,
        donorId: donor.id,
      });
      const createDonorEthnicitiesProimise = createDonorEthnicities({
        donorEthnicities: donorEthnictiesToCreate,
        donorId: donor.id,
      });

      requestPromises.push(updateDonorEthnicitiesPromise);
      requestPromises.push(deleteDonorEthnicitiesPromise);
      requestPromises.push(createDonorEthnicitiesProimise);
    }

    if (form.isFieldTouched(['ethnicity', ETHNICITIES.OTHER.name])) {
      const donorEthnicitiesForm = form.getFieldsValue().ethnicity;
      const otherEthnicityForm = donorEthnicitiesForm[ETHNICITIES.OTHER.name];

      updatedDonorData.ethnicityOther = otherEthnicityForm.isChecked
        ? otherEthnicityForm.specifics
        : null;
    }

    if (!isEmpty(updatedDonorData)) {
      const updateDonorPromise = updateDonor({
        id: donor.id,
        ...updatedDonorData,
      });

      requestPromises.push(updateDonorPromise);
    }

    document.body.style.cursor = 'wait';

    await Promise.all(requestPromises);

    location.reload();
  }

  function resetForm() {
    form.resetFields();
    message.info('Changes Discarded!');
  }

  const genderRadioButtons = Object.entries(GENDERS).map(([key, gender]) => (
    <S.StyledRadio key={gender.legacyId} value={gender.name}>
      <Text type="body1reg2">{gender.name.replace('_', '-')}</Text>
    </S.StyledRadio>
  ));

  const ethnicityOptions = Object.entries(ETHNICITIES).map(
    ([key, ethnicity]) => {
      const { checkboxLabel, specificsPlaceholder } =
        makeEtchnicityText(ethnicity);

      const ethnicityCheckboxFormItemName = [
        'ethnicity',
        ethnicity.name,
        'isChecked',
      ];

      return (
        <S.EthnicityOptionContainer key={ethnicity.name}>
          <Row align="middle" gutter={[0, 8]}>
            <Col md={10} xs={24}>
              <Form.Item
                noStyle
                name={ethnicityCheckboxFormItemName}
                valuePropName="checked"
              >
                <Checkbox>{checkboxLabel}</Checkbox>
              </Form.Item>
            </Col>
            <Col md={14} xs={24}>
              <Form.Item noStyle shouldUpdate>
                {(formInstance) => {
                  const ethnicityCheckboxValue = formInstance.getFieldValue(
                    ethnicityCheckboxFormItemName
                  );
                  const shouldDisableInput = !ethnicityCheckboxValue;
                  const isRequired =
                    !shouldDisableInput &&
                    ethnicity.name === ETHNICITIES.OTHER.name;

                  return (
                    <Form.Item
                      name={['ethnicity', ethnicity.name, 'specifics']}
                      rules={[
                        {
                          required: isRequired,
                          message: 'This field is required',
                        },
                      ]}
                    >
                      <Input
                        disabled={shouldDisableInput}
                        placeholder={specificsPlaceholder}
                        size="large"
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </S.EthnicityOptionContainer>
      );
    }
  );

  const isTabLoading = isDonorEthnicityLoading || isDonorGenderLoading;

  if (isTabLoading) {
    return <LoadingPage />;
  }

  return (
    <Box margin="60px 0 0 0">
      <Row>
        <Col lg={18} md={20} xl={12} xs={24}>
          <Form
            form={form}
            initialValues={getInitialValue(
              donor,
              donorGender,
              donorEthnicities
            )}
            layout="vertical"
            onFinish={handleSave}
          >
            <Form.Item label="Gender" name="gender">
              <S.StyledRadioGroup>{genderRadioButtons}</S.StyledRadioGroup>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.gender !== currentValues.gender
              }
            >
              {({ getFieldValue }) =>
                getFieldValue('gender') === GENDERS.OTHER && (
                  <Form.Item name="otherGender">
                    <S.OtherGenderInput />
                  </Form.Item>
                )
              }
            </Form.Item>
            <Form.Item label="Race/Ethnicity (options based on U.S. Census)">
              {ethnicityOptions}
            </Form.Item>
            <Form.Item label="Occupation" name="occupation">
              <Input placeholder="Your occupation" size="large" />
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {() => (
                <EditProfileBottom
                  saveBtnProps={{
                    disabled: !form.isFieldsTouched(),
                    loading: !!mutationCount,
                  }}
                  onDiscard={resetForm}
                />
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Box>
  );
}

function makeEtchnicityText(ethnicity: EthnicityObj) {
  const ETHNICITY_TEXTS = {
    [ETHNICITIES.AMERICAN_INDIAN.name]: {
      checkboxLabel: 'American Indian or Alaskan Native',
      specificsPlaceholder: '(Optional) origin e.g. Navajo',
    },
    [ETHNICITIES.ASIAN.name]: {
      checkboxLabel: 'Asian',
      specificsPlaceholder: '(Optional) origin e.g. Chinese',
    },
    [ETHNICITIES.BLACK.name]: {
      checkboxLabel: 'Black, African American',
      specificsPlaceholder: '(Optional) origin e.g. Hatian',
    },
    [ETHNICITIES.HISPANIC.name]: {
      checkboxLabel: 'Hispanic, Latino, or Spanish specifics',
      specificsPlaceholder: '(Optional) origin e.g. Mexican',
    },
    [ETHNICITIES.NATIVE_HAWAIIAN.name]: {
      checkboxLabel: 'Native Hawaiian, or Pacific Islander',
      specificsPlaceholder: '(Optional) origin e.g. Samoan',
    },
    [ETHNICITIES.WHITE.name]: {
      checkboxLabel: 'White',
      specificsPlaceholder: '(Optional) origin e.g. German',
    },
    [ETHNICITIES.OTHER.name]: {
      checkboxLabel: 'Other',
      specificsPlaceholder: 'Other',
    },
  };

  return ETHNICITY_TEXTS[ethnicity.name] ?? {};
}

function getDonorEthnicitiesToDeleteAndUpdate(
  form: FormInstance,
  donorEthnicities?: GetDonorEthnicityResponse
) {
  const donorEthnicityIdsToDelete: string[] = [];
  const donorEthnictiesToUpdate: {
    id: string;
    specifics: null | string;
  }[] = [];

  donorEthnicities?.forEach((donorEthnicity) => {
    const ethnicityFormValue = getDonorEthnicityForm(
      form,
      donorEthnicity.ethnicity
    );

    const shouldDeleteDonorEthnicity = !ethnicityFormValue.isChecked;

    const shouldUpdateDonotEthnicitySpecifics =
      typeof ethnicityFormValue.specifics === 'string' &&
      ethnicityFormValue.specifics !== donorEthnicity.specifics;

    if (shouldDeleteDonorEthnicity) {
      donorEthnicityIdsToDelete.push(donorEthnicity.id);

      return;
    }

    if (shouldUpdateDonotEthnicitySpecifics) {
      donorEthnictiesToUpdate.push({
        id: donorEthnicity.id,
        specifics: ethnicityFormValue.specifics ?? null,
      });
    }
  });

  return { donorEthnicityIdsToDelete, donorEthnictiesToUpdate };
}

function getDonorEthnicityForm(
  form: FormInstance,
  targetEthnicityName: string
) {
  const formValues = form.getFieldsValue();
  const donorEthnicitiesForm = formValues.ethnicity;

  const [ethnicityFormName, ethnicityFormValue] =
    Object.entries<EthnicityFormValue>(donorEthnicitiesForm).find(
      ([ethnicityFormName, ethnicityFormValue]) =>
        ethnicityFormName === targetEthnicityName
    ) as [string, EthnicityFormValue];

  return {
    ...ethnicityFormValue,
    ethnicity: ethnicityFormName,
  };
}

function getDonorEthnicitiesToCreate(
  form: FormInstance,
  donorEthnicities?: GetDonorEthnicityResponse
) {
  const formValues = form.getFieldsValue();
  const donorEthnicitiesForm = formValues.ethnicity;

  return Object.entries<EthnicityFormValue>(donorEthnicitiesForm)
    .map(([ethnicityFormName, ethnicityFormValue]) => {
      const isNotSeleted = !ethnicityFormValue.isChecked;

      if (isNotSeleted) {
        return;
      }

      if (ethnicityFormName === ETHNICITIES.OTHER.name) {
        return;
      }

      const isEthnicitySelectedAlreadyInDb = Boolean(
        donorEthnicities?.find(
          (donorEthnicity) => ethnicityFormName === donorEthnicity.ethnicity
        )
      );

      if (!isEthnicitySelectedAlreadyInDb) {
        const ethnicityLegacyId = getEthnicityLegacyId(ethnicityFormName);

        return {
          ethnicityLegacyId,
          specifics: ethnicityFormValue.specifics,
        };
      }
    })
    .filter(Boolean) as {
    ethnicityLegacyId: string;
    specifics: string;
  }[];
}

function getInitialValue(
  donor: Donor,
  donorGender?: DonorGender,
  donorEthnicities?: GetDonorEthnicityResponse
) {
  const ethnicities: Indexable<EthnicityFormValue> = {};

  donorEthnicities?.forEach((donorEthnicity: any) => {
    const ethnicity = donorEthnicity.ethnicity
      ? (donorEthnicity.ethnicity as string)
      : '';

    ethnicities[ethnicity] = {
      isChecked: true,
      specifics: donorEthnicity.specifics,
    };
  });

  if (donor.ethnicityOther) {
    ethnicities[ETHNICITIES.OTHER.name] = {
      isChecked: true,
      specifics: donor.ethnicityOther,
    };
  }

  return {
    occupation: donor?.occupation,
    otherGender: donorGender && donorGender.isOther ? donorGender.gender : '',
    gender: donorGender && !donorGender.isOther ? donorGender.gender : '',
    ethnicity: ethnicities,
  };
}

export default WhoGives;
