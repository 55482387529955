import styled from 'styled-components';

import { BODY_1_REG_2, BUTTON_1_2 } from 'src/client/constants/text';

import Button from '../Button';
import DatePicker from '../DatePicker';
import Input from '../Input';
import Select from '../Select';
import { Flex } from '../StyledCommon';

const { RangePicker } = DatePicker;

export const Container = styled.div`
  .ant-form-item {
    margin: 0;
  }
`;

export const FilterSelect = styled(Select)`
  min-width: 168px;

  && .ant-select-selector {
    height: 36px;
    border-radius: 6px;
    background: #fafafa;
  }

  && .ant-select-selection-item {
    ${BUTTON_1_2};
    color: ${(props) => props.theme.colors.darkEmphasis1};
    line-height: 36px;
  }

  &:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select-focused {
    border-color: ${(props) => props.theme.colors.darkSurface1};
    box-shadow: none;
  }
`;

export const ControlsContainer = styled(Flex)`
  align-items: center;
  flex-direction: column;
  gap: 10px;

  @media ${(props) => props.theme.screen.sm} {
    align-items: flex-start;
  }

  @media ${(props) => props.theme.screen.md} {
    flex-direction: row;
  }
`;

export const SelectionContainer = styled(Flex)`
  flex-direction: column;
  gap: 12px;

  @media ${(props) => props.theme.screen.sm} {
    flex-direction: row;
    align-items: center;
  }
`;

export const FilterInputContainer = styled.div`
  min-width: 280px;

  @media ${(props) => props.theme.screen.md} {
    min-width: 320px;
  }
`;

export const StyledInput = styled(Input)`
  height: 36px;
  width: 100%;
`;

export const StyledSelect = styled(FilterSelect)`
  width: 100%;

  && .ant-select-selector {
    background: white;
  }

  && .ant-select-selection-item,
  && .ant-select-selection-placeholder {
    ${BODY_1_REG_2};
    line-height: 36px;
  }
`;

export const StyledRangePicker = styled(RangePicker)`
  padding: 6px 12px;
  height: 36px;
  border-radius: 6px;
  width: 100%;

  .ant-picker-input > input {
    ${BODY_1_REG_2};
  }
`;

export const ClearButton = styled(Button)`
  box-shadow: none;

  @media ${(props) => props.theme.screen.sm} {
    align-self: flex-end;
  }
`;
