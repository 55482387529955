import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  AdminAddPlatform,
  AdminAddRecipient,
  AdminDashboard,
  AdminDonors,
  AdminEditDonor,
  AdminEditGive,
  AdminEditPlatform,
  AdminEditRecipient,
  AdminGives,
  AdminPlatformDashboard,
  AdminPlatforms,
  AdminRecipients,
  AdminUsersPage,
  AdminViewGive,
  PageNotFound,
  PrivacyPolicyPage,
  PublicGallery,
  ResendInitialPasswordLink,
} from 'src/client/pages';

import routes from 'src/commons/constants/routes';

import AdminAddGive from '../../../pages/AdminAddGive';

function AdminRoutes() {
  return (
    <Switch>
      <Route component={AdminUsersPage} path={routes.ADMINS} />
      <Route exact component={AdminGives} path={routes.ADMIN_GIVES} />
      <Route exact component={AdminDonors} path={routes.ADMIN_DONORS} />
      <Route exact component={AdminDashboard} path={routes.ADMIN_DASHBOARD} />
      <Route exact component={AdminEditGive} path={routes.ADMIN_EDIT_GIVE} />
      <Route exact component={AdminEditDonor} path={routes.ADMIN_EDIT_DONOR} />
      <Route exact component={PrivacyPolicyPage} path={routes.PRIVACY_POLICY} />
      <Route
        exact
        component={AdminEditRecipient}
        path={routes.ADMIN_EDIT_RECIPIENT}
      />
      <Route exact component={AdminAddGive} path={routes.ADMIN_ADD_GIVE} />
      <Route
        exact
        component={AdminAddRecipient}
        path={routes.ADMIN_ADD_RECIPIENT}
      />
      <Route exact component={AdminViewGive} path={routes.ADMIN_VIEW_GIVE} />
      <Route exact component={AdminPlatforms} path={routes.ADMIN_PLATFORMS} />
      <Route
        exact
        component={AdminEditPlatform}
        path={routes.ADMIN_EDIT_PLATFORMS}
      />
      <Route
        exact
        component={AdminAddPlatform}
        path={routes.ADMIN_ADD_PLATFORMS}
      />
      <Route
        exact
        component={AdminPlatformDashboard}
        path={routes.ADMIN_PLATFORM_DASHBOARD}
      />
      <Route exact component={AdminRecipients} path={routes.ADMIN_RECIPIENTS} />
      <Route
        exact
        component={ResendInitialPasswordLink}
        path={routes.RESEND_INITIAL_PASSWORD_LINK}
      />
      <Route exact path={routes.ROOT}>
        <Redirect to={routes.ADMIN_DASHBOARD} />
      </Route>
      <Route exact component={PublicGallery} path={routes.PUBLIC_GALLERY} />
      <Route component={PageNotFound} />;
    </Switch>
  );
}

export default AdminRoutes;
