import styled from 'styled-components';

import { Button } from 'src/client/components';

export const Card = styled.div`
  padding: 40px;
  border-radius: 24px;
  background: ${(props) => props.theme.colors.white};
`;

export const ConfirmButton = styled(Button)`
  width: 100%;
`;
