import { BarChart } from 'recharts';
import styled from 'styled-components';

import {
  H6_BOLD_2,
  LABEL_2_MED_2,
  LABEL_2_REG_2,
} from 'src/client/constants/text';

export const Container = styled.div`
  margin: 30px 0;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: ${(props) => props.theme.colors.darkSurface2};

  @media ${(props) => props.theme.device.laptop} {
    margin: 34px 0 60px 0;
    padding: 45px 40px;
  }
`;

export const GraphContainer = styled.div`
  width: 100%;
  height: 440px;

  @media ${(props) => props.theme.device.laptop} {
    height: 312px;
  }
`;

export const StyledBarChart = styled(BarChart)`
  text, text tspan {
    margin-top: 5px;
    fill: ${(props) => props.theme.colors.lightEmphasis2} !important;
    ${LABEL_2_REG_2};
  }

  .recharts-default-tooltip {
    background-color: ${(props) => props.theme.colors.darkSurface1} !important;
    border: none !important;
    border-radius: 8px;
  }

  .recharts-tooltip-label {
    ${H6_BOLD_2};
  }

  .recharts-tooltip-item {
    padding-bottom: 0px;
    ${LABEL_2_MED_2};
  }

  .recharts-rectangle .recharts-tooltip-cursor {
    fill: ${(props) => props.theme.colors.darkSurface3} !important;
  }

  .recharts-tooltip-item-name,
  .recharts-tooltip-item-separator,
  .recharts-tooltip-item-value,
  .recharts-tooltip-item-unit {
    color: ${(props) => props.theme.colors.lightSurface80} !important;
  }
`;
