import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  useSensor,
  useSensors,
  MouseSensor,
} from '@dnd-kit/core';
import { Grid } from 'antd';
import React, { useState } from 'react';

import { useSectionStatus } from 'src/client/hooks/useSectionStatus';
import { analytics } from 'src/client/libs/segment';
import { STATUS_OPTIONS, SectionStatus } from 'src/client/types/Gallery';
import { sortAndGroupPageRecipients } from 'src/client/utils/PageRecipientUtils';
import { SEGMENT_EVENTS } from 'src/commons/constants/segment';
import { Page, PageRecipient } from 'src/commons/types';

import EditControlButtons from '../EditControlButtons';
import FeaturedCardsEditMode from '../FeaturedCardsEditMode';
import FeaturedCardsViewMode from '../FeaturedCardsViewMode';

import GiveCard from '../GiveCard';
import HiddenCardsEditMode from '../HiddenCardsEditMode';
import UnfeaturedCardsEditMode from '../UnfeaturedCardsEditMode';
import UnfeaturedCardsViewMode from '../UnfeaturedCardsViewMode';

import { useMovePageRecipientsEditData } from './hooks/useMovePageRecipient';
import * as S from './styles';

type Props = {
  isLoading: boolean;
  isSaveButtonLoading: boolean;
  isViewingPublicly: boolean;
  onSave: () => void;
  page: Page;
  pageRecipientsEditData: PageRecipient[];
  savedPageRecipients: PageRecipient[] | undefined;
  sectionStatus: SectionStatus;
  setPageRecipientsEditData: (newPageRecipients: PageRecipient[]) => void;
  setSectionStatus: (newStatus: STATUS_OPTIONS) => void;
};

const { useBreakpoint } = Grid;

function CardsSection(props: Props) {
  const {
    isLoading,
    isSaveButtonLoading,
    isViewingPublicly,
    onSave,
    page,
    pageRecipientsEditData,
    savedPageRecipients,
    sectionStatus,
    setPageRecipientsEditData,
    setSectionStatus,
  } = props;

  const screens = useBreakpoint();
  const isBigScreen = screens.md;
  const {
    isEditing,
    isSaveButtonDisabled,
    isSectionHasChanges: isCardsSectionHasChanges,
  } = useSectionStatus(sectionStatus);
  const [activePageRecipient, setActivePageRecipient] =
    useState<PageRecipient | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const pageRecipients = isEditing
    ? pageRecipientsEditData
    : savedPageRecipients;

  const {
    sortedFeaturedPageRecipients: featuredPageRecipients,
    sortedUnfeaturedPageRecipients: unfeaturedPageRecipients,
    sortedHiddenPageRecipients: hiddenPageRecipients,
  } = sortAndGroupPageRecipients(pageRecipients);

  const {
    featurePageRecipient,
    featuredCardsEditModeRef,
    hiddenCardsEditModeRef,
    hidePageRecipient,
    moveAndSetPageRecipientOrder,
    moveFeaturedPageRecipientToUnfeatured,
    moveHiddenPageRecipientToUnfeatured,
    unfeaturedCardsEditModeRef,
  } = useMovePageRecipientsEditData({
    pageRecipientsEditData,
    setPageRecipientsEditData,
    isCardsSectionHasChanges,
    setSectionStatus,
  });

  function startEditing() {
    if (savedPageRecipients) {
      setPageRecipientsEditData(savedPageRecipients);
      setSectionStatus(STATUS_OPTIONS.EDITING_WITH_NO_CHANGES);

      analytics.track(
        SEGMENT_EVENTS.USER_CLICKED_AN_EDIT_GALLERY_SECTION_BUTTON,
        {
          page,
          ownerName: page.ownerName,
          section: 'cards',
        }
      );
    }
  }

  function cancelEditing() {
    setPageRecipientsEditData([]);
    setSectionStatus(STATUS_OPTIONS.VIEW_MODE);
  }

  function handleSaveSection() {
    onSave();

    analytics.track(SEGMENT_EVENTS.USER_SAVED_A_GALLERY_SECTION, {
      section: 'cards',
    });
  }

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;

    setActivePageRecipient(
      pageRecipientsEditData.find(
        (pageRecipient) => pageRecipient.id === active.id
      ) as PageRecipient
    );
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (!over) {
      return null;
    }

    const overPageRecipient = pageRecipients?.find(
      (pageRecipient) => pageRecipient.id === over?.id
    ) as PageRecipient;

    const isGoingToDropToDifferentVisibility =
      overPageRecipient.visibility !== activePageRecipient?.visibility;

    if (isGoingToDropToDifferentVisibility) {
      return null;
    }

    if (active.id !== over?.id) {
      const pageRecipientOver = pageRecipients?.find(
        (pageRecipient) => pageRecipient.id === over.id
      ) as PageRecipient;
      moveAndSetPageRecipientOrder({
        pageRecipients: pageRecipientsEditData,
        pageRecipienToMoveId: active.id as string,
        newOrderIndex: pageRecipientOver.order as number,
        newVisibility: pageRecipientOver.visibility,
      });
    }
  }

  function renderArrowGuide2() {
    if (!isBigScreen) {
      return null;
    }

    if (isQuoteEmptyAndViewingPublicly) {
      return null;
    }

    return <S.ArrowGuide2 data-cy="arrow-guide-2" src="/galleryArrow2.svg" />;
  }

  const isQuoteEmptyAndViewingPublicly = !page.quote && isViewingPublicly;
  const isFeaturedPageRecipientsEmpty = featuredPageRecipients?.length === 0;
  const isUnfeaturedPageRecipientsEmpty =
    unfeaturedPageRecipients?.length === 0;
  const isHiddenPageRecipientsEmpty = hiddenPageRecipients?.length === 0;

  const featuredCards = isEditing ? (
    <FeaturedCardsEditMode
      featuredPageRecipients={featuredPageRecipients}
      handleDragEnd={handleDragEnd}
      handleDragStart={handleDragStart}
      isLoading={isLoading}
      ref={featuredCardsEditModeRef}
      sensors={sensors}
      onHidePageRecipient={hidePageRecipient}
      onUnfeaturePageRecipient={moveFeaturedPageRecipientToUnfeatured}
    />
  ) : (
    <FeaturedCardsViewMode
      featuredPageRecipients={featuredPageRecipients}
      isLoading={isLoading}
    />
  );
  const unfeaturedCards = isEditing ? (
    <UnfeaturedCardsEditMode
      featuredPageRecipients={featuredPageRecipients}
      handleDragEnd={handleDragEnd}
      handleDragStart={handleDragStart}
      isLoading={isLoading}
      ref={unfeaturedCardsEditModeRef}
      sensors={sensors}
      unfeaturedPageRecipients={unfeaturedPageRecipients}
      onFeaturePageRecipient={featurePageRecipient}
      onHidePageRecipient={hidePageRecipient}
    />
  ) : (
    <UnfeaturedCardsViewMode
      isLoading={isLoading}
      unfeaturedPageRecipients={unfeaturedPageRecipients}
    />
  );
  const hiddenCardsContent = isEditing ? (
    <HiddenCardsEditMode
      handleDragEnd={handleDragEnd}
      handleDragStart={handleDragStart}
      hiddenPageRecipients={hiddenPageRecipients}
      isLoading={isLoading}
      ref={hiddenCardsEditModeRef}
      sensors={sensors}
      onFeaturePageRecipient={moveHiddenPageRecipientToUnfeatured}
    />
  ) : null;
  const divider = !isEditing && <S.Divider />;
  const giveCardOverlay = activePageRecipient ? (
    <GiveCard
      isDragOverlay
      isEditMode
      id={activePageRecipient.id}
      pageRecipient={activePageRecipient}
    />
  ) : null;

  return (
    <DndContext
      autoScroll={{
        acceleration: 500,
      }}
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <S.GivesSection>
        <S.GivesSectionContentContainer isEditing={isEditing}>
          <EditControlButtons
            cancelButtonCyId="cards-section-cancel-btn"
            editButtonCyId="cards-section-edit-btn"
            editButtonText={{
              bigScreenText: 'Organize gallery',
              smallScreenText: 'Organize',
            }}
            isEditing={isEditing}
            isSaveButtonDisabled={isSaveButtonDisabled}
            isSaving={isSaveButtonLoading}
            isViewingPublicly={isViewingPublicly}
            saveButtonCyId="cards-section-save-btn"
            title="Gallery Section"
            onCancel={cancelEditing}
            onEdit={startEditing}
            onSave={handleSaveSection}
          />
          {(!isFeaturedPageRecipientsEmpty || isEditing) && (
            <>
              <S.CardsHeader isEditing={isEditing}>
                {renderArrowGuide2()}
                Featured
              </S.CardsHeader>
              {featuredCards}
            </>
          )}
          {(!isUnfeaturedPageRecipientsEmpty || isEditing) && (
            <>
              {divider}
              <S.CardsHeader isEditing={isEditing}>
                {isFeaturedPageRecipientsEmpty &&
                  !isEditing &&
                  renderArrowGuide2()}
                All
              </S.CardsHeader>
              {unfeaturedCards}
            </>
          )}
          {!isHiddenPageRecipientsEmpty && (
            <>
              <S.CardsHeader isEditing={isEditing}>Hidden</S.CardsHeader>
              {hiddenCardsContent}
            </>
          )}
          <DragOverlay>{giveCardOverlay}</DragOverlay>
        </S.GivesSectionContentContainer>
      </S.GivesSection>
    </DndContext>
  );
}

export default CardsSection;
