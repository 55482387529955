import { useMutation } from 'react-query';

import {
  createPlatform,
  deletePlatform,
  updatePlatform,
} from 'src/client/api/PlatformApi';
import { Platform } from 'src/commons/types';
import { ApiResponse } from 'src/commons/types/Response.type';

type Options<Data = ApiResponse<Platform>> = {
  onSuccess: (data: Data) => void;
};

export function useDeletePlatform(
  options?: Options<ApiResponse<{ deletedPlatformId: string }>>
) {
  return useMutation(deletePlatform, options);
}

export function useCreatePlatformMutation(
  options?: Options<ApiResponse<Platform>>
) {
  return useMutation(createPlatform, options);
}

export function useUpdatePlatformMutation(
  options?: Options<ApiResponse<Platform>>
) {
  return useMutation(updatePlatform, options);
}
