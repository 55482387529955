import { message } from 'antd';
import { AxiosError } from 'axios';
import get from 'lodash.get';

import { Indexable } from '../types/Indexable.type';

export const GLOBAL_QUERY_CONFIG = {
  queries: {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 0,
    staleTime: 0,
    onError: (err: unknown) => {
      const error = err as AxiosError;

      if (
        error.response?.data?.error?.message.includes(
          'FAILED_PRECONDITION: no matching index found'
        )
      ) {
        message.error('Error: Index not available');
      }
    },
  },
  mutations: {
    onSuccess: (data: unknown) => {
      if ((data as Indexable)?.message) {
        message.success((data as Indexable).message);
      }
    },
    onError: (error: unknown) => {
      const errorMessage = get(
        error,
        'response.data.error.message',
        'Oops! Something went wrong.'
      );

      message.error(errorMessage);
    },
  },
};
