import { Form, FormInstance, message } from 'antd';
import omit from 'lodash/omit';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router-dom';

import { Title } from 'src/client/components';
import {
  useUpdateDonorMutation,
  useUpdatePageMutation,
  useUploadPageCustomImageMutation,
} from 'src/client/hooks/mutations';
import { useUpdatePageRecipientsMutation } from 'src/client/hooks/mutations/pageRecipientMutation';
import {
  GET_GALLERY_CUSTOM_IMAGES_KEY,
  setPageRecipientsQueryData,
  updateGetDonorByIdQuery,
  updateGetPageQueryData,
  useGetDonorById,
  useGetPage,
  useGetPageRecipients,
} from 'src/client/hooks/queries';
import { useScrollRotation } from 'src/client/hooks/useScrollRotation';
import { analytics } from 'src/client/libs/segment';
import { SECTION_NAMES, STATUS_OPTIONS } from 'src/client/types/Gallery';

import {
  createUploadFormItemValue,
  getTouchedFieldsValue,
} from 'src/client/utils/FormUtils';
import {
  DEFAULT_GALLERY_SHAPE,
  DEFAULT_GALLERY_TITLE,
  GALLERY_THEME_COLOR_VALUES,
  GALLERY_THEME_COLORS,
  GalleryColor,
} from 'src/commons/constants/gallery';

import { SEGMENT_EVENTS } from 'src/commons/constants/segment';
import { Page, PageRecipient, RECIPIENT_TYPE } from 'src/commons/types';
import { asyncForEach } from 'src/commons/utils/ArrayUtils';
import { convertEmptyStringsToNulls } from 'src/commons/utils/ObjectUtil';
import {
  getFileNameFromPath,
  getLastLetter,
} from 'src/commons/utils/StringUtils';
import { RequireField } from 'src/commons/utils/TypescriptUtils';

import MobileDismissableAlert from '../DonorGallery/components/IntroSection/components/MobileDismissableAlert';

import SuccessMessage from '../DonorGallery/components/SuccessMessage';

import { useDonorGallerySectionStatusManager } from '../DonorGallery/useDonorGallerySectionStatusManager';
import LoadingPage from '../LoadingPage';
import PageNotFound from '../PageNotFound';

import AdjectiveSection from './components/AdjectiveSection';
import CardsSection from './components/CardsSection';
import GalleryDescriptionInput from './components/GalleryDescriptionInput';
import GalleryQuoteAuthorInput from './components/GalleryQuoteAuthorInput';
import GalleryShapeInput from './components/GalleryShapeInput';
import GalleryTitleInput from './components/GalleryTitleInput';
import NavBarEditMode from './components/NavbarEditMode';
import PrivacyStatusInfo from './components/PrivacyStatusInfo';
import * as S from './styles';

type Props = {
  isViewingPublicly: boolean;
};

type UrlParams = {
  pageId: string;
};

const { useForm } = Form;

function DonorGalleryV2(props: Props) {
  const { isViewingPublicly } = props;

  const match = useRouteMatch<UrlParams>();
  const { pageId } = match.params;

  const [introSectionFormV2] = useForm();
  const [adjectiveSectionForm] = useForm();

  const queryClient = useQueryClient();
  const {
    data: page,
    isLoading: isGetPageLoading,
    isFetching: isGetPageFetching,
  } = useGetPage(pageId);
  const { data: donor, isLoading: isDonorLoading } = useGetDonorById(
    page?.ownerId
  );

  const [pageRecipientsEditData, setPageRecipientsEditData] = useState<
    PageRecipient[]
  >([]);
  const [visibleTypes, setVisibleTypes] = useState<Set<RECIPIENT_TYPE>>(
    new Set(Object.values(RECIPIENT_TYPE))
  );
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    introSectionFormV2.resetFields();
  }, [introSectionFormV2, isGetPageFetching]);

  const queryDataOfGetPageRecipients = {
    pageId: page?.id as string,
  };

  const {
    data: pageRecipients,
    isLoading: isPageRecipientsLoading,
    isIdle: isPageRecipientIdle,
  } = useGetPageRecipients(queryDataOfGetPageRecipients, {
    enabled: !!page?.id,
  });

  const { mutateAsync: updatePage, isLoading: isUpdatingPage } =
    useUpdatePageMutation({
      onSuccess: (updatePayload) => {
        updateGetPageQueryData({
          queryClient,
          data: updatePayload,
        });
      },
    });

  const {
    mutateAsync: uploadPageCustomImage,
    isLoading: isUploadingPageCustomImage,
  } = useUploadPageCustomImageMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(GET_GALLERY_CUSTOM_IMAGES_KEY, {
        refetchInactive: true,
      });
    },
  });

  const { mutateAsync: updateDonor, isLoading: isUpdatingDonor } =
    useUpdateDonorMutation({
      onSuccess: (data) => {
        updateGetDonorByIdQuery({
          data,
          donorId: data.id,
          queryClient,
        });
      },
    });

  const {
    mutateAsync: updatePageRecipient,
    isLoading: isUpdatingPageRecipients,
  } = useUpdatePageRecipientsMutation({
    // onSuccess: showSuccessMessage,
  });

  const isSavingAll =
    isUpdatingDonor ||
    isUpdatingPage ||
    isUploadingPageCustomImage ||
    isUpdatingPageRecipients;

  const {
    createSectionStatusSetter,
    exitEditModeToAllSections,
    getSectionStatus,
    sectionsStatus,
    setAllSectionStatusToViewMode,
    setSectionStatus,
  } = useDonorGallerySectionStatusManager();

  function showSuccessMessage() {
    message.info({
      icon: <div></div>,
      className: 'update-page-success-message',
      content: <SuccessMessage />,
    });
  }

  async function saveAllChanges() {
    try {
      const formsToSave = [introSectionFormV2];

      await saveCardsFiltering();
      await saveCardsSectionChanges();
      await savePageCustomImageChanges();
      await saveAdjectiveSection();
      await saveFormChanges(formsToSave);

      exitEditModeToAllSections(formsToSave);
      showSuccessMessage();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  async function savePageCustomImageChanges() {
    const touchedFields = getTouchedFieldsValue(introSectionFormV2);

    if (!('galleryCustomImage' in touchedFields)) {
      return null;
    }

    const file = touchedFields.galleryCustomImage?.[0];

    if (file) {
      await uploadPageCustomImage({
        file: file.originFileObj as File,
        pageId: page?.id as string,
      });
    } else {
      // if user chooses a shape
      await updatePage({
        id: page?.id as string,
        galleryCustomImageFilePath: null,
      });
    }
  }

  async function saveFormChanges(formSections: FormInstance<any>[]) {
    let updatePayload: RequireField<Partial<Page>, 'id'> = {
      id: (page as Page).id,
    };
    const ignoredFormItems = ['galleryCustomImage'];

    await asyncForEach(formSections, async (formSection) => {
      await formSection.validateFields();

      const touchedFields = getTouchedFieldsValue(formSection);
      const touchedFieldsWithNulls = convertEmptyStringsToNulls(touchedFields);

      // Temporary fix for handling quote and author fields when chosen from curated quotes
      const isQuoteChanged = page?.quote !== formSection.getFieldValue('quote');
      const isAuthorChanged =
        page?.author !== formSection.getFieldValue('author');
      let quoteAuthorPayload = {};

      // This code checks if the 'quote' or 'author' fields have been modified.
      // If the quote has been changed and it's not in the touchedFieldsWithNulls, or if the author has been changed,
      // the quoteAuthorPayload object is updated with the new quote or author value.
      if (
        !(
          'footerDescription' in touchedFields ||
          'footerIntroduction' in touchedFields
        )
      ) {
        quoteAuthorPayload = {
          ...(isQuoteChanged && {
            quote: formSection.getFieldValue('quote') || null,
          }),
          ...(isAuthorChanged && {
            author: formSection.getFieldValue('author') || null,
          }),
        };
      }

      updatePayload = {
        ...updatePayload,
        ...omit(touchedFieldsWithNulls, ignoredFormItems),
        ...quoteAuthorPayload,
      };
    });

    await updatePage(updatePayload);
    updateGetPageQueryData({
      queryClient,
      data: updatePayload,
    });
  }

  async function saveAdjectiveSection() {
    const payload = adjectiveSectionForm.getFieldsValue();
    const { galleryAdjective, isAdjectiveSectionVisible } = payload;

    if (galleryAdjective || isAdjectiveSectionVisible) {
      await updateDonor({
        id: donor?.id as string,

        ...(galleryAdjective && {
          descriptionCurrent: galleryAdjective,
          descriptionPrevious: donor?.descriptionCurrent,
        }),
        ...(isAdjectiveSectionVisible !== undefined && {
          isAdjectiveSectionVisible,
        }),
      });

      analytics.track(SEGMENT_EVENTS.USER_SAVED_A_GALLERY_SECTION, {
        section: 'adjection',
        newAdjective: galleryAdjective,
        previousAdjective: donor?.descriptionCurrent,
      });
    }
  }

  async function saveCardsSectionChanges() {
    if (pageRecipientsEditData.length > 0) {
      const sanitizedPageRecipientsEditData = pageRecipientsEditData.map(
        ({ recipientWebsite, ...pageRecipientData }) => pageRecipientData
      );
      await updatePageRecipient(sanitizedPageRecipientsEditData);
      setPageRecipientsQueryData(
        queryClient,
        pageRecipientsEditData,
        queryDataOfGetPageRecipients
      );
      setPageRecipientsEditData([]);
      setSectionStatus({
        name: SECTION_NAMES.CARDS_SECTION,
        status: STATUS_OPTIONS.VIEW_MODE,
      });
    }
  }

  async function saveCardsFiltering() {
    await updatePage({
      id: page?.id as string,
      visibleRecipientTypes: Array.from(visibleTypes),
    });
  }

  async function handleSelectThemeColor(color: GalleryColor) {
    if (color === page?.themeColor) {
      return;
    }

    await updatePage({
      id: page?.id as string,
      themeColor: color,
    });
  }

  function handleIntroFormV2Change() {
    const isFieldsTouched = introSectionFormV2.isFieldsTouched();

    const setSectionStatus = createSectionStatusSetter(
      SECTION_NAMES.INTRO_SECTION
    );

    if (isFieldsTouched) {
      setSectionStatus(STATUS_OPTIONS.EDITING_WITH_CHANGES);
    } else {
      setSectionStatus(STATUS_OPTIONS.EDITING_WITH_NO_CHANGES);
    }
  }

  function handleAdjectiveSectionFormChange() {
    const isFieldsTouched = adjectiveSectionForm.isFieldsTouched();

    const setSectionStatus = createSectionStatusSetter(
      SECTION_NAMES.ADJECTIVE_SECTION
    );

    if (isFieldsTouched) {
      setSectionStatus(STATUS_OPTIONS.EDITING_WITH_CHANGES);
    } else {
      setSectionStatus(STATUS_OPTIONS.EDITING_WITH_NO_CHANGES);
    }
  }

  function discardAllChanges() {
    introSectionFormV2.resetFields();
    adjectiveSectionForm.resetFields();

    if (pageRecipients) {
      setPageRecipientsEditData(pageRecipients);
    }

    setVisibleTypes(new Set(page?.visibleRecipientTypes));

    setAllSectionStatusToViewMode();
  }

  function makeGalleryTitle(ownerName: string) {
    const ownerNameLastLetter = getLastLetter(ownerName);

    if (ownerNameLastLetter === 's') {
      return `${ownerName}‘ Giving Side`;
    } else {
      return `${ownerName}‘s Giving Side`;
    }
  }

  const titleInputRef = React.useRef<HTMLDivElement>(null);
  const footerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (footerRef.current) {
        const rect = footerRef.current.getBoundingClientRect();
        const isInView = rect.top <= window.innerHeight && rect.bottom >= 0;

        if (isInView) {
          footerRef.current.style.height = '100vh';
        } else {
          footerRef.current.style.height = '200vh'; // Original height
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const rotation = useScrollRotation({ container });

  if (isGetPageLoading || isDonorLoading) {
    return <LoadingPage />;
  }

  if (!page || !donor) {
    return <PageNotFound />;
  }

  const navBar = !isViewingPublicly && (
    <NavBarEditMode
      isSavingAll={isSavingAll}
      page={page}
      sectionsStatus={sectionsStatus}
      onCloseAllEdit={setAllSectionStatusToViewMode}
      onDiscardAllChanges={discardAllChanges}
      onSaveAllChanges={saveAllChanges}
    />
  );

  const title =
    !page.isIntroductionTitleEditable || page.ownerName === null
      ? DEFAULT_GALLERY_TITLE
      : makeGalleryTitle(page.ownerName);
  const showPrivacyStatus = isViewingPublicly && !page.isVisible;

  const stickyHeader = isViewingPublicly && (
    <StickyHeader
      galleryTitleRef={titleInputRef}
      privacyStatusContent={
        <PrivacyStatusInfo isViewingPublicly={isViewingPublicly} page={page} />
      }
      showPrivacyStatus={showPrivacyStatus}
      title={title}
    />
  );

  const accentColorPicker = !isViewingPublicly && (
    <S.AccentColorPickerContainer isLoading={isSavingAll}>
      <span>Accent color</span>
      {GALLERY_THEME_COLORS.map((color) => (
        <S.AccentColor
          color={GALLERY_THEME_COLOR_VALUES[color]}
          isDisabled={isSavingAll}
          isSelected={page?.themeColor === color}
          key={color}
          onClick={() => handleSelectThemeColor(color)}
        />
      ))}
    </S.AccentColorPickerContainer>
  );

  const stickyBottomBadge = isViewingPublicly && (
    <S.PoweredByContainer>
      Powered by
      <S.Logo src="/logo.png" />
    </S.PoweredByContainer>
  );

  return (
    <>
      <Title title="Gallery - Giving Side" />

      <S.Container ref={setContainer}>
        <S.DonorGalleryGlobalStyle />
        {navBar}
        <MobileDismissableAlert />

        <S.IntroSectionContainer>
          {stickyHeader}

          <Form
            form={introSectionFormV2}
            initialValues={{
              quote: page.quote,
              author: page.author,
              ownerName: page.ownerName,
              introductionDescription: page.introductionDescription,
              isIntroductionTitleEditable:
                page.isIntroductionTitleEditable ?? true,
              isIntroductionDescriptionVisible:
                page.isIntroductionDescriptionVisible ?? true,
              galleryCustomImage:
                page.galleryCustomImageFileUrl &&
                page.galleryCustomImageFilePath
                  ? [
                      createUploadFormItemValue({
                        url: page.galleryCustomImageFileUrl,
                        fileName: getFileNameFromPath(
                          page.galleryCustomImageFilePath as string
                        ),
                      }),
                    ]
                  : undefined,
              galleryShape: {
                color: page.galleryShape?.color ?? DEFAULT_GALLERY_SHAPE?.color,
                name: page.galleryShape?.name ?? DEFAULT_GALLERY_SHAPE?.name,
              },
              isQuoteAuthorVisible: page.isQuoteAuthorVisible ?? true,
            }}
            onFieldsChange={handleIntroFormV2Change}
          >
            <div ref={titleInputRef}>
              <GalleryTitleInput
                galleryVisibility={page.isVisible}
                handleFormChange={handleIntroFormV2Change}
                introSectionForm={introSectionFormV2}
                isViewingPublicly={isViewingPublicly}
                sectionStatus={getSectionStatus(SECTION_NAMES.INTRO_SECTION)}
              />
            </div>
            <GalleryDescriptionInput
              handleFormChange={handleIntroFormV2Change}
              introSectionForm={introSectionFormV2}
              isViewingPublicly={isViewingPublicly}
              sectionStatus={getSectionStatus(SECTION_NAMES.INTRO_SECTION)}
            />
            <GalleryShapeInput
              introSectionForm={introSectionFormV2}
              isViewingPublicly={isViewingPublicly}
              rotation={rotation}
              themeColor={
                page?.themeColor ??
                (DEFAULT_GALLERY_SHAPE?.color as GalleryColor)
              }
              onChange={handleIntroFormV2Change}
            />
            <GalleryQuoteAuthorInput
              adjectiveSectionForm={adjectiveSectionForm}
              handleFormChange={handleIntroFormV2Change}
              introSectionForm={introSectionFormV2}
              isViewingPublicly={isViewingPublicly}
              page={page}
              sectionStatus={getSectionStatus(SECTION_NAMES.INTRO_SECTION)}
            />
          </Form>
        </S.IntroSectionContainer>

        <Form
          form={adjectiveSectionForm}
          initialValues={{
            isAdjectiveSectionVisible: donor.isAdjectiveSectionVisible ?? true,
            galleryAdjective: donor?.descriptionCurrent as string,
          }}
        >
          <AdjectiveSection
            adjectiveSectionForm={adjectiveSectionForm}
            donor={donor}
            handleFormChange={handleAdjectiveSectionFormChange}
            isUpdating={isSavingAll}
            isViewingPublicly={isViewingPublicly}
            sectionStatus={getSectionStatus(SECTION_NAMES.ADJECTIVE_SECTION)}
            setSectionStatus={createSectionStatusSetter(
              SECTION_NAMES.ADJECTIVE_SECTION
            )}
            themeColor={
              page?.themeColor ?? (DEFAULT_GALLERY_SHAPE?.color as GalleryColor)
            }
          />
        </Form>

        <CardsSection
          isLoading={isPageRecipientIdle || isPageRecipientsLoading}
          isSaveButtonLoading={isUpdatingPageRecipients}
          isViewingPublicly={isViewingPublicly}
          page={page}
          pageRecipientsEditData={pageRecipientsEditData}
          savedPageRecipients={pageRecipients}
          sectionStatus={getSectionStatus(SECTION_NAMES.CARDS_SECTION)}
          setPageRecipientsEditData={setPageRecipientsEditData}
          setSectionStatus={createSectionStatusSetter(
            SECTION_NAMES.CARDS_SECTION
          )}
          setVisibleTypes={setVisibleTypes}
          visibleTypes={visibleTypes}
          onSave={saveCardsSectionChanges}
        />

        <S.FooterContainer ref={footerRef}>
          <S.FooterBackground
            themeColor={
              page?.themeColor ?? (DEFAULT_GALLERY_SHAPE?.color as GalleryColor)
            }
          />
        </S.FooterContainer>
      </S.Container>

      {stickyBottomBadge}
      {accentColorPicker}
    </>
  );
}

type StickyHeaderProps = {
  galleryTitleRef: React.RefObject<HTMLElement>;
  privacyStatusContent: React.ReactNode;
  showPrivacyStatus: boolean;
  title: string;
};

function StickyHeader(props: StickyHeaderProps) {
  const { galleryTitleRef, privacyStatusContent, showPrivacyStatus, title } =
    props;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const visibilityObserver = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(!entry.isIntersecting);
      },
      { threshold: 0, rootMargin: '-60px 0px 0px 0px' }
    );

    if (galleryTitleRef.current) {
      visibilityObserver.observe(galleryTitleRef.current);
    }

    return () => visibilityObserver.disconnect();
  }, [galleryTitleRef]);

  return (
    <>
      <S.SlidingHeaderWrapper $isVisible={isVisible}>
        <S.SlidingHeaderText>{title}</S.SlidingHeaderText>
      </S.SlidingHeaderWrapper>
      {showPrivacyStatus && (
        <S.AnimatedPrivacyContainer $headerVisible={isVisible}>
          {privacyStatusContent}
        </S.AnimatedPrivacyContainer>
      )}
    </>
  );
}

export default DonorGalleryV2;
