/* eslint-disable no-alert */
import { DeleteOutlined, EditOutlined, LeftOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';

import { Box, Button, Flex, RowDivider } from 'src/client/components';
import { useDeleteGiveMutation } from 'src/client/hooks/mutations';
import { useGetGiveById } from 'src/client/hooks/queries';

import AdminLayout from 'src/client/layouts/AdminLayout';
import routes from 'src/commons/constants/routes';
import { Give, GIVE_UNIT } from 'src/commons/types';
import { formatDate } from 'src/commons/utils/DateUtils';
import { formatToCurrency } from 'src/commons/utils/MoneyUtilts';
import { capitalizeFirstLetter } from 'src/commons/utils/StringUtils';

import GiveInfoRowDivider from './components/GiveInfoRowDivider';

import * as S from './styles';

type UrlParams = {
  giveId: string;
};

const gutter = 32;

function AdminViewGive(props: RouteComponentProps<UrlParams>) {
  const { history, match } = props;

  const { data: give, refetch: refetchGive } = useGetGiveById({
    giveId: match.params.giveId,
  });

  const { mutateAsync: deleteGiveMutate } = useDeleteGiveMutation();

  useEffect(() => {
    refetchGive();
  }, []);

  function redirectBack() {
    history.goBack();
  }

  function handleDeletIconClick() {
    const isConfirm = window.confirm('Are you sure?');

    if (isConfirm) {
      deleteGive();
    }
  }

  async function deleteGive() {
    document.body.style.cursor = 'wait';

    await deleteGiveMutate(match.params.giveId);

    document.body.style.cursor = 'default';

    window.location.href = routes.ADMIN_GIVES;
  }

  const emptyText = '-';
  const statusText = give?.status
    ? capitalizeFirstLetter(give.status)
    : emptyText;
  const recurringFrequencyText = give?.frequency
    ? capitalizeFirstLetter(String(give?.frequency))
    : emptyText;
  const giveCountText = give?.giveCount || emptyText;
  const giveCountTotalText = give?.giveCountTotal || emptyText;
  const pledgeStartDateText = give?.startDate
    ? formatDate(give.startDate)
    : emptyText;
  const pledgeEndDateText = give?.endDate
    ? formatDate(give.endDate)
    : emptyText;
  const recurringGivesCountAndDuration = `${giveCountText}/${giveCountTotalText} gives from ${pledgeStartDateText} - ${pledgeEndDateText}`;
  const giveDateText = give?.giveDate ? formatDate(give.giveDate) : emptyText;
  const donorText = give?.donorName || emptyText;
  const platformText = give?.platformName || emptyText;
  const donationText = getDonationText(give);
  const taxDeductibleText = give?.taxDeductible
    ? capitalizeFirstLetter(give.taxDeductible)
    : emptyText;
  const tipText = give?.splitAmount
    ? formatToCurrency(give?.splitAmount)
    : emptyText;
  const tipTaxDeductible = give?.splitTaxDeductible
    ? capitalizeFirstLetter(give?.splitTaxDeductible)
    : emptyText;
  const noteText = give?.detailEntry?.trim() || emptyText;

  return (
    <>
      <AdminLayout>
        <S.Container>
          <Box margin="0 0 15px 0">
            <Button type="variant1" onClick={redirectBack}>
              <LeftOutlined />
              Gives Dashboard
            </Button>
          </Box>
          <Row gutter={[gutter, 0]}>
            <Col>
              <S.Receipt
                body={give?.htmlBody ?? ''}
                className="receipt"
              ></S.Receipt>
            </Col>
            <Col flex="auto">
              <RowDivider justify="space-between">
                <Col>
                  <S.FieldTitle>Recipient</S.FieldTitle>
                  <S.RecipientName type="body1reg2">
                    {give?.recipientName}
                  </S.RecipientName>
                </Col>
                <Col>
                  <Flex alignItems="center" justifyContent="center">
                    <Box margin="0 8px 0 0">
                      <Link
                        to={routes.ADMIN_EDIT_GIVE.replace(
                          /:giveId/,
                          give?.id || ''
                        )}
                      >
                        <Button disabled={!give?.id} type="primary">
                          <EditOutlined />
                          Edit
                        </Button>
                      </Link>
                    </Box>
                    <Button type="secondary" onClick={handleDeletIconClick}>
                      <DeleteOutlined />
                    </Button>
                  </Flex>
                </Col>
              </RowDivider>
              <GiveInfoRowDivider
                title={<S.FieldTitle>Status</S.FieldTitle>}
                value={<S.FieldValue>{statusText}</S.FieldValue>}
              />
              <GiveInfoRowDivider
                title={<S.FieldTitle>Donor</S.FieldTitle>}
                value={<S.FieldValue>{donorText}</S.FieldValue>}
              />
              <GiveInfoRowDivider
                title={<S.FieldTitle>Give Date</S.FieldTitle>}
                value={<S.FieldValue>{giveDateText}</S.FieldValue>}
              />
              <GiveInfoRowDivider
                title={<S.FieldTitle>Platform</S.FieldTitle>}
                value={<S.FieldValue>{platformText}</S.FieldValue>}
              />
              <GiveInfoRowDivider
                title={<S.FieldTitle>Recurring</S.FieldTitle>}
                value={
                  <>
                    <S.FieldValue>{recurringFrequencyText}</S.FieldValue>
                    <S.FieldSubValue>
                      {recurringGivesCountAndDuration}
                    </S.FieldSubValue>
                  </>
                }
              />
              <GiveInfoRowDivider
                title={
                  <>
                    <S.FieldTitle>Donation</S.FieldTitle>
                    <S.FieldTitle>Tax Deductible</S.FieldTitle>
                  </>
                }
                value={
                  <>
                    <S.FieldValue>{donationText}</S.FieldValue>
                    <S.FieldValue>{taxDeductibleText}</S.FieldValue>
                  </>
                }
              />
              <GiveInfoRowDivider
                title={
                  <>
                    <S.FieldTitle>Tip</S.FieldTitle>
                    <S.FieldTitle>Tax Deductible</S.FieldTitle>
                  </>
                }
                value={
                  <>
                    <S.FieldValue>{tipText}</S.FieldValue>
                    <S.FieldValue>{tipTaxDeductible}</S.FieldValue>
                  </>
                }
              />
              <GiveInfoRowDivider
                title={<S.FieldTitle>Note</S.FieldTitle>}
                value={<S.FieldValue>{noteText}</S.FieldValue>}
              />
            </Col>
          </Row>
        </S.Container>
      </AdminLayout>
    </>
  );
}

function getDonationText(give: Give | undefined) {
  if (give?.amount === undefined || give.amount === null) {
    return '-';
  }

  if (give.unit === GIVE_UNIT.DOLLARS) {
    return formatToCurrency(give.amount);
  }

  return `${give.amount} hours`;
}

export default AdminViewGive;
