import { Form } from 'antd';
import { FormInstance } from 'antd/es/form';
import React from 'react';

import { NumberRangeInput } from 'src/client/components';
import {
  DonorsFilterInput,
  FilterType,
  GiveFilterInput,
  PlatformFilterInput,
  RecipientFilterInput,
} from 'src/commons/constants/filterInputs';

import Select from '../Select';
import Text from '../Text';

import * as S from './styles';

type SelectInputOptions = { value: string; label: string }[];
type FilterInput<T> = {
  key: T;
  label: string;
  filterType: FilterType;
  selectOptions?: SelectInputOptions;
  placeholder?: string;
}[];

export type FilterInputEnums =
  | GiveFilterInput
  | RecipientFilterInput
  | PlatformFilterInput
  | DonorsFilterInput;
export type FilterInputs = FilterInput<FilterInputEnums>;
export type MultiFilterFormValue = Record<FilterInputEnums, any> & {
  filterType: FilterInputEnums;
};

type Props = {
  filterInputs: FilterInputs;
  form: FormInstance<MultiFilterFormValue>;
  initialFilterType: FilterInputEnums;
  initialValues: MultiFilterFormValue;
  onClearFilterAndSort: () => void;
  onValuesChange?: (changedValues: any, allValues: any) => void;
};

const { Option } = Select;

function MultiFilter(props: Props) {
  const {
    filterInputs,
    form,
    initialValues,
    initialFilterType,
    onClearFilterAndSort,
    onValuesChange,
  } = props;

  const filterSelectOptions = filterInputs.map((filterInput) => (
    <Option key={filterInput.key} value={filterInput.key}>
      {filterInput.label}
    </Option>
  ));

  function renderFilterInput(formInstance: FormInstance<any>) {
    let filterInputElement: React.ReactNode;
    const filterType = formInstance.getFieldValue(
      'filterType'
    ) as FilterInputEnums;

    const filterInput = filterInputs.find(
      (filterInput) => filterInput.key === filterType
    );

    switch (filterInput?.filterType) {
      case FilterType.TEXT:
        filterInputElement = (
          <S.StyledInput placeholder={filterInput.placeholder} size="large" />
        );
        break;
      case FilterType.DATE_RANGE:
        filterInputElement = <S.StyledRangePicker />;
        break;
      case FilterType.SELECT:
        filterInputElement = (
          <S.StyledSelect
            options={filterInput.selectOptions}
            placeholder={filterInput.placeholder}
          />
        );
        break;
      case FilterType.NUMBER_RANGE:
        return <NumberRangeInput label="" name={filterInput.key} />;
    }

    return <Form.Item name={filterInput?.key}>{filterInputElement}</Form.Item>;
  }

  return (
    <S.Container>
      <Form
        form={form}
        initialValues={
          initialValues.filterType
            ? initialValues
            : { ...initialValues, filterType: initialFilterType }
        }
        preserve={false}
        onValuesChange={onValuesChange}
      >
        <S.ControlsContainer>
          <S.SelectionContainer>
            <Text type="body1med2">Filter</Text>
            <Form.Item name="filterType">
              <S.FilterSelect>{filterSelectOptions}</S.FilterSelect>
            </Form.Item>
            <S.FilterInputContainer>
              <Form.Item
                noStyle
                shouldUpdate={(prevValue, currentValue) =>
                  prevValue.filterType !== currentValue.filterType
                }
              >
                {renderFilterInput}
              </Form.Item>
            </S.FilterInputContainer>
          </S.SelectionContainer>
          <S.ClearButton type="variant1" onClick={onClearFilterAndSort}>
            <Text type="link">Clear filters & sorting</Text>
          </S.ClearButton>
        </S.ControlsContainer>
      </Form>
    </S.Container>
  );
}

export default MultiFilter;
