import { useMutation } from 'react-query';

import {
  bulkDeleteFiles,
  deleteFile,
  uploadFile,
  uploadPageFooterImage,
  uploadPageGalleryCustomImage,
  uploadPlatformImage,
  uploadRecipientImage,
} from 'src/client/api/uploadsApi';

type Options<Response> = {
  onSuccess?: (data: Response) => void;
};

export function useUploadFileMutation() {
  return useMutation(uploadFile);
}

export function useUploadPlatformImageMutation() {
  return useMutation(uploadPlatformImage);
}

export function useUploadRecipientImageMutation(options?: Options<any>) {
  return useMutation(uploadRecipientImage, options);
}

export function useUploadPageFooterImageMutation(options?: Options<any>) {
  return useMutation(uploadPageFooterImage, options);
}

export function useUploadPageCustomImageMutation(options?: Options<any>) {
  return useMutation(uploadPageGalleryCustomImage, options);
}

export function useDeleteFileMutation(options?: Options<any>) {
  return useMutation(deleteFile, options);
}

export function useBulkDeleteFilesMutation(options?: Options<any>) {
  return useMutation(bulkDeleteFiles, options);
}
