import React from 'react';

import { PageRecipient } from 'src/commons/types';

import RecipientLogo from '../RecipientLogo';

import * as S from './styles';

type Props = {
  pageRecipient: PageRecipient;
} & React.HTMLAttributes<HTMLDivElement>;

function FeaturedCard(props: Props) {
  const { pageRecipient, ...containerProps } = props;

  return (
    <S.Container {...containerProps}>
      <S.Card>
        <RecipientLogo
          pageRecipient={pageRecipient}
          pageRecipientLogoReplacementTextType="h1bold2"
        />
      </S.Card>
      <S.StyledText type="caption4">{pageRecipient.recipientName}</S.StyledText>
    </S.Container>
  );
}

export default FeaturedCard;
