import styled from 'styled-components';

import { FlexCenter } from 'src/client/components/StyledCommon';

type ItemImageProps = {
  backgroundUrl: string;
};

const defaultRecipientBg = '/recipientBg.png';

export const Container = styled.div`
  position: relative;
  padding: 80px 0;
  text-align: center;

  .title {
    display: none;
    margin-top: 30px;
  }
`;

export const ItemImage = styled(FlexCenter)<ItemImageProps>`
  width: 100%;
  height: auto;
  aspect-ratio: 3 / 2;

  background-image: ${(props) =>
    props.backgroundUrl !== defaultRecipientBg
      ? `url(${props.backgroundUrl})`
      : null};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  border-radius: 20px;
`;

export const ItemContainer = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.darkSurface3};
`;
