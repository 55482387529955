import React from 'react';

import * as S from './styles';

export default function Movement() {
  return (
    <S.StyledMovement>
      <S.Title type="h2bold2">Movements</S.Title>
      <S.Caption type="body1reg2">
        See a timeline of all you do. Allow yourself moments of remembrance and
        reflection around your giving commitments
      </S.Caption>
      <S.ComingSoon type="body2med2">Coming Soon</S.ComingSoon>
    </S.StyledMovement>
  );
}
