export enum Entity {
  ALTERNATE_EMAIL = 'AlternateEmail',
  COUNTER = 'Counter',
  CUSTOM_REGEX = 'CustomRegex',
  DONOR = 'Donor',
  DONOR_ETHNICITY = 'DonorEthnicity',
  DONOR_GENDER = 'DonorGender',
  DONOR_GROUP = 'DonorGroup',
  DONOR_LINKED_ACCOUNT = 'DonorLinkedAccount',
  ETHNICITY = 'Ethnicity',
  FRIENDLY_ID_SLUG = 'FriendlyIdSlug',
  GENDER = 'Gender',
  GIVE = 'Give',
  GIVE_TAG = 'GiveTag',
  GOAL = 'Goal',
  GROUP = 'Group',
  GROUP_GIVE = 'GroupGive',
  GROUP_RECIPIENT = 'GroupRecipient',
  IMAGE = 'Image',
  MOMENT = 'Moment',
  ORGANIZATION = 'Organization',
  PAGE = 'Page',
  PAGE_RECIPIENT = 'PageRecipient',
  PERSONAL_PRACTICE = 'PersonalPractice',
  PERSONAL_REFLECTION = 'PersonalReflection',
  PLATFORM = 'Platform',
  PLATFORM_STAT = 'PlatformStat',
  RECIPIENT = 'Recipient',
  RECIPIENT_STAT = 'RecipientStat',
  STAT_RECALCULATION_BATCH = 'StatRecalculationBatch',
  TAG = 'Tag',
  USER = 'User',
}
