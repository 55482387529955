import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { Text } from 'src/client/components';
import defaultTheme from 'src/client/themes/default';

function SuccessMessage() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <div data-cy="success-toast">
        <PartyPopperIcon src="/party-popper.png" />
        <Text type="body1med2">Changes saved</Text>
      </div>
    </ThemeProvider>
  );
}

export const PartyPopperIcon = styled.img`
  margin: 0 auto 15px;
  width: 40px;
`;

export default SuccessMessage;
