import React from 'react';

import Button from 'src/client/components/Button';

import { Box, FlexCenter } from 'src/client/components/StyledCommon';

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  confirmButtonProps?: Omit<React.ComponentProps<typeof Button>, 'type'>;
};

function ActionButtons(props: Props) {
  const { onCancel, onConfirm, confirmButtonProps } = props;

  return (
    <FlexCenter>
      <Box margin="0 16px 0 0">
        <Button
          data-cy="set-goal-btn"
          type="primary"
          onClick={onConfirm}
          {...confirmButtonProps}
        >
          Set Goal
        </Button>
      </Box>
      <Button type="secondary" onClick={onCancel}>
        Cancel
      </Button>
    </FlexCenter>
  );
}

export default ActionButtons;
