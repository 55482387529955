import React from 'react';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { Box, Button, Text } from 'src/client/components';
import routes from 'src/commons/constants/routes';

export default function PageNotFound() {
  const { colors } = useTheme();

  return (
    <Container>
      <Logo src="/logo-white.png" />
      <Ornament src="/ornament14.png"></Ornament>
      <Content>
        <Text color={colors.teal1} type="h5bold2">
          404 ERROR
        </Text>
        <Box margin="24px 0px 40px 0">
          <Text color={colors.white} type="h1bold2">
            Oops, something went wrong. Sorry about that.
          </Text>
        </Box>
        <Link to={routes.ROOT}>
          <Button type="primary">Back to home</Button>
        </Link>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.midnight7};
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Content = styled.div`
  margin: 16px;
  max-width: 600px;
`;

const Logo = styled.img`
  position: absolute;
  margin: 14px 0 0 16px;
  height: 22px;
  top: 0;
  left: 0;
`;

const Ornament = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: ${(props) => props.theme.size.mobileL}) {
    display: none;
  }
`;
