import styled from 'styled-components';

import { Button } from 'src/client/components';
import { BUTTON_2_2 } from 'src/client/constants/text';

export const RecipientWrapper = styled.div`
  flex-grow: 1;
`;

export const AddRecipientButton = styled(Button)`
  & > span {
    ${BUTTON_2_2}
  }
`;
