import { QueryClient, useQuery } from 'react-query';

import {
  getAllPlatforms,
  getPlatformByIds,
  getPlatforms,
} from 'src/client/api/PlatformApi';
import { Platform, PlatformFilter } from 'src/commons/types';

const GET_PLATFORM_BY_IDS_KEY = 'GET_PLATFORM_BY_IDS_KEY';
const GET_ALL_PLATFORMS_KEY = 'GET_ALL_PLATFORMS_KEY';
const GET_ALL_PLATFORMS = 'GET_ALL_PLATFORMS';

type UpdateGetPlatfromsByIdsParams = {
  queryClient: QueryClient;
  data: Platform;
  platformIds: string[];
};

export function useGetPlatformByIds(platformIds: string[] | undefined) {
  return useQuery(
    [GET_PLATFORM_BY_IDS_KEY, platformIds],
    () => getPlatformByIds(platformIds as string[]),
    {
      enabled: !!platformIds?.length,
    }
  );
}

export function useGetAllPlatforms() {
  return useQuery([GET_ALL_PLATFORMS_KEY], getAllPlatforms);
}

export function useGetPlatforms(params?: PlatformFilter) {
  return useQuery([GET_ALL_PLATFORMS, params], () => getPlatforms(params));
}

export function updateGetPlatformByIdsQueryData(
  params: UpdateGetPlatfromsByIdsParams
) {
  const { queryClient, data, platformIds } = params;

  queryClient.setQueryData<Platform[] | undefined>(
    [GET_PLATFORM_BY_IDS_KEY, platformIds],
    (old) => {
      if (!old) {
        return undefined;
      }

      const updatePlatform = old?.map((platform) => {
        if (platform.id === data.id) {
          return {
            ...platform,
            ...data,
          };
        }

        return platform;
      });

      return {
        ...old,
        data: updatePlatform,
      };
    }
  );
}
