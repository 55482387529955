import { customAlphabet } from 'nanoid';
import slugifyLib from 'slugify';

import {
  NON_DIGIT_EXCEPT_PERIOD,
  REGEX_HTTPS,
  REGEX_MULTIPLE_SPACES,
  REGEX_NEWLINE,
  REGEX_NEWLINE_INSENSITIVE,
  REGEX_REMOVE_SPECIFIC_SYMBOLS,
  REGEX_SPLIT_SPECIFIC_SYMBOLS,
  REGEX_WHITE_SPACE,
} from '../../commons/constants/regex';
import TextInputLengths from '../../commons/constants/textInputLengths';

export function toTrimmedString(str: string | undefined) {
  return str?.trim() || '';
}

export function slugify(str: string) {
  return slugifyLib(toTrimmedString(str).toLowerCase());
}

export function zeroPad(num: number, size: number) {
  let newNum = num.toString();

  while (newNum.length < size) {
    newNum = '0' + num;
  }

  return newNum;
}

export function generateSearchTagsFromString(str: string): string[] {
  const trimmedStr = str.trim();
  const minimumCharacters = TextInputLengths.SEARCH.MIN;
  const strLower = trimmedStr.toLowerCase() || '';
  const splitted = strLower.split(' ').filter(Boolean);
  const keywords: string[] = [];

  splitted.forEach((word) => {
    for (let i = minimumCharacters; i <= word.length; i += 1) {
      const keyword = word.substring(0, i);

      keywords.push(keyword);
    }
  });

  return [...new Set(keywords)].filter(Boolean);
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function removeNonDigitExceptPeriod(text: string) {
  return text.replace(NON_DIGIT_EXCEPT_PERIOD, '');
}

export function removeSpecificSymbols(text: string) {
  return text.replace(REGEX_REMOVE_SPECIFIC_SYMBOLS, ' ');
}

export function toTitleCase(string: string) {
  return string
    .trim()
    .split(' ')
    .filter(Boolean)
    .map((word) => capitalizeFirstLetter(word))
    .join(' ');
}

export function separateByWords(string: string) {
  return string
    .trim()
    .replace(REGEX_NEWLINE_INSENSITIVE, ' ')
    .split(' ')
    .filter(Boolean);
}

export function separateBySymbolsAndWords(string: string) {
  return string
    .trim()
    .replace(REGEX_NEWLINE_INSENSITIVE, ' ')
    .split(REGEX_SPLIT_SPECIFIC_SYMBOLS)
    .filter(Boolean);
}

export function separateByNextLine(string: string) {
  return string.trim().split(REGEX_NEWLINE_INSENSITIVE).filter(Boolean);
}

export function normalize(str: string | null | undefined) {
  return (
    str
      ?.replace(REGEX_NEWLINE, ' ')
      .replace(REGEX_WHITE_SPACE, ' ')
      .replace(REGEX_MULTIPLE_SPACES, ' ')
      .toUpperCase()
      .trim() || ''
  );
}

const alphabetIdToUse =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
const customIdLength = 10;
const customNanoid = customAlphabet(alphabetIdToUse, customIdLength);

export function uniquifyFileName(fileName: string) {
  const splittedName = fileName.split('.');

  if (splittedName.length === 0) {
    throw new Error("Attached file doesn't have file type");
  }

  return [
    `${splittedName[0]}_${customNanoid()}`,
    ...splittedName.slice(1),
  ].join('.');
}

export function pluralize(word: string, count: number) {
  return count > 1 ? `${word}s` : word;
}

export function replaceBackslashWithForwardSlash(str: string) {
  return str.replace(/\\/g, '/');
}

export function getLastLetter(string: string) {
  return string?.trim()?.slice(-1);
}

export function getFileNameFromPath(path: string) {
  const splittedPath = path.split('/');

  return splittedPath[splittedPath.length - 1];
}

export function getFileNameFromUrl(url: string) {
  const fileName = getFileNameFromPath(url);

  return fileName.split('?')[0];
}

export function getFirstWord(string: string) {
  return toTrimmedString(string).split(' ')[0];
}

export function cleanRecipientUrl(url: string) {
  const utmRegex = /(\?|&)utm_[^&]+/g;
  const trailingSlashRegex = /\/$/;
  const trailingQuestionMarkAndAmpersandRegex = /[?&]$/;
  const emptyString = '';

  if (!url) {
    return url;
  }

  return url
    .replace(REGEX_HTTPS, emptyString)
    .replace(utmRegex, emptyString)
    .replace(trailingQuestionMarkAndAmpersandRegex, emptyString)
    .replace(trailingSlashRegex, emptyString);
}

export function removeNonNumericCharacters(value: string): string {
  if (value === '0') {
    return value;
  }

  return value?.replace(/[^0-9.]/g, '').replace(/^0+/, '');
}

export function removeCommas(string: string): string {
  return string.replace(/,/g, '');
}
