/* eslint-disable sonarjs/cognitive-complexity */
import { LeftOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import React from 'react';
import { useQueryClient } from 'react-query';
import { RouteComponentProps } from 'react-router-dom';

import {
  Box,
  Button,
  PlatformDetailsForm,
  Text,
  Title,
} from 'src/client/components';
import {
  useUpdatePlatformMutation,
  useUploadPlatformImageMutation,
} from 'src/client/hooks/mutations';
import {
  updateGetPlatformByIdsQueryData,
  useGetPlatformByIds,
} from 'src/client/hooks/queries';
import { useGetPlatformImage } from 'src/client/hooks/queries/imageQueries';
import AdminLayout from 'src/client/layouts/AdminLayout';
import routes from 'src/commons/constants/routes';
import { Platform } from 'src/commons/types';
import { removeUndefinedEntries } from 'src/commons/utils/ObjectUtil';

import LoadingPage from '../LoadingPage';
import PageNotFound from '../PageNotFound';

type UrlParams = {
  platformId: string;
};

const { useForm } = Form;

function AdminEditPlatform(props: RouteComponentProps<UrlParams>) {
  const { history } = props;
  const queryClient = useQueryClient();
  const [platformDetailsForm] = useForm();

  const platformId = props.match.params.platformId;

  const { data: platforms, isLoading: isPlatformLoading } = useGetPlatformByIds(
    [platformId]
  );

  const platform = platforms?.[0];

  const { mutateAsync: updatePlatform } = useUpdatePlatformMutation({
    onSuccess: (response) => {
      const { data } = response;
      updateGetPlatformByIdsQueryData({
        data,
        queryClient,
        platformIds: [data.id],
      });
    },
  });

  const { data: platformImage, isLoading: isPlatformImageLoading } =
    useGetPlatformImage(platform?.id);
  const { mutateAsync: uploadPlatformImage } = useUploadPlatformImageMutation();

  async function handlePlatformUpdate() {
    const platformDetailsFormValues = platformDetailsForm.getFieldsValue();

    const dataToUpdate = removeUndefinedEntries({
      id: platformId,
      name: platformDetailsFormValues.name,
      website: platformDetailsFormValues.website,
      platformTypes: platformDetailsFormValues.platformType
        ? [platformDetailsFormValues.platformType]
        : undefined,
      platformCompanyTypes: platformDetailsFormValues.platformCompanyType
        ? [platformDetailsFormValues.platformCompanyType]
        : undefined,
      platformStatusTypes: platformDetailsFormValues.platformStatusType
        ? [platformDetailsFormValues.platformStatusType]
        : undefined,
    });

    const uploadedPlatformImage =
      platformDetailsFormValues.imageUpload?.[0]?.originFileObj;

    if (uploadedPlatformImage) {
      await uploadPlatformImage({
        platformId: platform?.id as string,
        file: uploadedPlatformImage,
      });
    }

    await updatePlatform(dataToUpdate as Platform);

    window.location.href = routes.ADMIN_PLATFORMS;
  }

  function handlePlatformReset() {
    platformDetailsForm.resetFields();
  }

  function goBack() {
    history.goBack();
  }

  if (isPlatformLoading || isPlatformImageLoading) {
    return <LoadingPage />;
  }

  if (!platform) {
    return <PageNotFound />;
  }

  return (
    <>
      <Title title="Edit Platform - Admin" />
      <AdminLayout>
        <Box margin="134px 211px">
          <Button type="variant1" onClick={goBack}>
            <LeftOutlined />
            Back
          </Button>
          <Box margin="22px 0 35px 0">
            <Text type="displayMMed2">Update Platform</Text>
          </Box>
          <Box margin="0 0 26px 0">
            <Text type="h2med2">Platform details</Text>
          </Box>
          <PlatformDetailsForm
            cancelButtonText="Discard Changes"
            confirmButtonText="Save Changes"
            form={platformDetailsForm}
            initialValues={getInitialValue(
              platform,
              platformImage?.platformImagePath
            )}
            onCancel={handlePlatformReset}
            onFinish={handlePlatformUpdate}
          />
        </Box>
      </AdminLayout>
    </>
  );
}

function getInitialValue(
  platform: Platform | undefined,
  platformImageUrl: string | undefined
) {
  return {
    name: platform?.name,
    website: platform?.website,
    platformType: platform?.platformTypes
      ? platform.platformTypes[0]
      : undefined,
    platformCompanyType: platform?.platformCompanyTypes
      ? platform.platformCompanyTypes[0]
      : undefined,
    platformStatusType: platform?.platformStatusTypes
      ? platform.platformStatusTypes[0]
      : undefined,
    imageUpload: platformImageUrl
      ? [
          {
            uid: 1,
            status: 'done',
            url: platformImageUrl,
          },
        ]
      : undefined,
  };
}

export default AdminEditPlatform;
