import styled from 'styled-components';

import { Flex, FlexCenter, LoadingCard, Text } from 'src/client/components';

type RecipientImageProps = {
  logo?: string | null;
};

export const Container = styled.div`
  padding: 28px 16px;
  min-height: 100%;
  height: fit-content;
  background: ${(props) => props.theme.colors.darkSurface2};
  border-radius: 8px;
  color: white;

  @media ${(props) => props.theme.device.laptop} {
    padding: 35px 37px;
  }
`;

export const EmptyScreenContainer = styled(FlexCenter)`
  height: 476px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.darkSurface3};
  flex-direction: column;
`;

export const GiveRowContainer = styled.div`
  padding: 18px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
`;

export const RecipientImageSpinContainer = styled(FlexCenter)`
  margin-right: 20px;
  height: 60px;
  width: 60px;
`;

export const RecipientImage = styled(FlexCenter)<RecipientImageProps>`
  margin-right: 20px;
  height: 41px;
  width: 41px;
  border-radius: 100%;
  background-color: transparent;
  background-image: ${(props) => `url(${props.logo})`};
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  border: 0.8px solid rgba(255, 255, 255, 0.06);

  @media ${(props) => props.theme.device.laptop} {
    height: 60px;
    width: 60px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledLoadingCard = styled(LoadingCard)`
  height: 400px;
`;

export const RecipientNameContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  word-break: break-all;
  white-space: normal;
  min-width: 0;
`;

export const GiveAmountContainer = styled(Flex)`
  margin-left: 15px;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
`;

export const RecipientNameText = styled(Text)`
  word-break: break-word;
`;
