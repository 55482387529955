import styled, { css } from 'styled-components';

import { Text, Input, Table } from 'src/client/components';
import { BODY_2_REGULAR } from 'src/client/constants/text';

export const HeaderTitle = styled(Text)`
  margin-bottom: 32px;
  margin-top: 21px;
`;

export const ButtonText = styled(Text)`
  margin-top: 2px;
`;

const filterBaseStyle = css`
  padding: 8px 12px;
  ${BODY_2_REGULAR};
`;

export const NameInput = styled(Input)`
  width: 400px;
  ${filterBaseStyle};
`;

export const StyledTable = styled(Table)`
  margin-top: 46px;
`;

export const Container = styled.div`
  padding: 122px 105px;
  width: 100%;

  & > div:first-child {
    margin-bottom: 32px;
  }
`;

export const ActionsContainer = styled.div`
  svg {
    color: ${(props) => props.theme.colors.teal2};
  }
`;
