import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useAppcues } from 'src/client/hooks/useAppcues';
import { useSegmentIdentify } from 'src/client/hooks/useIdentify';
import { ACTIONS, SUBJECTS } from 'src/commons/constants/abilities';
import routes from 'src/commons/constants/routes';

import { useAbilityContext } from '../../contexts/AbilityContext';

import AdminDonorRoutes from './AdminDonorRoutes';
import AdminRecipientRoutes from './AdminRecipientRoutes';
import AdminRoutes from './AdminRoutes';
import DonorPersonalRoutes from './DonorPersonalRoutes';
import RecipientPersonalRoutes from './RecipientPersonalRoutes';

export default function AuthorizedRoutes() {
  useAppcues();
  useSegmentIdentify();

  const ability = useAbilityContext();

  const donorPersonalRoutes = ability.can(
    ACTIONS.MANAGE,
    SUBJECTS.DONOR_PERSONAL_PAGE
  ) && <Route component={DonorPersonalRoutes} path={routes.ROOT} />;

  const adminDonorPagesRoutes = ability.can(
    ACTIONS.MANAGE,
    SUBJECTS.ADMIN_DONOR_PAGE
  ) && <Route component={AdminDonorRoutes} path={routes.ADMIN_DONOR_ROOT} />;

  const adminRecipientRoutes = ability.can(
    ACTIONS.MANAGE,
    SUBJECTS.ADMIN_RECIPIENT_PAGE
  ) && (
    <Route
      component={AdminRecipientRoutes}
      path={routes.ADMIN_RECIPIENT_ROOT}
    />
  );

  const adminPagesRoutes = ability.can(ACTIONS.MANAGE, SUBJECTS.ADMIN_PAGE) && (
    <AdminRoutes />
  );

  const recipientPersonalRoutes = ability.can(
    ACTIONS.MANAGE,
    SUBJECTS.RECIPIENT_PERSONAL_PAGE
  ) && (
    <Switch>
      <Route exact component={RecipientPersonalRoutes} path={routes.ROOT} />
    </Switch>
  );

  return (
    <Switch>
      {adminDonorPagesRoutes}
      {adminRecipientRoutes}
      {adminPagesRoutes}
      {donorPersonalRoutes}
      {recipientPersonalRoutes}
    </Switch>
  );
}
