/* eslint-disable no-magic-numbers */
import { rem } from 'polished';
import styled from 'styled-components';

import { Button, Flex } from 'src/client/components';

import GiveCard from '../GiveCard';

export const CardsContainer = styled(Flex)`
  flex-wrap: wrap;
  justify-content: center;

  margin-top: -1px;
  margin-left: -1px;
`;

export const StyledGiveCard = styled(GiveCard)`
  border: 0;
  margin: 0;

  outline: 1px solid ${(props) => props.theme.colors.gray6};
  margin-top: 1px;
  margin-left: 1px;

  &:hover {
    text-decoration: ${(props) => (props.onClick ? 'underline' : 'none')};
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  }
`;

export const VisibilityControlButton = styled(Button)`
  && {
    margin-top: 32px;
    padding: 16px 26px;
    color: black;
    background: transparent;
    border: 1px solid ${(props) => props.theme.colors.gray6};
    border-radius: 0;

    span {
      text-align: center;
      font-family: ${(props) => props.theme.fontFamilies.vollkorn};
      font-size: ${rem('32px')};
      font-style: italic;
      line-height: 130%; /* 41.6px */
      text-transform: uppercase;
    }

    @media ${(props) => props.theme.screen.md} {
      margin-top: 80px;
    }
  }
`;
