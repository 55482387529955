import { Line as AntdLine } from '@ant-design/charts';
import React from 'react';

type Props = React.ComponentProps<typeof AntdLine>;

function Lines(props: Props) {
  return <AntdLine smooth padding="auto" {...props} />;
}

export default Lines;
