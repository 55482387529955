import {
  DashboardOutlined,
  HeartOutlined,
  IdcardOutlined,
  ContainerOutlined,
  UserOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { logout } from 'src/client/api/AuthApi';
import { AdminSider, Flex, UserToolbar } from 'src/client/components';
import { MenuSiderConfig } from 'src/client/components/AdminSider';
import DonorSider from 'src/client/components/DonorSider';
import { AccountContext } from 'src/client/contexts/AccountContext';
import { useDonorDashboardGives } from 'src/client/hooks/useDonorDashboardView';
import { useMatchMenuItem } from 'src/client/hooks/useMatchMenuItem';
import YearFilter from 'src/client/pages/DonorDashboard/components/YearFilter';
import routes from 'src/commons/constants/routes';
import { Admin } from 'src/commons/types';

import * as S from './styles';

type Props = {
  children: React.ReactNode | React.ReactNodeArray;
  donorId?: string;
  siderConfig?: MenuSiderConfig[];
  mobileSiderTitle?: string;
};

const adminRouteData = [
  {
    route: routes.ADMIN_DASHBOARD,
    name: 'Dashboard',
    icon: <DashboardOutlined />,
    keys: [routes.ADMIN_DASHBOARD],
  },
  {
    route: routes.ADMIN_GIVES,
    icon: <HeartOutlined />,
    name: 'Gives',
    keys: [routes.ADMIN_GIVES],
  },
  {
    route: routes.ADMIN_RECIPIENTS,
    name: 'Recipients',
    icon: <IdcardOutlined />,
    keys: [routes.ADMIN_RECIPIENTS],
  },
  {
    route: routes.ADMIN_PLATFORMS,
    name: 'Platforms',
    icon: <ContainerOutlined />,
    keys: [routes.ADMIN_PLATFORMS],
  },
  {
    route: routes.ADMIN_DONORS,
    icon: <UserOutlined />,
    name: 'Users',
    keys: [routes.ADMIN_DONORS],
  },
  {
    route: routes.ADMINS,
    icon: <SettingOutlined />,
    name: 'Admins',
    keys: [routes.ADMINS],
  },
];

function AdminLayout(props: Props) {
  const { children, donorId, siderConfig, mobileSiderTitle } = props;

  const screens = useBreakpoint();
  const { getMatchedMenuItem } = useMatchMenuItem();
  const admin = useContext(AccountContext) as Admin;
  const history = useHistory();
  const [isSideMenuCollapsed, setSideMenuCollapsed] = useState<boolean>(true);

  const matchedMenuItem = getMatchedMenuItem(adminRouteData);
  const selectedKeys = matchedMenuItem ? [matchedMenuItem] : undefined;

  const { dashboardView, switchDashboardView } = useDonorDashboardGives(
    donorId as string,
    false
  );

  const currentRoute = history.location.pathname;
  const isInAdminDonorPages = currentRoute.includes(
    routes.ADMIN_DONOR_ROOT.replace(':donorId', '')
  );

  async function handleClickSignout() {
    await logout();

    location.href = routes.ROOT;
  }

  function onYearFilterChange(value: string) {
    switchDashboardView(value);
  }

  function toggleVisibility() {
    setSideMenuCollapsed(!isSideMenuCollapsed);
  }

  function redirectToRoot() {
    history.push(routes.ROOT);
  }

  const toolbarMenu = (
    <Menu>
      <Menu.Item onClick={handleClickSignout}>Logout</Menu.Item>
    </Menu>
  );

  const adminMenuItems = adminRouteData.map((adminItem) => (
    <Menu.Item key={adminItem.route}>
      <S.MenuText key={adminItem.route} to={adminItem.route}>
        {adminItem.name}
      </S.MenuText>
    </Menu.Item>
  ));

  const sideMenuButton = <S.StyledMenuOutlined onClick={toggleVisibility} />;

  const topMenu = screens.lg && (
    <S.StyledMenu _theme="dark" mode="horizontal" selectedKeys={selectedKeys}>
      {adminMenuItems}
    </S.StyledMenu>
  );

  const yearFilter = screens.xs && <S.YearFilterContainer><YearFilter
    className=""
    donorId={donorId as string}
    value={dashboardView}
    onChange={onYearFilterChange}
  /></S.YearFilterContainer>;

  const headerContent = (
    <Flex flexDirection='column' flexGrow={1}>
      <Flex flexDirection='row'>
        <Flex alignItems="center">
          {isInAdminDonorPages && sideMenuButton}
          <S.Logo src="/logo-white.png" onClick={redirectToRoot} />
        </Flex>
        <S.MenuContainer>
          {topMenu}
          <UserToolbar
            profilePhoto="/defaultUserProfile.png"
            toolbarMenu={toolbarMenu}
            userName={admin.firstName}
          />
        </S.MenuContainer>
      </Flex>
      {isInAdminDonorPages && yearFilter}
    </Flex>
  );

  return (
    <S.StyledLayout>
      {/* Header is duplicated to move contents below the fixed header */}
      <S.Header isPositionFixed={false}>{headerContent}</S.Header>
      <S.Header isPositionFixed>{headerContent}</S.Header>
      <Layout>
        {isInAdminDonorPages && (
          <AdminSider
            adminRouteData={adminRouteData}
            collapsed={isSideMenuCollapsed}
            mobileSiderConfig={siderConfig}
            mobileSiderTitle={mobileSiderTitle}
            sider={
              <DonorSider
                collapsed={isSideMenuCollapsed}
                menuItemsConfig={siderConfig}
              />
            }
          />
        )}
        <S.StyledContent>{children}</S.StyledContent>
      </Layout>
    </S.StyledLayout>
  );
}

export default AdminLayout;
