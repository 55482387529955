import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

type ContainerProps = {
  isSpacer: boolean;
};

export const Container = styled(motion.div)<ContainerProps>`
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.midnight7};
  visibility: ${(props) => (props.isSpacer ? 'hidden' : 'visible')};

  .ant-btn-primary {
    padding: 11px 8px;
  }

  .ant-btn-variant2 {
    padding: 11px 12px;
  }

  ${(props) =>
    props.isSpacer
      ? css`
          position: relative;
          visibility: hidden;
        `
      : css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: 100;
        `}
`;

export const Logo = styled.img`
  height: 22px;
  cursor: pointer;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;
