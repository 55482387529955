import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import styled from 'styled-components';

import { useVerifyAlternateEmail } from '../hooks/mutations';

import ActionSuccessPage from './ActionSuccessPage';

import { LoadingPage } from '.';

type UrlParams = {
  token: string;
};

function AlternateEmailVerification(props: RouteChildrenProps<UrlParams>) {
  const { match } = props;

  const [
    isAlternateEmailVerificationSuccess,
    setIsAlternateEmailVerificationSuccess,
  ] = useState(false);

  const {
    mutateAsync: verifyAlternateEmail,
    isLoading: isVerifyAlternateEmailLoading,
  } = useVerifyAlternateEmail();

  useEffect(() => {
    handleVerifyAlternateEmail();
  }, []);

  async function handleVerifyAlternateEmail() {
    try {
      await verifyAlternateEmail(match?.params.token as string);

      setIsAlternateEmailVerificationSuccess(true);
    } catch (error) {
      setIsAlternateEmailVerificationSuccess(false);
    }
  }

  const headerText = isAlternateEmailVerificationSuccess
    ? 'You email has been verified!'
    : 'Failed to verify email address.';

  const subheadingText = isAlternateEmailVerificationSuccess
    ? "We're happy to have you in our community of users."
    : '';

  if (isVerifyAlternateEmailLoading) {
    return <LoadingPage />;
  }

  return (
    <Container>
      <Row justify="center">
        <Col lg={15} sm={15} xl={8} xs={22}>
          <ActionSuccessPage headerText={headerText}>
            {subheadingText}
          </ActionSuccessPage>
        </Col>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
  background: ${(props) => props.theme.colors.midnight7};
`;

export default AlternateEmailVerification;
