import { DollarCircleOutlined, HistoryOutlined } from '@ant-design/icons';
import { Radio, RadioChangeEvent } from 'antd';
import React, { useState } from 'react';

import LoadingAnimation from 'src/client/components/LoadingAnimation';
import { Box, Flex } from 'src/client/components/StyledCommon';
import Text from 'src/client/components/Text';
import StatCard from 'src/client/components/v2/StatCard';
import { getAggregatedData } from 'src/client/utils/GiveUtils';
import { GIVE_UNIT, Give } from 'src/commons/types';
import { formatToCurrency } from 'src/commons/utils/MoneyUtilts';

import * as S from './styles';

type Props = {
  gives: Give[] | undefined;
  isGivesLoading: boolean;
};

export enum CardViewType {
  AMOUNT = 'Amount',
  TIME = 'Time',
}

function ByTheNumbersSection(props: Props) {
  const { gives, isGivesLoading } = props;

  const [cardView, setCardView] = useState(CardViewType.AMOUNT);

  const viewConfig = {
    [CardViewType.AMOUNT]: {
      suffix: 'amount',
      type: GIVE_UNIT.DOLLARS,
      titles: {
        gives: 'Gives',
        engagements: 'Recurring Gives',
        contributed: 'Contributed',
        raised: 'Raised',
        matched: 'Matched',
      },
      formatValue: formatToCurrency,
      hideRaisedAndMatched: false,
    },
    [CardViewType.TIME]: {
      suffix: 'hours',
      type: GIVE_UNIT.HOURS,
      titles: {
        gives: 'Volunteer engagements',
        engagements: 'Recurring Volunteer Engagements',
        contributed: 'Hours Volunteered',
        raised: 'Raised',
        matched: 'Matched',
      },
      formatValue: (value: number) => `${value} hrs`,
      hideRaisedAndMatched: true,
    },
  };

  const currentView = viewConfig[cardView];

  function handleCardViewChange(e: RadioChangeEvent) {
    const value = e.target.value;
    setCardView(value as CardViewType);
  }

  const aggregatedData = gives && getAggregatedData(gives, currentView.type);

  const cardsData = [
    {
      title: currentView.titles.gives,
      value: aggregatedData?.givesCount,
    },
    {
      title: 'Recipients',
      value: aggregatedData?.recipientsCount,
    },
    {
      title: currentView.titles.contributed,
      value: currentView.formatValue(aggregatedData?.givesTotalAmount || 0),
    },
    {
      title: currentView.titles.engagements,
      value: aggregatedData?.recurringGivesCount || 0,
    },
    {
      title: currentView.titles.raised,
      isComingSoon: true,
      hide: currentView.hideRaisedAndMatched,
    },
    {
      title: currentView.titles.matched,
      isComingSoon: true,
      hide: currentView.hideRaisedAndMatched,
    },
  ];

  const cards = cardsData
    .filter((card) => !card.hide)
    .map((cardUiData, i) => (
      <StatCard
        cardViewType={cardView}
        isComingSoon={cardUiData.isComingSoon}
        key={i}
        title={cardUiData.title}
      >
        <Text type="displayMMed2">{cardUiData.value}</Text>
      </StatCard>
    ));

  const averageGiveAmountText = isGivesLoading ? (
    <LoadingAnimation />
  ) : (
    <Text type="h3med2">
      {currentView.formatValue(aggregatedData?.average || 0)}
    </Text>
  );

  const medianGiveAmountText = isGivesLoading ? (
    <LoadingAnimation />
  ) : (
    <Text type="h3med2">
      {currentView.formatValue(aggregatedData?.median || 0)}
    </Text>
  );

  return (
    <S.Container>
      <Flex justifyContent="space-between">
        <Text type="h3bold2">By the Numbers</Text>
        <S.RadioGroup
          buttonStyle="solid"
          value={cardView}
          onChange={handleCardViewChange}
        >
          <Radio.Button value={CardViewType.AMOUNT}>
            <DollarCircleOutlined />
            <S.RadioText>{CardViewType.AMOUNT}</S.RadioText>
          </Radio.Button>
          <Radio.Button value={CardViewType.TIME}>
            <HistoryOutlined />
            <S.RadioText>{CardViewType.TIME}</S.RadioText>
          </Radio.Button>
        </S.RadioGroup>
      </Flex>
      <S.CentralTendencyContainer>
        <Box margin="0 118px 0 0">
          <S.CentralTendencyTitle>
            Average give {currentView.suffix}
          </S.CentralTendencyTitle>
          {averageGiveAmountText}
        </Box>
        <div>
          <S.CentralTendencyTitle>
            Median give {currentView.suffix}
          </S.CentralTendencyTitle>
          {medianGiveAmountText}
        </div>
      </S.CentralTendencyContainer>
      <S.CardsContainer>
        <S.StyledLoadingCard isLoading={isGivesLoading}>
          {cards}
        </S.StyledLoadingCard>
      </S.CardsContainer>
    </S.Container>
  );
}

export default ByTheNumbersSection;
