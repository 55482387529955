import { TimePicker as AntdTimePicker } from 'antd';
import React from 'react';

import * as S from './styles';

type Props = React.ComponentProps<typeof AntdTimePicker>;

function TimePicker(props: Props) {
  return <S.StyledTimePicker {...props} />;
}

export default TimePicker;
