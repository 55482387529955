import styled from 'styled-components';

import Text from '../../../../components/Text';

export const StyledMovement = styled.div`
  padding: 50px;
  width: 100%;
  min-height: 357px;
  background: url('/movement.png');
  background-size: cover;
  border-radius: 8px;
  color: white;
  overflow: auto;
  padding: 89px 24px 30px;
`;

export const ComingSoon = styled(Text)`
  padding: 15px 16px;
  width: fit-content;
  background: white;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.title85};
`;

export const Title = styled(Text)`
  margin: 0 0 8px 0;
`;

export const Caption = styled(Text)`
  margin-bottom: 30px;
`;
