import React from 'react';

import * as S from './styles';

type Props = {
  title: string;
  subtitle?: React.ReactNode;
};

export default function PlatformHeroBanner(props: Props) {
  return (
    <S.CoverContainer coverImage="/platformsDashboardCover.png">
      <S.StyledText breakpoint="100vw" color="#fff" type="displayLMed2">
        {props.title}
      </S.StyledText>
      <S.StyledText breakpoint="min(828px, 100vw)" color="#fff" type="h4med2">
        {props.subtitle}
      </S.StyledText>
    </S.CoverContainer>
  );
}
