import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Title } from 'src/client/components';
import { useGetPlatformByIds } from 'src/client/hooks/queries';
import { useGetPlatformStat } from 'src/client/hooks/queries/platformStatQueries';
import AdminLayout from 'src/client/layouts/AdminLayout';
import { Entity } from 'src/commons/constants/entities';
import { PlatformStat } from 'src/commons/types';

import { PageNotFound } from '..';

import CardsSection from './components/CardsSection';
import GallerySection from './components/GallerySection';
import PlatformFooter from './components/PlatformFooter';
import PlatformHeroBanner from './components/PlatformHeroBanner';

import * as S from './styles';

type UrlParams = {
  platformId: string;
};

type StatData = {
  title: string;
  statisticValue: any;
};

function AdminPlatformDashboard(props: RouteComponentProps<UrlParams>) {
  const { match } = props;

  const { data: platforms } = useGetPlatformByIds([match.params.platformId]);
  const { data: platformStat, isLoading: isPlatformStatLoading } =
    useGetPlatformStat(match.params.platformId);

  const platform = platforms?.[0];
  const platformName = platform ? platform?.name : 'Platform';
  const headerTitle = `${platformName}'s User Insight Dashboard`;
  const headerSubtitle =
    'View and access real-time aggregated data about your customers, specifically who they are as givers and including what causes and organizations they care about and support and which other platforms they give through.';

  function renderPlatformCard(statData: StatData) {
    return (
      <S.PlatformCard
        key={statData.title}
        statisticValue={`${statData.statisticValue}`}
        title={statData.title}
      />
    );
  }

  const { allDonorGivesStatData, donorGivingGoalsStatData, generalStatData } =
    getCardStatData(platformStat);

  const generalStatCards = generalStatData?.map(renderPlatformCard);

  const userGivingGoalsStatCards =
    donorGivingGoalsStatData?.map(renderPlatformCard);

  const allDonorGivesStatCards = allDonorGivesStatData?.map(renderPlatformCard);

  if (!platformStat && !isPlatformStatLoading) {
    return <PageNotFound />;
  }

  return (
    <>
      <Title title={platformName} />
      <AdminLayout>
        <PlatformHeroBanner subtitle={headerSubtitle} title={headerTitle} />
        <S.Content>
          <CardsSection
            isLoading={isPlatformStatLoading}
            title={`Giving through ${platformName}`}
          >
            {generalStatCards}
          </CardsSection>
          <CardsSection
            isLoading={isPlatformStatLoading}
            title="Your User's Giving Goals"
          >
            {userGivingGoalsStatCards}
          </CardsSection>
          <CardsSection
            isLoading={isPlatformStatLoading}
            title="Your Givers Giving to All Causes"
          >
            {allDonorGivesStatCards}
          </CardsSection>
          <GallerySection
            isLoading={isPlatformStatLoading}
            platformStat={platformStat}
            type={Entity.RECIPIENT}
          />
          <GallerySection
            isLoading={isPlatformStatLoading}
            platformStat={platformStat}
            type={Entity.PLATFORM}
          />
        </S.Content>
        <PlatformFooter />
      </AdminLayout>
    </>
  );
}

function getCardStatData(platformStat?: PlatformStat) {
  if (!platformStat) {
    return {};
  }

  const generalStatData = [
    {
      statisticValue: platformStat?.donorCount.toLocaleString(),
      title: 'Users',
    },
    {
      statisticValue: platformStat?.giveCount.toLocaleString(),
      title: 'No. of Gives',
    },
    {
      statisticValue: `$${Math.round(
        platformStat?.givesTotalAmount
      ).toLocaleString()}`,
      title: 'Donated',
    },
    {
      statisticValue: `$${Math.round(
        platformStat?.aveGiveAmount
      ).toLocaleString()}`,
      title: 'Average Gives',
    },
    {
      statisticValue: `$${Math.round(
        platformStat?.medGiveAmount
      ).toLocaleString()}`,
      title: 'Median Give',
    },
    {
      statisticValue: platformStat?.donorsStayAveMonths,
      title: 'Avg. Num. of Months',
    },
  ];

  const donorGivingGoalsStatData = [
    {
      statisticValue: platformStat?.goalsCount.toLocaleString(),
      title: 'Goals Set',
    },
    {
      statisticValue: `$${Math.round(
        platformStat?.aveGoalAmount
      ).toLocaleString()}`,
      title: 'Average Goal',
    },
    {
      statisticValue: `$${Math.round(
        platformStat?.medGoalAmount
      ).toLocaleString()}`,
      title: 'Median Goal',
    },
  ];

  const allDonorGivesStatData = [
    {
      statisticValue: platformStat?.donorGiveCount.toLocaleString(),
      title: 'Number of Gives',
    },
    {
      statisticValue: `$${Math.round(
        platformStat?.donorTotalGiveAmount || 0
      )?.toLocaleString()}`,
      title: 'Total amount given',
    },
    {
      statisticValue: `$${Math.round(
        platformStat?.donorMedGiveAmount || 0
      ).toLocaleString()}`,
      title: 'Median Give',
    },
  ];

  return { generalStatData, donorGivingGoalsStatData, allDonorGivesStatData };
}

export default AdminPlatformDashboard;
