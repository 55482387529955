import React from 'react';
import styled from 'styled-components';

import { FlexCenter } from 'src/client/components';
import { useGetPlatformByIds } from 'src/client/hooks/queries';

type Props = {
  platformId: string;
  platformImagePath: string;
};

function PlatformCard(props: Props) {
  const { platformId, platformImagePath } = props;
  const { data: platforms } = useGetPlatformByIds([platformId]);
  const platform = platforms?.[0];

  return (
    <a
      href={platform?.website ? `https://${platform?.website}` : undefined}
      rel="noreferrer"
      target="_blank"
    >
      <PlatformContainer>
        <PlatformImage src={platformImagePath} />
      </PlatformContainer>
    </a>
  );
}

const PlatformContainer = styled(FlexCenter)`
  height: 86px;
  width: 275px;
  flex-grow: 1;
  background-color: white;
  border-radius: 10px;

  @media ${(props) => props.theme.device.laptop} {
    height: 145px;
    max-width: 290px;
  }
`;

const PlatformImage = styled.img`
  max-height: 80%;
  max-width: 80%;
`;

export default PlatformCard;
