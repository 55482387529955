import React from 'react';

import { Text } from 'src/client/components';

import CounterBadge from 'src/client/pages/DonorDashboard/components/TopGivesSection/components/CounterBadge';

import * as S from './styles';

type Props = {
  giveCount: number;
  recipientName: string;
  giveAmount: number;
  counterBadgeNumber: number;
};

function BottomRecipientCard(props: Props) {
  const { giveCount, recipientName, giveAmount, counterBadgeNumber } = props;

  return (
    <S.Card>
      <CounterBadge count={counterBadgeNumber} />
      <S.CardInformationBody flexDirection="column">
        <Text type="body1reg2">{recipientName}</Text>
        <S.GivesInformation flexDirection="column">
          <Text type="body2reg2">{giveCount} Gives</Text>
          <Text type="body2reg2">
            ${Math.ceil(giveAmount).toLocaleString()} Total
          </Text>
        </S.GivesInformation>
      </S.CardInformationBody>
    </S.Card>
  );
}

export default BottomRecipientCard;
