import { Col, Row } from 'antd';
import React from 'react';

import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Box, Button, Text } from 'src/client/components';
import { GIVING_SIDE_EMAIL } from 'src/client/constants/email';
import BasicLayout from 'src/client/layouts/BasicLayout';

import routes from 'src/commons/constants/routes';

import * as S from './styles';

function AccountCreationInstruction() {
  const { colors } = useTheme();

  return (
    <BasicLayout>
      <S.Container>
        <Row justify="center">
          <Col lg={9} sm={15} xs={22}>
            <Text color={colors.teal1} type="overline">
              CREATE YOUR GIVING SIDE ACCOUNT
            </Text>
            <Box margin="24px 0 0 0" />
            <S.Header color={colors.white}>Start tracking your impact</S.Header>
            <S.BodyText isEmail={false}>
              To create your account, simply forward any giving receipt you have
              available to&nbsp;
              <S.BodyText isEmail>
                <a href={`mailto:${GIVING_SIDE_EMAIL}`}>{GIVING_SIDE_EMAIL}</a>
              </S.BodyText>
              .
              <br />
              <br />
              After that, instructions on how to complete your account will be
              sent to your email.
            </S.BodyText>
            <Box margin="40px 0 0">
              <Link to={routes.ROOT}>
                <Button type="link">Back to Home</Button>
              </Link>
            </Box>
          </Col>
        </Row>
      </S.Container>
    </BasicLayout>
  );
}

export default AccountCreationInstruction;
