import styled from 'styled-components';

import { Text } from 'src/client/components';
import { BODY, BODY_2_REGULAR, H4, SUBTITLE1 } from 'src/client/constants/text';

type BodyTextProps = {
  isEmail: boolean;
};

export const Container = styled.div`
  margin: 101px 0 101px 0;

  @media ${(props) => props.theme.device.tablet} {
    margin: 219px 0 0 0;
  }
`;

export const Header = styled(Text)`
  ${SUBTITLE1}

  @media ${(props) => props.theme.device.tablet} {
    ${H4}
  }
`;

export const BodyText = styled(Text).attrs({
  type: 'body',
}) <BodyTextProps>`
  &, a {
    display: ${(props) => (props.isEmail ? 'inline' : 'block')};
    margin-top: 24px;
    color: ${(props) => (props.isEmail ? props.theme.colors.teal1 : 'white')};
    ${BODY_2_REGULAR};
    cursor: ${(props) => (props.isEmail ? 'pointer' : 'default')};

    @media ${(props) => props.theme.device.tablet} {
      ${BODY}
    }
  }
`;
