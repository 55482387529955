import { QueryClient, useQuery } from 'react-query';

import { getPageRecipients } from 'src/client/api/PageRecipientApi';
import { GetPageRecipientParams } from 'src/client/types/ApiParams';
import { PageRecipient } from 'src/commons/types';

export const GET_PAGE_RECIPIENTS_KEY = 'GET_PAGE_RECIPIENTS_KEY';

type CommonOptions = {
  enabled?: boolean;
};

type UseGetPageRecipientParams = {
  isEditing?: boolean;
} & GetPageRecipientParams;

export function useGetPageRecipients(
  params: UseGetPageRecipientParams,
  options?: CommonOptions
) {
  const { isEditing = false, ...apiParams } = params;

  return useQuery(
    [
      GET_PAGE_RECIPIENTS_KEY,
      {
        ...apiParams,
        isEditing,
      },
    ],
    () => getPageRecipients(apiParams),
    options
  );
}

export function updatePageRecipientsQueryData(
  queryClient: QueryClient,
  data: Partial<PageRecipient>[],
  queryParams: UseGetPageRecipientParams
) {
  const { isEditing = false, ...apiParams } = queryParams;

  queryClient.setQueryData<PageRecipient[] | undefined>(
    [GET_PAGE_RECIPIENTS_KEY, { ...apiParams, isEditing }],
    (old) => {
      if (!old) {
        return undefined;
      }

      return old.map((pageRecipient) => {
        const itemToUpdate = data.find(
          (newPageRecipient) => pageRecipient.id === newPageRecipient.id
        );

        if (itemToUpdate) {
          return {
            ...pageRecipient,
            ...itemToUpdate,
          };
        }

        return pageRecipient;
      });
    }
  );
}

export function setPageRecipientsQueryData(
  queryClient: QueryClient,
  newPageRecipientQueryData: PageRecipient[] | undefined,
  queryParams: UseGetPageRecipientParams
) {
  const { isEditing = false, ...apiParams } = queryParams;

  queryClient.setQueryData<PageRecipient[] | undefined>(
    [GET_PAGE_RECIPIENTS_KEY, { ...apiParams, isEditing }],
    () => newPageRecipientQueryData
  );
}
