import { Modal } from 'antd';
import { rem } from 'polished';
import styled from 'styled-components';

import { Button, Text } from 'src/client/components';
import { minimalisticScrollBar } from 'src/client/components/StyledCommon';

import Shape from '../IntroSection/components/Shape';

type MenuItemProps = {
  isSelected: boolean;
  'data-cy-selected': string;
};

type AdjectiveContainerProps = {
  isPreview: boolean;
};

export const GivingAdjectiveSection = styled.section`
  background: black;
  overflow-x: hidden;
`;

export const GivingAdjectiveContentContainer = styled.div<AdjectiveContainerProps>`
  position: relative;
  width: 100%;
  padding: 0 0 64px;

  > div:first-child {
    margin: 0 auto;
    max-width: 1440px;
  }

  @media ${(props) => props.theme.screen.md} {
    padding: 46px 0;
  }

  @media ${(props) => props.theme.screen.lg} {
    padding: ${(props) => (props.isPreview ? '46px 0 0 0' : '46px 0 0 46px')};
  }
`;

export const EditButton = styled(Button)`
  position: absolute;
  top: 22px;
  right: 22px;

  @media ${(props) => props.theme.screen.md} {
    top: 46px;
    right: 46px;
  }

  @media ${(props) => props.theme.screen.xxl} {
    top: 12px;
    right: 0px;
    position: relative;
  }
`;

export const AdjectiveText = styled(Text)`
  margin: 8px 0 0 0;
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  color: white;
  font-size: ${rem('67px')};
  line-height: ${rem('80px')};

  @media ${(props) => props.theme.screen.md} {
    margin: 30 0 0 0;
    font-size: ${rem('198px')};
    line-height: ${rem('239px')};
  }
`;

export const AdjectiveHeader = styled(Text)`
  font-family: ${(props) => props.theme.fontFamilies.sourceSerif};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  color: white;
  padding: 64px 24px 14px;
  font-size: ${rem('24px')};
  line-height: ${rem('34px')};

  @media ${(props) => props.theme.screen.md} {
    padding: 0 24px;
    font-size: ${rem('40px')};
    line-height: ${rem('56px')};
  }
`;

export const StyledModal = styled(Modal)`
  overflow: hidden;

  ${minimalisticScrollBar};

  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-body {
    border-radius: 20px;
    padding: 24px;
    height: 100%;
  }
`;

export const Container = styled.div`
  margin-top: 24px;
  margin-bottom: 14px;
`;

export const StyledMenuItem = styled.div<MenuItemProps>`
  border-radius: 6px;
  border: 2px solid transparent;
  padding: 10px;
  color: ${(props) => props.theme.colors.darkEmphasis1};
  font-weight: 860;
  font-size: ${rem('32px')};
  line-height: ${rem('39px')};

  background-color: ${(props) =>
    props.isSelected
      ? props.theme.colors.darkPrimary100
      : props.theme.colors.darkEmphasis5};

  &:hover {
    border: 2px solid ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.black};
  }
`;

export const StyledMenu = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  color: ${(props) => props.theme.colors.black};
  height: 310px;
`;

export const SeparatorPickerContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.size.md}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const SeparatorColorSection = styled.section`
  margin-bottom: 24px;

  @media (min-width: ${(props) => props.theme.size.md}) {
    margin-bottom: 0;
  }
`;

export const SeparatorPreviewContainer = styled.div`
  width: 216px;
  padding: 8px;
  border-radius: 8px;

  color: ${(props) => props.theme.colors.darkEmphasis2};
  background: ${(props) => props.theme.colors.gray3};
`;

export const StyledShape = styled(Shape)`
  margin-top: 8px;
  margin-right: 8px;
  width: 33px;
  height: 33px;
  display: inline-block;
`;
