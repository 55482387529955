import React from 'react';

import { LoadingCard, Text } from 'src/client/components';
import CounterBadge from 'src/client/pages/DonorDashboard/components/TopGivesSection/components/CounterBadge';

import * as S from './styles';

type Props = {
  counterBadgeNumber: number;
  coverImageSrc?: string;
  giveAmount: number;
  giveCount: number;
  isCoverImageLoading?: boolean;
  recipientName: string;
};

function TopRecipientCard(props: Props) {
  const {
    counterBadgeNumber,
    coverImageSrc,
    giveAmount,
    giveCount,
    isCoverImageLoading,
    recipientName,
  } = props;

  return (
    <S.Card>
      {isCoverImageLoading ? (
        <LoadingCard />
      ) : (
        <S.CardImage imageSrc={coverImageSrc ?? '/recipientBg.png'} />
      )}
      <S.CardInformationContainer>
        <CounterBadge count={counterBadgeNumber} />
        <S.CardInformationBody flexDirection="column">
          <Text type="body1med2">{recipientName}</Text>
          <S.GivesInformation>
            <Text type="body2reg2">{giveCount.toLocaleString()} Gives</Text>
            <Text type="body2reg2">
              ${Math.ceil(giveAmount).toLocaleString()} total
            </Text>
          </S.GivesInformation>
        </S.CardInformationBody>
      </S.CardInformationContainer>
    </S.Card>
  );
}

export default TopRecipientCard;
