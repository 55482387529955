import { Tabs } from 'antd';
import React, { useState } from 'react';

import { Box, Flex, FlexCenter, Text, Title } from 'src/client/components';
import { useGetRecipientStats } from 'src/client/hooks/queries/RecipientStatQueries';
import useThemeContext from 'src/client/hooks/useTheme';
import { RECIPIENT_HOMEPAGE_TABS } from 'src/commons/constants/tabs';
import { Recipient, RecipientStatYear } from 'src/commons/types';

import { LoadingPage, PageNotFound } from '..';

import FooterNavigation from './components/FooterNavigation';
import GivingAndGoalsTab from './components/GivingAndGoalsTab';
import HowOurPeopleGiveTab from './components/HowOurPeopleGiveTab';
import OurPeopleTab from './components/OurPeopleTab';
import RecipientLogo from './components/RecipientLogo';
import TimelineControlBar from './components/TimelineControlBar';
import UploadLogoModal from './components/UploadLogoModal';

import * as S from './styles';

const { TabPane } = Tabs;

type Props = {
  recipient: Recipient;
};

function RecipientHomepage(props: Props) {
  const { recipient } = props;

  const { colors } = useThemeContext();
  const [isModalLogoUploadVisible, setIsModalLogoUploadVisible] =
    useState(false);
  const [tabKey, setTabKey] = useState(RECIPIENT_HOMEPAGE_TABS.OUR_PEOPLE);
  const [selectedYear, setSelectedYear] = useState<
    number | RecipientStatYear.ALL_TIME
  >(RecipientStatYear.ALL_TIME);

  const { data: recipientStatsResponse, isLoading: isRecipientStatsLoading } =
    useGetRecipientStats({
      filter: { recipientId: recipient.id },
      options: {
        order: {
          property: 'year',
          isDescending: false,
        },
      },
    });

  const recipientStats = recipientStatsResponse?.data;
  const currentRecipientStats = recipientStats?.find(
    (recipientStat) => recipientStat.year === selectedYear
  );

  function hideModalLogoUpload() {
    setIsModalLogoUploadVisible(false);
  }

  function showUploadLogoModal() {
    setIsModalLogoUploadVisible(true);
  }

  function handleTabChange(key: string, shouldScrollWithoutAnimation = false) {
    const originalScrollBehavior =
      document.documentElement.style.scrollBehavior;
    document.documentElement.style.scrollBehavior = 'auto';

    window.scrollTo({
      behavior: shouldScrollWithoutAnimation ? 'auto' : 'smooth',
      top: 0,
    });
    document.documentElement.style.scrollBehavior = originalScrollBehavior;

    setTabKey(key as RECIPIENT_HOMEPAGE_TABS);
  }

  if (isRecipientStatsLoading) {
    return <LoadingPage />;
  }

  if (!recipient || !recipientStats) {
    return <PageNotFound />;
  }

  return (
    <>
      <Title title={recipient.name} />
      <S.FirstSection>
        <S.RadialGradient1 src="/radialGradient1.png" />
        <S.RadialGradient2 src="/radialGradient2.png" />
        <S.HeaderContainer>
          <Box margin="71px 0 0 0">
            <FlexCenter>
              <RecipientLogo
                recipientId={recipient.id}
                onClick={showUploadLogoModal}
              />
              <Text color={colors.white} type="body2reg2">
                {recipient.name}
              </Text>
            </FlexCenter>
          </Box>
          <Flex alignItems="center" flexDirection="column">
            <div>
              <Box margin="191px 0 24px 0">
                <Flex>
                  <Text color={colors.white} type="h5">
                    Our
                  </Text>
                  <Box margin="-5px 0 0 10px">
                    <S.Logo src="/logo-white.png" />
                  </Box>
                </Flex>
              </Box>
              <Text color={colors.white} type="displayLBold2">
                Dashboard
              </Text>
            </div>
          </Flex>
          <Box margin="60px 0 0 0">
            <FlexCenter>
              <TimelineControlBar
                recipientStats={recipientStats}
                selectedYear={selectedYear}
                onChange={setSelectedYear}
              />
            </FlexCenter>
          </Box>
        </S.HeaderContainer>
      </S.FirstSection>
      <S.StyledTabs centered activeKey={tabKey} onChange={handleTabChange}>
        <TabPane
          key={RECIPIENT_HOMEPAGE_TABS.OUR_PEOPLE}
          tab={<Text type="buttonMedium">Our People</Text>}
        >
          <OurPeopleTab currentRecipientStat={currentRecipientStats} />
        </TabPane>
        <TabPane
          key={RECIPIENT_HOMEPAGE_TABS.GIVING_AND_GOAL}
          tab={<Text type="buttonMedium">Giving & Goals</Text>}
        >
          <GivingAndGoalsTab currentRecipientStat={currentRecipientStats} />
        </TabPane>
        <TabPane
          key={RECIPIENT_HOMEPAGE_TABS.HOW_OUR_PEOPLE_GIVE}
          tab={<Text type="buttonMedium">How Our People Give</Text>}
        >
          <HowOurPeopleGiveTab currentRecipientStat={currentRecipientStats} />
        </TabPane>
      </S.StyledTabs>
      <FooterNavigation
        currentTab={tabKey}
        recipientId={recipient.id}
        onTabChange={handleTabChange}
      />
      <UploadLogoModal
        recipient={recipient}
        visible={isModalLogoUploadVisible}
        onCancel={hideModalLogoUpload}
        onOk={hideModalLogoUpload}
      />
    </>
  );
}

export default RecipientHomepage;
