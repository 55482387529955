import { Select } from 'antd';
import styled from 'styled-components';

import { BODY_2_REG_2 } from 'src/client/constants/text';

export const StyledSelect = styled(Select)`
  .ant-select-selection-search-input,
  && .ant-select-selection-placeholder,
  && .ant-select-selection-item {
    ${BODY_2_REG_2}
    line-height: ${(props) => props.theme.input.middle.height};
  }

  && .ant-select-selector {
    padding: 0 8px;
    height: ${(props) => props.theme.input.middle.height};
    border-radius: ${(props) => props.theme.input.middle.borderRadius};
    border: 1px solid ${(props) => props.theme.colors.darkEmphasis3};
  }

  &&:hover .ant-select-selector {
    box-shadow: none;
    border: 1px solid black;
    border-color: black;
  }

  && .ant-select-selection-search {
    height: ${(props) => props.theme.input.middle.height};
    left: 8px;
  }

  &.ant-select-focused:not(&.ant-select-disabled)&.ant-select:not(
      &.ant-select-customize-input
    )
    .ant-select-selector {
    border-color: black;
    box-shadow: none;
  }

  &&.ant-select-lg {
    .ant-select-selector {
      padding: 0 12px;
      height: ${(props) => props.theme.input.large.height};
      border-radius: ${(props) => props.theme.input.large.borderRadius};
    }

    && .ant-select-selection-search {
      height: ${(props) => props.theme.input.large.height};
      left: 12px;
    }
  }
`;
