export enum GivingAdjective {
  DOPE = 'DOPE',
  FRESH = 'FRESH',
  FIERY = 'FIERY',
  FIERCE = 'FIERCE',
  READY = 'READY',
  RESILIENT = 'RESILIENT',
  STRONG = 'STRONG',
  LOUD = 'LOUD',
  LOVE = 'LOVE',
  JUSTICE = 'JUSTICE',
  POWER = 'POWER',
  INSPIRED = 'INSPIRED',
  COURAGEOUS = 'COURAGEOUS',
  GROWING = 'GROWING',
  STARTING = 'STARTING',
  UNWAVERING = 'UNWAVERING',
  AMAZING = 'AMAZING',
  AWESOME = 'AWESOME',
  BLESSED = 'BLESSED',
  INSPIRING = 'INSPIRING',
  INHERITED = 'INHERITED',
  ANCESTRAL = 'ANCESTRAL',
  FABULOUS = 'FABULOUS',
  FAITHFUL = 'FAITHFUL',
  FAMILIAL = 'FAMILIAL',
  STEADY = 'STEADY',
  DEDICATED = 'DEDICATED',
  DETERMINED = 'DETERMINED',
  PERSISTENT = 'PERSISTENT',
  PURPOSEFUL = 'PURPOSEFUL',
  PLAYFUL = 'PLAYFUL',
  HOPEFUL = 'HOPEFUL',
  OPTIMISTIC = 'OPTIMISTIC',
  CATALYTIC = 'CATALYTIC',
  GALVANIZING = 'GALVANIZING',
  ASPIRING = 'ASPIRING',
  JUST = 'JUST',
  EVERYTHING = 'EVERYTHING',
  ME = 'ME',
}
