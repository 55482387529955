import { Grid, Menu } from 'antd';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { logout } from 'src/client/api/AuthApi';
import { Flex } from 'src/client/components';
import { AccountContext } from 'src/client/contexts/AccountContext';
import { useDonorDashboardGives } from 'src/client/hooks/useDonorDashboardView';
import YearFilter from 'src/client/pages/DonorDashboard/components/YearFilter';
import routes from 'src/commons/constants/routes';
import {
  AccountContext as AccountContextType,
  Recipient,
} from 'src/commons/types';

import UserToolbar from '../UserToolbar';

import * as S from './styles';

type Props = {
  className?: string;
  donorId?: string;
  onMenuSiderClick?: () => void;
  isPositionFixed: boolean;
  profileImage?: string;
  shouldShowProfileMenuIten?: boolean;
  shouldShowYearFilter?: boolean;
  shouldShowSiderButton?: boolean;
};

const { useBreakpoint } = Grid;

export default function Navigation(props: Props) {
  const {
    className = '',
    profileImage,
    donorId,
    onMenuSiderClick,
    isPositionFixed,
    shouldShowProfileMenuIten = true,
    shouldShowYearFilter = true,
    shouldShowSiderButton = true,
  } = props;

  const screens = useBreakpoint();
  const account = useContext(AccountContext) as AccountContextType;
  const history = useHistory();

  const { dashboardView, switchDashboardView } = useDonorDashboardGives(
    donorId as string,
    false
  );

  function onChange(value: string) {
    switchDashboardView(value);
  }

  function redirectToProfile() {
    history.push(routes.EDIT_PROFILE);
  }

  function redirectToRoot() {
    history.push(routes.ROOT);
  }

  async function logOut() {
    await logout();
    (window as any).Appcues.reset();

    location.href = routes.ROOT;
  }

  const profileMenuItem = shouldShowProfileMenuIten && (
    <Menu.Item onClick={redirectToProfile}>Profile</Menu.Item>
  );

  const toolbarMenu = (
    <Menu>
      {profileMenuItem}
      <Menu.Item onClick={logOut}>Logout</Menu.Item>
    </Menu>
  );

  const toolbar = account && (
    <UserToolbar
      profilePhoto={profileImage}
      toolbarMenu={toolbarMenu}
      userName={
        account.isRoleRecipient ? undefined : (account as Recipient).name
      }
    />
  );

  const siderButton = shouldShowSiderButton && (
    <S.StyledMenuOutlined onClick={onMenuSiderClick} />
  );

  return (
    <S.Container className={className} isPositionFixed={isPositionFixed}>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          {siderButton}
          <S.Logo src="/logo-white.png" onClick={redirectToRoot} />
        </Flex>
        {toolbar}
      </Flex>
      {shouldShowYearFilter && screens.xs && (
        <S.YearFilterContainer>
          <YearFilter
            className=""
            donorId={donorId as string}
            value={dashboardView}
            onChange={onChange}
          />
        </S.YearFilterContainer>
      )}
    </S.Container>
  );
}
