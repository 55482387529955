import { InputNumber as AntdInputNumber } from 'antd';
import React from 'react';

import styled, { css } from 'styled-components';

import { BODY_2_REG_2 } from 'src/client/constants/text';

type StyledInputNumberProps = {
  size?: React.ComponentProps<typeof AntdInputNumber>['size'];
};

export const StyledInputNumber = styled(
  AntdInputNumber
)<StyledInputNumberProps>`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.darkEmphasis3};
  border-radius: ${(props) =>
    props.size === 'large'
      ? props.theme.input.large.borderRadius
      : props.theme.input.middle.borderRadius};

  &.ant-input-number-affix-wrapper:not(
      .ant-input-number-affix-wrapper-disabled
    ):hover,
  &.ant-input-number-affix-wrapper-focused,
  &.ant-input-number:not(.ant-input-number-affix-wrapper):hover,
  &.ant-input-number-focused {
    border: 1px solid black;
    box-shadow: none;
  }

  &.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled)
    .ant-input-number {
    border: none;
  }

  input,
  .ant-input-number-prefix {
    ${BODY_2_REG_2};
    line-height: 100%;
  }

  .ant-input-number-prefix {
    color: ${(props) => props.theme.colors.darkEmphasis2};
  }

  input {
    height: ${(props) => props.theme.input.middle.height};
  }

  ${(props) =>
    props.size === 'large' &&
    css`
      input {
        height: ${(props) => props.theme.input.large.height};
      }
    `}
`;
