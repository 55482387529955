import React from 'react';

import RecipientLogoBase from 'src/client/pages/DonorGallery/components/RecipientLogo';
import { TextType } from 'src/client/types/Text';
import { PageRecipient } from 'src/commons/types';

import * as stylesheet from './styles';

type Props = {
  pageRecipient: PageRecipient;
  pageRecipientLogoReplacementTextType: TextType;
};

function RecipientLogo(props: Props) {
  return <RecipientLogoBase {...props} styles={stylesheet} />;
}

export default RecipientLogo;
