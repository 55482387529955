import { useHistory } from 'react-router-dom';

import routes from 'src/commons/constants/routes';
import { GIVE_STATUS, Give } from 'src/commons/types';

import { replaceRouteParams } from 'src/commons/utils/RouteUtils';

import { useGetGives } from './queries';

export function useNavigateNewerAndOlderUnprocessedGives(
  give: Give | undefined
) {
  const history = useHistory();

  const { data: olderUnprocessedGiveResponse } = useGetGives({
    beforeDateCreated: give?.dateCreated,
    status: GIVE_STATUS.UNPROCESSED,
    limit: 1,
    order: {
      isDescending: true,
      property: 'dateCreated',
    },
  });
  const { data: newerUnprocessedGiveResponse } = useGetGives({
    afterDateCreated: give?.dateCreated,
    status: GIVE_STATUS.UNPROCESSED,
    limit: 1,
    order: {
      isDescending: false,
      property: 'dateCreated',
    },
  });

  const olderUnprocessedGive = olderUnprocessedGiveResponse?.data[0];
  const newerUnprocessedGive = newerUnprocessedGiveResponse?.data[0];

  function goToOlderUnprocessedGive() {
    history.push(
      replaceRouteParams(routes.ADMIN_EDIT_GIVE, {
        giveId: olderUnprocessedGive?.id,
      })
    );
  }

  function goToNewerUnprocessedGive() {
    history.push(
      replaceRouteParams(routes.ADMIN_EDIT_GIVE, {
        giveId: newerUnprocessedGive?.id,
      })
    );
  }

  return {
    goToNewerUnprocessedGive,
    goToOlderUnprocessedGive,
    newerUnprocessedGive,
    olderUnprocessedGive,
  };
}
