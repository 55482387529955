import {
  Environments,
  deployedEnvironments,
} from 'src/commons/constants/environments';

/* eslint-disable no-undef */
const deploymentEnvironment = {
  ACTUAL_ENV: process.env.ACTUAL_ENV,
  IS_DEPLOYED: deployedEnvironments.includes(
    process.env.ACTUAL_ENV as Environments
  ),
  IS_PROD: process.env.ACTUAL_ENV === Environments.PRODUCTION,
  IS_STAGING: process.env.ACTUAL_ENV === Environments.STAGING,
  IS_DEV: process.env.ACTUAL_ENV === Environments.DEVELOPMENT,
  IS_STAGING_PR: process.env.IS_STAGING_PR === 'true',
};

const CONFIG = {
  ...deploymentEnvironment,
  API_URL: process.env.REACT_APP_API_URL || window.location.origin,
  GOOGLE_ANALYTICS_DASHBOARD:
    'https://analytics.google.com/analytics/web/?authuser=2#/report-home/a71384265w108845449p113441772',
  HEAP_ANALYTICS_DASHBAORD:
    'https://heapanalytics.com/app/env/1285296820/overview',
  SEGMENT_WRITE_KEY: process.env.REACT_APP_SEGMENT_WRITE_KEY,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
};

export default CONFIG;
