import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  Flex,
  StatisticCard,
  Text,
  Title,
} from 'src/client/components';

import configs from 'src/client/configs';
import { useRecalculateAdminDashboardStats } from 'src/client/hooks/mutations';
import { useGetAdminDashboardStats } from 'src/client/hooks/queries';
import AdminLayout from 'src/client/layouts/AdminLayout';
import { FULL_MONTH_DATE, LOCAL_TIME } from 'src/commons/constants/dateFormat';
import routes from 'src/commons/constants/routes';
import { AdminDashboardStatsCount } from 'src/commons/types';
import { formatDate } from 'src/commons/utils/DateUtils';

import { LoadingPage, PageNotFound } from '..';

import * as S from './styles';

function AdminDashboard() {
  const history = useHistory();
  const {
    data: adminDashboardStatsResponse,
    isLoading: isAdminDashboardLoading,
    refetch: refetchAdminDashboardStats,
  } = useGetAdminDashboardStats();
  const {
    mutateAsync: recalculateAdminDashboardStats,
    isLoading: isRecalculateAdminDashboardStatsLoading,
  } = useRecalculateAdminDashboardStats();

  const lastUpdatedText = formatDate(
    adminDashboardStatsResponse?.dateUpdated,
    `${FULL_MONTH_DATE} ${LOCAL_TIME}`
  );
  const formattedDashboardStats = Object.fromEntries(
    Object.entries(adminDashboardStatsResponse?.adminDashboardStats ?? {}).map(
      ([key, value]) => [key, value?.toLocaleString()]
    )
  ) as unknown as AdminDashboardStatsCount;

  function directToAdminGives() {
    history.push(routes.ADMIN_GIVES);
  }

  async function handleRecalculateAdminDashboardStats() {
    if (!isRecalculateAdminDashboardStatsLoading) {
      await recalculateAdminDashboardStats();
      refetchAdminDashboardStats();
    }
  }

  if (isAdminDashboardLoading) {
    return <LoadingPage />;
  }

  if (!formattedDashboardStats) {
    return <PageNotFound />;
  }

  return (
    <AdminLayout>
      <Title title="Dashboard - Admin" />
      <S.CoverContainer coverImage="/adminDashboardCover.png">
        <div>
          <Text type="displayMMed2">Admin Dashboard</Text>
          <Flex margin="10px 0">
            <Text type="body2med2">View other dashboards:&nbsp;</Text>
            <Text type="body2med2">
              <a
                href={configs.GOOGLE_ANALYTICS_DASHBOARD}
                rel="noreferrer"
                target="_blank"
              >
                Google Analytics
              </a>
            </Text>
            <Text type="body2med2">&nbsp;|&nbsp;</Text>
            <Text type="body2med2">
              <a
                href={configs.HEAP_ANALYTICS_DASHBAORD}
                rel="noreferrer"
                target="_blank"
              >
                Heap
              </a>
            </Text>
          </Flex>
          <Flex alignItems="center">
            <Text type="body2med2">As of {lastUpdatedText}</Text>
            <Box margin="0 0 0 11px">
              <S.ReloadIcon
                isLoading={isRecalculateAdminDashboardStatsLoading}
                onClick={handleRecalculateAdminDashboardStats}
              />
            </Box>
          </Flex>
        </div>
        <Button type="primary" onClick={directToAdminGives}>
          Process Gives Now
        </Button>
      </S.CoverContainer>
      <S.AnalyticsContainer>
        <StatisticCard
          statisticValue={`${formattedDashboardStats.taxDeductiblePercentage}%`}
          title="Tax Deductible"
        />
        <StatisticCard
          statisticValue={`${formattedDashboardStats.givesProcessed}`}
          title="Gives Processed"
        />
        <StatisticCard
          statisticValue={`$${formattedDashboardStats.totalGivesProcessedAmount}`}
          title="Amount Processed"
        />
        <StatisticCard
          statisticValue={`${formattedDashboardStats.recurringDonors}`}
          title="Recurring Users"
        />
        <StatisticCard
          statisticValue={`${formattedDashboardStats.platforms}`}
          title="Platforms"
        />
        <StatisticCard
          statisticValue={`${formattedDashboardStats.recipients}`}
          title="Recipients"
        />
        <StatisticCard
          statisticValue={`$${formattedDashboardStats.medianGiveAmount}`}
          title="Median Dollar Gift"
        />
        <StatisticCard
          statisticValue={`$${formattedDashboardStats.averageGiveAmount}`}
          title="Ave. Dollar Gift"
        />
        <StatisticCard
          statisticValue={`${formattedDashboardStats.uniqueDonors}`}
          title="Unique Users"
        />
        <StatisticCard
          statisticValue={`$${formattedDashboardStats.medianTotalPerDonor}`}
          title="Median Total / User"
        />
        <StatisticCard
          statisticValue={`$${formattedDashboardStats.averageTotalPerDonor}`}
          title="Ave. Total / User"
        />
        <StatisticCard
          statisticValue={`${formattedDashboardStats.donorsWithGoal}`}
          title="Donors with Goal"
        />
        <StatisticCard
          statisticValue={`${formattedDashboardStats.totalHourGive} hrs`}
          title="Processed"
        />
        <StatisticCard
          statisticValue={`${formattedDashboardStats.medianHourGive} hrs`}
          title="Median Time Gift"
        />
        <StatisticCard
          statisticValue={`${formattedDashboardStats.averageHourGive} hrs`}
          title="Ave. Time Give"
        />
      </S.AnalyticsContainer>
    </AdminLayout>
  );
}

export default AdminDashboard;
