import { add, isFuture } from 'date-fns';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Text } from 'src/client/components';
import Select from 'src/client/components/v2/Select';
import { US_TAX_DEADLINE } from 'src/client/constants/tax';
import useGetQueryString from 'src/client/hooks/useGetQueryString';
import { useGetYearsSinceFirstGive } from 'src/client/hooks/useGetYearsSinceFirstGive';
import { useSetQueryString } from 'src/client/hooks/useSetQueryString';
import { getCurrentYear } from 'src/commons/utils/DateUtils';

type TaxYearSelectProps = {
  labelPrefix?: string;
};

type Props = {
  donorId: string;
};

function TaxDeductionCover(props: Props) {
  const { donorId } = props;

  const { taxYear } = useGetQueryString();
  const setQueryString = useSetQueryString();
  const { giveYears, isLoading } = useGetYearsSinceFirstGive(donorId, true);

  useEffect(() => {
    if (!isLoading) {
      handleTaxYearChange(getSelectedTaxYear());
    }
  }, [isLoading]);

  function getSelectedTaxYear() {
    if (taxYear) {
      return taxYear;
    }

    const thisYear = getCurrentYear();
    const lastYear = thisYear - 1;
    const hasGivesLastYear = giveYears.includes(lastYear);

    const isTodayBeforeTaxDeadline = isFuture(
      add(US_TAX_DEADLINE, { days: 4 })
    );

    if (hasGivesLastYear && isTodayBeforeTaxDeadline) {
      return lastYear.toString();
    }

    return thisYear.toString();
  }

  function getTaxYearsOptions() {
    return giveYears.map((year) => ({
      value: year.toString(),
      label: year.toString(),
    }));
  }

  function handleTaxYearChange(value: unknown) {
    setQueryString({ taxYear: value as string });
  }

  return (
    <Container>
      <TopSection>
        <CoverTitle type="h2bold2">Tax-Deductible Gives</CoverTitle>
        <TaxYearSelect
          labelPrefix="Tax year:"
          options={getTaxYearsOptions()}
          value={getSelectedTaxYear()}
          virtual={false}
          onChange={handleTaxYearChange}
        />
      </TopSection>
      <DisclaimerText type="body1reg2">
        Disclaimer: Giving Side does not provide tax, legal or accounting
        advice. This material has been prepared for informational purposes only.
        It is not intended to provide, and should not be relied on for, tax,
        legal or accounting advice. You should consult your own tax, legal and
        accounting advisors for filing your taxes.
      </DisclaimerText>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopSection = styled.div`
  display: flex;
  margin: 0 0 19px;
  gap: 8px;
  flex-direction: column;

  @media ${(props) => props.theme.device.tablet} {
    flex-direction: row;
    gap: 28px;
  }
`;

const DisclaimerText = styled(Text)`
  color: ${(props) => props.theme.colors.lightEmphasis2};
`;

const CoverTitle = styled(Text)`
  color: ${(props) => props.theme.colors.white};
`;

const TaxYearSelect = styled(Select)<Partial<TaxYearSelectProps>>`
  position: sticky;
  width: 210px;

  .ant-select-arrow {
    top: 18px;
  }

  && .ant-select-selection-item::before {
    content: ${(props) => `'${props.labelPrefix}'`};
    color: ${(props) => props.theme.colors.teal1};
    padding-right: 5px;
  }
`;

export default TaxDeductionCover;
