import isEmpty from 'lodash/isEmpty';
import { useContext, useEffect, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import routes from 'src/commons/constants/routes';
import { Give, GivesFilter, GIVE_STATUS } from 'src/commons/types';
import { getCurrentYear } from 'src/commons/utils/DateUtils';
import { replaceRouteParams } from 'src/commons/utils/RouteUtils';

import {
  DASHBOARD_VIEW,
  DEFAULT_DASHBOARD_VIEW,
} from '../constants/DonorDashboard';
import { AccountContext } from '../contexts/AccountContext';

import { useGetGives } from './queries';

type RouteParams = {
  view: string | undefined;
};

export function useDonorDashboardGives(
  donorId: string,
  shouldRedirect: boolean = true
) {
  const account = useContext(AccountContext);
  const history = useHistory();

  const personalDashboardRouteMatch = useRouteMatch<RouteParams>(
    routes.DONOR_PERSONAL_DASHBOARD_VIEW
  );
  const adminDonorDashboardRouteMatch = useRouteMatch<RouteParams>(
    routes.ADMIN_DONOR_DASHBOARD_VIEW
  );
  const wasRedirectedToAllViewRef = useRef(false);

  const dashboardView = getDashboardView();

  const isDashboardViewInCurrentYear =
    dashboardView === DASHBOARD_VIEW.THIS_YEAR;
  const giveYear = getGiveYear();

  const giveQuery: GivesFilter = {
    giveYear,
    donorId: donorId,
    status: GIVE_STATUS.PROCESSED,
  };

  const { data: givesResponse, isLoading: isGivesLoading } =
    useGetGives(giveQuery);

  const gives = givesResponse?.data;

  useEffect(() => {
    switchToAllTimeIfCurrentYearIsEmpty(gives);
  }, [gives, isGivesLoading]);

  function getDashboardView() {
    if (personalDashboardRouteMatch?.params.view) {
      return personalDashboardRouteMatch.params.view;
    }

    if (adminDonorDashboardRouteMatch?.params.view) {
      return adminDonorDashboardRouteMatch.params.view;
    }

    return DEFAULT_DASHBOARD_VIEW;
  }

  function getGiveYear() {
    const isDashboardViewSpecificYear = !Number.isNaN(Number(dashboardView));

    if (isDashboardViewInCurrentYear) {
      return String(getCurrentYear());
    } else if (isDashboardViewSpecificYear) {
      return dashboardView;
    }
  }

  function switchToAllTimeIfCurrentYearIsEmpty(gives?: Give[]) {
    if (isGivesLoading) {
      return null;
    }

    const shouldSwitchToAllTime =
      isDashboardViewInCurrentYear &&
      isEmpty(gives) &&
      !wasRedirectedToAllViewRef.current &&
      shouldRedirect;

    if (shouldSwitchToAllTime) {
      switchDashboardView(DASHBOARD_VIEW.ALL_TIME);
      wasRedirectedToAllViewRef.current = true;
    }
  }

  function switchDashboardView(view: string) {
    if (account?.isRoleAdmin) {
      history.push(
        replaceRouteParams(routes.ADMIN_DONOR_DASHBOARD_VIEW, {
          donorId,
          view,
        })
      );
    }

    if (account?.isRoleDonor) {
      history.push(
        replaceRouteParams(routes.DONOR_PERSONAL_DASHBOARD_VIEW, {
          view,
        })
      );
    }
  }

  return {
    dashboardView,
    gives,
    isGivesLoading,
    switchDashboardView,
    giveYear,
  };
}
