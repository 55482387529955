import styled from 'styled-components';

import { Flex, FlexCenter, Text } from 'src/client/components';

export const Container = styled.div`
  color: black;
  min-height: calc(100vh - 50px);

  form {
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-explain-error {
    text-align: left;
  }

  button {
    width: 100%;
  }
`;

export const NavigationBar = styled(Flex)`
  margin-bottom: 100px;
  padding: 16px 40px;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.img`
  width: 121px;
`;

export const Header = styled(Text)`
  text-align: center;
  margin-bottom: 21px;
`;

export const BodyContainer = styled(FlexCenter)`
  margin: 0 auto;
  width: 100%;
  max-width: 407px;
  flex-direction: column;

  a {
    border-bottom: 1px solid black;
    color: black;
  }
`;

export const SignInContainer = styled(Flex)`
  margin: 21px 0 0 0;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  gap: 8px;

  @media ${(props) => props.theme.screen.sm} {
    justify-content: space-between;
    flex-direction: row;
  }
`;
