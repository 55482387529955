import { rem } from 'polished';
import styled from 'styled-components';

import { Text } from 'src/client/components';

type EditingElementsProps = {
  isEditing: boolean;
};

export const GivesSection = styled.section`
  background: #f4f0ec;
`;

export const GivesSectionContentContainer = styled.div<EditingElementsProps>`
  margin: 0 auto 0;
  padding: 22px;
  max-width: 1440px;

  @media ${(props) => props.theme.screen.lg} {
    padding: ${(props) =>
      props.isEditing ? '40px 64px 144px' : '46px 78px 140px'};
  }

  @media ${(props) => props.theme.screen.xxl} {
    padding: 46px 28px 140px;
  }
`;

export const CardsHeader = styled(Text)<EditingElementsProps>`
  position: relative;
  margin: 0 0 40px 0;
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${rem('40px')};
  line-height: ${rem('48px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};

  @media ${(props) => props.theme.screen.md} {
    margin: ${(props) => (props.isEditing ? '0 0 24px 0' : '0 0 70px 0')};
    font-size: ${rem('122px')};
    line-height: ${rem('147px')};
  }
`;

export const Divider = styled.hr`
  margin: 80px 0;
  border-top: 1px solid black;

  @media ${(props) => props.theme.screen.md} {
    margin: 214px 0 148px 0;
  }
`;

export const ArrowGuide2 = styled.img`
  position: absolute;
  top: -320px;
  left: 120px;
  max-width: 450px;
  height: 324px;
  display: none;

  @media (min-width: 1300px) {
    display: block;
    left: 120px;
  }
`;
