import styled from 'styled-components';

import { Flex } from 'src/client/components';

export const Card = styled.div`
  width: 100%;
  border-radius: 8px;
  color: white;
  background-color: ${(props) => props.theme.colors.darkSurface3};

  display: flex;
  padding: 16px;
  gap: 8px;

  @media (${(props) => props.theme.device.laptop}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const CardInformationBody = styled(Flex)`
  width: 100%;
  gap: 8px;
`;

export const GivesInformation = styled(Flex)`
  color: ${(props) => props.theme.colors.lightMedEmphasis};
  gap: 4px;
`;
