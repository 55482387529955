import { useContext, useEffect } from 'react';

import { UserRole } from 'src/commons/constants/roles';
import { Account } from 'src/commons/types';

import { AccountContext } from '../contexts/AccountContext';

export function useAppcues() {
  const account = useContext(AccountContext);

  useEffect(() => {
    if (account?.id) {
      (window as any).Appcues.identify(account?.id, {
        role: account?.role,
        name: getAccountName(account as Account),
      });
    }
  }, []);

  function getAccountName(account: Account) {
    switch (account.role) {
      case UserRole.ADMIN:
        return `${account.firstName} ${account.lastName}`;
      case UserRole.DONOR:
        return account.name;
      case UserRole.RECIPIENT:
        return account.name;
      default:
        return null;
    }
  }
}
