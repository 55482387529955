import {
  FileTextOutlined,
  EditOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import styled, { useTheme } from 'styled-components';

import Button from '../../Button';

type Props = {
  onClickEdit: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClickReceipt: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClickWebsiteLink: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  isWebsiteLinkVisible?: boolean;
};

export function ActionTableCell(props: Props) {
  const {
    onClickEdit,
    onClickReceipt,
    onClickWebsiteLink,
    isWebsiteLinkVisible = true,
  } = props;

  const { colors } = useTheme();

  const renderWebsiteLink = isWebsiteLinkVisible && (
    <Button aria-label="link" type="link" onClick={onClickWebsiteLink}>
      <Tooltip color={colors.darkSurface3} placement="bottom" title="Website">
        <LinkOutlined />
      </Tooltip>
    </Button>
  );

  return (
    <Container>
      <Button aria-label="details" type="link" onClick={onClickReceipt}>
        <Tooltip color={colors.darkSurface3} placement="bottom" title="Details">
          <FileTextOutlined />
        </Tooltip>
      </Button>
      <Button aria-label="edit" type="link" onClick={onClickEdit}>
        <Tooltip color={colors.darkSurface3} placement="bottom" title="Edit">
          <EditOutlined />
        </Tooltip>
      </Button>
      {renderWebsiteLink}
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
  }

  @media ${(props) => props.theme.device.laptop} {
    gap: 33px;
  }
`;

export default ActionTableCell;
