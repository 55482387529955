import styled from 'styled-components';

import { Button } from 'src/client/components';
import Text from 'src/client/components/Text';
import { BUTTON_2_2 } from 'src/client/constants/text';

export const Container = styled.div`
  padding: 40px;
  width: 100%;

  .ant-form-vertical .ant-form-item-label {
    padding-bottom: 4px;
  }
`;

export const DateText = styled(Text)`
  color: ${(props) => props.theme.colors.teal2};
`;

export const AddGiveButton = styled(Button)`
  & > span {
    ${BUTTON_2_2}
  }
`;
