import { PlusOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import qs from 'qs';
import React from 'react';

import {
  Box,
  DatePicker,
  Input,
  Select,
  TimePicker,
  Text,
} from 'src/client/components';
import { HOUR_MIN_A, MONTH_DAY_YEAR } from 'src/commons/constants/dateFormat';
import routes from 'src/commons/constants/routes';
import { Give, Platform, RecipientBasic } from 'src/commons/types';
import { separateByWords } from 'src/commons/utils/StringUtils';

import { GroupInputTitleText } from '../../styles';

import * as S from './styles';

type Props = {
  isPlatformsLoading: boolean;
  isRecipientsLoading: boolean;
  platforms: Platform[] | undefined;
  recipients: RecipientBasic[] | undefined;
  give: Give;
};

const { Option } = Select;
const gutterSmall = 8;
const gutterBig = 24;

function GeneralDetailsInput(props: Props) {
  const {
    isPlatformsLoading,
    isRecipientsLoading,
    platforms,
    recipients,
    give,
  } = props;

  function handleSelectFilter(input: string, option: any) {
    const optionName = option.children.toLowerCase();
    const selectValueWords = separateByWords(optionName);

    const inputWords = separateByWords(input.toLowerCase());

    return inputWords.every((word) =>
      selectValueWords.some(
        (selectValueWord) => selectValueWord.indexOf(word) === 0
      )
    );
  }

  function handleSelectSort(optionA: any, optionB: any) {
    return optionA.value
      .toLowerCase()
      .localeCompare(optionB.value.toLowerCase());
  }

  const platformOptions = platforms?.map((platform) => (
    <Option key={platform.id} value={platform.id}>
      {platform.name}
    </Option>
  ));

  const recipientOptions = recipients?.map((recipient) => (
    <Option key={recipient.id} value={recipient.id}>
      {recipient.name}
    </Option>
  ));

  function getAddRecipientLink() {
    const queryString = qs.stringify(
      { giveId: give.id },
      { addQueryPrefix: true }
    );

    return `${routes.ADMIN_ADD_RECIPIENT}${queryString}`;
  }

  return (
    <>
      <GroupInputTitleText>Details</GroupInputTitleText>
      <Text type="body1med2">Email subject</Text>
      <Box margin="8px 0 24px 0">
        <Text type="h3med2">{give.subject}</Text>
      </Box>
      <Row gutter={[gutterSmall, 0]} wrap={false}>
        <Col flex="auto">
          <S.RecipientWrapper>
            <Form.Item
              label="Recipient"
              name="recipientId"
              rules={[{ required: true, message: 'Recipient is required' }]}
            >
              <Select
                showSearch
                data-cy="recipient-select"
                disabled={isRecipientsLoading}
                filterOption={handleSelectFilter}
                filterSort={handleSelectSort}
                loading={isRecipientsLoading}
                placeholder="Recipient"
                size="large"
                virtual={false}
              >
                {recipientOptions}
              </Select>
            </Form.Item>
          </S.RecipientWrapper>
        </Col>
        <Col>
          <Box margin="22px 0 0 0">
            <a href={getAddRecipientLink()} rel="noreferrer" target="_blank">
              <S.AddRecipientButton type="variant1">
                <PlusOutlined />
                Add Recipient
              </S.AddRecipientButton>
            </a>
          </Box>
        </Col>
      </Row>
      <Row gutter={[gutterBig, 0]}>
        <Col span={12}>
          <Form.Item
            label="Date of Donation"
            name="giveDate"
            rules={[
              { required: true, message: 'Date of donation is required' },
            ]}
          >
            <DatePicker
              data-cy="give-date-picker"
              format={MONTH_DAY_YEAR}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Time of Donation" name="giveTime">
            <TimePicker
              data-cy="give-time-picker"
              format={HOUR_MIN_A}
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Platform"
        name="platformId"
        rules={[{ required: true, message: 'Platform is required' }]}
      >
        <Select
          showSearch
          data-cy="platform-select"
          disabled={isPlatformsLoading}
          filterOption={handleSelectFilter}
          filterSort={handleSelectSort}
          loading={isPlatformsLoading}
          placeholder="Platform"
          size="large"
          virtual={false}
        >
          {platformOptions}
        </Select>
      </Form.Item>
      <Form.Item label="Note (Optional)" name="detailEntry">
        <Input data-cy="note-input" placeholder="Add a note" size="large" />
      </Form.Item>
    </>
  );
}

export default GeneralDetailsInput;
