import { Modal } from 'antd';

import styled from 'styled-components';

import {
  FlexCenter,
  minimalisticScrollBar,
} from 'src/client/components/StyledCommon';
import Select from 'src/client/components/v2/Select';
import { BODY_1_REG_2, BUTTON_1_2, H4_MED_2 } from 'src/client/constants/text';

type RecipientLogoProps = {
  logo: string | undefined;
};

export const Container = styled(Modal)`
  ${minimalisticScrollBar};

  p,
  .ant-modal-title,
  .ant-form-item-label > label {
    color: white;
  }

  .ant-modal-title {
    ${H4_MED_2};
  }

  .ant-modal-content {
    display: flex;
    height: 90vh;
    flex-direction: column;
    border-radius: 20px;
    overflow: hidden;
    background-color: ${(props) => props.theme.colors.darkSurface2};
  }

  .ant-modal-header {
    background-color: ${(props) => props.theme.colors.darkSurface2};
    border: none;
  }

  .ant-modal-footer {
    border: none;
  }

  .ant-modal-body {
    height: 100%;
    overflow: auto;
  }

  .ant-picker,
  .ant-picker-input > input,
  .ant-input-number-affix-wrapper,
  .ant-input,
  &&&&& .ant-select-selector,
  .ant-select-selection-item,
  input {
    padding: 15px 12px 13px;
    background-color: ${(props) => props.theme.colors.darkSurface1};
    border: 1px solid ${(props) => props.theme.colors.darkSurface1};
    color: white;
    ${BODY_1_REG_2};
  }

  .ant-input-number-prefix {
    color: ${(props) => props.theme.colors.lightMedEmphasis};
    ${BODY_1_REG_2};
  }

  .ant-picker::placeholder,
  .ant-picker-input > input::placeholder,
  .ant-input-number::placeholder,
  .ant-input-number-affix-wrapper::placeholder,
  .ant-input::placeholder,
  &&&&& .ant-select-selector::placeholder,
  .ant-select-selection-item::placeholder,
  input::placeholder,
  && .ant-select-selection-placeholder {
    color: ${(props) => props.theme.colors.lightMedEmphasis};
    ${BODY_1_REG_2};
  }

  &&&& .ant-input-number-affix-wrapper-status-error,
  &&& .ant-picker-status-error,
  &&& .ant-select-status-error {
    background-color: ${(props) => props.theme.colors.darkSurface1};
  }

  .ant-input-number-affix-wrapper {
    align-items: center;
  }

  .ant-form-item-explain-error {
    margin-top: 10px;
  }

  && .ant-picker-input > input {
    padding: 0;
  }

  .ant-modal-footer {
    padding: 32px 24px 24px;
  }

  .ant-input-number-affix-wrapper,
  .ant-select-selector {
    height: 52px;
  }

  .ant-btn {
    padding: 15px 20px 16px;
    height: unset;
    border: none;
    border-radius: 8px;
    ${BUTTON_1_2};
  }

  &&&& .ant-btn[disabled] {
    background: ${(props) => props.theme.colors.surfaceDisabled};
    color: ${(props) => props.theme.colors.darkEmphasis3};
  }

  .ant-btn-primary {
    background: ${(props) => props.theme.colors.darkPrimary100};
    color: ${(props) => props.theme.colors.darkEmphasis1};

    :hover,
    &:focus,
    &:active {
      background: ${(props) => props.theme.colors.darkPrimary80};
      color: ${(props) => props.theme.colors.darkEmphasis1};
    }
  }

  .ant-btn-default {
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.darkEmphasis1};

    :hover {
      background: ${(props) => props.theme.colors.lightSurface80};
      color: ${(props) => props.theme.colors.darkEmphasis1};
    }
  }

  .ant-form-item {
    margin: 0;
  }

  .ant-form-item-extra {
    color: ${(props) => props.theme.colors.lightMedEmphasis};
    margin: 4px 0;
  }

  .anticon-calendar,
  .anticon-down,
  .anticon-close {
    color: ${(props) => props.theme.colors.lightMedEmphasis};
  }

  .ant-picker-clear,
  .ant-select-clear {
    background: ${(props) => props.theme.colors.darkSurface1};
    color: ${(props) => props.theme.colors.lightMedEmphasis};
  }
`;

export const RecipientLogo = styled(FlexCenter)<RecipientLogoProps>`
  margin-right: 15px;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.darkSurface1};
  background-image: url(${(props) => props.logo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const HeartIcon = styled.img`
  margin-right: 11px;
  height: 21px;
  width: 23px;
`;

export const HourglassIcon = styled.img`
  margin-right: 11px;
  height: 21px;
  width: 17px;
`;

export const Divider = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
`;

export const StyledSelect = styled(Select).attrs((props) => ({
  allowClear: true,
  dropdownStyle: {
    backgroundColor: props.theme.colors.darkSurface1,
    borderRadius: '8px',
  },
  virtual: false,
}))`
  &&&& .ant-select-selector {
    background-color: ${(props) => props.theme.colors.darkSurface1};
  }
`;
