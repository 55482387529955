import styled from 'styled-components';

import { FlexCenter } from 'src/client/components';

export const AnalyticsContainer = styled.div`
  width: 100%;
  background: white;

  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;

  @media (${(props) => props.theme.screen.md}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (${(props) => props.theme.screen.xl}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const ContentSection = styled.div`
  display: flex;
  margin-top: 66px;
  width: 100%;
  flex-direction: column;
  row-gap: 60px;
`;

export const SpinContainer = styled(FlexCenter)`
  height: 700px;
`;
