import { Platform, PlatformFilter } from 'src/commons/types';
import { ApiResponse } from 'src/commons/types/Response.type';

import ApiClient from './ApiClient';

const apiVersion = '1.0';
const endpoint = 'platforms';
const url = `${apiVersion}/${endpoint}`;

type DeletePlatformResponse = ApiResponse<{
  deletedPlatformId: string;
}>;

export async function getPlatformByIds(
  platformIds: string[]
): Promise<Platform[]> {
  const { data } = await ApiClient.post<Platform[]>(`${url}/ids`, {
    platformIds: platformIds,
  });

  return data;
}

export async function getAllPlatforms(): Promise<Platform[]> {
  const { data } = await ApiClient.get<Platform[]>(`${url}/all`);

  return data;
}

export async function getPlatforms(
  params?: PlatformFilter
): Promise<ApiResponse<Platform[]>> {
  const { data } = await ApiClient.post<ApiResponse<Platform[]>>(
    `${url}`,
    params
  );

  return data;
}

export async function deletePlatform(
  platformId: string
): Promise<DeletePlatformResponse> {
  const { data } = await ApiClient.delete<DeletePlatformResponse>(
    `${url}/${platformId}`
  );

  return data;
}

export async function createPlatform(
  platform: Platform
): Promise<ApiResponse<Platform>> {
  const { data } = await ApiClient.post<ApiResponse<Platform>>(
    `${url}/create`,
    platform
  );

  return data;
}

export async function updatePlatform(
  platform: Platform
): Promise<ApiResponse<Platform>> {
  const { data } = await ApiClient.put<ApiResponse<Platform>>(
    `${url}/${platform.id}`,
    platform
  );

  return data;
}
