import styled from 'styled-components';

import {
  BODY,
  BODY_1_REG_2,
  BODY_2_MEDIUM,
  BODY_2_MED_2,
  BODY_2_REGULAR,
  BODY_2_REG_2,
  BODY_ITALIC,
  BUTTON_MEDIUM,
  BUTTON_REGULAR,
  CAPTION1,
  CAPTION2,
  CAPTION3,
  CAPTION4,
  H1,
  H1_MED_2,
  H2,
  H2_MED_2,
  H3,
  H3_MED_2,
  H4,
  H4_MED_2,
  H5,
  H5_MED_2,
  H6_MED_2,
  LABEL_1_MED_2,
  OVERLINE,
  SUBTITLE1,
  SUBTITLE2,
  H3_BOLD_2,
  H1_BOLD_2,
  DISPLAY_M_MED_2,
  DISPLAY_XL_BOLD_2,
  BODY_1_MED_2,
  H2_BOLD_2,
  H5_SEMIBOLD_2,
  H5_BOLD_2,
  CAPTION_REG_2,
  LABEL_1_REG_2,
  LABEL_2_MED_2,
  H4_BOLD_2,
  DISPLAY_S_MED_2,
  DISPLAY_S_BOLD_2,
  DISPLAY_L_MED_2,
  BUTTON_1_2,
  BUTTON_2_2,
  DISPLAY_L_BOLD_2,
  DISPLAY_M_BOLD_2,
  LINK,
} from 'src/client/constants/text';
import { TextType } from 'src/client/types/Text';

type TextProps = {
  color?: string;
  type?: TextType;
  isInline?: boolean;
};

export const StyledText = styled.p<TextProps>`
  display: ${(props) => (props.isInline ? 'inline' : 'block')};
  margin: 0;
  color: ${(props) => props.color};

  ${(props) => props.type === 'h1' && H1}
  ${(props) => props.type === 'h2' && H2}
  ${(props) => props.type === 'h3' && H3}
  ${(props) => props.type === 'h4' && H4}
  ${(props) => props.type === 'h5' && H5}
  ${(props) => props.type === 'subtitle1' && SUBTITLE1}
  ${(props) => props.type === 'subtitle2' && SUBTITLE2}
  ${(props) => props.type === 'body' && BODY}
  ${(props) => props.type === 'bodyItalic' && BODY_ITALIC}
  ${(props) => props.type === 'buttonMedium' && BUTTON_MEDIUM}
  ${(props) => props.type === 'buttonRegular' && BUTTON_REGULAR}
  ${(props) => props.type === 'overline' && OVERLINE}
  ${(props) => props.type === 'caption1' && CAPTION1}
  ${(props) => props.type === 'caption2' && CAPTION2}
  ${(props) => props.type === 'caption3' && CAPTION3}
  ${(props) => props.type === 'caption4' && CAPTION4}
  ${(props) => props.type === 'body2Regular' && BODY_2_REGULAR}
  ${(props) => props.type === 'body2Medium' && BODY_2_MEDIUM}
  ${(props) => props.type === 'h1med2' && H1_MED_2}
  ${(props) => props.type === 'h1bold2' && H1_BOLD_2}
  ${(props) => props.type === 'h2med2' && H2_MED_2}
  ${(props) => props.type === 'h2bold2' && H2_BOLD_2}
  ${(props) => props.type === 'h3med2' && H3_MED_2}
  ${(props) => props.type === 'h3bold2' && H3_BOLD_2}
  ${(props) => props.type === 'h4med2' && H4_MED_2}
  ${(props) => props.type === 'h4bold2' && H4_BOLD_2}
  ${(props) => props.type === 'h6med2' && H6_MED_2}
  ${(props) => props.type === 'h5med2' && H5_MED_2}
  ${(props) => props.type === 'h5semibold2' && H5_SEMIBOLD_2}
  ${(props) => props.type === 'h5bold2' && H5_BOLD_2}
  ${(props) => props.type === 'body1reg2' && BODY_1_REG_2}
  ${(props) => props.type === 'body1med2' && BODY_1_MED_2}
  ${(props) => props.type === 'body2reg2' && BODY_2_REG_2}
  ${(props) => props.type === 'body2med2' && BODY_2_MED_2}
  ${(props) => props.type === 'label1reg2' && LABEL_1_REG_2}
  ${(props) => props.type === 'label1med2' && LABEL_1_MED_2}
  ${(props) => props.type === 'label2med2' && LABEL_2_MED_2}
  ${(props) => props.type === 'displaySMed2' && DISPLAY_S_MED_2}
  ${(props) => props.type === 'displaySBold2' && DISPLAY_S_BOLD_2}
  ${(props) => props.type === 'displayMMed2' && DISPLAY_M_MED_2}
  ${(props) => props.type === 'displayMBold2' && DISPLAY_M_BOLD_2}
  ${(props) => props.type === 'displayLMed2' && DISPLAY_L_MED_2}
  ${(props) => props.type === 'displayLBold2' && DISPLAY_L_BOLD_2}
  ${(props) => props.type === 'displayXlBold2' && DISPLAY_XL_BOLD_2}
  ${(props) => props.type === 'caption1reg2' && CAPTION_REG_2}
  ${(props) => props.type === 'button-1-2' && BUTTON_1_2}
  ${(props) => props.type === 'button-2-2' && BUTTON_2_2}
  ${(props) => props.type === 'link' && LINK}
`;
