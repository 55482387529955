import styled from 'styled-components';

import Shape from '../Shape';

type ContainerProps = {
  isSelected: boolean;
};

type ColorProps = {
  color: string;
};

export const Container = styled.div<ContainerProps>`
  border: ${(props) =>
    props.isSelected ? '2px solid black' : '1px solid #CFD1D5'};
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
  height: fit-content;
`;

export const Color = styled.div<ColorProps>`
  height: 23px;
  width: 23px;
  border-radius: 4px;
  background: ${(props) => props.color};
  border: 1px solid ${(props) => props.theme.colors.gray3};
`;

export const StyledShape = styled(Shape)`
  height: 44px;

  svg {
    width: 40px;
    height: 40px;
  }
`;
