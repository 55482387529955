import { STATUS_OPTIONS, SectionStatus } from 'src/client/types/Gallery';
import routes from 'src/commons/constants/routes';
import { replaceRouteParams } from 'src/commons/utils/RouteUtils';

export function isSectionEditing(sectionStatus: SectionStatus) {
  return (
    sectionStatus.status === STATUS_OPTIONS.EDITING_WITH_NO_CHANGES ||
    sectionStatus.status === STATUS_OPTIONS.EDITING_WITH_CHANGES
  );
}

export function isSectionSaveButtonDisabled(sectionStatus: SectionStatus) {
  return sectionStatus.status === STATUS_OPTIONS.EDITING_WITH_NO_CHANGES;
}

export function isSectionHasChanges(sectionStatus: SectionStatus) {
  return sectionStatus.status === STATUS_OPTIONS.EDITING_WITH_CHANGES;
}

export function getPublicGalleryUrl(pageId: string) {
  const publicGalleryEndpoint = replaceRouteParams(routes.PUBLIC_GALLERY, {
    pageId: pageId,
  });
  const publicLinkDisplay = `${window.location.host}${publicGalleryEndpoint}`;
  const publicLink = `${window.location.origin}${publicGalleryEndpoint}`;

  return { publicLinkDisplay, publicLink };
}
