import { ArrowLeftOutlined, PictureOutlined } from '@ant-design/icons';
import { Form, FormInstance, Modal } from 'antd';

import { Tab } from 'rc-tabs/lib/interface';
import React, { useState } from 'react';

import { useRouteMatch } from 'react-router-dom';

import { Box, Button, Flex, Text } from 'src/client/components';

import { handleGetValueFromUploadEvent } from 'src/client/utils/FormUtils';
import {
  DEFAULT_GALLERY_SHAPE,
  GALLERY_SHAPE_MODAL_UPLOADS_WIDTH,
  GalleryColor,
  GalleryShape,
  GridOptionType,
} from 'src/commons/constants/gallery';

import { Page } from 'src/commons/types';

import GalleryImageHistory from '../GalleryImageHistory';
import GridOptionPicker from '../GridOptionPicker';
import Shape from '../Shape';

import * as S from './styles';

type UrlParams = {
  pageId: string;
};

type Props = React.ComponentProps<typeof Modal> & {
  introSectionForm: FormInstance<any>;
  themeColor: GalleryColor;
  onCloseModal: () => void;
  onSave?: (value: Page['galleryShape']) => void;
};

function GalleryShapeModal(props: Props) {
  const { introSectionForm, themeColor, onSave, onCloseModal, ...modalProps } =
    props;

  const match = useRouteMatch<UrlParams>();
  const { pageId } = match.params;

  const galleryShape = introSectionForm.getFieldValue('galleryShape');
  const galleryCustomImage =
    introSectionForm.getFieldValue('galleryCustomImage');

  const [selectedShape, setSelectedShape] = useState<GalleryShape>(
    galleryShape?.name ?? DEFAULT_GALLERY_SHAPE?.name
  );
  const [showGalleryShapeSelection, setShowGalleryShapeSelection] =
    useState(false);
  const [selectedTab, setSelectedTab] = useState('1');

  const isSaveDisabled = galleryShape.name === selectedShape;

  function handleImageUpload({ file, onSuccess }: any) {
    const reader = new FileReader();

    reader.onloadend = () => {
      introSectionForm.setFieldsValue({
        galleryCustomImage: reader.result as string,
      });
      onSuccess('ok');
      onCloseModal();
    };

    reader.readAsDataURL(file);
  }

  function getGalleryCustomImageUrlFormValue() {
    if (galleryCustomImage?.[0]?.url) {
      return galleryCustomImage[0].url;
    } else if (galleryCustomImage?.[0]?.originFileObj) {
      return URL.createObjectURL(galleryCustomImage[0].originFileObj);
    }

    return undefined;
  }

  const galleryCustomImageUrlFormValue = getGalleryCustomImageUrlFormValue();

  function handleSave() {
    if (onSave) {
      onSave({
        name: selectedShape,
        color: themeColor,
      });

      introSectionForm.setFieldsValue({
        galleryCustomImage: null,
      });
    }
  }

  function handleShapeChange(value: string) {
    setSelectedShape(value as GalleryShape);
  }

  function handleGoBackToSelectView() {
    setSelectedShape(galleryShape.name);
    setShowGalleryShapeSelection(false);
  }

  const newImageTabContent = (
    <S.ButtonGroup>
      <Form.Item
        noStyle
        getValueFromEvent={handleGetValueFromUploadEvent}
        name="galleryCustomImage"
      >
        <S.StyledUpload
          accept="image/*"
          customRequest={handleImageUpload}
          showUploadList={false}
        >
          <S.ImageContainer>
            {galleryCustomImageUrlFormValue ? (
              <>
                <img alt="Uploaded" src={galleryCustomImageUrlFormValue} />
                <S.ImageOverlay className="overlay">
                  <Text type="label1med2">Change Image</Text>
                </S.ImageOverlay>
              </>
            ) : (
              <>
                <PictureOutlined />
                <Text type="label1med2">Upload photo</Text>
              </>
            )}
          </S.ImageContainer>
        </S.StyledUpload>
      </Form.Item>

      <S.ChooseShapeButton onClick={() => setShowGalleryShapeSelection(true)}>
        <Shape
          shape={{
            name: galleryShape?.name,
            color: themeColor,
          }}
        />
        <Text type="label1med2">Choose shape</Text>
      </S.ChooseShapeButton>
    </S.ButtonGroup>
  );

  const TabItems: Tab[] = [
    { key: '1', label: 'New Image', children: newImageTabContent },
    {
      key: '2',
      label: 'Previous Uploads',
      children: (
        <GalleryImageHistory pageId={pageId} onCancel={modalProps?.onCancel} />
      ),
    },
  ];

  const gallerySelectView = (
    <>
      <Text type="h4med2">Image</Text>
      <Box margin="24px 0 0 0" />
      <S.StyledTabs
        defaultActiveKey="1"
        items={TabItems}
        onChange={(key) => setSelectedTab(key)}
      />
    </>
  );

  const galleryShapeView = (
    <>
      <S.BackHeading>
        <span onClick={handleGoBackToSelectView}>
          <ArrowLeftOutlined />
        </span>
        <Text type="h4med2">Image</Text>
      </S.BackHeading>

      <Box margin="24px 0 0 0" />
      <S.SelectedShapeDisplay
        shape={{
          name: selectedShape,
          color: themeColor,
        }}
      />
      <Box margin="24px 0 0 0" />
      <Text type="label1med2">Customize</Text>
      <Box margin="0 0 8px 0" />
      <GridOptionPicker
        selectedColor={themeColor}
        selectedShape={selectedShape}
        type={GridOptionType.SHAPE}
        onChange={handleShapeChange}
      />
      <Flex gap="8px" justifyContent="flex-end" margin="32px 0 0 0">
        <Button
          data-cy="gallery-shape-modal-cancel-btn"
          type="secondary"
          onClick={modalProps?.onCancel}
        >
          Cancel
        </Button>
        <Button
          data-cy="gallery-shape-modal-save-btn"
          disabled={isSaveDisabled}
          type="primary"
          onClick={handleSave}
        >
          Done
        </Button>
      </Flex>
    </>
  );

  return (
    <S.StyledModal
      {...modalProps}
      activeTab={selectedTab}
      data-cy="gallery-shape-modal"
      footer={false}
      width={
        selectedTab === '2' ? GALLERY_SHAPE_MODAL_UPLOADS_WIDTH : undefined
      }
      zIndex={10000}
    >
      {showGalleryShapeSelection ? galleryShapeView : gallerySelectView}
    </S.StyledModal>
  );
}

export default GalleryShapeModal;
