/* eslint-disable no-magic-numbers */
import { rem } from 'polished';
import styled from 'styled-components';

import { Button, Flex } from 'src/client/components';

import GiveCard from '../GiveCard';

export const CardsContainer = styled(Flex)`
  flex-wrap: wrap;
  justify-content: center;

  margin-top: -1px;
  margin-left: -1px;
`;

export const StyledGiveCard = styled(GiveCard)`
  border: 0;
  margin: 0;

  outline: 1px solid ${(props) => props.theme.colors.gray7};
  margin-top: 1px;
  margin-left: 1px;

  &:hover {
    text-decoration: ${(props) => (props.onClick ? 'underline' : 'none')};
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  }
`;

export const VisibilityControlButton = styled(Button)`
  && {
    color: black;
    background: transparent;
    border: none;
    border-bottom: 2px solid black;
    height: fit-content;
    padding: 10px 0;
    margin: 90px 0 78px 0;
    border-radius: 0;

    span {
      font-family: ${(props) => props.theme.fontFamilies.sfPro};
      font-weight: ${(props) => props.theme.fontWeights.medium};
      line-height: ${rem('48px')};
      font-size: ${rem('24px')};
      @media ${(props) => props.theme.screen.md} {
        font-size: ${rem('40px')};
        margin-top: 100px;
      }
    }
  }
`;
