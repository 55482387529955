import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Popconfirm, Space } from 'antd';
import React, { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Box, Button, Flex, Text, Title } from 'src/client/components';
import { ADMIN_USERS_LIMIT } from 'src/client/constants/number';
import { AccountContext } from 'src/client/contexts/AccountContext';
import { useDeleteAdminMutation } from 'src/client/hooks/mutations';
import { removeAdminsQueryData, useGetAdmins } from 'src/client/hooks/queries';
import AdminLayout from 'src/client/layouts/AdminLayout';
import { transformToTable } from 'src/client/utils/UserUtils';
import routes from 'src/commons/constants/routes';
import { Indexable } from 'src/commons/types/Indexable.type';

import * as S from '../styles';

type MakeColumnParams = {
  accountId: string | undefined;
  onClickEdit: (giveId: string) => void;
  onConfirmDelete: (giveId: string) => void;
};

export default function ViewPage() {
  const account = useContext(AccountContext);
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate: deleteUser } = useDeleteAdminMutation({
    onSuccess: (user) => {
      removeAdminsQueryData(queryClient, user.data);
    },
  });

  const { data, isLoading } = useGetAdmins({
    options: {
      limit: ADMIN_USERS_LIMIT,
    },
  });

  const sortedData = data?.sort((a, b) =>
    (a.lastName ? a.lastName : a.username).localeCompare(
      b.lastName ? b.lastName : b.username
    )
  );

  function handleConfirmDelete(id: string) {
    deleteUser({ id });
  }

  function handleClickEdit(id: string) {
    history.push(routes.EDIT_ADMIN.replace(':adminId', id));
  }

  function handleOpenCreateModal() {
    history.push(routes.ADD_ADMIN);
  }

  const columns = makeColumns({
    accountId: account?.id,
    onClickEdit: handleClickEdit,
    onConfirmDelete: handleConfirmDelete,
  });

  return (
    <>
      <Title title="Admins - Admin" />
      <AdminLayout>
        <S.Container>
          <Flex alignItems="center" justifyContent="space-between">
            <Text type="displayMMed2">Admins</Text>
            <Button type="primary" onClick={handleOpenCreateModal}>
              <PlusOutlined />
              Admin
            </Button>
          </Flex>
          <S.StyledTable
            columns={columns}
            dataSource={sortedData?.map(transformToTable)}
            loading={isLoading}
            pagination={false}
            scroll={{ x: 400 }}
          />
        </S.Container>
      </AdminLayout>
    </>
  );
}

function makeColumns(params: MakeColumnParams) {
  const { accountId, onClickEdit, onConfirmDelete } = params;

  return [
    {
      dataIndex: 'fullName',
      key: 'fullName',
      title: 'Name',
    },
    {
      dataIndex: 'email',
      key: 'email',
      title: 'Email',
    },
    {
      key: 'action',
      title: 'Action',
      render: (text: string, record: Indexable) => {
        function handleConfirmDelete() {
          onConfirmDelete(record.id);
        }

        function handleClickEdit() {
          onClickEdit(record.id);
        }

        const deleteBtn = accountId !== record.id && (
          <Popconfirm
            cancelText={<S.ButtonText>No</S.ButtonText>}
            okButtonProps={{
              danger: true,
              type: 'primary',
            }}
            okText={<S.ButtonText>Delete</S.ButtonText>}
            title={<Box margin="0 40px 0 0 ">Delete this admin?</Box>}
            onConfirm={handleConfirmDelete}
          >
            <Button size="small" type="noStyle">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        );

        return (
          <S.ActionsContainer>
            <Space size="small">
              <Button size="small" type="noStyle" onClick={handleClickEdit}>
                <EditOutlined />
              </Button>
              {deleteBtn}
            </Space>
          </S.ActionsContainer>
        );
      },
    },
  ];
}
