import React from 'react';

import { Link } from 'react-router-dom';

import { Text } from 'src/client/components';

import routes from 'src/commons/constants/routes';
import { getCurrentYear } from 'src/commons/utils/DateUtils';

import * as S from './styles';

function LandingPageFooter() {
  return (
    <S.Container>
      <S.LogoContainer>
        <S.Logo src="/logo-white.png" />
        <Text type="label1reg2">©{getCurrentYear()} Giving Side</Text>
      </S.LogoContainer>
      <S.LinksContainer>
        <a href="mailto:hello@givingside.com">
          <Text type="label1reg2">Contact us</Text>
        </a>
        <Link to={routes.PRIVACY_POLICY}>
          <Text type="label1reg2">Privacy Policy</Text>
        </Link>
      </S.LinksContainer>
    </S.Container>
  );
}

export default LandingPageFooter;
