import styled from 'styled-components';

import { Button } from 'src/client/components';
import { BUTTON_1_2 } from 'src/client/constants/text';

export const Container = styled.div`
  margin: 71px 105px;
`;

export const StyledButton = styled(Button)`
  &.ant-btn-lg {
    padding: 13px 20px 13px 16px;
  }

  & > span {
    ${BUTTON_1_2}
  }
`;
