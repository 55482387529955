/* eslint-disable no-alert */
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Button, Title } from 'src/client/components';
import {
  useDuplicateGiveMutation,
  useProcessGiveMutation,
  useRejectGiveMutation,
} from 'src/client/hooks/mutations';
import {
  useGetAllPlatforms,
  useGetAllRecipients,
  useGetGiveById,
} from 'src/client/hooks/queries';
import { useHandleUpdateGive } from 'src/client/hooks/useHandleUpdateGive';
import AdminLayout from 'src/client/layouts/AdminLayout';
import { getFormErrorList } from 'src/client/utils/FormUtils';
import routes from 'src/commons/constants/routes';
import { DONATION_TYPE, GIVE_STATUS, Give, GIVE_UNIT } from 'src/commons/types';

import { LoadingPage, PageNotFound } from '..';

import Attachments from './components/Attachments';
import DonationDetailsInput from './components/DonationDetailsInput';
import DuplicateGiveInfo from './components/DuplicateGiveInfo';
import DurationDetailsInput from './components/DurationDetailsInput';
import GeneralDetailsInput from './components/GeneralDetailsInput';
import NavigationButtons from './components/NavigationButtons';
import Receipt from './components/Receipt';
import * as S from './styles';

type UrlParams = {
  giveId: string;
};

const { useForm } = Form;
const gutter = 32;

function AdminEditGive(props: RouteComponentProps<UrlParams>) {
  const { history, match } = props;

  const [form] = useForm();
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true);

  const { data: give, isLoading: isGiveLoading } = useGetGiveById({
    giveId: match.params.giveId,
    shouldPrepopulateWhenUnprocessed: true,
  });

  const { data: platforms, isLoading: isPlatformsLoading } =
    useGetAllPlatforms();
  const { data: recipients, isLoading: isRecipientsLoading } =
    useGetAllRecipients();

  const {
    mutateAsync: processGive,
    isLoading: isProcessingGive,
    data: processGiveResponse,
    reset: resetProcessGiveMutation,
  } = useProcessGiveMutation();
  const { mutateAsync: rejectGive, isLoading: isRejectingGive } =
    useRejectGiveMutation();

  const { mutateAsync: duplicateGive, isLoading: isDuplicatingGive } =
    useDuplicateGiveMutation();

  const { handleUpdate, isUpdatingGive } = useHandleUpdateGive({
    form,
    give,
    platforms,
    processGive,
    recipients,
  });

  useEffect(() => {
    resetFieldsWhenGiveChanges();
    resetProcessGiveMutation();

    if (give?.status === GIVE_STATUS.UNPROCESSED) {
      setIsPrimaryButtonDisabled(false);
    }
  }, [give?.id]);

  function resetFieldsWhenGiveChanges() {
    if (give?.id) {
      form.resetFields();
    }
  }

  async function handleReject() {
    const isConfirm = window.confirm(
      'Are you sure you want to reject this give?'
    );

    if (isConfirm) {
      await rejectGive(match.params.giveId as string);

      redirectToGivesTable();
    }
  }

  async function handleDuplicate() {
    const isConfirm = window.confirm(
      'Are you sure you want to duplicate this give?'
    );

    if (isConfirm) {
      await duplicateGive(match.params.giveId as string);
    }
  }

  function redirectToGivesTable() {
    history.push(routes.ADMIN_GIVES);
  }

  function handleFormChange() {
    const isProcessButtonDisabled =
      !form.isFieldsTouched() || !!getFormErrorList(form).length;

    setIsPrimaryButtonDisabled(isProcessButtonDisabled);
  }

  const updateButtonText =
    give?.status === GIVE_STATUS.PROCESSED ? 'Update' : 'Process';

  if (isGiveLoading) {
    return <LoadingPage />;
  }

  if (!give) {
    return <PageNotFound />;
  }

  return (
    <>
      <Title title="Process Give - Admin" />
      <AdminLayout>
        <S.Container>
          <Row gutter={[gutter, 0]} justify="center">
            <Col md={22} xs={20} xxl={24}>
              <Button type="variant1" onClick={redirectToGivesTable}>
                <LeftOutlined />
                Gives Dashboard
              </Button>
              <NavigationButtons give={give} />
            </Col>
          </Row>
          <Row gutter={[gutter, 0]} justify="center">
            <Col md={11} xs={20} xxl={12}>
              <Receipt htmlBody={give?.htmlBody} textBody={give?.textBody} />
              <Attachments give={give} />
            </Col>
            <Col md={11} xs={20} xxl={12}>
              <Form
                form={form}
                initialValues={getFormInitialValues(give)}
                layout="vertical"
                scrollToFirstError={{
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'center',
                }}
                onFieldsChange={handleFormChange}
                onFinish={handleUpdate}
              >
                <GeneralDetailsInput
                  give={give}
                  isPlatformsLoading={isPlatformsLoading}
                  isRecipientsLoading={isRecipientsLoading}
                  platforms={platforms}
                  recipients={recipients}
                />
                <DonationDetailsInput />
                <DurationDetailsInput />
                <DuplicateGiveInfo processGiveResponse={processGiveResponse} />
                <S.ActionButtonsContainer>
                  <Button
                    data-cy="process-give-btn"
                    disabled={isPrimaryButtonDisabled}
                    htmlType="submit"
                    loading={isProcessingGive || isUpdatingGive}
                    type="primary"
                  >
                    <CheckCircleOutlined />
                    {updateButtonText}
                  </Button>
                  <Button
                    data-cy="reject-give-btn"
                    disabled={give.status === GIVE_STATUS.REJECTED}
                    loading={isRejectingGive}
                    type="destructive"
                    onClick={handleReject}
                  >
                    <CloseCircleOutlined />
                    Reject
                  </Button>
                  <Button
                    data-cy="duplicate-give-btn"
                    disabled={give.status === GIVE_STATUS.REJECTED}
                    loading={isDuplicatingGive}
                    type="secondary"
                    onClick={handleDuplicate}
                  >
                    <CopyOutlined />
                    Duplicate
                  </Button>
                  <Button type="variant1" onClick={redirectToGivesTable}>
                    Cancel
                  </Button>
                </S.ActionButtonsContainer>
              </Form>
            </Col>
          </Row>
        </S.Container>
      </AdminLayout>
    </>
  );
}

function getFormInitialValues(give: Give) {
  return {
    ...give,
    donationType:
      give.unit === GIVE_UNIT.HOURS ? DONATION_TYPE.HOURS : DONATION_TYPE.CASH,
    hours: give.unit === GIVE_UNIT.HOURS ? give.amount : undefined,
    amount: give.unit === GIVE_UNIT.DOLLARS ? give.amount : undefined,
    endDate: give?.endDate && moment(give.endDate),
    giveDate: give?.giveDate && moment(give.giveDate),
    giveTime: give?.giveDate && moment(give.giveDate),
    isDonationSplit: !!give?.splitAmount,
    isDonationMatching: !!give?.matchedDonationAmount,
    startDate: give?.startDate && moment(give.startDate),
  };
}

export default AdminEditGive;
