import { Col, Form, message, Row } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';

import { Box, EditProfileBottom } from 'src/client/components';
import LoadingAnimation from 'src/client/components/LoadingAnimation';
import { AccountContext } from 'src/client/contexts/AccountContext';
import { useUpdateDonorMutation } from 'src/client/hooks/mutations';
import { useGetCityStateByZipCode } from 'src/client/hooks/queries';
import { Donor } from 'src/commons/types/Donor.type';

import * as S from './styles';

const { useForm } = Form;

function PersonalizeGivingSide() {
  const [form] = useForm();
  const donor = useContext(AccountContext) as Donor;
  const { isLoading: isUpdateDonorLoading, mutateAsync: updateDonor } =
    useUpdateDonorMutation();
  const {
    refetch,
    data: cityState,
    isLoading: isCityStateByZipCodeLoading,
  } = useGetCityStateByZipCode(form.getFieldValue('zip') ?? donor.zip);

  async function handleSave() {
    const fieldValues = form.getFieldsValue();
    const updatedDonorData: Partial<Donor> = {};

    if (form.isFieldTouched('zip')) {
      updatedDonorData.zip = fieldValues.zip;
    }

    if (form.isFieldTouched('birthday')) {
      updatedDonorData.birthday = fieldValues.birthday;
    }

    if (form.isFieldTouched('bio')) {
      updatedDonorData.bio = fieldValues.bio;
    }

    await updateDonor({
      id: donor.id,
      ...updatedDonorData,
    });

    location.reload();
  }

  function resetForm() {
    form.resetFields();
    message.info('Changes Discarded!');
  }

  function handleZipCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    refetch();
  }

  function renderCityState() {
    if (isCityStateByZipCodeLoading) {
      return (
        <Box margin="45px 0 0 20px">
          <LoadingAnimation />
        </Box>
      );
    }

    if (cityState?.city && cityState.state) {
      return (
        <S.CityStateText type="body1reg2">
          {cityState.city.toLocaleLowerCase()}, {cityState.state}
        </S.CityStateText>
      );
    }

    return (
      <S.UnknownZipCodeText type="body1reg2">
        We don&apos;t know that zip code, but you should proceed anyway.
      </S.UnknownZipCodeText>
    );
  }

  return (
    <Box margin="30.5px 0 0 0">
      <Row>
        <Col md={12} xs={24}>
          <S.StyledForm
            form={form}
            initialValues={{
              bio: donor.bio,
              birthday: donor.birthday && moment(donor.birthday),
              zip: donor.zip,
            }}
            layout="vertical"
          >
            <Form.Item label="Zip Code">
              <S.ZipCodeContainer>
                <Form.Item noStyle name="zip">
                  <S.ZipCodeInput
                    placeholder="ZIP code"
                    size="large"
                    onChange={handleZipCodeChange}
                  />
                </Form.Item>
                {renderCityState()}
              </S.ZipCodeContainer>
            </Form.Item>
            <Box margin="40px 0 0 0" />
            <Form.Item label="Birthday" name="birthday">
              <S.BirthdateInput placeholder="Birthday" size="large" />
            </Form.Item>
            <Box margin="40px 0 0 0" />
            <Form.Item label="Bio" name="bio">
              <S.StyledTextArea
                placeholder="Write a few words about yourself, what you care about, and/ or what motivates your Giving Side. "
                size="large"
              />
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {() => (
                <EditProfileBottom
                  saveBtnProps={{
                    disabled: !form.isFieldsTouched(),
                    loading: isUpdateDonorLoading,
                  }}
                  onDiscard={resetForm}
                  onSave={handleSave}
                />
              )}
            </Form.Item>
          </S.StyledForm>
        </Col>
      </Row>
    </Box>
  );
}

export default PersonalizeGivingSide;
