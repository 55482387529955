import DOMPurify from 'dompurify';
import React, { useEffect, useRef } from 'react';

import * as S from './styles';

type Props = {
  body: string | undefined | null;
  className?: string;
};

function EmailViewer(props: Props) {
  const { body, className = '' } = props;

  const receiptRef = useRef<HTMLDivElement>(null);
  const sanitizedBody = DOMPurify.sanitize(body ?? '');

  useEffect(() => {
    const receiptElement = receiptRef.current;
    receiptElement?.addEventListener('click', openToNewTabOnLinkClick);

    return () => {
      receiptElement?.removeEventListener('click', openToNewTabOnLinkClick);
    };
  }, []);

  function openToNewTabOnLinkClick(e: MouseEvent) {
    const target = e.target as HTMLAnchorElement;

    if (target && target.nodeName === 'A') {
      e.preventDefault();
      window.open(target.href, '_blank');
    }
  }

  return (
    <S.Container
      className={className}
      dangerouslySetInnerHTML={{ __html: sanitizedBody }}
      ref={receiptRef}
    ></S.Container>
  );
}

export default EmailViewer;
