import React from 'react';

import { LegacyGlobalStyle } from 'src/client/components';

import Header from './components/header';

import * as S from './styles';

function PrivacyPolicyPage() {
  return (
    <>
      <LegacyGlobalStyle />
      <Header />

      <S.Container>
        <S.ArticleTitle>Privacy Policy</S.ArticleTitle>
        <S.ArticleModificationDate>
          Last updated: July 1, 2020
        </S.ArticleModificationDate>

        <S.ArticleContentP>
          Giving Side (“we”) places a high priority on protecting your privacy.
          This privacy policy (“Policy”) has been created to demonstrate our
          commitment to the privacy of our members and website users. This
          policy explains what types of information are collected by the website
          givingside.com (“Site”) and how this information is used.
        </S.ArticleContentP>
        <S.ArticleContentP>
          This Statement of Privacy applies to givingside.com and Giving Side
          and governs data collection and usage. For the purposes of this
          Privacy Policy, unless otherwise noted, all references to Giving Side
          include www.givingside.com.
        </S.ArticleContentP>

        <S.ArticleContentH1>Acceptance of Policy</S.ArticleContentH1>

        <S.ArticleContentP>
          This privacy policy (&quot;Policy&quot;) describes how Giving Side
          collects, uses and shares personal information of its Users. By using
          Giving Side’s website, you consent to the data practices described in
          this statement.
        </S.ArticleContentP>

        <S.ArticleContentH1>What we collect</S.ArticleContentH1>

        <S.ArticleContentP>
          Information You Give Us. We collect your name, email address,
          username, password, demographic information (if you choose),
          information provided on the receipts you send us, and other
          information you provide to us.
        </S.ArticleContentP>
        <S.ArticleContentP>
          Information Automatically Collected. We automatically log information
          about you and your computer. For example, when visiting our Site, we
          log your computer operating system type, browser type, browser
          language, IP address and information about your use of and actions on
          our Site.
        </S.ArticleContentP>
        <S.ArticleContentP>
          Cookies. We may log information using &quot;cookies.&quot; Cookies are
          small data files stored on your hard drive by a website. Cookies help
          us make our Site and your visit better. We use cookies to see which
          parts of our Site people use and like and to count visits to our Site.
        </S.ArticleContentP>
        <S.ArticleContentP>
          Web Beacons. We may log information using digital images called Web
          beacons on our Site or in our emails. We use Web beacons to manage
          cookies, count visits, and to learn what marketing works and what does
          not. We also use Web Beacons to tell if you open or act on our emails.
        </S.ArticleContentP>

        <S.ArticleContentH1>How we use personal information</S.ArticleContentH1>

        <S.ArticleContentP>
          We use your personal information to provide our services to you,
          allowing us to operate, maintain, and improve our site. Specifically,
          your personal information is what allows the creation of a
          personalized space designed to reflect your giving. We also respond to
          comments and questions and provide customer service to you. We send
          you information including confirmations, technical notices, updates,
          security alerts, and support and administrative messages. We
          communicate about upcoming product changes and trends in giving. We
          also use your personal information to keep your account safe, and
          protect, investigate, and deter against fraudulent, unauthorized, or
          illegal activity.
        </S.ArticleContentP>
        <S.ArticleContentP>
          Giving Side may also contact you via surveys to learn about your
          opinion of current services or of potential new services that may be
          offered.
        </S.ArticleContentP>

        <S.ArticleContentH1>
          How we share personal information
        </S.ArticleContentH1>

        <S.ArticleContentP>
          Giving Side does not sell, rent or lease its customer lists to third
          parties.
        </S.ArticleContentP>
        <S.ArticleContentP>
          Giving Side may share aggregated giving data to its recipients, but
          does not intentionally share any personally identifiable data.
        </S.ArticleContentP>
        <S.ArticleContentP>
          Giving Side may also share data with trusted partners to help perform
          statistical analysis, send you email or provide customer support. All
          such third parties are prohibited from using your personal information
          except to provide these services to Giving Side, and they are required
          to maintain the confidentiality of your information.
        </S.ArticleContentP>
        <S.ArticleContentP>
          Giving Side may keep track of the websites and pages our users visit
          within Giving Side, in order to determine what Giving Side services
          are the most popular. This data is used to deliver customized content
          and advertising within Giving Side to customers whose behavior
          indicates that they are interested in a particular subject area.
        </S.ArticleContentP>
        <S.ArticleContentP>
          Giving Side will only disclose your personal information, without
          notice, if required to do so by law or in the good faith belief that
          such action is necessary to: (a) conform to the edicts of the law or
          comply with legal process served on Giving Side or the site; (b)
          protect and defend the rights or property of Giving Side; and, (c) act
          under exigent circumstances to protect the personal safety of users of
          Giving Side, or the public.
        </S.ArticleContentP>

        <S.ArticleContentH1>Control over your information</S.ArticleContentH1>

        <S.ArticleContentP>
          We respect your privacy and give you an opportunity to opt-out of
          receiving announcements of certain information or send requests about
          personal information. Users may opt-out of receiving any or all
          communications from Giving Side by contacting us here:
        </S.ArticleContentP>
        <S.ArticleContentP>Email: support@givingside.com</S.ArticleContentP>
        <S.ArticleContentP>
          You have the ability to delete and edit individual “gives” or entries
          through your Giving Side account. If you need assistance in deleting
          individual gives or entries, please email support@givingside.com.
        </S.ArticleContentP>
        <S.ArticleContentP>
          If you would like to delete your account, please email
          support@givingside.com. Please include the email address(es)
          associated with your account(s).
        </S.ArticleContentP>

        <S.ArticleContentH1>
          Security of your personal information
        </S.ArticleContentH1>

        <S.ArticleContentP>
          We value the safety of your personal information and take steps to
          help protect it so that you may enjoy our services securely.
        </S.ArticleContentP>

        <S.ArticleContentH1>Contact information</S.ArticleContentH1>

        <S.ArticleContentP>
          Giving Side welcomes your questions or comments regarding this
          Statement of Privacy. If you believe that Giving Side has not adhered
          to this Statement, please contact Giving Side at:
        </S.ArticleContentP>
        <S.ArticleContentAddressP>Giving Side</S.ArticleContentAddressP>
        <S.ArticleContentAddressP>PO Box 11281</S.ArticleContentAddressP>
        <S.ArticleContentAddressP>
          Oakland, California 94611
        </S.ArticleContentAddressP>
        <S.ArticleContentP>
          Email Address: info@givingside.com
        </S.ArticleContentP>

        <S.ArticleContentH1>Changes to this privacy policy</S.ArticleContentH1>

        <S.ArticleContentP>
          Giving Side reserves the right, at its sole discretion, to modify or
          replace the Policy at any time. If the alterations constitute a
          material change to the Policy, Giving Side will notify you by posting
          an announcement on the Site. What constitutes a material change will
          be determined at Giving Side’s sole discretion. You shall be
          responsible for reviewing and becoming familiar with any such
          modifications. Using any service or viewing any content following
          notification of a material change to the Policy shall constitute your
          acceptance of the Policy as modified.
        </S.ArticleContentP>

        <S.ArticleFooter>
          This policy was informed by Digial Impact’s toolkit, which helps civil
          society organizations use and govern digital resources, ethically and
          effectively toward their mission.
        </S.ArticleFooter>

        <S.FooterLink>https://digitalimpact.io</S.FooterLink>
      </S.Container>
    </>
  );
}

export default PrivacyPolicyPage;
