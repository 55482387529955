import { useMutation } from 'react-query';

import {
  SaveScrapedRecipientWebsiteResponse,
  checkIfRecipientExists,
  createRecipient,
  deleteRecipient,
  resetRecipientPassword,
  saveScrapedRecipientWebsiteImage,
  scrapeRecipientWebsite,
  sendRecipientResetPasswordEmail,
  updateRecipient,
} from 'src/client/api/RecipientApi';
import { DeleteRecipientResponse } from 'src/client/types/ApiResponse';
import { ApiResponse, Recipient } from 'src/commons/types';

type CommonOptions<Data = Recipient> = {
  onSuccess?: (option: Data) => void;
};

export function useCreateRecipientMutation(
  opts: CommonOptions<ApiResponse<Recipient>>
) {
  return useMutation(createRecipient, opts);
}

export function useDeleteRecipient(
  options: CommonOptions<DeleteRecipientResponse>
) {
  return useMutation(deleteRecipient, options);
}

export function useUpdateRecipientMutation(opts?: CommonOptions) {
  return useMutation(updateRecipient, opts);
}

export function useCheckIfRecipientExistsMutation() {
  return useMutation(checkIfRecipientExists);
}

export function useSendRecipientResetPasswordEmailMutation() {
  return useMutation(sendRecipientResetPasswordEmail);
}

export function useResetRecipientPasswordMutation() {
  return useMutation(resetRecipientPassword);
}

export function useSaveScrapedRecipientWebsiteImageMutation(
  opts?: CommonOptions<SaveScrapedRecipientWebsiteResponse>
) {
  return useMutation(saveScrapedRecipientWebsiteImage, opts);
}

export function useScrapeRecipientWebsite() {
  return useMutation(scrapeRecipientWebsite);
}
