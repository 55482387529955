import { DefaultProperties } from './Common.type';

export type Image = {
  file?: string | null;
  fileHash?: string | null;
  isFromGuidestar?: boolean | null;
  isPrimaryLogo?: boolean | null;
  ownerLegacyId?: string | null;
  ownerId: string;
  ownerType: IMAGE_OWNER_TYPE;
} & DefaultProperties;

export enum IMAGE_OWNER_TYPE {
  RECIPIENT = 'Recipient',
  PLATFORM = 'Platform',
}

export type ImageWithUrl = Image & { url: string };
