export const GENDERS = {
  AGENDER: {
    legacyId: '5',
    name: 'agender',
  },
  FEMALE: {
    legacyId: '1',
    name: 'female',
  },
  GENDERQUEER: {
    legacyId: '4',
    name: 'genderqueer',
  },
  MALE: {
    legacyId: '2',
    name: 'male',
  },
  NON_BINARY: {
    legacyId: '3',
    name: 'non_binary',
  },
  TRANSGENDER: {
    legacyId: '6',
    name: 'transgender',
  },
  OTHER: {
    legacyId: '7',
    name: 'other',
  },
};
