import React, { useEffect, useState } from 'react';

import { useTheme } from 'styled-components';

import { LoadingCard } from 'src/client/components';

import Text from 'src/client/components/Text';
import { GIVING_SIDE_EMAIL } from 'src/client/constants/email';
import { groupGivesByPlatform, hydratePlatformCountsWithPlatformImage } from 'src/client/utils/GiveUtils';

import { Give, PlatformCount } from 'src/commons/types';

import PlatformCard from '../PlatformCard';

import * as S from './styles';

type Props = {
  gives: Give[] | undefined;
  isGivesLoading: boolean;
};

const TOP_PLATFORM_LIMIT = 4;

function TopPlatformsSection(props: Props) {
  const { gives, isGivesLoading } = props;

  const { colors } = useTheme();
  const [platforms, setPlatforms] = useState<(PlatformCount & { platformImagePath: string | undefined })[]>([]);

  useEffect(() => {
    async function hydratePlatforms() {
      if (gives) {
        const hydratedPlatforms = await hydratePlatformCountsWithPlatformImage(groupGivesByPlatform(gives))
        setPlatforms(hydratedPlatforms || [])
      }
    }

    hydratePlatforms()
  }, [gives])

  const platformCards = platforms
    .filter((platform) => !!platform.platformImagePath)
    .slice(0, TOP_PLATFORM_LIMIT)
    .map((groupedGive) => (
      <PlatformCard
        key={groupedGive.platformId}
        platformId={groupedGive.platformId}
        platformImagePath={groupedGive.platformImagePath!}
      />
    ));

  const emptyContent =
    <LoadingCard isLoading={isGivesLoading}>
      <S.EmptyContentContainer>
        <div>
          <Text isInline color={colors.lightMedEmphasis} type="body1reg2">
            Forward your next give to&nbsp;
          </Text>
          <a href={`mailto:${GIVING_SIDE_EMAIL}`}>
            <Text isInline color={colors.teal1} type="body1med2">
              {GIVING_SIDE_EMAIL}
            </Text>
          </a>
        </div>
      </S.EmptyContentContainer>
    </LoadingCard>

  const content =
    <S.CardsContainer>
      <LoadingCard isLoading={isGivesLoading}>{platformCards}</LoadingCard>
    </S.CardsContainer>

  return (
    <>
      <Text type="h3bold2">Top Giving Platforms You Gave Through</Text>
      {platformCards.length > 0 ? content : emptyContent}
    </>
  );
}

export default TopPlatformsSection;
