import { useContext, useEffect } from 'react';

import { AccountContext } from '../contexts/AccountContext';
import { analytics } from '../libs/segment';

export function useSegmentIdentify() {
  const account = useContext(AccountContext);

  useEffect(() => {
    analytics.identify(account?.id, {
      email: account?.email,
      role: account?.role,
    });
  }, [account?.id]);
}
