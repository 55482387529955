import { Form, Row } from 'antd';
import styled from 'styled-components';

import { DatePicker, Flex, Input, Text } from 'src/client/components';

const { TextArea } = Input;

export const StyledForm = styled(Form)`
  .ant-form-item {
    margin: 0;
  }
`;

export const StyledRow = styled(Row)`
  margin-bottom: 38.5px;
`;

export const StyledTextArea = styled(TextArea)`
  padding-top: 15px;
  height: 183px !important;
  border-radius: 8px;
  resize: none;
`;

export const CityStateText = styled(Text)`
  color: ${(props) => props.theme.colors.darkMedEmphasis};
  text-transform: capitalize;
`;

export const ZipCodeContainer = styled(Flex)`
  flex-wrap: wrap;
  align-items: center;
  gap: 34px;
`;

export const UnknownZipCodeText = styled(CityStateText)`
  text-transform: none;
`;

export const ZipCodeInput = styled(Input)`
  width: 121px;
`;

export const BirthdateInput = styled(DatePicker)`
  width: 100%;
  max-width: 262px;
`;
