import { CloudDownloadOutlined } from '@ant-design/icons';
import React from 'react';

import styled from 'styled-components';

import Button from '../Button';
import Text from '../Text';

type Props = {
  disabled?: boolean;
  onClick?: () => void;
};

function ExportButton(props: Props) {
  const { disabled, onClick } = props;

  return (
    <Container>
      <ExportCaption type="caption1reg2">Download as spreadsheet</ExportCaption>
      <StyledButton
        data-cy="export-spreadsheet"
        disabled={disabled}
        icon={<StyledCloudDownloadOutlined />}
        type="variant2"
        onClick={onClick}
      >
        Export
      </StyledButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: start;

  @media ${(props) => props.theme.device.laptop} {
    gap: 0px;
    flex-direction: row;
    align-items: center;
  }
`;

const ExportCaption = styled(Text)`
  width: 100%;
  color: ${(props) => props.theme.colors.lightEmphasis2};
  margin-right: 10px;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.size.tablet}) {
    display: none;
  }

  @media ${(props) => props.theme.device.laptop} {
    width: 90px;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: between;
`;

const StyledCloudDownloadOutlined = styled(CloudDownloadOutlined)`
  line-height: 0;

  svg {
    width: 22px;
    height: 22px;
  }
`;

export default ExportButton;
