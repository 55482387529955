/* eslint-disable no-magic-numbers */
import Decimal from 'decimal.js';

import { DECIMAL_OFFSET } from '../constants/offsets';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

const REGEX_COMMA = /,/gi;
const HUNDRED = 100;

export function formatToCurrency(amount: number) {
  return formatter.format(amount);
}

export function formatToFinancial(price: number) {
  return price / DECIMAL_OFFSET;
}

export function toIntMoney(amount: number) {
  const movedDecimalPointNumber = moveDecimalPointTwoPlacesToTheRight(amount);
  const decimalsRemovedNumber = new Decimal(
    movedDecimalPointNumber
  ).toDecimalPlaces(0);

  return decimalsRemovedNumber.toNumber();
}

export function moveDecimalPointTwoPlacesToTheRight(number: number) {
  return new Decimal(number).times(HUNDRED).toNumber();
}

export function toDollarAmount(str: string | undefined) {
  const dollarAmount =
    Number(str?.replace(REGEX_COMMA, '').replace('USD', '')) * HUNDRED;

  return Number(dollarAmount.toFixed(0));
}
