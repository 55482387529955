import { Radio } from 'antd';

import styled from 'styled-components';

import { BUTTON_1_2 } from 'src/client/constants/text';

export const Container = styled.div`
  padding: 40px 27px;
`;

export const RadioGroupContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FixedAmountButton = styled(Radio.Button)`
  &&& {
    border-radius: 4px 0 0 4px;
    overflow: hidden;
  }
`;

export const SuperGoalButton = styled(Radio.Button)`
  &&& {
    border-radius: 0 4px 4px 0;
    overflow: hidden;
  }
`;

export const RadioGroup = styled(Radio.Group)`
  .ant-radio-button-input {
    background: rgba(255, 255, 255, 0.12) !important;
  }

  &&& .ant-radio-button-checked {
    background: ${(props) => props.theme.colors.teal1};
    color: ${(props) => props.theme.colors.darkHighEmphasis} !important;
    border: 0;
  }

  &&& .ant-radio-button-wrapper-checked > span {
    color: ${(props) => props.theme.colors.darkHighEmphasis} !important;
  }

  &&& .ant-radio-button-wrapper > span {
    ${BUTTON_1_2};
  }

  .ant-radio-button-wrapper {
    padding: 12px 16px 8px 16px;
    line-height: 0;
    height: unset;
    border: 0;
    background: rgba(255, 255, 255, 0.2);
    color: ${(props) => props.theme.colors.white};
  }

  &&& .ant-radio-button {
    padding: 10px 16px 7px;
  }
`;
