import { Form } from 'antd';
import styled from 'styled-components';

import Select from 'src/client/components/Select';

import {
  BODY_1_MED_2,
  BODY_1_REG_2,
  BUTTON_1_2,
} from 'src/client/constants/text';

import Button from '../Button';
import Input from '../Input';

export const StatusSelect = styled(Select)`
  .ant-select-selection-search-input,
  && .ant-select-selection-placeholder,
  && .ant-select-selection-item {
    ${BODY_1_REG_2}
  }

  && .ant-select-selection-placeholder {
    color: ${(props) => props.theme.colors.darkEmphasis2};
  }

  && .ant-select-selector {
    border-color: ${(props) => props.theme.colors.neutral5};
  }

  &&& {
    width: 30%;
  }
`;

export const StyledForm = styled(Form)`
  && .ant-form-item-label label {
    ${BODY_1_MED_2}
  }
`;

export const StyledFormInput = styled(Input)`
  &.ant-input-lg::placeholder {
    color: ${(props) => props.theme.colors.darkEmphasis2};
  }

  &.ant-input-lg {
    ${BODY_1_REG_2}
    padding: 15px 12px 13px;
    border-color: ${(props) => props.theme.colors.neutral5};
  }
`;

export const StyledFormInputSelect = styled(Select)`
  .ant-select-selection-search-input,
  && .ant-select-selection-placeholder,
  && .ant-select-selection-item {
    ${BODY_1_REG_2}
  }

  && .ant-select-selection-placeholder {
    color: ${(props) => props.theme.colors.darkEmphasis2};
  }

  && .ant-select-selector {
    border-color: ${(props) => props.theme.colors.neutral5};
  }
`;

const BaseButton = styled(Button)`
  & > span {
    ${BUTTON_1_2}
  }
`;

export const AddPlatformButton = styled(BaseButton)`
  &.ant-btn-lg {
    padding: 15px 20px 16px;
  }
`;

export const CancelButton = styled(BaseButton)`
  &.ant-btn-lg {
    padding: 15px 20px 16px;
  }

  & > span {
    color: ${(props) => props.theme.colors.darkEmphasis3};
  }
`;
