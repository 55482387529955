import { Col, Row } from 'antd';
import React from 'react';

import { useTheme } from 'styled-components';

import { Box, Text } from 'src/client/components';

import * as S from './styles';

function Testimonials() {
  const { colors } = useTheme();

  const testimonialCards = testimonialsData.map((testimonial) => (
    <S.TestimonialCard key={testimonial.name}>
      <S.TestimonialPerson src={testimonial.image} />
      <div>
        <Text color={colors.darkSurface1} type="body1med2">
          {testimonial.name}
        </Text>
        <Box margin="4px 0 0 0" />
        <Text color={colors.darkEmphasis2} type="label1reg2">
          {testimonial.role}
        </Text>
        <Box margin="21px 0 0 0" />
        <Text color={colors.darkEmphasis1} type="h4med2">
          {testimonial.testimonial}
        </Text>
      </div>
    </S.TestimonialCard>
  ));

  return (
    <S.Bg>
      <Row justify="center">
        <Col md={24} xs={20}>
          <S.Container>
            <Text type="displaySBold2">Join the community</Text>
            {testimonialCards}
          </S.Container>
        </Col>
      </Row>
    </S.Bg>
  );
}

const testimonialsData = [
  {
    name: 'Lucy Bernholz',
    role: 'Director, Digital Civil Society Lab at Stanford PACS; Author, How We Give Now: A Philanthropic Guide for the Rest of Us',
    testimonial:
      '“Giving Side is a mirror that shows you how you’re acting on the things you care about.”',
    image: '/lucyBernholz.png',
  },
  {
    name: 'Gregory Cendana',
    role: "President & Co-Founder, Can't Stop Won't Stop Consulting",
    testimonial:
      '“Giving Side has helped me live out my values, both as an organizer and member of many cherished communities. By providing a dedicated reminder of my relationships to amazing people, organizations and causes, Giving Side has been a well of inspiration, supporting me to share more love, support and mutuality.”',
    image: '/gregoryCendana.png',
  },
  {
    name: 'Ed Fields',
    role: 'Senior Advisor & Chief Strategist at City of Birmingham',
    testimonial:
      "“Civic participation is a muscle that we're asked to exercise regularly. Mario and his team at Giving Side have been there for me, encouraging and scaffolding my practice of supporting the institutions and communities that shape and support me.”",
    image: '/edFields.png',
  },
  {
    name: 'Veronica Garcia',
    role: 'Founder, Wealth Reclamation Academy of Practioners (WRAP)',
    testimonial:
      '“As a longtime nonprofit fundraiser, and now movement resource mobilizer, I’m deeply grateful to get to practice a craft that aligns with my life values. My Giving Side is a living model of the trust, love, and cooperation that connect the most generative relationships of my life, across home, work, and community. It maps the milestones of my own transformative journey through these uncertain times.”',
    image: '/veronicaGarcia.png',
  },
];

export default Testimonials;
