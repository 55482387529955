import { Affix, Col, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React from 'react';

import { Text, Title } from 'src/client/components';
import DescriptionDropdown from 'src/client/components/DescriptionDropdown';
import GivingGoalCard from 'src/client/components/GivingGoalCard';
import { Box } from 'src/client/components/StyledCommon';
import { DASHBOARD_VIEW } from 'src/client/constants/DonorDashboard';
import { YEAR_VIEW } from 'src/client/constants/DonorPages';
import { useDonorDashboardGives } from 'src/client/hooks/useDonorDashboardView';
import { useGetYearsSinceFirstGive } from 'src/client/hooks/useGetYearsSinceFirstGive';
import { Donor } from 'src/commons/types';

import RecentGivesCard from '../../components/RecentGivesCard';

import ByTheNumbersSection from './components/ByTheNumbersSection';
import GivesSection from './components/GivesSection';
import GivingOverTimeCard from './components/GivingOverTimeCard';
import GivingTypeSection from './components/GivingTypeSection';
import RecurringGivesCard from './components/RecurringGivesCard';
import TopGivesSection from './components/TopGivesSection';
import TopPlatformsSection from './components/TopPlatformsSection';
import * as S from './styles';

type Props = {
  donor: Donor;
};

const horizontalRowGutter = 32;
const verticalRowGutter = 24;
const targetTop = 50;

function DonorDashboard(props: Props) {
  const { donor } = props;

  const {
    dashboardView,
    giveYear,
    gives,
    isGivesLoading,
    switchDashboardView,
  } = useDonorDashboardGives(donor.id);
  const { giveYears } = useGetYearsSinceFirstGive(donor.id, true);

  const screen = useBreakpoint();

  function onChange(value: string) {
    switchDashboardView(value);
  }

  function handleOnFeedbackClick() {
    window.location.href = 'mailto:hello@givingside.com';
  }

  const yearView =
    giveYear === undefined ? YEAR_VIEW.ALL_TIME : Number(giveYear);

  const carouselAndGivingGoalSection = (
    <Row gutter={[horizontalRowGutter, verticalRowGutter]}>
      <Col lg={11} sm={24} xs={24}>
        <GivingGoalCard donor={donor} yearView={yearView} />
      </Col>
      <Col lg={13} sm={24} xs={24}>
        <RecentGivesCard
          gives={gives}
          isGivesLoading={isGivesLoading}
          yearView={yearView}
        />
      </Col>
    </Row>
  );

  return (
    <>
      <Title title="Dashboard - Giving Side" />
      <S.Container>
        <DescriptionDropdown donor={donor} />
        {screen.sm && <Affix offsetTop={targetTop}>
          <S.TabsContainer>
            <S.StyledTabs
              centered
              activeKey={dashboardView}
              items={[
                ...giveYears.reverse().map(year => ({
                  key: year.toString(),
                  label: year,
                  children: null,
                })),
                {
                  key: DASHBOARD_VIEW.ALL_TIME,
                  label: 'All Time',
                  children: null,
                }
              ]}
              tabPosition='top'
              onChange={onChange}
            />
          </S.TabsContainer>
        </Affix>}
        <S.Content justify="center">
          <Col md={20} xs={22}>
            <Row>
              <Col span={24}>
                <S.HeaderContainer>
                  <Text type="h1bold2">Your Dashboard</Text>
                </S.HeaderContainer>
              </Col>
            </Row>
            {carouselAndGivingGoalSection}
            <TopGivesSection gives={gives} isGivesLoading={isGivesLoading} />
            <Box margin="50px 0 0 0" />
            <Row
              gutter={[horizontalRowGutter, verticalRowGutter]}
              justify="space-between"
            >
              <Col lg={14} sm={24} xs={24}>
                <GivingOverTimeCard
                  dashboardView={dashboardView}
                  gives={gives}
                  isGivesLoading={isGivesLoading}
                />
              </Col>
              <Col lg={10} sm={24} xs={24}>
                <RecurringGivesCard
                  gives={gives}
                  isGivesLoading={isGivesLoading}
                />
              </Col>
            </Row>
            <ByTheNumbersSection
              gives={gives}
              isGivesLoading={isGivesLoading}
            />
            <GivesSection donor={donor} giveYear={giveYear} />
            <GivingTypeSection gives={gives} isGivesLoading={isGivesLoading} />
            <TopPlatformsSection
              gives={gives}
              isGivesLoading={isGivesLoading}
            />
            <S.StyledFeedback onClick={handleOnFeedbackClick} />
          </Col>
        </S.Content>
      </S.Container >
    </>
  );
}

export default DonorDashboard;
