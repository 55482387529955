import { TimePicker as AntdTimePicker } from 'antd';

import styled from 'styled-components';

import { BODY_2_REG_2 } from 'src/client/constants/text';

export const StyledTimePicker = styled(AntdTimePicker)`
  padding: 0 8px;
  height: ${(props) => props.theme.input.middle.height};
  width: 100%;
  border-radius: ${(props) => props.theme.input.middle.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.darkEmphasis3};

  && input {
    ${BODY_2_REG_2};
  }

  :hover,
  &.ant-picker-focused {
    border: 1px solid black;
    box-shadow: none;
  }

  &.ant-picker-large {
    padding: 0 12px;
    height: ${(props) => props.theme.input.large.height};
    border-radius: ${(props) => props.theme.input.large.borderRadius};
  }
`;
