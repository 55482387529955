import React, { useEffect } from 'react';

import { Flex } from 'src/client/components';
import { useNavigateNewerAndOlderUnprocessedGives } from 'src/client/hooks/useNavigateNewerAndOlderUnprocessedGives';
import { Give } from 'src/commons/types';

import * as S from './styles';

type Props = {
  give: Give | undefined;
};

enum Keys {
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
}

function NavigationButtons(props: Props) {
  const { give } = props;

  const {
    goToNewerUnprocessedGive,
    goToOlderUnprocessedGive,
    newerUnprocessedGive,
    olderUnprocessedGive,
  } = useNavigateNewerAndOlderUnprocessedGives(give);

  useEffect(() => {
    document.addEventListener('keyup', handleKeyup);

    return () => document.removeEventListener('keyup', handleKeyup);
  }, [olderUnprocessedGive?.id, newerUnprocessedGive?.id]);

  function handleKeyup(e: KeyboardEvent) {
    const target = e.target as HTMLElement;

    if (target.nodeName !== 'BODY') {
      return null;
    }

    if (e.key === Keys.ARROW_RIGHT && newerUnprocessedGive) {
      goToNewerUnprocessedGive();
    }

    if (e.key === Keys.ARROW_LEFT && olderUnprocessedGive) {
      goToOlderUnprocessedGive();
    }
  }

  return (
    <Flex justifyContent="space-between">
      <S.PrevGiveButton
        data-cy="prev-give-btn"
        disabled={!olderUnprocessedGive}
        isVisible={!!olderUnprocessedGive}
        type="variant1"
        onClick={goToOlderUnprocessedGive}
      >
        <img src="/arrowIcon.png" />
        Previous Give
      </S.PrevGiveButton>
      <S.NextGiveButton
        data-cy="next-give-btn"
        disabled={!newerUnprocessedGive}
        isVisible={!!newerUnprocessedGive}
        type="variant1"
        onClick={goToNewerUnprocessedGive}
      >
        Next Give
        <img src="/arrowIcon.png" />
      </S.NextGiveButton>
    </Flex>
  );
}

export default NavigationButtons;
