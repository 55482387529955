import styled from 'styled-components';

import { Button, Select } from 'src/client/components';
import { BUTTON_1_2 } from 'src/client/constants/text';

export const StatusSelect = styled(Select)`
  && {
    width: 400px;
  }
`;

export const StyledButton = styled(Button)`
  & > span {
    ${BUTTON_1_2}
    color: ${(props) => props.theme.colors.darkEmphasis2};
  }
`;
