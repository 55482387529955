import {
  Area,
  LineConfig,
  AreaConfig,
  ColumnConfig,
  Plot,
  PlotEvent,
  Datum,
} from '@ant-design/charts';
import React from 'react';

import { useTheme } from 'styled-components';

import defaultTheme from 'src/client/themes/default';
import {
  GIVE_UNIT,
  GroupedGiveByMonth,
  GroupedGiveByYear,
} from 'src/commons/types';
import { formatToCurrency } from 'src/commons/utils/MoneyUtilts';

type Props = {
  className?: string;
  data: GroupedGiveByMonth[] | GroupedGiveByYear[];
  xField: string;
  yField: string;
  giveUnit: GIVE_UNIT;
};

let PreTooltipData: { date: string; value: number };

type Base = LineConfig | AreaConfig | ColumnConfig;

const PlotMaps: Record<string, Plot<Base>> = {};

const axisLabelStyle = {
  fill: 'white',
  fontWeight: defaultTheme.fontWeights.regular,
  fontSize: 12,
};

export default function LineGraph(props: Props) {
  const { className, data, xField, yField, giveUnit } = props;

  const { colors } = useTheme();

  const showTooltip = ({ x, y }: { x: number; y: number }) => {
    Object.keys(PlotMaps).forEach((plot) => {
      PlotMaps[plot].chart.showTooltip({ x, y });
    });
  };

  const setTooltipPosition = (evt: PlotEvent, plot: Plot<Base>) => {
    const { x, y } = evt.gEvent;
    const currentData = plot.chart.getTooltipItems({ x, y });

    if (currentData[0]?.data.date === PreTooltipData?.date) {
      return;
    }

    PreTooltipData = currentData[0]?.data;
    showTooltip({ x, y });
  };

  const config: AreaConfig = {
    data,
    xField,
    yField,
    height: 200,
    areaStyle: {
      fill: giveUnit == GIVE_UNIT.DOLLARS ? colors.teal1 : colors.magenta500,
      fillOpacity: 1,
    },
    color: giveUnit == GIVE_UNIT.DOLLARS ? colors.teal1 : colors.magenta500,
    meta: {
      [yField]: {
        // eslint-disable-next-line no-magic-numbers
        max: data.some(d => d.totalAmount > 4) ? undefined : 4,
      },
    },
    yAxis: {
      label: {
        formatter: (text) => {
          const localizedString = Number(text).toLocaleString();

          return giveUnit == GIVE_UNIT.DOLLARS ? `$${localizedString}` : `${localizedString} hrs`
        },
        style: axisLabelStyle,
      },
      grid: {
        line: {
          style: {
            stroke: colors.lightLowEmphasis,
          },
        },
      },
    },
    xAxis: {
      label: {
        style: axisLabelStyle,
      },
    },
    tooltip: {
      formatter: (datum: Datum) => {
        const formattedValue =
          giveUnit === GIVE_UNIT.DOLLARS
            ? formatToCurrency(datum.totalAmount)
            : `${datum.totalAmount} hrs`;

        return {
          name:
            giveUnit === GIVE_UNIT.DOLLARS
              ? 'Total Give Amount'
              : 'Total Volunteer Hour',
          value:
            typeof datum.totalAmount === 'number'
              ? formattedValue
              : datum.totalAmount,
        };
      },
      domStyles: {
        'g2-tooltip': {
          backgroundColor: colors.darkSurface3,
          boxShadow: 'none',
        },
        'g2-tooltip-title': {
          color: 'white',
        },
        'g2-tooltip-name': {
          color: 'white',
        },
        'g2-tooltip-value': {
          color: 'white',
        },
      },
    },
  };

  return (
    <Area
      {...config}
      className={className}
      onReady={(plot) => {
        PlotMaps.area = plot as any;
        plot.on('mousemove', (evt: PlotEvent) => {
          setTooltipPosition(evt, plot as any);
        });
      }}
    />
  );
}
