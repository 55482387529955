export const ETHNICITIES = {
  AMERICAN_INDIAN: {
    legacyId: '28',
    name: 'american_indian',
  },
  ASIAN: {
    legacyId: '29',
    name: 'asian',
  },
  BLACK: {
    legacyId: '26',
    name: 'black',
  },
  HISPANIC: {
    legacyId: '27',
    name: 'hispanic',
  },
  NATIVE_HAWAIIAN: {
    legacyId: '30',
    name: 'native_hawaiian',
  },
  WHITE: {
    legacyId: '25',
    name: 'white',
  },
  OTHER: {
    legacyId: '31',
    name: 'other',
  },
};
