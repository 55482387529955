import React from 'react';

import PrivacyStatusInfoBase from 'src/client/pages/DonorGallery/components/PrivacyStatusInfo';
import { Page } from 'src/commons/types';

import * as stylesheet from './styles';

type Props = {
  isViewingPublicly: boolean;
  page: Page;
};

function PrivacyStatusInfo(props: Props) {
  return (
    <PrivacyStatusInfoBase
      {...props}
      buttonType="secondary"
      styles={stylesheet}
    />
  );
}

export default PrivacyStatusInfo;
