import RollbackOutlined from '@ant-design/icons/RollbackOutlined';

import React from 'react';

import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';

import * as S from '../styles';

function Header() {
  const history = useHistory();

  function handleOnClickGoBack() {
    history.goBack();
  }

  return (
    <>
      <Router>
        <S.NavWrapper>
          <Link to="/">
            <S.Logo src="/logo-white.png" />
          </Link>

          <S.StyledBackButton type="secondary" onClick={handleOnClickGoBack}>
            Go Back
            <RollbackOutlined />
          </S.StyledBackButton>
        </S.NavWrapper>
      </Router>
    </>
  );
}

export default Header;
