import { Input } from 'antd';
import styled, { css } from 'styled-components';

export type GalleryTextAreaProps = {
  isEditing: boolean;
};

const { TextArea } = Input;

export const GalleryTextArea = styled(TextArea)<GalleryTextAreaProps>`
  padding: 10px;
  background-color: transparent !important;
  border: 4px solid ${(props) => props.theme.colors.darkPrimary100};
  outline: none;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.black};

  &:hover,
  &:focus {
    border: 4px solid ${(props) => props.theme.colors.darkPrimary100};
    box-shadow: none;
  }

  ${(props) =>
    !props.isEditing &&
    css`
      border: none;
      padding: 0;

      &:hover,
      &:focus {
        border: none;
        box-shadow: none;
      }
    `}
`;
