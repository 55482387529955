import {
  GetDonorGivingGoalParams,
  ResetPasswordParams,
} from 'src/client/types/ApiParams';
import {
  Donor,
  DonorEthnicity,
  DonorsFilter,
  Give,
  Goal,
} from 'src/commons/types';
import {
  CompareDonorPasswordParams,
  CreateDonorEthnicitiesParams,
  DeleteDonorEthnicitiesParams,
  UpdateDonorEthnicityParams,
  UpdateDonorGenderParams,
  UpdateDonorParams,
} from 'src/commons/types/Params.type';
import { ApiResponse } from 'src/commons/types/Response.type';
import { formatToFinancial, toIntMoney } from 'src/commons/utils/MoneyUtilts';

import ApiClient from './ApiClient';

const apiVersion = '1.0';
const endpoint = 'donors';
const url = `${apiVersion}/${endpoint}`;

type VerifyResetPasswordTokenResponse = {
  isValid: boolean;
  donorEmail: string | null;
  isTokenExpired: boolean;
};

type MergeAccountsParams = {
  sourceDonorMergeToken: string;
  destinationDonorId: string;
};

type SendConfirmMergeAccountsEmailParams = {
  sourceDonorEmail: string;
  destinationDonorId: string;
};

export type GetDonorEthnicityResponse = (DonorEthnicity & {
  ethnicity: string;
})[];

export async function getDonorByIds(ids: string[]): Promise<Donor[]> {
  const { data } = await ApiClient.post<Donor[]>(`${url}/ids`, {
    donorIds: ids,
  });

  return data;
}

export async function updateDonor(params: UpdateDonorParams): Promise<Donor> {
  const { data } = await ApiClient.put<Donor>(`${url}/${params.id}`, params);

  return data;
}

export async function getDonorGender(
  donorId: string
): Promise<{ gender: null | undefined | string; isOther: boolean }> {
  const { data } = await ApiClient.get<{
    gender: null | undefined | string;
    isOther: boolean;
  }>(`${url}/${donorId}/gender`);

  return data;
}

export async function updateDonorGender(
  params: UpdateDonorGenderParams
): Promise<{ message: string }> {
  const { donorId, gender, isOther } = params;

  const { data } = await ApiClient.put<{ message: string }>(
    `${url}/${donorId}/gender`,
    {
      gender,
      isOther,
    }
  );

  return data;
}

export async function getDonorEthnicities(
  donorId: string
): Promise<GetDonorEthnicityResponse> {
  const { data } = await ApiClient.get<GetDonorEthnicityResponse>(
    `${url}/${donorId}/ethnicities`
  );

  return data;
}

export async function updateDonorEthnicities(
  params: UpdateDonorEthnicityParams
): Promise<ApiResponse<DonorEthnicity[]>> {
  const { donorId, donorEthnicities } = params;

  const { data } = await ApiClient.put<ApiResponse<DonorEthnicity[]>>(
    `${url}/${donorId}/ethnicities`,
    {
      donorEthnicities,
    }
  );

  return data;
}

export async function deleteDonorEthnicities(
  params: DeleteDonorEthnicitiesParams
): Promise<{ message: string }> {
  const { deletedDonorEthnicityIds, donorId } = params;

  const { data } = await ApiClient.delete<{ message: string }>(
    `${url}/${donorId}/ethnicities`,
    {
      params: {
        deletedDonorEthnicityIds,
      },
    }
  );

  return data;
}

export async function createDonorEthnicities(
  params: CreateDonorEthnicitiesParams
): Promise<ApiResponse<DonorEthnicity[]>> {
  const { donorId, donorEthnicities } = params;

  const { data } = await ApiClient.post<ApiResponse<DonorEthnicity[]>>(
    `${url}/${donorId}/ethnicities`,
    {
      donorEthnicities,
    }
  );

  return data;
}

export async function getDonorFirstGive(donorId: string): Promise<Give> {
  const { data } = await ApiClient.get<Give>(`${url}/${donorId}/first-give`);

  return data;
}

export async function getDonorGoal(
  params: GetDonorGivingGoalParams
): Promise<Goal | undefined> {
  const { donorId, ...query } = params;

  const { data } = await ApiClient.get<Goal | undefined>(
    `${url}/${donorId}/goal`,
    {
      params: query,
    }
  );

  if (!data) {
    return undefined;
  }

  return {
    ...data,
    amount: data.amount && formatToFinancial(data.amount),
    income: data.income && formatToFinancial(data.income),
  };
}

export async function createDonorGoal(
  params: Partial<Goal> & { donorId: string }
): Promise<Goal> {
  const { donorId, ...goal } = params;

  const { data } = await ApiClient.post<Goal>(`${url}/${donorId}/goal`, {
    ...goal,
    amount: goal.amount && toIntMoney(goal.amount),
    income: goal.income && toIntMoney(goal.income),
  });

  return {
    ...data,
    amount: data?.amount && formatToFinancial(data.amount),
    income: data?.income && formatToFinancial(data.income),
  };
}

export async function updateDonorGoal(
  params: Partial<Omit<Goal, 'id'>> & { donorId: string; goalId: string }
): Promise<Goal> {
  const { donorId, ...goal } = params;

  const { data } = await ApiClient.put<Goal>(`${url}/${donorId}/goal`, {
    ...goal,
    amount: goal.amount && toIntMoney(goal.amount),
    income: goal.income && toIntMoney(goal.income),
  });

  return {
    ...data,
    amount: data?.amount && formatToFinancial(data.amount),
    income: data?.income && formatToFinancial(data.income),
  };
}

export async function getDonors(
  filter: DonorsFilter
): Promise<ApiResponse<Donor[]>> {
  const { data: donorsResponse } = await ApiClient.post<ApiResponse<Donor[]>>(
    url,
    filter
  );

  const formattedDonorsResponseData = donorsResponse.data.map((donor) => ({
    ...donor,
    currentYearGoalAmount:
      donor.currentYearGoalAmount &&
      formatToFinancial(donor.currentYearGoalAmount),
  }));

  return {
    ...donorsResponse,
    data: formattedDonorsResponseData,
  };
}

export async function deleteDonorById(
  donorId: string
): Promise<ApiResponse<string>> {
  const { data } = await ApiClient.delete<ApiResponse<string>>(
    `${url}/${donorId}`
  );

  return data;
}

export async function getDonorById(
  donorId: string
): Promise<Donor | undefined> {
  const { data } = await ApiClient.get<Donor | undefined>(`${url}/${donorId}`);

  return data;
}

export async function updateDonorPassword(
  params: CompareDonorPasswordParams
): Promise<Donor | undefined> {
  const { donorId, newPassword, currentPasswordVerification } = params;

  const { data } = await ApiClient.patch(`${url}/${donorId}/password`, {
    newPassword,
    currentPasswordVerification,
  });

  return data;
}

export async function verifyResetPasswordToken(
  token: string
): Promise<VerifyResetPasswordTokenResponse> {
  const { data } = await ApiClient.get<VerifyResetPasswordTokenResponse>(
    `${url}/password/token/${token}`
  );

  return data;
}

export async function resetDonorPassword(
  params: ResetPasswordParams
): Promise<ApiResponse> {
  const { data } = await ApiClient.put<ApiResponse>(
    `${url}/password/token/${params.token}`,
    {
      password: params.password,
    }
  );

  return data;
}

export async function sendSetInitialPasswordEmail(donorEmail: string) {
  const { data } = await ApiClient.post(`${url}/initial-password/email`, {
    donorEmail,
  });

  return data;
}

export async function sendResetPasswordEmail(donorEmail: string) {
  const { data } = await ApiClient.post(`${url}/password/reset/email`, {
    donorEmail,
  });

  return data;
}

export async function sendConfirmMergeAccountsEmail(
  params: SendConfirmMergeAccountsEmailParams
) {
  const { sourceDonorEmail, destinationDonorId } = params;

  const { data } = await ApiClient.post(`${url}/merge/confirm-email`, {
    sourceDonorEmail,
    destinationDonorId,
  });

  return data;
}

export type MergeAccountsApiResponse = ApiResponse<{
  destinationDonor: Donor;
  sourceDonor: Donor;
}>;

export async function mergeAccounts(
  params: MergeAccountsParams
): Promise<MergeAccountsApiResponse> {
  const { sourceDonorMergeToken, destinationDonorId } = params;

  const { data } = await ApiClient.post<MergeAccountsApiResponse>(
    `${url}/merge`,
    {
      sourceDonorMergeToken,
      destinationDonorId,
    }
  );

  return data;
}
