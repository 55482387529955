import { RadioChangeEvent } from 'antd';
import React from 'react';

import { LoadingCard } from 'src/client/components';
import ContentTitle from 'src/client/pages/AdminPlatformDashboard/components/ContentTitle';

import * as S from './styles';

type Props = {
  children?: React.ReactNode;
  isLoading: boolean;
  onButtonGroupChange: (value: GALLERY_VIEW) => void;
  title: string;
  buttonGroupValue: GALLERY_VIEW;
};

export enum GALLERY_VIEW {
  GIVE_COUNT = 'Number of Gives',
  TOTAL_AMOUNT = 'Total Amount',
}

function GalleryContainer(props: Props) {
  const { buttonGroupValue, children, isLoading, onButtonGroupChange, title } =
    props;

  function onChange(e: RadioChangeEvent) {
    onButtonGroupChange(e.target.value);
  }

  return (
    <S.Container>
      <S.HeaderContainer>
        <ContentTitle title={title} />
        <S.StyledButtonGroup
          isDisabled={isLoading}
          value={buttonGroupValue}
          value1={GALLERY_VIEW.GIVE_COUNT}
          value2={GALLERY_VIEW.TOTAL_AMOUNT}
          onChange={onChange}
        />
      </S.HeaderContainer>
      <S.ContentContainer>
        <LoadingCard isLoading={false}>{children}</LoadingCard>
      </S.ContentContainer>
    </S.Container>
  );
}

export default GalleryContainer;
