import styled from 'styled-components';

import { Input, Radio } from 'src/client/components';

export const OtherGenderInput = styled(Input)`
  margin-bottom: 60px;
  width: 282px;
`;

export const StyledRadio = styled(Radio)`
  color: black;
  text-transform: capitalize;
`;

export const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

export const EthnicityOptionContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;

  .ant-form-item {
    margin: 0;
  }
`;
