import styled, { createGlobalStyle } from 'styled-components';

import {
  FlexCenter,
  minimalisticScrollBar,
} from 'src/client/components/StyledCommon';
import Text from 'src/client/components/Text';
import { BUTTON_1_2, H3_MED_2 } from 'src/client/constants/text';

export const DescriptionDropdownGlobalStyle = createGlobalStyle`
  .give-adjective-dropdown {
    border-radius: 8px;
    overflow: hidden;
    ${minimalisticScrollBar};
  }

  .give-adjective-dropdown .ant-dropdown-menu {
    height: 192px;
    border-radius: 8px;
    background: ${(props) => props.theme.colors.darkSurface3};
  }

  .give-adjective-dropdown .ant-dropdown-menu-title-content {
    color: white;
    ${BUTTON_1_2};
  }

  .ant-dropdown-menu-item.ant-dropdown-menu-item-active, .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active, .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active, .ant-dropdown-menu-submenu-title:hover {
    background: ${(props) => props.theme.colors.darkSurface2};
  }
`;

export const Container = styled.div`
  display: flex;
  height: 331px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url('/homecoverDark.png');
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;

  .anticon-caret-down {
    margin-left: 16px;
    color: ${(props) => props.theme.colors.darkPrimary100};
    font-size: 25px;
  }
`;

export const Title = styled(Text)`
  margin-bottom: 4px;
  text-transform: uppercase;
  ${H3_MED_2};
`;

type AccountDescriptionTextProps = {
  isMutating: boolean;
};

export const AccountDescriptionText = styled(Text)<AccountDescriptionTextProps>`
  color: ${(props) =>
    props.isMutating ? props.theme.colors.lightEmphasis3 : 'white'};
`;

export const AccountDescriptionContainer = styled(FlexCenter)`
  cursor: pointer;
`;
