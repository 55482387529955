import {
  addHours,
  differenceInHours,
  eachMonthOfInterval,
  format,
  isAfter,
} from 'date-fns';
import moment, { Moment } from 'moment';

import { FULL_MONTH_DATE } from '../constants/dateFormat';
import {
  ISO_8601_NON_ZULU_DATE_PATTERN,
  ISO_8601_ZULU_DATE_PATTERN,
} from '../constants/regex';

type DateParam = string | Date | undefined | null;

const one_minute_in_millis = 60_000;

export function parseDate(dateParam: DateParam): null | Date {
  const date = new Date(dateParam as string);

  return dateParam && isFinite(date as unknown as number) ? date : null;
}

export function getYears(initialDate: Date, endDate: Date): number[] {
  if (!initialDate || !endDate) {
    return [];
  }

  const initialYear = new Date(initialDate).getFullYear();
  const endYear = new Date(endDate).getFullYear();
  const yearDifference = endYear - initialYear;

  return [...Array(yearDifference + 1).keys()].map(
    (index) => initialYear + index
  );
}

export function getCurrentYear(): number {
  return new Date().getFullYear();
}

export function convertMomentDateRangeToDate(
  dateRange: [Moment, Moment]
): [Date, Date] {
  const dateCreatedFrom = dateRange[0].toDate();
  const dateCreatedTo = dateRange[1].toDate();

  return [dateCreatedFrom, dateCreatedTo];
}

export function convertStringDateRangeToDate(
  dateRange: [string, string]
): [Date, Date] {
  const dateCreatedFrom = new Date(dateRange[0]);
  const dateCreatedTo = new Date(dateRange[1]);

  return [dateCreatedFrom, dateCreatedTo];
}

export function convertStringDateRangeToMoment(
  dateRange: [string, string]
): [Moment, Moment] {
  const dateCreatedFrom = moment(new Date(dateRange[0]));
  const dateCreatedTo = moment(new Date(dateRange[1]));

  return [dateCreatedFrom, dateCreatedTo];
}

export function formatDate(
  date?: Date | string,
  format: string = FULL_MONTH_DATE
) {
  if (!date) {
    return '';
  }

  return moment(new Date(date)).format(format);
}

export function isTokenExpired(date: Date, durationInHour = 1): boolean {
  const dateOfExpiration = addHours(date, durationInHour);

  return isAfter(new Date(), dateOfExpiration);
}

export function getAllMonths() {
  const lastMonth = 11;
  const months = eachMonthOfInterval({
    start: new Date(0, 0),
    end: new Date(0, lastMonth),
  });

  return months.map((date) => format(date, 'MMM'));
}

function isIsoZuluDate(date: string | Date): date is string {
  return typeof date === 'string' && ISO_8601_ZULU_DATE_PATTERN.test(date);
}

function isIsoNonZuluDate(date: string | Date): date is string {
  return typeof date === 'string' && ISO_8601_NON_ZULU_DATE_PATTERN.test(date);
}

export function removeTimezone(date: Date | string): string {
  if (typeof date === 'string') {
    if (isIsoZuluDate(date)) {
      return date.replace('Z', '');
    } else if (isIsoNonZuluDate(date)) {
      return date;
    }

    throw new Error('Invalid date format');
  }

  const tzoffset = date.getTimezoneOffset() * one_minute_in_millis; //offset in milliseconds

  return new Date(date.valueOf() - tzoffset).toISOString().slice(0, -1);
}

export function createDateFromYear(year: number) {
  if (typeof year !== 'number' || year < 0) {
    throw new Error('Invalid year provided');
  }

  return new Date(year, 0, 1, 0, 0, 0, 0); // Month is 0-indexed
}

export function isDateCertainHoursOld(
  statRecalculationBatchDateUpdated: Date,
  hours: number
) {
  const now = new Date();
  const pastDate = new Date(statRecalculationBatchDateUpdated);
  const hoursDifference = differenceInHours(now, pastDate);

  return hoursDifference >= hours;
}
