/* eslint-disable sonarjs/cognitive-complexity */
import { EyeOutlined, LinkOutlined, LockOutlined } from '@ant-design/icons';
import { Grid, message } from 'antd';
import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Box, Button, Flex, FlexCenter, Text } from 'src/client/components';
import routes from 'src/commons/constants/routes';
import { Page } from 'src/commons/types';

import { replaceRouteParams } from 'src/commons/utils/RouteUtils';

import { UnderlinedButton } from '../../styles';

import { getPublicGalleryUrl } from '../../utils';
import PrivacyChangeConfirmPopover from '../PrivacyChangeConfirmPopover';

import * as S from './styles';

type Props = {
  page: Page;
  isSpacer: boolean;
};

const { useBreakpoint } = Grid;

function NavbarViewMode(props: Props) {
  const { isSpacer, page } = props;

  const screens = useBreakpoint();
  const [isPrivate, setIsPrivate] = useState(!page.isVisible);
  const history = useHistory();

  const { publicLinkDisplay, publicLink } = getPublicGalleryUrl(page.id);

  function openPreview() {
    window.open(replaceRouteParams(routes.PUBLIC_GALLERY, { pageId: page.id }));
  }

  function copyPublicLink() {
    navigator.clipboard.writeText(publicLink);
    message.info('Copied link!');
  }

  function redirectToRoot() {
    history.push(routes.ROOT);
  }

  const privacyText = isPrivate ? (
    <Text type="body1med2">Only you can see your gallery</Text>
  ) : (
    <Text type="body1med2">Anyone with the link can view</Text>
  );
  const privacyButton = isPrivate ? (
    <UnderlinedButton data-cy={isSpacer ? undefined : 'publish-btn'}>
      Publish to share
    </UnderlinedButton>
  ) : (
    <UnderlinedButton data-cy={isSpacer ? undefined : 'unpublish-btn'}>
      Unpublish
    </UnderlinedButton>
  );
  const privacyIcon = isPrivate ? (
    <LockOutlined />
  ) : (
    <img src="/earthOutlineIcon.png" />
  );

  function renderContent() {
    const isBigScreen = screens.md;

    if (isBigScreen) {
      return (
        <>
          <S.Logo src="/logo-white.png" onClick={redirectToRoot} />
          <FlexCenter>
            {privacyIcon}
            <Box margin="0 8px 0 9px">{privacyText}</Box>
            <PrivacyChangeConfirmPopover
              isPrivate={isPrivate}
              page={page}
              publicLink={publicLink}
              publicLinkDisplayText={publicLinkDisplay}
              setIsPrivate={setIsPrivate}
            >
              {privacyButton}
            </PrivacyChangeConfirmPopover>
          </FlexCenter>
          <Button type="variant2" onClick={openPreview}>
            Preview
          </Button>
        </>
      );
    } else {
      const privacyText = isPrivate ? (
        <Text type="body1med2">Private</Text>
      ) : (
        <Text type="body1med2">Public</Text>
      );
      const privacyButton = isPrivate ? (
        <Button data-cy={isSpacer ? undefined : 'publish-btn'} type="variant2">
          Public
        </Button>
      ) : (
        <Button
          data-cy={isSpacer ? undefined : 'unpublish-btn'}
          type="variant2"
        >
          Unpublish
        </Button>
      );
      const previewButton = isPrivate ? null : (
        <Button
          data-cy={isSpacer ? undefined : 'mobile-preview-btn'}
          type="variant2"
          onClick={openPreview}
        >
          <EyeOutlined />
        </Button>
      );
      const copyLinkButton = isPrivate ? null : (
        <Button
          data-cy={isSpacer ? undefined : 'mobile-copy-link-btn'}
          type="variant2"
          onClick={copyPublicLink}
        >
          <LinkOutlined />
        </Button>
      );

      return (
        <>
          <Flex>
            <Box margin="0 21px 0 0">
              <S.Logo src="/givingSideIcon.png" />
            </Box>
            <FlexCenter>
              {privacyIcon}
              <Box margin="0 0 0 11px">{privacyText}</Box>
            </FlexCenter>
          </Flex>
          <S.ActionButtonsContainer>
            <PrivacyChangeConfirmPopover
              isPrivate={isPrivate}
              page={page}
              publicLink={publicLink}
              publicLinkDisplayText={publicLinkDisplay}
              setIsPrivate={setIsPrivate}
            >
              {privacyButton}
            </PrivacyChangeConfirmPopover>
            {previewButton}
            {copyLinkButton}
          </S.ActionButtonsContainer>
        </>
      );
    }
  }

  return (
    <S.Container
      animate={{
        y: 0,
      }}
      data-cy={isSpacer ? undefined : 'navbar-view-mode'}
      exit={{
        y: -100,
      }}
      initial={{ y: -100 }}
      isSpacer={isSpacer}
    >
      {renderContent()}
    </S.Container>
  );
}

export default NavbarViewMode;
