import { Admin } from 'src/commons/types/Admin.type';
import { AdminDashboardStatsCount } from 'src/commons/types/Counter.type';
import { GetAdminsQuery } from 'src/commons/types/Params.type';
import { ApiResponse } from 'src/commons/types/Response.type';

import { ResetPasswordParams } from '../types/ApiParams';

import ApiClient from './ApiClient';

const apiVersion = '1.0';
const endpoint = 'admins';
const url = `${apiVersion}/${endpoint}`;

type GetAdminDashboardStatsResponse = {
  adminDashboardStats: AdminDashboardStatsCount;
  dateUpdated: Date;
};

type VerifyResetPasswordTokenResponse = {
  isValid: boolean;
  adminEmail: string | null;
  isTokenExpired: boolean;
};

export async function getAdmin(id: string): Promise<Admin> {
  const { data } = await ApiClient.get<Admin>(`${url}/${id}`);

  return data;
}

export async function getAdmins(params: GetAdminsQuery): Promise<Admin[]> {
  const { data } = await ApiClient.get<Admin[]>(url, {
    params: {
      jsonQuery: JSON.stringify(params),
    },
  });

  return data;
}

export async function createAdmin(
  params: Partial<Admin>
): Promise<ApiResponse<Admin>> {
  const { data } = await ApiClient.post<ApiResponse<Admin>>(url, params);

  return data;
}

export async function updateAdmin(
  params: Partial<Admin>
): Promise<ApiResponse<Admin>> {
  const { data } = await ApiClient.put<ApiResponse<Admin>>(
    `${url}/${params.id}`,
    params
  );

  return data;
}

export async function deleteAdmin(
  params: Partial<Admin>
): Promise<ApiResponse<Admin>> {
  const { data } = await ApiClient.delete<ApiResponse<Admin>>(
    `${url}/${params.id}`
  );

  return data;
}

export async function getAdminDashboardStats(): Promise<GetAdminDashboardStatsResponse> {
  const { data } = await ApiClient.get<GetAdminDashboardStatsResponse>(
    `${url}/dashboard`
  );

  return data;
}

export async function recalculateAdminDashboardStats() {
  const { data } = await ApiClient.put(`${url}/dashboard`);

  return data;
}

export async function sendAdminResetPasswordEmail(adminEmail: string) {
  const { data } = await ApiClient.post(`${url}/password/reset/email`, {
    adminEmail,
  });

  return data;
}

export async function resetAdminPassword(
  params: ResetPasswordParams
): Promise<ApiResponse> {
  const { data } = await ApiClient.put<ApiResponse>(
    `${url}/password/token/${params.token}`,
    {
      password: params.password,
    }
  );

  return data;
}

export async function verifyAdminResetPasswordToken(
  token: string
): Promise<VerifyResetPasswordTokenResponse> {
  const { data } = await ApiClient.get<VerifyResetPasswordTokenResponse>(
    `${url}/password/token/${token}`
  );

  return data;
}
