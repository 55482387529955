import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React from 'react';

import { Direction } from 'src/client/constants/Direction';
import { RECIPIENT_HOMEPAGE_TABS } from 'src/commons/constants/tabs';

import FooterNavigationButton from '../FooterNavigationButton';

import * as S from './styles';

type Props = {
  currentTab: RECIPIENT_HOMEPAGE_TABS;
  onTabChange: (
    key: RECIPIENT_HOMEPAGE_TABS,
    shouldScrollWithoutAnimation?: boolean
  ) => void;
  recipientId: string;
};

type TabsConfig = {
  [key: string]: {
    previousTab?: RECIPIENT_HOMEPAGE_TABS;
    nextTab?: RECIPIENT_HOMEPAGE_TABS;
    name: string;
  };
};

const tabsConfig: TabsConfig = {
  [RECIPIENT_HOMEPAGE_TABS.GIVING_AND_GOAL]: {
    previousTab: RECIPIENT_HOMEPAGE_TABS.OUR_PEOPLE,
    nextTab: RECIPIENT_HOMEPAGE_TABS.HOW_OUR_PEOPLE_GIVE,
    name: 'Giving & Goals',
  },
  [RECIPIENT_HOMEPAGE_TABS.HOW_OUR_PEOPLE_GIVE]: {
    previousTab: RECIPIENT_HOMEPAGE_TABS.GIVING_AND_GOAL,
    name: 'How Our People Give',
  },
  [RECIPIENT_HOMEPAGE_TABS.OUR_PEOPLE]: {
    nextTab: RECIPIENT_HOMEPAGE_TABS.GIVING_AND_GOAL,
    name: 'Our People',
  },
};

function FooterNavigation(props: Props) {
  const { currentTab, recipientId, onTabChange } = props;

  const screen = useBreakpoint();

  const previousTabKey = tabsConfig[currentTab].previousTab;
  const nextTabKey = tabsConfig[currentTab].nextTab;
  const previousTabName = previousTabKey
    ? tabsConfig[previousTabKey].name
    : undefined;
  const nextTabName = nextTabKey ? tabsConfig[nextTabKey].name : undefined;

  function handleClickPrevious() {
    if (previousTabKey) {
      onTabChange(previousTabKey, true);
    }
  }

  function handleClickNext() {
    if (nextTabKey) {
      onTabChange(nextTabKey, true);
    }
  }

  return (
    <S.Container>
      <S.StyledWidthLimit>
        {!screen.lg && (
          <S.StyledRecipientLogo isEditable={false} recipientId={recipientId} />
        )}
        <S.NavigationContainer>
          <FooterNavigationButton
            direction={Direction.LEFT}
            isHidden={!previousTabKey}
            tabName={previousTabName}
            onClick={handleClickPrevious}
          />
          {screen.lg && (
            <S.StyledRecipientLogo
              isEditable={false}
              recipientId={recipientId}
            />
          )}
          <FooterNavigationButton
            direction={Direction.RIGHT}
            isHidden={!nextTabKey}
            tabName={nextTabName}
            onClick={handleClickNext}
          />
        </S.NavigationContainer>
      </S.StyledWidthLimit>
    </S.Container>
  );
}

export default FooterNavigation;
