import React, { forwardRef } from 'react';

import { TextType } from 'src/client/types/Text';

import { StyledText } from './styles';

type Props = React.HTMLProps<HTMLParagraphElement> & {
  type?: TextType;
  children: React.ReactNode | React.ReactNodeArray;
  className?: string;
  color?: string;
  isInline?: boolean;
  as?: undefined;
};

const Text = forwardRef<HTMLParagraphElement, Props>((props, ref) => {
  const { type = 'p', children, color, isInline = false, ...extras } = props;

  return (
    <StyledText
      {...extras}
      color={color}
      isInline={isInline}
      ref={ref}
      type={type}
    >
      {children}
    </StyledText>
  );
});

export default Text;
