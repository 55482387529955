import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { Box, Flex, Text } from 'src/client/components';

import * as S from './styles';

type Props = {
  givesCount: number;
  order: number;
  platformLogo?: string;
  platformName: string;
};

function PlatformCard(props: Props) {
  const { givesCount, order, platformLogo, platformName } = props;
  const { colors } = useContext(ThemeContext);

  const renderPlatformLogo = platformLogo && (
    <S.PlatformLogo logoSrc={platformLogo} />
  );

  return (
    <S.Container>
      <Flex alignItems="center">
        <Box margin="0 27px 0 0">
          <Text color={colors.teal2} type="h3bold2">
            {order}
          </Text>
        </Box>
        <Flex flexDirection="column">
          <Text type="h4med2">{platformName}</Text>
          <Text type="body1reg2">{givesCount} gives</Text>
        </Flex>
      </Flex>
      {renderPlatformLogo}
    </S.Container>
  );
}

export default PlatformCard;
