import styled from 'styled-components';

import { Flex, FlexCenter, Text } from 'src/client/components';

export const Container = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  max-width: 409px;
  min-width: 344px;

  &:hover {
    text-decoration: ${(props) => (props.onClick ? 'underline' : 'none')};
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  }
`;

export const Card = styled(FlexCenter)`
  margin-bottom: 21px;
  width: 100%;
  padding: 18px;
  height: 498px;
  border: 1px solid ${(props) => props.theme.colors.gray7};
  border-radius: 3.71px;
  background-color: ${(props) => props.theme.colors.white};
`;

export const StyledText = styled(Text)`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;
