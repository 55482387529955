import {
  DndContext,
  DragEndEvent,
  SensorDescriptor,
  SensorOptions,
} from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableContext } from '@dnd-kit/sortable';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { forwardRef, useMemo } from 'react';

import { LoadingCard } from 'src/client/components';
import { MAX_FEATURED_CARDS_COUNT } from 'src/commons/constants/gallery';
import { PageRecipient } from 'src/commons/types';

import GiveCardV2 from '../../../DonorGalleryV2/components/CardsSection/components/GiveCard';

import GiveCardBase from '../GiveCard';

import * as stylesheet from './styles';

type Props = {
  unfeaturedPageRecipients: PageRecipient[] | undefined;
  featuredPageRecipients: PageRecipient[] | undefined;
  isLoading: boolean;
  onFeaturePageRecipient: (pageRecipientId: string) => void;
  onHidePageRecipient: (pageRecipientId: string) => void;
  handleDragEnd: (event: DragEndEvent) => void;
  handleDragStart: (event: DragEndEvent) => void;
  sensors: SensorDescriptor<SensorOptions>[];
  showSpotlightText?: boolean;
  styles?: any;
};

const UnfeaturedCardsEditMode = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const {
      unfeaturedPageRecipients,
      featuredPageRecipients,
      isLoading,
      onFeaturePageRecipient,
      onHidePageRecipient,
      sensors,
      handleDragEnd,
      handleDragStart,
      showSpotlightText,
      styles,
    } = props;

    const S = styles ?? stylesheet;
    const GiveCard = styles ? GiveCardV2 : GiveCardBase;

    const screens = useBreakpoint();

    const unfeaturedPageRecipientIds = useMemo(
      () => unfeaturedPageRecipients?.map((pageRecipient) => pageRecipient.id),
      [unfeaturedPageRecipients]
    );

    const unfeaturedCards = useMemo(
      () =>
        unfeaturedPageRecipients?.map((pageRecipient) => (
          <GiveCard
            isEditMode
            data-page-recipient-visibility="unfeatured-card"
            editModeConfig={{
              primaryButtonText: 'Feature',
              isPrimaryButtonHidden:
                featuredPageRecipients?.length === MAX_FEATURED_CARDS_COUNT,
              primaryButtonProps: {
                onClick: () => {
                  onFeaturePageRecipient(pageRecipient.id);
                },
              },
              secondaryButtonProps: {
                onClick: () => onHidePageRecipient(pageRecipient.id),
              },
            }}
            key={pageRecipient.id}
            pageRecipient={pageRecipient}
          />
        )),
      [
        GiveCard,
        featuredPageRecipients?.length,
        onFeaturePageRecipient,
        onHidePageRecipient,
        unfeaturedPageRecipients,
      ]
    );

    const instructionText = useMemo(
      () => (
        <div>
          To unfeature a recipient, {screens.lg ? 'hover' : 'tap'} over them and
          click <span>Unfeature</span>
        </div>
      ),
      [screens.lg]
    );

    const spotlightText = useMemo(
      () =>
        styles &&
        showSpotlightText && (
          <S.SpotlightText type="body1reg2">
            Drag and drop to reorder.
          </S.SpotlightText>
        ),
      [S, showSpotlightText, styles]
    );

    return (
      <DndContext
        modifiers={[restrictToParentElement]}
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        <S.StyledDragAndDropArea
          data-cy="unfeatured-cards-drag-and-drop-area"
          emptyStateInstructionText={instructionText}
          instructionTabText="Drag and drop recipients to rearrange their order"
          isEmpty={!unfeaturedPageRecipientIds?.length}
          ref={ref}
        >
          {spotlightText}
          <LoadingCard isLoading={isLoading}>
            <SortableContext items={unfeaturedPageRecipientIds as string[]}>
              <S.UnfeaturedCardsContainer>
                {unfeaturedCards}
              </S.UnfeaturedCardsContainer>
            </SortableContext>
          </LoadingCard>
        </S.StyledDragAndDropArea>
      </DndContext>
    );
  }
);

export default UnfeaturedCardsEditMode;
