import styled from 'styled-components';

import { Text } from 'src/client/components';

type CoverContainerProps = {
  coverImage: string;
};

type Props = {
  breakpoint?: string;
};

export const StyledText = styled(Text)<Props>`
  max-width: ${(props) => props.breakpoint};
  padding-bottom: 10px;
`;

export const CoverContainer = styled.div<CoverContainerProps>`
  padding: 67px 151px 86px;
  width: 100%;
  background-image: url(${(props) => props.coverImage});
  background-size: cover;
  @media only screen and (max-width: ${(props) => props.theme.size.mobileL}) {
    padding: 60px 24px !important;
  }
  @media only screen and (max-width: ${(props) => props.theme.size.tablet}) {
    padding: 67px min(151px, 8vw) 85px !important;
  }
`;
