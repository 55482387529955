import { Indexable } from 'src/commons/types';

export function convertObjectToFormData(object: Indexable): FormData {
  const formData = new FormData();

  Object.entries(object).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
}
