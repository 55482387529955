import { CheckCircleFilled } from '@ant-design/icons';
import { Drawer } from 'antd';
import styled from 'styled-components';

type QuoteItemContainerProps = {
  isQuoteMatch: boolean;
};

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    border-bottom: 0;
  }

  .ant-drawer-close {
    position: absolute;
    top: 20px;
    right: 4px;
  }
`;

export const DrawerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuoteItemContainer = styled.button<QuoteItemContainerProps>`
  position: relative;

  padding: 24px;
  border: none;
  border-radius: 4px;
  margin-bottom: 8px;
  background: ${(props) =>
    props.isQuoteMatch ? props.theme.colors.black : props.theme.colors.gray3};
  color: ${(props) =>
    props.isQuoteMatch ? props.theme.colors.white : props.theme.colors.black};

  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.white};
  }

  p {
    text-align: left;
  }

  p:nth-child(1) {
    padding-right: 16px;
  }

  p:nth-child(2) {
    margin-top: 4px;
  }
`;

export const QuoteItemSelectedIcon = styled(CheckCircleFilled)`
  color: ${(props) => props.theme.colors.magenta300};

  position: absolute;
  top: 18px;
  right: 18px;
`;
