import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import { findBestMatch } from 'string-similarity';

import { Indexable } from '../types';

type FindClosestEntryParams<T> = {
  target: string;
  entries: T[];
  keyOfTarget: keyof T;
};

export function removeUndefinedEntries<T extends Object>(object: T): T {
  return removeEntries(object, undefined);
}

export function removeNullEntries<T extends Object>(object: T): T {
  return removeEntries(object, null);
}

export function removeEntries<T extends Object>(
  object: T,
  dataToRemove: any
): T {
  const newObject = {} as T;
  const dataToRemoveArr = Array.isArray(dataToRemove)
    ? dataToRemove
    : [dataToRemove];

  Object.entries(object).forEach(([key, value]) => {
    const shouldNotRemoveEntry = !dataToRemoveArr.includes(value);

    if (shouldNotRemoveEntry) {
      newObject[key as keyof T] = value;
    }
  });

  return newObject;
}

export function getSize(object: Indexable): number {
  return Object.keys(object).length;
}

type GroupObjectsOpts = {
  transformPropertyTargetValue?: (
    propertyTargetValue: any
  ) => string | undefined;
};

export function groupObjects<T>(
  objects: T[],
  targetPropertyName: keyof T,
  opts?: GroupObjectsOpts
): Indexable<T[]> {
  const { transformPropertyTargetValue } = opts || {};
  const groupedObjects: Indexable<T[]> = {};

  objects.forEach((object) => {
    let propertyValue = object[targetPropertyName] as unknown as string;

    if (transformPropertyTargetValue) {
      propertyValue = transformPropertyTargetValue(
        propertyValue
      ) as unknown as string;
    }

    if (typeof propertyValue !== 'string') {
      return null;
    }

    const objectGroup = groupedObjects[propertyValue];

    if (objectGroup) {
      objectGroup.push(object);
    } else {
      groupedObjects[propertyValue] = [object];
    }
  });

  return groupedObjects;
}

export function removePropertiesInObjectArray<T extends object>(
  results: T[],
  hiddedProperties: (keyof T)[] | undefined
): T[] {
  return results.map((result) => omit(result, hiddedProperties || [])) as T[];
}

export function findClosestEntry<T extends Record<string, any>>(
  params: FindClosestEntryParams<T>
): undefined | T {
  const { target, entries, keyOfTarget } = params;

  if (!target || !entries || isEmpty(entries) || !keyOfTarget) {
    return undefined;
  }

  const entryFieldsToMatch = entries?.map((entry) =>
    entry[keyOfTarget]?.toLowerCase()
  );

  const ratingThreshold = 0.85;

  const { bestMatch } = findBestMatch(target.toLowerCase(), entryFieldsToMatch);
  const closestName =
    bestMatch.rating > ratingThreshold ? bestMatch.target : undefined;

  return entries?.find(
    (entry) => entry[keyOfTarget]?.toLowerCase() === closestName
  );
}

export function convertEmptyStringsToNulls(
  obj: Record<string, any>
): Record<string, any> {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      value === '' ? null : value,
    ])
  );
}
