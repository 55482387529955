import React from 'react';

import { ScaledSlide } from 'src/client/types/Slide';

import LoadingCard from '../../LoadingCard';
import Text from '../../Text';

import * as S from './styles';

type Props = ScaledSlide & {
  isLoading: boolean;
};

export default function Item(props: Props) {
  const { backgroundUrl, title, isLoading, slideText } = props;

  const displayTitle = !isLoading && (
    <Text className="title" type="h3med2">
      {title}
    </Text>
  );

  const displaySlideText = slideText && <Text type="h5">{slideText}</Text>;

  return (
    <S.Container>
      <S.ItemContainer>
        <LoadingCard isLoading={isLoading}>
          <S.ItemImage backgroundUrl={backgroundUrl}>
            {displaySlideText}
          </S.ItemImage>
        </LoadingCard>
      </S.ItemContainer>
      {displayTitle}
    </S.Container>
  );
}
