import { DeleteRecipientResponse } from 'src/client/types/ApiResponse';
import {
  ImageWithUrl,
  Recipient,
  RecipientBasic,
  RecipientFilter,
  SaveScrapedRecipientWebsiteImageParams,
} from 'src/commons/types';
import { ApiResponse } from 'src/commons/types/Response.type';
import { RequireField } from 'src/commons/utils/TypescriptUtils';

import { ResetPasswordParams } from '../types/ApiParams';

import ApiClient from './ApiClient';

const apiVersion = '1.0';
const endpoint = 'recipients';
export const url = `${apiVersion}/${endpoint}`;

type GetRecipientWebsiteResponse = {
  website: string;
};

type VerifyResetPasswordTokenResponse = {
  isValid: boolean;
  recipientEmail: string | null;
  isTokenExpired: boolean;
};

type ScrapeRecipientWebsiteResponse = {
  title: string;
  description: string;
  images: string[];
};

export type SaveScrapedRecipientWebsiteResponse = {
  message: string;
  data: ImageWithUrl[];
};

export async function getRecipientsByIds(
  recipientIds: string[]
): Promise<Recipient[]> {
  const { data } = await ApiClient.post<Recipient[]>(`${url}/ids`, {
    recipientIds,
  });

  return data;
}

export async function getRecipientById(
  recipientId: string
): Promise<Recipient> {
  const { data } = await ApiClient.get<Recipient>(`${url}/${recipientId}`);

  return data;
}

export async function getAllRecipients(): Promise<RecipientBasic[]> {
  const { data } = await ApiClient.get<RecipientBasic[]>(`${url}/all`);

  return data;
}

export async function createRecipient(
  recipientData: Partial<Recipient>
): Promise<ApiResponse<Recipient>> {
  const { data } = await ApiClient.post<ApiResponse<Recipient>>(
    `${url}/create`,
    recipientData
  );

  return data;
}

export async function getRecipients(
  params?: RecipientFilter
): Promise<ApiResponse<Recipient[]>> {
  const { data } = await ApiClient.post<ApiResponse<Recipient[]>>(
    `${url}`,
    params
  );

  return data;
}

export async function deleteRecipient(
  recipientId: string
): Promise<DeleteRecipientResponse> {
  const { data } = await ApiClient.delete<DeleteRecipientResponse>(
    `${url}/${recipientId}`
  );

  return data;
}

export async function updateRecipient(
  params: RequireField<Partial<Recipient>, 'id'>
): Promise<Recipient> {
  const { data } = await ApiClient.put<Recipient>(
    `${url}/${params.id}`,
    params
  );

  return data;
}

export async function checkIfRecipientExists(name: string): Promise<boolean> {
  const { data } = await ApiClient.get<boolean>(`${url}/exists/${name}`);

  return data;
}

export async function getRecipientWebsite(
  recipientId: string
): Promise<GetRecipientWebsiteResponse> {
  const { data } = await ApiClient.get<GetRecipientWebsiteResponse>(
    `${url}/${recipientId}/website`
  );

  return data;
}

export async function sendRecipientResetPasswordEmail(recipientEmail: string) {
  const { data } = await ApiClient.post(`${url}/password/reset/email`, {
    recipientEmail,
  });

  return data;
}

export async function verifyRecipientResetPasswordToken(
  token: string
): Promise<VerifyResetPasswordTokenResponse> {
  const { data } = await ApiClient.get<VerifyResetPasswordTokenResponse>(
    `${url}/password/token/${token}`
  );

  return data;
}

export async function resetRecipientPassword(
  params: ResetPasswordParams
): Promise<ApiResponse> {
  const { data } = await ApiClient.put<ApiResponse>(
    `${url}/password/token/${params.token}`,
    {
      password: params.password,
    }
  );

  return data;
}

export async function scrapeRecipientWebsite(
  urltoScrape: string
): Promise<ScrapeRecipientWebsiteResponse> {
  const { data } = await ApiClient.post<ScrapeRecipientWebsiteResponse>(
    `${url}/scrape`,
    { url: urltoScrape }
  );

  return data;
}

export async function saveScrapedRecipientWebsiteImage(
  params: SaveScrapedRecipientWebsiteImageParams
): Promise<SaveScrapedRecipientWebsiteResponse> {
  const { data } = await ApiClient.post<SaveScrapedRecipientWebsiteResponse>(
    `${url}/scrape/${params.recipientId}`,
    {
      imageUrl: params.imageUrl,
      isPrimaryLogo: params.isPrimaryLogo,
    }
  );

  return data;
}
