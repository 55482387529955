import React from 'react';
import styled from 'styled-components';

import { useGetRecipientLogo } from 'src/client/hooks/queries/imageQueries';

type Props = {
  recipientId: string;
  isEditable?: boolean;
  className?: string;
  onClick?: () => void;
};

const defaultRecipientLogo = '/recipientBg.png';

function RecipientLogo(props: Props) {
  const { className = '', recipientId, isEditable = true, onClick } = props;

  const { data: recipientLogo } = useGetRecipientLogo(recipientId);

  function handleRecipientLogoError(
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) {
    const target = event.target as HTMLImageElement;

    target.src = defaultRecipientLogo;
  }

  const cameraIcon = isEditable && <CameraIcon src="/cameraIcon.png" />;

  return (
    <Container className={className}>
      <Logo
        src={recipientLogo?.recipientLogoImagePath || defaultRecipientLogo}
        onClick={onClick}
        onError={handleRecipientLogoError}
      />
      {cameraIcon}
    </Container>
  );
}

export const Container = styled.div`
  position: relative;
  margin-right: 24px;
  height: 40px;
  width: 80px;
  cursor: pointer;
  border-radius: 8px;
`;

export const Logo = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
`;

export const CameraIcon = styled.img`
  position: absolute;
  right: -10px;
  bottom: -10px;
`;

export default RecipientLogo;
