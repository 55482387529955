import { Col, Form, FormInstance, Row, UploadFile, message } from 'antd';
import React, { useState } from 'react';

import { scrapeRecipientWebsite } from 'src/client/api/RecipientApi';
import {
  Box,
  Button,
  Flex,
  Input,
  PictureCardUpload,
  Text,
} from 'src/client/components';
import Select from 'src/client/components/Select';
import { useCheckIfRecipientExistsMutation } from 'src/client/hooks/mutations';
import { useGetAllRecipients } from 'src/client/hooks/queries';
import useThemeContext from 'src/client/hooks/useTheme';
import { handleGetValueFromUploadEvent } from 'src/client/utils/FormUtils';
import { ALL_DIGIT_EXCEPT_HYPHEN } from 'src/commons/constants/regex';
import { RECIPIENT_TYPE } from 'src/commons/types';
import { getFileNameFromUrl, toTitleCase } from 'src/commons/utils/StringUtils';

import LogoSelection from './components/LogoSelection';
import { StyledFormItem } from './styles';

type Props = React.ComponentProps<typeof Form> & {
  onCancel?: () => void;
  isEditingRecipient: boolean;
  isSubmitting: boolean;
};

const rowGutter = 24;
const { Option } = Select;

function RecipientDetailsForm(props: Props) {
  const { isEditingRecipient, isSubmitting, onCancel, ...formProps } = props;

  const [isScraping, setIsScraping] = useState(false);

  const { colors } = useThemeContext();
  const { data: recipients, isLoading: isRecipientsLoading } =
    useGetAllRecipients();

  const { mutateAsync: checkIfRecipientExists, isLoading: isCheckingIfRecipientExists } =
    useCheckIfRecipientExistsMutation();

  const [validateStatusRecipientName, setValidateStatusRecipientName] = useState<'validating' | 'error' | ''>('');

  async function handleRecipientNameValidation(value: string) {
    const exists = await checkIfRecipientExists(value)
    setValidateStatusRecipientName(exists ? 'error' : '')

    return exists
  }

  async function handleScrape() {
    setIsScraping(true);

    try {
      const url = formProps.form?.getFieldValue('website');

      if (!url) {
        message.warning('Please enter the website url.');

        return;
      }

      const {
        title = '',
        description = '',
        images: newScrapedImageUrls = [],
      } = await scrapeRecipientWebsite(url);

      if (newScrapedImageUrls.length === 0) {
        message.warning('No images scraped from the website url.');
      }

      const currentScrapedImages: UploadFile[] =
        formProps.form?.getFieldValue('scrapedImages') || [];

      const newScrapedImages = newScrapedImageUrls?.reduce<
        Partial<UploadFile>[]
      >((acc, newScrapedImageUrl) => {
        const imageFileName = getFileNameFromUrl(newScrapedImageUrl);

        const isNew = !currentScrapedImages.some(
          ({ url }) => getFileNameFromUrl(url as string) === imageFileName
        );

        if (isNew) {
          acc.push({ uid: newScrapedImageUrl, url: newScrapedImageUrl });
        }

        return acc;
      }, []);

      formProps.form?.setFieldsValue({
        ogTitle: title,
        ogDescription: description,
        scrapedImages: [...currentScrapedImages, ...newScrapedImages],
      });

      message.success('Successfully scraped content from website');
    } catch (error) {
      message.error('Failed to scrape content from website');
    } finally {
      setIsScraping(false);
    }
  }

  function handleChange(e: any) {
    const ONLY_DIGIT_AND_HYPHEN = e.target.value.replace(
      ALL_DIGIT_EXCEPT_HYPHEN,
      ''
    );
    formProps.form?.setFieldsValue({
      taxId: ONLY_DIGIT_AND_HYPHEN,
    });
  }

  function handleTaxIdFormatter(event: React.KeyboardEvent<HTMLInputElement>) {
    const taxIdElement = event.target as HTMLInputElement;
    const shouldAddHyphen =
      event.key !== 'Backspace' && taxIdElement.value.length === 2;

    if (shouldAddHyphen) {
      taxIdElement.value += '-';
      formProps.form?.setFieldsValue({
        taxid: taxIdElement.value,
      });
    }
  }

  const recipientDetails = !isEditingRecipient ? (
    <>
      <Form.Item
        getValueFromEvent={handleGetValueFromUploadEvent}
        label="Logo"
        name="logoImage"
        valuePropName="fileList"
      >
        <PictureCardUpload beforeUpload={() => false} maxCount={1} />
      </Form.Item>
      <Flex>
        <Button
          data-cy="create-btn"
          htmlType="submit"
          loading={isSubmitting}
          type="primary"
        >
          Create Recipient
        </Button>
        <Box margin="0 0 0 12px">
          <Button data-cy="cancel-btn" type="variant1" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </Flex>
    </>
  ) : null;

  function renderLogoSelection(formInstance: FormInstance<any>) {
    return <LogoSelection form={formInstance} />;
  }

  function renderWebsiteTitle(formInstance: FormInstance<any>) {
    const { getFieldValue } = formInstance;
    const title = getFieldValue('ogTitle') || 'No Title Found';

    return (
      <Text
        color={colors.darkHighEmphasis}
        data-cy="website-title-text"
        type="body1reg2"
      >
        {title}
      </Text>
    );
  }

  function renderWebsiteDescription(formInstance: FormInstance<any>) {
    const { getFieldValue } = formInstance;

    const description =
      getFieldValue('ogDescription') || 'No Description Found';

    return (
      <Text
        color={colors.darkHighEmphasis}
        data-cy="website-description-text"
        type="body1reg2"
      >
        {description}
      </Text>
    );
  }

  const editForm = isEditingRecipient ? (
    <>
      <Form.Item noStyle name="ogTitle" />
      <Form.Item noStyle name="ogDescription" />
      <Row justify="space-between">
        <Col span={18}>
          <Box margin="22px 0 0 0">
            <Text color={colors.darkHighEmphasis} type="h3med2">
              Open Graph
            </Text>
          </Box>
          <Box margin="8px 0 19px 0">
            <Text color={colors.darkEmphasis2} type="body1reg2">
              Assets such as the website&apos;s title, description, and preview
              image will be displayed on share pages
            </Text>
          </Box>
        </Col>
        <Col>
          <Box margin="20px 0 0 0">
            <Button
              data-cy="scrape-btn"
              disabled={isScraping}
              loading={isScraping}
              type="secondary"
              onClick={handleScrape}
            >
              Scrape Again
            </Button>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col span={4}>
          <Box margin="22px 0 19px 0">
            <Text color={colors.darkHighEmphasis} type="body1med2">
              Website Title
            </Text>
          </Box>
        </Col>
        <Col span={20}>
          <Box margin="22px 0 19px 0">
            <Form.Item noStyle dependencies={['ogTitle']}>
              {renderWebsiteTitle}
            </Form.Item>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col span={4}>
          <Box margin="22px 0 19px 0">
            <Text color={colors.darkHighEmphasis} type="body1med2">
              Description
            </Text>
          </Box>
        </Col>
        <Col span={20}>
          <Box margin="22px 0 19px 0">
            <Form.Item noStyle dependencies={['ogDescription']}>
              {renderWebsiteDescription}
            </Form.Item>
          </Box>
        </Col>
      </Row>
      <Form.Item
        noStyle
        dependencies={['logoImageId', 'scrapedImages', 'uploadedImages']}
      >
        {renderLogoSelection}
      </Form.Item>

      <Box margin="22px 0 19px 0">
        <Text color={colors.darkEmphasis1} type="h3med2">
          Account Details
        </Text>
      </Box>
      <Row gutter={rowGutter}>
        <Col span={10}>
          <Form.Item label="Email" name="email">
            <Input placeholder="Email" size="large" />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label="Password" name="password">
            <Input placeholder="Create Password" size="large" />
          </Form.Item>
        </Col>
      </Row>

      <Flex>
        <Button
          data-cy="edit-btn"
          htmlType="submit"
          loading={isSubmitting}
          type="primary"
        >
          Save Changes
        </Button>
        <Box margin="0 0 0 12px">
          <Button data-cy="discard-btn" type="variant1" onClick={onCancel}>
            Discard Changes
          </Button>
        </Box>
      </Flex>
    </>
  ) : null;

  function renderTaxidOrSponsor(formInstance: FormInstance<any>) {
    const { getFieldValue } = formInstance;

    const isNonprofitFiscallySponsored =
      getFieldValue('recipientTypes') ===
      RECIPIENT_TYPE.NONPROFIT_FISCALLY_SPONSORED;

    if (isNonprofitFiscallySponsored) {
      const recipientOptions = recipients?.map((recipient) => (
        <Option key={recipient.id} value={recipient.id}>
          {recipient.name}
        </Option>
      ));

      return (
        <Form.Item
          label="Parent/Fiscal Sponsor"
          name="sponsor"
          rules={[
            { required: true, message: 'Parent/Fiscal Sponsor is required' },
          ]}
        >
          <Select
            showSearch
            data-cy="recipient-sponsor-input"
            disabled={isRecipientsLoading}
            filterOption={(input, option) =>
              option?.children?.toLowerCase().includes(input.toLowerCase())
            }
            loading={isRecipientsLoading}
            optionFilterProp="children"
            placeholder="Select Parent/Fiscal Sponsor"
            size="large"
          >
            {recipientOptions}
          </Select>
        </Form.Item>
      );
    }

    return (
      <Form.Item label="US Federal Tax ID" name="taxid">
        <Input
          data-cy="recipient-taxid-input"
          maxLength={10}
          placeholder="Input US Federal Tax ID"
          size="large"
          onChange={handleChange}
          onKeyUp={handleTaxIdFormatter}
        />
      </Form.Item>
    );
  }

  const recipientTypeOptions = Object.values(RECIPIENT_TYPE).map(
    (recipientType) => (
      <Option key={recipientType} value={recipientType}>
        {toTitleCase(recipientType.replace(/_/g, ' '))}
      </Option>
    )
  );

  return (
    <Form layout="vertical" {...formProps}>
      <Row gutter={rowGutter}>
        <Col span={12}>
          <StyledFormItem
            hasFeedback
            label="Recipient Name"
            name="name"
            rules={[
              { required: true, message: 'Recipient name is required' },
              {
                validator: async (_, value) => {
                  if (isEditingRecipient || !value) {
                    return Promise.resolve()
                  }

                  return await handleRecipientNameValidation(value) ? Promise.reject(new Error('Recipient name already exists')) : Promise.resolve()
                }
              },

            ]}
            validateStatus={isCheckingIfRecipientExists ? 'validating' : validateStatusRecipientName}
          >
            <Input
              data-cy="recipient-name-input"
              placeholder="Recipient Name"
              size="large"
            />
          </StyledFormItem>
        </Col>
        <Col span={12}>
          <Form.Item label="Website" name="website">
            <Input
              data-cy="recipient-website-input"
              placeholder="Paste website URL"
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={12}>
          <Form.Item
            label="Type of Recipient"
            name="recipientTypes"
            rules={[{ required: true, message: 'Recipient Type is required' }]}
          >
            <Select
              showSearch
              data-cy="recipient-types-input"
              placeholder="Search recipient type"
              size="large"
            >
              {recipientTypeOptions}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.recipientTypes !== currentValues.recipientTypes
            }
          >
            {renderTaxidOrSponsor}
          </Form.Item>
        </Col>
      </Row>
      {recipientDetails}
      {editForm}
    </Form>
  );
}

export default RecipientDetailsForm;
