import { LineChart } from 'recharts';

import styled from 'styled-components';

import { H6_MED_2, LABEL_2_MED_2 } from 'src/client/constants/text';

type ColorLegendProps = {
  type: 'goal' | 'donation';
};

export const Container = styled.div`
  padding: 40px 30px;
  height: 100%;
  color: white;
`;

export const ColorLegend = styled.div<ColorLegendProps>`
  margin-right: 12px;
  height: 19px;
  width: 19px;
  border-radius: 50px;
  background: ${(props) => {
    if (props.type === 'goal') {
      return props.theme.colors.darkPrimary100;
    }

    if (props.type === 'donation') {
      return props.theme.colors.darkSecondary;
    }
  }};
`;

export const LineContainer = styled.div`
  margin: 50px 0;
  height: 300px;
`;

export const StyledLineChart = styled(LineChart)`
  text tspan {
    fill: ${(props) => props.theme.colors.white} !important;
    ${LABEL_2_MED_2};
  }

  .recharts-default-tooltip {
    background-color: ${(props) => props.theme.colors.darkSurface1} !important;
    border: none !important;
    border-radius: 8px;
  }

  .recharts-tooltip-label {
    ${H6_MED_2};
  }

  .recharts-tooltip-item {
    padding-bottom: 0px;
    ${LABEL_2_MED_2};
  }
`;
