import styled from 'styled-components';

import { Flex } from 'src/client/components';

export const CardsContainer = styled(Flex)`
  margin-top: 42px;
  margin-bottom: 24px;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;

  @media ${(props) => props.theme.device.laptop} {
    margin-bottom: 66px;
  }
`;

export const EmptyContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 42px;
  margin-bottom: 42px;
  height: 155px;
  padding: 24px;
  background: ${(props) => props.theme.colors.darkSurface3};
  border-radius: 8px;
`;
