import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Form, FormInstance } from 'antd';
import React, { useEffect, useState } from 'react';

import { Button } from 'src/client/components';
import { SectionStatus, STATUS_OPTIONS } from 'src/client/types/Gallery';

import * as S from './styles';

type Props = {
  introSectionForm: FormInstance<any>;
  isViewingPublicly: boolean;
  sectionStatus: SectionStatus;
  handleFormChange: () => void;
};

function GalleryDescriptionInput(props: Props) {
  const {
    introSectionForm,
    isViewingPublicly,
    sectionStatus,
    handleFormChange,
  } = props;

  const isIntroductionDescriptionVisible = introSectionForm.getFieldValue(
    'isIntroductionDescriptionVisible'
  );

  const [isHidden, setIsHidden] = useState<boolean>(
    !isIntroductionDescriptionVisible
  );

  useEffect(() => {
    if (sectionStatus.status === STATUS_OPTIONS.VIEW_MODE) {
      setIsHidden(!isIntroductionDescriptionVisible); // reset state when form is reset
    }
  }, [isIntroductionDescriptionVisible, sectionStatus]);

  function handleSetGalleryDescriptionVisibility(value: boolean) {
    introSectionForm.setFieldsValue({
      isIntroductionDescriptionVisible: !value,
    });
    setIsHidden(value);

    handleFormChange();
  }

  if (isViewingPublicly) {
    if (isHidden) {
      return null;
    }

    const description = introSectionForm.getFieldValue(
      'introductionDescription'
    );

    return (
      <S.Container>
        <S.DescriptionDisplay>{description}</S.DescriptionDisplay>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <S.GalleryDescriptionInputContainer>
        <S.StyledEditLabel isHidden={isHidden}>
          Gallery description
        </S.StyledEditLabel>
        <Form.Item noStyle name="introductionDescription">
          <S.GalleryDescriptionTextArea
            isEditing={!isHidden}
            placeholder="Add your gallery description..."
          />
        </Form.Item>
        <Form.Item noStyle name="isIntroductionDescriptionVisible">
          <S.OptionsGroup isHidden={isHidden}>
            <Button
              type="noStyle"
              onClick={() => handleSetGalleryDescriptionVisibility(false)}
            >
              <EyeOutlined height={20} />
              <span>Show</span>
            </Button>
            <Button
              type="noStyle"
              onClick={() => handleSetGalleryDescriptionVisibility(true)}
            >
              <EyeInvisibleOutlined />
              <span>Hide</span>
            </Button>
          </S.OptionsGroup>
        </Form.Item>
      </S.GalleryDescriptionInputContainer>
    </S.Container>
  );
}

export default GalleryDescriptionInput;
