import { Form, Tabs } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, FlexCenter, Input, Text } from 'src/client/components';
import { useLoginMutation } from 'src/client/hooks/mutations/authMutations';
import useThemeContext from 'src/client/hooks/useTheme';
import { UserRole } from 'src/commons/constants/roles';
import routes from 'src/commons/constants/routes';
import { RECIPIENT_SIGN_IN_TABS } from 'src/commons/constants/tabs';
import { replaceRouteParams } from 'src/commons/utils/RouteUtils';

import * as S from './styles';

type Props = {
  onRequestAccessSend: (formValues: any) => void;
};

const { TabPane } = Tabs;

function FormCard(props: Props) {
  const { onRequestAccessSend } = props;

  const { colors } = useThemeContext();
  const { mutateAsync: login } = useLoginMutation();
  const [tabKey, setTabKey] = useState(RECIPIENT_SIGN_IN_TABS.LOGIN);

  async function handleLogin(formValues: { email: string; password: string }) {
    await login({ ...formValues, userRole: UserRole.RECIPIENT });

    window.location.href = routes.ROOT;
  }

  function handleTabChange(key: string) {
    setTabKey(key as RECIPIENT_SIGN_IN_TABS);
  }

  function changeTabToLogin() {
    setTabKey(RECIPIENT_SIGN_IN_TABS.LOGIN);
  }

  function changeTabToRequestAccess() {
    setTabKey(RECIPIENT_SIGN_IN_TABS.REQUEST_ACCESS);
  }

  return (
    <S.Container>
      <S.StyledTab centered activeKey={tabKey} onChange={handleTabChange}>
        <TabPane
          key={RECIPIENT_SIGN_IN_TABS.LOGIN}
          tab={<Text type="button-1-2">Login</Text>}
        >
          <Box margin="30px 0 0 0" />
          <Form layout="vertical" onFinish={handleLogin}>
            <Form.Item label="Email" name="email">
              <Input placeholder="Email" size="large" />
            </Form.Item>
            <Form.Item label="Password" name="password">
              <Input.Password placeholder="Password" size="large" />
            </Form.Item>
            <S.ForgotPasswordContainer>
              <Link
                to={replaceRouteParams(routes.SEND_RESET_PASSWORD_EMAIL, {
                  userRole: UserRole.RECIPIENT.toLowerCase(),
                })}
              >
                <Text type="body1med2">Forgot password?</Text>
              </Link>
            </S.ForgotPasswordContainer>
            <S.SubmitButton htmlType="submit" type="primary">
              Login
            </S.SubmitButton>
            <FlexCenter>
              <Text color={colors.black} type="body1reg2">
                Don&apos;t have an account?&nbsp;
              </Text>
              <Button type="noStyle" onClick={changeTabToRequestAccess}>
                <Text color={colors.teal2} type="body1reg2">
                  Request access
                </Text>
              </Button>
            </FlexCenter>
          </Form>
        </TabPane>
        <TabPane
          key={RECIPIENT_SIGN_IN_TABS.REQUEST_ACCESS}
          tab={<Text type="button-1-2">Request Access</Text>}
        >
          <Form layout="vertical" onFinish={onRequestAccessSend}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: 'First Name is required' }]}
            >
              <Input placeholder="First Name" size="large" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: 'Last Name is required' }]}
            >
              <Input placeholder="Last Name" size="large" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Email is required' },
                { message: 'Invalid email', type: 'email' },
              ]}
            >
              <Input placeholder="Email" size="large" />
            </Form.Item>
            <Form.Item
              label="Organization"
              name="organization"
              rules={[{ required: true, message: 'Organization is required' }]}
            >
              <Input placeholder="Organization" size="large" />
            </Form.Item>
            <Form.Item
              label="How did you hear about us?"
              name="hearAboutUs"
              rules={[
                {
                  required: true,
                  message: 'How did you hear about us is required',
                },
              ]}
            >
              <Input placeholder="How did you hear about us?" size="large" />
            </Form.Item>
            <S.SubmitButton htmlType="submit" type="primary">
              Submit Request
            </S.SubmitButton>
            <FlexCenter>
              <Text color={colors.black} type="body1reg2">
                Already have an account?&nbsp;
              </Text>
              <Button type="noStyle" onClick={changeTabToLogin}>
                <Text color={colors.teal2} type="body1reg2">
                  Login
                </Text>
              </Button>
            </FlexCenter>
          </Form>
        </TabPane>
      </S.StyledTab>
    </S.Container>
  );
}

export default FormCard;
