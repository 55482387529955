import { PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

import { Box, Flex, Text } from '.';

type Props = React.ComponentProps<typeof Upload> & {
  uploadRef?: React.RefObject<HTMLElement>;
};

function PictureCardUpload(props: Props) {
  return (
    <StyledUpload {...props} listType="picture-card">
      <Flex flexDirection="column" ref={props.uploadRef as any}>
        <Box margin="0 0 8px 0">
          <PlusOutlined />
        </Box>
        <Text type="body2Regular">Upload</Text>
      </Flex>
    </StyledUpload>
  );
}

const uploadDimension = css`
  min-height: 180px;
  min-width: 180px;
`;

export const StyledUpload = styled(Upload)<Props>`
  position: relative;

  .ant-upload {
    ${uploadDimension}
    visibility: ${(props) => (props.uploadRef ? 'hidden' : 'visible')};
  }

  .ant-upload-list-picture-card-container {
    ${uploadDimension}
    cursor: pointer;
  }

  .ant-upload-list-picture-card
    .ant-upload-list-item:hover
    .ant-upload-list-item-info:before {
    opacity: 0;
  }
`;

export default PictureCardUpload;
