import React from 'react';

import styled from 'styled-components';

import { Box, Text } from 'src/client/components';

type Props = {
  icon: string;
  title: string;
  statisticValue: string;
};

function StatisticCardWithIcon(props: Props) {
  const { icon, statisticValue, title } = props;

  return (
    <Container>
      <StyledImage src={icon} />
      <Box margin="20px 0 20px 0">
        <Text type="body1reg2">{title}</Text>
      </Box>
      <Text type="displayMMed2">{statisticValue}</Text>
    </Container>
  );
}

const Container = styled.div`
  padding: 40px;
  height: 285px;
  width: 387px;
  border-radius: 20px;
  background: ${(props) => props.theme.colors.blueGray};
`;

const StyledImage = styled.img`
  max-height: 70px;
  object-fit: contain;
`;

export default StatisticCardWithIcon;
