import React from 'react';
import styled from 'styled-components';

import { Card } from 'src/client/components';
import Text from 'src/client/components/Text';

type Props = {
  subtitle?: string;
  value?: React.ReactNode;
};

export default function NumbersCard(props: Props) {
  const { subtitle, value } = props;

  return (
    <Container>
      <NumbersCardStyle>
        <Subtitle type={'subtitle2'}>{subtitle}</Subtitle>
        <ValueText type="h3">{value}</ValueText>
      </NumbersCardStyle>
    </Container>
  );
}

const Subtitle = styled(Text)`
  margin-bottom: 23px;
  color: ${(props) => props.theme.colors.teal2};
`;

const NumbersCardStyle = styled.div`
  display: flex;
  padding: 31px;
  width: 100%;
  flex-direction: column;
`;

const Container = styled(Card)`
  margin-bottom: 20px;
  width: 100%;
  width: 359px;
  flex-shrink: 1;
  overflow: auto;

  .ant-card-body {
    width: 100%;
  }
`;

export const ValueText = styled(Text)`
  width: 100%;
`;
