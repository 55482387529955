import { Give, Recipient } from 'src/commons/types';

export function getRecipientNameById(
  recipientId: string | undefined | null,
  recipients: Recipient[] | undefined
): string {
  return (
    recipients?.find((recipient) => recipient.id === recipientId)?.name || ''
  );
}

export function getRecipientIdsFromGives(gives: Give[]): string[] {
  return gives
    .filter((give) => give.recipientId)
    .map((give) => give.recipientId) as string[];
}

export function getRecipientById(
  recipients: Recipient[] | undefined,
  recipientId: string | undefined | null
) {
  return recipients?.find((recipient) => recipient.id === recipientId);
}
