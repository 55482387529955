import styled from 'styled-components';

import { BODY_1_MED_2 } from 'src/client/constants/text';

export const Container = styled.div`
  padding: 44px 0 0 0;
  width: 100%;

  .ant-form-item-label label {
    ${BODY_1_MED_2};
    color: ${(props) => props.theme.colors.darkEmphasis1};
  }

  @media ${(props) => props.theme.screen.lg} {
    padding: 44px 0 0 48px;
  }
`;

export const Header = styled.div`
  padding: 16px 24px;
  box-shadow: inset 0px -1px 0px #f0f0f0;
`;

export const InlineTexts = styled.div`
  p {
    display: inline;
    line-height: 160%;
  }
`;
