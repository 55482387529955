import styled, { css } from 'styled-components';

type ArrowGuide1Props = {
  isDescriptionEmpty?: boolean;
  isQuoteTextAreaRendering: boolean;
};

export const ArrowGuide1 = styled.img<ArrowGuide1Props>`
  position: absolute;
  display: none;
  max-width: 364px;
  height: 281px;

  ${(props) =>
    props.isQuoteTextAreaRendering &&
    css`
      top: -290px;
      right: 95px;

      @media (min-width: 1300px) {
        display: block;
        right: -30px;
        width: 24%;
      }
    `}

  ${(props) =>
    !props.isQuoteTextAreaRendering &&
    css`
      @media (min-width: 1300px) {
        display: block;
        right: -360px;

        ${props.isDescriptionEmpty &&
        `
          top: 40px;
          right: 160px;
        `}
      }
    `}
`;
