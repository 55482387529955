import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';

import { Flex, Text, Title } from 'src/client/components';
import AdminLayout from 'src/client/layouts/AdminLayout';
import routes from 'src/commons/constants/routes';

import { GiveStatistics } from './components/GiveStatistics';
import GivesTable from './components/GivesTable';

import * as S from './styles';

export default function AdminGives() {
  return (
    <>
      <Title title="Gives - Admin" />
      <AdminLayout>
        <S.Container>
          <Flex alignItems="center" gap="24px" margin="0 0 32px 0">
            <Text type="h2med2">Gives</Text>
            <Link to={routes.ADMIN_ADD_GIVE}>
              <S.AddGiveButton size="large" type="primary">
                <PlusOutlined />
                Add Give
              </S.AddGiveButton>
            </Link>
          </Flex>
          <GiveStatistics />
          <GivesTable />
        </S.Container>
      </AdminLayout>
    </>
  );
}
