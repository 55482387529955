import { useMutation } from 'react-query';

import { updatePage } from 'src/client/api/PageApi';
import { Page } from 'src/commons/types';

type CommonOptions = {
  onSuccess?: (option: Page) => void;
  onMutate?: (variables: any) => void;
};

export function useUpdatePageMutation(opts?: CommonOptions) {
  return useMutation(updatePage, opts);
}
