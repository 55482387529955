import { DefaultProperties } from './Common.type';

export type Counter = {
  count: any;
  type: CounterType;
} & DefaultProperties;

export enum CounterType {
  ADMIN_DASHBOARD_STATS = 'adminDashboardStats',
}

export type AdminDashboardStatsCount = {
  averageGiveAmount: number;
  averageHourGive: number;
  averageTotalPerDonor: number;
  donorsWithGoal: number;
  givesProcessed: number;
  medianGiveAmount: number;
  medianHourGive: number;
  medianTotalPerDonor: number;
  platforms: number;
  recipients: number;
  recurringDonors: number;
  taxDeductiblePercentage: number;
  totalGivesProcessedAmount: number;
  totalMatchedDonationAmount: number;
  totalHourGive: number;
  uniqueDonors: number;
};
