import { Col, Form, FormInstance, Row } from 'antd';
import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Box, Input, Text } from 'src/client/components';
import {
  useResetAdminPasswordMutation,
  useResetPasswordMutation,
  useResetRecipientPasswordMutation,
} from 'src/client/hooks/mutations';
import { useLoginMutation } from 'src/client/hooks/mutations/authMutations';
import {
  useVerifyAdminResetPasswordToken,
  useVerifyRecipientResetPasswordToken,
  useVerifyResetPasswordToken,
} from 'src/client/hooks/queries';
import BasicLayout from 'src/client/layouts/BasicLayout';
import { UserRole } from 'src/commons/constants/roles';
import routes from 'src/commons/constants/routes';
import { replaceRouteParams } from 'src/commons/utils/RouteUtils';

import { capitalizeFirstLetter } from 'src/commons/utils/StringUtils';

import { PageNotFound, LoadingPage } from '..';

import * as S from './styles';

const requireRule = [
  {
    required: true,
  },
];

type RouteParams = {
  token: string;
  userRole: string;
};

type FormValues = {
  password: string;
  confirmPassword: string;
};

type ResetTokenVerification = {
  isValidToken: boolean | undefined;
  isTokenExpired: boolean | undefined;
};

function ResetPassword(props: RouteComponentProps<RouteParams>) {
  const { match } = props;
  const { colors } = useTheme();

  const {
    data: resetDonorPasswordTokenVerification,
    isLoading: isDonorTokenVerificationLoading,
  } = useVerifyResetPasswordToken(match.params.token);

  const {
    data: resetRecipientPasswordTokenVerification,
    isLoading: isRecipientTokenVerificationLoading,
  } = useVerifyRecipientResetPasswordToken(match.params.token);

  const {
    data: resetAdminPasswordTokenVerification,
    isLoading: isAdminTokenVerificationLoading,
  } = useVerifyAdminResetPasswordToken(match.params.token);

  const { mutateAsync: resetDonorPassword } = useResetPasswordMutation();
  const { mutateAsync: resetAdminPassword } = useResetAdminPasswordMutation();

  const { mutateAsync: resetRecipientPassword } =
    useResetRecipientPasswordMutation();

  const { mutateAsync: login } = useLoginMutation();

  const userRoleMatch = capitalizeFirstLetter(match.params.userRole);

  const isResetPasswordLoading =
    isDonorTokenVerificationLoading ||
    isRecipientTokenVerificationLoading ||
    isAdminTokenVerificationLoading;

  const { isTokenExpired, isValidToken } = isResetTokenVerificationValid();

  function isResetTokenVerificationValid(): ResetTokenVerification {
    // switch (userRoleMatch) {
    //   case UserRole.ADMIN:
    //     return {
    //       isTokenExpired: resetAdminPasswordTokenVerification?.isTokenExpired,
    //       isValidToken: resetAdminPasswordTokenVerification?.isValid,
    //     };
    //   case UserRole.DONOR:
    //     return {
    //       isTokenExpired: resetDonorPasswordTokenVerification?.isTokenExpired,
    //       isValidToken: resetDonorPasswordTokenVerification?.isValid,
    //     };
    //   case UserRole.RECIPIENT:
    //     return {
    //       isTokenExpired:
    //         resetRecipientPasswordTokenVerification?.isTokenExpired,
    //       isValidToken: resetRecipientPasswordTokenVerification?.isValid,
    //     };
    // }

    // return {
    //   isTokenExpired: false,
    //   isValidToken: false,
    // };

    return {
      isTokenExpired: false,
      isValidToken: true,
    };
  }

  async function handleSubmit(values: FormValues) {
    const resetPasswordParams = {
      password: values.password,
      token: match.params.token,
    };

    switch (userRoleMatch) {
      case UserRole.ADMIN:
        await resetAdminPassword(resetPasswordParams);

        await login({
          email: resetAdminPasswordTokenVerification?.adminEmail as string,
          password: values.password,
          userRole: UserRole.ADMIN,
        });
        break;
      case UserRole.RECIPIENT:
        await resetRecipientPassword(resetPasswordParams);

        await login({
          email:
            resetRecipientPasswordTokenVerification?.recipientEmail as string,
          password: values.password,
          userRole: UserRole.RECIPIENT,
        });
        break;
      case UserRole.DONOR:
        await resetDonorPassword(resetPasswordParams);

        await login({
          email: resetDonorPasswordTokenVerification?.donorEmail as string,
          password: values.password,
          userRole: UserRole.DONOR,
        });
        break;
    }

    window.location.href = routes.ROOT;
  }

  function newPasswordValidation() {
    const minimumPasswordChar = 8;

    return {
      validator: (_: any, value: string) => {
        if (value.length > minimumPasswordChar) {
          return Promise.resolve();
        }

        return Promise.reject(
          new Error('Password should be at least 8 characters')
        );
      },
    };
  }

  function validateConfirmPassword(formInstance: any) {
    const { getFieldValue } = formInstance as FormInstance;

    return {
      validator: (_: any, value: string) => {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }

        return Promise.reject(new Error('Passwords do not match.'));
      },
    };
  }

  if (isResetPasswordLoading) {
    return <LoadingPage />;
  }

  if (!isValidToken) {
    return <PageNotFound />;
  }

  if (isTokenExpired) {
    return (
      <Redirect
        to={replaceRouteParams(routes.RESEND_RESET_PASSWORD_LINK, {
          userRole: match.params.userRole,
        })}
      />
    );
  }

  return (
    <BasicLayout isOrnamentVisible>
      <Row justify="center">
        <Col lg={15} sm={15} xl={8} xs={22}>
          <div>
            <Box margin="88px 0 0 0" />
            <Text color={colors.teal1} type="h5bold2">
              GIVING SIDE
            </Text>
            <Box margin="24px 0 40px 0">
              <Text color={colors.white} type="displaySBold2">
                Change your password
              </Text>
            </Box>
          </div>
          <S.Card>
            <Text type="h4med2">Create a password (at least 8 characters)</Text>
            <Box margin="40px 0 0 0" />
            <Form layout="vertical" onFinish={handleSubmit}>
              <Form.Item
                label="New Password"
                name="password"
                rules={[...requireRule, newPasswordValidation]}
              >
                <Input.Password placeholder="Password" size="large" />
              </Form.Item>
              <Form.Item
                label="Confirm New Password"
                name="confirmPassword"
                rules={[...requireRule, validateConfirmPassword]}
              >
                <Input.Password placeholder="Confirm password" size="large" />
              </Form.Item>
              <Form.Item>
                <S.ConfirmButton htmlType="submit" type="primary">
                  Change my password
                </S.ConfirmButton>
              </Form.Item>
            </Form>
          </S.Card>
        </Col>
      </Row>
    </BasicLayout>
  );
}

export default ResetPassword;
