import React from 'react';
import styled from 'styled-components';

import Text from 'src/client/components/Text';

import LoadingCard from './LoadingCard';

type Props = {
  className?: string;
  isLoading?: boolean;
  statisticValue: string;
  title: string;
};

function StatisticCard(props: Props) {
  const { className = '', isLoading = false, statisticValue, title } = props;

  return (
    <Card className={className}>
      <LoadingCard isLoading={isLoading}>
        <Text className="statistic-value" type="displayMMed2">
          {statisticValue}
        </Text>
        <Title className="statistic-title" type="h5med2">
          {title}
        </Title>
      </LoadingCard>
    </Card>
  );
}

const Title = styled(Text)`
  color: ${(props) => props.theme.colors.teal2};
`;

const Card = styled.div`
  display: flex;
  padding: 40px 30px 27px;
  border-radius: 17px;
  border: 1px solid ${(props) => props.theme.colors.darkEmphasis1};
  box-shadow: 0px 1.75007px 14.8756px rgba(0, 0, 0, 0.02);
  flex-direction: column;
  justify-content: space-between;
  background: white;
  overflow: auto;

  height: 204px;
  width: 100%;
  max-width: 390px;

  &:hover {
    background-color: ${(props) => props.theme.colors.darkPrimary100};
    border: none;
  }

  &:hover p {
    color: ${(props) => props.theme.colors.darkEmphasis1};
  }

  &:hover ${Title} {
    color: ${(props) => props.theme.colors.darkEmphasis2};
  }
`;

export default StatisticCard;
