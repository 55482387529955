import { useRef } from 'react';

import { STATUS_OPTIONS } from 'src/client/types/Gallery';

import {
  movePageRecipientOrder,
  sortAndGroupPageRecipients,
} from 'src/client/utils/PageRecipientUtils';
import { PageRecipient, PageRecipientVisibility } from 'src/commons/types';

type UseMovePageRecipientsEditDataParams = {
  isCardsSectionHasChanges: boolean;
  pageRecipientsEditData: PageRecipient[];
  setPageRecipientsEditData: (newPageRecipients: PageRecipient[]) => void;
  setSectionStatus: (newStatus: STATUS_OPTIONS) => void;
};

export function useMovePageRecipientsEditData(
  params: UseMovePageRecipientsEditDataParams
) {
  const {
    isCardsSectionHasChanges,
    pageRecipientsEditData,
    setPageRecipientsEditData,
    setSectionStatus,
  } = params;

  const {
    sortedFeaturedPageRecipients: featuredPageRecipients,
    sortedUnfeaturedPageRecipients: unfeaturedPageRecipients,
    sortedHiddenPageRecipients: hiddenPageRecipients,
  } = sortAndGroupPageRecipients(pageRecipientsEditData);

  const featuredCardsEditModeRef = useRef<HTMLDivElement>(null!);
  const unfeaturedCardsEditModeRef = useRef<HTMLDivElement>(null!);
  const hiddenCardsEditModeRef = useRef<HTMLDivElement>(null!);

  function featurePageRecipient(pageRecipientId: string) {
    featuredCardsEditModeRef.current.scrollIntoView();
    moveAndSetPageRecipientOrder({
      newVisibility: PageRecipientVisibility.FEATURED,
      pageRecipients: pageRecipientsEditData,
      pageRecipienToMoveId: pageRecipientId,
      newOrderIndex: featuredPageRecipients?.length ?? 0,
    });
  }

  function moveFeaturedPageRecipientToUnfeatured(pageRecipientId: string) {
    unfeaturedCardsEditModeRef.current.scrollIntoView();
    moveAndSetPageRecipientOrder({
      newVisibility: PageRecipientVisibility.UNFEATURED,
      pageRecipients: pageRecipientsEditData,
      pageRecipienToMoveId: pageRecipientId,
      newOrderIndex: 0,
    });
  }

  function hidePageRecipient(pageRecipientId: string) {
    moveAndSetPageRecipientOrder({
      newVisibility: PageRecipientVisibility.HIDDEN,
      pageRecipients: pageRecipientsEditData,
      pageRecipienToMoveId: pageRecipientId,
      newOrderIndex: hiddenPageRecipients?.length ?? 0,
    });
  }

  function moveHiddenPageRecipientToUnfeatured(pageRecipientId: string) {
    unfeaturedCardsEditModeRef.current.scrollIntoView({
      block: 'end',
    });
    moveAndSetPageRecipientOrder({
      newVisibility: PageRecipientVisibility.UNFEATURED,
      pageRecipients: pageRecipientsEditData,
      pageRecipienToMoveId: pageRecipientId,
      newOrderIndex: unfeaturedPageRecipients?.length ?? 0,
    });
  }

  function moveAndSetPageRecipientOrder(
    ...params: Parameters<typeof movePageRecipientOrder>
  ) {
    const newPageRecipientOrders = movePageRecipientOrder(...params);

    if (!isCardsSectionHasChanges) {
      setSectionStatus(STATUS_OPTIONS.EDITING_WITH_CHANGES);
    }

    setPageRecipientsEditData(newPageRecipientOrders);
  }

  return {
    featurePageRecipient,
    featuredCardsEditModeRef,
    hiddenCardsEditModeRef,
    hidePageRecipient,
    moveAndSetPageRecipientOrder,
    moveFeaturedPageRecipientToUnfeatured,
    moveHiddenPageRecipientToUnfeatured,
    unfeaturedCardsEditModeRef,
  };
}
