import styled from 'styled-components';

import { Flex, FlexCenter } from 'src/client/components';

export const Container = styled(Flex)`
  color: white;
  width: 100%;
  text-align: center;
  padding: 20px 40px;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.darkSurface3};
  flex-wrap: wrap;
  row-gap: 10px;
  flex-direction: column;
  row-gap: 34px;

  a {
    color: white;
  }

  @media ${(props) => props.theme.device.tablet} {
    flex-direction: row;
  }
`;

export const Logo = styled.img`
  width: 180px;
`;

export const LogoContainer = styled(FlexCenter)`
  gap: 24px;
  flex-wrap: wrap;
  flex-direction: column;

  @media ${(props) => props.theme.device.tablet} {
    flex-direction: row;
  }
`;

export const LinksContainer = styled(FlexCenter)`
  column-gap: 27px;
  row-gap: 34px;
  flex-wrap: wrap;
  flex-direction: column;

  @media ${(props) => props.theme.device.tablet} {
    flex-direction: row;
  }
`;
