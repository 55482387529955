import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import times from 'lodash/times';
import React from 'react';
import MarqueeComponent from 'react-fast-marquee';

import styled from 'styled-components';

import { GalleryColor, GalleryShape } from 'src/commons/constants/gallery';

import Shape from '../pages/DonorGalleryV2/components/GalleryShapeInput/components/Shape';

type Props = {
  galleryShapeColor?: GalleryColor;
  separatorShapes: GalleryShape[];
  children: React.ReactNode;
  scrollSpeed?: number;
};

const MARQUEE_SPEED_MOBILE = 100;
const MARQUEE_SPEED = 200;

function Marquee(props: Props) {
  const { children, galleryShapeColor, scrollSpeed, separatorShapes } = props;
  const screens = useBreakpoint();
  const marqueeScrollSpeed =
    scrollSpeed ?? (screens.lg ? MARQUEE_SPEED : MARQUEE_SPEED_MOBILE);

  const displayChildren = times(2, () =>
    separatorShapes.map((shape) => (
      <MarqueeChild key={shape}>
        {children}
        <SeparatorContainer>
          <Separator
            shape={{
              name: shape,
              color: galleryShapeColor || GalleryColor.COLOR_1,
            }}
          />
        </SeparatorContainer>
      </MarqueeChild>
    ))
  );

  return (
    <MarqueeContainer speed={marqueeScrollSpeed}>
      {displayChildren}
    </MarqueeContainer>
  );
}

const MarqueeContainer = styled(MarqueeComponent)`
  width: 100vw;
`;

const MarqueeChild = styled.div`
  gap: 30px;
  margin-right: 30px;
  display: inline-flex;
`;

const SeparatorContainer = styled.div`
  align-self: center;
`;

const StyledShape = styled(Shape)`
  margin-right: 8px;
  width: 11px;
  height: 11px;
  display: inline-block;
`;

const Separator = styled(StyledShape)`
  svg {
    width: 100%;
    height: 100%;
  }
`;

export default Marquee;
