import React from 'react';
import { useTheme } from 'styled-components';

import { Text } from 'src/client/components';

import LoadingAnimation from 'src/client/components/LoadingAnimation';
import { useGetRecipientLogo } from 'src/client/hooks/queries';

import { formatToCurrency } from 'src/commons/utils/MoneyUtilts';

import CounterBadge from '../CounterBadge';

import * as S from './styles';

type Props = {
  recipientName: string;
  giveCount: number;
  totalGiveAmount: number;
  recipientId: string;
  counterBadgeNumber: number;
};

function TopCard(props: Props) {
  const {
    giveCount,
    recipientName,
    totalGiveAmount,
    recipientId,
    counterBadgeNumber,
  } = props;
  const { colors } = useTheme();

  const { data: recipientLogo, isLoading: isRecipientLogoLoading } =
    useGetRecipientLogo(recipientId);

  const cardCoverContent = isRecipientLogoLoading ? (
    <LoadingAnimation />
  ) : (
    <S.CoverContainer logoSrc={recipientLogo?.recipientLogoImagePath} recipientName={recipientName} />
  );

  return (
    <S.StyledCard cover={cardCoverContent}>
      <CounterBadge count={counterBadgeNumber} />
      <S.DescriptionContainer>
        <Text type="body2med2">{recipientName}</Text>
        <S.SubtitleContainer>
          <Text color={colors.lightMedEmphasis} type="body2reg2">
            {giveCount} Gives
          </Text>
          <Text color={colors.lightMedEmphasis} type="body2reg2">
            {formatToCurrency(totalGiveAmount)} Total
          </Text>
        </S.SubtitleContainer>
      </S.DescriptionContainer>
    </S.StyledCard>
  );
}

export default TopCard;
