import { Layout, Menu } from 'antd';
import styled from 'styled-components';

import theme from 'src/client/themes/default';

import Text from '../Text';

export const StyledSider = styled(Layout.Sider)`
  position: fixed;
  height: 100vh;
  background-color: ${theme.colors.midnight7};
  z-index: 99;
`;

export const MenuStyle = styled(Menu)`
  .ant-menu-item {
    display: flex;
    align-items: center;

    svg {
      height: 21px;
      width: 21px;
    }
  }

  .ant-menu-item.ant-menu-item-selected,
  .ant-menu-item.ant-menu-item-selected {
    background-color: ${(props) => props.theme.colors.darkSurface1};
    border-right: 3px solid ${(props) => props.theme.colors.darkPrimary100};
    color: ${(props) => props.theme.colors.darkPrimary100};
  }

  .ant-menu-item:hover {
    background-color: ${(props) => props.theme.colors.darkSurface2};
    color: ${(props) => props.theme.colors.white};
  }

  .ant-menu-item.ant-menu-item-selected:hover {
    color: ${(props) => props.theme.colors.darkPrimary100};
  }

  width: 100%;
  background-color: ${(props) => props.theme.colors.darkSurface1};
  color: ${(props) => props.theme.colors.white};
  border-right: 0;
`;

export const StyledMenuItem = styled(Menu.Item)``;

export const StyleMenuTextItem = styled(Text)`
  width: 100%;
  margin: 0;
  padding: 4px 0px 0px 4px;
`;

export const StyledText = styled(Text)`
  margin: 32px 0 0 12px;
  color: ${(props) => props.theme.colors.teal2};
`;
