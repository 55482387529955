import { Layout } from 'antd';
import styled from 'styled-components';

export const StyledLayout = styled(Layout)`
  position: relative;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.midnight7};
`;

export const Ornament = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
`;
