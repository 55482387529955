import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import { AccountContext } from 'src/client/contexts/AccountContext';

import BasicLayout from 'src/client/layouts/BasicLayout';
import { PrivacyPolicyPage, RecipientHomepage } from 'src/client/pages';

import routes from 'src/commons/constants/routes';
import { Recipient } from 'src/commons/types';

function RecipientPersonalRoutes() {
  const recipient = useContext(AccountContext) as Recipient;

  return (
    <BasicLayout recipientId={recipient.id}>
      <Switch>
        <Route
          exact
          component={PrivacyPolicyPage}
          path={routes.PRIVACY_POLICY}
        />
        <Route exact path={routes.ROOT}>
          <RecipientHomepage recipient={recipient} />
        </Route>
      </Switch>
    </BasicLayout>
  );
}

export default RecipientPersonalRoutes;
