import { Form, message } from 'antd';
import { AxiosError } from 'axios';
import React, { useContext, useState } from 'react';

import { useTheme } from 'styled-components';

import { Box, Input, Text } from 'src/client/components';

import { AccountContext } from 'src/client/contexts/AccountContext';
import { useSendConfirmMergeAccountsEmailMutation } from 'src/client/hooks/mutations';

import { Donor } from 'src/commons/types';

import * as S from './styles';

const { useForm } = Form;

function MergeAccounts() {
  const account = useContext(AccountContext) as Donor;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { colors } = useTheme();
  const [form] = useForm();
  const {
    mutate: sendConfirmMergeAccountsEmail,
    isLoading: isSendingConfirmMergeAccountsEmail,
  } = useSendConfirmMergeAccountsEmailMutation({
    onError: (error: AxiosError) => {
      if (
        error?.response?.data?.error?.message?.includes(
          'Source donor does not exist'
        )
      ) {
        message.error('No user exist with that email address');
      }
    },
    onSuccess: () => {
      message.success('Confirmation email has been sent');
      hideModal();
    },
  });

  function showModal() {
    setIsModalVisible(true);
  }

  function hideModal() {
    setIsModalVisible(false);
  }

  function handleModalOk() {
    form.submit();
  }

  function handleFormFinish(values: any) {
    sendConfirmMergeAccountsEmail({
      sourceDonorEmail: values.email,
      destinationDonorId: (account as Donor)?.id,
    });
  }

  return (
    <>
      <Box margin="52px 0 8px 0">
        <Text color={colors.darkEmphasis1} type="body1med2">
          Have another Giving Side accounts?
        </Text>
      </Box>
      <Text color={colors.darkEmphasis2} type="body1reg2">
        Merge another account with this one.
      </Text>
      <Box margin="16px 0 0 0" />
      <S.MergeAccountsButton
        data-cy="merge-accounts-btn"
        type="variant2"
        onClick={showModal}
      >
        Merge Accounts
      </S.MergeAccountsButton>
      <S.StyledModal
        centered
        cancelButtonProps={
          {
            className: 'cancel-button',
            ['data-cy']: 'cancel-btn',
          } as any
        }
        confirmLoading={isSendingConfirmMergeAccountsEmail}
        data-cy="merge-accounts-modal"
        okButtonProps={
          {
            className: 'ok-button',
            ['data-cy']: 'confirm-btn',
          } as any
        }
        okText="Confirm"
        open={isModalVisible}
        title={<Text type="h5bold2">Merge Accounts</Text>}
        onCancel={hideModal}
        onOk={handleModalOk}
      >
        <Text color={colors.darkEmphasis2} type="body1reg2">
          Enter the email address of the account you&apos;d like to merge.
          We&apos;ll send an email to confirm the account merge.
        </Text>
        <Box margin="24px 0 0 0" />
        <Form
          form={form}
          layout="vertical"
          preserve={false}
          onFinish={handleFormFinish}
        >
          <Form.Item
            label={<Text type="body1med2">Email</Text>}
            name="email"
            rules={[
              {
                required: true,
                message: 'Email is required',
              },
            ]}
          >
            <Input data-cy="email-input" placeholder="Email" size="large" />
          </Form.Item>
        </Form>
      </S.StyledModal>
    </>
  );
}

export default MergeAccounts;
