import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import React from 'react';
import Slider from 'react-slick';

import { ScaledSlide } from 'src/client/types/Slide';

import { Item } from './components';
import { Container, ArrowButton } from './styles';

const { useBreakpoint } = Grid;

type Props = {
  isLoading: boolean;
  slides: ScaledSlide[];
};

const DEFAULT_SLIDES_TO_SHOW = 3;
const centerPadding = 0;
const settings = {
  arrows: true,
  dots: false,
  centerMode: true,
  centerPadding: centerPadding + 'px',
  nextArrow: (
    <ArrowButton>
      <RightOutlined />
    </ArrowButton>
  ),
  prevArrow: (
    <ArrowButton>
      <LeftOutlined />
    </ArrowButton>
  ),
};

export default function ScaledCenterCarousel(props: Props) {
  const { slides, isLoading } = props;

  const screen = useBreakpoint();

  function getSlides(): ScaledSlide[] {
    if (isLoading) {
      return [...Array(DEFAULT_SLIDES_TO_SHOW).keys()].map(() => ({
        backgroundUrl: '',
        title: '',
      }));
    } else {
      return slides;
    }
  }

  const items = getSlides().map((slide, i) => (
    <Item
      backgroundUrl={slide.backgroundUrl}
      isLoading={isLoading}
      key={i}
      slideText={slide.slideText}
      title={slide.title}
    />
  ));

  const slidesToShow = screen.md ? DEFAULT_SLIDES_TO_SHOW : 1;

  return (
    <Container isBigScreen={!!screen.md}>
      <Slider {...settings} slidesToShow={slidesToShow}>
        {items}
      </Slider>
    </Container>
  );
}
