import { rem } from 'polished';
import { createGlobalStyle } from 'styled-components';

import { BODY_1_REG_2, LABEL_1_MED_2, LABEL_1_REG_2 } from './constants/text';
import defaultTheme from './themes/default';

type DefaultThemeProps = typeof defaultTheme;

type ThemeProps = {
  theme: DefaultThemeProps;
};

export default createGlobalStyle<ThemeProps>`
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 100%;
    font-family: ${(props) => props.theme.fontFamilies.regular};
    scroll-behavior: smooth;
  }

  html {
    @media ${(props) => props.theme.screen.xxl} {
      font-size: 18px;
    }
  }

  .ant-tooltip-inner {
    ${LABEL_1_MED_2}
}

  .ant-tooltip-placement-right {
    padding-left: 8px;
  }

  .ant-dropdown-menu-light:hover .ant-dropdown-menu-light:focus {
    background-color: ${(props) => props.theme.colors.white};
  }

  @font-face {
    font-family: 'SF Pro';
    src: url('/fonts/SF-Pro.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Vollkorn';
    src: url('/fonts/Vollkorn.ttf') format('truetype');
  }

  h1 {
    font-size: ${(props) => props.theme.fontSizes.h1};
    line-height: ${(props) => props.theme.fontSizes.h1};
  }

  h2 {
    font-size: ${(props) => props.theme.fontSizes.h2};
    line-height: ${(props) => props.theme.fontSizes.h2};
  }

  h3 {
    font-size: ${(props) => props.theme.fontSizes.h3};
    line-height: ${(props) => props.theme.fontSizes.h3};
  }

  h4 {
    font-size: ${(props) => props.theme.fontSizes.h4};
    line-height: ${(props) => props.theme.fontSizes.h4};
  }

  h5 {
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: ${(props) => props.theme.fontSizes.h5};
  }

  body {
    font-size: ${(props) => props.theme.fontSizes.body};
    line-height: ${rem('20px')};
  }

  a {
    color: ${(props) => props.theme.colors.teal2};
  }

  .ant-btn {
    line-height: 0px;
  }

  .ant-dropdown-menu {
    max-height: 500px;
    overflow: auto;
  }

  .ant-upload-list-item-thumbnail {
    background-color: ${(props) => props.theme.colors.gray4};
  }

  .ant-notification-notice {
    width: 100%;
    padding: 16px;
    background-color: ${(props) => props.theme.colors.darkSurface2};

    @media ${(props) => props.theme.device.tablet} {
      width: 380px;
    }
  }

  .ant-notification-notice .ant-notification-notice-message {
    color: ${(props) => props.theme.colors.white};
    width: fit-content;

    @media ${(props) => props.theme.device.tablet} {
      width: 250px;
    }

    ${BODY_1_REG_2}
  }

  .ant-notification-notice-with-icon .ant-notification-notice-icon {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .ant-notification-notice-close, .ant-notification-notice-close:hover {
    color: ${(props) => props.theme.colors.white};
  }

  .ant-form-item-explain-error {
    margin-top: 8px;
    color: ${(props) => props.theme.colors.danger500};
    ${LABEL_1_REG_2};
  }

  .ant-form-item-label > label, .ant-form-vertical .ant-form-item-label > label {
    ${LABEL_1_MED_2};
  }

  .ant-form-vertical .ant-form-item-label {
    padding: 0 0 4px 0;
  }

	.receipt h1,
  .receipt h2,
  .receipt h3,
  .receipt h4,
  .receipt h5,
  .receipt body {
    font-size: inherit;
    line-height: inherit;
  }

  .ant-select-dropdown {
    border-radius: 8px;
  }

  .ant-select-dropdown-light {
    padding: 0 4px;

    .ant-select-item {
      border-radius: 4px;
    }

    .ant-select-item-option-content {
      ${BODY_1_REG_2};
      color: ${(props) => props.theme.colors.darkSurface1};
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background: ${(props) => props.theme.colors.gray3};
    }
  }

  .ant-message-custom-content > span {
    font-family: ${(props) => props.theme.fontFamilies.sfPro};
  }
`;
