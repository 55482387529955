import React from 'react';

import GridOptionV2 from 'src/client/pages/DonorGalleryV2/components/GalleryShapeInput/components/GridOption';
import {
  GalleryColor,
  GalleryShape,
  GridOptionType,
} from 'src/commons/constants/gallery';

import GridOptionBase from '../GridOption';

import * as stylesheet from './styles';

type Props = {
  colors?: GalleryColor[];
  type: GridOptionType;
  selectedColor: GalleryColor;
  selectedShape?: GalleryShape;
  styles?: any;
  onChange?: (value: string) => void;
};

function GridOptionPicker(props: Props) {
  const { colors, type, selectedColor, selectedShape, styles, onChange } =
    props;

  const S = styles ?? stylesheet;
  const GridOption = styles ? GridOptionV2 : GridOptionBase;

  let options;

  function handleSelect(value: string) {
    if (onChange) {
      onChange(value);
    }
  }

  if (type === GridOptionType.COLOR) {
    options = (colors as GalleryColor[]).map((color) => (
      <GridOption
        color={color}
        isSelected={selectedColor === color}
        key={color}
        type={GridOptionType.COLOR}
        onClick={handleSelect}
      />
    ));
  }

  if (type === GridOptionType.SHAPE) {
    options = Object.values(GalleryShape).map((galleryShape) => (
      <GridOption
        isSelected={selectedShape === galleryShape}
        key={galleryShape}
        shape={{
          color: selectedColor,
          name: galleryShape,
        }}
        type={GridOptionType.SHAPE}
        onClick={handleSelect}
      />
    ));
  }

  return <S.Container data-cy="grid-option-picker">{options}</S.Container>;
}

export default GridOptionPicker;
