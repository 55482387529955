import styled from 'styled-components';

import { LABEL_1_MED_2 } from 'src/client/constants/text';

export const Container = styled.div`
  display: flex;
  margin: 40px 0 0 0;
  flex-direction: column;
`;

export const Attachment = styled.a`
  ${LABEL_1_MED_2};
  color: ${(props) => props.theme.colors.teal2};
  cursor: pointer;

  .anticon-paper-clip {
    margin-right: 9px;
    color: ${(props) => props.theme.colors.darkEmphasis2};
  }
`;
