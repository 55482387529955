import { Radio, RadioChangeEvent } from 'antd';
import React from 'react';

import { Text } from 'src/client/components';
import { RecipientStat, RecipientStatYear } from 'src/commons/types';

import * as S from './styles';

type Props = {
  recipientStats: RecipientStat[];
  onChange: (year: number) => void;
  selectedYear: number | RecipientStatYear.ALL_TIME;
};

function TimelineControlBar(props: Props) {
  const { recipientStats, onChange, selectedYear } = props;

  const years = recipientStats.map((recipientStat) => recipientStat.year);

  function handleChange(e: RadioChangeEvent) {
    onChange(e.target.value);
  }

  const radioButtons = years
    .filter((year) => year !== RecipientStatYear.ALL_TIME)
    .map((year) => (
      <Radio.Button key={year} value={year}>
        <Text type="button-2-2">{year}</Text>
      </Radio.Button>
    ));

  return (
    <S.StyledRadioGroup value={selectedYear} onChange={handleChange}>
      {radioButtons}
      <Radio.Button value={RecipientStatYear.ALL_TIME}>
        <Text type="button-2-2">All Time</Text>
      </Radio.Button>
    </S.StyledRadioGroup>
  );
}

export default TimelineControlBar;
