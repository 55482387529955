import { DatePicker as AntdDatePicker } from 'antd';
import React, { forwardRef } from 'react';

import { MONTH_DAY_YEAR } from 'src/commons/constants/dateFormat';

import * as S from './styles';

const { RangePicker: AntdRangePicker } = AntdDatePicker;

type DatePickerProps = React.ComponentProps<typeof AntdDatePicker>;
type RangePickerProps = React.ComponentProps<typeof AntdRangePicker>;

function DatePicker(props: DatePickerProps) {
  return <S.StyledDatePicker {...props} />;
}

const RangePicker = forwardRef<any, React.PropsWithChildren<RangePickerProps>>(
  (props: RangePickerProps, ref) => (
    <S.StyledRangePicker {...props} format={MONTH_DAY_YEAR} ref={ref} />
  )
);

DatePicker.RangePicker = RangePicker;

export default DatePicker;
