import styled from 'styled-components';

import { Button, Flex } from 'src/client/components';
import { BUTTON_2_2 } from 'src/client/constants/text';

import Shape from '../Shape';

export const Container = styled(Flex)`
  margin-right: 16px;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.screen.md} {
    margin-right: 30px;
  }
`;

export const TealCircle = styled.div`
  margin-right: 16px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #2fe9fe;
  flex-shrink: 0;

  @media ${(props) => props.theme.screen.md} {
    margin-right: 29px;
    height: 96px;
    width: 96px;
  }
`;

export const CustomizeButton = styled(Button)`
  margin-top: 13px;

  & {
    padding: 7px 12px;
  }

  span {
    ${BUTTON_2_2};
  }
`;

export const StyledShape = styled(Shape)`
  svg {
    width: 40px;
    height: auto;

    @media ${(props) => props.theme.screen.md} {
      width: 96px;
    }
  }
`;
