import { DragEndEvent, SensorDescriptor, SensorOptions } from '@dnd-kit/core';
import React, { forwardRef } from 'react';

import FeaturedCardsEditModeBase from 'src/client/pages/DonorGallery/components/FeaturedCardsEditMode';

import { PageRecipient } from 'src/commons/types';

import * as stylesheet from './styles';

type Props = {
  featuredPageRecipients: PageRecipient[] | undefined;
  isLoading: boolean;
  onHidePageRecipient: (pageRecipientId: string) => void;
  onUnfeaturePageRecipient: (pageRecipientId: string) => void;
  handleDragEnd: (event: DragEndEvent) => void;
  handleDragStart: (event: DragEndEvent) => void;
  sensors: SensorDescriptor<SensorOptions>[];
};

const FeaturedCardsEditMode = forwardRef<HTMLDivElement, Props>(
  (props, ref) => (
    <FeaturedCardsEditModeBase {...props} ref={ref} styles={stylesheet} />
  )
);

export default FeaturedCardsEditMode;
