import { Col, Row } from 'antd';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { useTheme } from 'styled-components';

import { EditProfileCover, Text, Title } from 'src/client/components';

import DonorLayout from 'src/client/layouts/DonorLayout';
import routes from 'src/commons/constants/routes';
import {
  BASICS,
  DEFAULT_EDIT_PROFILE_TAB,
  PERSONALIZE_GIVING_SIDE,
  WHO_GIVES,
} from 'src/commons/constants/tabs';

import Basics from './Basics';
import PersonalizeGivingSide from './PersonalizeGivingSide';
import WhoGives from './WhoGives';
import * as S from './styles';

const formItemGutterHorizontal = 18;
export const formItemGutter: [number, number] = [formItemGutterHorizontal, 0];

function DonorEditProfile() {
  const { path } = useRouteMatch();
  const match = useRouteMatch<{ tab: string }>(routes.EDIT_PROFILE_TAB);
  const { colors } = useTheme();

  const currentTab = match?.params.tab ?? DEFAULT_EDIT_PROFILE_TAB;

  function getTabTitle() {
    const optionalText = (
      <Text color={colors.darkEmphasis2} type="h4med2">
        (optional)
      </Text>
    );

    switch (currentTab) {
      case BASICS:
        return <Text type="h4bold2">The Basics</Text>;
      case PERSONALIZE_GIVING_SIDE:
        return (
          <S.InlineTexts>
            <Text type="h4bold2">Personalize your Giving Side&nbsp;</Text>
            {optionalText}
          </S.InlineTexts>
        );
      case WHO_GIVES:
        return (
          <S.InlineTexts>
            <Text type="h4bold2">
              Help Us Tell the Story of How We Give Today&nbsp;
            </Text>
            {optionalText}
          </S.InlineTexts>
        );
    }
  }

  const tabTitle = getTabTitle();

  return (
    <>
      <Title title="Profile - Giving Side" />
      <DonorLayout>
        <EditProfileCover />
        <S.Container>
          <Row justify="center">
            <Col sm={22} xs={20}>
              <S.Header>{tabTitle}</S.Header>
              <Switch>
                <Route component={Basics} path={`${path}/${BASICS}`} />
                <Route
                  component={PersonalizeGivingSide}
                  path={`${path}/${PERSONALIZE_GIVING_SIDE}`}
                />
                <Route component={WhoGives} path={`${path}/${WHO_GIVES}`} />
                <Route>
                  <Redirect to={`${path}/${DEFAULT_EDIT_PROFILE_TAB}`} />
                </Route>
              </Switch>
            </Col>
          </Row>
        </S.Container>
      </DonorLayout>
    </>
  );
}

export default DonorEditProfile;
