import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import dateFormat from 'dateformat';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

import { downloadGivesExcel, getGives } from 'src/client/api/GiveApi';
import { Flex } from 'src/client/components';
import EditGiveModal from 'src/client/components/EditGiveModal';
import ReceiptModal from 'src/client/components/ReceiptModal';
import Table from 'src/client/components/Table';
import Text from 'src/client/components/Text';
import { RecipientTableCell, ActionTableCell } from 'src/client/components/v2';
import ExportButton from 'src/client/components/v2/ExportButton';
import { TABLE_PAGE_SIZE } from 'src/client/constants/dashboard';
import { updateInfiniteGivesQueryData } from 'src/client/hooks/queries';
import { useGetRecipientsByIds } from 'src/client/hooks/queries/RecipientQueries';
import { usePaginate } from 'src/client/hooks/usePaginate';
import { TableOrder } from 'src/client/types/Table';
import { convertTableOrderToDatastoreOrder } from 'src/client/utils/OrderUtils';
import {
  getRecipientById,
  getRecipientIdsFromGives,
} from 'src/client/utils/Recipientutils';
import { Entity } from 'src/commons/constants/entities';
import { DATE_FORMAT } from 'src/commons/constants/masks';
import { Order } from 'src/commons/constants/order';
import {
  Donor,
  Give,
  GivesFilter,
  GIVE_STATUS,
  Recipient,
  GIVE_UNIT,
} from 'src/commons/types';
import { formatToCurrency } from 'src/commons/utils/MoneyUtilts';
import { capitalizeFirstLetter } from 'src/commons/utils/StringUtils';

import * as S from './styles';

export type GiveTableRowData = Give & {
  recipient: Recipient | undefined;
  isRecipientNameLoading: boolean;
};

type Props = {
  donor: Donor;
  giveYear?: string;
};

function GivesSection(props: Props) {
  const { donor, giveYear } = props;

  const [receiptModalGiveId, setReceiptModalGiveId] = useState('');
  const [isEditGiveModalVisible, setIsEditGiveModalVisible] = useState(false);
  const [isReceiptModalVisible, setIsReceiptModalVisible] = useState(false);
  const [tableSort, setTableSort] = useState<TableOrder<Give>>({
    columnKey: 'giveDate',
    order: Order.DESCEND,
  });
  const queryClient = useQueryClient();

  const givesQuery: GivesFilter = {
    giveYear,
    limit: TABLE_PAGE_SIZE,
    donorId: donor.id as string,
    status: GIVE_STATUS.PROCESSED,
    order: convertTableOrderToDatastoreOrder<Give>(tableSort),
    givesSectionSorting: true,
  };

  const {
    currentPageCount,
    isLoading: isGivesLoading,
    goToFirstPage: goToFirstGivePage,
    isFetchingNextPage: isFetchingNextGivePage,
    currentPage: currentPageData,
    goToNextPage: goToNextGivePage,
    goToPrevPage: goToPrevGivePage,
    isThereMoreToPaginate: isThereMoreGivesToPaginate,
  } = usePaginate({
    api: getGives,
    entity: Entity.GIVE,
    query: givesQuery,
  });

  const gives = currentPageData?.data;

  const [selectedGiveData, setSelectedGiveData] =
    useState<GiveTableRowData | null>(null);

  const {
    data: recipients,
    isLoading: isRecipientsLoading,
    isIdle: isRecipientsIdle,
  } = useGetRecipientsByIds(gives && getRecipientIdsFromGives(gives));

  function showEditModal(record: GiveTableRowData) {
    setIsEditGiveModalVisible(true);
    setSelectedGiveData(record);
  }

  function handleRowClick(record: GiveTableRowData) {
    showEditModal(record);
  }

  function handleCancelEditGive() {
    setIsEditGiveModalVisible(false);
  }

  function handleCancelReceiptModal() {
    setIsReceiptModalVisible(false);
  }

  function handleShowReceiptModal(giveId: string) {
    setReceiptModalGiveId(giveId);
    setIsReceiptModalVisible(true);
  }

  function handleEditModalClose() {
    setIsEditGiveModalVisible(false);
    setSelectedGiveData(null);
  }

  function handleSaveGive(newGive: Give) {
    updateInfiniteGivesQueryData(queryClient, newGive, givesQuery);
    handleEditModalClose();
  }

  function handleTableChange(
    pagination: any,
    filters: any,
    sorter: SorterResult<Give> | SorterResult<Give>[]
  ) {
    if (!Array.isArray(sorter)) {
      setTableSort({
        columnKey: sorter.columnKey as keyof Give,
        order: sorter.order as Order,
      });
    }
  }

  function handleExportGives() {
    downloadGivesExcel({
      giveYear,
      donorId: donor.id as string,
    });
  }

  const columns = makeColumns({
    onReceiptIconClick: handleShowReceiptModal,
    onEditIconClick: showEditModal,
    sortInfo: tableSort,
  });

  function getTableDataSource(): GiveTableRowData[] | undefined {
    return gives?.map((give) => ({
      ...give,
      key: give.id,
      recipient: getRecipientById(recipients, give.recipientId),
      isRecipientNameLoading: isRecipientsLoading || isRecipientsIdle,
    }));
  }

  const editGiveModal = isEditGiveModalVisible && selectedGiveData && (
    <EditGiveModal
      centered
      give={selectedGiveData}
      open={isEditGiveModalVisible}
      title="Edit Give"
      width={866}
      onCancel={handleCancelEditGive}
      onClose={handleEditModalClose}
      onSave={handleSaveGive}
    />
  );

  const renderExportButton = (
    <ExportButton
      disabled={
        isGivesLoading ||
        !getTableDataSource() ||
        getTableDataSource()?.length === 0
      }
      onClick={handleExportGives}
    />
  );

  return (
    <>
      <S.Container id="gives-section">
        <Flex alignItems="center" justifyContent="space-between">
          <Text type="h3bold2">Your {giveYear ?? 'All Time'} Gives</Text>
          {renderExportButton}
        </Flex>
        <Row>
          <Col span={24}>
            <Table
              isV2
              className="table"
              columns={columns}
              dataSource={getTableDataSource()}
              loading={isGivesLoading || isFetchingNextGivePage}
              paginate={{
                currentPageCount,
                onJumpToFirstPage: goToFirstGivePage,
                isThereMoreItemsToPaginate: isThereMoreGivesToPaginate,
                onNextPage: goToNextGivePage,
                onPrevPage: goToPrevGivePage,
                pageSize: TABLE_PAGE_SIZE,
                prevButtonText: 'Prev',
              }}
              onChange={handleTableChange}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
                ['data-testid']: `row-giveId-${record.id}`,
              })}
            />
          </Col>
        </Row>
      </S.Container>
      {editGiveModal}
      <ReceiptModal
        giveId={receiptModalGiveId}
        isVisible={isReceiptModalVisible}
        onCancel={handleCancelReceiptModal}
      />
    </>
  );
}

type MakeColumnParams = {
  onReceiptIconClick: (giveId: string) => void;
  onEditIconClick: (give: GiveTableRowData) => void;
  sortInfo: TableOrder<Give>;
};

function makeColumns(params: MakeColumnParams): ColumnsType<any> {
  const { onReceiptIconClick, onEditIconClick, sortInfo } = params;

  function renderActions(text: string, record: GiveTableRowData) {
    function handleSeeReceiptClick(
      event: React.MouseEvent<HTMLElement, MouseEvent>
    ) {
      event.stopPropagation();
      onReceiptIconClick(record.id);
    }

    function handleGlobalClick(
      event: React.MouseEvent<HTMLElement, MouseEvent>
    ) {
      event.stopPropagation();

      if (record.recipient?.website) {
        const cleanedUrl = record.recipient.website
          .replace('https://', '')
          .trim();
        window.open(`https://${cleanedUrl}`, '_blank');
      }
    }

    function handleEditIconClick() {
      onEditIconClick(record);
    }

    return (
      <ActionTableCell
        isWebsiteLinkVisible={!!record.recipient?.website}
        onClickEdit={handleEditIconClick}
        onClickReceipt={handleSeeReceiptClick}
        onClickWebsiteLink={handleGlobalClick}
      />
    );
  }

  const getSortOrder = (key: string) =>
    sortInfo.columnKey === key ? sortInfo.order : undefined;

  return [
    {
      dataIndex: 'giveDate',
      key: 'giveDate',
      sorter: true,
      title: 'Date',
      sortOrder: getSortOrder('giveDate'),
      render: renderDate,
    },
    {
      dataIndex: 'recipientName',
      key: 'recipientName',
      title: 'Recipient',
      sorter: (a: GiveTableRowData, b: GiveTableRowData) => {
        const nameA = a.recipientName || '';
        const nameB = b.recipientName || '';

        return nameA.localeCompare(nameB);
      },
      sortOrder: getSortOrder('recipientName'),
      render: renderRecipientName,
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      title: 'Contributed',
      align: 'right',
      sorter: (a: GiveTableRowData, b: GiveTableRowData) => {
        const unitPriorityA = a.unit === GIVE_UNIT.DOLLARS ? 1 : 0;
        const unitPriorityB = b.unit === GIVE_UNIT.DOLLARS ? 1 : 0;

        return (
          unitPriorityB - unitPriorityA || (a.amount ?? 0) - (b.amount ?? 0)
        );
      },
      sortOrder: getSortOrder('amount'),
      render: renderAmount,
    },
    {
      dataIndex: 'taxDeductible',
      key: 'taxDeductible',
      title: 'Tax Deductible',
      width: '150px',
      sorter: (a: GiveTableRowData, b: GiveTableRowData) =>
        (a.taxDeductible?.toLowerCase() || '').localeCompare(
          b.taxDeductible?.toLowerCase() || ''
        ),
      sortOrder: getSortOrder('taxDeductible'),
      render: renderTaxDeductible,
    },
    {
      key: 'action',
      title: 'Actions',
      render: renderActions,
    },
  ];
}

function renderDate(date: string) {
  if (!date) {
    return null;
  }

  return <Text type="body1reg2">{dateFormat(date, DATE_FORMAT)}</Text>;
}

function renderRecipientName(recipientName: string, give: GiveTableRowData) {
  return <RecipientTableCell give={give} />;
}

function renderAmount(amount: number, give: GiveTableRowData) {
  return (
    <Text type="body1reg2">
      {give.unit === GIVE_UNIT.DOLLARS
        ? formatToCurrency(amount)
        : `${amount} hours`}
    </Text>
  );
}

function renderTaxDeductible(text: string) {
  return (
    <Text type="body1reg2">{text ? capitalizeFirstLetter(text) : '-'}</Text>
  );
}

export default GivesSection;
