import React from 'react';

import Button from 'src/client/components/Button';

import { Box } from 'src/client/components/StyledCommon';

import Text from 'src/client/components/Text';

import { CardState } from '../..';

import * as S from './styles';

type Props = {
  setCardState: React.Dispatch<React.SetStateAction<CardState>>;
};

function NoGoalState(props: Props) {
  const { setCardState } = props;

  function handleSimpleGoalButtonClick() {
    setCardState(CardState.EDITING_FIXED_AMOUNT);
  }

  function handleSuperGoalButtonClick() {
    setCardState(CardState.EDITING_SUPER_GOAL);
  }

  return (
    <S.Container>
      <img src="/bird.png" />
      <Box margin="33px 0 0 0" />
      <Text type="h2med2">Set a Giving Goal</Text>
      <Box margin="13px 0 0 0" />
      <Text type="body1reg2">
        You haven’t set your giving goal for this year. Try to set a goal real
        quick!
      </Text>
      <Box margin="32px 0 0 0" />
      <S.ButtonsContainer>
        <Button type="primary" onClick={handleSimpleGoalButtonClick}>
          Set Simple Goal
        </Button>
        <Button type="secondary" onClick={handleSuperGoalButtonClick}>
          Set Super Goal ✨
        </Button>
      </S.ButtonsContainer>
    </S.Container>
  );
}

export default NoGoalState;
