import React from 'react';
import { useTheme } from 'styled-components';

import { Box, Text } from 'src/client/components';
import Line from 'src/client/components/Line';
import { Graph } from 'src/commons/types';

import * as S from './styles';

type Props = {
  graphData?: Graph;
  title: string;
  yFieldName: string;
  isAmount?: boolean;
};

function LineGraph(props: Props) {
  const { graphData, title, yFieldName, isAmount = false } = props;

  const { colors } = useTheme();

  const data = graphData ?? [
    {
      xField: 2016,
      yField: 35,
    },
    {
      xField: 2017,
      yField: 10,
    },
    {
      xField: 2018,
      yField: 15,
    },
    {
      xField: 2019,
      yField: 40,
    },
    {
      xField: 2020,
      yField: 45,
    },
    {
      xField: 2021,
      yField: 32,
    },
    {
      xField: 2022,
      yField: 20,
    },
  ];

  return (
    <S.Container>
      <Text type="body1reg2">{title}</Text>
      <Box margin="29px 0 0 0" />
      <Line
        color={colors.teal2}
        data={data}
        height={214}
        lineStyle={{
          lineWidth: 4,
        }}
        meta={{
          yField: {
            // eslint-disable-next-line no-magic-numbers
            max: data.some(d => d.yField > 4) ? undefined : 4,
            formatter: (value) => isAmount ? `$${Number(value).toLocaleString()}` : Number(value).toLocaleString()
          },
        }}
        tooltip={{
          formatter: (datum) => ({
            name: yFieldName,
            value: isAmount ? `$${Number(datum.yField).toLocaleString()}` : Number(datum.yField).toLocaleString(),
          })
        }}
        xField="xField"
        yField="yField"
      />
    </S.Container>
  );
}

export default LineGraph;
