import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './client/App';
import ScrollToTop from './client/components/ScrollToTop';
import CONFIG from './client/configs';
import { GLOBAL_QUERY_CONFIG } from './commons/constants/queryOptions';

const queryClient = new QueryClient({
  defaultOptions: GLOBAL_QUERY_CONFIG,
});

const history = createBrowserHistory();

if (CONFIG.IS_DEPLOYED) {
  Sentry.init({
    dsn: CONFIG.SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    environment: CONFIG.ACTUAL_ENV,
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/givingside.com/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ScrollToTop />
        <App />
      </QueryClientProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
