import { rem } from 'polished';
import { css } from 'styled-components';

export const H1 = css`
  font-size: ${(props) => props.theme.fontSizes.h1};
  line-height: ${(props) => props.theme.fontSizes.h1};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const H2 = css`
  font-size: ${(props) => props.theme.fontSizes.h2};
  line-height: ${(props) => props.theme.fontSizes.h2};
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const H3 = css`
  font-size: ${(props) => props.theme.fontSizes.h3};
  line-height: ${(props) => props.theme.fontSizes.h3};
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const H4 = css`
  font-size: ${(props) => props.theme.fontSizes.h4};
  line-height: 160%;
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const H5 = css`
  font-size: ${(props) => props.theme.fontSizes.h5};
  line-height: ${(props) => props.theme.fontSizes.h5};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const SUBTITLE1 = css`
  font-size: ${(props) => props.theme.fontSizes.subtitle1};
  line-height: ${rem('34px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const SUBTITLE2 = css`
  font-size: ${(props) => props.theme.fontSizes.subtitle2};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  line-height: ${rem('29px')};
`;

export const BODY = css`
  font-size: ${(props) => props.theme.fontSizes.body};
  line-height: 160%;
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const BODY_ITALIC = css`
  font-size: ${(props) => props.theme.fontSizes.body};
  line-height: ${rem('20px')};
  font-weight: ${(props) => props.theme.fontWeights.light};
`;

export const BUTTON_MEDIUM = css`
  font-size: ${(props) => props.theme.fontSizes.buttonSize};
  line-height: ${rem('22px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const BUTTON_REGULAR = css`
  font-size: ${(props) => props.theme.fontSizes.buttonSize};
  line-height: ${rem('22px')};
`;

export const OVERLINE = css`
  font-size: ${(props) => props.theme.fontSizes.overline};
  line-height: ${rem('22px')};
  letter-spacing: 0.13em;
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const CAPTION1 = css`
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${(props) => props.theme.fontSizes.caption1};
  line-height: ${rem('27px')};
`;

export const CAPTION2 = css`
  font-size: ${(props) => props.theme.fontSizes.caption2};
  line-height: ${rem('27px')};
`;

export const CAPTION3 = css`
  font-size: ${(props) => props.theme.fontSizes.caption2};
  line-height: ${rem('18px')};
`;

export const CAPTION4 = css`
  font-size: ${(props) => props.theme.fontSizes.body};
  line-height: ${rem('21px')};
`;

export const BODY_2_REGULAR = css`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${(props) => props.theme.fontSizes.body2};
  line-height: ${rem('22px')};
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const BODY_2_MEDIUM = css`
  font-size: ${(props) => props.theme.fontSizes.body2};
  line-height: ${rem('22px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

const MOBILE_H1_MED_2 = css`
  font-size: ${rem('30px')};
  line-height: ${rem('39px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const H1_MED_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  ${MOBILE_H1_MED_2};

  @media ${(props) => props.theme.device.laptop} {
    font-size: ${rem('36px')};
    line-height: ${rem('47px')};
    font-weight: ${(props) => props.theme.fontWeights.medium};
  }
`;

const MOBILE_H1_BOLD_2 = css`
  font-size: ${rem('30px')};
  line-height: ${rem('39px')};
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const H1_BOLD_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  ${MOBILE_H1_BOLD_2};

  @media ${(props) => props.theme.device.laptop} {
    font-size: ${rem('36px')};
    line-height: ${rem('47px')};
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }
`;

const MOBILE_H2_MED_2 = css`
  font-size: ${rem('26px')};
  line-height: ${rem('36px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const H2_MED_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  ${MOBILE_H2_MED_2}

  @media ${(props) => props.theme.device.laptop} {
    font-size: ${rem('30px')};
    line-height: ${rem('42px')};
    font-weight: ${(props) => props.theme.fontWeights.medium};
  }
`;

const MOBILE_H2_BOLD_2 = css`
  font-size: ${rem('26px')};
  line-height: ${rem('36px')};
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const H2_BOLD_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  ${MOBILE_H2_BOLD_2};

  @media ${(props) => props.theme.device.laptop} {
    font-size: ${rem('30px')};
    line-height: ${rem('42px')};
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }
`;

export const MOBILE_H3_MED_2 = css`
  font-size: ${rem('22px')};
  line-height: ${rem('31px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const H3_MED_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  ${MOBILE_H3_MED_2};

  @media ${(props) => props.theme.device.laptop} {
    font-size: ${rem('24px')};
    line-height: ${rem('34px')};
    font-weight: ${(props) => props.theme.fontWeights.medium};
  }
`;

const MOBILE_H3_BOLD_2 = css`
  font-size: ${rem('22px')};
  line-height: ${rem('31px')};
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const H3_BOLD_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  ${MOBILE_H3_BOLD_2};

  @media ${(props) => props.theme.device.laptop} {
    font-size: ${rem('24px')};
    line-height: ${rem('34px')};
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }
`;

export const H4_MED_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  font-size: ${rem('20px')};
  line-height: ${rem('30px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const H4_BOLD_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  font-size: ${rem('20px')};
  line-height: ${rem('30px')};
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const H5_MED_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  font-size: ${rem('18px')};
  line-height: ${rem('27px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const H5_SEMIBOLD_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  font-size: ${rem('18px')};
  line-height: ${rem('27px')};
  font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const H5_BOLD_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  font-size: ${rem('18px')};
  line-height: ${rem('27px')};
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const H6_MED_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  font-size: ${rem('16px')};
  line-height: ${rem('24px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const H6_BOLD_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  font-size: ${rem('16px')};
  line-height: ${rem('24px')};
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const BODY_1_REG_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${rem('16px')};
  line-height: ${rem('24px')};
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const BODY_1_MED_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${rem('16px')};
  line-height: ${rem('24px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const BODY_2_REG_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${rem('14px')};
  line-height: ${rem('21px')};
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const BODY_2_MED_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${rem('14px')};
  line-height: ${rem('21px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const BUTTON_1_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${rem('16px')};
  line-height: ${rem('19px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const BUTTON_2_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${rem('14px')};
  line-height: ${rem('17px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const LABEL_1_REG_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${rem('14px')};
  line-height: ${rem('18px')};
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const LABEL_1_MED_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${rem('14px')};
  line-height: ${rem('18px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const LABEL_2_MED_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${rem('12px')};
  line-height: ${rem('16px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const LABEL_2_REG_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${rem('12px')};
  line-height: ${rem('16px')};
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const MOBILE_DISPLAY_S_BOLD_2 = css`
  font-size: ${rem('32px')};
  line-height: ${rem('42px')};
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const DISPLAY_S_BOLD_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  ${MOBILE_DISPLAY_S_BOLD_2};

  @media ${(props) => props.theme.device.laptop} {
    font-size: ${rem('40px')};
    line-height: ${rem('52px')};
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }
`;

export const DISPLAY_S_MED_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  font-size: ${rem('45px')};
  line-height: ${rem('52px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

const MOBILE_DISPLAY_M_MED_2 = css`
  font-size: ${rem('36px')};
  line-height: ${rem('47px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const DISPLAY_M_MED_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  ${MOBILE_DISPLAY_M_MED_2};

  @media ${(props) => props.theme.device.laptop} {
    font-size: ${rem('48px')};
    line-height: ${rem('62px')};
    font-weight: ${(props) => props.theme.fontWeights.medium};
  }
`;

const MOBILE_DISPLAY_M_BOLD_2 = css`
  font-size: ${rem('36px')};
  line-height: ${rem('47px')};
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const DISPLAY_M_BOLD_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  ${MOBILE_DISPLAY_M_BOLD_2};

  @media ${(props) => props.theme.device.laptop} {
    font-size: ${rem('48px')};
    line-height: ${rem('62px')};
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }
`;

const MOBILE_DISPLAY_L_MED_2 = css`
  font-size: ${rem('40px')};
  line-height: ${rem('52px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const DISPLAY_L_MED_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  ${MOBILE_DISPLAY_L_MED_2};

  @media ${(props) => props.theme.device.laptop} {
    font-size: ${rem('64px')};
    line-height: ${rem('83.2px')};
    font-weight: ${(props) => props.theme.fontWeights.medium};
  }
`;

const MOBILE_DISPLAY_L_BOLD_2 = css`
  font-size: ${rem('40px')};
  line-height: ${rem('52px')};
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const DISPLAY_L_BOLD_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  ${MOBILE_DISPLAY_L_BOLD_2};

  @media ${(props) => props.theme.device.laptop} {
    font-size: ${rem('64px')};
    line-height: ${rem('83.2px')};
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }
`;

const MOBILE_DISPLAY_XL_BOLD_2 = css`
  font-size: ${rem('48px')};
  line-height: ${rem('62px')};
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const DISPLAY_XL_BOLD_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  ${MOBILE_DISPLAY_XL_BOLD_2};

  @media ${(props) => props.theme.device.laptop} {
    font-size: ${rem('96px')};
    line-height: ${rem('125px')};
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }
`;

export const CAPTION_REG_2 = css`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${(props) => props.theme.fontSizes.captionReg2};
  line-height: ${rem('16px')};
`;

export const LINK = css`
  color: ${(props) => props.theme.colors.darkEmphasis1};
  border-bottom: 1px solid ${(props) => props.theme.colors.darkEmphasis1};
  ${BODY_1_MED_2};
`;
