import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';

type Props = React.ComponentProps<typeof Button>;

function LegacyButton(props: Props) {
  return <StyledButton {...props} />;
}

const StyledButton = styled(Button)`
  margin-top: 0.7rem;
  padding: 1.1rem 3rem;
  height: auto;
  width: 100%;
  border: 1px solid #2fe9fe;
  white-space: normal;

  &&& {
    line-height: 1.5715;
  }
`;

export default LegacyButton;
