import { Modal } from 'antd';
import styled from 'styled-components';

import { Text } from 'src/client/components';
import PictureCardUpload from 'src/client/components/PictureCardUpload';

export const StyledUpload = styled(PictureCardUpload)`
  .ant-upload-list-picture-card-container {
    background-color: ${(props) => props.theme.colors.white};
    pointer-events: none;
    position: absolute;
  }
  .ant-upload-list-picture-card {
    position: relative;
    padding-top: 25px;
  }
`;

export const StyledText = styled(Text)`
  color: ${(props) => props.theme.colors.black};
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    height: 400px;
    width: 564px;
    border-radius: 24px;
  }

  .ant-modal-body {
    padding: 40px 30px 10px 30px;
  }

  .ant-modal-close-x {
    margin: 32px 22px 0 0;
    color: ${(props) => props.theme.colors.royalBlue};
  }

  .ant-modal-footer {
    border: none;
    display: flex;
    justify-content: start;
    flex-direction: row-reverse;
    padding: 10px 22px;
  }

  .ant-btn-primary {
    display: flex;
    margin-right: 20px;
    padding: 25px 13px;
    align-items: center;
    background: ${(props) => props.theme.colors.teal2};
    border-radius: 8px;
    outline: none;
    border: none;
  }

  .ant-btn-default {
    display: flex;
    padding: 25px 13px;
    align-items: center;
    color: ${(props) => props.theme.colors.title85};
    border: none;
    border-radius: 2px;
  }
`;
