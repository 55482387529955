import { DefaultProperties } from './Common.type';

export type Platform = {
  domainName: string | null;
  giveCount: number | null;
  isSyncing: boolean;
  lastSyncDate?: Date | null;
  name: string;
  platformCompanyTypes?: PLATFORM_COMPANY_TYPE[] | null;
  platformStatusTypes?: PLATFORM_STATUS_TYPE[] | null;
  platformTypes?: PLATFORM_TYPE[] | null;
  searchTags: string[];
  slug: string | null;
  website: string | null;
} & DefaultProperties;

export enum PLATFORM_TYPE {
  CROWDFUNDING = 'crowdfunding',
  DONATION = 'donation',
  INDIVIDUAL = 'individual',
  LOAN = 'loan',
  POLITICAL = 'political',
  TICKET = 'ticket',
  OTHER = 'other',
}

export enum PLATFORM_COMPANY_TYPE {
  FOR_PROFIT = 'for_profit',
  FOR_PROFIT_B_CORP = 'for_profit_b_corp',
  NONPROFIT = 'nonprofit',
  UNKNOWN = 'unknown',
  OTHER = 'other',
}

export enum PLATFORM_STATUS_TYPE {
  ACQUIRED = 'acquired',
  ACTIVE = 'active',
  CLOSED = 'closed',
  UNKNOWN = 'unknown',
  OTHER = 'other',
}
