import { Form } from 'antd';
import styled from 'styled-components';

import { BODY_2_REG_2 } from 'src/client/constants/text';

type ImageContainerProps = {
  isSelected?: boolean;
};

type StyledFlexProps = {
  isSelected?: boolean;
}

export const Divider = styled.hr`
  border: 0;
  border-top: 1px solid ${(props) => props.theme.colors.border1};
`;

export const ImagesSectionContainer = styled.div`
  padding: 24px;
  background-color: #11182c08;
  border-radius: 8px;
  margin-top: 66px;
`;

export const WebsiteImagesPlaceholderContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 28.5px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const ImageContainer = styled.div<ImageContainerProps>`
  height: 171px;
  width: 171px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};

  .ant-upload-list-item {
    border: ${(props) =>
    props.isSelected ? `2px solid ${props.theme.colors.midnight7}` : 'none'};
    border-bottom: 0;
    width: 100%;
  }

  .ant-upload-list-picture-card
    .ant-upload-list-item:hover
    .ant-upload-list-item-info:before {
    opacity: 0;
  }
`;

export const UncheckedCircle = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 43.33px;
  border: 0.83px solid ${(props) => props.theme.colors.midnight7};
`;

export const StyledFlex = styled.div<StyledFlexProps>`
  display: flex;
  flex-direction: row;
  padding: 4px 14px;
  border: ${(props) => props.isSelected ? '2px solid #000000' : 'none'};
  border-top: 1px solid rgba(0,0,0, 0.05);
  gap: 4px;
  align-items: center;
  background-color: ${(props) => props.isSelected ? props.theme.colors.midnight7 : props.theme.colors.white};
  color: ${(props) => props.isSelected ? props.theme.colors.white : props.theme.colors.midnight7};
  ${BODY_2_REG_2};
`
export const DeleteButton = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  height: 32px;
  width: 32px;
  padding: 6px;
  border-radius: 3.3px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  z-index: 1; // Ensures the button is in front of the parent
  top: 10px; 
  right: 10px;
  transition: transform 0.2s;
  
  :hover {
    background: ${(props) => props.theme.colors.gray3};
    :not
  }

  :hover:not([disabled]) {
    transform: translate(0px, -4px);
  }
`

export const StyledFormItem = styled(Form.Item)`
    .ant-input-affix-wrapper{
      border-radius: 8px;
      height: 38px;
    }
  `
