import React from 'react';
import styled from 'styled-components';

import { FlexCenter } from 'src/client/components/StyledCommon';
import { Indexable } from 'src/commons/types';

import LoadingAnimation from './LoadingAnimation';

type Props = {
  children?: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  spinSize?: 'small' | 'default' | 'large';
  spinProps?: Indexable;
};

function LoadingCard(props: Props) {
  const {
    className = '',
    children,
    isLoading,
    spinSize = 'default',
    spinProps,
  } = props;

  const loadingCard = (
    <Container className={className}>
      <LoadingAnimation data-testid="loading-icon" size={spinSize} {...spinProps} />
    </Container>
  );

  if (isLoading === true) {
    return loadingCard;
  }

  return <>{children}</>;
}

const Container = styled(FlexCenter)`
  height: 100%;
  width: 100%;
`;

export default LoadingCard;
