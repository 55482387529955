import React from 'react';

import QuotesButtonGroupBase from 'src/client/pages/DonorGallery/components/IntroSection/components/QuotesButtonGroup';

import * as stylesheet from './styles';

type Props = {
  onRandomizeQuote: () => void;
  onSeeAllQuotes: () => void;
};

function QuotesButtonGroup(props: Props) {
  return <QuotesButtonGroupBase styles={stylesheet} {...props} />;
}

export default QuotesButtonGroup;
