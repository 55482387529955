import { Col, Form, Row } from 'antd';
import React from 'react';

import styled from 'styled-components';

import { Box, InputNumber, Select, Text } from 'src/client/components';

import { SPLIT_TYPE, TAX_DEDUCTIBLE } from 'src/commons/types';

import { capitalizeFirstLetter } from 'src/commons/utils/StringUtils';

const { Option } = Select;

const horizontalGutter = 12;

function SplitDetails() {
  const splitTypeOptions = Object.values(SPLIT_TYPE).map((splitType) => (
    <Option key={splitType} value={splitType}>
      {capitalizeFirstLetter(splitType)}
    </Option>
  ));

  const splitTaxDeductibleOptions = Object.values(TAX_DEDUCTIBLE).map(
    (taxDeductible) => (
      <Option key={taxDeductible} value={taxDeductible}>
        {capitalizeFirstLetter(taxDeductible)}
      </Option>
    )
  );

  return (
    <Component>
      <Box margin="0 0 10px 0">
        <Title>Split Details</Title>
      </Box>
      <Row gutter={[horizontalGutter, 0]}>
        <Col flex="auto">
          <Form.Item
            label={<Label>Amount</Label>}
            name="splitAmount"
            rules={[{ required: true, message: 'Amount is required' }]}
          >
            <InputNumber
              data-cy="split-amount-input"
              placeholder="Amount in USD"
              prefix="$"
              size="large"
            />
          </Form.Item>
        </Col>
        <Col flex="auto">
          <Form.Item
            label={<Label>Type</Label>}
            name="splitType"
            rules={[{ required: true, message: 'Type is required' }]}
          >
            <Select
              showSearch
              data-cy="split-type-select"
              placeholder="Type"
              size="large"
            >
              {splitTypeOptions}
            </Select>
          </Form.Item>
        </Col>
        <Col flex="auto">
          <Form.Item
            label={<Label>Tax Deductible?</Label>}
            name="splitTaxDeductible"
            rules={[{ required: true, message: 'Tax Deductible is required' }]}
          >
            <Select
              showSearch
              data-cy="split-tax-deductible-select"
              placeholder="Tax deductible?"
              size="large"
            >
              {splitTaxDeductibleOptions}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Component>
  );
}

const Component = styled.div`
  margin-bottom: 24px;
  padding: 16px;
  background-color: ${(props) => props.theme.colors.border1};
  border-radius: 8px;
`;

const Title = styled(Text).attrs(() => ({
  type: 'h5med2',
}))`
  color: ${(props) => props.theme.colors.darkEmphasis1};
`;

const Label = styled(Text).attrs(() => ({
  type: 'label1med2',
}))`
  color: ${(props) => props.theme.colors.darkEmphasis1};
`;

export default SplitDetails;
