import { rem } from 'polished';
import styled, { createGlobalStyle, css } from 'styled-components';

import galleryFooterBlack from 'src/client/assets/images/covers/gallery-footer-black.svg';
import galleryFooterMagenta from 'src/client/assets/images/covers/gallery-footer-magenta.svg';
import galleryFooterOrange from 'src/client/assets/images/covers/gallery-footer-orange.svg';
import galleryFooterPurple from 'src/client/assets/images/covers/gallery-footer-purple.svg';
import galleryFooterTeal from 'src/client/assets/images/covers/gallery-footer-teal.svg';
import { Button, Text } from 'src/client/components';
import { BODY_2_REG_2, LABEL_2_REG_2 } from 'src/client/constants/text';
import { GalleryColor } from 'src/commons/constants/gallery';

type AccentPickerProps = {
  isLoading: boolean;
};

type AccentColorProps = {
  isSelected: boolean;
  color: string;
  isDisabled: boolean;
};

type SlidingHeaderProps = {
  $isVisible: boolean;
};

type OptionsGroupProps = {
  isHidden: boolean;
};

export const GALLERY_COLOR_VALUES = {
  [GalleryColor.COLOR_1]: galleryFooterOrange,
  [GalleryColor.COLOR_2]: galleryFooterMagenta,
  [GalleryColor.COLOR_3]: galleryFooterTeal,
  [GalleryColor.COLOR_4]: galleryFooterPurple,
  [GalleryColor.COLOR_5]: galleryFooterBlack,
};

export const DonorGalleryGlobalStyle = createGlobalStyle`
  .update-page-success-message {
    margin-top: 45vh;
  }

  .update-page-success-message .ant-message-notice-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 122px;
    width: 270px;
    border-radius: 6px;
  }
`;

export const SlidingHeaderWrapper = styled.header<SlidingHeaderProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.colors.whiteSmoke};
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 18px 0;

  transform: translateY(${(props) => (props.$isVisible ? '0' : '-100%')});
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
`;

export const SlidingHeaderText = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;

  font-family: ${(props) => props.theme.fontFamilies.vollkorn};
  font-size: ${rem('20px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  line-height: 110%; /* 22px */
`;

export const AnimatedPrivacyContainer = styled.div<{ $headerVisible: boolean }>`
  position: fixed;
  top: 24px;
  z-index: 10000;

  display: flex;
  justify-content: center;
  width: 100%;

  padding-right: 18px;

  /* Animate between original position and pushed-down position */
  top: ${(props) => (props.$headerVisible ? '84px' : '24px')};
  transition: top 0.3s ease-in-out;

  @media ${(props) => props.theme.device.mobileL} {
    padding: 0;
    width: fit-content;
    right: 24px;
  }
`;

export const PrivacyStatusContainer = styled.div`
  position: fixed;
  top: 24px;
  z-index: 10000;

  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 18px;
  padding-right: 18px;

  @media ${(props) => props.theme.device.mobileL} {
    padding: 0;
    width: fit-content;
    right: 24px;
  }
`;

export const Container = styled.div`
  background: ${(props) => props.theme.colors.whiteSmoke};
  color: black;

  scroll-snap-type: y proximity;
  overflow-y: scroll;
  height: 100vh;

  .ant-form-item-explain {
    margin-top: 4px;
  }

  .ant-form-item-explain-error {
    ${BODY_2_REG_2}
    color: ${(props) => props.theme.colors.danger500};
  }
`;

export const IntroSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-left: 16px;
  padding-right: 16px;

  @media ${(props) => props.theme.screen.sm} {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media ${(props) => props.theme.screen.md} {
    padding-left: 48px;
    padding-right: 48px;
  }

  @media ${(props) => props.theme.screen.lg} {
    padding-left: 80px;
    padding-right: 80px;
  }
`;

export const EditLabel = styled(Text).attrs({
  type: 'body2reg2',
})`
  && {
    margin-left: 10px;
    padding: 0 4px;
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.gray9};
    border-radius: 4px 4px 0 0;
    width: fit-content;
  }
`;

export const UnderlinedButton = styled(Button).attrs({
  type: 'noStyle',
})`
  && {
    color: ${(props) => props.theme.colors.darkPrimary100};
  }

  &[disabled],
  &[disabled]:hover {
    color: ${(props) => props.theme.colors.darkPrimary100};
    opacity: 40%;
  }

  & > span {
    text-decoration: underline !important;
  }
`;

export const OptionsGroup = styled.div<OptionsGroupProps>`
  &&& {
    border-radius: 0 0 4px 4px;
    margin-left: 10px;

    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.gray9};
    width: fit-content;
    padding: 4px;

    display: flex;
    align-items: center;
    gap: 4px;

    button {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 2px 4px;

      & > .anticon + span {
        margin-left: 0px;
      }

      &:first-child,
      &:last-child {
        border-radius: 4px;
      }

      &:first-child {
        color: ${(props) =>
          props.isHidden
            ? props.theme.colors.white
            : props.theme.colors.darkEmphasis1};
        background: ${(props) =>
          props.isHidden ? 'inherit' : props.theme.colors.white};
      }

      &:last-child {
        color: ${(props) =>
          props.isHidden
            ? props.theme.colors.darkEmphasis1
            : props.theme.colors.white};
        background: ${(props) =>
          props.isHidden ? props.theme.colors.white : 'inherit'};
      }
    }

    span {
      ${LABEL_2_REG_2}
    }

    span:first-child {
      height: 16px;
      width: 16px;
    }

    span > svg {
      height: 16px;
      width: 16px;
    }
  }
`;

export const AccentColorPickerContainer = styled.div<AccentPickerProps>`
  position: fixed;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  padding: 10px 10px 10px 14px;
  align-items: center;
  gap: 10px;
  margin: 0 auto;
  z-index: 99999;

  border-radius: 80px;
  background: white;
  width: 346px;

  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08);

  & > span {
    ${LABEL_2_REG_2}
    line-height: ${rem('14px')};
    white-space: nowrap;
  }

  ${(props) =>
    props.isLoading &&
    css`
      cursor: progress !important;
    `}
`;

export const AccentColor = styled.div<AccentColorProps>`
  background-color: ${(props) => props.color};
  width: 40px;
  height: 40px;
  border-radius: 9999px;

  cursor: pointer;

  ${(props) =>
    props.isSelected &&
    css`
      width: 46px;
      height: 46px;
      border: 4px solid white;
      outline: 2px solid black;
    `}

  ${(props) =>
    props.isDisabled &&
    css`
      cursor: progress !important;
      pointer-events: none;
    `}
`;

export const FooterWrapper = styled.footer<{ isVisible: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #f9a03f;
  transform: translateY(${(props) => (props.isVisible ? '0' : '100%')});
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 90;
`;

export const FooterContainer = styled.div`
  position: relative;
  height: 100vh;

  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  z-index: 9999;
`;

export const FooterBackground = styled.div<{ themeColor: GalleryColor }>`
  width: 100%;
  height: 100%;
  background-image: ${(props) =>
    `url(${GALLERY_COLOR_VALUES[props.themeColor]})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const PoweredByContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 6.486px 10.541px;
  gap: 4.865px;

  position: fixed;
  bottom: 32px;
  right: 24px;
  z-index: 10000;

  border-radius: 4.865px;
  border: 0.811px solid #e2e2e2;
  background: white;
  box-shadow: 0px 4.054px 7.297px 0px rgba(0, 0, 0, 0.05);

  color: ${(props) => props.theme.colors.darkMedEmphasis};
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: 12.973px;
  line-height: 120.5%;
`;

export const Logo = styled.img`
  height: 17px;
`;
