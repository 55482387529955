import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Form, Popconfirm, Tooltip } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import dateFormat from 'dateformat';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { getPlatforms } from 'src/client/api/PlatformApi';
import { Box, Table, Text } from 'src/client/components';
import LoadingAnimation from 'src/client/components/LoadingAnimation';
import MultiFilter, {
  FilterInputs,
  MultiFilterFormValue,
} from 'src/client/components/MultiFilter';
import { useDeletePlatform } from 'src/client/hooks/mutations/platformMutation';

import { useManageTableQueryString } from 'src/client/hooks/useManageTableQueryString';
import { usePaginate } from 'src/client/hooks/usePaginate';
import { TableOrder } from 'src/client/types/Table';

import { createFilterDefaultValues } from 'src/client/utils/FormUtils';
import { Entity } from 'src/commons/constants/entities';
import {
  FilterType,
  PlatformFilterInput,
} from 'src/commons/constants/filterInputs';
import { DATE_FORMAT } from 'src/commons/constants/masks';
import routes from 'src/commons/constants/routes';

import { Platform, PlatformFilter, Recipient } from 'src/commons/types';

import * as S from './styles';

type MakeColumnsParams = {
  orderInfo: TableOrder<any> | undefined;
  onDelete: (platformId: string) => void;
};

const filterDefaultValues: MultiFilterFormValue = createFilterDefaultValues(
  PlatformFilterInput,
  PlatformFilterInput.SEARCH_QUERY
);

const platformTableLimit = 50;
const { useForm } = Form;

function PlatformTable() {
  const [filterForm] = useForm();

  const { mutateAsync: deletePlatform } = useDeletePlatform({
    onSuccess: () => {
      window.location.reload();
    },
  });

  const {
    formattedQueryStringForApiFilter,
    formattedQueryStringForFormValues,
    queryString,
    setTableQueryString,
  } = useManageTableQueryString<PlatformFilter>();

  const {
    currentPageCount,
    isThereMoreToPaginate: isThereMorePlatformsToPaginate,
    goToFirstPage: goToFirstPlatformPage,
    goToNextPage: goToNextPlatformPage,
    goToPrevPage: goToPrevPlatformPage,
    currentPage: platformsResponse,
    isLoading: isPlatformsLoading,
    isFetchingNextPage: isFetchingNextPlatformPage,
    refetch: refetchPlatform,
  } = usePaginate({
    api: getPlatforms,
    entity: Entity.PLATFORM,
    query: {
      ...formattedQueryStringForApiFilter,
      limit: platformTableLimit,
    },
  });

  useEffect(() => {
    refetchPlatform();
  }, []);

  const platforms = platformsResponse?.data;

  function handleTableChange(
    pagination: any,
    filters: any,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) {
    setTableQueryString({
      ...queryString,
      sorter,
    });
  }

  function handleTableFilterChange(changedValues: any, allValues: any) {
    setTableQueryString(allValues);
  }

  function resetFilterAndSort() {
    setTableQueryString({});
    filterForm.setFieldsValue(filterDefaultValues);
    goToFirstPlatformPage();
  }

  function handleDelete(platformId: string) {
    deletePlatform(platformId);
  }

  const columns = makeColumns({
    orderInfo: queryString.tableOrder,
    onDelete: handleDelete,
  });

  return (
    <>
      <MultiFilter
        filterInputs={filterInputs}
        form={filterForm}
        initialFilterType={PlatformFilterInput.SEARCH_QUERY}
        initialValues={formattedQueryStringForFormValues}
        onClearFilterAndSort={resetFilterAndSort}
        onValuesChange={handleTableFilterChange}
      />
      <Box margin="24px 0 0 0" />
      <Table
        columns={columns}
        dataSource={platforms}
        loading={{
          spinning: isPlatformsLoading || isFetchingNextPlatformPage,
          indicator: <LoadingAnimation />,
        }}
        paginate={{
          currentPageCount,
          isThereMoreItemsToPaginate: isThereMorePlatformsToPaginate,
          onJumpToFirstPage: goToFirstPlatformPage,
          onNextPage: goToNextPlatformPage,
          onPrevPage: goToPrevPlatformPage,
          pageSize: platformTableLimit,
        }}
        onChange={handleTableChange}
      />
    </>
  );
}

const filterInputs: FilterInputs = Object.values(PlatformFilterInput).map(
  (platformFilterInput) => {
    switch (platformFilterInput) {
      case PlatformFilterInput.SEARCH_QUERY:
        return {
          filterType: FilterType.TEXT,
          label: 'Search',
          placeholder: 'Search platform',
          key: platformFilterInput,
        };
      case PlatformFilterInput.GIVES:
        return {
          filterType: FilterType.NUMBER_RANGE,
          label: 'Gives',
          key: platformFilterInput,
        };
    }
  }
);

function makeColumns(params: MakeColumnsParams) {
  const { orderInfo, onDelete } = params;

  return [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Name',
      sorter: (a: any, b: any) => 0,
      sortOrder: orderInfo?.columnKey === 'name' ? orderInfo.order : undefined,
      render: (name: string, record: Recipient) => (
        <Link
          to={routes.ADMIN_PLATFORM_DASHBOARD.replace(
            /:platformId/g,
            record.id
          )}
        >
          <Text isInline type="link">
            {name}
          </Text>
        </Link>
      ),
    },
    {
      dataIndex: 'giveCount',
      key: 'giveCount',
      title: 'No. of gives',
      sorter: (a: any, b: any) => 0,
      sortOrder:
        orderInfo?.columnKey === 'giveCount' ? orderInfo.order : undefined,
      render: (giveCount: number | null) => giveCount || 0,
    },
    {
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      title: 'Date Created',
      sorter: true,
      sortOrder:
        orderInfo?.columnKey === 'dateCreated' ? orderInfo.order : undefined,
      render: (date: string) => {
        if (!date) {
          return null;
        }

        return <Text type="body1reg2">{dateFormat(date, DATE_FORMAT)}</Text>;
      },
    },
    {
      dataIndex: '',
      key: '',
      title: 'Action',
      width: '80px',
      render: (text: string, record: Platform) => {
        function handleDelete() {
          onDelete(record.id);
        }

        return (
          <S.ActionContainer>
            <Link
              to={routes.ADMIN_EDIT_PLATFORMS.replace(
                /:platformId/g,
                record.id
              )}
            >
              <Tooltip placement="bottom" title="Edit">
                <EditOutlined />
              </Tooltip>
            </Link>
            <Popconfirm
              cancelText="No"
              okButtonProps={{
                danger: true,
                type: 'primary',
              }}
              okText="Yes"
              placement="topRight"
              title="Delete this platform?"
              onConfirm={handleDelete}
            >
              <Tooltip placement="bottom" title="Delete">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </S.ActionContainer>
        );
      },
    },
  ];
}

export default PlatformTable;
