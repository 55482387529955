import styled from 'styled-components';

import { FlexCenter, Text } from 'src/client/components';

export const SpinContainer = styled(FlexCenter)`
  flex-direction: column;
  height: 80vh;
  width: 100%;
  color: white;
`;

export const LoadingText = styled(Text)`
  max-width: 500px;
  text-align: center;
`;

export const HighlightText = styled(Text)`
  display: inline;
  color: ${({ theme }) => theme.colors.darkPrimary100};
`;
