import styled from 'styled-components';

import { Flex } from 'src/client/components';

type CoverProps = {
  imageSrc: string;
};

export const Card = styled.div`
  width: 100%;
  border-radius: 16px;
  background-color: ${(props) => props.theme.colors.darkSurface3};

  display: flex;
  flex-direction: column;

  @media (${(props) => props.theme.screen.sm}) {
    flex-direction: row;
    height: auto;

    & > div {
      flex: 1;
    }
  }

  @media (${(props) => props.theme.screen.md}) {
    flex-direction: column;

    & > div {
      flex: inherit;
    }
  }
`;

export const CardImage = styled.div<CoverProps>`
  width: 100%;
  height: auto;
  background: ${(props) =>
    `${props.theme.colors.white} center center / cover no-repeat url(${props.imageSrc})`};
  aspect-ratio: 3 / 2;

  border-top-right-radius: 16px;
  border-top-left-radius: 16px;

  @media (${(props) => props.theme.screen.sm}) {
    border-top-right-radius: 0;
    border-bottom-left-radius: 16px;
  }

  @media (${(props) => props.theme.screen.md}) {
    border-bottom-left-radius: 0;
    border-top-right-radius: 16px;
  }
`;

export const CardInformationContainer = styled(Flex)`
  color: white;
  gap: 8px;
  padding: 16px;

  & > :first-child {
    align-self: flex-start;
  }

  @media (${(props) => props.theme.screen.sm}) {
    & > div {
      align-self: flex-start;
    }
  }
`;

export const CardInformationBody = styled(Flex)`
  width: 100%;
  gap: 8px;
`;

export const GivesInformation = styled(Flex)`
  color: ${(props) => props.theme.colors.lightMedEmphasis};

  & > p {
    flex: 1;
  }

  @media (${(props) => props.theme.screen.sm}) {
    & > p {
      flex: initial;
    }

    justify-content: space-between;
  }
`;
