import { UploadFile } from 'antd';
import isNil from 'lodash/isNil';
import { useQueryClient } from 'react-query';

import { deleteImages } from '../api/ImageApi';

import {
  useSaveScrapedRecipientWebsiteImageMutation,
  useUpdateRecipientMutation,
} from './mutations/recipientMutation';
import { useUploadRecipientImageMutation } from './mutations/uploadMutation';
import {
  resetGetRecipientByIdQueryData,
  updateGetRecipientByIdQueryData,
  useGetRecipientById,
} from './queries/RecipientQueries';
import {
  useGetRecipientImages,
  resetGetRecipientImages,
  resetGetRecipientLogo,
} from './queries/imageQueries';

type GetImageTasksParams = {
  imagesToDelete: UploadFile[];
  uploadedImages: UploadFile[];
  scrapedImages: UploadFile[];
  selectedLogoImageId: string;
};

function useAdminEditRecipients(recipientId: string) {
  const queryClient = useQueryClient();

  const { data: recipient, isLoading: isRecipientLoading } =
    useGetRecipientById(recipientId);

  const { data: recipientImagesResponse, isLoading: isRecipientImagesLoading } =
    useGetRecipientImages(recipientId);

  const { mutateAsync: updateRecipient, isLoading: isUpdatingRecipient } =
    useUpdateRecipientMutation({
      onSuccess: (data) => {
        updateGetRecipientByIdQueryData({
          data: data,
          recipientId: data.id,
          queryClient,
        });
      },
    });

  const {
    mutateAsync: uploadRecipientImage,
    isLoading: isUploadingRecipientImage,
  } = useUploadRecipientImageMutation({
    onSuccess: () => false,
  });

  const {
    mutateAsync: saveScrapedRecipientWebsiteImage,
    isLoading: isSavingScrapedRecipientImage,
  } = useSaveScrapedRecipientWebsiteImageMutation({
    onSuccess: () => false,
  });

  const uploadedImagesFromDb = recipientImagesResponse?.recipientImages?.filter(
    (image) => !image.isFromGuidestar
  );

  const scrapedImagesFromDb = recipientImagesResponse?.recipientImages?.filter(
    (image) => image.isFromGuidestar
  );

  const isLoading = isRecipientLoading || isRecipientImagesLoading;

  async function uploadImage(imgFile: UploadFile, selectedLogoImageId: string) {
    const isPrimaryLogo = selectedLogoImageId === imgFile.uid;

    await uploadRecipientImage({
      recipientId,
      isPrimaryLogo,
      file: imgFile.originFileObj as File,
    });
  }

  async function uploadScrapedImage(
    imgFile: UploadFile,
    selectedLogoImageId: string
  ) {
    const isPrimaryLogo = selectedLogoImageId === imgFile.uid;

    await saveScrapedRecipientWebsiteImage({
      recipientId,
      isPrimaryLogo,
      imageUrl: imgFile.url as string,
    });
  }

  function clearRecipientQueryCache() {
    resetGetRecipientByIdQueryData(recipientId, queryClient);
    resetGetRecipientImages(recipientId, queryClient);
    resetGetRecipientLogo(recipientId, queryClient);
  }

  function getImageTasks(params: GetImageTasksParams) {
    const {
      selectedLogoImageId,
      imagesToDelete = [],
      uploadedImages = [],
      scrapedImages = [],
    } = params;

    const tasks = [];

    const imageIdsToDelete = imagesToDelete?.map(({ uid }) => uid);
    const imagesToUpload = uploadedImages?.filter(({ status }) => !status);
    const scrapedImagesToUpload = scrapedImages?.filter(
      ({ status }) => !status
    );

    if (scrapedImagesToUpload.length > 0) {
      tasks.push(
        ...scrapedImagesToUpload.map((img) =>
          uploadScrapedImage(img, selectedLogoImageId)
        )
      );
    }

    if (imagesToUpload.length > 0) {
      tasks.push(
        ...imagesToUpload.map((img) => uploadImage(img, selectedLogoImageId))
      );
    }

    if (imageIdsToDelete.length > 0) {
      tasks.push(deleteImages(imageIdsToDelete));
    }

    return tasks;
  }

  function shouldUpdateLogoId(logoImageId: string | undefined): boolean {
    return isNil(logoImageId) || Number.isInteger(+logoImageId);
  }

  const isSubmitting =
    isUpdatingRecipient ||
    isUploadingRecipientImage ||
    isSavingScrapedRecipientImage;

  return {
    recipient,
    isLoading,
    isSubmitting,
    uploadedImagesFromDb,
    scrapedImagesFromDb,
    updateRecipient,
    getImageTasks,
    clearRecipientQueryCache,
    shouldUpdateLogoId,
  };
}

export default useAdminEditRecipients;
