import { Radio } from 'antd';
import styled from 'styled-components';

export const StyledRadioGroup = styled(Radio.Group)`
  border: 1px solid ${(props) => props.theme.colors.gray2};
  border-radius: 6px;

  .ant-radio-button-wrapper {
    border-color: transparent;
    background: transparent;
    border: none;
    box-shadow: none !important;
    padding: 0 16px;
  }

  .ant-radio-button-wrapper span:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-radio-button-wrapper::before {
    content: initial;
  }

  .ant-radio-button-wrapper > span:last-child {
    height: 100%;
    width: 100%;
  }

  .ant-radio-button-wrapper > span:last-child p {
    color: ${(props) => props.theme.colors.white};
  }

  .ant-radio-button-wrapper-checked {
    background: ${(props) => props.theme.colors.teal2};
  }
`;
