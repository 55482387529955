/* eslint-disable sonarjs/cognitive-complexity */
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { TablePaginationConfig, TableProps } from 'antd';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/lib/table/interface';
import React from 'react';

import Button from '../Button';

import * as S from './styles';

export type CustomProps = {
  isV2?: boolean;
};

type Props = TableProps<any> & {
  paginate?: {
    currentPageCount: number;
    isThereMoreItemsToPaginate: boolean;
    onJumpToFirstPage: () => void;
    onNextPage: () => void;
    onPrevPage: () => void;
    pageSize: number;
    prevButtonText?: string;
    nextButtonText?: string;
  };
} & CustomProps;

function Table(props: Props) {
  const { paginate, isV2, ...tableProps } = props as Required<Props>;

  const isPaginated = !!paginate;

  function getTotalItems() {
    let totalPages = paginate.currentPageCount + 1;

    if (paginate.isThereMoreItemsToPaginate) {
      totalPages += 1;
    }

    return totalPages * paginate.pageSize;
  }

  function goToNextPage() {
    paginate.onNextPage();
  }

  function goToPreviousPage() {
    paginate.onPrevPage();
  }

  function handlePaginationChange(page: number, pageSize?: number | undefined) {
    const isNextPage = page > paginate.currentPageCount;

    if (isNextPage) {
      goToNextPage();
    } else {
      goToPreviousPage();
    }
  }

  // eslint-disable-next-line max-params
  function handleChange(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>
  ) {
    if (extra.action === 'sort') {
      paginate.onJumpToFirstPage();
    }

    if (tableProps?.onChange) {
      tableProps.onChange(pagination, filters, sorter, extra);
    }
  }

  function handleRow(record: any) {
    let result = {};

    if (tableProps.onRow) {
      result = tableProps.onRow(record);
    }

    return {
      ['data-cy']: `row-${record.id}`,
      ...result,
    } as any;
  }

  const pagination: TablePaginationConfig | false | undefined = isPaginated
    ? {
        current: paginate.currentPageCount + 1,
        defaultCurrent: 1,
        total: getTotalItems(),
        pageSize: paginate.pageSize,
        showQuickJumper: false,
        simple: true,
        onChange: handlePaginationChange,
        itemRender: (current, type, OriginalElement) => {
          if (type === 'next') {
            return (
              <Button type="secondary">
                {paginate.nextButtonText ?? 'Next'}
                <RightOutlined />
              </Button>
            );
          }

          if (type === 'prev') {
            return (
              <Button type="secondary">
                <LeftOutlined />
                {paginate.prevButtonText ?? 'Previous'}
              </Button>
            );
          }

          if (type === 'page') {
            return null;
          }

          return OriginalElement;
        },
      }
    : tableProps.pagination;

  return isV2 ? (
    <S.StyledTableV2
      {...tableProps}
      pagination={pagination}
      onChange={handleChange}
      onRow={handleRow}
    />
  ) : (
    <S.StyledTable
      {...tableProps}
      pagination={pagination}
      onChange={handleChange}
      onRow={handleRow}
    />
  );
}

export default Table;
