import React from 'react';
import styled from 'styled-components';

import PrivacyInfoCard from 'src/client/components/PrivacyInfoCard';
import { useSessionStorage } from 'src/client/hooks/useSessionStorage';

import { BUTTON_1_2 } from '../constants/text';

import { Button } from '.';

type Props = {
  onSave?: () => void;
  onDiscard?: () => void;
  isSavingLoading?: boolean;
  saveBtnProps?: React.ComponentProps<typeof Button>;
};

function EditProfileBottom(props: Props) {
  const { isSavingLoading = false, onDiscard, onSave, saveBtnProps } = props;

  const [isPrivacyInfoCardVisible, setIsPrivacyInfoCardVisible] =
    useSessionStorage('isPrivacyInfoCardVisible', 'true');

  function closePrivacyInfoCard() {
    setIsPrivacyInfoCardVisible('false');
  }

  const privacyInfoCard = isPrivacyInfoCardVisible == 'true' && (
    <PrivacyInfoCard onClose={closePrivacyInfoCard} />
  );

  return (
    <>
      <Container>
        <StyledButton type="variant1" onClick={onDiscard}>
          Discard Changes
        </StyledButton>
        <StyledButton
          htmlType="submit"
          loading={isSavingLoading}
          type="primary"
          onClick={onSave}
          {...saveBtnProps}
        >
          Save Changes
        </StyledButton>
      </Container>
      {privacyInfoCard}
    </>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;
  margin: 84px 0;
  gap: 22px;
  flex-wrap: wrap;

  @media ${(props) => props.theme.screen.md} {
    justify-content: flex-end;
  }
`;

const StyledButton = styled(Button)`
  margin-bottom: 16px;
  padding: 15px 20px;
  min-width: 177px;
  ${BUTTON_1_2};
`;

export default EditProfileBottom;
