import { rem } from 'polished';
import styled from 'styled-components';

import { Text } from 'src/client/components';

import * as StylesheetBase from 'src/client/pages/DonorGallery/components/GiveCard/styles';

export const Title = styled(Text)`
  text-align: center;
  font-family: ${(props) => props.theme.fontFamilies.vollkorn};
  font-size: ${rem('18px')};
  line-height: 120.5%; /* 21.69px */
`;

export const {
  Container,
  HoverOverlay,
  RecipientLogoContainer,
  MeatballsMenuIcon,
  ActionButton,
} = StylesheetBase;
