import {
  DonorsGoalProgress,
  DonorsGoalProgressFilter,
} from 'src/commons/types';
import { formatToFinancial } from 'src/commons/utils/MoneyUtilts';

import ApiClient from './ApiClient';

const apiVersion = '1.0';
const endpoint = 'goals';
const url = `${apiVersion}/${endpoint}`;

type GoalAndGiveAmountHistory = {
  year: number;
  goalAmount: number;
  totalDonatedAmount: number | null;
}[];

export async function getDonorsGoalProgress(
  params: DonorsGoalProgressFilter
): Promise<DonorsGoalProgress> {
  const { data } = await ApiClient.post<DonorsGoalProgress>(
    `${url}/get/donors/progress`,
    params
  );

  return data;
}

export async function getGoalAndGiveAmountHistory(
  donorId: string
): Promise<GoalAndGiveAmountHistory> {
  const { data: dataArray } = await ApiClient.get<GoalAndGiveAmountHistory>(
    `${url}/donors/${donorId}/history/goal-and-give-amount`
  );

  return dataArray.map((data) => ({
    ...data,
    goalAmount:
      typeof data.goalAmount === 'number'
        ? formatToFinancial(data.goalAmount)
        : data.goalAmount,
    totalDonatedAmount:
      typeof data.totalDonatedAmount === 'number'
        ? formatToFinancial(data.totalDonatedAmount)
        : data.totalDonatedAmount,
  }));
}
