import { useMutation } from 'react-query';

import { login } from 'src/client/api/AuthApi';
import { Admin } from 'src/commons/types';

type Options = {
  onSuccess: (data: Admin) => void;
};

export function useLoginMutation(opts?: Options) {
  return useMutation(login, opts);
}
