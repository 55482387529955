import { Tabs } from 'antd';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { Text } from 'src/client/components';
import routes from 'src/commons/constants/routes';
import {
  DEFAULT_EDIT_PROFILE_TAB,
  EDIT_PROFILE_TABS,
} from 'src/commons/constants/tabs';

import { LABEL_1_MED_2 } from '../constants/text';

const { TabPane } = Tabs;

function EditProfileCover() {
  const match = useRouteMatch<{ tab: string }>(routes.EDIT_PROFILE_TAB);
  const history = useHistory();

  const currentTab = match?.params.tab ?? DEFAULT_EDIT_PROFILE_TAB;

  function handleTabChange(activeTab: string) {
    history.push(routes.EDIT_PROFILE_TAB.replace(/:tab/, activeTab));
  }

  const tabPanes = EDIT_PROFILE_TABS.map((tab) => (
    <TabPane key={tab} tab={tab.replace(/-/gi, ' ')} />
  ));

  return (
    <Container>
      <Text color="white" type="h1bold2">
        Edit your profile
      </Text>
      <StyledTabs activeKey={currentTab} onChange={handleTabChange}>
        {tabPanes}
      </StyledTabs>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding: 0 60px;
  height: 242.92px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  background: url('/editProfileCover.png');
  background-size: cover;

  wrap: {
    padding: '16px';
  }

  @media ${(props) => props.theme.device.tablet} {
    padding: 0 60px 0 110px;
  }
`;

const StyledTabs = styled(Tabs)`
  display: flex;
  margin-top: 27px;
  text-transform: capitalize;

  .ant-tabs-nav-list {
    white-space: nowrap;
  }

  .ant-tabs-tab-btn {
    ${LABEL_1_MED_2};
  }

  .ant-tabs-tab-btn,
  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-btn:active {
    color: ${(props) => props.theme.colors.lightEmphasis2};
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.white} !important;
  }

  .ant-tabs-nav::before {
    border: none;
  }

  .ant-tabs-ink-bar {
    background: ${(props) => props.theme.colors.white};
  }
`;

export default EditProfileCover;
