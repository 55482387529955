import styled from 'styled-components';

import { Button, FlexCenter, Text } from 'src/client/components';
import { CAPTION4 } from 'src/client/constants/text';

type ContainerProps = {
  isEditMode: boolean;
};

export const Container = styled(FlexCenter)<ContainerProps>`
  position: relative;
  margin: 0 0 -2px -2px;
  padding: 8px;
  height: 400px;
  width: 321px;
  border: 1px solid ${(props) => props.theme.colors.gray7};
  flex-direction: column;
  background: ${(props) =>
    props.isEditMode ? props.theme.colors.white : 'transparent'};
`;

export const HoverOverlay = styled(FlexCenter)`
  flex-direction: column;
  gap: 11px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
`;

export const RecipientLogoContainer = styled(FlexCenter)`
  margin-bottom: 40px;
  height: 221px;
  width: 221px;
  border: 1px solid ${(props) => props.theme.colors.gray7};
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.darkSurface1};
  overflow: hidden;
`;

export const Title = styled(Text)`
  ${CAPTION4};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const MeatballsMenuIcon = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;

  @media ${(props) => props.theme.screen.md} {
    display: none;
  }
`;

export const ActionButton = styled(Button)`
  min-width: 99px;
  padding: 16px 20px !important;
`;
