import React from 'react';

import { Link, useHistory } from 'react-router-dom';

import { Box, Button, Text } from 'src/client/components';

import { GIVING_SIDE_EMAIL } from 'src/client/constants/email';

import routes from 'src/commons/constants/routes';

import * as S from './styles';

function PlatformFooter() {
  const history = useHistory();

  function redirectToRoot() {
    history.push(routes.ROOT);
  }

  return (
    <S.Container>
      <S.TriangleImage src="/triangle.svg" />
      <S.ContentContainer>
        <S.SendEmailContainer>
          <S.StyledText breakpoint="660px" color="#fff" type="body1reg2">
            Thank you for subscribing to your platform’s User Insight Dashboard.
            We’re working on adding more helpful data, as well as information to
            assist you in your work. For feature requests send us an email!
          </S.StyledText>
          <Box padding="0 40px 125px">
            <Button
              to="#"
              type="primary"
              onClick={(e) => {
                window.location.href = `mailto:${GIVING_SIDE_EMAIL}`;
                e.preventDefault();
              }}
            >
              Send us an email
            </Button>
          </Box>
          <S.FooterNavigationContainer>
            <S.FlexChild>
              <S.Logo src="/logo-white.png" onClick={redirectToRoot} />
              <Link to={routes.PRIVACY_POLICY}>
                <Text isInline color="white" type="body1med2">
                  Privacy Policy
                </Text>
              </Link>
            </S.FlexChild>
            <S.FlexChild>
              <Text isInline color="white" type="body1med2">
                Our Advisors
              </Text>
              <a href="mailto:hello@givingside.com">
                <Text isInline color="white" type="body1med2">
                  Contact Us
                </Text>
              </a>
              <Link to={routes.DONOR_SIGN_IN}>
                <Text isInline color="white" type="body1med2">
                  Beta Users Sign In
                </Text>
              </Link>
            </S.FlexChild>
          </S.FooterNavigationContainer>
        </S.SendEmailContainer>
      </S.ContentContainer>
    </S.Container>
  );
}

export default PlatformFooter;
