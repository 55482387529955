import React from 'react';
import styled from 'styled-components';

import { Box, Flex, LoadingCard, Text } from 'src/client/components';
import { CardViewType } from 'src/client/pages/DonorDashboard/components/ByTheNumbersSection';

import { StyledText } from '../../Text/styles';

type Props = {
  title: string;
  children: React.ReactNode;
  cardViewType?: CardViewType;
  isLoading?: boolean;
  isComingSoon?: boolean;
};

function StatCard(props: Props) {
  const { title, children, isLoading, isComingSoon, cardViewType } = props;

  return (
    <Container cardViewType={cardViewType || CardViewType.AMOUNT}>
      <StyledLoadingCard isLoading={isLoading}>
        <Flex>
          <TitleText type="h5med2">{title}&nbsp;</TitleText>
        </Flex>
        {!isComingSoon && children}
        {isComingSoon && <GapBox />}
        {isComingSoon && <Flex><ComingSoonText type='caption1'>Coming Soon</ComingSoonText></Flex>}
      </StyledLoadingCard>
    </Container>
  );
}

const Container = styled.div<{ cardViewType: CardViewType }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 30px 28px;
  width: 100%;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.darkSurface2};
  border-left: 2px solid ${(props) => props.cardViewType == CardViewType.AMOUNT ? props.theme.colors.teal1 : props.theme.colors.magenta500};
  transition: border-left 0.25s;

  @media ${(props) => props.theme.device.laptop} {
    flex-basis: 48%; // 2 cards in a row (not 50% to give small offset)
  }

  @media ${(props) => props.theme.device.laptopL} {
    flex-basis: 31%; // 3 cards in a row (not 33% to give small offset)
  }

  .anticon {
    color: ${(props) => props.theme.colors.lightEmphasis2};
    font-size: 12px;
  }
`;

export const StyledLoadingCard = styled(LoadingCard)`
  height: 110px;
  border-radius: 8px;
`;

const TitleText = styled(Text)`
  color: ${(props) => props.theme.colors.lightEmphasis2};
  margin-bottom: 10px;

  @media ${(props) => props.theme.device.tablet} {
    margin-bottom: 23px;
  }
`;

const ComingSoonText = styled(StyledText)`
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};
  color: ${(props) => props.theme.colors.white};
  opacity: 0.4;
`;

const GapBox = styled(Box)`
  flex: 1 0 20px
`

export default StatCard;
