import { useMutation } from 'react-query';

import {
  createGive,
  deleteGive,
  duplicateGive,
  processGive,
  rejectGive,
  updateGive,
} from 'src/client/api/GiveApi';
import { ProcessGiveResponse } from 'src/client/types/ApiResponse';
import { Give } from 'src/commons/types';
import { ApiResponse } from 'src/commons/types/Response.type';

type CommonOptions<Response = ApiResponse<Give>> = {
  onSuccess?: (data: Response) => void;
  onMutate?: (variables: any) => unknown;
};

export function useUpdateGiveMutation(opts?: CommonOptions) {
  return useMutation(updateGive, opts);
}

export function useRejectGiveMutation(opts?: CommonOptions) {
  return useMutation(rejectGive, opts);
}

export function useProcessGiveMutation(
  opts?: CommonOptions<ProcessGiveResponse>
) {
  return useMutation(processGive, opts);
}

export function useDeleteGiveMutation(opts?: CommonOptions<string>) {
  return useMutation(deleteGive, opts);
}

export function useCreateGiveMutation() {
  return useMutation(createGive);
}

export function useDuplicateGiveMutation(
  opts?: CommonOptions<{
    duplicatedGive: Give;
  }>
) {
  return useMutation(duplicateGive, opts);
}
