import styled from 'styled-components';

import { Button } from 'src/client/components';

export const Container = styled.div`
  margin-top: 40px;
  padding: 40px;
  border-radius: 24px;
  background: ${(props) => props.theme.colors.white};

  .ant-tabs-ink-bar {
    background: ${(props) => props.theme.colors.teal2};
  }

  .ant-btn-text {
    padding: 0;
  }
`;

export const SubmitButton = styled(Button)`
  margin-bottom: 40px;
  width: 100%;
`;
