import { DefaultProperties } from './Common.type';

export type Recipient = {
  adjective?: string | null;
  createdBy?: string | null;
  currentSignInAt?: Date | null;
  currentSignInIp?: string | null;
  domainName?: string | null;
  email?: string | null;
  encryptedPassword?: string | null;
  giveCount: number | null;
  guideStarOrgId?: string | null;
  guideStarOrgLegacyId?: string | null;
  isSyncing: boolean;
  lastSignInAt?: Date | null;
  lastSignInIp?: string | null;
  lastSyncDate?: Date | null;
  logoImageId: string | null;
  loweredCaseName: string | null;
  name: string;
  ogDescription?: string | null;
  ogTitle?: string | null;
  parentRecipientId?: string | null;
  parentRecipientLegacyId?: string | null;
  recipientTypes?: RECIPIENT_TYPE[] | null;
  rememberCreatedAt?: Date | null;
  resetPasswordSentAt?: Date | null;
  resetPasswordToken?: string | null;
  searchTags: string[];
  signInCount?: number | null;
  slug?: string | null;
  taxid?: string | null;
  website?: string | null;
} & DefaultProperties;

export type RecipientBasic = Pick<Recipient, 'id' | 'name'>;

export enum RECIPIENT_TYPE {
  CROWDFUNDING = 'crowdfunding',
  FOR_PROFIT = 'for_profit',
  FOR_PROFIT_B_CORP = 'for_profit_b_corp',
  INDIVIDUAL = 'individual',
  LABOR_UNION = 'labor_union',
  NONPROFIT = 'nonprofit',
  NONPROFIT_FISCALLY_SPONSORED = 'nonprofit_fiscally_sponsored',
  OTHER = 'other',
  POLITICAL_C4 = 'political_c4',
  POLITICAL_CANDIDATE = 'political_candidate',
  POLITICAL_PAC = 'political_pac',
}

export const LEGACY_RECIPIENT_TYPES = ['political_campaign'] as const;
export type LegacyRecipientType = (typeof LEGACY_RECIPIENT_TYPES)[number];

export type ValidRecipientType = RECIPIENT_TYPE | LegacyRecipientType;

export type SaveScrapedRecipientWebsiteImageParams = {
  imageUrl: string;
  isPrimaryLogo: boolean;
  recipientId: string;
};
