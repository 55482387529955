import { Radio } from 'antd';
import { rem } from 'polished';
import styled from 'styled-components';

import { Flex } from 'src/client/components';
import LineGraph from 'src/client/components/LineGraph';

import { StyledText } from 'src/client/components/Text/styles';

import { GraphView } from '.';

export const Container = styled.div`
  display: flex;
  padding: 30px 37px 68px;
  height: 467px;
  flex-direction: column;
  background: ${(props) => props.theme.colors.darkSurface2};
  border-radius: 8px;

  .ant-select {
    width: 123px;
  }
`;

export const StyledLineGraph = styled(LineGraph)`
  width: 100%;
`;

export const LineGraphContainer = styled.div`
  margin-top: 44px;
  height: 100%;
`;

export const HeadingContainer = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`;

export const RadioGroup = styled(Radio.Group)`
  --bgColor: ${(props) => props.value == GraphView.AMOUNT ? props.theme.colors.teal1 : props.theme.colors.magenta500};
  background-color: ${(props) => props.theme.colors.darkSurface3};
  display: grid;
  grid-auto-flow: column;
  border-radius: 6px;
  padding: 4px;
  gap: 6px;

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: ${(props) => props.value == GraphView.AMOUNT ? props.theme.colors.black : props.theme.colors.white};
    background: var(--bgColor);
    border-color: var(--bgColor);

    &:hover{
      color: ${(props) => props.value == GraphView.AMOUNT ? props.theme.colors.black : props.theme.colors.white};
      background: var(--bgColor);
      border-color: var(--bgColor);
    }

    &:before{
      background-color: transparent;
    }
  }

  .ant-radio-button-wrapper {
    display: flex;
    border: none;
    background: transparent;
    color: ${(props) => props.theme.colors.white};
    border-radius: 6px;
    padding: 4px 8px 4px 8px;
    align-items: center;
    justify-content: center;

    &:focus-within{
      box-shadow: none !important;
    }

    &:not(:first-child){
      &:before{
        background-color: transparent;
      }
    }
  }

  span:has(.anticon svg){
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .anticon{
    margin-right: 6px;
    & svg{
      font-size: ${rem('19px')};
    }
  }
`

export const Text = styled(StyledText)`
  font-family: ${(props) => props.theme.fontFamilies.sfPro};
  font-size: ${(props) => props.theme.fontSizes.body2};
  line-height: ${rem('16.8px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`
