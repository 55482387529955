import { getYears } from 'src/commons/utils/DateUtils';

import { useGetDonorFirstGive } from './queries';

export function useGetYearsSinceFirstGive(
  donorId: string,
  isDescending = false
) {
  const { data: donorFirstGive, isLoading } = useGetDonorFirstGive(donorId);

  function getGiveYears() {
    const years = getYears(getFirstGiveDate(), new Date());

    if (isDescending) {
      return years.reverse();
    }

    return years;
  }

  function getFirstGiveDate() {
    if (donorFirstGive?.giveDate) {
      return new Date(donorFirstGive.giveDate);
    } else {
      return new Date();
    }
  }

  return {
    isLoading,
    giveYears: getGiveYears(),
  };
}
