import { rem } from 'polished';
import styled, { createGlobalStyle } from 'styled-components';

import { Button, Text } from 'src/client/components';
import { Select } from 'src/client/components/v2';
import { BUTTON_1_2, BUTTON_2_2 } from 'src/client/constants/text';

export const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
  background: ${(props) => props.theme.colors.gray1};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray5};

  width: 100%;
  min-height: 60px;
  padding: 14px;
  gap: 8px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media ${(props) => props.theme.screen.md} {
    flex-wrap: nowrap;
  }
`;

export const BackButton = styled(Button)`
  &&&.ant-btn-lg {
    padding-left: 2px !important;
  }

  @media ${(props) => props.theme.screen.md} {
    margin-right: 16px;
  }

  &:hover {
    background: white;
  }

  & > span:last-child {
    ${BUTTON_1_2}
    display: none;

    @media ${(props) => props.theme.screen.md} {
      display: inline-block;
    }
  }
`;

export const EditingText = styled(Text)`
  color: ${(props) => props.theme.colors.darkEmphasis2};
  width: 120px;

  @media ${(props) => props.theme.screen.md} {
    width: fit-content;
    margin-right: 8px;
  }
`;

export const AccessText = styled(Text)`
  margin-right: 8px;

  ${BUTTON_1_2}
  color: ${(props) => props.theme.colors.darkEmphasis2};

  display: none;

  @media ${(props) => props.theme.screen.md} {
    display: inline-block;
  }
`;

export const PrivacySelectGlobalStyle = createGlobalStyle`
  .ant-select-dropdown {
    width: 300px !important;
    padding: 8px 0;
  }

  & .ant-select-dropdown-dark {
    *::-webkit-scrollbar-track {
      background: transparent;
    }

    *::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.lightSurface20};
      border-radius: 6px;
    }

    *::-webkit-scrollbar {
      width: 5px;
    }

    .ant-select-item {
      padding: 8px 16px;

      &:not(:first-child) {
        border-top: 1px solid ${(props) => props.theme.colors.gray4};
      }
    }

    && .ant-select-item-option-content {
      display: block;
      width: 100%;
    }

    && .ant-select-item-option-selected.ant-select-item-option-selected {
      background: unset;

      & .anticon.anticon-check {
        display: block;
      }
    }

    && .ant-select-item-option-active.ant-select-item-option-active {
      background: white;
    }
  }
`;

export const PrivacySelect = styled(Select)`
  position: relative;
  max-width: 220px;
  width: fit-content;

  &&&&& .ant-select-selector {
    padding: 11px 12px;
    color: ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.gray4};
  }

  & .anticon-down {
    color: ${(props) => props.theme.colors.black};
    font-size: ${rem('14px')};
    padding-top: 2px;
    margin-left: 16px;
  }

  @media ${(props) => props.theme.screen.lg} {
    width: 100%;
  }
`;

export const SelectOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  div > span {
    ${BUTTON_2_2}
  }

  div > span:first-child {
    color: ${(props) => props.theme.colors.black};
  }

  div > span:last-child {
    color: ${(props) => props.theme.colors.darkEmphasis2};
  }

  & > span {
    display: none;
    color: ${(props) => props.theme.colors.black};
    font-size: ${rem('20px')};
  }
`;

export const SelectOptionCurrent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  ${BUTTON_2_2};

  & > span {
    display: inline;
  }
`;

export const SelectLoadingContainer = styled.div`
  width: 196px;
  height: 40.5px;

  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${rem('16px')};

  border-radius: 4px;
  background: ${(props) => props.theme.colors.gray4};
`;

export const CopyLinkButton = styled(Button)`
  & > span:last-child {
    ${BUTTON_2_2}

    display: none;

    @media ${(props) => props.theme.screen.md} {
      display: inline-block;
    }
  }
`;

export const PreviewButton = styled(Button)`
  margin-left: auto;

  & > span:last-child {
    ${BUTTON_2_2}

    display: none;

    @media ${(props) => props.theme.screen.md} {
      display: inline-block;
    }
  }
`;

export const CloseEditButton = styled(Button)`
  padding: 12px 16px;
`;

export const UnsavedSectionText = styled(Text)`
  color: ${(props) => props.theme.colors.darkEmphasis1};
`;

export const DiscardButton = styled(Button)``;
