import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { rem } from 'polished';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { Box, Button, Flex, Text } from 'src/client/components';
import { Direction } from 'src/client/constants/Direction';

type Props = {
  direction: Direction.LEFT | Direction.RIGHT;
  isHidden: boolean;
  onClick?: () => void;
  tabName?: string;
};

function FooterNavigationButton(props: Props): React.ReactElement {
  const { isHidden, direction, tabName, onClick } = props;

  const { colors } = useContext(ThemeContext);

  const directionText = direction === Direction.LEFT ? 'Prev' : 'Next';
  const alignItems = direction === Direction.LEFT ? 'flex-start' : 'flex-end';
  const leftArrow = direction === Direction.LEFT && (
    <Box margin="0 30px 0 0">
      <ArrowLeftOutlined />
    </Box>
  );
  const rightArrow = direction === Direction.RIGHT && (
    <Box margin="0 0 0 30px">
      <ArrowRightOutlined />
    </Box>
  );

  return (
    <Container isHidden={isHidden} type="noStyle" onClick={onClick}>
      <Flex alignItems="center">
        {leftArrow}
        <Flex alignItems={alignItems} flexDirection="column">
          <Text color={colors.white} type="displaySBold2">
            {directionText}
          </Text>
          <TabText color={colors.teal1} type="body1reg2">
            {tabName}
          </TabText>
        </Flex>
        {rightArrow}
      </Flex>
    </Container>
  );
}

type ContainerProps = {
  isHidden: boolean;
};

const Container = styled(Button)<ContainerProps>`
  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};

  &:focus {
    outline: none;
  }
`;

const TabText = styled(Text)`
  font-size: ${rem('12px')};
  line-height: ${rem('16px')};

  @media ${(props) => props.theme.screen.md} {
    font-size: inherit;
    line-height: inherit;
  }
`;

export default FooterNavigationButton;
