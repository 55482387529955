import styled from 'styled-components';

import { Button, Flex, FlexCenter, Input, Text } from 'src/client/components';

export const ErrorText = styled(Text)`
  color: #ff0000;
`;

export const Container = styled.div`
  text-align: center;
  color: black;

  form {
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-explain-error {
    text-align: left;
  }
`;

export const Logo = styled.img`
  width: 121px;
`;

export const Header = styled(Text)`
  margin-bottom: 21px;
`;

export const NavigationBar = styled(Flex)`
  margin-bottom: 100px;
  padding: 16px 40px;
  align-items: center;
  justify-content: space-between;
`;

export const BodyContainer = styled(FlexCenter)`
  margin: 0 auto;
  width: 100%;
  max-width: 407px;
  flex-direction: column;

  a {
    border-bottom: 1px solid black;
    color: black;
  }
`;

export const StyledInput = styled(Input)`
  width: 100%;
`;

export const StyledInputPassword = styled(Input.Password)`
  width: 100%;
`;

export const LoginButton = styled(Button)`
  width: 100%;
`;

export const GoogleButton = styled(Button)`
  && {
    display: flex;
    width: 100%;
    background: white;
    color: ${(props) => props.theme.colors.darkEmphasis1};
    border: 1px solid ${(props) => props.theme.colors.darkLowEmphasis};
    align-items: center;
    justify-content: center;

    img {
      margin-right: 8px;
    }
  }
`;

export const CheckboxContainer = styled(Flex)`
  margin-bottom: 24px;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: 8px;

  @media ${(props) => props.theme.screen.sm} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const RequestEarlyAccessContainer = styled(Flex)`
  margin: 21px 0 0 0;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  gap: 8px;

  @media ${(props) => props.theme.screen.sm} {
    justify-content: space-between;
    flex-direction: row;
  }
`;
