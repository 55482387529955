import styled from 'styled-components';

import NumberInput from 'src/client/components/NumberInput';
import { H2_MED_2 } from 'src/client/constants/text';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const AmountInput = styled(NumberInput)`
  border-radius: 0px;
  background: transparent;
  border: none;
  outline: none;
  color: ${(props) => props.theme.colors.white};
  border-bottom: 1px dashed ${(props) => props.theme.colors.teal1} !important;
  margin: 32px 0 8px 0;
  text-align: center;
  ${H2_MED_2}

  &&& {
    box-shadow: none;
  }
`;
