import { RadioChangeEvent } from 'antd';
import React from 'react';
import { useQueryClient } from 'react-query';

import { YEAR_VIEW } from 'src/client/constants/DonorPages';

import {
  useCreateDonorGoalMutation,
  useUpdateDonorGoalMutation,
} from 'src/client/hooks/mutations';
import { updateDonorGoalQueryData } from 'src/client/hooks/queries';
import { Donor, Goal } from 'src/commons/types';
import { removeNullEntries } from 'src/commons/utils/ObjectUtil';

import { CardState } from '../..';
import useGivingGoalState from '../../hooks/useGivingGoalState';
import FixedAmountEditState from '../FixedAmountEditState';
import SuperGoalEditState from '../SuperGoalEditState';

import * as S from './style';

enum RadioGroupValues {
  FIXED_AMOUNT = 'fixedAmount',
  SUPER_GOAL = 'superGoal',
}

type Props = {
  donor: Donor;
  cardState: CardState;
  setCardState: React.Dispatch<React.SetStateAction<CardState>>;
  donorGoal: Goal | undefined;
  setShouldShowConfetti: React.Dispatch<React.SetStateAction<boolean>>;
  yearView: number | YEAR_VIEW.ALL_TIME;
  donorGoalQuery: {
    donorId: string;
    year?: number;
  };
};

function EditState(props: Props) {
  const {
    cardState,
    donor,
    donorGoal,
    donorGoalQuery,
    setCardState,
    setShouldShowConfetti,
    yearView,
  } = props;

  const queryClient = useQueryClient();
  const {
    goalState,
    setNewGoal,
    startEditingSuperGoal,
    adjustIncome,
    adjustIncomeRate,
    adjustGivingGoal,
  } = useGivingGoalState(donorGoal);
  const {
    mutateAsync: createDonorGivingGoal,
    isLoading: isCreateDonorGoalLoading,
  } = useCreateDonorGoalMutation({
    onSuccess: (data) => {
      updateDonorGoalQueryData(queryClient, data, donorGoalQuery);
    },
  });
  const {
    mutateAsync: updateDonorGivingGoal,
    isLoading: isUpdateDonorGoalLoading,
  } = useUpdateDonorGoalMutation({
    onSuccess: (data) => {
      updateDonorGoalQueryData(queryClient, data, donorGoalQuery);
    },
  });

  function getRadioGroupValue() {
    if (cardState === CardState.EDITING_FIXED_AMOUNT) {
      return RadioGroupValues.FIXED_AMOUNT;
    }

    if (cardState === CardState.EDITING_SUPER_GOAL) {
      return RadioGroupValues.SUPER_GOAL;
    }
  }

  function handleRadioGroupChange(e: RadioChangeEvent) {
    if (e.target.value === RadioGroupValues.FIXED_AMOUNT) {
      setCardState(CardState.EDITING_FIXED_AMOUNT);
    }

    if (e.target.value === RadioGroupValues.SUPER_GOAL) {
      setCardState(CardState.EDITING_SUPER_GOAL);
    }
  }

  function cancelEditing() {
    if (donorGoal) {
      setCardState(CardState.DISPLAY_GOAL);
    } else {
      setCardState(CardState.NO_GOAL_CURRENT_YEAR);
    }

    setShouldShowConfetti(false);
  }

  async function saveNewGoal() {
    if (yearView === YEAR_VIEW.ALL_TIME) {
      return null;
    }

    if (goalState.goal === null) {
      return null;
    }

    if (donorGoal) {
      await updateDonorGivingGoal(
        removeNullEntries({
          year: yearView,
          amount: goalState.goal,
          donorId: donor.id,
          goalId: donorGoal.id,
          income: goalState.income,
        })
      );
    } else {
      await createDonorGivingGoal(
        removeNullEntries({
          year: yearView,
          amount: goalState.goal,
          donorId: donor.id,
          income: goalState.income,
        })
      );
    }

    setShouldShowConfetti(true);
    setCardState(CardState.DISPLAY_GOAL);
  }

  function renderContent() {
    if (cardState === CardState.EDITING_FIXED_AMOUNT) {
      return (
        <FixedAmountEditState
          goal={goalState.goal}
          isSavingGoal={isSavingGoal}
          setNewGoal={setNewGoal}
          onCancel={cancelEditing}
          onSave={saveNewGoal}
        />
      );
    }

    if (cardState === CardState.EDITING_SUPER_GOAL) {
      return (
        <SuperGoalEditState
          adjustGivingGoal={adjustGivingGoal}
          adjustIncome={adjustIncome}
          adjustIncomeRate={adjustIncomeRate}
          goal={goalState.goal}
          income={goalState.income}
          incomeRate={goalState.incomeRate}
          isSavingGoal={isSavingGoal}
          startEditingSuperGoal={startEditingSuperGoal}
          onCancel={cancelEditing}
          onSave={saveNewGoal}
        />
      );
    }
  }

  const isSavingGoal = isCreateDonorGoalLoading || isUpdateDonorGoalLoading;

  return (
    <>
      <S.Container>
        <S.RadioGroupContainer>
          <S.RadioGroup
            value={getRadioGroupValue()}
            onChange={handleRadioGroupChange}
          >
            <S.FixedAmountButton value={RadioGroupValues.FIXED_AMOUNT}>
              <div data-cy="fixed-amount-btn">Fixed Amount</div>
            </S.FixedAmountButton>
            <S.SuperGoalButton value={RadioGroupValues.SUPER_GOAL}>
              <div data-cy="super-goal-btn">Super Goal</div>
            </S.SuperGoalButton>
          </S.RadioGroup>
        </S.RadioGroupContainer>
        {renderContent()}
      </S.Container>
    </>
  );
}

export default EditState;
