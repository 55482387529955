import React from 'react';
import styled from 'styled-components';

import { FlexCenter, Text } from 'src/client/components';

type Props = {
  count: number;
  className?: string;
};

function CounterBadge(props: Props) {
  const { count, className = '' } = props;

  return (
    <Container className={className}>
      <Text type="h6med2">{count}</Text>
    </Container>
  );
}

const Container = styled(FlexCenter)`
  height: 32px;
  width: 32px;
  flex-shrink: 0;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.05);
`;

export default CounterBadge;
