import React from 'react';

import * as S from './styles';

type Props = {
  recipientLogo: string | undefined;
};

function GalleryCard(props: Props) {
  const { recipientLogo } = props;

  return (
    <S.GalleryCard
      hoverable
      cover={<S.CoverImage imageSrc={recipientLogo} />}
    />
  );
}

export default GalleryCard;
