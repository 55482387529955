import styled from 'styled-components';

import { Flex, FlexCenter } from 'src/client/components';

import EditControlButtons from '../EditControlButtons';

type DescriptionContainerProps = {
  isQuoteTextAreaRendering: boolean;
  isViewingPublicly: boolean;
};

export const Container = styled.section`
  position: relative;
  margin: 16px auto;
  max-width: 1440px;
  height: 100%;
  width: 100%;
  color: black;
  background: white;

  .ant-form-item {
    margin: 0;
  }

  @media ${(props) => props.theme.screen.md} {
    margin-top: 0;
  }
`;

export const DescriptionContainer = styled(Flex)<DescriptionContainerProps>`
  position: relative;
  margin: ${(props) =>
    props.isQuoteTextAreaRendering ? '0 0 50px 0' : '115px 0 110px 8px'};
  justify-content: ${(props) =>
    props.isQuoteTextAreaRendering ? 'flex-start' : 'flex-end'};

  @media ${(props) => props.theme.screen.md} {
    margin-left: 0;
    margin-right: 0;
    margin-top: ${(props) =>
      props.isQuoteTextAreaRendering ? '170px' : '195px'};

    margin-bottom: ${(props) => {
      if (props.isQuoteTextAreaRendering) {
        return '182px';
      } else if (props.isViewingPublicly) {
        return '240px';
      }

      return '180px';
    }};
  }
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  width: 497px;
  margin: 5px 0 0 0;
`;

export const LogoContainer = styled(FlexCenter)`
  margin-bottom: 110px;
  padding: 16px;

  @media ${(props) => props.theme.screen.md} {
    margin-bottom: 140px;
    padding: 30px;
  }
`;

export const Logo = styled.img`
  height: 22px;
  display: block;
  margin: 16px auto;

  @media ${(props) => props.theme.screen.md} {
    height: 30px;
    margin: 30px auto;
  }
`;

export const StyledEditControlButtons = styled(EditControlButtons)`
  display: flex;
  justify-content: flex-end;
  margin: 8px 0 20px 0;
  position: sticky;
  z-index: 99;
  top: 80px;

  @media ${(props) => props.theme.screen.md} {
    top: 111px;
    margin: 40px 46px 20px 0;
  }
`;
