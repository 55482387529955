import { Radio as AntdRadio } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { BODY_1_REG_2 } from '../constants/text';

type Props = React.ComponentProps<typeof AntdRadio>;

function Radio(props: Props) {
  const { children, ...extras } = props;

  return <StyledRadio {...extras}>{children}</StyledRadio>;
}

Radio.Group = AntdRadio.Group;

const StyledRadio = styled(AntdRadio)`
  margin: 0 0 12px 0;

  .ant-radio-inner {
    border-color: ${(props) => props.theme.colors.neutral5};
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${(props) => props.theme.colors.darkEmphasis1};
  }

  .ant-radio-inner::after {
    background: ${(props) => props.theme.colors.darkEmphasis1};
  }

  span {
    ${BODY_1_REG_2};
    color: ${(props) => props.theme.colors.title85};
  }
`;

export default Radio;
