import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  border-radius: 8px;
  padding: 8px;
  border: 2px solid ${(props) => props.theme.colors.darkEmphasis1};

  * {
    display: inline-block;
    font-family: ${(props) => props.theme.fontFamilies.sourceSerif};
    font-size: ${rem('24px')};
    line-height: ${rem('34px')};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};

    @media ${(props) => props.theme.screen.md} {
      font-size: ${rem('40px')};
      line-height: ${rem('56px')};
    }
  }

  && input,
  input:focus-visible {
    padding: 0;
    border: none;
    outline: none;
    color: black;
  }

  input::placeholder {
    color: ${(props) => props.theme.colors.darkEmphasis3};
  }
`;

export const InputWidthReference = styled.span`
  position: absolute;
  visibility: hidden;
  top: 0;
  left: 0;
`;

export const EndText = styled.div`
  color: ${(props) => props.theme.colors.darkEmphasis3};
`;
