import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Flex, Text, Title } from 'src/client/components';
import AdminLayout from 'src/client/layouts/AdminLayout';
import routes from 'src/commons/constants/routes';

import PlatformTable from './components/PlatformsTable';
import * as S from './styles';

function AdminPlatforms() {
  return (
    <>
      <Title title="Platforms - Admin" />
      <AdminLayout>
        <S.Container>
          <Box margin="0 0 24px 0">
            <Flex alignItems="center" gap="24px" margin="0 0 20px 0">
              <Text type="displayMMed2">Platforms</Text>
              <Link to={routes.ADMIN_ADD_PLATFORMS}>
                <S.StyledButton type="primary">
                  <PlusOutlined />
                  Platform
                </S.StyledButton>
              </Link>
            </Flex>
          </Box>
          <PlatformTable />
        </S.Container>
      </AdminLayout>
    </>
  );
}

export default AdminPlatforms;
