export enum FormField {
  MEMBERSHIP_STATUS = 'Membership Status',
}

export enum ChartFields {
  COUNT = 'count',
  GIVING_TYPE = 'giveType',
  MONTH = 'month',
  RECIPIENT = 'recipient',
  TOTAL_AMOUNT = 'totalAmount',
  YEAR = 'year',
}
