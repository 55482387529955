import { LeftOutlined } from '@ant-design/icons';
import { Form, Space } from 'antd';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Text,
  Title,
} from 'src/client/components';
import { useCreateGiveMutation } from 'src/client/hooks/mutations';
import { useGetDonors } from 'src/client/hooks/queries';
import useThemeContext from 'src/client/hooks/useTheme';
import AdminLayout from 'src/client/layouts/AdminLayout';
import routes from 'src/commons/constants/routes';
import { Donor, GIVE_STATUS } from 'src/commons/types';

import * as S from './styles';

const { Option } = Select;
const { TextArea } = Input;

function AdminAddGive(props: RouteComponentProps) {
  const { history } = props;

  const { colors } = useThemeContext();
  const [donorNameQuery, setDonorNameQuery] = useState('');

  const { data: donorsResponse } = useGetDonors({
    searchQuery: donorNameQuery,
  });
  const { mutateAsync: createGive } = useCreateGiveMutation();

  const donors = donorsResponse?.data;

  function handleBack() {
    history.push(routes.ADMIN_GIVES);
  }

  function handleCancel() {
    history.push(routes.ADMIN_GIVES);
  }

  async function handleCreateGive(values: any) {
    const donor = donors?.find((donor) => donor.id == values.donorId) as Donor;
    const donorName = donor.name;

    const newGive = await createGive({
      donorName,
      donorId: values.donorId,
      fromEmail: donor.email,
      fromName: donor.name,
      textBody: values.textBody,
      status: GIVE_STATUS.UNPROCESSED,
      subject: values.subject,
    });

    history.push(routes.ADMIN_EDIT_GIVE.replace(/:giveId/, newGive.id));
  }

  function handleDonorSearch(searchQuery: string) {
    setDonorNameQuery(searchQuery);
  }

  const donorOptions = donors?.map((donor) => (
    <Option key={donor.id} value={donor.id}>
      <Flex>
        <Text color={colors.darkHighEmphasis} type="body1reg2">
          {donor.name}
        </Text>
        &nbsp;
        <Text color={colors.darkMedEmphasis} type="body1reg2">
          ({donor.email})
        </Text>
      </Flex>
    </Option>
  ));

  return (
    <>
      <Title title="Add Give - Admin" />
      <AdminLayout>
        <S.Container>
          <Button type="variant1" onClick={handleBack}>
            <LeftOutlined />
            Back
          </Button>
          <Box margin="22px 0 19px 0">
            <Text type="displayMMed2">Add Give</Text>
          </Box>
          <Form
            layout="vertical"
            wrapperCol={{
              span: 12,
            }}
            onFinish={handleCreateGive}
          >
            <Form.Item
              label="Donor"
              name="donorId"
              rules={[{ required: true, message: 'Donor is required' }]}
            >
              <Select
                showSearch
                filterOption={false}
                placeholder="Search name or email"
                size="large"
                onSearch={handleDonorSearch}
              >
                {donorOptions}
              </Select>
            </Form.Item>
            <Form.Item
              label="Email Subject"
              name="subject"
              rules={[{ required: true, message: 'Email subject is required' }]}
            >
              <Input placeholder="Input email subject here" size="large" />
            </Form.Item>
            <Form.Item
              label="Email Body (Text Format)"
              name="textBody"
              rules={[{ required: true, message: 'Email body is required' }]}
            >
              <TextArea placeholder="Paste email body" size="large" />
            </Form.Item>
            <Space size={22}>
              <Button htmlType="submit" type="primary">
                Create Give
              </Button>
              <Button type="variant1" onClick={handleCancel}>
                Cancel
              </Button>
            </Space>
          </Form>
        </S.Container>
      </AdminLayout>
    </>
  );
}

export default AdminAddGive;
