import { useMutation } from 'react-query';

import {
  sendRecipientRequestAccessEmail,
  sendDonorRequestAccessEmail,
} from 'src/client/api/OutboundEmailApi';

export function useSendRecipientRequestAccessEmailMutation() {
  return useMutation(sendRecipientRequestAccessEmail);
}

export function useSendDonorRequestAccessEmailMutation() {
  return useMutation(sendDonorRequestAccessEmail);
}
