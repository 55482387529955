/* eslint-disable no-alert */
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Form, Popconfirm, Tooltip } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { getRecipients } from 'src/client/api/RecipientApi';
import { Box, MultiFilter, Table, Text } from 'src/client/components';
import LoadingAnimation from 'src/client/components/LoadingAnimation';
import {
  FilterInputs,
  MultiFilterFormValue,
} from 'src/client/components/MultiFilter';
import { useDeleteRecipient } from 'src/client/hooks/mutations';
import { useManageTableQueryString } from 'src/client/hooks/useManageTableQueryString';
import { usePaginate } from 'src/client/hooks/usePaginate';
import { TableOrder } from 'src/client/types/Table';
import { createFilterDefaultValues } from 'src/client/utils/FormUtils';
import { Entity } from 'src/commons/constants/entities';
import {
  FilterType,
  RecipientFilterInput,
} from 'src/commons/constants/filterInputs';
import routes from 'src/commons/constants/routes';
import {
  Indexable,
  Recipient,
  RecipientFilter,
  RECIPIENT_TYPE,
} from 'src/commons/types';
import { formatDate } from 'src/commons/utils/DateUtils';
import { replaceRouteParams } from 'src/commons/utils/RouteUtils';
import { toTitleCase } from 'src/commons/utils/StringUtils';

import * as S from './styles';

type MakeColumnsParams = {
  onDelete: (recipientId: string) => void;
  orderInfo: TableOrder<any>;
};

const filterDefaultValues: MultiFilterFormValue = createFilterDefaultValues(
  RecipientFilterInput,
  RecipientFilterInput.SEARCH_QUERY
);

const recipientTableLimit = 50;

const { useForm } = Form;

function RecipientsTable() {
  const [filterForm] = useForm();
  const history = useHistory();

  const {
    formattedQueryStringForApiFilter,
    formattedQueryStringForFormValues,
    queryString,
    setTableQueryString,
  } = useManageTableQueryString<RecipientFilter>();

  const { mutateAsync: deleteRecipient } = useDeleteRecipient({
    onSuccess: () => {
      window.location.reload();
    },
  });

  const {
    currentPageCount,
    currentPage: recipientsResponse,
    goToFirstPage: goToFirstRecipientPage,
    goToNextPage: goToNextRecipientPage,
    goToPrevPage: goToPrevRecipientPage,
    isFetchingNextPage: isFetchingNextRecipientPage,
    isLoading: isRecipientsLoading,
    isThereMoreToPaginate: isThereMoreRecipientsToPaginate,
    refetch: refetchRecipient,
  } = usePaginate({
    api: getRecipients,
    entity: Entity.RECIPIENT,
    query: {
      limit: recipientTableLimit,
      ...formattedQueryStringForApiFilter,
    },
  });

  useEffect(() => {
    refetchRecipient();
  }, []);

  const recipients = recipientsResponse?.data;

  function handleTableFilterChange(changedValues: any, allValues: any) {
    setTableQueryString(allValues);
  }

  function handleTableChange(
    pagination: any,
    filters: any,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) {
    setTableQueryString({
      ...queryString,
      sorter,
    });
  }

  async function handleDelete(recipientId: string) {
    await deleteRecipient(recipientId);
  }

  function resetFilterAndSort() {
    setTableQueryString({});
    filterForm.setFieldsValue(filterDefaultValues);
    goToFirstRecipientPage();
  }

  function handleRowClick(recipient: Recipient, e: React.MouseEvent) {
    history.push(
      replaceRouteParams(routes.ADMIN_EDIT_RECIPIENT, {
        recipientId: recipient.id,
      })
    );
  }

  const columns = makeColumns({
    onDelete: handleDelete,
    orderInfo: queryString.tableOrder,
  });

  return (
    <S.Container>
      <MultiFilter
        filterInputs={filterInputs}
        form={filterForm}
        initialFilterType={RecipientFilterInput.SEARCH_QUERY}
        initialValues={formattedQueryStringForFormValues}
        onClearFilterAndSort={resetFilterAndSort}
        onValuesChange={handleTableFilterChange}
      />
      <Box margin="32px 0 0 0" />
      <Table
        columns={columns}
        dataSource={recipients}
        loading={{
          spinning: isRecipientsLoading || isFetchingNextRecipientPage,
          indicator: <LoadingAnimation />,
        }}
        paginate={{
          currentPageCount,
          isThereMoreItemsToPaginate: isThereMoreRecipientsToPaginate,
          onJumpToFirstPage: goToFirstRecipientPage,
          onNextPage: goToNextRecipientPage,
          onPrevPage: goToPrevRecipientPage,
          pageSize: recipientTableLimit,
        }}
        onChange={handleTableChange}
        onRow={(record) => ({
          onClick: (e) => handleRowClick(record, e),
        })}
      />
    </S.Container>
  );
}

const filterInputs: FilterInputs = Object.values(RecipientFilterInput).map(
  (recipientFilterInput) => {
    switch (recipientFilterInput) {
      case RecipientFilterInput.SEARCH_QUERY:
        return {
          filterType: FilterType.TEXT,
          label: 'Search',
          placeholder: 'Search recipient',
          key: recipientFilterInput,
        };
      case RecipientFilterInput.RECIPIENT_TYPE:
        return {
          filterType: FilterType.SELECT,
          label: 'Recipient Type',
          selectOptions: Object.values(RECIPIENT_TYPE).map((recipientType) => ({
            label: toTitleCase(recipientType.replace(/_/g, ' ')),
            value: recipientType,
          })),
          placeholder: 'All',
          key: recipientFilterInput,
        };
      case RecipientFilterInput.GIVES:
        return {
          filterType: FilterType.NUMBER_RANGE,
          label: 'Gives',
          key: recipientFilterInput,
        };
      case RecipientFilterInput.DATE_CREATED_RANGE:
        return {
          filterType: FilterType.DATE_RANGE,
          label: 'Date Created',
          key: recipientFilterInput,
        };
    }
  }
);

function makeColumns(params: MakeColumnsParams) {
  const { onDelete, orderInfo } = params;

  return [
    {
      dataIndex: 'name',
      key: 'loweredCaseName',
      title: 'User (Giver)',
      onCell: () => ({
        onClick: (e: React.MouseEvent) => e.stopPropagation(),
      }),
      sorter: (a: any, b: any) => 0,
      sortOrder:
        orderInfo?.columnKey === 'loweredCaseName'
          ? orderInfo.order
          : undefined,
      render: (name: string, record: Recipient) => (
        <Link
          to={replaceRouteParams(routes.ADMIN_RECIPIENT_ROOT, {
            recipientId: record.id,
          })}
        >
          <Text isInline type="link">
            {name}
          </Text>
        </Link>
      ),
    },
    {
      dataIndex: 'recipientTypes',
      key: 'recipientTypes',
      title: 'Recipient Type',
      sorter: (a: any, b: any) => 0,
      sortOrder:
        orderInfo?.columnKey === 'recipientTypes' ? orderInfo.order : undefined,
      render: (recipientTypes: RECIPIENT_TYPE[]) =>
        toTitleCase(recipientTypes[0]?.replace(/_/g, ' ') ?? ''),
    },
    {
      dataIndex: 'giveCount',
      key: 'giveCount',
      title: 'Gives',
      sorter: (a: any, b: any) => 0,
      sortOrder:
        orderInfo?.columnKey === 'giveCount' ? orderInfo.order : undefined,
      render: (giveCount: number | null) => giveCount || 0,
    },
    {
      dataIndex: 'logoImageId',
      key: 'logoImageId',
      title: 'Logo',
      sorter: (a: any, b: any) => 0,
      sortOrder:
        orderInfo?.columnKey === 'logoImageId' ? orderInfo.order : undefined,
      render: (logoImageId: string | null) => (logoImageId ? 'Yes' : 'No'),
    },
    {
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      title: 'Date Created',
      sorter: (a: any, b: any) => 0,
      sortOrder:
        orderInfo?.columnKey === 'dateCreated' ? orderInfo.order : undefined,
      render: (dateCreated: Date) => formatDate(dateCreated),
    },
    {
      key: 'action',
      title: 'Action',
      onCell: () => ({
        onClick: (e: React.MouseEvent) => e.stopPropagation(),
      }),
      render: (text: string, record: Indexable) => {
        function handleDelete() {
          onDelete(record.id);
        }

        return (
          <S.ActionButtonsContainer>
            <Link
              to={routes.ADMIN_RECIPIENTS_EDIT.replace(
                /:recipientId/g,
                record.id
              )}
            >
              <Tooltip placement="bottom" title="Edit">
                <EditOutlined />
              </Tooltip>
            </Link>
            <Popconfirm
              cancelText="No"
              okButtonProps={{
                danger: true,
                type: 'primary',
              }}
              okText="Yes"
              placement="topRight"
              title="Delete this recipient?"
              onConfirm={handleDelete}
            >
              <Tooltip placement="bottom" title="Delete">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </S.ActionButtonsContainer>
        );
      },
    },
  ];
}

export default RecipientsTable;
