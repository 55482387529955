import { format } from 'date-fns';
import dateFormat from 'dateformat';

import { DATE_FORMAT } from 'src/commons/constants/masks';
import { taxDeductibles } from 'src/commons/constants/tax';
import {
  GroupedGiveByRecipient,
  GroupedMonthlyRecurringGives,
  Indexable,
  Give,
  GIVE_FREQUENCY,
  PlatformCount,
  Recipient,
  RECIPIENT_TYPE,
  GIVE_STATUS,
  GroupedGiveByMonth,
  GroupedGiveByYear,
} from 'src/commons/types';
import { sortObjectsByAscending } from 'src/commons/utils/ArrayUtils';
import { getAllMonths, getCurrentYear } from 'src/commons/utils/DateUtils';
import { calculateMedian } from 'src/commons/utils/MathUtils';
import { formatToFinancial } from 'src/commons/utils/MoneyUtilts';

import { getPlatformImage } from '../api/ImageApi';

export function transformToTable(give: Give, recipient?: Recipient) {
  return {
    ...give,
    recipient,
    dateCreated: dateFormat(give.dateCreated, DATE_FORMAT),
    contributed: give.amount || 0,
  };
}

export function getAggregatedData(gives: Give[], giveType: number) {
  const givesByType = gives?.filter((give) => give.unit === giveType);
  const givesCount = givesByType.length;
  const recipientIds: string[] = [];
  let givesTotalAmount: number = 0;
  let recurringGivesCount: number = 0;

  givesByType?.forEach((give) => {
    const recipientId = give.recipientId;

    if (give.isRecurring) {
      recurringGivesCount += 1;
    }

    if (recipientId && !recipientIds.includes(recipientId)) {
      recipientIds.push(recipientId);
    }

    const amount = Number(give.amount);
    givesTotalAmount += amount;
  });

  const recipientsCount = recipientIds.length;
  const average = givesTotalAmount / givesCount;
  const median = calculateMedian(givesByType.map((give) => give.amount ?? 0));
  const raised = 0;
  const match = 0;

  return {
    givesCount,
    givesTotalAmount,
    recipientsCount,
    raised,
    match,
    recurringGivesCount,
    average,
    median,
  };
}

export function groupGivesByYear(gives: Give[]): GroupedGiveByYear[] {
  const groupedGivesByYear: GroupedGiveByYear[] = [];

  gives?.forEach((give) => {
    if (!give.giveDate) {
      return null;
    }

    const year = new Date(give.giveDate).getFullYear();

    const yearGroup = groupedGivesByYear.find(
      (groupedGive) => groupedGive.year === year
    );

    if (yearGroup) {
      yearGroup.totalAmount += give.amount ?? 0;
    } else {
      groupedGivesByYear.push({
        year,
        totalAmount: give.amount ?? 0,
      });
    }
  });

  let carriedOverAmount = 0;

  return sortObjectsByAscending(groupedGivesByYear, 'year').map(
    (yearlyGive) => {
      const totalAmount = yearlyGive.totalAmount + carriedOverAmount;
      const result = {
        ...yearlyGive,
        year: yearlyGive.year,
        totalAmount,
      };

      carriedOverAmount = totalAmount;

      return result;
    }
  );
}

export function groupGivesByMonth(
  gives: Give[],
  year: number
): GroupedGiveByMonth[] {
  const decMonthIndex = 11;
  let lastMonthToGroup;

  if (year === getCurrentYear()) {
    lastMonthToGroup = new Date().getMonth();
  } else {
    lastMonthToGroup = decMonthIndex;
  }

  const monthlyGives = getAllMonths()
    .slice(0, lastMonthToGroup + 1)
    .map((month) => ({
      month,
      totalAmount: 0,
    }));

  gives.forEach((give) => {
    if (!give.giveDate) {
      return null;
    }

    if (new Date(give.giveDate).getFullYear() !== year) {
      return null;
    }

    const giveMonth = format(new Date(give.giveDate), 'MMM');

    const giveGroup = monthlyGives.find(
      (recipientGive: GroupedGiveByMonth) => recipientGive.month === giveMonth
    );

    if (!giveGroup) {
      return null;
    }

    giveGroup.totalAmount += give.amount ?? 0;
  });

  let amountCarriedOver = 0;

  return monthlyGives.map((giveMonth) => {
    const totalAmount = giveMonth.totalAmount + amountCarriedOver;
    const result = {
      ...giveMonth,
      totalAmount,
    };

    amountCarriedOver = totalAmount;

    return result;
  });
}

const recipientTypeOrdered = [
  RECIPIENT_TYPE.NONPROFIT,
  RECIPIENT_TYPE.NONPROFIT_FISCALLY_SPONSORED,
  RECIPIENT_TYPE.POLITICAL_CANDIDATE,
  RECIPIENT_TYPE.POLITICAL_C4,
  RECIPIENT_TYPE.POLITICAL_PAC,
  RECIPIENT_TYPE.CROWDFUNDING,
  RECIPIENT_TYPE.INDIVIDUAL,
  RECIPIENT_TYPE.FOR_PROFIT,
  RECIPIENT_TYPE.FOR_PROFIT_B_CORP,
  RECIPIENT_TYPE.LABOR_UNION,
  RECIPIENT_TYPE.OTHER,
];

export function getGiveTypesStats(recipients: Recipient[], gives: Give[]) {
  const typesOfGivingData = Object.values(recipientTypeOrdered).map(
    (recipientType) => ({
      count: 0,
      giveType: recipientType,
    })
  );

  gives.forEach((give) => {
    const recipient = recipients.find(
      (recipient) => recipient.id === give.recipientId
    );

    if (!recipient) {
      return null;
    }

    const giveTypeIndex = typesOfGivingData.findIndex((typeOfGivingData) =>
      recipient.recipientTypes?.includes(typeOfGivingData.giveType)
    );

    if (giveTypeIndex !== -1) {
      typesOfGivingData[giveTypeIndex].count += 1;
    }
  });

  return typesOfGivingData.map((data) => ({
    ...data,
    giveTypeDisplay: getGiveTypeDisplay(data.giveType),
  }));
}

function getGiveTypeDisplay(giveType: RECIPIENT_TYPE) {
  switch (giveType) {
    case RECIPIENT_TYPE.CROWDFUNDING:
      return 'Crowdfunding Campaigns';
    case RECIPIENT_TYPE.FOR_PROFIT:
      return 'For Profits';
    case RECIPIENT_TYPE.FOR_PROFIT_B_CORP:
      return 'B Corps';
    case RECIPIENT_TYPE.INDIVIDUAL:
      return 'Individuals';
    case RECIPIENT_TYPE.LABOR_UNION:
      return 'Labor Unions';
    case RECIPIENT_TYPE.NONPROFIT:
      return 'Non-Profits';
    case RECIPIENT_TYPE.NONPROFIT_FISCALLY_SPONSORED:
      return 'Fiscally Sponsored';
    case RECIPIENT_TYPE.OTHER:
      return 'Others';
    case RECIPIENT_TYPE.POLITICAL_C4:
      return 'Political C4s';
    case RECIPIENT_TYPE.POLITICAL_CANDIDATE:
      return 'Political Campaigns';
    case RECIPIENT_TYPE.POLITICAL_PAC:
      return 'Political PACs';
  }
}

export function groupGivesByRecipient(gives: Give[]): GroupedGiveByRecipient[] {
  const indexedGroupedGivesByRecipient: Indexable<GroupedGiveByRecipient> = {};

  gives?.forEach((give) => {
    const recipientId = give.recipientId;
    const cover = give.recipientCover;

    if (!recipientId) {
      return null;
    }

    const recipientGroup = indexedGroupedGivesByRecipient[recipientId];

    if (recipientGroup) {
      (recipientGroup.giveCount as number)++;
      recipientGroup.totalAmountOfGives += Number(give.amount);
    } else {
      indexedGroupedGivesByRecipient[recipientId] = {
        recipientId,
        recipientLogo: cover || null,
        giveCount: 1,
        totalAmountOfGives: give.amount as number,
        latestGiveDate: give.giveDate,
        recipientName: give.recipientName as string,
      };
    }
  });

  return Object.values(indexedGroupedGivesByRecipient).map(
    (groupedGive) => groupedGive
  );
}

//Add tests
export function groupGivesByPlatform(gives: Give[] | undefined) {
  const topPlatforms: PlatformCount[] = [];
  const platformIds: string[] = [];

  gives?.forEach((give) => {
    const platformId = give?.platformId;
    const cover = give.platformCover as string | undefined;

    if (platformId && platformIds.includes(platformId)) {
      const existingPlatformDataIndex = topPlatforms.findIndex(
        (existingPlatform) => existingPlatform.platformId === platformId
      );

      topPlatforms[existingPlatformDataIndex].count += 1;
    } else if (platformId) {
      topPlatforms.push({
        cover,
        platformId,
        count: 1,
      });

      platformIds.push(platformId);
    }
  });

  return topPlatforms;
}

export async function hydratePlatformCountsWithPlatformImage(
  platformCounts: PlatformCount[]
) {
  return Promise.all(
    platformCounts.map(async (give) => {
      const platformId = give?.platformId;
      const { platformImagePath } = await getPlatformImage(platformId!);

      return {
        ...give,
        platformImagePath: platformImagePath || undefined,
      };
    })
  );
}

export function groupGivesByRecurring(gives: Give[]) {
  const groupedGivesByRecurringMap: Record<
    string,
    GroupedMonthlyRecurringGives
  > = {};

  gives.forEach((give) => {
    const recurringGiveGroupAlreadyExists =
      groupedGivesByRecurringMap[`${give.recipientId}-${give.frequency}`];

    if (
      give.isRecurring &&
      !recurringGiveGroupAlreadyExists &&
      give.recipientId &&
      give.status === GIVE_STATUS.PROCESSED &&
      Object.values(GIVE_FREQUENCY).includes(give.frequency as GIVE_FREQUENCY)
    ) {
      groupedGivesByRecurringMap[`${give.recipientId}-${give.frequency}`] = {
        recipientId: give.recipientId,
        monthlyGiveAmount: give.amount ?? 0,
        recipientName: give.recipientName as string,
        frequency: give.frequency as GIVE_FREQUENCY,
      };
    }
  });

  return Object.values(groupedGivesByRecurringMap).map(
    (groupedGiveByRecurring) => groupedGiveByRecurring
  );
}

export function formatMoneyInGiveToFinancial(give: Give): Give {
  return {
    ...give,
    amount: give.amount && formatToFinancial(give.amount),
    splitAmount: give.splitAmount && formatToFinancial(give.splitAmount),
    matchedDonationAmount:
      give.matchedDonationAmount &&
      formatToFinancial(give.matchedDonationAmount),
  };
}

export function getTaxDeductibleStats(gives: Give[] = []) {
  return gives?.reduce(
    (acc, give) => {
      if (!give.taxDeductible || !give.amount) {
        return acc;
      }

      const isTaxDeductible: boolean = taxDeductibles.includes(
        give.taxDeductible
      );

      if (isTaxDeductible) {
        acc.taxDeductibleCount += 1;
        acc.taxDeductibleAmount += give.amount;
      }

      acc.givesCount += 1;

      return acc;
    },
    {
      taxDeductibleAmount: 0,
      taxDeductibleCount: 0,
      givesCount: 0,
    }
  );
}
