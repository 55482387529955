import React, { useEffect, useState } from 'react';

import { isActualNumberOrNumericString } from 'src/commons/constants/dataTypes';
import {
  removeCommas,
  removeNonNumericCharacters,
} from 'src/commons/utils/StringUtils';

import { Override } from 'src/commons/utils/TypescriptUtils';

import Input from './Input';

type Props = Override<
  React.ComponentProps<typeof Input>,
  {
    onChange: (value: number) => void;
    value: string | number;
    prefixValue?: string;
  }
>;

function NumberInput(props: Props) {
  const { value: externalValue, onChange, prefixValue = '', ...extras } = props;

  const [internalValue, setInternalValue] = useState<string>(
    `${externalValue}`
  );

  useEffect(() => {
    const internalValueLastCharacter = internalValue[internalValue.length - 1];
    const shouldNotSyncInternalValue =
      internalValueLastCharacter === '.' &&
      parseFloat(removePrefixValue(removeCommas(internalValue))) ===
        externalValue;

    if (!shouldNotSyncInternalValue) {
      setInternalValue(`${externalValue}`);
    }
  });

  function removePrefixValue(value: string) {
    if (prefixValue) {
      return value.replace(prefixValue, '');
    } else {
      return value;
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const formattedValue =
      removePrefixValue(
        removeCommas(removeNonNumericCharacters(e.target.value))
      ) || `0`;

    setInternalValue(e.target.value);
    onChange(parseFloat(formattedValue));
  }

  function formatStringInputToNumberWithComma(value: string): string {
    value = removePrefixValue(removeCommas(value));

    if (!isActualNumberOrNumericString(value)) {
      return value;
    }

    if (!value) {
      return '';
    }

    if (value.startsWith('.')) {
      value = `0${value}`;
    }

    // Add comma separator only to integer to handle values like '5665.'
    const splitValue = value.split('.');
    let integerPart = splitValue[0];
    const decimalPart = splitValue[1];

    integerPart = parseInt(integerPart).toLocaleString();

    // Recombine
    if (decimalPart !== undefined) {
      return `${prefixValue}${integerPart}.${decimalPart}`;
    } else {
      return `${prefixValue}${integerPart}`;
    }
  }

  return (
    <Input
      {...extras}
      value={formatStringInputToNumberWithComma(internalValue)}
      onChange={handleChange}
    />
  );
}

export default NumberInput;
