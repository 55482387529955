import styled from 'styled-components';

type PlatformLogoProps = {
  logoSrc?: string;
};

export const Container = styled.div`
  display: flex;
  margin-bottom: 16px;
  padding: 16px 32px;
  height: 127px;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.blueGray};
  border-radius: 8px;
`;

export const PlatformLogo = styled.div<PlatformLogoProps>`
  height: 95px;
  width: 186px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${(props) => props.logoSrc});
  background-color: transparent;
`;
