import styled from 'styled-components';

import { Text } from 'src/client/components';

export const Container = styled.div`
  padding: 8px 10px;
  width: fit-content;
  border: 1px solid ${(props) => props.theme.colors.darkSurface3};
  border-radius: 8px;
  z-index: 99;
  background: white;

  button {
    padding: 8px 16px;
  }
`;

export const Body = styled.div`
  display: flex;
  align-items: center;

  & > span {
    margin-right: 6.5px;
    svg {
      width: 24px;
      height: 24px;
    }
  }

  & > div {
    width: fit-content;
    margin-right: 10px;

    @media ${(props) => props.theme.device.mobileL} {
      width: 239px;
    }
  }
`;

export const DescriptionText = styled(Text)`
  color: ${(props) => props.theme.colors.darkEmphasis2};
`;
