import { Modal } from 'antd';
import styled from 'styled-components';

import { BODY_1_MED_2 } from 'src/client/constants/text';

export const Container = styled.div`
  margin-top: 40px;
  background-color: ${(props) => props.theme.colors.darkSurface2};
  border-radius: 8px;
  padding: 32px 35px;

  .ant-pagination-simple-pager {
    display: none;
  }

  .table {
    margin-top: 33px;
    border-radius: 8px;
    width: 100%;
    overflow: auto;

    .ant-table-thead > tr > th {
      border-bottom: 1px solid ${(props) => props.theme.colors.darkSecondary};
    }

    th .ant-table-cell {
      ${BODY_1_MED_2}
    }
  }

  @media ${(props) => props.theme.screen.md} {
    padding: 46px 32px;
  }
`;

export const StyledEditGiveModal = styled(Modal)`
  .ant-modal-content {
    display: flex;
    height: 80vh;
    flex-direction: column;
    border-radius: 20px;
    overflow: hidden;
  }

  .ant-modal-body {
    height: 100%;
    overflow: auto;
  }
`;
