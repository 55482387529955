import { Layout } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React from 'react';
import { useHistory } from 'react-router';

import { useMatchMenuItem } from 'src/client/hooks/useMatchMenuItem';

import * as S from './style';

type Props = React.ComponentProps<typeof Layout.Sider> & {
  adminRouteData: MenuSiderConfig[];
  mobileSiderConfig?: MenuSiderConfig[];
  mobileSiderTitle?: string;
  sider?: React.ReactNode;
};

export type MenuSiderConfig = {
  keys: string[];
  route: string;
  name: string;
  icon?: JSX.Element;
  shouldOpenInNewTab?: boolean;
};

function AdminSider(props: Props) {
  const { adminRouteData, sider, mobileSiderConfig, mobileSiderTitle } = props;

  const history = useHistory();
  const screens = useBreakpoint();
  const { getMatchedMenuItem } = useMatchMenuItem();

  function handleMenuItemClick(evt: any) {
    history.push(evt.key);
  }

  const matchedMenuItemInAdminDonor = getMatchedMenuItem(mobileSiderConfig);
  const matchedMenuItemInAdmin = getMatchedMenuItem(adminRouteData);

  const selectedKeysInAdminDonor = matchedMenuItemInAdminDonor
    ? [matchedMenuItemInAdminDonor]
    : undefined;
  const selectedKeysInAdmin = matchedMenuItemInAdmin
    ? [matchedMenuItemInAdmin]
    : undefined;

  const adminMobileMenuItems =
    adminRouteData &&
    adminRouteData.map((admin) => (
      <S.StyledMenuItem
        icon={admin.icon}
        key={admin.route}
        onClick={handleMenuItemClick}
      >
        <S.StyleMenuTextItem type="buttonMedium">
          {admin.name}
        </S.StyleMenuTextItem>
      </S.StyledMenuItem>
    ));

  const mobileMenuItems =
    mobileSiderConfig &&
    mobileSiderConfig?.map((childRoute) => (
      <S.StyledMenuItem
        icon={childRoute.icon}
        key={childRoute.route}
        onClick={handleMenuItemClick}
      >
        <S.StyleMenuTextItem type="buttonMedium">
          {childRoute.name}
        </S.StyleMenuTextItem>
      </S.StyledMenuItem>
    ));

  const mobileMenu = (
    <S.MenuStyle selectedKeys={selectedKeysInAdminDonor}>
      {mobileMenuItems}
    </S.MenuStyle>
  );

  const mobileTitle = mobileSiderTitle && (
    <S.StyledText type="caption3">{mobileSiderTitle}</S.StyledText>
  );

  const isDesktop = screens.lg;

  if (isDesktop) {
    return <>{sider}</>;
  }

  return (
    <S.StyledSider
      collapsible
      collapsedWidth={screens.lg ? '50' : '0'}
      theme={'light' as any}
      trigger={null}
      width="250px"
      {...props}
    >
      <S.StyledText type="caption3">Admin</S.StyledText>
      <S.MenuStyle selectedKeys={selectedKeysInAdmin}>
        {adminMobileMenuItems}
      </S.MenuStyle>

      {mobileTitle}
      {mobileMenu}
    </S.StyledSider>
  );
}

export default AdminSider;
