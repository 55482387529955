import { Form, Select } from 'antd';
import styled from 'styled-components';

import { Flex } from 'src/client/components';
import Button from 'src/client/components/Button';

type ProfilePhotoProps = {
  profilePhotoSrc?: string;
};

export const StyledForm = styled(Form)`
  margin: 14.5px 0 0 0;

  .ant-form-item {
    margin: 0;
  }
`;

export const Container = styled.div`
  padding: 17px 20px 0;
`;

export const ProfilePhoto = styled.div<ProfilePhotoProps>`
  height: 160px;
  width: 160px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.gray10};
  background-image: url(${(props) => props.profilePhotoSrc});
  background-size: cover;
  background-position: center;
`;

export const StyledButton = styled(Button)`
  margin-top: 20px;
  padding: 14.37px 20px;

  @media (min-width: ${(props) => props.theme.size.mobileL}) {
    margin-left: 20px;
  }
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    padding: 14px 22px !important;
    height: 50px !important;
    border-radius: 8px !important;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
`;

export const AddEmailButton = styled(StyledButton)`
  margin: 0 0 0 10px;
  padding: 14px 22px;
  height: 52px;
`;

export const Bottom = styled.div`
  display: flex;
  margin: 84.75px 0;
  justify-content: space-between;
  align-items: center;
`;

export const FlexForProfilePhoto = styled(Flex)`
  @media (max-width: ${(props) => props.theme.size.mobileL}) {
    margin-top: 0px;
  }

  @media (min-width: ${(props) => props.theme.size.tablet}) {
    margin-left: 20px;
    margin-top: 40px;
  }

  @media (max-width: ${(props) => props.theme.size.tablet}) {
    margin-top: 40px;
  }
`;
