import styled from 'styled-components';

import { StatisticCard } from 'src/client/components';
import { DISPLAY_L_MED_2, H4_MED_2 } from 'src/client/constants/text';

export const Content = styled.div`
  display: flex;
  margin: auto;
  padding: 0px 15px 50px;
  max-width: 1440px;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;

  @media (${(props) => props.theme.screen.sm}) {
    padding: 0px 60px 50px;
  }
`;

export const PlatformCard = styled(StatisticCard)`
  height: 141px;
  width: 100%;
  max-width: none;
  padding: 24px;
  overflow: hidden;

  .statistic-value {
    ${DISPLAY_L_MED_2}
  }

  .statistic-title {
    ${H4_MED_2}
    color: ${(props) => props.theme.colors.darkEmphasis2}
  }

  @media (${(props) => props.theme.screen.md}) {
    height: 181px;
  }
`;
