import { useQuery } from 'react-query';

import { getCityStateByZipCode } from 'src/client/api/LocationApi';

const GET_CITY_STATE_BY_ZIP_CODE = 'GET_CITY_STATE_BY_ZIP_CODE';

export function useGetCityStateByZipCode(zipCode: number) {
  return useQuery([GET_CITY_STATE_BY_ZIP_CODE, zipCode], () =>
    getCityStateByZipCode(zipCode)
  );
}
