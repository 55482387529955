import { MenuOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Flex } from 'src/client/components';
import { BODY_1_REG_2 } from 'src/client/constants/text';
import theme from 'src/client/themes/default';

type HeaderProps = {
  isPositionFixed: boolean;
};

export const Logo = styled.img`
  display: none;
  height: 22px;
  cursor: pointer;

  @media ${(props) => props.theme.device.mobileL} {
    display: block;
  }
`;

export const Header = styled(Layout.Header) <HeaderProps>`
  display: flex;
  position: ${(props) => (props.isPositionFixed ? 'fixed' : undefined)};
  padding: 0px 16px;
  height: auto;
  width: 100%;
  line-height: 50px;
  justify-content: space-between;
  align-items: center;
  z-index: 99;

  && {
    background: ${(props) => props.theme.colors.darkSurface1};
  }
`;

export const MainContainer = styled(Layout)`
  background: ${(props) => props.theme.colors.white};
`;

export const StyledMenuItem = styled(Menu.Item)`
  height: 100%;

  .ant-menu-item-selected {
    background-color: white !important;
  }
`;

export const MenuContainer = styled(Flex)`
  flex-grow: 1;
  justify-content: flex-end;
`;

export const StyledMenu = styled(Menu).attrs((props: any) => ({
  theme: props._theme,
})) <React.ComponentProps<typeof Menu> & { _theme: string }>`
  margin-right: 57px;
  width: 670px;

  .ant-menu-item-selected,
  .ant-menu-item-active {
    background: ${theme.colors.darkPrimary100} !important;

    a {
      color: ${theme.colors.darkEmphasis1} !important;
    }
  }
`;

export const StyledLayout = styled(Layout)`
  min-height: 100vh;

  .ant-layout-content {
    background: white;
  }
`;

export const StyledContent = styled(Layout.Content)`
  height: max-content;
  min-height: initial;
`;

export const StyledMenuOutlined = styled(MenuOutlined)`
  margin-right: 20px;
  width: 19px;
  color: white;
`;

export const StyledSider = styled(Layout.Sider)`
  position: fixed;
  background-color: ${theme.colors.royalBlue};
  z-index: 99;
`;

export const MenuText = styled(Link)`
  &&& {
    ${BODY_1_REG_2};
    color: white;
  }
`;

export const YearFilterContainer = styled.div`
  flex: 1;

  .ant-select {
    width: 100%;
  }
`;
