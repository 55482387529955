import {
  DndContext,
  DragEndEvent,
  SensorDescriptor,
  SensorOptions,
} from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableContext } from '@dnd-kit/sortable';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { forwardRef, useMemo } from 'react';

import { FlexCenter, LoadingCard } from 'src/client/components';
import { PageRecipient } from 'src/commons/types';

import GiveCardV2 from '../../../DonorGalleryV2/components/CardsSection/components/GiveCard';
import GiveCardBase from '../GiveCard';

import * as stylesheet from './styles';

type Props = {
  featuredPageRecipients: PageRecipient[] | undefined;
  isLoading: boolean;
  onHidePageRecipient: (pageRecipientId: string) => void;
  onUnfeaturePageRecipient: (pageRecipientId: string) => void;
  handleDragEnd: (event: DragEndEvent) => void;
  handleDragStart: (event: DragEndEvent) => void;
  sensors: SensorDescriptor<SensorOptions>[];
  styles?: any;
};

const FeaturedCardsEditMode = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const {
      featuredPageRecipients,
      isLoading,
      onHidePageRecipient,
      onUnfeaturePageRecipient,
      sensors,
      styles,
      handleDragEnd,
      handleDragStart,
    } = props;

    const S = styles ?? stylesheet;
    const GiveCard = styles ? GiveCardV2 : GiveCardBase;

    const screens = useBreakpoint();

    const featuredPageRecipientIds = useMemo(
      () => featuredPageRecipients?.map((pageRecipient) => pageRecipient.id),
      [featuredPageRecipients]
    );

    const featuredCards = useMemo(
      () =>
        featuredPageRecipients?.map((pageRecipient) => (
          <GiveCard
            isEditMode
            data-page-recipient-visibility="featured-card"
            editModeConfig={{
              primaryButtonText: 'Unfeature',
              primaryButtonProps: {
                onClick: () => onUnfeaturePageRecipient(pageRecipient.id),
              },
              secondaryButtonProps: {
                onClick: () => onHidePageRecipient(pageRecipient.id),
              },
            }}
            key={pageRecipient.id}
            pageRecipient={pageRecipient}
          />
        )),
      [
        featuredPageRecipients,
        GiveCard,
        onUnfeaturePageRecipient,
        onHidePageRecipient,
      ]
    );

    const instructionText = useMemo(
      () => (
        <div>
          To feature a recipient, {screens.lg ? 'hover' : 'tap'} over them and
          click <span>Feature</span>
        </div>
      ),
      [screens.lg]
    );

    const spotlightText = useMemo(
      () => (
        <S.SpotlightText type="body1reg2">
          To feature a recipient, {screens.lg ? 'hover' : 'tap'} over them and
          click Feature. Drag and drop to reorder.
        </S.SpotlightText>
      ),
      [S, screens.lg]
    );

    return (
      <DndContext
        autoScroll={{
          acceleration: 500,
        }}
        modifiers={[restrictToParentElement]}
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        <S.StyledDragAndDropArea
          data-cy="featured-cards-drag-and-drop-area"
          emptyStateInstructionText={instructionText}
          instructionTabText="Drag and drop recipients to rearrange their order"
          isEmpty={!featuredPageRecipients?.length}
          ref={ref}
        >
          <FlexCenter>{spotlightText}</FlexCenter>
          <LoadingCard
            isLoading={isLoading}
            spinProps={{
              'data-cy': 'featured-cards-spinner',
            }}
          >
            <SortableContext items={featuredPageRecipientIds as string[]}>
              <S.FeaturedCardsContainer>
                {featuredCards}
              </S.FeaturedCardsContainer>
            </SortableContext>
          </LoadingCard>
        </S.StyledDragAndDropArea>
      </DndContext>
    );
  }
);

export default FeaturedCardsEditMode;
