import React from 'react';

import { ReactComponent as Shape1 } from 'src/client/assets/images/galleryShapes/shape1.svg';
import { ReactComponent as Shape2 } from 'src/client/assets/images/galleryShapes/shape2.svg';
import { ReactComponent as Shape3 } from 'src/client/assets/images/galleryShapes/shape3.svg';
import { ReactComponent as Shape4 } from 'src/client/assets/images/galleryShapes/shape4.svg';
import { ReactComponent as Shape5 } from 'src/client/assets/images/galleryShapes/shape5.svg';
import { ReactComponent as Shape6 } from 'src/client/assets/images/galleryShapes/shape6.svg';
import { ReactComponent as Shape7 } from 'src/client/assets/images/galleryShapes/shape7.svg';
import {
  GALLERY_COLOR_VALUES,
  GalleryColor,
  GalleryShape,
} from 'src/commons/constants/gallery';

import * as stylesheet from './styles';

const shapeComponents = {
  [GalleryShape.SHAPE_1]: Shape1,
  [GalleryShape.SHAPE_2]: Shape2,
  [GalleryShape.SHAPE_3]: Shape3,
  [GalleryShape.SHAPE_4]: Shape4,
  [GalleryShape.SHAPE_5]: Shape5,
  [GalleryShape.SHAPE_6]: Shape6,
  [GalleryShape.SHAPE_7]: Shape7,
};

type Props = {
  shape: {
    name: GalleryShape;
    color: GalleryColor;
  };
  className?: string;
  colorValues?: typeof GALLERY_COLOR_VALUES;
  styles?: any;
};

function Shape(props: Props) {
  const { className, colorValues, shape, styles } = props;

  const S = styles ?? stylesheet;
  const colorEnum = colorValues ?? GALLERY_COLOR_VALUES;

  const ShapeSvg = shapeComponents[shape.name as GalleryShape];

  if (!ShapeSvg) {
    return null;
  }

  return (
    <S.Container
      className={className}
      color={colorEnum[shape.color]}
      data-color={shape.color}
      data-cy="gallery-shape"
      data-shape={shape.name}
    >
      <ShapeSvg />
    </S.Container>
  );
}

export default Shape;
