import { Form, Modal } from 'antd';

import styled from 'styled-components';

import { Button } from 'src/client/components';
import Select from 'src/client/components/Select';
import Text from 'src/client/components/Text';
import { BODY_1_REG_2, BUTTON_1_2 } from 'src/client/constants/text';

import Input from '../Input';

export const Container = styled.div`
  .ant-form-item {
    margin: 0;
  }
`;

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 8px;
`;

export const ChangeButton = styled(Button)`
  &&& {
    margin-left: 16px;
    padding: 0;
    color: ${(props) => props.theme.colors.teal2};

    :hover {
      background: white;
    }
  }
`;

export const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .ant-modal-content {
    max-width: 572px;
    border-radius: 8px !important;
    overflow: hidden;
  }

  .cancel-button {
    border: none;
    color: ${(props) => props.theme.colors.darkEmphasis1};
    box-shadow: none;

    span {
      ${BUTTON_1_2};
    }
  }

  .ok-button {
    padding: 13px 16px;
    height: fit-content;
    background: ${(props) => props.theme.colors.teal1};
    color: black;
    border-radius: 8px;
    border: none;

    span {
      ${BUTTON_1_2}
    }
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  max-width: 400px;
`;

export const PrimaryEmailInput = styled(Input)`
  && {
    padding: 12px 15px;
    color: ${(props) => props.theme.colors.darkEmphasis1};
    background: ${(props) => props.theme.colors.gray4};
    border-radius: 8px;
    border: 0;
    cursor: default;
    ${BODY_1_REG_2};
  }
`;

export const CheckAlternateEmailText = styled(Text)`
  cursor: pointer;
`;

export const ConfirmPasswordInput = styled(Input.Password)`
  width: 100%;
  max-width: 400px;
`;
