import styled, { createGlobalStyle } from 'styled-components';

import Select from 'src/client/components/v2/Select';
import { BUTTON_1_2 } from 'src/client/constants/text';

export const YearFilterGlobalStyle = createGlobalStyle`
	.ant-select-dropdown-dark {
    .ant-select-item {
      color: white;
    }

    .ant-select-item-option-selected.ant-select-item-option-selected {
      color: ${(props) => props.theme.colors.teal1};
      background: unset;
    }

    .ant-select-item-option-active.ant-select-item-option-active {
      background: ${(props) => props.theme.colors.darkSurface2};
    }

    .ant-select-item-option-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      ${BUTTON_1_2};
    }
  }
`;

export const StyledSelect = styled(Select)`
  position: relative;
`;
