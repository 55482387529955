import styled from 'styled-components';

export const SectionStyle = styled.div`
  background-color: transparent;
  border-radius: 0px;
  flex-wrap: wrap;

  @media ${(props) => props.theme.device.laptop} {
    margin-bottom: 60px;
    padding: ${(props) => props.style?.padding};
    flex-wrap: nowrap;
    background-color: ${(props) =>
      props.style?.backgroundColor || 'transparent'};
    border-radius: 20px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  padding-bottom: 30px;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  @media ${(props) => props.theme.device.desktop} {
    flex-wrap: nowrap;
    flex-direction: row;
  }
`;
