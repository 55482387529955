import React from 'react';

import Button from 'src/client/components/Button';
import Text from 'src/client/components/Text';

import { StyledFeedback } from './styles';

type Props = {
  className?: string;
  onClick?: () => void;
};

const buttonStyle = {
  minWidth: '172px',
};

export default function Feedback(props: Props) {
  const { className = '', onClick } = props;

  function handleClick() {
    if (onClick) {
      onClick();
    }
  }

  return (
    <StyledFeedback className={className}>
      <Text className="feedback-message" color="white" type="body1reg2">
        Do you have questions or feedback? We’re building and learning and we
        would love to hear from you!
      </Text>
      <Button
        className="feedback-button"
        style={buttonStyle}
        type="primary"
        onClick={handleClick}
      >
        Send us a Note
      </Button>
    </StyledFeedback>
  );
}
