import styled from 'styled-components';

import { Button } from 'src/client/components';
import { BUTTON_1_2 } from 'src/client/constants/text';

export const ChangePasswordButton = styled(Button)`
  && {
    padding: 0;
    color: ${(props) => props.theme.colors.teal2};
    ${BUTTON_1_2};
  }
`;
