import { Col, Row } from 'antd';
import React, { useState } from 'react';

import { Box, Text } from 'src/client/components';
import { useSendRecipientRequestAccessEmailMutation } from 'src/client/hooks/mutations';
import useThemeContext from 'src/client/hooks/useTheme';
import BasicLayout from 'src/client/layouts/BasicLayout';

import ActionSuccessPage from '../ActionSuccessPage';

import FormCard from './components/FormCard';

function RecipientSignIn() {
  const { colors } = useThemeContext();

  const [isRequestAccessSent, setIsRequestAccessSent] = useState(false);
  const {
    mutateAsync: sendRequestAccessEmail,
    isLoading: isSendRequestAccessEmailLoading,
  } = useSendRecipientRequestAccessEmailMutation();

  async function handleRequestAccessSend(formValues: any) {
    if (!isSendRequestAccessEmailLoading) {
      await sendRequestAccessEmail({
        email: formValues.email,
        firstName: formValues.firstName,
        hearAboutUs: formValues.hearAboutUs,
        lastName: formValues.lastName,
        organization: formValues.organization,
      });

      setIsRequestAccessSent(true);
    }
  }

  const content = isRequestAccessSent ? (
    <ActionSuccessPage headerText="Your request has been received.">
      Thank you! Someone from Giving Side will be in touch with you soon.
    </ActionSuccessPage>
  ) : (
    <>
      <Box margin="88px 0 24px 0">
        <Text color={colors.teal1} type="h5bold2">
          OUR PEOPLE
        </Text>
      </Box>
      <Text color={colors.white} type="displaySBold2">
        Know, love & nourish your supporters’ political journeys
      </Text>
      <FormCard onRequestAccessSend={handleRequestAccessSend} />
      <Box margin="0 0 92px 0" />
    </>
  );

  return (
    <BasicLayout isOrnamentVisible>
      <Row justify="center">
        <Col lg={15} sm={15} xl={8} xs={22}>
          {content}
        </Col>
      </Row>
    </BasicLayout>
  );
}

export default RecipientSignIn;
