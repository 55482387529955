import styled from 'styled-components';

import { FlexCenter, Text } from 'src/client/components';

import DragAndDropArea from '../DragAndDropArea';

export const StyledDragAndDropArea = styled(DragAndDropArea)`
  .drag-and-drop-area {
    display: block;
    padding-top: 36px;
  }
`;

export const HiddenCardsContainer = styled(FlexCenter)`
  flex-wrap: wrap;
  gap: 16px;
`;

export const SpotlightText = styled(Text)`
  text-align: center;
  margin-bottom: 24px;
`;
