import { Radio } from 'antd';
import { rem } from 'polished';
import styled from 'styled-components';

import { BUTTON_1_2 } from 'src/client/constants/text';

import Text from '../Text';

export const RadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper-checked {
    background-color: ${(props) => props.theme.colors.teal1} !important;
    border-color: ${(props) => props.theme.colors.teal1} !important;
  }
`;

export const RadioButton = styled(Radio.Button)`
  overflow: hidden;

  @media ${(props) => props.theme.device.mobileL} {
    display: inline-block;
  }
`;

const StyledRadioButton = styled(RadioButton)`
  padding: 4px 8px;
  height: auto;
  text-transform: capitalize;
  white-space: nowrap;

  && {
    color: ${(props) => props.theme.colors.gray10};
  }

  .ant-radio-button-checked {
    background-color: ${(props) => props.theme.colors.teal1};
  }

  @media (${(props) => props.theme.screen.md}) {
    padding: 8px 16px;
  }
`;

export const RadioButtonRight = styled(StyledRadioButton)`
  &&& {
    border-radius: 0 6px 6px 0;
    outline: none;
  }
`;

export const RadioButtonLeft = styled(StyledRadioButton)`
  &&& {
    border-radius: 6px 0 0 6px !important;
    outline: none;
  }
`;

export const RadioButtonText = styled(Text)`
  ${BUTTON_1_2}
  font-size: ${rem('12px')};
  line-height: ${rem('16px')};

  @media (${(props) => props.theme.screen.md}) {
    font-size: ${rem('16px')};
    line-height: ${rem('19px')};
  }
`;
