import { rem } from 'polished';
import styled from 'styled-components';

import { Flex, Text } from 'src/client/components';

export const FirstTitle = styled(Text)`
  font-size: ${rem('32px')};
  line-height: ${rem('42px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};

  @media ${(props) => props.theme.screen.lg} {
    width: 742px;
    font-size: ${rem('40px')};
    line-height: ${rem('52px')};
  }
`;

export const SecondTitle = styled(Text)`
  font-size: ${rem('32px')};
  line-height: ${rem('42px')};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-family: ${(props) => props.theme.fontFamilies.leagueSpartan};

  margin: 16px 0 32px;

  @media ${(props) => props.theme.screen.md} {
    width: 384px;
    margin: 0;
    font-size: ${rem('40px')};
    line-height: ${rem('52px')};
  }
`;

export const RecipientCardsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 32px;
  flex-wrap: wrap;
`;

export const TopRecipientCardsContainer = styled(Flex)`
  flex-direction: column;
  gap: 32px;

  @media (${(props) => props.theme.screen.md}) {
    flex-direction: row;
    gap: 16px;
  }
`;

export const BottomRecipientCardsContainer = styled(Flex)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  margin-top: 24px;

  @media (${(props) => props.theme.screen.md}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 18px;
  }

  @media (${(props) => props.theme.device.laptop}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (${(props) => props.theme.device.laptopL}) {
    display: flex;
  }
`;

export const CompactRecipientCardsContainer = styled(RecipientCardsContainer)`
  gap: 16px;
`;

const Section = styled.section`
  padding: 40px 24px;
  width: 100%;

  @media (${(props) => props.theme.screen.md}) {
    padding: 100px 24px;
  }

  @media (${(props) => props.theme.screen.lg}) {
    padding: 100px 67px;
  }
`;

export const FirstSection = styled(Section)`
  background: ${(props) => props.theme.colors.midnight7};
`;

export const SecondSection = styled(Section)`
  background: ${(props) => props.theme.colors.white};
`;

const SectionContainer = styled(Flex)`
  margin: 0 auto;
  max-width: 1228px;
`;

export const FirstSectionContainer = styled(SectionContainer)`
  display: flex;
  flex-direction: column;
`;

export const SecondSectionContainer = styled(SectionContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.screen.md} {
    align-items: flex-start;
    flex-direction: row;
  }
`;

export const FirstSectionHeader = styled(Flex)`
  flex-direction: column;

  @media ${(props) => props.theme.screen.md} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StatisticCardContainer = styled(Flex)`
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;
  gap: 30px;

  & > div {
    padding: 95px 30px 25px !important;
    height: initial;
    max-height: 389px;
  }

  & > div .statistic-value {
    margin-bottom: 26px;
  }

  @media ${(props) => props.theme.screen.md} {
    margin-left: 33px;
    justify-content: space-between;
  }

  @media ${(props) => props.theme.screen.lg} {
    flex-grow: 1;
  }
`;
