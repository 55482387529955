import { Progress as AntdProgress } from 'antd';
import React from 'react';
import styled from 'styled-components';

import useThemeContext from '../hooks/useTheme';

import Text from './Text';

type Props = React.ComponentProps<typeof AntdProgress>;

function Progress(props: Props) {
  const { colors } = useThemeContext();

  function progressFormat(percent: number | undefined) {
    return (
      <Text color={colors.darkHighEmphasis} type="body1reg2">
        {percent}%
      </Text>
    );
  }

  return (
    <StyledProgress
      format={progressFormat}
      strokeColor={colors.darkSecondary}
      {...props}
    />
  );
}

const StyledProgress = styled(AntdProgress)`
  display: flex;

  .ant-progress-text {
    width: fit-content;
  }
`;

export default Progress;
