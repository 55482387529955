import React, { useEffect } from 'react';

import { useTheme } from 'styled-components';

import NumberInput from 'src/client/components/NumberInput';
import { Box } from 'src/client/components/StyledCommon';
import Text from 'src/client/components/Text';
import { trimImpreciseFloatingPoint } from 'src/commons/utils/NumberUtils';

import ActionButtons from '../ActionButtons';

import * as S from './styles';

type Props = {
  adjustGivingGoal: (newGivingGoal: number) => void;
  adjustIncome: (newIncome: number) => void;
  adjustIncomeRate: (newIncomeRate: number) => void;
  goal: number | null;
  income: number | null;
  incomeRate: number | null;
  isSavingGoal?: boolean;
  onCancel: () => void;
  onSave: () => void;
  startEditingSuperGoal: () => void;
};

function SuperGoalEditState(props: Props) {
  const {
    adjustGivingGoal,
    adjustIncome,
    adjustIncomeRate,
    goal,
    income,
    incomeRate,
    isSavingGoal,
    onCancel,
    onSave,
    startEditingSuperGoal,
  } = props;

  const { colors } = useTheme();

  useEffect(() => {
    startEditingSuperGoal();
  }, []);

  const incomeRatePercentInputValue =
    typeof incomeRate === 'number'
      ? trimImpreciseFloatingPoint(incomeRate * 100)
      : 0;
  const incomeInputValue = income ?? 0;
  const givingGoalInputValue = goal ?? 0;

  function handleIncomeRateChange(value: number) {
    adjustIncomeRate((value || 0) / 100);
  }

  function handleIncomeChange(value: number) {
    adjustIncome(value || 0);
  }

  function handleGivingGoalChange(value: number) {
    adjustGivingGoal(value || 0);
  }

  return (
    <S.Container>
      <Text color={colors.lightMedEmphasis} type="h6med2">
        Super Goal
      </Text>
      <Box margin="13px 0 24px 0">
        <Text color={colors.white} type="h5med2">
          Creating a giving goal as a percentage of your income is a wonderful
          way to make giving part of your life for years to come.
        </Text>
      </Box>
      <S.InputContainer>
        <div>
          <Text type="label1med2">
            Percentage of income to give to people & causes
          </Text>
        </div>
        <NumberInput
          data-cy="income-percent-input"
          suffix="%"
          theme="dark"
          value={incomeRatePercentInputValue}
          onChange={handleIncomeRateChange}
        />
      </S.InputContainer>
      <Box margin="23px 0 0 0" />
      <S.InputContainer>
        <div>
          <Text type="label1med2">Annual income</Text>
        </div>
        <NumberInput
          data-cy="income-input"
          name="income"
          prefix="$"
          theme="dark"
          value={incomeInputValue}
          onChange={handleIncomeChange}
        />
      </S.InputContainer>
      <S.Line />
      <S.GivingGoalInputContainer>
        <Text color={colors.darkPrimary100} type="h6med2">
          Your Giving Goal
        </Text>
        <Box margin="0 0 0 24px">
          <NumberInput
            data-cy="goal-input"
            prefix="$"
            theme="dark"
            value={givingGoalInputValue}
            onChange={handleGivingGoalChange}
          />
        </Box>
      </S.GivingGoalInputContainer>
      <Text color={colors.lightMedEmphasis} type="body2reg2">
        You can edit your super goal at any time.
      </Text>
      <Box margin="45px 0 0 0" />
      <ActionButtons
        confirmButtonProps={{
          loading: isSavingGoal,
        }}
        onCancel={onCancel}
        onConfirm={onSave}
      />
    </S.Container>
  );
}

export default SuperGoalEditState;
