import { QueryClient, useInfiniteQuery, useQuery } from 'react-query';

import {
  getAttachmentsUrl,
  getPrepopulatedUnprocessedGiveById,
  getGiveById,
  getGiveByIds,
  getGiveHtmlBody,
  getGives,
} from 'src/client/api/GiveApi';
import { GetGivesParams } from 'src/client/types/ApiParams';
import { makePaginateQueryKey } from 'src/client/utils/QueryKeyUtils';
import { Entity } from 'src/commons/constants/entities';
import { Give, GivesFilter } from 'src/commons/types';
import { ApiResponse } from 'src/commons/types/Response.type';

export const GET_ATTACHMENTS_URL = 'GET_ATTACHMENTS_URL';
export const GET_GIVES_KEY = 'GET_GIVES_KEY';
export const GET_GIVE_BY_IDS_KEY = 'GET_GIVE_BY_IDS_KEY';
export const GET_GIVE_BY_ID_KEY = 'GET_GIVE_BY_ID_KEY';
export const GET_GIVE_HTML_BODY = 'GET_GIVE_HTML_BODY';
export const GET_INFINITE_GIVES_KEY = 'GET_INFINITE_GIVES_KEY';
export const GET_PREPOPULATED_UNPROCESSED_GIVE_BY_ID_KEY =
  'GET_PREPOPULATED_UNPROCESSED_GIVE_BY_ID_KEY';

type CommonOptions<Data = ApiResponse<Give[]>> = {
  keepPreviousData?: boolean;
  onSuccess?: (data: Data) => void;
  enabled?: boolean;
};

export function useGetGiveByIds(
  giveIds: string[],
  options?: CommonOptions<Give[]>
) {
  return useQuery(
    [GET_GIVE_BY_IDS_KEY, giveIds],
    () => getGiveByIds(giveIds),
    options
  );
}

export function useGetPrepopulatedUnprocessedGiveById(
  giveId: string,
  options?: CommonOptions<Give>
) {
  return useQuery([GET_PREPOPULATED_UNPROCESSED_GIVE_BY_ID_KEY, giveId], () =>
    getPrepopulatedUnprocessedGiveById(giveId)
  );
}

type GetGiveByIdParams = {
  giveId: string;
  shouldPrepopulateWhenUnprocessed?: boolean;
};

export function useGetGiveById(
  params: GetGiveByIdParams,
  options?: CommonOptions<Give>
) {
  return useQuery([GET_GIVE_BY_ID_KEY, params], () => getGiveById(params));
}

export function useGetGives(params?: GivesFilter, options?: CommonOptions) {
  return useQuery([GET_GIVES_KEY, params], () => getGives(params), options);
}

export function useGetGiveHtmlBody(giveId: string) {
  return useQuery([GET_GIVE_HTML_BODY, giveId], () => getGiveHtmlBody(giveId), {
    enabled: !!giveId,
  });
}

export function useGetAttachmentsUrl(attachments: string[]) {
  return useQuery([GET_ATTACHMENTS_URL, attachments], () =>
    getAttachmentsUrl(attachments)
  );
}

export function useInfiniteGetGives(params: GivesFilter) {
  return useInfiniteQuery(
    [GET_INFINITE_GIVES_KEY, params],
    (context) =>
      getGives({
        ...params,
        cursor: context.pageParam?.nextCursor,
      }),
    {
      getNextPageParam: (lastGives, allGives) => {
        if (lastGives.cursor) {
          return {
            nextCursor: lastGives.cursor,
          };
        } else {
          return undefined;
        }
      },
    }
  );
}

export function updateGivesQueryData(
  queryClient: QueryClient,
  data: Give,
  queryParams: GetGivesParams
) {
  queryClient.setQueryData<ApiResponse<Give[]> | undefined>(
    [GET_GIVES_KEY, queryParams],
    (old) => {
      if (!old) {
        return undefined;
      }

      const updatedGives = old?.data.map((give) => {
        if (give.id === data.id) {
          return {
            ...give,
            ...data,
          };
        }

        return give;
      });

      return {
        ...old,
        data: updatedGives,
      };
    }
  );
}

type InfiniteGiveQueryCache = {
  pages: ApiResponse<Give[]>[];
  pageParams: {
    nextCursor: string | undefined;
  }[];
};

export function updateInfiniteGivesQueryData(
  queryClient: QueryClient,
  dataToUpdate: Give,
  queryParams: GetGivesParams
) {
  queryClient.setQueryData<InfiniteGiveQueryCache | undefined>(
    [makePaginateQueryKey(Entity.GIVE), queryParams],
    (old) => {
      if (!old) {
        return undefined;
      }

      const updatedGivePages = old?.pages.map((giveResponse) =>
        updateGivePage(giveResponse, dataToUpdate)
      );

      return {
        pageParams: old.pageParams,
        pages: updatedGivePages,
      };
    }
  );
}

export function addGiveInInfiniteGivesQueryData(
  queryClient: QueryClient,
  newGive: Give,
  queryParams: GetGivesParams
) {
  queryClient.setQueryData<InfiniteGiveQueryCache | undefined>(
    [makePaginateQueryKey(Entity.GIVE), queryParams],
    (old) => {
      if (!old) {
        return undefined;
      }

      const updatedGivePages = old.pages.map((giveResponse, i) => {
        if (i === 0) {
          return {
            ...giveResponse,
            data: [newGive, ...giveResponse.data],
          };
        }

        return giveResponse;
      });

      return {
        pageParams: old.pageParams,
        pages: updatedGivePages,
      };
    }
  );
}

function updateGivePage(
  giveResponse: ApiResponse<Give[]>,
  dataToUpdate: Give
): ApiResponse<Give[]> {
  const updatedGives = giveResponse?.data.map((giveResponse) => {
    if (giveResponse.id === dataToUpdate.id) {
      return {
        ...giveResponse,
        ...dataToUpdate,
      };
    }

    return giveResponse;
  });

  return {
    ...giveResponse,
    data: updatedGives,
  };
}
